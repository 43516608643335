import { getNotification } from "actions/front/common/notification";
import { ReactComponent as Bell } from "assets/images/icons/bell.svg";
import { ReactComponent as XIcon } from "assets/images/icons/x.svg";
import { useEffect } from "react";
import { connect } from "react-redux";
import Button from "../common/Button";
import NotificationItem from "./NotificationItem";

function NotificationDropdown({
  isOpen,
  setOpen,
  getNotification,
  notifications,
}) {
  useEffect(() => {
    getNotification();
  }, []);

  return (
    <div className="relative whitespace-normal">
      <button
        className="relative align-text-top mr-8 ml-4"
        onClick={() => setOpen((o) => !o)}
      >
        {notifications?.length > 0 && (
          <div className="absolute -top-0.5 -right-1 w-1.5 h-1.5 bg-primary-600 rounded-full" />
        )}
        <Bell />
      </button>
      {isOpen && (
        <div className="absolute lg:fixed -left-[200px] top-12 lg:inset-0 w-[400px] lg:w-full bg-white p-6 shadow-lg z-50">
          <div className="flex justify-between mb-6">
            <div className="text-dgray-900 text-xl font-medium">
              Notifications
            </div>
            <button onClick={() => setOpen(false)}>
              <XIcon className="text-dgray-500" />
            </button>
          </div>
          {!notifications || notifications?.length === 0 ? (
            <NoNotifications />
          ) : (
            <ul className="space-y-8 text-sm text-dgray-500">
              {notifications.map((item) => (
                <NotificationItem {...item} />
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}

function NoNotifications() {
  return (
    <div className="text-center pt-28 pb-20">
      <div className="text-dgray-900 font-semibold mb-2">
        No new notifications
      </div>
      <p className="text-dgray-500 mb-8">
        There are no new notifications to display. You can go to your feed to
        see all updates from the hosts you follow.
      </p>
      <Button small flat href="/feed">
        Go to your feed
      </Button>
    </div>
  );
}

const mapStateToProps = ({ notification }) => ({
  loading: notification.loading,
  notifications: notification.notificationDetails,
});

export default connect(mapStateToProps, {
  getNotification,
})(NotificationDropdown);
