import { ReactComponent as GlobeIcon } from "assets/images/icons/globe.svg";
import { ReactComponent as PinIcon } from "assets/images/icons/location-pin.svg";
import { ReactComponent as UsersIcon } from "assets/images/icons/users-1.svg";
import janeImg from "assets/images/jane-doe.jpg";
import mockEventImg from "assets/images/mock-event.jpg";
import UserImage from "assets/images/user-nobg.jpg";
import Pill from "components/common/Pill";
import { formatDateShort } from "utils/formatDate";
import moment from "moment";
import { Link } from "react-router-dom";
import { EXPERTISE_COLOR } from "constants";
import getEventImage from "utils/getEventImage";
import { getCurrentTimeZone } from "utils/time";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { getAllCourses } from "actions/front/common/course";
import { getClosestSession } from "utils/course";

const filters = {
  limit: 0,
  page: 1,
  orderBy: "created_at",
  ascending: "asc",
  query: "",
  custom_filters: "",
};

function HostCard({ data }) {
  const [closestSession, setClosestSession] = useState();
  const [remaining, setRemaining] = useState(0);

  useEffect(() => {
    if (data && data.closest_courses) {
      getAllCourses(filters, data._id, "all").then((res) => {
        const list = res.data.data.map((course) => ({
          ...course,
          closest: getClosestSession(course),
        }));
        if (list.length > 0) {
          const sorted = list.sort((i1, i2) =>
            i1.closest.start_at === i2.closest.start_at
              ? 0
              : i1.closest.start_at < i2.closest.start_at
              ? -1
              : 1
          );
          setClosestSession(sorted[0]);
          setRemaining(sorted.length - 1);
        }
      });
    }
  }, [data]);

  return (
    <div className="relative rounded-2xl shadow-lg bg-white max-w-sm flex-1 lg:mx-auto w-full">
      <div className="py-8 px-6 mb-6">
        <div className="flex mb-6">
          <div className="flex-1 mr-2 flex">
            <div
              className="bg-center bg-cover flex-1 min-h-[160px]"
              style={{
                backgroundImage: data.avatar
                  ? data.avatar.startsWith("https://")
                    ? data.avatar
                    : `url(${process.env.REACT_APP_MEDIA_URL}${data.avatar})`
                  : `url(${UserImage})`,
              }}
            ></div>
          </div>
          <div className="flex-1">
            <div className="text-lg font-bold mb-1">{data.name}</div>
            <div className="text-xs text-dgray-500 mb-4">
              {data.location && (
                <div className="mb-1">
                  <PinIcon className="inline mr-1" />
                  {[data.city, data.state, data.country]
                    .filter((i) => i !== null && i !== "")
                    .join(", ")}
                </div>
              )}
              {data.languages.length > 0 && (
                <div>
                  <GlobeIcon className="inline mr-1" />
                  {data.languages.join(", ")}
                </div>
              )}
            </div>
            <div className="flex flex-wrap gap-2">
              {data.expertise_list?.slice(0, 3).map((exp, i) => (
                <Pill key={i} text={exp} color={EXPERTISE_COLOR[exp]} />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <div className="text-dgray-500 mb-6 line-clamp-4">
            {data.description}
          </div>
          <HostUpcomingEvents data={closestSession} remaining={remaining} />
        </div>
      </div>
      <div className="absolute bottom-0 inset-x-0 py-4 px-6 mt-10 text-sm text-primary-700 font-medium border-t border-dgray-200 ">
        <Link to={`/hosts/${data.username}`}>See Host Details</Link>
      </div>
    </div>
  );
}

export default connect(({ auth }) => ({ user: auth.user }))(HostCard);

function HostUpcomingEvents({ data, user, remaining }) {
  const timeZone = getCurrentTimeZone(user);
  return (
    <div>
      <div className="text-sm font-semibold mb-3">Upcoming Events</div>
      <div className="flex gap-2 mb-3">
        {!data ? (
          <p>This host has no upcoming events.</p>
        ) : (
          <>
            <div
              className="h-20 w-20 rounded-lg bg-cover"
              style={{
                backgroundImage: `url(${getEventImage(data._id)})`,
                backgroundPosition: "center",
              }}
            ></div>
            <div>
              <Link
                to={`/session/${data._id._id}`}
                className="text-sm text-dgray-500 font-medium mb-1"
              >
                {data._id.name}
              </Link>
              <div className="flex items-center gap-2 mb-2">
                {data.session ? (
                  <>
                    <div className="text-xs font-medium">
                      {formatDateShort(
                        moment.unix(data.closest.start_at).tz(timeZone)
                      )}
                    </div>
                  </>
                ) : null}
                {data._id.course_type === 1 ? (
                  <Pill text="Private event" color="orange" />
                ) : (
                  <Pill text="Public event" color="orange" />
                )}
              </div>
              <div className="text-xs text-dgray-500">
                <UsersIcon className="inline h-4" />
                {/*{data[0].attendeeCount} attendees*/}
              </div>
            </div>
          </>
        )}
      </div>
      {remaining > 1 && (
        <div className="text-dgray-500 italic">+{remaining} more</div>
      )}
    </div>
  );
}
