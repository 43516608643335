import axios from "utils/axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  HOST_APPLICATION_ERROR,
  HOST_APPLICATION_LIST_UPDATED,
  REMOVE_ERRORS,
  HOST_APPLICATION_LIST_COMPLETED,
  LOADING_HOST_APPLICATION_LIST_DATA,
} from "actions/types";
import { logout } from "actions/auth";

//get LOG list
export const getHostApplicationList = (logParams) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    dispatch({ type: REMOVE_ERRORS });
    dispatch({ type: LOADING_HOST_APPLICATION_LIST_DATA });

    const query = logParams.query ? logParams.query : "&query=";

    const res = await axios.get(
      `/api/admin/host-applications?limit=${logParams.limit}&page=${logParams.page}&orderBy=${logParams.orderBy}&ascending=${logParams.ascending}${query}`,
      config
    );
    console.log("log response :::" + res);
    dispatch({
      type: HOST_APPLICATION_LIST_UPDATED,
      payload: res.data.response[0],
    });
    dispatch({
      type: HOST_APPLICATION_LIST_COMPLETED,
    });
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err.response);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: HOST_APPLICATION_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  }
};

export const getHostApplicationById = async (id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`/api/admin/host-applications/${id}`, config);
    return { status: true, data: res };
  } catch (ex) {
    return { status: false };
  }
};


export const updateHostApplication = async (id, status) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(`/api/admin/host-applications/change-status/${id}`, {
      status
    }, config);
    return { status: true, data: res };
  } catch (ex) {
    return { status: false };
  }
};
