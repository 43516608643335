import React, { useState, useMemo, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import * as moment from 'moment-timezone';
import { ORDER_DETAILS } from "actions/types";
import {SESSION_TYPE}  from "constants/index";
import Pagination from "views/Front/Pagination";
import { RECORD_PER_PAGE } from "constants/index";
import { getCurrentTimeZone } from "utils/time";

const LearnerPaymentHistory = ({ learnerOrderList, user, history, paginate, totalRecords, filters }) => {
  const dispatch = useDispatch();
  
  let timeZone = getCurrentTimeZone(user);

  const downloadPdf = (item) => {
    dispatch({
      type: ORDER_DETAILS,
      payload: item,
    })
    history.push('/order-detail');
  };

  return (
    <>
      <div className="col-md-12">
        <h3>Spending History</h3>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Session</th>
                <th>Mentor</th>
                <th>Interaction Information</th>
                <th>Total Amount</th>
                <th>Booked date</th>
                <th>Refund Amount</th>
                <th>Payment Status</th>
                <th>Print</th>
              </tr>
            </thead>
            <thead>
              {learnerOrderList.map((item, i) => {
                return (
                  <tr key={`order-${i}`}>
                    <td>{i + 1}</td>
                    <td>{item.course_name}</td>
                    <td>{item.mentor_name}</td>
                    <td>
                      <p className="mb-1">
                        <small>Date/Time:</small> <br />
                        {item.session_type == SESSION_TYPE.GENERIC
                          ? moment
                              .unix(item.session_data[0].start_at)
                              .tz(timeZone)
                              .format("MMM Do, YYYY HH:mm A") +
                            "-" +
                            moment
                              .unix(
                                item.session_data[item.session_data.length - 1]
                                  .end_at
                              )
                              .tz(timeZone)
                              .format("HH:mm A")
                          : item.session_data.map((session) => (
                              <>
                                <span>
                                  {moment
                                    .unix(session.start_at)
                                    .tz(timeZone)
                                    .format("MMM Do, YYYY HH:mm A")}{" "}
                                  -{" "}
                                  {moment
                                    .unix(session.end_at)
                                    .tz(timeZone)
                                    .format("HH:mm A")}
                                </span>
                                <br />
                              </>
                            ))}
                      </p>
                    </td>
                    <td>${item.course_price}</td>
                    <td>
                      {moment
                        .unix(item.booked_on)
                        .tz(timeZone)
                        .format("MMM Do, YYYY")}
                    </td>
                    <td>{item.refund_amount ? `$${item.refund_amount}` : 'N/A'}</td>
                    <td>
                      {item.order_booking_status == 1
                        ? item.order_status == 1
                          ? "Pending"
                          : item.order_status == 2
                          ? "Success"
                          : item.order_status == 3
                          ? "Fail"
                          : item.order_status == 4 && "Free"
                        : "Refunded"}
                    </td>
                    <td>
                      <Link to="#" onClick={() => downloadPdf(item)}>
                        View Invoice
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </thead>
          </table>
        </div>
      </div>
      <div className="row paginate-pos" id="learnerPagination">
        <div className="col-md-12">
          <Pagination
            totalRecords={totalRecords}
            recordPerPage={RECORD_PER_PAGE}
            paginate={paginate}
            currentPage={filters.page}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(withRouter(LearnerPaymentHistory));
