import { editPassword } from "actions/admin/profile";
import {
  currentUser,
  getConfigurations,
  update,
} from "actions/front/myaccount";
import { ReactComponent as UserCheck } from "assets/images/icons/user-check.svg";
import CenterContainer from "components/common/Container/Center";
import SelectInput from "components/common/form/SelectInput";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Spinner } from "reactstrap";
import IconCircle from "views/Front/HomePage/IconCircle";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { useHistory } from "react-router-dom";
import Button from "components/common/Button";

const BecomeAHost = ({
  user,
  location,
  errorList,
  isAuthenticated,
  authLoading,
}) => {
  const history = useHistory();

  const pageOptions = [
    { label: "Edit My Profile", value: "edit" },
    { label: "Account Settings", value: "settings" },
    { label: "Become A Host", value: "becomeAHost" },
  ];

  const selectedOptions = (selected) => {
    if (selected.value == "edit") history.push("/profile");
    if (selected.value == "settings") history.push("/settings");
  };

  return (
    <>
      <Header />
      <CenterContainer>
        <div className="md:text-left pt-12 pb-12 xl:px-0 md:w-full md:px-0 mx-auto">
          <p className="text-dgray-900 mb-24 md:mb-8 text-5xl md:text-2xl text-center md:text-left font-medium">
            Manage my account
          </p>
          <div className="flex flex-row md:flex-col gap-4">
            <div className="flex flex-col w-72 md:w-full">
              <SelectInput
                options={pageOptions}
                className="hidden md:block mt-4 text-sm"
                defaultValue={pageOptions[2]}
                onChange={selectedOptions}
              />
              <ul className="mt-4 space-y-1 md:hidden">
                <li
                  className={`flex w-full justify-between cursor-pointer items-center rounded
                  ${
                    location.pathname === "/profile"
                      ? "text-primary-700 bg-primary-50"
                      : "text-dgray-700 hover:text-gray-500"
                  }
                `}
                >
                  <Link
                    to="/profile"
                    className="flex items-center py-2 px-3 w-full"
                  >
                    <div
                      className={
                        location.pathname === "/profile"
                          ? "text-primary-600"
                          : "text-dgray-500"
                      }
                    ></div>
                    <span className="font-medium ml-2 md:ml-0">
                      Edit My Profile
                    </span>
                  </Link>
                </li>
                <li
                  className={`flex w-full justify-between cursor-pointer items-center rounded
                  ${
                    location.pathname === "/settings"
                      ? "text-primary-700 bg-primary-50"
                      : "text-dgray-700 hover:text-gray-500"
                  }
                `}
                >
                  <Link
                    to="/settings"
                    className="flex items-center py-2 px-3 w-full"
                  >
                    <div
                      className={
                        location.pathname === "/settings"
                          ? "text-primary-600"
                          : "text-dgray-500"
                      }
                    ></div>
                    <span className="font-medium ml-2 md:ml-0">
                      Account Settings
                    </span>
                  </Link>
                </li>
                <li
                  className={`flex w-full justify-between cursor-pointer items-center rounded
                  ${
                    location.pathname === "/account/become-a-host"
                      ? "text-primary-700 bg-primary-50"
                      : "text-dgray-700 hover:text-gray-500"
                  }
                `}
                >
                  <Link
                    to="/account/become-a-host"
                    className="flex items-center py-2 px-3 w-full"
                  >
                    <div
                      className={
                        location.pathname === "/account/become-a-host"
                          ? "text-primary-600"
                          : "text-dgray-500 "
                      }
                    ></div>
                    <span className="font-medium ml-2 md:ml-0">
                      Become A Host
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex flex-col text-center items-center justify-center w-full px-16 md:px-0 min-h-32">
              {authLoading || !user.host_application ? (
                <>
                  <IconCircle>
                    <UserCheck />
                  </IconCircle>
                  <div className="text-3xl text-dgray-900 font-semibold mt-4">
                    Become a DDN Host
                  </div>
                  <div className="text-base text-dgray-500 mt-4 px-0">
                    Introduce yourself and show your expertise to become a DDN
                    host.
                  </div>
                  <div className="basis-1/2 pr-24 lg:mb-16 mt-8 px-40 md:px-4 mb-16">
                    <ul className="space-y-8 border-l-2">
                      {items.map(({ title, description }) => (
                        <li className="pl-8">
                          <div className="font-medium relative text-left">
                            <div className="absolute -top-2 -left-[57px]">
                              <Disc />
                            </div>
                            {title}
                          </div>
                          <div className="text-dgray-500 text-left">
                            {description}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Button
                    className="bg-primary-600 text-white md:w-full"
                    to="/become-a-host"
                  >
                    Start my application
                  </Button>
                </>
              ) : (
                <>
                  {user.host_application.status === "submitted" ||
                  user.host_application.status === "in_review" ? (
                    <>
                      <IconCircle>
                        <UserCheck />
                      </IconCircle>
                      <div className="text-3xl text-dgray-900 font-semibold mt-4">
                        You Application Status
                      </div>
                      <div className="text-base text-dgray-500 mt-4 px-0">
                        We've received your application, it is currently under
                        review.
                      </div>
                    </>
                  ) : (
                    <>
                      {user.host_application.status === "rejected" ? (
                        <>
                          <IconCircle>
                            <UserCheck />
                          </IconCircle>
                          <div className="text-3xl text-dgray-900 font-semibold mt-4">
                            You Application Status
                          </div>
                          <div className="text-base text-dgray-500 mt-4 px-0">
                            Your application is rejected. Please contact us for
                            information.
                          </div>
                        </>
                      ) : (
                        <Redirect to={"/dashboard"} />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </CenterContainer>
      <Footer />
    </>
  );
};

function Disc() {
  return (
    <div className="rounded-full inline-block">
      <div className="rounded-full w-10 h-10 border-2 border-dgray-200 flex justify-center items-center bg-white">
        <div className="rounded-full w-3 h-3 bg-dgray-200" />
      </div>
    </div>
  );
}

const items = [
  {
    title: "Your personal information",
    description:
      "Your basic personal information that will be displayed on your host profile.",
  },
  {
    title: "Experience & Qualification",
    description:
      "Your background & professional experience that will help us to evaluate your English proficiency and any supporting documents.",
  },
  {
    title: "Payout method selection",
    description:
      "Selection and set up of preferred payout method from available options on DDN.",
  },
  {
    title: "Review & Approval",
    description:
      "DDN team carefully reviews and evaluates all applications. You will get a notification on your application.",
  },
];

const mapStateToProps = (state) => ({
  errorList: state.errors,
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  authLoading: state.auth.loading,
});

export default connect(mapStateToProps, {})(withRouter(BecomeAHost));
