import {
  LEARNER_LOADING,
  LEARNER_LIST_UPDATED,
  LEARNER_FAIL,
  LEARNER_CHANGE_STATUS,
  LEARNER_CHANGE_STATUS_ERROR,
  LEARNER_DETAILS,
} from "actions/types";

const initalState = {
  learnersList: {
    page: 1,
    data: [],
    count: 0,
  },
  learnerDetail: [],
  currentUser: null,
  loading: false,
  error: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case LEARNER_LOADING:
      return {
        ...state,
        learnersList: payload.is_sub_element
          ? state.learnersList
          : initalState.learnersList,
        currentUser: payload.is_sub_element
          ? state.currentUser
          : initalState.currentUser,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case LEARNER_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case LEARNER_LIST_UPDATED:
      return {
        ...state,
        learnersList: {
          data: payload.data.data,
          page: payload.data.metadata[0].current_page,
          count: payload.data.metadata[0].totalRecord,
        },
        loading: false,
        isSubLoading: false,
      };
    case LEARNER_DETAILS:
        return {
          ...state,
          learnerDetail: payload,
          loading: false,
          isSubLoading: false,
        };
    case LEARNER_CHANGE_STATUS:
      return {
        ...state,
        learnersList: {
          ...state.learnersList,
          data: state.learnersList.data.map((learner) =>
            learner._id === payload.data._id
              ? { ...learner, status: payload.data.status }
              : learner
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case LEARNER_CHANGE_STATUS_ERROR:
      return {
        ...state,
        learnersList: {
          ...state.learnersList,
          data: state.learnersList.data.map((learner) =>
            learner._id === payload.data
              ? { ...learner, is_failed: true }
              : { ...learner, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    default:
      return state;
  }
}
