import IconCircle from "views/Front/HomePage/IconCircle";
import { ReactComponent as MailIcon } from "assets/images/icons/mail.svg";
import { ReactComponent as ArrowLeft } from "assets/images/icons/arrow-left.svg";
import { Link } from "react-router-dom";

export default function SuccessScreen({
  children,
  title,
  description,
  iconColor,
  icon,
}) {
  return (
    <div className="max-w-sm mx-auto py-24">
      <SmallHeader
        title={title}
        description={description}
        iconColor={iconColor}
        icon={icon || <MailIcon />}
      />
      {children}
      <div className="flex justify-center text-dgray-500 text-sm mt-8 md:mt-8 xl:mt-10">
        <ArrowLeft className="mr-3" />
        <Link to="/login"> Back to log in </Link>
      </div>
    </div>
  );
}

function SmallHeader({ icon, title, description, iconColor }) {
  return (
    <div className="text-center mb-8">
      <IconCircle className="mx-auto mb-5" color={iconColor} large>
        {icon}
      </IconCircle>
      <div className="text-3xl md:text-2xl text-dgray-900 font-semibold mb-3">
        {title}
      </div>
      <div className="text-dgray-500">{description}</div>
    </div>
  );
}
