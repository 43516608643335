import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { momentLocalizer } from "react-big-calendar";
import * as moment from 'moment-timezone';
import {
  getEvents,
  getEventDetail,
  educatorCancelCourseScheduled,
} from "actions/front/mentorDashboard";
import { getSessions } from "actions/front/learner/courses";
import { confirmAlert } from "react-confirm-alert"; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import { SESSION_TYPE } from "constants/index";
import Spinner1 from "views/Spinner1";
import { getCurrentTimeZone } from "utils/time";
import defaultImage from "assets/img/avatars/user.jpg";
import CourseImage from "assets/images/mock-event.jpg";

const Calendar = ({
    getEvents,
    getEventDetail,
    getSessions,
    user,
    educatorCancelCourseScheduled,
    onStatisticsLoad
  }) => {
    const [events, setEvents] = useState(null);
    const [sessions, setSessions] = useState(null);
    
    let timeZone = getCurrentTimeZone(user);
    
    const localizer = momentLocalizer(moment);
    let unixTimeStamp = moment.utc().unix();
    
    // let currentDate = moment().tz(timeZone).format("YYYY-MM-DD HH:mm");
    let currentDate = moment().tz(timeZone);
    const [calendarLoader, setCalendarLoader] = useState(false);
    const [cancelLoader, setCancelLoader] = useState(false);
    const [maxCancelHr, setMaxCancelHr] = useState(false);
    
    useEffect(() => {
      if ((events && sessions) && !calendarLoader) {
        let all = [...events, ...sessions]
        const now = moment()
        const oneWeekLater = moment().add(7, 'days')
  
        all = all 
          .map(item => ({...item, start: moment(item.start), end: moment(item.end)}))
          .filter(item => item.start.isAfter(now) && item.end.isBefore(oneWeekLater) && (item.hasOwnProperty('is_booked') ? item.is_booked : true))
          .sort((a, b) => a.start.diff(b.start))
  
        const statistics = {
          loading: false,
          data: {
            courseOffered: all.filter(item => item.type === 'event'),
            courseTaken: all.filter(item => item.type === 'session'),
            upcomingSessionsCount: all.length,
            closestUpcomingCourse: all[0],
          }
        }
        onStatisticsLoad(statistics)
      }
    }, [events, sessions, calendarLoader])
    
    const getCalendarData = () => {
      Promise.all([getEvents(), getSessions()]).then(async (response) => {
        const eventData = response[0];
        const sessionData = response[1];
  
        if (eventData.status) {
          let eventlists = [];
          setMaxCancelHr(eventData.response.settings.cancellation_maxhour);
          let sessionPromise = await eventData.response.events.map(
            async (singleSession) => {
              if (
                (singleSession.session_type != SESSION_TYPE.GENERIC &&
                  singleSession.session_type != SESSION_TYPE.LIVE_EVENT) ||
                (singleSession.session_type == SESSION_TYPE.GENERIC &&
                  singleSession.session_booking_count == 0)
              ) {
                let start_time = moment
                  .unix(singleSession.start_at)
                  .tz(timeZone)
                  .format("YYYY-MM-DDTHH:mm:ssZ");
                let end_time = moment
                  .unix(singleSession.end_at)
                  .tz(timeZone)
                  .format("YYYY-MM-DDTHH:mm:ssZ");
  
                eventlists.push({
                  type: "event",
                  start: new Date(start_time),
                  end: new Date(end_time),
                  title: singleSession.course_name,
                  course_id: singleSession.course_id,
                  is_booked:
                    singleSession.session_booking_count > 0 ? true : false,
                  all_session_with_recurring_id:
                    singleSession.session_type != SESSION_TYPE.GENERIC
                      ? singleSession.all_session_with_recurring_id
                      : "",
                  session_group_id: singleSession.session_group_id,
                  start_at: singleSession.start_at,
                  end_at: singleSession.end_at,
                  course_image: singleSession.course_image,
                  session_booking_count: singleSession.session_booking_count,
                  detail_for: singleSession.session_type,
                  check_session_cancelation_start_at:
                    singleSession.check_session_cancelation_start_at,
                  is_cancelled:
                    singleSession.session_type != SESSION_TYPE.GENERIC
                      ? singleSession.is_cancelled
                        ? singleSession.is_cancelled
                        : false
                      : false,
                });
              } else if (singleSession.session_type === SESSION_TYPE.LIVE_EVENT) {
                let start_time = moment
                  .unix(singleSession.start_at)
                  .tz(timeZone)
                  .format("YYYY-MM-DDTHH:mm:ssZ");
                let end_time = moment
                  .unix(singleSession.start_at)
                  .add(2, "hours")
                  .tz(timeZone)
                  .format("YYYY-MM-DDTHH:mm:ssZ");
                if (singleSession.end_at)
                  end_time = moment
                    .unix(singleSession.end_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
  
                eventlists.push({
                  type: "event",
                  start: new Date(start_time),
                  end: new Date(end_time),
                  title: singleSession.course_name,
                  course_id: singleSession.course_id,
                  is_booked:
                    singleSession.session_booking_count > 0 ? true : false,
                  session_booking_count: singleSession.session_booking_count,
                  course_image: singleSession.course_image,
                  all_session_with_recurring_id:
                    singleSession.all_session_with_recurring_id,
                  session_group_id: singleSession.session_group_id,
                  start_at: singleSession.start_at,
                  end_at: singleSession.end_at,
                  detail_for: singleSession.session_type,
                  check_session_cancelation_start_at:
                    singleSession.check_session_cancelation_start_at,
                  is_cancelled:
                    singleSession.session_type != SESSION_TYPE.GENERIC
                      ? singleSession.is_cancelled
                        ? singleSession.is_cancelled
                        : false
                      : false,
                });
              } else {
                let sortedSession = await Promise.all(
                  singleSession.booked_sessions.sort(
                    (a, b) => a.start_at - b.start_at
                  )
                );
  
                if (sortedSession.length !== 0) {
                  if (sortedSession[0].start_at != singleSession.start_at) {
                    let start_time = moment
                      .unix(singleSession.start_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
                    let end_time = moment
                      .unix(sortedSession[0].start_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
  
                    eventlists.push({
                      start: new Date(start_time),
                      end: new Date(end_time),
                      title: singleSession.course_name,
                      course_id: singleSession.course_id,
                      is_booked: false,
                      session_booking_count: singleSession.session_booking_count,
                      end_at: singleSession.end_at,
                      course_image: singleSession.course_image,
                      all_session_with_recurring_id: "",
                      session_group_id: singleSession.session_group_id,
                      start_at: singleSession.start_at,
                      end_at: sortedSession[0].start_at,
                      detail_for: singleSession.session_type,
                      check_session_cancelation_start_at:
                        singleSession.check_session_cancelation_start_at,
                      is_cancelled: false,
                    });
                  }
                  let sessionIndex = 0;
                  while (sessionIndex < sortedSession.length) {
                    let start_time = moment
                      .unix(sortedSession[sessionIndex].start_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
                    let end_time = moment
                      .unix(sortedSession[sessionIndex].end_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
  
                    eventlists.push({
                      start: new Date(start_time),
                      end: new Date(end_time),
                      title: singleSession.course_name,
                      course_id: singleSession.course_id,
                      is_booked: true,
                      all_session_with_recurring_id: "",
                      session_group_id: singleSession.session_group_id,
                      start_at: sortedSession[sessionIndex].start_at,
                      end_at: sortedSession[sessionIndex].end_at,
                      detail_for: singleSession.session_type,
                      check_session_cancelation_start_at:
                        singleSession.check_session_cancelation_start_at,
                      is_cancelled:
                        sortedSession[sessionIndex].is_cancelled == 2
                          ? true
                          : false,
                      session_booking_count: singleSession.session_booking_count,
                      course_image: singleSession.course_image,
                    });
  
                    if (
                      sessionIndex + 1 != sortedSession.length &&
                      sortedSession[sessionIndex].end_at !=
                        sortedSession[sessionIndex + 1].start_at
                    ) {
                      let start_time = moment
                        .unix(sortedSession[sessionIndex].end_at)
                        .tz(timeZone)
                        .format("YYYY-MM-DDTHH:mm:ssZ");
                      let end_time = moment
                        .unix(sortedSession[sessionIndex + 1].start_at)
                        .tz(timeZone)
                        .format("YYYY-MM-DDTHH:mm:ssZ");
  
                      eventlists.push({
                        start: new Date(start_time),
                        end: new Date(end_time),
                        title: singleSession.course_name,
                        course_id: singleSession.course_id,
                        is_booked: true,
                        all_session_with_recurring_id: "",
                        session_group_id: singleSession.session_group_id,
                        start_at: sortedSession[sessionIndex].end_at,
                        end_at: sortedSession[sessionIndex + 1].start_at,
                        detail_for: singleSession.session_type,
                        is_cancelled: false,
                        session_booking_count: singleSession.session_booking_count,
                        course_image: singleSession.course_image,
                        check_session_cancelation_start_at:
                          singleSession.check_session_cancelation_start_at,
                      });
                    }
  
                    sessionIndex++;
                  }
  
                  if (
                    sortedSession[sortedSession.length - 1].end_at !=
                    singleSession.end_at
                  ) {
                    let start_time = moment
                      .unix(sortedSession[sortedSession.length - 1].end_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
                    let end_time = moment
                      .unix(singleSession.end_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
  
                    eventlists.push({
                      start: new Date(start_time),
                      end: new Date(end_time),
                      title: singleSession.course_name,
                      course_id: singleSession.course_id,
                      is_booked: false,
                      all_session_with_recurring_id: "",
                      session_group_id: singleSession.session_group_id,
                      start_at: sortedSession[sortedSession.length - 1].end_at,
                      end_at: singleSession.end_at,
                      detail_for: singleSession.session_type,
                      is_cancelled: false,
                      check_session_cancelation_start_at:
                        singleSession.check_session_cancelation_start_at,
                      session_booking_count: singleSession.session_booking_count,
                      course_image: singleSession.course_image,
                    });
                  }
                } else {
                  let start_time = moment
                    .unix(singleSession.start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
                  let end_time = moment
                    .unix(singleSession.end_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
  
                  eventlists.push({
                    start: new Date(start_time),
                    end: new Date(end_time),
                    title: singleSession.course_name,
                    course_id: singleSession.course_id,
                    is_booked:
                      singleSession.session_booking_count > 0 ? true : false,
                    all_session_with_recurring_id: "",
                    session_group_id: singleSession.session_group_id,
                    start_at: singleSession.start_at,
                    end_at: singleSession.end_at,
                    detail_for: singleSession.session_type,
                    check_session_cancelation_start_at:
                      singleSession.check_session_cancelation_start_at,
                    is_cancelled: singleSession.is_cancelled == 2 ? true : false,
                    session_booking_count: singleSession.session_booking_count,
                    course_image: singleSession.course_image,
                  });
                }
              }
            }
          );
          await Promise.all(sessionPromise);
          setEvents(eventlists);
        }
        if (sessionData.status) {
          let eventlists = [];
          let promise = await sessionData.response.map(
            async (singleSessionGroup, i) => {
              if (singleSessionGroup.session_type == SESSION_TYPE.GENERIC) {
                let start_time = moment
                  .unix(singleSessionGroup.session_detail[0].start_at)
                  .tz(timeZone)
                  .format("YYYY-MM-DDTHH:mm:ssZ");
                let end_time = moment
                  .unix(
                    singleSessionGroup.session_detail[
                      singleSessionGroup.session_detail.length - 1
                    ].end_at
                  )
                  .tz(timeZone)
                  .format("YYYY-MM-DDTHH:mm:ssZ");
                eventlists.push({
                  type: "session",
                  start: new Date(start_time),
                  end: new Date(end_time),
                  start_at: singleSessionGroup.session_detail[0].start_at,
                  end_at: singleSessionGroup.session_detail[0].end_at,
                  title: singleSessionGroup.course_name,
                  course_id: singleSessionGroup.course_id,
                  course_image: singleSessionGroup.course_image,
                  detail_for: singleSessionGroup.session_type,
                  mentor_id: singleSessionGroup.mentor_id,
                  mentor_name: singleSessionGroup.mentor_name,
                  is_cancelled:
                    singleSessionGroup.is_cancelled === 3 ||
                    singleSessionGroup.is_cancelled === 2
                      ? true
                      : false,
                  mentor_username: singleSessionGroup.mentor_username,
                });
              } else if (singleSessionGroup.session_type === SESSION_TYPE.LIVE_EVENT) {
                let sessionPromise = await singleSessionGroup.session_detail.map(
                  (session) => {
                    let start_time = moment
                      .unix(session.start_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
                    let end_time = moment
                      .unix(session.start_at)
                      .add(2, "hours")
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
                    eventlists.push({
                      type: "session",
                      start: new Date(start_time),
                      end: new Date(end_time),
                      start_at: singleSessionGroup.session_detail[0].start_at,
                      end_at: singleSessionGroup.session_detail[0].end_at,
                      title: singleSessionGroup.course_name,
                      course_id: singleSessionGroup.course_id,
                      course_image: singleSessionGroup.course_image,
                      detail_for: singleSessionGroup.session_type,
                      mentor_id: singleSessionGroup.mentor_id,
                      mentor_name: singleSessionGroup.mentor_name,
                      is_cancelled:
                        singleSessionGroup.is_cancelled === 3 ||
                        singleSessionGroup.is_cancelled === 2
                          ? true
                          : false,
                      mentor_username: singleSessionGroup.mentor_username,
                    });
                  }
                );
                await Promise.all(sessionPromise);
              } else {
                let sessionPromise = await singleSessionGroup.session_detail.map(
                  (session) => {
                    let start_time = moment
                      .unix(session.start_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
                    let end_time = moment
                      .unix(session.end_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
                    eventlists.push({
                      type: "session",
                      start: new Date(start_time),
                      end: new Date(end_time),
                      start_at: singleSessionGroup.session_detail[0].start_at,
                      end_at: singleSessionGroup.session_detail[0].end_at,
                      title: singleSessionGroup.course_name,
                      course_id: singleSessionGroup.course_id,
                      course_image: singleSessionGroup.course_image,
                      detail_for: singleSessionGroup.session_type,
                      mentor_id: singleSessionGroup.mentor_id,
                      mentor_name: singleSessionGroup.mentor_name,
                      is_cancelled:
                        singleSessionGroup.is_cancelled === 3 ||
                        singleSessionGroup.is_cancelled === 2
                          ? true
                          : false,
                      mentor_username: singleSessionGroup.mentor_username,
                    });
                  }
                );
                await Promise.all(sessionPromise);
              }
            }
          );
          await Promise.all(promise);
          // console.log(eventlists);
          setSessions(eventlists);
        }
        setCalendarLoader(false);
        setCancelLoader(false);
      });
    };
  
    useMemo(() => {
      setCalendarLoader(true);
      getCalendarData();
    }, []);
  
    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState(false);
    const toggle = () => setModal(!modal);
    const onEventClick = async (e) => {
      setModal(!modal);
      let modalSetData = { ...e };
      setModalData(false);
      if (modalSetData.type === "event") {
        const attendeesResponse = await getEventDetail(modalSetData.course_id);
        if (attendeesResponse.status) {
          modalSetData = {
            ...modalSetData,
            attendees: attendeesResponse.response,
          };
        }
      }
      const start_at = moment(modalSetData.start);
      let check_start_date = moment.unix(modalSetData.start_at).tz(timeZone);
      let check_Date = moment(
        moment(currentDate).add(maxCancelHr, "hours").format("YYYY-MM-DD HH:mm")
      );
      if (
        modalSetData.is_booked &&
        start_at.unix() > currentDate.unix() &&
        !modalSetData.is_cancelled &&
        check_start_date.unix() > check_Date.unix()
      ) {
        modalSetData.can_be_cancelled = true;
      } else {
        modalSetData.can_be_cancelled = false;
      }
      setModalData(modalSetData);
    };
  
    const eventStyleGetter = (event, start, end, isSelected) => {
      if (event.type === "event") {
        //var backgroundColor = '#' + event.hexColor;
        // const start_at = moment(start).tz(timeZone).format("YYYY-MM-DD HH:mm");
        const start_at = moment(start);
        // let backgroundColor = start_at > currentDate ? '#3174ad' : 'lightblue';
        let backgroundColor = "";
        if (event.is_booked && !event.is_cancelled) {
          backgroundColor = "#28a745"; // for booked and not cancelled
        } else if (start_at.unix() > currentDate.unix()) {
          backgroundColor = "#f39263"; // for upcoming and other
        }
  
        if (start_at.unix() < currentDate.unix()) {
          backgroundColor = "red"; // for past
        }
  
        if (event.is_cancelled) {
          backgroundColor = "black"; // for cancelled
        }
        var style = {
          backgroundColor: backgroundColor,
          borderRadius: "0px",
          opacity: 0.8,
          color: "#FFFFFF",
          border: "0px",
          display: "block",
        };
        return {
          style: style,
        };
      } else {
        const start_at = moment(start);
        // let backgroundColor = start_at > currentDate ? '#3174ad' : 'lightblue';
        let backgroundColor = "#28a745";
        if (event.is_cancelled) {
          backgroundColor = "black"; // if session is cacled
        } else if (start_at.unix() < currentDate.unix()) {
          backgroundColor = "red"; // for past
        } else if (start_at.unix() > currentDate.unix()) {
          backgroundColor = "#28a745"; // for upcoming and other
        }
        // else {
        //   backgroundColor = "#3174ad"; // for upcoming and other
        // }
        var style = {
          backgroundColor: backgroundColor,
          borderRadius: "0px",
          opacity: 0.8,
          color: "#FFFFFF",
          border: "0px",
          display: "block",
        };
        return {
          style: style,
        };
      }
    };
  
    const onCancelClick = () => {
      toggle();
      confirmAlert({
        title: <h6>Are you sure you want to cancel this session ?</h6>,
        message: (
          <div>
            <span>Your learners will be notified and 100% refunded</span>
          </div>
        ),
        buttons: [
          {
            label: "Ok",
            onClick: () => confirmCancel(),
          },
          {
            label: "Back",
            onClick: () => {},
          },
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    };
  
    const confirmCancel = async () => {
      setCancelLoader(true);
      educatorCancelCourseScheduled(modalData).then(async (res) => {
        setModal(false);
        setModalData(false);
        if (res.status) {
          getCalendarData();
        } else {
          alert(res.msg);
          setCancelLoader(false);
        }
      });
    };
  }

  // const mapStateToProps = (state) => ({});
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getEvents,
  getEventDetail,
  getSessions,
  educatorCancelCourseScheduled,
})(Calendar);
