import { getSessions } from "actions/front/learner/courses";
import {
  // educatorCancelCourseScheduled,
  getEventDetail,
  getEvents,
} from "actions/front/mentorDashboard";
import { ReactComponent as CalendarIcon } from "assets/images/icons/calendar.svg";
import Button from "components/common/Button";
import DashboardHeader from "components/common/dashboard/Header";
import DatePicker from "components/common/form/DatePicker";
import SelectInput from "components/common/form/SelectInput";
import DashboardCalendar from "components/dashboard/DashboardCalendar";
import * as moment from "moment-timezone";
import { useState } from "react";
import { momentLocalizer } from "react-big-calendar";
import { connect } from "react-redux";
import "scss/calendar/calendar.scss";

const dateOptions = [
  {
    label: "Week",
    value: "week",
  },
  {
    label: "Month",
    value: "month",
  },
];

const MyCalendar = ({ getEvents, getEventDetail, getSessions, user }) => {
  const [calendarView, setCalendarView] = useState(dateOptions[0]);
  const [selectedDateRange, setSelectedDateRange] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState({
    event: null,
    loading: false,
  });

  const [dates, setDates] = useState([])

  const localizer = momentLocalizer(moment);
  localizer.formats.timeGutterFormat = "h A";

  return (
    <div className="py-8 pb-4 min-h-screen">
      <DashboardHeader
        title="My Calendar"
        description="Manage and create your courses, calendar and mentor settings."
      />
      <div className="flex md:flex-col flex-row mr-2 lg:flex-row xl:flex-row text-center md:w-full mx-auto">
        <div className="w-80">
          <div className="flex flex-row w-full">
            <SelectInput
              options={dateOptions}
              defaultValue={calendarView}
              padding={"10px 14px"}
              textLeft={true}
              className="w-2/3 z-50 mr-4"
              onChange={(option) => setCalendarView(option)}
            />
            <Button
              className="w-1/3 flex items-center"
              flat
              small
              onClick={() => setSelectedDateRange(new Date())}
            >
              <CalendarIcon />
              <span className="ml-1">Today</span>
            </Button>
          </div>
          <div className="mt-4 text-left">
            <DatePicker
              inline={true}
              selected={selectedDateRange}
              onSelect={(selected) => setSelectedDateRange(selected)}
              highlightDates={[
                {
                  "react-datepicker__day--point-highlight": dates
                },
              ]}
            />
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row items-center mt-6">
              <div className="w-4 h-4 rounded-full bg-cyan-500 mr-2"></div>
              <p className="text-cyan-500 text-sm font-semibold">
                Events You're Attending
              </p>
            </div>
            <div className="flex flex-row items-center mt-6">
              <div className="w-4 h-4 rounded-full bg-primary-500 mr-2"></div>
              <p className="text-primary-500 text-sm font-semibold">
                Events You're Hosting
              </p>
            </div>
          </div>
        </div>
        <div className="w-full ml-12 md:max-h-auto max-h-[calc(100vh-170px)]">
          <DashboardCalendar
            calendarView={calendarView}
            selectedDateRange={selectedDateRange}
            onLoaded={(eventDates) => setDates(eventDates)}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getEvents,
  getEventDetail,
  getSessions,
  // educatorCancelCourseScheduled,
})(MyCalendar);
