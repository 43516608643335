import CenterContainer from "components/common/Container/Center";
import Button from "./Button";

export default function JoinCTA() {
  return (
    <CenterContainer className="py-24 md:py-12">
      <div className="bg-blue-800 text-center rounded-2xl p-16 lg:px-4">
        <h3 className="text-white text-4xl md:text-3xl mb-5 md:mb-4">
          Join DingDingNow today.
        </h3>
        <p className="text-dgray-100 text-xl md:text-lg mb-8">
          Become a member of growing learner community. Join us for free, and
          one week of free trial as a speaker for all new users!
        </p>
        <div className="flex justify-center gap-3 md:flex-col-reverse">
          <Button
            className="bg-white !text-dgray-700 md:w-full"
            to="/become-a-host"
          >
            Become a Host
          </Button>
          <Button className="px-12 md:block" to="/register">
            Sign Up
          </Button>
        </div>
      </div>
    </CenterContainer>
  );
}
