import { getEventDetailState } from "actions/front/mentorDashboard";
import { ReactComponent as GlobeIcon } from "assets/images/icons/globe.svg";
import Button from "components/common/Button";
import LargeModal from "components/common/Modal/LargeModal";
import { ATTENDEE_ROLE, SESSION_TYPE } from "constants";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import getUserAvatar from "utils/getUserAvatar";
import { getTimeZone } from "utils/time";
import Spinner from "views/Spinner";
import moment from "moment";

function AttendeeListModal({
  getEventDetailState,
  isOpen,
  setIsOpen,
  eventId,
  state,
  eventType = 5,
  user,
  role,
  all,
  start_at,
  end_at,
}) {
  const tz = getTimeZone(user);
  const [isLoading, setIsLoading] = useState(true);
  const [speakers, setSpeakers] = useState([]);
  const [audience, setAudience] = useState([]);

  useEffect(() => {
    if (isOpen) {
      init();
    }
  }, [isOpen]);

  const init = async () => {
    const res = await getEventDetailState(eventId,state);
    // const res = await getEventDetail("654cb0396f5cf83d5c132c6f");
    if (res.status) {
      const attendees = res.response.map((item) => item.learner);
      const speakerList = [];
      const audienceList = [];
      attendees.forEach((att, i) => {
        if (att.role === ATTENDEE_ROLE.SPEAKER) speakerList.push(att);
        else audienceList.push(att);
      });
      setAudience(audienceList);
      setSpeakers(speakerList);
    }
    setIsLoading(false);
  };

  return (
    <LargeModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <h2 className="text-2xl font-semibold text-center mb-2">Attendees</h2>
      {isLoading ? (
        <Spinner />
      ) : eventType === SESSION_TYPE.LIVE_EVENT ? (
        <>
          <div className="flex justify-between md:flex-col gap-6 w-3/4 mx-auto lg:w-full py-10">
            {role == 1 ? (
              <div className="flex-1 md:mb-10">
                <div className="font-medium text-center text-dgray-800 mb-4">
                  Listeners
                </div>
                <ul className="space-y-4 overflow-y-scroll max-h-72">
                  {audience?.length === 0 ? (
                    <div className="text-dgray-500 text-center text-sm">
                      No listeners found
                    </div>
                  ) : (
                    audience.map((att) => (
                      <div className="flex gap-2">
                        <img
                          src={getUserAvatar(att.avatar)}
                          alt="speaker avatar"
                          className="rounded-full w-10 h-10"
                        />
                        <div>
                          <div className="text-sm font-medium mb-1">
                            {att.first_name} {att.last_name}
                          </div>
                          <div className="text-xs text-dgray-500">
                            <GlobeIcon className="inline mr-1 align-top" />
                            {att?.country || att?.city}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </ul>
              </div>
            ) : (
              <div className="flex-1 md:mb-10">
                <div className="font-medium text-center text-dgray-800 mb-4">
                  Speakers
                </div>
                <ul className="space-y-4 overflow-y-scroll max-h-72">
                  {speakers?.length === 0 ? (
                    <div className="text-dgray-500 text-center text-sm">
                      No speakers found
                    </div>
                  ) : (
                    speakers.map((att) => (
                      <div className="flex gap-2">
                        <img
                          src={getUserAvatar(att.avatar)}
                          alt="speaker member avatar"
                          className="rounded-full w-10 h-10"
                        />
                        <div>
                          <div className="text-sm font-medium mb-1">
                            {att.first_name} {att.last_name}
                          </div>
                          <div className="text-xs text-dgray-500">
                            <GlobeIcon className="inline mr-1 align-top" />
                            {att?.country || att?.city}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </ul>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="flex w-3/5 mx-auto lg:w-full py-10">
            <div className="flex-1 flex justify-between md:flex-col gap-6">
              <div className="flex-1 md:mb-10">
                {audience?.length === 0 ? (
                  <div className="text-dgray-500 text-center text-sm">
                    No students found.
                  </div>
                ) : (
                  audience.map((att) => (
                    <div className="flex-1 flex w-full justify-between items-center mb-4">
                      <div className="flex gap-2">
                        {/* {moment.unix(startAt).format("MMM D, h:mm A")} */}
                        {moment
                          .unix(att.session_data.start_at)
                          .tz(tz)
                          .format("MMM D, h:mm A")}{" "}
                        -
                        {moment
                          .unix(att.session_data.end_at)
                          .tz(tz)
                          .format("h:mm A")}
                      </div>
                      <div className="flex gap-2">
                        <img
                          src={getUserAvatar(att.avatar)}
                          alt="listener member avatar"
                          className="rounded-full w-10 h-10"
                        />
                        <div>
                          <div className="text-sm font-medium mb-1">
                            {att.first_name} {att.last_name}
                          </div>
                          <div className="text-xs text-dgray-500">
                            <GlobeIcon className="inline mr-1 align-top" />
                            {att?.country || att?.city}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="text-center">
        <Button
          type="button"
          className="w-1/2 md:w-full"
          onClick={() => setIsOpen(false)}
          small
          secondary
        >
          Close
        </Button>
      </div>
    </LargeModal>
  );
}

export default connect(({ auth }) => ({ user: auth.user }), { getEventDetailState })(
  AttendeeListModal
);
