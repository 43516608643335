import { update, updateUserState } from "actions/front/myaccount";
import Button from "components/common/Button";
import SelectInput from "components/common/form/SelectInput";
import TierInfoModal from "components/common/Modal/TierInfoModal";
import {
  HOST_TIER,
  PROFICIENCY_DESCRIPTION,
  PROFICIENCY_LEVELS,
} from "constants";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { isUserHost } from "utils/isUserHost";
import DashboardCard from "./DashboardCars";

function DashboardUserTier({ user, update }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedValue, setUpdatedValue] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUpdating) setSelectedValue(null);
  }, [isUpdating]);

  const updateTier = () => {
    setIsLoading(true);
    update({ proficiency_level: selectedValue })
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          setIsModalOpen(false);
        }
        dispatch(updateUserState("proficiency_level", selectedValue));
        setIsUpdating(false);
        setUpdatedValue(selectedValue);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <TierInfoModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        isForHosts={isUserHost(user)}
      />
      <DashboardCard>
        <div className="text-primary-600 text-lg font-medium mb-2">
          {isUserHost(user) ? "Host Tier" : "English Proficiency Level"}
        </div>
        <div className="flex justify-between gap-4 md:flex-col">
          {isUpdating ? (
            <div className="w-1/2 md:w-full">
              <SelectInput
                options={PROFICIENCY_LEVELS}
                className="w-full"
                defaultValue={user.proficiency_level}
                onChange={(opt) => setSelectedValue(opt.value)}
              />
            </div>
          ) : (
            <div>
              <div className="text-3xl font-semibold mb-2">
                {isUserHost(user)
                  ? HOST_TIER[user.host_tier]?.label
                  : PROFICIENCY_LEVELS.find(
                      (lvl) =>
                        lvl.value === (updatedValue || user.proficiency_level)
                    )?.label}
              </div>
              <p className="text-dgray-500">
                {isUserHost(user)
                  ? user.host_tier
                    ? HOST_TIER[user.host_tier]?.description
                    : "Host tier not found"
                  : PROFICIENCY_DESCRIPTION[
                      updatedValue || user.proficiency_level
                    ]}
              </p>
            </div>
          )}
          <div>
            {isUpdating ? (
              <div className="flex items-center gap-2">
                <Button
                  disabled={isLoading}
                  onClick={() => setIsUpdating(false)}
                  flat
                  small
                >
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  onClick={updateTier}
                  secondary
                  small
                >
                  Confirm
                </Button>
              </div>
            ) : (
              !isUserHost(user) && (
                <Button onClick={() => setIsUpdating(true)} secondary small>
                  Update
                </Button>
              )
            )}
          </div>
        </div>
      </DashboardCard>
    </>
  );
}

export default connect((state) => ({ user: state.auth.user }), { update })(
  DashboardUserTier
);
