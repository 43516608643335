import axios from "utils/axios";
import {
  COURSE_LOADING,
  COURSE_LIST_UPDATED,
  COURSE_FAIL,
  COURSE_CHANGE_STATUS,
  COURSE_CHANGE_STATUS_ERROR,
  COURSE_DELETED,
} from "actions/types";

  export const getFullCoursesList = async (count) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(`/api/admin/courses?limit=${count}`, config);
      return res.data.response[0];
    } catch (err) {
      console.log(err);
    }
  };

//get courses list

export const getCoursesList = (userParams) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const query = userParams.query ? userParams.query : "&query=";
    const res = await axios.get(
      `/api/admin/courses?limit=${userParams.limit}&page=${userParams.page}&orderBy=${userParams.orderBy}&ascending=${userParams.ascending}${query}`,
      config
    );
    dispatch({
      type: COURSE_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: "success",
        data: res.data.response[0],
      },
    });
    return res.data.response[0];
  } catch (err) {
    // console.log(err);
    dispatch({
      type: COURSE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (course_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/admin/courses/change-status/${course_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: COURSE_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: COURSE_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          data: course_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: COURSE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

// get Course by id
export const getCourseById = (course_id) => async (
  dispatch
) => {
  try {
    dispatch({
      type: COURSE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/admin/courses/${course_id}`,
      config
    );
    return res.data;
  } catch (err) {
    dispatch({
      type: COURSE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

export const deleteCourse = (course_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.delete(`/api/educator/course/${course_id}`, config);
    if (res.data.status === true) {
      await dispatch({
        type: COURSE_DELETED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: COURSE_FAIL,
        payload: {
          message: res.data.message,
          alertType: "danger",
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: COURSE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};
