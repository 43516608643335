import FileDropzone from "../FileDropzone";

export default function ImageDropzone({ onDrop, className = "", name }) {
  // TODO: validate image dimensions

  return (
    <FileDropzone
      onDrop={onDrop}
      accept={{ "image/png": [".png", ".jpg", ".jpeg", ".svg", ".gif"] }}
      maxFiles={1}
      description="SVG, PNG, JPG or GIF (max. 800x400px)"
      className={className}
      name={name}
    />
  );
}

// const checkImgDimensions = (imageEl, maxWidth = 800, maxHeight = 400) => {
//   if (!imageEl) return false;
//   return imageEl.width <= maxWidth && imageEl.height <= maxHeight;
// };
