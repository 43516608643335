import React from 'react'
import Button from "components/common/form/Button";
import arrowLeft from "assets/images/icons/arrow-left.svg";
import emailIcon from "assets/images/email-icon.svg";


export default function VerificationEmail() {
    return (
    <div className="flex flex-col p-20 md:p-10 md:px-10 mx-10 md:mx-30 sm:mx-0 lg:mx-0 justify-center items-center sm:mt-0 mt-0 md:mt-10 xl:mt-20">
      <img src={emailIcon} alt="Email Icon"/> 
      <div className="text-center mt-5">
        <div className="text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-dgray-900 font-semibold">Check your email</div>
        <div className="text-base md:text-base lg:text-lg xl:text-2xl text-dgray-500 mt-5 lg:mt-5 xl:mt-10 w-3/5 md:w-full ml-32 md:ml-0">We sent a verification link to your given email address.</div>
      </div>
      <div className="mt-16 text-dgray-500 text-sm">
        <label>
            Didn’t receive the email? <Button className="text-primary-700">Click to resend</Button>
        </label>
      </div>
      <div className="text-center flex flex-row text-dgray-500 text-sm md:text-sm lg:text-base xl:text-xl mt-8 md:mt-8 xl:mt-10">
        <img src={arrowLeft} alt="Arrow left" className="mr-3"/> 
        <a href={"/login"}> Back to log in </a>
      </div>
    </div>
    )
}
