import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Input, FormGroup } from "reactstrap";

const CustomFilters = (props) => {
  let query;

  const initialFilter = {
    query: "",
  };

  const [filter, setFilter] = useState(initialFilter);

  const handleClick = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
    let tempFilters = { ...filter, [e.target.name]: e.target.value };

    let final_query = "";
    Object.keys(tempFilters).map(function (key) {
      final_query = `${final_query}&${key}=${tempFilters[key]}`;
    });
    props.onSearch(final_query);
  };

  return (
    <div>
      <div className="reset_bt">
        <h6>Filters</h6>
        <div className="row">
          <div className="col-12">
            <FormGroup>
              <Input
                className="form-control"
                innerRef={(n) => (query = n)}
                type="text"
                placeholder="Search"
                name="query"
                id="query"
                onChange={(e) => handleClick(e)}
              />
            </FormGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(withRouter(CustomFilters));
