import { getAllCourses } from "actions/front/common/course";
import { follow, getFollowingList } from "actions/front/dingdong";
import { getMycourseSessions } from "actions/front/learner/courses";
import { getUpcomingSessions } from "actions/front/educator/courses";
import pencilIcon from "assets/images/icons/pencil-icon.svg";
import dotsIcon from "assets/images/icons/three-dots.svg";
import Calendar from "components/calendar";
import Button from "components/common/Button";
import getTimezoneOffset from "components/common/TimezoneToGMT";
import DashboardCalendar from "components/dashboard/DashboardCalendar";
import DashboardCalendarToolbar from "components/dashboard/DashboardCalendarToolbar";
import EmptyUpcomingEventCardLarge from "components/event/EmptyUpcomingEventCardLarge";
import UpcomingEventCardLarge from "components/event/UpcomingEventCardLarge";
import { COURSE_LIST } from "constants";
import DashboardHeader from "components/common/dashboard/Header";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Spinner } from "reactstrap";
import getUserAvatar from "utils/getUserAvatar";
import { getCurrentTimeZone } from "utils/time";
import { loadUserLastInfo } from "actions/auth";

const UserDashboard = ({ getMycourseSessions, user,loadUserLastInfo,setOnlyId,onlyId }) => {
  const [statistics, setStatistics] = useState({ loading: true });
  const [coursesList, setCoursesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [followingList, setFollowingList] = useState([]);
  const [featuredEvent, setFeaturedEvent] = useState();

  const [calendarView, setCalendarView] = useState(dateOptions[0]);
  const [selectedDateRange, setSelectedDateRange] = useState(new Date());
  let timeZone = getCurrentTimeZone(user);
  let gmt = getTimezoneOffset(timeZone);

  const onStatisticsLoad = (data) => {
    setStatistics(data);
    console.log("stats", { data });
  };

  const [filters, setFilters] = useState({
    limit: 3,
    page: 1,
    orderBy: "start_at",
    ascending: "desc",
    query: "",
    custom_filters: "", //start_date //end_date
  });

  useEffect(() => {
    console.log("========================userDashboard");
    loadUserLastInfo()
    setLoading(true);
    if (!loading) {
      Promise.all([
        getAllCourses({ ...filters }),
        getUpcomingSessions({
          limit: 1,
          page: 1,
          orderBy: "start_at",
          ascending: "asc",
          query: "",
          custom_filters: "",
        }),
        getMycourseSessions({ session_type: COURSE_LIST.UPCOMING, page: 1 }),
        getMycourseSessions({ session_type: COURSE_LIST.COMPLETED, page: 1 }),
        getFollowingList(),
      ]).then((res) => {
        const [
          allCoursesResponse,
          upcomingSessionResponse,
          upcomingSessionsResponse,
          coursesListResponse,
          followingListResponse,
        ] = res;
        if (allCoursesResponse.status) {
        }
        if (
          upcomingSessionResponse.status &&
          upcomingSessionResponse.data.data.length > 0
        ) {
          console.log(upcomingSessionResponse.data.data[0],"upcomingSessionResponse.data.data[0]")
          setFeaturedEvent(upcomingSessionResponse.data.data[0]);
        }
        if (coursesListResponse.status) {
          setCoursesList(coursesListResponse.data.data);
        }
        if (followingList) {
          console.log("followingList", followingList);
          setFollowingList(followingList);
        }
        console.log("responses", { res });
        setLoading(false);
      });
    }
  }, []);

  const refetch = async () => {
    getUpcomingSessions({
      limit: 1,
      page: 1,
      orderBy: "start_at",
      ascending: "asc",
      query: "",
      custom_filters: "",
    }).then(upcomingSessionResponse => {
      if (
        upcomingSessionResponse.status &&
        upcomingSessionResponse.data.data.length > 0
      ) {
        setFeaturedEvent(upcomingSessionResponse.data.data[0]);
      } else {
        setFeaturedEvent(null);
      }

    })

  }

  return (
    <div className="">
      <div className="md:w-full">
        <>
          <div className="md:w-full py-8 md:p-0">
            {statistics.loading ? (
              <Spinner />
            ) : statistics.data ? (
              <>
                <DashboardHeader title={`Welcome back, ${user.first_name}!`} />
                <div className="flex flex-col mt-6">
                  <div className="flex flex-row md:flex-col">
                    <div className="w-3/5 h-90 md:w-full">
                      {featuredEvent ? (
                        <UpcomingEventCardLarge
                          featuredEvent={featuredEvent}
                          statistics={statistics}
                          gmt={gmt}
                          timeZone={timeZone}
                          refetch={refetch}
                          setOnlyId={setOnlyId}
                        />
                      ) : (
                        <EmptyUpcomingEventCardLarge />
                      )}
                    </div>
                    <div className="rounded-lg shadow border h-90 w-2/5 md:w-full md:ml-0 ml-3 relative bg-white md:mt-8">
                      <div className="flex flex-row justify-between p-3 mt-2">
                        <div className="flex flex-row">
                          <label className="text-base text-dgray-900 font-medium">
                            My Learning History
                          </label>
                        </div>
                        <img src={dotsIcon} alt="Dots Icon" className="mr-3" />
                      </div>
                      <div className="pb-8">
                        {coursesList.reverse().map(
                          (course, i) =>
                            i < 3 && (
                              <>
                                <div className="flex justify-between px-4 pt-4 pb-2">
                                  <div className="text-sm text-dgray-900 font-medium">
                                    {course.course_name}
                                  </div>
                                  <div>
                                    <div className="text-xs text-dgray-500">
                                      {moment
                                        .unix(course.start_at)
                                        .format("MMM D, Y")}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex justify-between px-4">
                                  <div className="flex gap-2.5">
                                    <img
                                      src={getUserAvatar(course.mentor_avatar)}
                                      alt=""
                                      className="rounded-full w-8 h-8"
                                    />
                                    <div className="">
                                      <div className="text-xs text-dgray-500 md:hidden">
                                        hosted by
                                      </div>
                                      <div className="text-sm font-medium">
                                        {course.mentor_name}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                        )}
                      </div>
                      <div className="border-t text-end p-4 font-medium text-primary-700 text-sm absolute md:relative inset-x-0 bottom-0">
                        <Link to={`/dashboard/journey`}>See All</Link>
                      </div>
                    </div>
                  </div>
                  <div className="flex md:flex-col gap-3">
                    <div className="w-2/5 md:w-full rounded-lg shadow border h-96 mt-3 relative bg-white">
                      <div className="p-3 mt-2">
                        <div className="text-base text-dgray-900 font-medium">
                          Your Favorite Hosts
                        </div>
                        <div className="text-sm text-dgray-500 font-medium py-3">
                          Hosts you most interacted with
                        </div>
                        {followingList.map((user) => (
                          <div className="flex gap-2.5 p-1">
                            <img
                              src={getUserAvatar(user.avatar)}
                              alt=""
                              className="rounded-full w-8 h-8"
                            />
                            <div className="w-full">
                              <div>
                                <div className="flex justify-between items-center">
                                  <Link
                                    to={`/hosts/${user.username}`}
                                    className="text-sm font-medium text-dgray-700"
                                  >
                                    {user.fullName}
                                  </Link>
                                  {user.courses > 0 ? (
                                    <div className="w-2.5 h-2.5 rounded-full bg-primary-600" />
                                  ) : null}
                                </div>
                              </div>
                              <div className="text-sm text-dgray-500">
                                {user.courses} upcoming sessions
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="border-t text-end p-4 font-medium text-primary-700 text-sm absolute inset-x-0 bottom-0">
                          <Link to={`hosts`} className="">
                            See All Updates
                          </Link>
                        </div>
                        <span className="w-2.5 h-2.5 rounded-full bg-primary-600" />
                      </div>
                    </div>
                    <div className="w-full rounded-lg shadow border h-96 mt-3 relative bg-white">
                      <DashboardCalendar
                        calendarView={calendarView}
                        selectedDateRange={selectedDateRange}
                        Toolbar={DashboardCalendarToolbar}
                        refetch={refetch}
                        onlyId={onlyId}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <div className="col-md-12">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="calendar"
                  role="tabpanel"
                  aria-labelledby="calendar-tab"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <Calendar  onStatisticsLoad={onStatisticsLoad} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getMycourseSessions,
  loadUserLastInfo
})(withRouter(UserDashboard));

const dateOptions = [
  {
    label: "Week",
    value: "week",
  },
  {
    label: "Month",
    value: "month",
  },
];
