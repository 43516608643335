import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "actions/auth";
import { getCartCount } from "actions/front/myaccount";
import { getCategories, getSubCategories } from "actions/front/category";
import { getNotification } from "actions/front/common/notification";
import defaultImage from "assets/img/avatars/user.jpg";
import { ReactComponent as ShoppingCart } from "assets/images/icons/shopping-cart.svg";
import ddnLogoDark from "assets/images/ddn_logo_dark.svg";
import ddnLogoLight from "assets/images/ddn_logo_light.svg";
import MobileMenu from "./MobileMenu";
import NotificationDropdown from "components/NotificationDropdown";

const Header = ({
  auth: { isAuthenticated, loading, user },
  logout,
  history,
  notificationDetails,
  location,
  getNotification,
  getCartCount,
  cart_count_loading,
  cart_count,
  getCategories,
  category_list,
  className = "",
  light,
}) => {
  const filters = {
    limit: "",
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    custom_filters: "",
  };

  const [notificationCount, setNotificationCount] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);

  const setHover = (categoryIndex) => {
    setCurrentCategory(categoryIndex);
  };
  useEffect(() => {
    if (user?.login_role) {
      let count = [];
      if (notificationDetails) {
        notificationDetails.map((item) =>
          item.readBy.includes(user._id) ? "" : count.push("notify")
        );
        setNotificationCount(count.length);
      }
    }
  }, [notificationDetails, user]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    getCartCount();
  }, [user]);

  const onLogoutClick = (e) => {
    logout(history);
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [isExploreOpen, setExploreOpen] = useState(false);
  const toggleExplore = () => setExploreOpen(!isExploreOpen);

  const [isOpenNotif, setOpenNotif] = useState(false);

  return (
    <>
      {isOpenNotif && (
        <div
          className="fixed inset-0 bg-dgray-700/30 z-20"
          onClick={() => setOpenNotif(false)}
        />
      )}
      <div
        className={`relative z-30 px-40 xl:px-20 lg:px-10 md:px-4 py-5 whitespace-nowrap
        ${light ? "text-white" : "text-dgray-600"}
        ${className}
      `}
      >
        <header className="my-6 lg:my-3 md:my-0 flex justify-between">
          <div className="flex">
            <div className="flex items-center flex-shrink-0 mr-6 md:mr-0">
              <Link to="/">
                <img
                  src={light ? ddnLogoLight : ddnLogoDark}
                  alt="DDN Logo"
                  style={{ width: "200px" }}
                />
              </Link>
            </div>

            <ul className="flex items-center lg:hidden">
              <li className="ml-8">
                <Link to={"/"}>Overview</Link>
              </li>
              <li className="ml-8">
                <Link to={"/events"}>Events</Link>
              </li>
              <li className="ml-8">
                <Link to={"/hosts"}>Hosts</Link>
              </li>
              <li className="ml-8">
                <Link to="/blogs">DDN Blog</Link>
              </li>
            </ul>
          </div>
          {isAuthenticated ? (
            <MenuAuth {...{ isOpenNotif, setOpenNotif, light, cart_count }} />
          ) : (
            <MenuNoAuth light={light} />
          )}
          <MobileMenu />
        </header>
      </div>
    </>
  );
};

function MenuAuth({ isOpenNotif, setOpenNotif, light, cart_count }) {
  return (
    <ul
      className={`flex items-center lg:hidden
        ${light ? "text-white" : "text-primary-700"}
      `}
    >
      <NotificationDropdown
        isOpen={isOpenNotif}
        setOpen={setOpenNotif}
        light={light}
      />
      <Link
        to={"/cart"}
        className="relative align-text-top mr-8"
      >
        {cart_count > 0 && (
          <div className="absolute -top-0.5 -right-1 w-1.5 h-1.5 bg-primary-600 rounded-full" />
        )}
        <ShoppingCart />
      </Link>
      {/*
      <Link to="/feed" className="font-medium mr-8">
        My Feed
      </Link>
      */}
      <Link
        className="bg-primary-600 text-white px-5 py-3 rounded-lg whitespace-nowrap"
        to="/dashboard"
      >
        My DDN
      </Link>
    </ul>
  );
}

function MenuNoAuth({ light }) {
  return (
    <ul className="flex flex-row items-center lg:hidden">
      <Link
        to="/login"
        className={`font-medium mr-8 ml-2
          ${light ? "text-white" : "text-primary-700"}
        `}
      >
        Sign In
      </Link>

      <Link
        className="bg-primary-600 text-white px-5 py-2.5 rounded-lg whitespace-nowrap"
        to="/register"
      >
        Sign up
      </Link>
    </ul>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  notificationDetails: state.notification.notificationDetails,
  cart_count_loading: state.auth.cart_count_loading,
  cart_count: state.auth.cart_count,
  category_list: state.categories,
});

export default connect(mapStateToProps, {
  logout,
  getNotification,
  getCartCount,
  getCategories,
})(Header);
