import { follow, unFollow } from "actions/front/dingdong";
import FollowToast from "components/Toast/Follow";
import SignInToast, { signinToastOptions } from "components/Toast/SignIn";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function useFollowHost(host) {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector(({ auth }) => ({
    user: auth.user,
    isAuthenticated: auth.isAuthenticated,
  }));

  const [isLoadingFollow, setLoadingFollow] = useState(false);
  const [isFollowing, setFollowing] = useState(false);

  useEffect(() => {
    setFollowing(checkFollowing(user, host));
  }, [user, host]);

  const toggleFollow = async () => {
    if (isAuthenticated) {
      setLoadingFollow(true);
      if (isFollowing) {
        unfollowHost();
      } else {
        followHost();
      }
    } else {
      toast.error(<SignInToast />, signinToastOptions);
    }
  };

  const unfollowHost = async () => {
    await dispatch(unFollow(host._id));
    setFollowing(false);
  };

  const followHost = async () => {
    await dispatch(follow(host._id));
    setFollowing(true);
    toast(<FollowToast host={host} unfollow={unfollowHost} />);
  };

  return { isLoadingFollow, isFollowing, toggleFollow };
}

function checkFollowing(user, host) {
  if (!user || !host) return false;
  return (
    user &&
    user.following &&
    user.following.users &&
    user.following.users.findIndex((u) => u._id === host._id) > -1
  );
}
