import { ReactComponent as Home } from "assets/images/icons/home.svg";
import { Fragment } from "react";
import { Link } from "react-router-dom";

// crumb = { label: 'Example', href: '/example' }
export default function BreadCrumbs({ crumbs = [] }) {
  return (
    <ul className="flex gap-5 items-center">
      {crumbs.map((crumb, i) => (
        <Fragment key={i}>
          {i !== 0 && <span className="text-dgray-300 text-xl">/</span>}
          {i === 0 ? (
            <Crumb
              label={<Home />}
              href="/"
              highlight={i === crumbs.length - 1}
            />
          ) : (
            <Crumb {...crumb} highlight={i === crumbs.length - 1} />
          )}
        </Fragment>
      ))}
    </ul>
  );
}

function Crumb({ label, href, highlight }) {
  return (
    <li
      className={`text-sm font-medium ${
        highlight ? "text-primary-700" : "text-dgray-500"
      }`}
    >
      <Link to={href}>{label}</Link>
    </li>
  );
}
