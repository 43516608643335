import { ReactComponent as CheckCircle } from "assets/images/icons/check-circle.svg";
import Button from "components/common/Button";
import EventCardLarge from "components/event/EventCardLarge";
import { SESSION_TYPE } from "constants";
import { useFormikContext } from "formik";
import moment from "moment";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import IconCircle from "views/Front/HomePage/IconCircle";

function CreateEventPublicStep4({ user }) {
  const { values } = useFormikContext();
  const location = useLocation();
  const editId = new URLSearchParams(location.search).get("editId");

  return (
    <>
      <div className="text-center py-16 mt-24">
        <IconCircle color="green" className="mx-auto mb-5">
          <CheckCircle />
        </IconCircle>
        <h1 className="text-3xl md:text-2xl font-medium mb-2">
          You successfully {editId ? "edited" : "created"} your event!
        </h1>
        <p className="text-xl md:text-base text-dgray-500 mb-16 md:mb-8">
          Your event has been published! The event is added to your calendar.
        </p>
        <EventCardLarge
          isPreview
          data={{
            course_image: values.image
              ? typeof values.image === "string"
                ? values.image
                : URL.createObjectURL(values.image)
              : "",
            category_id: values.associatedProgram,
            course_type: SESSION_TYPE.LIVE_EVENT,
            course_name: values.title,
            course_overview: values.description,
            date: moment(new Date(values.date))
              .set("hour", values.time.split(":")[0])
              .set("minute", values.time.split(":")[1]),

            mentor_avatar: user.avatar,
            mentor_name: user.full_name,

            host: user,

            session_booking_count: "",
            timezone: user.timezone,
          }}
        />
      </div>
      <div className="flex justify-center gap-4 py-16 lg:py-10">
        <Button to="/" flat small>
          Go to home page
        </Button>
        <Button to="/dashboard/calendar" small>
          Go to your calendar
        </Button>
      </div>
    </>
  );
}

export default connect(({ auth }) => ({
  user: auth.user,
}))(CreateEventPublicStep4);
