//alert constants
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const UPDATE_CANCLE_PASS = "UPDATE_CANCLE_PASS"
//loading constant
export const INITIAL_LOADING = "INITIAL_LOADING";
export const LOADING_ON_SUBMIT = "LOADING_ON_SUBMIT";
export const LOADING_ON_CMS_SUBMIT = "LOADING_ON_CMS_SUBMIT";
export const LOADING_ON_EMAIL_SUBMIT = "LOADING_ON_EMAIL_SUBMIT";
export const LOADING_ON_INQUIRY_SUBMIT = "LOADING_ON_INQUIRY_SUBMIT";
export const LOADING_ON_SETTINGS_SUBMIT = "LOADING_ON_SETTINGS_SUBMIT";
export const LOADING_ON_PROFILE_SUBMIT = "LOADING_ON_PROFILE_SUBMIT";
export const LOADING_ON_USER_SUBMIT = "LOADING_ON_USER_SUBMIT";
export const LOADING_ON_CHANGING_PASSWORD = "LOADING_ON_CHANGING_PASSWORD";

//errors constnats
export const SET_ERRORS_LIST = "SET_ERRORS_LIST";
export const REMOVE_ERRORS = "REMOVE_ERRORS";
export const SET_FRONT_ERRORS = "SET_FRONT_ERRORS";

//login/logout/register, auth, profile constnats
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const ACCOUNT_DELETED = "ACCOUNT_DELETED";

export const GET_PROFILE = "GET_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const USER_LOADED = "USER_LOADED";
export const NEW_USER_LOADED = "NEW_USER_LOADED";
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_FAIL = "AUTH_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const AUTH_ERROR = "AUTH_ERROR";

//Forgot Password Constants
export const RESET_LINK_SUCCESS = "RESET_LINK_SUCCESS";
export const RESET_LINK_FAIL = "RESET_LINK_FAIL";
export const RESET_LINK_GET_DETAIL_SUCCESS = "RESET_LINK_GET_DETAIL_SUCCESS";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const CHECK_LINK_SUCCESS = "CHECK_LINK_SUCCESS";
export const CHECK_LINK_ERROR = "CHECK_LINK_ERROR";

//google login
export const GOOGLE_SIGN_IN_SUCCESS = "GOOGLE_SIGN_IN_SUCCESS";
export const GOOGLE_SIGN_IN_FAIL = "GOOGLE_SIGN_IN_FAIL";

//CourseCategory constants
export const COURSE_CATEGORY_LOADING = "COURSE_CATEGORY_LOADING";
export const COURSE_CATEGORY_LIST_UPDATED = "COURSE_CATEGORY_LIST_UPDATED";
export const COURSE_CATEGORY_FAIL = "COURSE_CATEGORY_FAIL";
export const COURSE_CATEGORY_CREATED = "COURSE_CATEGORY_CREATED";
export const COURSE_CATEGORY_CREATE_ERROR = "COURSE_CATEGORY_CREATE_ERROR";
export const COURSE_CATEGORY_UPDATED = "COURSE_CATEGORY_UPDATED";
export const COURSE_CATEGORY_UPDATE_ERROR = "COURSE_CATEGORY_UPDATE_ERROR";
export const COURSE_CATEGORY_DELETED = "COURSE_CATEGORY_DELETED";
export const COURSE_CATEGORY_DELETE_ERROR = "COURSE_CATEGORY_DELETE_ERROR";
export const COURSE_CATEGORY_CHANGE_STATUS = "COURSE_CATEGORY_CHANGE_STATUS";
export const COURSE_CATEGORY_CHANGE_STATUS_ERROR =
  "COURSE_CATEGORY_CHANGE_STATUS_ERROR";
export const GET_COURSE_CATEGORY_BY_ID = "GET_COURSE_CATEGORY_BY_ID";

//CourseSubCategory constants
export const COURSE_SUBCATEGORY_LOADING = "COURSE_SUBCATEGORY_LOADING";
export const COURSE_SUBCATEGORY_LIST_UPDATED =
  "COURSE_SUBCATEGORY_LIST_UPDATED";
export const COURSE_SUBCATEGORY_FAIL = "COURSE_SUBCATEGORY_FAIL";
export const COURSE_SUBCATEGORY_CREATED = "COURSE_SUBCATEGORY_CREATED";
export const COURSE_SUBCATEGORY_CREATE_ERROR =
  "COURSE_SUBCATEGORY_CREATE_ERROR";
export const COURSE_SUBCATEGORY_UPDATED = "COURSE_SUBCATEGORY_UPDATED";
export const COURSE_SUBCATEGORY_UPDATE_ERROR =
  "COURSE_SUBCATEGORY_UPDATE_ERROR";
export const COURSE_SUBCATEGORY_DELETED = "COURSE_SUBCATEGORY_DELETED";
export const COURSE_SUBCATEGORY_DELETE_ERROR =
  "COURSE_SUBCATEGORY_DELETE_ERROR";
export const COURSE_SUBCATEGORY_CHANGE_STATUS =
  "COURSE_SUBCATEGORY_CHANGE_STATUS";
export const COURSE_SUBCATEGORY_CHANGE_STATUS_ERROR =
  "COURSE_SUBCATEGORY_CHANGE_STATUS_ERROR";
export const GET_COURSE_SUBCATEGORY_BY_ID = "GET_COURSE_SUBCATEGORY_BY_ID";

//Educator constants
export const EDUCATOR_LOADING = "EDUCATOR_LOADING";
export const EDUCATOR_LIST_UPDATED = "EDUCATOR_LIST_UPDATED";
export const EDUCATOR_FAIL = "EDUCATOR_FAIL";
export const EDUCATOR_CHANGE_STATUS = "EDUCATOR_CHANGE_STATUS";
export const EDUCATOR_CHANGE_STATUS_ERROR = "EDUCATOR_CHANGE_STATUS_ERROR";

//Learner constants
export const LEARNER_LOADING = "LEARNER_LOADING";
export const LEARNER_LIST_UPDATED = "LEARNER_LIST_UPDATED";
export const LEARNER_FAIL = "LEARNER_FAIL";
export const LEARNER_CHANGE_STATUS = "LEARNER_CHANGE_STATUS";
export const LEARNER_CHANGE_STATUS_ERROR = "LEARNER_CHANGE_STATUS_ERROR";
export const LEARNER_DETAILS = "LEARNER_DETAILS";

//Course constants
export const COURSE_LOADING = "COURSE_LOADING";
export const COURSE_LIST_UPDATED = "COURSE_LIST_UPDATED";
export const COURSE_FAIL = "COURSE_FAIL";
export const COURSE_CHANGE_STATUS = "COURSE_CHANGE_STATUS";
export const COURSE_CHANGE_STATUS_ERROR = "COURSE_CHANGE_STATUS_ERROR";
export const GET_COURSE_BY_ID = "GET_COURSE_BY_ID";
export const COURSE_DELETED = "COURSE_DELETED";

//Users constants
export const USER_LOADING = "USER_LOADING";
export const USER_CREATED = "USER_CREATED";
export const USER_ERROR = "USER_CREATE_ERROR";
export const DELETE_USER = "DELETE_USER";
export const USER_UPDATED = "USER_UPDATED";
export const USER_LIST_UPDATED = "USER_LIST_UPDATED";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";
export const USER_SEARCH_PARAMATERS_UPDATE = "USER_SEARCH_PARAMATERS_UPDATE";
export const USER_PROFILE_PASSWORD_ERROR = "USER_PROFILE_PASSWORD_ERROR";
// export const USER_PROFILE_PASSWORD_UPDATED = "USER_PROFILE_PASSWORD_UPDATED";
export const USER_PROFILE_LOADED = "USER_PROFILE_LOADED";

//Email Template Constants
export const EMAIL_TEMPLATE_CREATED = "EMAIL_TEMPLATE_CREATED";
export const EMAIL_TEMPLATE_ERROR = "EMAIL_TEMPLATE_ERROR";
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";
export const EMAIL_TEMPLATE_UPDATED = "EMAIL_TEMPLATE_UPDATED";
export const EMAIL_TEMPLATE_LIST_UPDATED = "EMAIL_TEMPLATE_LIST_UPDATED";
export const GET_EMAIL_TEMPLATE_BY_ID = "GET_EMAIL_TEMPLATE_BY_ID";
export const CHANGE_EMAIL_TEMPLATE_STATUS = "CHANGE_EMAIL_TEMPLATE_STATUS";
export const EMAIL_TEMPLATE_SEARCH_PARAMATERS_UPDATE =
  "EMAIL_TEMPLATE_SEARCH_PARAMATERS_UPDATE";

//CMS Constants
export const CMS_CREATED = "CMS_CREATED";
export const CMS_ERROR = "CMS_ERROR";
export const DELETE_CMS = "DELETE_CMS";
export const CMS_UPDATED = "CMS_UPDATED";
export const CMS_LIST_UPDATED = "CMS_LIST_UPDATED";
export const GET_CMS_BY_ID = "GET_CMS_BY_ID";
export const CHANGE_CMS_STATUS = "CHANGE_CMS_STATUS";
export const CMS_SEARCH_PARAMATERS_UPDATE = "CMS_SEARCH_PARAMATERS_UPDATE";

//Inquiry Constants
export const INQUIRY_CREATED = "INQUIRY_CREATED";
export const INQUIRY_ERROR = "INQUIRY_ERROR";
export const DELETE_INQUIRY = "DELETE_INQUIRY";
export const INQUIRY_UPDATED = "INQUIRY_UPDATED";
export const INQUIRY_LIST_UPDATED = "INQUIRY_LIST_UPDATED";
export const GET_INQUIRY_BY_ID = "GET_INQUIRY_BY_ID";
export const CHANGE_INQUIRY_STATUS = "CHANGE_INQUIRY_STATUS";
export const INQUIRY_SEARCH_PARAMATERS_UPDATE =
  "INQUIRY_SEARCH_PARAMATERS_UPDATE";

//Setting Constants
export const SETTING_ERROR = "SETTING_ERROR";
export const SETTING_UPDATED = "SETTING_UPDATED";
export const GET_SETTING = "GET_SETTING";

//Profile Constants
export const USER_PROFILE_LOADING = "USER_PROFILE_LOADING";
export const USER_PROFILE_UPDATED = "USER_PROFILE_UPDATED";
export const USER_PROFILE_UPDATE_ERROR = "USER_PROFILE_UPDATE_ERROR";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";
export const USER_PROFILE_PASSWORD_UPDATED = "USER_PROFILE_PASSWORD_UPDATED";
export const USER_PROFILE_PASSWORD_UPDATE_ERROR =
  "USER_PROFILE_PASSWORD_UPDATE_ERROR";
export const USER_PROFILE_MODAL_LOADING = "USER_PROFILE_MODAL_LOADING";
//My Account page constants

export const MYACCOUNT_LOADING = "MYACCOUNT_LOADING";
export const USER_MYACCOUNT_UPDATED = "USER_MYACCOUNT_UPDATED";
export const USER_MYACCOUNT_UPDATED_ERROR = "USER_MYACCOUNT_UPDATED_ERROR";
export const USER_MYACCOUNT_FAIL = "USER_MYACCOUNT_FAIL";
export const USER_MYACCOUNT = "USER_MYACCOUNT";
export const USER_MYACCOUNT_ERROR = "USER_MYACCOUNT_ERROR";
export const USER_PASSWORD_UPDATED = "USER_PASSWORD_UPDATED";
export const USER_PASSWORD_UPDATE_ERROR = "USER_PASSWORD_UPDATE_ERROR";
export const USER_IMAGE_LOADING = "USER_IMAGE_LOADING";
export const USER_IMAGE_UPDATE_UPDATED = "USER_IMAGE_UPDATE_UPDATED";
export const USER_IMAGE_UPDATE_ERROR = "USER_IMAGE_UPDATE_ERROR";
export const USER_IMAGE_UPDATE_FAIL = "USER_IMAGE_UPDATE_FAIL";

/*############################### Front Constants ################################*/
/******************************** Eduactor constants ******************************/
export const EDUCATOR_COURSE_LOADING = "EDUCATOR_COURSE_LOADING";
export const EDUCATOR_COURSE_CREATED = "EDUCATOR_COURSE_CREATED";
export const EDUCATOR_COURSE_CREATE_ERROR = "EDUCATOR_COURSE_CREATE_ERROR";
export const EDUCATOR_COURSE_FAIL = "EDUCATOR_COURSE_FAIL";
export const EDUCATOR_COURSE_EDITED = "EDUCATOR_COURSE_EDITED";
export const EDUCATOR_COURSE_EDIT_ERROR = "EDUCATOR_COURSE_EDIT_ERROR";

// Notifications Constants
export const NOTIFICATION_UPDATED = "NOTIFICATION_UPDATED";

export const ORDER_DETAILS = "ORDER_DETAILS";

//course rating Constants
export const FRONT_COURSE_RATING_FAIL = "FRONT_COURSE_RATING_FAIL";
export const FRONT_COURSE_RATING_CREATED = "FRONT_COURSE_RATING_CREATED";
export const FRONT_COURSE_RATING_CREATE_ERROR =
  "FRONT_COURSE_RATING_CREATE_ERROR";
export const FRONT_COURSE_RATING_LOADER = "FRONT_COURSE_RATING_LOADER";

//cart count constants
export const CART_COUNT_LOADING = "CART_COUNT_LOADING";
export const CART_COUNT_SUCCESS = "CART_COUNT_SUCCESS";
export const CART_COUNT_ERROR = "CART_COUNT_ERROR";
export const CART_COUNT_FAIL = "CART_COUNT_FAIL";

export const MEETING_LINK_GENERATED = "MEETING_LINK_GENERATED";

//log constants
export const LOG_ERROR = "LOG_ERROR";
export const LOG_LIST_UPDATED = "LOG_LIST_UPDATED";
export const LOADING_LOG_LIST_DATA = "LOADING_LOG_LIST_DATA";
export const LOG_LIST_COMPLETED = "LOG_LIST_COMPLETED";

export const HOST_APPLICATION_ERROR = "HOST_APPLICATION_ERROR";
export const HOST_APPLICATION_LIST_UPDATED = "HOST_APPLICATION_LIST_UPDATED";
export const LOADING_HOST_APPLICATION_LIST_DATA = "LOADING_HOST_APPLICATION_LIST_DATA";
export const HOST_APPLICATION_LIST_COMPLETED = "HOST_APPLICATION_LIST_COMPLETED";


//fetch category constants
export const FETCH_CATEGORIES_LOADING = "FETCH_CATEGORIES_LOADING";
export const FETCH_CATEGORIES_COMPLETED = "FETCH_CATEGORIES_COMPLETED";

//statistics
export const STATISTICS_LOADING = "STATISTICS_LOADING";
export const STATISTICS_SUCCESS = "STATISTICS_SUCCESS";
export const STATISTICS_FAILED = "STATISTICS_FAILED";

//blog
export const BLOG_CREATED = "BLOG_CREATED";
export const BLOG_CREATED_ERROR = "BLOG_CREATED_ERROR";
export const BLOG_LOADING = "BLOG_LOADING";

//path
export const PATH_CHANGE = "PATH_CHANGE";
export const PATH_INITIALIZE = "PATH_INITIALIZE";

export const USER_STATE_UPDATE = "USER_STATE_UPDATE";
