import React from "react";
import { Link } from "react-router-dom";

const NewCourseAdded = ({ details }) => {
 return (
    <div className="col-md-12">
      <div className="notification-box box">
        <div className="space-between">
          <a href="#" className="d-flex">
            <i className="fa fa-book"></i>
            <strong>
            <Link to={"/dashboard/session-management"}>New Session Added</Link>
            </strong>
          </a>
          {/* <small className="text-muted">3 sec ago</small> */}
        </div>
        <p>
          There is a new course - {details.course_name} added by{" "}
          {details.mentorName} you might want to check out...
        </p>
      </div>
    </div>
  );
};

export default NewCourseAdded;
