import React, { useState, useEffect,useMemo } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";
import { connect, useDispatch,useSelector } from "react-redux";
import { getCartCount } from "actions/front/myaccount";
import Swal from "sweetalert2";
import Footer from "views/Front/Layout/Footer";
import Header from "views/Front/Layout/Header";
import CenterContainer from "components/common/Container/Center";
import { loadUserLastInfo } from "actions/auth";

const PaymentStatus = ({ getCartCount,loadUserLastInfo,course_id }) => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  let [userState, setUserState] = useState(true);
  // 使用 useMemo 缓存用户信息
  // const memoizedUser = useMemo(() => user, [user]);
  useEffect(() => {
    console.log("=====result-close",course_id);
    if (!stripe) {
      return;
    }
    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(async ({ paymentIntent }) => {
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Success! Payment received.");
            getCartCount();
            // loadUserLastInfo();
            Swal.fire({
              title: "",
              text: "Success! Payment received.",
              icon: "success",
              confirmButtonText: "Close",
              closeOnConfirm: false,
            }).then((result) => {
              console.log("=====result-close",typeof course_id);
              if (result.value) {
                if([undefined,'undefined',""].includes(course_id)){
                  history.push("/dashboard");
                }else{
                  history.push(`/session/${course_id}`);
                }
              }
            });
            break;

          case "processing":
            setMessage(
              "Payment processing. We'll update you when payment is received."
            );
            break;

          case "requires_payment_method":
            history.push("/cart?status=retry");
            setMessage("Payment failed. Please try another payment method.");
            break;

          default:
            setMessage("Something went wrong.");
            break;
        }
      });
  }, [stripe, history, getCartCount]);
  return (
    <>
      <Header />
      <CenterContainer className="py-24 mx-4 md:py-16">
        <div className="h-24"></div>
      </CenterContainer>
      <Footer />
    </>
  );
};

export default connect(null, { getCartCount,loadUserLastInfo})(PaymentStatus);
