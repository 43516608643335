import {
  getAllCourses,
  getPrivateCourses,
  getLiveEventCourses,
  getFeaturedEventCourses,
} from "actions/front/common/course";
import arrowRight from "assets/images/icons/arrow-right-red.svg";
import arrowRightWhite from "assets/images/icons/arrow-right-white.svg";

import CenterContainer from "components/common/Container/Center";
import SectionTriangle from "components/common/SectionTriangle";
import EventCard from "components/event/EventCard";
import WeeklyEventCard from "components/event/WeeklyEventCard";
import Spinner from "views/Spinner";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Header from "views/Front/Layout/Header";
import Button from "../../../../components/common/Button";
import Footer from "views/Front/Layout/Footer";
import JoinCTA from "components/common/JoinCTA";
import { sortEventsByClosest } from "utils/course";

const filters = {
  limit: 3,
  page: 1,
  orderBy: "session_detail.session_group.all_session_with_recurring",
  ascending: "desc",
  query: "",
  custom_filters: "",
};

const List = () => {
  const [loading, setLoading] = useState(false);
  const [featured, setFeatured] = useState();
  const [events, setEvents] = useState();
  const [courses, setCourses] = useState();

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getFeaturedEventCourses(filters),
      getPrivateCourses(filters),
    ]).then(([featured, courses]) => {
      getLiveEventCourses({
        ...filters,
        not_in: featured.data.data.map((f) => f._id._id),
        limit: 5,
      }).then((res) => {
        setEvents(sortEventsByClosest(res.data.data));
        //setEvents(res.data.data);
      });
      setFeatured(sortEventsByClosest(featured.data.data));
      setCourses(sortEventsByClosest(courses?.data?.data));
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Header />
      <div className="mb-8 md:mb-8">
        <CenterContainer>
          <div className="text-center md:text-left pt-24 pb-12 xl:px-0 w-2/3 md:w-full md:px-4 mx-auto">
            <h1 className="text-dgray-900 text-5xl md:text-4xl font-semibold mb-6">
              Explore Public and One on One Events
            </h1>
            <p className="text-dgray-500 mb-12 text-xl md:text-lg">
              Always free to join Public Events as a Listener. Gain access to
              unlimited Public Events as a Speaker with a monthly Pass
              after your free trial!
            </p>
          </div>
        </CenterContainer>
        <div className="relative bg-primary-50 pt-4 pb-20 md:pb-10">
          <SectionTriangle />
          <h1 className="p-16 mt-12 text-center text-5xl md:text-3xl text-black font-semibold">
            Featured Public Group Events
          </h1>
          <CenterContainer>
            <div className="flex justify-center lg:flex-col gap-8 md:gap-4 mb-16 md:mb-8">
              {loading ? (
                <Spinner />
              ) : (
                featured &&
                featured.length > 0 &&
                featured.map((course, i) => <EventCard data={course} key={i} />)
              )}
            </div>
            <div className="flex flex-row justify-center">
              <Link
                to="/explore"
                className="bg-primary-600 text-white px-5 py-3 rounded-lg text-center inline-block"
              >
                <span className="text-lg mr-3">See All</span>
                <img className="inline-block -mt-1" src={arrowRightWhite} />
              </Link>
            </div>
          </CenterContainer>
        </div>

        <div className="">
          <CenterContainer className="py-24 md:py-12">
            <div className="bg-dgray-200 text-center rounded-2xl p-16 lg:px-4">
              <h3 className="text-dgray-800 font-semibold text-4xl md:text-3xl mb-12 md:mb-4">
                Upcoming Public Group Events
              </h3>
              {loading ? (
                <Spinner />
              ) : (
                events &&
                events.length > 0 && (
                  <>
                    <div className="md:hidden block">
                      {splitArray(events).map((array, index) => (
                        <div className="w-full space-y-4" key={`pre_${index}`}>
                          {array.map((event, i) => (
                            <WeeklyEventCard data={event} key={i} />
                          ))}
                        </div>
                      ))}
                    </div>
                    <div className="hidden md:block">
                      {splitArray(events).map((array, index) => (
                        <div className="w-full space-y-4" key={`pre_${index}`}>
                          {array.map((event, i) => (
                            <EventCard data={event} key={i} />
                          ))}
                        </div>
                      ))}
                    </div>
                  </>
                )
              )}
            </div>
          </CenterContainer>
        </div>
        <CenterContainer>
          <h3 className="text-4xl md:text-3xl font-semibold md:text-center">
            One on One Private Events
          </h3>
          <div className="flex justify-center lg:flex-col gap-8 md:gap-4 mb-16 md:mb-8 mt-8 md:hidden">
            {loading ? (
              <Spinner />
            ) : (
              courses &&
              courses.length > 0 && (
                <Carousel
                  pagination
                  disableArrowsOnEnd={false}
                  itemsToShow={3}
                  showArrows={false}
                  enableMouseSwipe
                  itemPadding={[20, 20, 20, 20]}
                  transitionMs={800}
                  autoPlaySpeed={7000}
                  renderPagination={CarouselPagination}
                  breakPoints={[
                    { width: 0, itemsToShow: 1, itemsToScroll: 1 },
                    { width: 768, itemsToShow: 2, itemsToScroll: 1 },
                    { width: 1024, itemsToShow: 3, itemsToScroll: 1 },
                  ]}
                >
                  {courses.map((course, i) => (
                    <EventCard data={course} key={i} />
                  ))}
                </Carousel>
              )
            )}
          </div>
          <div className="hidden md:flex justify-center lg:flex-col gap-8 mb-16 md:mb-8 mt-8">
            {loading ? (
              <Spinner />
            ) : (
              courses &&
              courses.length > 0 &&
              courses.map((course, i) => <EventCard data={course} key={i} />)
            )}
          </div>
          <div className="text-center justify-center">
            <EventsListButton />
          </div>
        </CenterContainer>
        <JoinCTA />
      </div>
      <Footer />
    </>
  );
};

function EventsListButton() {
  return (
    <button className={`py-2.5 px-3.5 rounded-md mr-2 bg-primary-50`}>
      <Link
        to="/explore"
        className="flex flex-row text-lg md:text-base text-primary-700"
      >
        <span>See All Events</span>
        <img src={arrowRight} className="ml-3" />
      </Link>
    </button>
  );
}

function CarouselPagination({ activePage, onClick, pages }) {
  return (
    <ul className="flex gap-4 mt-10">
      {pages.map((page) => (
        <li>
          <button
            onClick={() => onClick(page)}
            className={`w-2.5 h-2.5 rounded-full ${
              activePage === page ? "bg-primary-600" : "bg-gray-500"
            }`}
          />
        </li>
      ))}
      <div />
    </ul>
  );
}

export default connect(null)(withRouter(List));

function splitArray(arr, perChunk = 3) {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}
