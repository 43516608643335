import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { edit, getSetting } from "actions/admin/setting";
import Spinner from "views/Spinner";
import Errors from "views/Admin/Layout/Errors";
import { REMOVE_ERRORS } from "actions/types";
import { menberProductList,UpdateMenberProductList } from "actions/menber";
import Moment from "react-moment";
import SettingsDialog from "./SettingsDialog";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const EditSettings = ({
  getSetting,
  edit,
  setting: { currentSetting, loading },
  history,
  errorList,
  match,
  menberProductList,
  UpdateMenberProductList
}) => {
  const dispatch = useDispatch();
  let initialData = {
    phone1: "",
    phone2: "",
    commission_rate: "",
    cancellation_maxhour: "",
    free_trial_duration: "",
    max_events_per_week: "",
  };
  const [formData, setFormData] = useState(initialData);
  const [isModalOpen, setModalOpen] = useState(false);
  const [menberList, setMenberList] = useState(null);
  const [menberId, setMenberId] = useState(null);
  const [menberPrice, setMenberPrice] = useState(0);
  let {
    phone1,
    phone2,
    commission_rate,
    cancellation_maxhour,
    setting_id,
    free_trial_duration,
    max_events_per_week,
  } = formData;

  const subscribe = async () => {
    let response = await menberProductList();
    console.log("====fetchmenberProducData", response);

    if (!response?.status) return;
    setMenberList(response?.data?.member_list);

    // console.log("setModalOpen", isModalOpen);
  };
  const updateSubscribe = async () => {
    let response = await UpdateMenberProductList(menberId,menberPrice);
    console.log("====fetchmenberProducData",);

    if (!response?.status) return    toast.error(<>Errorfully updated membership</>, { autoClose: 500 });
    console.log(response,"responseresponse")
    setModalOpen(false)
    subscribe()
    toast.success(<>Successfully updated membership</>, { autoClose: 500})
   
  };
  const dialog = (id,price) => {
    setModalOpen(true);
    setMenberId(id)
    setMenberPrice(price)
    
  };

  useEffect(() => {
    getSetting();
    subscribe();
  }, []);

  useEffect(() => {
    if (currentSetting != null && Object.keys(currentSetting).length > 0) {
      let setting = currentSetting;

      let newData = {
        ...initialData,
        setting_id: setting._id,
        phone1: setting.phone1,
        phone2: setting.phone2,
        commission_rate: setting.commission_rate,
        cancellation_maxhour: setting.cancellation_maxhour,
        free_trial_duration: setting.free_trial_duration,
        max_events_per_week: setting.max_events_per_week,
      };
      setFormData(newData);
    }
  }, [currentSetting]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    edit(formData, history);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="flex">
      <div className="animated fadeIn flex-1">
        <Row>
          <Col xs="12" sm="6">
            <Card>
              <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                <CardBody>
                  <h4>Admin settings</h4>
                  <br></br>
                  <FormGroup>
                    <Label htmlFor="phone1">Support Phone no 1*</Label>
                    <Input
                      type="text"
                      id="phone1"
                      name="phone1"
                      maxLength="100"
                      value={phone1}
                      placeholder="Enter Support Phone no"
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.phone1 ? true : false}
                    />
                    <Errors current_key="phone1" key="phone1" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="phone2">Support Phone no 2</Label>
                    <Input
                      type="text"
                      id="phone2"
                      name="phone2"
                      maxLength="100"
                      value={phone2}
                      placeholder="Enter Support Phone no"
                      //required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.phone2 ? true : false}
                    />
                    <Errors current_key="phone2" key="phone2" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="commission_rate">Commission Rate %*</Label>
                    <Input
                      type="text"
                      id="commission_rate"
                      name="commission_rate"
                      maxLength="10"
                      value={commission_rate}
                      placeholder="Enter Commission Rate in %"
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.commission_rate ? true : false}
                    />
                    <Errors
                      current_key="commission_rate"
                      key="commission_rate"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="cancellation_maxhour">
                      Session Cancellation Max Hour*
                    </Label>
                    <Input
                      type="text"
                      id="cancellation_maxhour"
                      name="cancellation_maxhour"
                      maxLength="10"
                      value={cancellation_maxhour}
                      placeholder="Enter Max Cancellation Hour"
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.cancellation_maxhour ? true : false}
                    />
                    <Errors
                      current_key="cancellation_maxhour"
                      key="cancellation_maxhour"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="free_trial_duration">
                      Free Trial Duration (days) *
                    </Label>
                    <Input
                      type="number"
                      id="free_trial_duration"
                      name="free_trial_duration"
                      maxLength="10"
                      value={free_trial_duration}
                      placeholder="Enter Free Trial Duration"
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.free_trial_duration ? true : false}
                    />
                    <Errors
                      current_key="free_trial_duration"
                      key="free_trial_duration"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="max_events_per_week">
                      Max Number of Events Per Week
                    </Label>
                    <Input
                      type="number"
                      id="max_events_per_week"
                      name="max_events_per_week"
                      maxLength="10"
                      value={max_events_per_week}
                      placeholder="Enter Max Number of Events Per Week"
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.max_events_per_week ? true : false}
                    />
                    <Errors
                      current_key="max_events_per_week"
                      key="max_events_per_week"
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">
                    <i className="fa fa-dot-circle-o"></i> Update
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
      <div >
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">title</th>
              <th scope="col">pass</th>
              <th scope="col">price</th>
              <th scope="col">Date Creatend</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {menberList &&
              menberList.map((item, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{item.title}</td>
                    <td>{item.pass}</td>
                    <td>{item.price}</td>
                    <td>{item.created_at}</td>
                    <td>
                      
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => dialog(item._id,item.price)}
                      >
                        <i className="fa fa-dot-circle-o"></i> Update
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {menberId  && (
          <SettingsDialog
            isOpen={isModalOpen}
            setOpen={setModalOpen}
            price={menberPrice}
            updateSubscribe={updateSubscribe}
            setMenberPrice={setMenberPrice}
          />
        )}
      </div>
    </div>
  );
};

EditSettings.propTypes = {
  getSetting: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  setting: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  setting: state.setting,
  errorList: state.errors.errorList,
  loading: state.setting.loading,
});

export default connect(mapStateToProps, {
  edit,
  getSetting,
  menberProductList,
  UpdateMenberProductList
})(EditSettings);
