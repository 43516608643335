import { getAllMyBlogs } from "actions/front/blog";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import BlogCard from "components/blog/BlogCard";
import Button from "components/common/Button";
import DashboardHeader from "components/common/dashboard/Header";
import TextInput from "components/common/form/TextInput";
import NotFoundText from "components/common/NotFoundText";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from "views/Spinner";

const initialFilters = {
  limit: 6,
  page: 1,
  orderBy: "created_at",
  ascending: "desc",
  query: "",
  custom_filters: "",
};

const BlogManagement = ({ getAllMyBlogs }) => {
  const [blogs, setBlogs] = useState();
  const [isLoading, setLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState();

  const fetchData = (f = {}) => {
    setLoading(true);
    getAllMyBlogs({
      ...filters,
      query,
      sortBy: sortBy?.value,
      ...f,
    }).then((res) => {
      setBlogs(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, [sortBy]);

  const onSearch = () => {
    fetchData();
  };
  const onClear = () => {
    setQuery("");
    fetchData({ query: "" });
  };
  return (
    <div className="py-8">
      <DashboardHeader
        title="My Blogs"
        description="Publish new blog posts and list your existing blog resources."
      />
      <div className="flex md:flex-col 2xl:flex-row mb-16 md:mb-3">
        <Button to="/create/blog" className="items-center gap-2 flex" small>
          <EditIcon />
          Create new blog post
        </Button>
      </div>
      <div className="flex justify-between md:flex-col gap-4 md:gap-12">
        <div className="flex gap-3">
          <div className="flex-1">
            <TextInput
              className="w-full"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search by blog name"
            />
          </div>
          <Button className="md:hidden" small flat onClick={onClear}>
            Clear
          </Button>
          <Button className="md:hidden" small onClick={onSearch}>
            Search
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-3 md:grid-cols-1 gap-8 pt-12 pb-36">
        {isLoading ? (
          <div className="col-span-2">
            <Spinner />
          </div>
        ) : blogs?.length > 0 ? (
          blogs.map((blog, i) => <BlogCard blog={blog} key={i} isEditing={true} />)
        ) : (
          <NotFoundText className="py-10 col-span-2">No blogs</NotFoundText>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_id: state.auth.user._id,
});

export default connect(mapStateToProps, {
  getAllMyBlogs,
})(withRouter(BlogManagement));
