import { getExpertiseList } from "actions/admin/setting";
import { ReactComponent as InfoCircle } from "assets/images/icons/info-circle.svg";
import FileDropzone from "components/common/form/FileDropzone";
import InputError from "components/common/form/InputError";
import MultiSelectInput from "components/common/form/MultiSelectInput";
import SelectInput from "components/common/form/SelectInput";
import Textarea from "components/common/form/Textarea";
import TierInfoModal from "components/common/Modal/TierInfoModal";
import { HOST_TIER_OPTIONS } from "constants";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import CreateStepControls from "../Create/StepControls";

export default function BecomeAHostStep2({ ...props }) {
  const { setFieldValue } = useFormikContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showedModal, setShowedModal] = useState(false);
  const [expertiseFields, setExpertiseFields] = useState([]);

  useEffect(() => {
    getExpertiseList().then((res) => {
      setExpertiseFields(res.data.response);
    });
  }, []);

  useEffect(() => {
    if (!showedModal && props.currentStep === 2) {
      setIsModalOpen(true);
      setShowedModal(true);
    }
  }, [props.currentStep]);

  return (
    <div>
      <TierInfoModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        isForHosts
      />
      <div className="space-y-5 border-b border-dgray-200 pb-5 mb-5">
        <SelectInput
          className="xl:w-1/3 lg:w-1/2 md:w-full"
          name="hostTier"
          label={
            <div className="flex items-center gap-2">
              Proficiency Tier
              <button
                type="button"
                className="text-dgray-400 mt-"
                onClick={() => setIsModalOpen(true)}
              >
                <InfoCircle />
              </button>
            </div>
          }
          options={HOST_TIER_OPTIONS}
        />
        <MultiSelectInput
          className="xl:w-1/3 lg:w-1/2 md:w-full"
          name="expertise"
          label="Expertise Fields"
          options={expertiseFields.map((exp) => ({ label: exp, value: exp }))}
        />
        <Textarea
          name="purpose"
          label="Please describe your purpose"
          placeholder="Your purpose for becoming a host..."
          maxLength={500}
        />
        <Textarea
          name="experience"
          label="Introduce your experience and practice with English"
          placeholder="Example: overseas degree, overseas job experience..."
          maxLength={500}
        />
        <div className="w-1/2 lg:w-full">
          <div className="text-sm text-dgray-700 font-medium">
            Upload supporting documents
          </div>
          <div className="text-sm text-dgray-500 mb-1.5">
            Ex: CV, diplome, certificates, etc.
          </div>
          <FileDropzone
            onDrop={(files) => {
              setFieldValue("documents", files);
            }}
            accept={{
              "image/png": [".png", ".jpg", ".jpeg"],
              "application/pdf": [".pdf"],
            }}
            description="PNG, JPG or PDF (max. 2000 kb)"
            maxSize={2000000}
          />
          <InputError name="documents" />
        </div>
      </div>
      <CreateStepControls {...props} />
    </div>
  );
}
