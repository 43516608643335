import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal, ModalBody } from "reactstrap";

function getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement("canvas");

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext("2d");
  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve, reject) =>
    canvas.toBlob(
      (blob) => {
        blob.name = `${Date.now()}.jpg`;
        resolve(blob);
      },
      "image/jpeg",
      1
    )
  );
}

const ImageCrop = ({
  aspect,
  onComplete,
  onRemove,
  className,
  id = null,
  style = null,
  image,
  disabled = false,
  removeButton = null,
  circularCrop = false,
  ...rest
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: aspect || 5 / 3,
    ...rest,
  });

  const fileUploadRef = useRef();
  const imgRef = useRef();

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const resetCrop = () => {
    setCrop({ aspect: 5 / 3 });
  };

  const onImageSelect = (e) => {
    const reader = new window.FileReader();
    reader.addEventListener(
      "load",
      () => {
        setSelectedImage(reader.result);
        setModalOpen(true);
      },
      false
    );
    reader.readAsDataURL(e.target.files[0]);
  };

  const onLoad = (e) => {
    imgRef.current = e;
  };

  const onCropChange = (cropData) => {
    setCrop(cropData);
  };

  const onCompleteLocal = async () => {
    const croppedImg = await getCroppedImg(imgRef.current, crop);
    setModalOpen(false);
    setFinalImage(croppedImg);
    if (onComplete) {
      onComplete(croppedImg);
    }
  };

  const onCancelLocal = (e) => {
    setSelectedImage(null);
    fileUploadRef.current.value = null;
    setModalOpen(false);
  };

  const onRemoveLocal = (e) => {
    setSelectedImage(null);
    setFinalImage(null);
    fileUploadRef.current.value = null;
    onRemove();
  };

  const extraFileInputProps = {};
  if (id) {
    extraFileInputProps.id = id;
  }
  if (style) {
    extraFileInputProps.style = style;
  }

  return (
    <>
      {disabled ? null : (
        <input
          type="file"
          ref={fileUploadRef}
          disabled={disabled}
          accept="image/*"
          onChange={onImageSelect}
          className={className}
          {...extraFileInputProps}
        />
      )}
      {finalImage || image ? (
        <div className="relative">
          <img
            src={image ? image : URL.createObjectURL(finalImage)}
            className={circularCrop ? "w-100 full-circle" : "w-100"}
          />
          {removeButton ? (
            <>{removeButton}</>
          ) : (
            <>
              {!disabled ? (
                <button
                  type="button"
                  onClick={onRemoveLocal}
                  className="btn btn-secondary remove-image absolute top-right"
                >
                  Remove
                </button>
              ) : null}
            </>
          )}
        </div>
      ) : null}
      <Modal size="xl" toggle={toggleModal} isOpen={isModalOpen}>
        <ModalBody>
          <ReactCrop
            circularCrop={circularCrop}
            onImageLoaded={onLoad}
            src={selectedImage}
            crop={crop}
            onChange={onCropChange}
          />
          <button
            type="button"
            onClick={onCancelLocal}
            className="btn btn-danger"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onCompleteLocal}
            className="btn btn-success"
          >
            Crop
          </button>
        </ModalBody>
      </Modal>
    </>
  );
};

ImageCrop.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default ImageCrop;
