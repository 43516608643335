const languages = [
  { name: "English", code: "en" },
  { name: "German", code: "de" },
  { name: "Chinese", code: "zh" },
  { name: "Spanish", code: "es" },
  { name: "French", code: "fr" },
  { name: "Hindi", code: "hi" },
  { name: "Portuguese", code: "pt" },
  { name: "Russian", code: "ru" },
  { name: "Korean", code: "ko" },
  { name: "Italian", code: "it" },
];

export default languages;
