import axios from "utils/axios";

import {
  USER_PROFILE_LOADING,
  USER_PROFILE_MODAL_LOADING,
  USER_PROFILE_UPDATED,
  USER_PROFILE_UPDATE_ERROR,
  USER_PROFILE_FAIL,
  USER_PROFILE_PASSWORD_UPDATED,
  USER_PROFILE_PASSWORD_UPDATE_ERROR,
} from "actions/types";

// Edit Profile
export const edit = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: USER_PROFILE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(`/api/admin/profile`, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: USER_PROFILE_UPDATED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: USER_PROFILE_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

// Edit Password
export const editPassword = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: USER_PROFILE_MODAL_LOADING,
      payload: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/admin/profile/password`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: USER_PROFILE_PASSWORD_UPDATED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
          isModal: true,
        },
      });
      return res.data;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      dispatch({
        type: USER_PROFILE_PASSWORD_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
          isModal: true,
        },
      });
      return res.data;
    }
  } catch (err) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
        isModal: true,
      },
    });
    return false;
  }
};
