import { ReactComponent as Atom } from "assets/images/icons/atom.svg";
import { ReactComponent as Collaboration } from "assets/images/icons/collaboration.svg";
import { ReactComponent as Compassion } from "assets/images/icons/compassion.svg";
import { ReactComponent as Inclusion } from "assets/images/icons/inclusion.svg";
import CenterContainer from "components/common/Container/Center";
import IconCircle from "../HomePage/IconCircle";
import FooterLinksLayout from "../Layout/FooterLinksLayout";
import aboutUsImg from "assets/images/about-us-vid.jpg";
import SectionTriangle from "components/common/SectionTriangle";

export default function AboutUs() {
  return (
    <FooterLinksLayout
      title="Our mission"
      subtitle="Our mission is to create the most valuable community of mentors and learners who are deeply passionate about sharing and acquiring knowledge, skills and experiences. By igniting the power of connecting with each of its members, DingDingNow aspires to facilitate exchanges that make life more engaging and meaningful."
    >
      <CenterContainer className="pt-40 md:pt-16 pb-24 text-center">
        <h2 className="mb-20 md:mb-14 text-5xl md:text-3xl font-semibold">
          Our Company Values
        </h2>
        <div className="flex lg:flex-col gap-8 lg:gap-12">
          {items.map(({ icon, title, description }) => (
            <div className="bg-dgray-50 rounded-2xl px-6 pb-8">
              <IconCircle className="mx-auto mb-2.5 -mt-7">{icon}</IconCircle>
              <h3 className="mb-2 text-xl font-medium">{title}</h3>
              <p className="text-dgray-500">{description}</p>
            </div>
          ))}
        </div>
      </CenterContainer>
      <div className="relative bg-primary-50 pt-40 pb-48 lg:pb-12">
        <SectionTriangle />
        <CenterContainer className="flex lg:flex-col items-center gap-16 lg:text-center">
          <div>
            <h2 className="text-5xl md:text-3xl font-semibold mb-6">
              Learn More About Us
            </h2>
            <p className="text-dgray-800 text-lg">
              DingDingNow is an early-stage company that was founded during the
              COVID-19 pandemic in 2020, when typical ways of networking such as
              meetups or career fairs were negatively affected. DingDingNow is
              targeting the learner and mentor communities with the goal of
              connecting them and having them share learnings and experiences on
              a real time fashion.
            </p>
          </div>
          <div className="shrink-0">
            <img
              src={aboutUsImg}
              alt=""
              className="object-cover rounded-xl h-[400px] md:h-[320px]"
            />
          </div>
        </CenterContainer>
      </div>
    </FooterLinksLayout>
  );
}

const items = [
  {
    icon: <Atom />,
    title: "Innovation",
    description:
      "Thinking outside of the box, challenging status quo and staying innovative are our motto to deliver a better service to our users and strengthen our communities.",
  },
  {
    icon: <Collaboration />,
    title: "Collaboration",
    description:
      "The collective effort is much more powerful than the sum of its single parts and so we are thrilled to facilitate exchanges that empower our communities.",
  },
  {
    icon: <Inclusion />,
    title: "Inclusion",
    description:
      "We recognize the power of diversity and the way it enriches our view of the world we live in. We welcome everyone to be a part of this journey.",
  },
  {
    icon: <Compassion />,
    title: "Compassion",
    description:
      "Our founding members are technology savvy and have extensive background in healthcare. We are passionate about helping others, and building a sustainable community.",
  },
];
