import {
  SET_ALERT,
  REMOVE_ALERT,
  // ADMIN_LOGIN_SUCCESS, //new admin login
  // ADMIN_LOGIN_ERROR,
  // ADMIN_LOGIN_LOADING,
  // ADMIN_LOGIN_FAIL,
  // USER_LOADING,
  // USER_FAIL,
  // USER_CHANGE_STATUS,
  // USER_CHANGE_STATUS_ERROR,
  // USER_CREATED,
  // USER_CREATE_ERROR,
  // USER_UPDATED,
  // USER_UPDATE_ERROR,
  COURSE_CATEGORY_LOADING, //course category
  COURSE_CATEGORY_FAIL,
  COURSE_CATEGORY_CREATED,
  COURSE_CATEGORY_CREATE_ERROR,
  COURSE_CATEGORY_UPDATED,
  COURSE_CATEGORY_UPDATE_ERROR,
  COURSE_CATEGORY_DELETED,
  COURSE_CATEGORY_DELETE_ERROR,
  COURSE_CATEGORY_CHANGE_STATUS,
  COURSE_CATEGORY_CHANGE_STATUS_ERROR,
  COURSE_SUBCATEGORY_LOADING, //course sub SUBcategory
  COURSE_SUBCATEGORY_FAIL,
  COURSE_SUBCATEGORY_CREATED,
  COURSE_SUBCATEGORY_CREATE_ERROR,
  COURSE_SUBCATEGORY_UPDATED,
  COURSE_SUBCATEGORY_UPDATE_ERROR,
  COURSE_SUBCATEGORY_DELETED,
  COURSE_SUBCATEGORY_DELETE_ERROR,
  COURSE_SUBCATEGORY_CHANGE_STATUS,
  COURSE_SUBCATEGORY_CHANGE_STATUS_ERROR,
  EDUCATOR_LOADING, // educator
  EDUCATOR_FAIL,
  EDUCATOR_CHANGE_STATUS,
  EDUCATOR_CHANGE_STATUS_ERROR,
  LEARNER_LOADING, //learner
  LEARNER_FAIL,
  LEARNER_CHANGE_STATUS,
  LEARNER_CHANGE_STATUS_ERROR,
  USER_PROFILE_MODAL_LOADING, //admin profile
  USER_PROFILE_LOADING,
  USER_PROFILE_UPDATED,
  USER_PROFILE_UPDATE_ERROR,
  USER_PROFILE_FAIL,
  USER_PROFILE_PASSWORD_UPDATED,
  USER_PROFILE_PASSWORD_UPDATE_ERROR,
  DELETE_USER,
  SETTING_UPDATED,
  SETTING_ERROR,
  COURSE_LOADING, //course
  COURSE_FAIL,
  COURSE_DELETED,
  COURSE_CHANGE_STATUS,
  COURSE_CHANGE_STATUS_ERROR,
} from "actions/types";

const initialState = {
  message: "",
  alertType: "",
  isModal: false,
  isAlertToDisplay: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ALERT:
      return payload;
    case REMOVE_ALERT:
      return initialState;

    //   case ADMIN_LOGIN_SUCCESS:
    //     return {
    //       message: payload.message,
    //       alertType: payload.alertType,
    //       isModal: payload.isModal ? payload.isModal : false,
    //       isAlertToDisplay: payload.isAlertToDisplay ? false : true,
    //     };
    //   case ADMIN_LOGIN_ERROR:
    //     return {
    //       message: payload.message,
    //       alertType: payload.alertType,
    //       isModal: payload.isModal ? payload.isModal : false,
    //       isAlertToDisplay: payload.isAlertToDisplay
    //         ? payload.isAlertToDisplay
    //         : true,
    //     };
    //   case ADMIN_LOGIN_LOADING:
    //     return payload ? { ...state } : initialState;

    //   case ADMIN_LOGIN_FAIL:
    //     return {
    //       message: payload.message,
    //       alertType: payload.alertType,
    //       isModal: payload.isModal ? payload.isModal : false,
    //       isAlertToDisplay: payload.isAlertToDisplay
    //         ? payload.isAlertToDisplay
    //         : true,
    //     };
    //   case USER_LOADING:
    case COURSE_CATEGORY_LOADING:
    case COURSE_SUBCATEGORY_LOADING:
    case EDUCATOR_LOADING:
    case LEARNER_LOADING:
    case COURSE_LOADING:
    case USER_PROFILE_MODAL_LOADING:
    case USER_PROFILE_LOADING:
      return initialState;
    // case USER_CHANGE_STATUS:
    // case USER_CHANGE_STATUS_ERROR:
    // case USER_CREATED:
    // case USER_CREATE_ERROR:
    // case USER_FAIL:
    // case USER_UPDATED:
    // case USER_UPDATE_ERROR:
    case COURSE_CATEGORY_FAIL:
    case COURSE_CATEGORY_CREATED:
    case COURSE_CATEGORY_CREATE_ERROR:
    case COURSE_CATEGORY_UPDATED:
    case COURSE_CATEGORY_UPDATE_ERROR:
    case COURSE_CATEGORY_DELETED:
    case COURSE_CATEGORY_DELETE_ERROR:
    case COURSE_CATEGORY_CHANGE_STATUS:
    case COURSE_CATEGORY_CHANGE_STATUS_ERROR:
    case COURSE_SUBCATEGORY_FAIL:
    case COURSE_SUBCATEGORY_CREATED:
    case COURSE_SUBCATEGORY_CREATE_ERROR:
    case COURSE_SUBCATEGORY_UPDATED:
    case COURSE_SUBCATEGORY_UPDATE_ERROR:
    case COURSE_SUBCATEGORY_DELETED:
    case COURSE_SUBCATEGORY_DELETE_ERROR:
    case COURSE_SUBCATEGORY_CHANGE_STATUS:
    case COURSE_SUBCATEGORY_CHANGE_STATUS_ERROR:
    case EDUCATOR_FAIL:
    case EDUCATOR_CHANGE_STATUS:
    case EDUCATOR_CHANGE_STATUS_ERROR:
    case LEARNER_FAIL:
    case COURSE_FAIL:
    case LEARNER_CHANGE_STATUS:
    case LEARNER_CHANGE_STATUS_ERROR:
    case COURSE_CHANGE_STATUS:
    case COURSE_DELETED:
    case COURSE_CHANGE_STATUS_ERROR:
    case USER_PROFILE_UPDATED:
    case USER_PROFILE_UPDATE_ERROR:
    case USER_PROFILE_FAIL:
    case USER_PROFILE_PASSWORD_UPDATED:
    case USER_PROFILE_PASSWORD_UPDATE_ERROR:
    case SETTING_UPDATED:
    case SETTING_ERROR:
    case DELETE_USER:
      return {
        message: payload.message,
        alertType: payload.alertType,
        isModal: payload.isModal ? payload.isModal : false,
        isAlertToDisplay: payload.isAlertToDisplay
          ? payload.isAlertToDisplay
          : true,
      };
    //   case LOGOUT_USER:
    //     return initialState;
    default:
      return state;
  }
}
