import axios from "utils/axios";
import {
  MYACCOUNT_LOADING,
  USER_MYACCOUNT_UPDATED,
  USER_MYACCOUNT_UPDATED_ERROR,
  USER_MYACCOUNT_FAIL,
  USER_MYACCOUNT,
  USER_MYACCOUNT_ERROR,
  USER_IMAGE_LOADING,
  USER_IMAGE_UPDATE_UPDATED,
  USER_IMAGE_UPDATE_ERROR,
  USER_IMAGE_UPDATE_FAIL,
  CART_COUNT_LOADING,
  CART_COUNT_SUCCESS,
  CART_COUNT_ERROR,
  CART_COUNT_FAIL,
  USER_STATE_UPDATE,
} from "actions/types";

export const update = (data) => async (dispatch) => {
  try {
    dispatch({
      type: MYACCOUNT_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //formData.username = formData.username.trim();

    const formData = new window.FormData();
    Object.keys(data).map(item => formData.append(item, data[item]));

    const res = await axios.post("/api/myaccount/", formData, config);
    console.log("res", res);
    if (res.data.status === true) {
      dispatch({
        type: USER_MYACCOUNT_UPDATED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
      console.log();

      return {
        status: true,
        message: res.data.message,
      };
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }

      dispatch({
        type: USER_MYACCOUNT_UPDATED_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: USER_MYACCOUNT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
    return false;
  }
};

export const updateSettings = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: MYACCOUNT_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //formData.username = formData.username.trim();

    const res = await axios.post("/api/myaccount/settings", formData, config);
    console.log("res", res);
    if (res.data.status === true) {
      dispatch({
        type: USER_MYACCOUNT_UPDATED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
      console.log();

      return {
        status: true,
        message: res.data.message,
      };
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }

      dispatch({
        type: USER_MYACCOUNT_UPDATED_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: USER_MYACCOUNT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
    return false;
  }
};

export const getConfigurations = () => async (dispatch) => {
  const res = await axios.get("/api/myaccount/configurations");
  return res.data;
};
export const currentUser = () => async (dispatch) => {
  try {
    dispatch({
      type: MYACCOUNT_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const res = await axios.get("/api/auth");
    if (res.data.status === true) {
      dispatch({
        type: USER_MYACCOUNT,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({
          type: USER_MYACCOUNT_ERROR,
          payload: {
            message: res.data.message,
            alertType: "danger",
          },
        });
      }
    }
  } catch (err) {
    dispatch({
      type: USER_MYACCOUNT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

export const uploadProfileImage = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: USER_IMAGE_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    console.log("formData", formData);

    let param = new window.FormData();
    param.append("avatar", formData.avatar);
    param.append("image", formData.image);
    const res = await axios.post(
      "/api/myaccount/upload-profile-image",
      param,
      config
    );
    console.log("param", param);

    if (res.data.status === true) {
      dispatch({
        type: USER_IMAGE_UPDATE_UPDATED,
        payload: res.data.response,
      });
      return { status: true };
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }

      await dispatch({
        type: USER_IMAGE_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
      return { status: false, data: errors[0].msg };
    }
  } catch (err) {
    dispatch({
      type: USER_IMAGE_UPDATE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
    return {
      status: false,
      data:
        err.response && err.response.statusText
          ? err.response.statusText
          : "Something went wrong.",
    };
  }
};

export const removeProfileImage = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.delete(
      "/api/myaccount/remove-profile-image",
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: USER_IMAGE_UPDATE_UPDATED,
        payload: "",
      });
      return { status: true };
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }

      await dispatch({
        type: USER_IMAGE_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
      return { status: false, data: errors[0].msg };
    }
  } catch (err) {
    dispatch({
      type: USER_IMAGE_UPDATE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
    return {
      status: false,
      data:
        err.response && err.response.statusText
          ? err.response.statusText
          : "Something went wrong.",
    };
  }
};

export const updatePassword = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: MYACCOUNT_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post("/api/myaccount/password", formData, config);
    if (res.data.status === true) {
      dispatch({
        type: USER_MYACCOUNT_UPDATED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors.length > 0) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: USER_MYACCOUNT_UPDATED_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: USER_MYACCOUNT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
    return false;
  }
};

export const getAllMentors = async (mentorParams) => {
  try {
    const custom_filters = mentorParams.custom_filters
      ? mentorParams.custom_filters
      : "&education=&industry=&session_completed=&year_of_experience=&rating=";
    const res = await axios.get(
      `/api/myaccount/getallmentors?limit=${mentorParams.limit}&page=${mentorParams.page}&orderBy=${mentorParams.orderBy}&ascending=${mentorParams.ascending}&query=${mentorParams.query}${custom_filters}`
    );
    return { status: true, data: res.data.response };
  } catch (error) {
    return { status: false };
  }
};

// get mentor by id
export const getMentorById = (mentor_id) => async (dispatch) => {
  dispatch({
    type: MYACCOUNT_LOADING,
    payload: { is_loading: true, is_sub_element: false },
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const res = await axios.get(`/api/myaccount/profile/${mentor_id}`, config);
  return res.data;
};

// get my orders
export const getMyOrders = (filters) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/learner/order/orderhistory?limit=${filters.limit}&page=${filters.page}&orderBy=${filters.orderBy}&ascending=${filters.ascending}`,
      config
    );
    if (res.data.status === true) {
      return {
        status: true,
        data: res.data.response[0].data,
        total: res.data.response[0].metadata[0].totalRecord,
      };
    } else {
      return { status: false };
    }
  } catch (error) {
    return { status: false };
  }
};

// get mentor orders
export const getMentorsOrders = (filters) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/mentor/payment/?limit=${filters.limit}&page=${filters.page}&orderBy=${filters.orderBy}&ascending=${filters.ascending}`,
      config
    );
    if (res.data.status) {
      return { data: res.data.response, status: true };
    }
  } catch (error) {
    return { status: false };
  }
};

//get cart count

export const getCartCount = () => async (dispatch) => {
  try {
    dispatch({
      type: CART_COUNT_LOADING,
      payload: true,
    });

    const res = await axios.get(`/api/learner/order/cart-count`);

    if (res.status) {
      dispatch({
        type: CART_COUNT_SUCCESS,
        payload: {
          data: res.data.response,
        },
      });
    } else {
      dispatch({
        type: CART_COUNT_ERROR,
      });
    }
  } catch (error) {
    dispatch({
      type: CART_COUNT_FAIL,
    });
  }
};

export const updateUserState = (key, value) => {
  return { type: USER_STATE_UPDATE, payload: { key, value } };
};
