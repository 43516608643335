import CourseImage from "assets/images/mock-event.jpg";
import { ReactComponent as ArrowUpRight } from "assets/images/icons/arrow-up-right.svg";
import Pill from "components/common/Pill";
import PinPill from "components/common/PinPill";
import getUserAvatar from "utils/getUserAvatar";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Button from "components/common/Button";
import { getBlogImage } from "utils/getEventImage";
const { REACT_APP_MEDIA_URL } = process.env;

function BlogCard({ blog, large, pinText, isEditing = false }) {
  return (
    <div
      className={`relative text-left flex-1 border border-dgray-200 lg:mx-auto overflow-hidden 
        ${large ? "rounded-3xl md:rounded-2xl" : "rounded-2xl max-w-sm"}
      `}
    >
      <div className={`${large ? "h-[360px]" : "h-60"} md:h-[200px]`}>
        <img
          alt="Blog cover"
          className="w-full h-full object-cover"
          src={getBlogImage(blog)}
        />
      </div>
      <div className="relative px-6 py-8">
        {pinText && <PinPill text={pinText} className="-top-11" />}
        {blog?.program ? (
          <Pill text={blog?.program?.name} color="blue" />
        ) : (
          <div className="inline py-0.5 px-2 w-4"></div>
        )}
        <Link
          to={`/blogs/${blog.slug}`}
          className="flex justify-between items-cente mb-3 mt-4"
        >
          <h3 className="text-xl md:text-lg font-semibold line-clamp-2">
            {blog.title}
          </h3>
          <div className="">
            <ArrowUpRight />
          </div>
        </Link>
        <p className="line-clamp-4 text-dgray-500 mb-6">
          {blog.meta_description}
        </p>

        <div className="flex items-center gap-2.5">
          <img
            src={getUserAvatar(blog.posted_by.avatar)}
            alt=""
            className="rounded-full w-10 h-10"
          />
          <div>
            <div className="text-sm font-medium">
              {`${blog.posted_by?.first_name} ${blog.posted_by?.last_name}`}
            </div>
            <div className="text-xs text-dgray-500">
              {moment(blog.created_at).format("MMM D, YYYY")}
            </div>
          </div>
        </div>
      </div>
      {isEditing ? (
        <>
          <div className="flex-1 flex flex-row justify-between mx-8 mb-4">
            <Link to={`/create/blog?editId=${blog._id}`}>Edit Blog</Link>
            <Link className="text-primary-700 font-medium" onClick={(e) => {}}>
              Delete
            </Link>
          </div>
          {blog.status === 0 ? (
            <div className="absolute top-2 left-0 py-2 px-3 bg-white text-primary-600 font-semibold">DRAFT</div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default connect((state) => ({
  categories: state.categories,
}))(BlogCard);
