import AccordionList from "components/common/AccordionList";
import CenterContainer from "components/common/Container/Center";
import CreateHero from "components/common/Hero/Create";
import Footer from "views/Front/Layout/Footer";
import Header from "views/Front/Layout/Header";
import { ReactComponent as CheckCircleIcon } from "assets/images/icons/check-circle.svg";
import { Link } from "react-router-dom";
import Button from "components/common/Button";
import Pill from "components/common/Pill";

export default function CreateEvent() {
  return (
    <>
      <Header />
      <CreateHero
        top="My Events"
        title="Create Session"
        bottom="Offer Public Events or One on One Sessions. Or both."
      />
      <CenterContainer className="bg-dgray-50  py-24 lg:py-10">
        <div className="grid grid-cols-2 lg:grid-cols-1 gap-16 lg:gap-8 mb-12">
          <Card
            href="/create/event"
            highlight
            title="Public Event"
            titleClassName="xl:mb-14 lg:mb-0"
            items={[
              "Get paid for hosting an event",
              "Fixed or flexible schedules to choose from",
              "Live interactions with speakers and listeners",
            ]}
          />
          <Card
            href="/create/one-on-one"
            title="One on One Session"
            items={[
              "More customized and personal interactive experiences for learners",
              "Set your price and schedule",
              "Share your expertise and experiences and mentor learners",
            ]}
          />
        </div>
      </CenterContainer>
      <CenterContainer className="py-24 lg:py-16" tight>
        <h2 className="text-4xl font-semibold text-center mb-16">
          Frequently asked questions
        </h2>
        <AccordionList items={faqItems} />
      </CenterContainer>
      <Footer />
    </>
  );
}

function Card({
  title,
  subtitle,
  items,
  highlight,
  titleClassName = "",
  href,
}) {
  return (
    <Link
      to={href}
      disabled
      className={`rounded-2xl bg-white pt-20 lg:pt-16 pb-10 px-8 shadow-lg
      ${
        highlight
          ? "border-2 border-primary-600 ring-2 ring-primary-50"
          : "border border-dgray-200"
      }`}
    >
      <div className="flex flex-col h-full">
        <div className="text-center border-b border-dgray-200 pb-8 mb-8 ">
          <div
            className={`text-5xl lg:text-4xl font-semibold mb-2
          ${!href ? "text-dgray-400" : ""} ${titleClassName}`}
          >
            {title}
          </div>
          <div className={`${!href ? "text-dgray-400" : "text-dgray-500"}`}>
            {subtitle}
          </div>
        </div>
        <ul className="space-y-3 text-dgray-500 basis-1/2">
          {items.map((item) => (
            <li className="flex gap-3">
              <CheckCircleIcon
                className={`shrink-0 ${
                  !href ? "text-dgray-400" : "text-primary-600"
                }`}
              />
              <div className={!href ? "text-dgray-400" : ""}>{item}</div>
            </li>
          ))}
        </ul>
      </div>
    </Link>
  );
}

const faqItems = [
  {
    title:
      "What is the difference between Public Events and One on One Session?",
    content:
      "Public Events offerings are for our subscribed members. One on One Session is designed for any users who are looking for more personalized guidance. One on One Sessions can be attended by anyone who pays. Prices and schedules are determined by you.",
  },
  {
    title: "Do I get paid the same for Public Event and One on One Session?",
    content:
      "No, Public Event hosts get paid based on different tiers, as stated on the “Become a Host” page. However, a host can determine the time and price for One on One Session, and DDN will take 15% commission. One on One Session payout is the same schedule as Public Event on a biweekly basis on Monday before 5pm EST.",
  },
  {
    title:
      "What if I accidentally create a Public Event and One on One Session at the same time?",
    content:
      "It won’t happen. DDN has technology to alert you for any conflicting schedules, and you won’t be able to publish an event or session with a conflicting time.",
  },
  {
    title: "How do I know someone book with me for One On One Session?",
    content:
      "DDN will notify you through email when someone books with you. Please check your email or log in on DDN periodically.",
  },
];
