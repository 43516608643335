import React, { Component } from "react";
import PropTypes from "prop-types";

export const languages = ({
    "sq": "shqiptar",
    "ar": "العربية",
    "bn": "বাংলা",
    "ch": "中文",
    "nl": "Nederlandse",
    "en": "English",
    "fr": "Français",
    "de": "German",
    "gr": "ελληνική",
    "gu": "Avañe'ẽ",
    "hi": "हिंदुस्तानी",
    "it": "Italiano",
    "ko": "한국어",
    "ms": "Melayu",
    "fa": "پارسی",
    "pt": "Português",
    "ro": "Română",
    "ru": "русский",
    "sr": "Српско-хрватски",
    "es": "Español",
    "sw": "Kiswahili",
    "sv": "Swedish",
    "ta": "தமிழ்",
    "tr": "Türkçe",
  });
  
  export const languagesExtended = [
    { "name": "Albanian", "localName": "shqiptar", "countries": ["Albania", "Kosovo", "North Macedonia"] },
    { "name": "Arabic", "localName": "العربية", "countries": ["Egypt", "Sudan", "Algeria", "Iraq", "Morocco", "Saudi Arabia"] },
    { "name": "Bengali", "localName": "বাংলা", "countries": ["Bangladesh", "India"] },
    { "name": "Chinese", "localName": "中文", "countries": ["China", "Singapore", "Taiwan"] },
    { "name": "Dutch", "localName": "Nederlandse", "countries": ["Netherlands", "Belgium", "Suriname"] },
    { "name": "English", "localName": "English", "countries": ["United Kingdom", "Nigeria", "Philippines", "Bangladesh", "India"] },
    { "name": "French", "localName": "Français", "countries": ["Canada", "Belgium", "Switzerland", "Madagascar", "Monaco", "Haiti"] },
    { "name": "German", "localName": "German", "countries": ["Germany", "Austria", "Switzerland", "Belgium", "Luxembourg", "Liechtenstein"] },
    { "name": "Greek", "localName": "ελληνική", "countries": ["Greece", "Cyprus"] },
    { "name": "Guarani", "localName": "Avañe'ẽ", "countries": ["Bolivia", "Paraguay"] },
    { "name": "Hindi", "localName": "हिंदुस्तानी", "countries": ["India", "Fiji (known as Hindustani)", "Pakistan (known as Urdu)"] },
    { "name": "Italian", "localName": "Italiano", "countries": ["Italy", "Switzerland", "San Marino", "Vatican City"] },
    { "name": "Korean", "localName": "한국어", "countries": ["North Korea", "South Korea"] },
    { "name": "Malay", "localName": "Melayu", "countries": ["Indonesia (known as Indonesian), Malaysia", "Singapore", "Brunei"] },
    { "name": "Persian", "localName": "پارسی", "countries": ["Iran", "Afghanistan (known as Dari), Tajikistan (known as Tajik)"] },
    { "name": "Portuguese", "localName": "Português", "countries": ["Portugal", "Brazil", "Mozambique", "Angola"] },
    { "name": "Romanian", "localName": "Română", "countries": ["Romania", "Moldova"] },
    { "name": "Russian", "localName": "русский", "countries": ["Russia", "Kazakhstan", "Belarus", "Kyrgyzstan"] },
    { "name": "Serbo-Croatian", "localName": "Српско-хрватски", "countries": ["Serbia", "Croatia", "Bosnia and Herzegovina", "Montenegro", "Kosovo"] },
    { "name": "Spanish", "localName": "Español", "countries": ["Spain", "Mexico", "Colombia", "Argentina"] },
    { "name": "Swahili", "localName": "Kiswahili", "countries": ["Tanzania", "Kenya", "Uganda", "Rwanda", "Democratic Republic of the Congo"] },
    { "name": "Swedish", "localName": "Swedish", "countries": ["Sweden", "Finland"] },
    { "name": "Tamil", "localName": "தமிழ்", "countries": ["Sri Lanka", "Singapore", "India"] },
    { "name": "Turkish", "localName": "Türkçe", "countries": ["Turkey", "Cyprus"] },
  ];

class LanguageSelector extends Component {
  constructor(props) {
    super(props);
    const defaultLanguage =
      languages[this.props.defaultLanguage] && this.props.defaultLanguage;
    
    this.state = {
      openOptions: false,
      defaultLanguage,
      filteredLanguages: [],
    };

    this.toggleOptions = this.toggleOptions.bind(this);
    this.closeOptions = this.closeOptions.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
    this.setLanguages = this.setLanguages.bind(this);
  }

  toggleOptions() {
    !this.state.disabled &&
      this.setState({
        openOptions: !this.state.openOptions,
      });
  }

  toggleOptionsWithKeyboard(evt) {
    evt.preventDefault();
    if (evt.keyCode === 27) {
      //esc key: hide options
      !this.state.disabled &&
        this.setState({
          openOptions: false,
        });
    }
  }

  closeOptions(event) {
    if (
      event.target !== this.refs.selectedFlag &&
      event.target !== this.refs.flagOptions &&
      event.target !== this.refs.filterText
    ) {
      this.setState({
        openOptions: false,
      });
    }
  }

  onSelect(languageCode) {
    this.setState({
      selected: languageCode,
      filter: "",
      openOptions: false,
    });
    this.props.onSelect && this.props.onSelect({
        code: languageCode,
        name: languagesExtended[languageCode].name
    });
  }

  onSelectWithKeyboard(evt, languageCode) {
    evt.preventDefault();
    if (evt.keyCode === 13) {
      //enter key: select
      this.onSelect(languageCode);
      this.closeOptions(evt);
    } else if (evt.keyCode === 27) {
      //esc key: hide options
      this.toggleOptions();
    }
  }

  updateSelected(languageCode) {
    const isValid = languages[languageCode];
    isValid &&
      this.setState({
        selected: languageCode,
      });
  }

  filterSearch(evt) {
    let filterValue = evt.target.value;
    let filteredLanguages =
      filterValue &&
      this.state.languages.filter((key) => {
        let label = this.props.customLabels[key] || languagesExtended[key].name;
        return label && label.match(new RegExp(filterValue, "i"));
      });

    this.setState({
      filter: filterValue,
      filteredLanguages: filteredLanguages,
    });
  }

  setLanguages() {
    const fullLanguages = Object.keys(languages);
    let selectedLanguages = this.props.selectedLanguages;
    let selectLanguages =
      this.props.languages &&
      this.props.languages.filter((language) => {
        return languages[language];
      });

    if (selectedLanguages.length > 0) {
        selectLanguages = fullLanguages.filter(item => {
            return !selectedLanguages.filter(selected => selected.code === item).length > 0
        });
    }

    this.setState(
      {
        languages: selectLanguages || fullLanguages,
      },
      () => {
        const { selected } = this.state;

        if (selected && !this.state.languages.includes(selected)) {
          this.setState({ selected: null });
        }
      }
    );
  }

  componentDidMount() {
    this.setLanguages();
    !this.props.disabled && window.addEventListener("click", this.closeOptions);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.languages !== this.props.languages ||
      prevProps.blackList !== this.props.blackList ||
      prevProps.selectedLanguages !== this.props.selectedLanguages
    ) {
      this.setLanguages();
    }
  }

  componentWillUnmount() {
    !this.props.disabled &&
      window.removeEventListener("click", this.closeOptions);
  }

  render() {
    let isSelected = this.state.selected || this.state.defaultLanguage;
    let selectedSize = this.props.selectedSize;
    let optionsSize = this.props.optionsSize;
    let alignClass =
      this.props.alignOptions.toLowerCase() === "left" ? "to--left" : "";
    return (
      <div
        className={`flag-select ${
          this.props.className ? this.props.className : ""
        }`}
      >
        <button
          type="button"
          ref="selectedFlag"
          style={{ fontSize: `${selectedSize}px` }}
          className="flag-select__btn"
          onClick={this.toggleOptions}
          onKeyUp={(evt) => this.toggleOptionsWithKeyboard(evt)}
          disabled={this.props.disabled}
          id="select_flag_button"
          aria-haspopup="listbox"
          aria-expanded={this.state.openOptions}
          aria-labelledby="select_flag_button"
        >
          {isSelected && (
            <span className="flag-select__option flag-select__option--placeholder">
              {!this.props.showSelectedLabel && (
                <span>
                  {this.props.names == "international"
                    ? languagesExtended[isSelected].name
                    : this.props.names == "local"
                    ? languagesExtended[isSelected].localName
                    : languagesExtended[isSelected].name}
                </span>
              )}
              {/*<img className="flag-select__option__icon" src={require(`../flags/${isSelected.toLowerCase()}.svg`)} alt={isSelected}/>*/}
              {this.props.showSelectedLabel && (
                <span className="flag-select__option__label">
                  {this.props.customLabels[isSelected] ||
                    (this.props.names == "international"
                      ? languagesExtended[isSelected].name
                      : this.props.names == "local"
                      ? languagesExtended[isSelected].localName
                      : languagesExtended[isSelected].name)}
                </span>
              )}
            </span>
          )}
          {!isSelected && (
            <span className="flag-select__option flag-select__option--placeholder">
              {this.props.placeholder}
            </span>
          )}
        </button>

        {this.state.openOptions && (
          <ul
            tabIndex="-1"
            role="listbox"
            ref="flagOptions"
            style={{
              display: "block",
              textAlign: "left",
              listStyleType: "none",
              fontSize: `${optionsSize}px`,
            }}
            className={`flag-select__options ${alignClass}`}
          >
            {this.props.searchable && (
              <div className="filterBox">
                <input
                  type="text"
                  placeholder={this.props.searchPlaceholder}
                  ref="filterText"
                  onChange={this.filterSearch}
                />
              </div>
            )}

            {(this.state.filter
              ? this.state.filteredLanguages
              : this.state.languages
            ).map((languageCode) => (
              <li
                key={languageCode}
                role="option"
                tabIndex="0"
                id={`select_flag_${languageCode}`}
                className={`flag-select__option ${
                  this.props.showOptionLabel ? "has-label" : ""
                }`}
                onClick={() => this.onSelect(languageCode)}
                onKeyUp={(evt) => this.onSelectWithKeyboard(evt, languageCode)}
              >
                <span
                  style={{
                    width: `${optionsSize}px`,
                    height: `${optionsSize}px`,
                  }}
                >
                  {!this.props.showOptionLabel && (
                    <span>
                      <i>
                        {/*languagesExtended[languageCode].name*/}
                        {this.props.names == "international"
                          ? languagesExtended[languageCode].name
                          : this.props.names == "local"
                          ? languagesExtended[languageCode].localName
                          : this.props.names == "both"
                          ? languagesExtended[languageCode].name +
                            " " +
                            languagesExtended[languageCode].localName
                          : languagesExtended[isSelected].name}
                      </i>
                    </span>
                  )}
                  {/*<img
										className="flag-select__option__icon"
										alt={`flag for ${languages[languageCode]}`}
									src={require(`../flags/${languageCode.toLowerCase()}.svg`)} />*/}
                  {this.props.showOptionLabel && (
                    <span className="flag-select__option__label">
                      {this.props.customLabels[languageCode] || //languages[languageCode]
                        (this.props.names == "international"
                          ? languagesExtended[languageCode].name
                          : this.props.names == "local"
                          ? languagesExtended[languageCode].localName
                          : this.props.names == "both"
                          ? languagesExtended[languageCode].name +
                            " " +
                            languagesExtended[languageCode].localName
                          : languagesExtended[languageCode].name)}
                    </span>
                  )}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

LanguageSelector.defaultProps = {
  selectedSize: 16,
  optionsSize: 14,
  placeholder: "Select a language",
  showSelectedLabel: true,
  showOptionLabel: true,
  alignOptions: "right",
  customLabels: {},
  disabled: false,
  blackList: false,
  searchable: false,
  searchPlaceholder: "Search",
  names: "local",
  selectedLanguages: []
};

LanguageSelector.propTypes = {
  languages: PropTypes.array,
  blackList: PropTypes.bool,
  customLabels: PropTypes.object,
  selectedSize: PropTypes.number,
  optionsSize: PropTypes.number,
  defaultLanguage: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  showSelectedLabel: PropTypes.bool,
  showOptionLabel: PropTypes.bool,
  alignOptions: PropTypes.string,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  names: PropTypes.oneOf(["local", "international", "both"]),
  selectedLanguages: PropTypes.array
};

export default LanguageSelector;
