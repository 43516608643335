import sanitizeHtml from "sanitize-html";
import "./sanitize.scss";

export function SanitizeHTML({ html, options, className = "" }) {
  return (
    <div
      dangerouslySetInnerHTML={sanitize(html, options)}
      className={`list-disc sanitized-content ${className}`}
    />
  );
}

const defaultOptions = {
  allowedTags: [
    "b",
    "i",
    "em",
    "strong",
    "a",
    "ul",
    "ol",
    "li",
    "p",
    "h2",
    "h3",
    "h4",
  ],
  allowedAttributes: { a: ["href"] },
};

const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }),
});
