import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Input, FormGroup, Button } from "reactstrap";
import DateRangeInput from "./DateRangeInput";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const CustomFilters = (props) => {
  let query;
  let dateFrom;
  let dateTo;

  const initialFilter = {
    query: "",
    dateFrom: "",
    dateTo: "",
  };

  const [filter, setFilter] = useState(initialFilter);
  // date range picker
  const [dates, setDates] = useState();
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);

  const handleClick = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
    let tempFilters = { ...filter, [e.target.name]: e.target.value };

    let final_query = "range";
    Object.keys(tempFilters).map(function (key) {
      final_query = `${final_query}&${key}=${tempFilters[key]}`;
    });
    props.onSearch(final_query);
  };

  useEffect(() => {
    if (dates !== undefined) {
      setShowDateRangePicker(!showDateRangePicker);
      setFilter({
        ...filter,
        dateFrom: dates.start.format("YYYY-MM-DD"),
        dateTo: dates.end.format("YYYY-MM-DD"),
      });
      let tempFilters = {
        ...filter,
        dateFrom: dates.start.format("YYYY-MM-DD"),
        dateTo: dates.end.format("YYYY-MM-DD"),
      };

      let final_query = "range";
      Object.keys(tempFilters).map(function (key) {
        final_query = `${final_query}&${key}=${tempFilters[key]}`;
      });
      props.onSearch(final_query);
    }
  }, [dates]);

  const handelReset = (e) => {
    //reset values on text and dropdown
    query.value = "";
    //reset submiting filters
    setFilter(initialFilter);
    setDates(undefined);
    //reset empty query
    props.onSearch("");
  };

  const showHideDateEangePicker = (e) => {
    setShowDateRangePicker(!showDateRangePicker);
  };

  return (
    <div className="row ">
      <div className="col-4">
        <FormGroup check className="checkbox">
          <Input
            className="form-control"
            innerRef={(n) => (query = n)}
            type="text"
            placeholder="Search"
            name="query"
            id="query"
            onChange={(e) => handleClick(e)}
          />
        </FormGroup>
      </div>

      <div className="col-4">
        <label
          className="range-picker-label"
          onClick={(e) => showHideDateEangePicker(e)}
        >
          <i class="fa fa-calendar" aria-hidden="true"></i>
          {dates !== undefined ? (
            <>
              {dates.start.format("YYYY-MM-DD")} -{" "}
              {dates.end.format("YYYY-MM-DD")}
            </>
          ) : (
            <>Choose Data Range </>
          )}
        </label>
        {showDateRangePicker && (
          <FormGroup className="range-picker-container">
            <DateRangeInput dates={dates} setDates={setDates} />
          </FormGroup>
        )}
      </div>
      <div className="col-4 ">
        <Button
          className="mr-05 reset-btn"
          size="sm"
          color="danger"
          onClick={(e) => handelReset(e)}
        >
          Reset Filters
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(withRouter(CustomFilters));
