import CenterContainer from "components/common/Container/Center";
import janeImg from "assets/images/jane-doe.jpg";

export default function HomePageTestimonials() {
  return (
    <CenterContainer className="py-24 md:py-12">
      <div className="text-center md:text-left mb-16 md:mb-12">
        <div className="text-primary-700 font-semibold mb-3 md:text-sm">
          What people say about DDN
        </div>
        <h2 className="text-4xl md:text-3xl font-semibold">
          Hear from the Learners
        </h2>
      </div>
      <div className="flex md:flex-col gap-32 md:gap-10">
        <div className="shrink-0">
          <img src={janeImg} alt="" className="rounded-2xl md:w-2/3" />
        </div>
        <div>
          <p className="text-4xl md:text-2xl mb-8">
            Talking with proficient mentors from all around the world is great.
            I feel more confident every day.
          </p>
          <div className="font-medium text-lg mb-1">&mdash; Jane Doe</div>
          <div className="text-dgray-500">Istanbul, Turkey</div>
        </div>
      </div>
    </CenterContainer>
  );
}
