import {
  MYACCOUNT_LOADING,
  USER_MYACCOUNT_UPDATED,
  USER_MYACCOUNT_UPDATED_ERROR,
  USER_MYACCOUNT_FAIL,
  USER_MYACCOUNT,
  USER_MYACCOUNT_ERROR,
  USER_PASSWORD_UPDATED,
  USER_PASSWORD_UPDATE_ERROR,
  USER_IMAGE_LOADING,
  USER_IMAGE_UPDATE_UPDATED,
  USER_IMAGE_UPDATE_ERROR,
  USER_IMAGE_UPDATE_FAIL,
  ORDER_DETAILS,
} from "actions/types";

const initalState = {
  isSubLoading: false,
  loading: false,
  error: {},
  user: null,
  modal_loading: false,
  orderDetails: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case MYACCOUNT_LOADING:
    case USER_IMAGE_LOADING:
      return {
        ...state,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case USER_MYACCOUNT_UPDATED:
      return {
        ...state,
        user: payload.data,
        loading: false,
        isSubLoading: false,
      };
    case USER_IMAGE_UPDATE_UPDATED:
      return {
        ...state,
        user: { ...state.user, avatar: payload },
        loading: false,
        isSubLoading: false,
      };
    case USER_MYACCOUNT_UPDATED_ERROR:
    case USER_IMAGE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case USER_MYACCOUNT_FAIL:
    case USER_IMAGE_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case USER_PASSWORD_UPDATED:
      return {
        ...state,
        modal_loading: false,
        loading: false,
      };
    case USER_PASSWORD_UPDATE_ERROR:
      return {
        ...state,
        modal_loading: false,
        loading: false,
      };
    case USER_MYACCOUNT:
      return {
        ...state,
        user: payload.data,
        loading: false,
      };
    case USER_MYACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ORDER_DETAILS:
      return {
        ...state,
        orderDetails: payload,
      }
    default:
      return state;
  }
}
