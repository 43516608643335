import { ReactComponent as ArrowLeftIcon } from "assets/images/icons/arrow-left.svg";
import { ReactComponent as UserCheckIcon } from "assets/images/icons/user-check.svg";
import Button from "components/common/Button";
import CenterContainer from "components/common/Container/Center";
import StepCircle from "components/common/FormSteps/StepCircle";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isUserHost } from "utils/isUserHost";
import IconCircle from "../HomePage/IconCircle";

function HostFormStartInfo({ user, setHasStarted }) {
  const isAlreadyHost = isUserHost(user);
  return (
    <CenterContainer className="my-24 w-[540px] md:w-full" tight>
      <div className="text-center">
        <IconCircle className="mx-auto mb-6" large>
          <UserCheckIcon />
        </IconCircle>
        <h1 className="text-3xl font-semibold mb-3">Become a DDN Host</h1>
        <p className="text-dgray-500 mb-10">
          Introduce yourself and show your expertise to become a DDN host.
        </p>
      </div>
      <ul className="space-y-8 mb-16">
        {items.map(({ title, description }, i) => (
          <li className="flex gap-4" key={`items_${i}`}>
            <StepCircle disabled key={i} />
            <div>
              <div className="text-dgray-700 font-medium mb-0.5">{title}</div>
              <div className="text-dgray-500">{description}</div>
            </div>
          </li>
        ))}
      </ul>
      <div>
        {isAlreadyHost || user.host_application ? (
          <div className="text-center mb-2 text-sm text-primary-700">
            <div className="mb-2">
              {isAlreadyHost
                ? "You are already a host!"
                : user.host_application &&
                  "You already have a host application in progress."}
            </div>
            <Link to="/dashboard" className="block font-medium p-1">
              Go to MyDDN
            </Link>
          </div>
        ) : (
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              setHasStarted(true);
            }}
            className="w-full mb-6"
            small
          >
            Start my application
          </Button>
        )}
        <Link
          to="/"
          className="text-sm text-dgray-500 font-medium flex items-center gap-2 justify-center p-2"
        >
          <ArrowLeftIcon />
          Back to home page
        </Link>
      </div>
    </CenterContainer>
  );
}

export default connect((state) => ({ user: state.auth.user }))(
  HostFormStartInfo
);

const items = [
  {
    title: "Your personal information",
    description:
      "Your basic personal information that will be displayed on your host profile.",
  },
  {
    title: "Experience & Qualification",
    description:
      "Your background & professional experience that will help us to evaluate your English proficiency and any supporting documents.",
  },
  {
    title: "Payout method selection",
    description:
      "Selection and set up of preferred payout method from available options on DDN.",
  },
  {
    title: "Review & Approval",
    description:
      "DDN team carefully reviews and evaluates all applications. You will get a notification on your application.",
  },
];
