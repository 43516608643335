import React, { useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { USER_TYPE } from "constants";

import HostMyAccount from "views/Front/ProfileCourseMgmt/HostMyAccount";
import StudentMyAccount from "views/Front/ProfileCourseMgmt/StudentMyAccount";

const MyAccount = ({ user, isHosting }) => {

  /********************set user data to local state*****************/
  return (
    isHosting ? 
    <HostMyAccount user={user}/> 
    :
    <StudentMyAccount user={user}/> 
  ) 
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  isHosting: state.auth.user?.role[0].user_type === USER_TYPE.HOST,
});

export default connect(mapStateToProps)(withRouter(MyAccount));
