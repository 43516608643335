import { ReactComponent as Pin } from "assets/images/icons/pin.svg";
import Pill from "./Pill";

export default function PinPill({ text, className = "" }) {
  return (
    <Pill
      className={`absolute ${className}`}
      color="orange"
      text={
        <>
          <Pin className="inline mr-1" />
          <span className="text-primary-700">{text}</span>
        </>
      }
    />
  );
}
