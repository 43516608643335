import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import { Provider } from "react-redux";
import store from "./store";
import { loadUser, logout } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Routes from "./views/Routing/Routes";
//import "App.scss";

// Auto logout from other tabs if token removed
window.addEventListener("storage", (e) => {
  if (e.key === "token" && e.oldValue && !e.newValue) {
    setAuthToken("");
    store.dispatch(logout());
  }
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (localStorage.token) {
  setAuthToken(localStorage.token);
}
const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route component={Routes} />
        </Switch>
      </Router>
    </Provider>
  );
};
export default App;
