import * as Yup from "yup";
import { matchingPasswordSchema, newPasswordSchema } from "./shared";

const resetPasswordSchema = Yup.object({
  password: newPasswordSchema(),
  passwordRepeat: matchingPasswordSchema(
    "Please confirm your password",
    "Please enter matching passwords"
  ),
});

export default resetPasswordSchema;
