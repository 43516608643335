import {
  COURSE_CATEGORY_LOADING,
  COURSE_CATEGORY_LIST_UPDATED,
  COURSE_CATEGORY_FAIL,
  COURSE_CATEGORY_CREATED,
  COURSE_CATEGORY_CREATE_ERROR,
  COURSE_CATEGORY_UPDATED,
  COURSE_CATEGORY_UPDATE_ERROR,
  COURSE_CATEGORY_DELETED,
  COURSE_CATEGORY_DELETE_ERROR,
  COURSE_CATEGORY_CHANGE_STATUS,
  COURSE_CATEGORY_CHANGE_STATUS_ERROR,
  GET_COURSE_CATEGORY_BY_ID,
} from "actions/types";

const initalState = {
  courseCategoriesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentCourseCategory: null,
  loading: false,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case COURSE_CATEGORY_LOADING:
      return {
        ...state,
        courseCategoriesList: payload.is_sub_element
          ? state.courseCategoriesList
          : initalState.courseCategoriesList,
        currentCourseCategory: payload.is_sub_element
          ? state.currentCourseCategory
          : initalState.currentCourseCategory,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case COURSE_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case COURSE_CATEGORY_LIST_UPDATED:
      return {
        ...state,
        courseCategoriesList: {
          data: payload.data.data,
          page: payload.data.metadata[0].current_page,
          count: payload.data.metadata[0].totalRecord,
        },
        loading: false,
        isSubLoading: false,
      };
    case COURSE_CATEGORY_CREATED:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case COURSE_CATEGORY_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case COURSE_CATEGORY_UPDATED:
      return {
        ...state,
        currentCourseCategory: null,
        loading: false,
      };
    case COURSE_CATEGORY_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case COURSE_CATEGORY_DELETED:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case COURSE_CATEGORY_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };

    case GET_COURSE_CATEGORY_BY_ID:
      return {
        ...state,
        currentCourseCategory: payload.data,
        loading: false,
      };
    case COURSE_CATEGORY_CHANGE_STATUS:
      return {
        ...state,
        courseCategoriesList: {
          ...state.courseCategoriesList,
          data: state.courseCategoriesList.data.map((category) =>
            category._id === payload.data._id
              ? { ...category, status: payload.data.status }
              : category
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case COURSE_CATEGORY_CHANGE_STATUS_ERROR:
      return {
        ...state,
        courseCategoriesList: {
          ...state.courseCategoriesList,
          data: state.courseCategoriesList.data.map((category) =>
            category._id === payload.data
              ? { ...category, is_failed: true }
              : { ...category, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };

    default:
      return state;
  }
}
