import { ReactComponent as UploadCloudIcon } from "assets/images/icons/upload-cloud.svg";
import { useDropzone } from "react-dropzone";
import formatBytes from "utils/formatBytes";
import IconCircle from "views/Front/HomePage/IconCircle";

export default function FileDropzone({
  onDrop,
  accept,
  description,
  maxFiles = 0,
  className = "",
  maxSize = Infinity,
  name,
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    maxSize,
  });
  return (
    <div {...getRootProps({ className })}>
      <input {...getInputProps({ name })} />
      <div
        className={`text-center text-dgray-500 rounded-lg border py-4 px-6
        ${isDragActive ? "border-dgray-300" : "border-dgray-200"}`}
      >
        <IconCircle className="mx-auto" color="gray">
          <UploadCloudIcon />
        </IconCircle>
        <div className="text-sm mb-1">
          <button type="button" className="text-primary-700 font-medium">
            Click to upload
          </button>{" "}
          or drag and drop
        </div>
        <div className="text-xs">{description}</div>
        <div className="text-sm space-y-1 mt-2">
          {acceptedFiles.map(({ name, size }, i) => (
            <div key={i}>
              {name}{" "}
              <span className="text-xs font-medium">{formatBytes(size)}</span>
            </div>
          ))}
          {fileRejections.map(({ file: { name, size } }, i) => (
            <div className="text-red-500" key={i}>
              {name}{" "}
              <span className="text-xs font-medium">{formatBytes(size)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
