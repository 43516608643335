export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE_SECOND_OPTION = 20;
export const TABLE_BORDER_COLOR = "#f1f5f1";
export const NO_RECORD = "No Record Found.";
export const USER_TYPE = {
  ADMIN: 1,
  STUDENT: 2,
  HOST: 3,
};

export const SESSION_TYPE = {
  GENERIC: 1,
  SPECIFIC_INDIVIDUAL: 2,
  SPECIFIC_GROUP: 3,
  SPECIFIC_BOTH: 4,
  LIVE_EVENT: 5,
};

export const SESSION_TYPE_REVERSE = {
  1: "One on One",
  2: "Specific Individual",
  3: "Group, 1-1",
  4: "Specific Both",
  5: "Live Event",
};

export const SESSION_DURATION = {
  15: "15 Mins",
  30: "30 Mins",
  45: "45 Mins",
  60: "60 Mins",
  // 75: "75 Mins",
  90: "90 Mins",
  // 105: "105 Mins",
  120: "120 Mins",
};

export const RECURRING_TYPE = {
  1: "None",
  2: "Daily",
  3: "Weekly",
  4: "Monthly",
};

export const DURATION_LIST = {
  0: "Last 30 days",
  1: "Last 60 days",
  2: "Last 90 days",
  3: "Last 6 months",
  4: "Last 1 year",
};

export const USER_STATUS = {
  1: "Active",
  2: "Unverified",
  3: "Blocked"
}

export const USER_STATUS_REVERSE = {
  ACTIVE: 1,
  UNVERIFIED: 2,
  BLOCKED: 3
}


export const RECURRING_TYPE_REVERSE = {
  NONE: 1,
  DAILY: 2,
  WEEKLY: 3,
  MONTHLY: 4,
};

export const TABS = {
  BASIC: 1,
  SESSION: 2,
  CALENDAR: 3,
  CONFIRMATION: 4,
};

export const COURSE_VIEW = {
  SCHEDULE: 1,
  RESCHEDULE: 2,
  DELETE: 3,
  EDIT: 4,
  REVIEW_COURSE: 6,
  DETAIL: 7,
};

export const RECORD_PER_PAGE = 15;

export const SORTING = {
  ALPHABET_ASC: 1,
  ALPHABET_DESC: 2,
  DATE_DESC: 3,
  DATE_ASC: 4,
  RATING_LOW_HIGH: 5,
  RATING_HIGH_LOW: 6,
};

export const BOOKED_SESSION_STATUS = {
  BOOKED: 1,
  CANCLLED_BY_MENTOR: 2,
  CANCLLED_BY_LEARNER: 3,
};

export const PAYMENT_STATUS = {
  1: "Pending",
  3: "Pending",
  2: "Paid to mentor",
};

const chimeClientUrl = process.env.REACT_APP_CHIME_CLIENT_URL;

export const CHIME_CLIENT_URL = chimeClientUrl;

export const COURSE_LIST = {
  UPCOMING: 1,
  COMPLETED: 2,
  ALL: 3,
};

export const SOCIAL_LINKS = {
  facebook: "https://www.facebook.com/DDNPlatform",
  twitter: "https://twitter.com/DDNPlatform",
  instagram: "https://www.instagram.com/ddnplatform",
  linkedin: "https://www.linkedin.com/company/dingdongnow",
  youtube: "https://www.youtube.com/channel/UCrW6ih1rdXAI93-27zrfe_Q",
};

// export const CATEGORIES = {
//   BUSINESS_COMMUNICATIONS: "Business Communications",
//   EXAM_PREPARATION: "Exam Preparation",
//   CONVERSATIONAL_ENGLISH: "Conversational English",
//   TRAVEL_ENGLISH: "Travel English",
// };

export const CATEGORIES = {
  "Business Communications": {
    label: "Business Communications",
    color: "pink",
  },
  "Mock Interview": {
    label: "Mock Interview",
    color: "red",
  },
  "Impromptu Public Speaking": {
    label: "Impromptu Public Speaking",
    color: "blue",
  },
  "Academic Exams": {
    label: "Academic Exams",
    color: "purple",
  },
  "Beginner's Comfort Zone": {
    label: "Beginner's Comfort Zone",
    color: "orange",
  },
  "Round Table Talk": {
    label: "Round Table Talk",
    color: "indigo",
  },
};

export const EVENT_STATUSES = {
  UPCOMING: "Upcoming",
  COMPLETED: "Completed",
  DRAFTS: "Drafts",
};

export const RESULTS = {
  MY_LISTED_COURSES: 1,
  UPCOMING_SESSION: 2,
  COMPLETED_SESSION: 3,
  ALL: 4,
  DRAFT_COURSES: 5,
};

export const ATTENDEE_ROLE = {
  ATTENDEE: 1,
  SPEAKER: 2,
};

export const PAYOUT_METHODS = {
  PayPal: "paypal",
  WeChatPay: "wechatpay",
};

export const NOTIFICATION_EVENT = {
  WELCOME: "welcome",
  EVENT_CREATE: "event_create",
  BLOG_CREATE: "blog_create",
  SPEAKER_SEAT_AVAILABLE: "speaker_seat_available",
  HOST_APPLICATION_RECEIVED: "host_application_received",
  HOST_APPLICATION_APPROVED: "host_application_approved",
  HOST_APPLICATION_REJECTED: "host_application_rejected",
  UPCOMING_EVENT_HOST: "upcoming_event_host",
  UPCOMING_EVENT_STUDENT: "upcoming_event_student",
};

export const HOST_APPLICATION_STATUS = {
  SUBMITTED: "submitted",
  IN_REVIEW: "in_review",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
};

export const EXPERTISE_COLOR = {
  "Executive Presence": "blue",
  "Client Management": "teal",
  "Business Presentation": "orange",
  "Critical Thinking": "purple",
  "Academic Excellence": "indigo",
  "Communication Skill": "pink",
  "Interpersonal Skill": "green",
};

export const PROGRAMS_COLOR = {
  "Executive Presence": "blue",
  "Client Management": "teal",
  "Business Presentation": "orange",
  "Critical Thinking": "purple",
  "Academic Excellence": "indigo",
  "Communication Skill": "pink",
  "Interpersonal Skill": "green",
};


export const PROFICIENCY_LEVELS = [
  { label: "A1 - Beginner", value: "A1" },
  { label: "A2 - Elementary", value: "A2" },
  { label: "B1 - Intermediate", value: "B1" },
  { label: "B2 - Upper Intermediate", value: "B2" },
  { label: "C1 - Advanced", value: "C1" },
  { label: "C2 - Proficiency", value: "C2" },
  { label: "D - Native", value: "D" },
];

export const PROFICIENCY_DESCRIPTION = {
  A1: "You can understand about 50% of the conversation, however, you can only reply with basic words, broken sentences and speak slowly. Not much confidence at all.",
  A2: "You can understand about 70%-80% of the conversation, and you can take part in simple exchanges on familiar topics. You feel a little bit confident but struggling with fluent conversation.",
  B1: "You can understand 90%-95% of the conversation, but might not be able to catch some advanced expressions. You are able to engage in a simple conversation and express your opinions using basic grammar and vocabulary.",
  B2: "You understand the conversation. You are able to communicate with native speakers and express your ideas fluently on complex topics however using basic grammar and vocabulary.",
  C1: "You are able to communicate with native speakers and express your ideas fluently on complex topics using complex grammar structure and advanced vocabulary.",
  C2: "You are able to articulate your opinions in any context, have strong logical thinking, and present as a polished conversationalist as an ESL.",
  D: "Native or an ESL equivalent to Native Speaker",
};

export const HOST_TIER = {
  tier_1: {
    label: "Tier 1",
    compensation: 5,
    color: "blue",
    colorClassName: "text-sky-700",
    description:
      "Good English communication skill, and host is capable to drive a conversion in a group.",
  },
  tier_2: {
    label: "Tier 2",
    compensation: 8,
    color: "pink",
    colorClassName: "text-pink-700",
    description:
      "Advanced – Excellent communication skill, and host is able to teach professional jargons and native expressions. ",
  },
  tier_3: {
    label: "Tier 3",
    compensation: 10,
    color: "green",
    colorClassName: "text-green-700",
    description:
      "English Native Speaker who is passion about helping ESL learners to master the skill. ",
  },
};

export const HOST_TIER_OPTIONS = Object.entries(HOST_TIER).map(
  ([tierKey, tier]) => ({ label: tier.label, value: tierKey })
);
