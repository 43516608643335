import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./admin/alert";
import errors from "./admin/errors";
// import alert from "./alert";
// import errors from "./errors";
import user from "./admin/user";
import coursecategory from "./admin/coursecategory";
import coursesubcategory from "./admin/coursesubcategory";
import emailTemplate from "./admin/emailTemplate";
import educator from "./admin/educator";
import logs from "./admin/logs";
import learner from "./admin/learner";
import cms from "./admin/cms";
import inquiry from "./admin/inquiry";
import setting from "./admin/setting";
import profile from "./admin/profile";
import hostApplication from "./admin/hostApplication";

import frontAlert from "./front/frontAlerts";
import frontErrors from "./front/frontErrors";
//front
import myaccount from "./front/myaccount";
import educatorCourse from "./front/educator/course";
import courses from "./admin/courses";
import notification from "./front/notification";
import frontCourseRating from "./front/frontCourseRating";
import categories from "./front/category";
import statistics from "./front/statistics";
import path from "./front/path";

export default combineReducers({
  auth,
  alert,
  user,
  hostApplication,
  coursecategory,
  coursesubcategory,
  educator,
  learner,
  emailTemplate,
  cms,
  errors,
  inquiry,
  notification,
  setting,
  profile,
  myaccount,
  frontErrors,
  frontAlert,
  educatorCourse,
  courses,
  frontCourseRating,
  logs,
  categories,
  statistics,
  path,
});
