import Swal from "sweetalert2";
import { useDispatch } from 'react-redux';
import { getSessions } from "actions/front/common/courseRating";
import {
  deleteCourse,
  generateMeetingLink,
  getCourseById,
} from "actions/front/educator/courses";
import { cancelCourseScheduled } from "actions/front/learner/courses";
import {
  educatorCancelCourseScheduled,
  getEvents,
} from "actions/front/mentorDashboard";
import { ReactComponent as GlobeIcon } from "assets/images/icons/globe.svg";
import Button from "components/common/Button";
import LargeModal from "components/common/Modal/LargeModal";
import NestedPill from "components/common/NestedPill";
import Pill from "components/common/Pill";
import { SanitizeHTML } from "components/common/SanitizeHtml";
import { YourRole } from "components/event/UpcomingEventCardLarge";
import { CHIME_CLIENT_URL, SESSION_TYPE } from "constants";
import format from "date-fns/format";
import inRange from "lodash.inrange";
import * as moment from "moment-timezone";
import { useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { connect } from "react-redux";
import { formatDateShort } from "utils/formatDate";
import getUserAvatar from "utils/getUserAvatar";
import { getCurrentTimeZone } from "utils/time";

function DashboardCalendar({
  calendarView,
  selectedDateRange,
  Toolbar,

  getEvents,
  getSessions,
  user,
  token,

  getCourseById,
  deleteCourse,
  test,
  generateMeetingLink,
  onLoaded,
  refetch,
  onlyId
}) {
  const dispatch = useDispatch();
  const timeZone = getCurrentTimeZone(user);
  let now = moment(
    moment.unix(moment.utc().unix()).tz(timeZone).format("YYYY-MM-DD HH:mm:ss")
  );
  const [events, setEvents] = useState();
  const [sessions, setSessions] = useState();
  const [generatedMeetingId, setGeneratedMeetingId] = useState();

  const [calendarLoader, setCalendarLoader] = useState(false);
  const [maxCancelHr, setMaxCancelHr] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState();

  const localizer = momentLocalizer(moment);
  localizer.formats.timeGutterFormat = "h A";
  let currentDate = moment().tz(timeZone);

  const onClickEvent = (event) => {};

  const eventStyleGetter = (event, start, end, isSelected) => {
    const commonStyles = {
      padding: "8px",
      paddingLeft: "10px",
      opacity: "1.0",
      borderRadius: "4px",
      border: "0px",
      display: "block",
      fontSize: "10px",
      lineHeight: "12px",
      fontWeight: 600,
    };

    const isHosting = event.type !== "session";
    if (isHosting) {
      //var backgroundColor = '#' + event.hexColor;
      // const start_at = moment(start).tz(timeZone).format("YYYY-MM-DD HH:mm");
      const start_at = moment(start);
      // let backgroundColor = start_at > currentDate ? '#3174ad' : 'lightblue';
      let opacity = "";
      if (event.is_booked && !event.is_cancelled) {
        opacity = "0.7"; // for booked and not cancelled
      } else if (start_at.unix() > currentDate.unix()) {
        opacity = "1.0"; // for upcoming and other
      }

      if (start_at.unix() < currentDate.unix()) {
        opacity = "0.7"; // for past
      }

      if (event.is_cancelled) {
        //backgroundColor = ""; // for cancelled
      }

      var style = {
        ...commonStyles,
        backgroundColor: "#FFF4ED",
        color: "#FF692E",
      };
      return {
        style,
        className: "rbc-event--hosting",
      };
    } else {
      const start_at = moment(start);
      // let backgroundColor = start_at > currentDate ? '#3174ad' : 'lightblue';
      let backgroundColor = "#28a745";
      if (event.is_cancelled) {
        backgroundColor = "black"; // if session is cancelled
      } else if (start_at.unix() < currentDate.unix()) {
        backgroundColor = "red"; // for past
      } else if (start_at.unix() > currentDate.unix()) {
        backgroundColor = "#28a745"; // for upcoming and other
      }
      // else {
      //   backgroundColor = "#3174ad"; // for upcoming and other
      // }
      var style = {
        ...commonStyles,
        backgroundColor: "#ECFDFF",
        color: "#06AED4",
      };
      return {
        style,
        className: "rbc-event--attending",
      };
    }
  };

  const onEventClick = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const getCalendarData = () => {
    Promise.all([getEvents(), getSessions()]).then(async (response) => {
      const eventData = response[0]; // hosting
      const sessionData = response[1]; // attending
      if (eventData.status) {
        let eventlists = [];
        setMaxCancelHr(eventData.response.settings.cancellation_maxhour);
        let sessionPromise = await eventData.response.events.map(
          async (singleSession) => {
            if (
              (singleSession.session_type !== SESSION_TYPE.GENERIC &&
                singleSession.session_type !== SESSION_TYPE.LIVE_EVENT) ||
              (singleSession.session_type === SESSION_TYPE.GENERIC &&
                singleSession.session_booking_count === 0)
            ) {
              let start_time = moment
                .unix(singleSession.start_at)
                .tz(timeZone)
                .format("YYYY-MM-DDTHH:mm:ssZ");
              let end_time = moment
                .unix(singleSession.end_at)
                .tz(timeZone)
                .format("YYYY-MM-DDTHH:mm:ssZ");

              eventlists.push({
                ...singleSession,
                isHosting: true,
                type: "event",
                start: new Date(start_time),
                end: new Date(end_time),
                title: singleSession.course_name,
                course_id: singleSession.course_id,
                is_booked:
                  singleSession.session_booking_count > 0 ? true : false,
                all_session_with_recurring_id:
                  singleSession.session_type !== SESSION_TYPE.GENERIC
                    ? singleSession.all_session_with_recurring_id
                    : "",
                session_group_id: singleSession.session_group_id,
                start_at: singleSession.start_at,
                end_at: singleSession.end_at,
                course_image: singleSession.course_image,
                session_booking_count: singleSession.session_booking_count,
                detail_for: singleSession.session_type,
                check_session_cancelation_start_at:
                  singleSession.check_session_cancelation_start_at,
                is_cancelled:
                  singleSession.session_type !== SESSION_TYPE.GENERIC
                    ? singleSession.is_cancelled
                      ? singleSession.is_cancelled
                      : false
                    : false,
              });
            } else if (singleSession.session_type === SESSION_TYPE.LIVE_EVENT) {
              let start_time = moment
                .unix(singleSession.start_at)
                .tz(timeZone)
                .format("YYYY-MM-DDTHH:mm:ssZ");
              let end_time = moment
                .unix(singleSession.start_at)
                .add(
                  singleSession.duration?.[0].duration_per_session,
                  "minutes"
                )
                .tz(timeZone)
                .format("YYYY-MM-DDTHH:mm:ssZ");

              eventlists.push({
                ...singleSession,
                isHosting: true,
                type: "event",
                start: new Date(start_time),
                end: new Date(end_time),
                title: singleSession.course_name,
                course_id: singleSession.course_id,
                is_booked:
                  singleSession.session_booking_count > 0 ? true : false,
                session_booking_count: singleSession.session_booking_count,
                course_image: singleSession.course_image,
                all_session_with_recurring_id:
                  singleSession.all_session_with_recurring_id,
                session_group_id: singleSession.session_group_id,
                start_at: singleSession.start_at,
                end_at: singleSession.end_at,
                detail_for: singleSession.session_type,
                check_session_cancelation_start_at:
                  singleSession.check_session_cancelation_start_at,
                is_cancelled:
                  singleSession.session_type !== SESSION_TYPE.GENERIC
                    ? singleSession.is_cancelled
                      ? singleSession.is_cancelled
                      : false
                    : false,
              });
            } else {
              let sortedSession = await Promise.all(
                singleSession.booked_sessions.sort(
                  (a, b) => a.start_at - b.start_at
                )
              );

              if (sortedSession.length !== 0) {
                if (sortedSession[0].start_at !== singleSession.start_at) {
                  let start_time = moment
                    .unix(singleSession.start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
                  let end_time = moment
                    .unix(sortedSession[0].start_at)
                    .add(
                      sortedSession[0].duration?.[0].duration_per_session,
                      "minutes"
                    )
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");

                  eventlists.push({
                    ...singleSession,
                    isHosting: true,
                    start: new Date(start_time),
                    end: new Date(end_time),
                    title: singleSession.course_name,
                    course_id: singleSession.course_id,
                    is_booked: false,
                    session_booking_count: singleSession.session_booking_count,
                    end_at: singleSession.end_at,
                    course_image: singleSession.course_image,
                    all_session_with_recurring_id: "",
                    session_group_id: singleSession.session_group_id,
                    start_at: singleSession.start_at,
                    end_at: sortedSession[0].start_at,
                    detail_for: singleSession.session_type,
                    check_session_cancelation_start_at:
                      singleSession.check_session_cancelation_start_at,
                    is_cancelled: false,
                  });
                }
                let sessionIndex = 0;
                while (sessionIndex < sortedSession.length) {
                  let start_time = moment
                    .unix(sortedSession[sessionIndex].start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
                  let end_time = moment
                    .unix(sortedSession[sessionIndex].end_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");

                  eventlists.push({
                    ...singleSession,
                    isHosting: true,
                    start: new Date(start_time),
                    end: new Date(end_time),
                    title: singleSession.course_name,
                    course_id: singleSession.course_id,
                    is_booked: true,
                    all_session_with_recurring_id: "",
                    session_group_id: singleSession.session_group_id,
                    start_at: sortedSession[sessionIndex].start_at,
                    end_at: sortedSession[sessionIndex].end_at,
                    detail_for: singleSession.session_type,
                    check_session_cancelation_start_at:
                      singleSession.check_session_cancelation_start_at,
                    is_cancelled:
                      sortedSession[sessionIndex].is_cancelled === 2
                        ? true
                        : false,
                    session_booking_count: singleSession.session_booking_count,
                    course_image: singleSession.course_image,
                  });

                  if (
                    sessionIndex + 1 !== sortedSession.length &&
                    sortedSession[sessionIndex].end_at !=
                      sortedSession[sessionIndex + 1].start_at
                  ) {
                    let start_time = moment
                      .unix(sortedSession[sessionIndex].end_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
                    let end_time = moment
                      .unix(sortedSession[sessionIndex + 1].start_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");

                    eventlists.push({
                      ...singleSession,
                      isHosting: true,
                      start: new Date(start_time),
                      end: new Date(end_time),
                      title: singleSession.course_name,
                      course_id: singleSession.course_id,
                      is_booked: true,
                      all_session_with_recurring_id: "",
                      session_group_id: singleSession.session_group_id,
                      start_at: sortedSession[sessionIndex].end_at,
                      end_at: sortedSession[sessionIndex + 1].start_at,
                      detail_for: singleSession.session_type,
                      is_cancelled: false,
                      session_booking_count:
                        singleSession.session_booking_count,
                      course_image: singleSession.course_image,
                      check_session_cancelation_start_at:
                        singleSession.check_session_cancelation_start_at,
                    });
                  }

                  sessionIndex++;
                }

                if (
                  sortedSession[sortedSession.length - 1].end_at !=
                  singleSession.end_at
                ) {
                  let start_time = moment
                    .unix(sortedSession[sortedSession.length - 1].end_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
                  let end_time = moment
                    .unix(singleSession.end_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");

                  eventlists.push({
                    ...singleSession,
                    isHosting: true,
                    start: new Date(start_time),
                    end: new Date(end_time),
                    title: singleSession.course_name,
                    course_id: singleSession.course_id,
                    is_booked: false,
                    all_session_with_recurring_id: "",
                    session_group_id: singleSession.session_group_id,
                    start_at: sortedSession[sortedSession.length - 1].end_at,
                    end_at: singleSession.end_at,
                    detail_for: singleSession.session_type,
                    is_cancelled: false,
                    check_session_cancelation_start_at:
                      singleSession.check_session_cancelation_start_at,
                    session_booking_count: singleSession.session_booking_count,
                    course_image: singleSession.course_image,
                  });
                }
              } else {
                let start_time = moment
                  .unix(singleSession.start_at)
                  .tz(timeZone)
                  .format("YYYY-MM-DDTHH:mm:ssZ");
                let end_time = moment
                  .unix(singleSession.end_at)
                  .tz(timeZone)
                  .format("YYYY-MM-DDTHH:mm:ssZ");

                eventlists.push({
                  ...singleSession,
                  isHosting: true,
                  start: new Date(start_time),
                  end: new Date(end_time),
                  title: singleSession.course_name,
                  course_id: singleSession.course_id,
                  is_booked:
                    singleSession.session_booking_count > 0 ? true : false,
                  all_session_with_recurring_id: "",
                  session_group_id: singleSession.session_group_id,
                  start_at: singleSession.start_at,
                  end_at: singleSession.end_at,
                  detail_for: singleSession.session_type,
                  check_session_cancelation_start_at:
                    singleSession.check_session_cancelation_start_at,
                  is_cancelled: singleSession.is_cancelled === 2 ? true : false,
                  session_booking_count: singleSession.session_booking_count,
                  course_image: singleSession.course_image,
                });
              }
            }
          }
        );
        await Promise.all(sessionPromise);
        setEvents(eventlists);
        if (onLoaded) {
          const daysSet = new Set(
            eventlists.map((item) => item.start.toLocaleDateString())
          );
          onLoaded([...daysSet].map((i) => new Date(i)));
        }
      }
      if (sessionData.status) {
        let eventlists = [];
        let promise = await sessionData.response.map(
          async (singleSessionGroup, i) => {
            if (singleSessionGroup.session_type === SESSION_TYPE.GENERIC) {
              let start_time = moment
                .unix(singleSessionGroup.session_detail[0].start_at)
                .tz(timeZone)
                .format("YYYY-MM-DDTHH:mm:ssZ");
              let end_time = moment
                .unix(
                  singleSessionGroup.session_detail[
                    singleSessionGroup.session_detail.length - 1
                  ].end_at
                )
                .tz(timeZone)
                .format("YYYY-MM-DDTHH:mm:ssZ");
              eventlists.push({
                ...singleSessionGroup,
                type: "session",
                start: new Date(start_time),
                end: new Date(end_time),
                start_at: singleSessionGroup.session_detail[0].start_at,
                end_at: singleSessionGroup.session_detail[0].end_at,
                title: singleSessionGroup.course_name,
                course_id: singleSessionGroup.course_id,
                course_image: singleSessionGroup.course_image,
                detail_for: singleSessionGroup.session_type,
                mentor_id: singleSessionGroup.mentor_id,
                mentor_name: singleSessionGroup.mentor_name,
                is_cancelled:
                  singleSessionGroup.is_cancelled === 3 ||
                  singleSessionGroup.is_cancelled === 2
                    ? true
                    : false,
                mentor_username: singleSessionGroup.mentor_username,
              });
            } else if (
              singleSessionGroup.session_type === SESSION_TYPE.LIVE_EVENT
            ) {
              let sessionPromise = await singleSessionGroup.session_detail.map(
                (session) => {
                  let start_time = moment
                    .unix(session.start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
                  let end_time = moment
                    .unix(session.start_at)
                    .add(
                      singleSessionGroup.duration?.[0]?.[0]
                        .duration_per_session,
                      "minutes"
                    )
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
                  eventlists.push({
                    ...singleSessionGroup,
                    type: "session",
                    start: new Date(start_time),
                    end: new Date(end_time),
                    start_at: singleSessionGroup.session_detail[0].start_at,
                    end_at: singleSessionGroup.session_detail[0].end_at,
                    title: singleSessionGroup.course_name,
                    course_id: singleSessionGroup.course_id,
                    course_image: singleSessionGroup.course_image,
                    detail_for: singleSessionGroup.session_type,
                    mentor_id: singleSessionGroup.mentor_id,
                    mentor_name: singleSessionGroup.mentor_name,
                    is_cancelled:
                      singleSessionGroup.is_cancelled === 3 ||
                      singleSessionGroup.is_cancelled === 2
                        ? true
                        : false,
                    mentor_username: singleSessionGroup.mentor_username,
                  });
                }
              );
              await Promise.all(sessionPromise);
            } else {
              let sessionPromise = await singleSessionGroup.session_detail.map(
                (session) => {
                  let start_time = moment
                    .unix(session.start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
                  let end_time = moment
                    .unix(session.end_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
                  eventlists.push({
                    ...singleSessionGroup,
                    type: "session",
                    start: new Date(start_time),
                    end: new Date(end_time),
                    start_at: singleSessionGroup.session_detail[0].start_at,
                    end_at: singleSessionGroup.session_detail[0].end_at,
                    title: singleSessionGroup.course_name,
                    course_id: singleSessionGroup.course_id,
                    course_image: singleSessionGroup.course_image,
                    detail_for: singleSessionGroup.session_type,
                    mentor_id: singleSessionGroup.mentor_id,
                    mentor_name: singleSessionGroup.mentor_name,
                    is_cancelled:
                      singleSessionGroup.is_cancelled === 3 ||
                      singleSessionGroup.is_cancelled === 2
                        ? true
                        : false,
                    mentor_username: singleSessionGroup.mentor_username,
                  });
                }
              );
              await Promise.all(sessionPromise);
            }
          }
        );
        await Promise.all(promise);
        // console.log(eventlists);
        setSessions(eventlists);
      }
      setCalendarLoader(false);
    });
  };

  useMemo(() => {
   console.log("onlyId====>",onlyId)
   getCalendarData();
  }, [onlyId]);
  const [isEventActivating, setEventActivating] = useState(false);

  const activateEvent = () => {
    setEventActivating(true);
    getCourseById(selectedEvent?.course_id).then((res) => {
      const recurringId =
        res.data.session_detail[0].session_group[0]
          .all_session_with_recurring[0]._id;
      generateMeetingLink(recurringId, selectedEvent?.course_id).then((res) => {
        setGeneratedMeetingId(res.meetingId);
        setEventActivating(false);
      });
    });
  };


  const confirmCancel = async (modalData) => {
    try{
      educatorCancelCourseScheduled(modalData).then(res=>{
        setIsModalOpen(false);
        if(res.status){
          setCalendarLoader(true);
          getCalendarData();
          refetch()
        }else{
          Swal.fire("Oops...", res.message, "error");
        }
      });
    }catch(ex){
      console.error(ex)
    }
  };


  const [isModalOpen, setIsModalOpen] = useState(false);
    console.log(events,"1events")
    console.log(sessions,"sessions")
  return (
    <>
      <LargeModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <h2 className="text-3xl font-semibold mt-9 mb-4">
          {selectedEvent?.title}
        </h2>
        <div className="flex gap-2.5 mb-4">
          <img
            src={getUserAvatar(selectedEvent?.mentor_avatar)}
            alt="host avatar"
            className="rounded-full w-10 h-10"
          />
          <div>
            <div className="text-sm font-medium">
              {selectedEvent?.mentor_name}
            </div>
            <div className="text-xs text-dgray-500">
              <GlobeIcon className="inline mr-1" />
              {selectedEvent?.host ? (
                selectedEvent?.host?.country
              ) : (
                <>
                  {selectedEvent?.mentor_city ?? (
                    <span>
                      {selectedEvent?.mentor_city}
                      {", "}
                    </span>
                  )}
                  {selectedEvent?.mentor_country ?? (
                    <>{selectedEvent?.mentor_country}</>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="text-sm font-semibold">
          {formatDateShort(selectedEvent?.start_at * 1000)}
        </div>
        <div className="text-sm text-dgray-500 mb-4">
          {getTimezoneOffset(selectedEvent?.start_at * 1000)}
        </div>
        <YourRole
          user={user}
          cohosts={selectedEvent?.cohosts}
          isHosting={selectedEvent?.isHosting}
          learnerRole={selectedEvent?.learner_role}
        />
        <div className="flex justify-between items-center mt-8 mb-6">
          <NestedPill
            innerText={
              selectedEvent?.course_type === SESSION_TYPE.LIVE_EVENT
                ? "Public Event"
                : "Private Event"
            }
            // outerText={`${selectedEvent?.speakerCount} Speaker Seats`}
          />
          <Pill color="blue" text={selectedEvent?.category_name} />
        </div>
        <div className="text-dgray-500 mb-12">
          <SanitizeHTML html={selectedEvent?.course_overview} />
        </div>
        {moment(selectedEvent?.start_at * 1000).isAfter(
          moment().subtract(1, "hours")
        ) && (
          <>
            <div className="text-primary-700 font-medium mb-12">
              Add this event to my external calendar
            </div>
            <div className="flex gap-3 items-center">
              {selectedEvent?.isHosting ? (selectedEvent.course_type == SESSION_TYPE.GENERIC) ? null : (
                <Button
                  className="flex-1 !bg-white border border-red-300"
                  small
                  secondary
                  onClick={async () =>confirmCancel({
                    all_session_with_recurring_id:
                      selectedEvent?.all_session_with_recurring_id,
                    course_id: selectedEvent?.course_id,
                    session_group_id: selectedEvent?.session_group_id,
                    start_at: selectedEvent?.start_at,
                    end_at: selectedEvent?.end_at,
                    detail_for: selectedEvent?.session_type,
                  })}
                >
                  Cancel Event
                </Button>
              ) : (
                <Button
                  className="flex-1 !bg-white border border-red-300"
                  small
                  secondary
                  onClick={async () => {
                    try {
                     const res=await cancelCourseScheduled(selectedEvent?._id);
                    if(res.status){
                      getCalendarData();
                      refetch()
                      setIsModalOpen(false);
                    }else{
                      Swal.fire("Oops...", res.msg, "error");
                    }
                    } catch (ex) {
                      console.log(ex,"exexex")
                    }
                  }}
                >
                  Cancel my listener seat
                </Button>
              )}

              {selectedEvent?.isHosting ? (selectedEvent.course_type == SESSION_TYPE.GENERIC) ? null : (
                generatedMeetingId ? (
                  <Button
                    small
                    target="_blank"
                    external={true}
                    href={`${CHIME_CLIENT_URL}join/${generatedMeetingId}?token=${token}`}
                  >
                    Join the event
                  </Button>
                ) : (
                  <>
                    {selectedEvent?.meeting_id ? (
                      <Button
                        small
                        target="_blank"
                        external={true}
                        href={`${CHIME_CLIENT_URL}join/${selectedEvent?.meeting_id}?token=${token}`}
                      >
                        Join the event
                      </Button>
                    ) : (
                      <>
                        {inRange(
                          moment
                            .duration(
                              now.diff(
                                moment
                                  .unix(selectedEvent?.start_at)
                                  .tz(timeZone)
                                  .format("YYYY-MM-DD HH:mm:ss")
                              )
                            )
                            .asMinutes(),
                          -5,
                          10
                        ) ? (
                          <>
                            <label className="text-dgray-500 text-sm">
                              Event starting in{" "}
                              {moment
                                .duration(
                                  now.diff(
                                    moment
                                      .unix(selectedEvent?.start_at)
                                      .tz(timeZone)
                                      .format("YYYY-MM-DD HH:mm:ss")
                                  )
                                )
                                .asMinutes()
                                .toFixed()}{" "}
                              mins
                            </label>
                            <Button
                              small
                              onClick={activateEvent}
                              loading={isEventActivating}
                            >
                              Activate the event
                            </Button>
                          </>
                        ) : (
                          <>
                            <label className="text-dgray-500 text-sm">
                              Event will be activated prior to starting time
                            </label>
                            <Button
                              small
                              disabled
                              className="disabled:bg-dgray-200"
                            >
                              Activate the event
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </>
                )
              ) : (
                <>
                  {selectedEvent?.session_detail?.[0].meeting_id ? (
                    <>
                      <label className="text-dgray-500 text-sm">
                        Event will be activated prior to starting time
                      </label>
                      <Button
                        small
                        external={true}
                        href={`${CHIME_CLIENT_URL}join/${selectedEvent?.session_detail?.[0].meeting_id}?token=${token}`}
                      >
                        Attend the event
                      </Button>
                    </>
                  ) : (
                    <>
                      <label className="text-dgray-500 text-sm">
                        Event will be activated prior to starting time
                      </label>
                      <Button small disabled className="disabled:bg-dgray-200">
                        Attend the event
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </LargeModal>
      <Calendar
        scrollToTime={new Date()}
        components={{
          toolbar: Toolbar ? () => <Toolbar /> : CustomToolbar,
          header: ({ date }) => {
            return (
              <div className="flex flex-col">
                <span>{format(date, "E")}</span>
                {calendarView.value === "week" ? (
                  <span>{format(date, "d")}</span>
                ) : null}
              </div>
            );
          },
        }}
        onSelectEvent={(event) => onEventClick(event)}
        localizer={localizer}
        date={selectedDateRange}
        events={events && sessions ? [...events, ...sessions] : []}
        startAccessor="start"
        endAccessor="end"
        defaultView={calendarView.value}
        view={calendarView.value}
        eventPropGetter={eventStyleGetter}
      />
    </>
  );
}

export default connect(
  (state) => ({
    user: state.auth.user,
    token: state.auth.token,
  }),
  {
    getEvents,
    getSessions,
    getCourseById,
    generateMeetingLink,
    deleteCourse,
  }
)(DashboardCalendar);

const CustomToolbar = ({ label, ...props }) => (
  <h1 className="text-primary-500 text-left mb-8">{label}</h1>
);

function getTimezoneOffset(date) {
  const split = new Date(date).toString().split(" ");
  return split[split.length - 1].slice(1, -1);
}
