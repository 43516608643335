import { ReactComponent as InfoCircle } from "assets/images/icons/info-circle.svg";
import Button from "components/common/Button";
import LargeModal from "components/common/Modal/LargeModal";
import Pill from "components/common/Pill";
import { HOST_TIER } from "constants";
import IconCircle from "views/Front/HomePage/IconCircle";

export default function TierInfoModal({ isOpen, setIsOpen }) {
  return (
    <LargeModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <IconCircle color="green" className="mx-auto mb-4">
        <InfoCircle />
      </IconCircle>
      <h2 className="text-2xl font-semibold text-center mb-2">Host tiers</h2>
      <p className="text-dgray-500 md:text-sm text-lg mb-8">
        DDN pays our host based on proficiency level. We have three different
        tiers for hosts:
      </p>
      <div className="space-y-8">
        {Object.values(HOST_TIER).map((tier) => (
          <div key={tier.description}>
            <div className="flex items-center gap-2 mb-3">
              <Pill text={tier.label} color={tier.color} />
              <div className={`${tier.colorClassName}`}>
                Compensated at ${tier.compensation}
                /event
              </div>
            </div>
            <p className="text-sm text-dgray-500">{tier.description}</p>
          </div>
        ))}
      </div>
      <div className="text-center">
        <Button
          type="button"
          className="w-1/2 md:w-full mt-8"
          onClick={() => setIsOpen(false)}
          small
        >
          OK
        </Button>
      </div>
    </LargeModal>
  );
}
