import TextInput from "components/common/form/TextInput";
import { GoogleApiWrapper } from "google-maps-react";
import { useEffect, useRef } from "react";
import { connect } from "react-redux";

const CitySearchInput = ({
  google,

  country,
  label,
  onChange,
  placeholder = "Search city",
  ...props
}) => {
  const { dispatch, loaded, ...rest } = props;
  const autocompleteEl = useRef(null);

  const renderAutoComplete = () => {
    if (!google || !country) return;

    const autocomplete = new google.maps.places.Autocomplete(
      autocompleteEl.current
    );
    autocomplete.setTypes(["(cities)"]);
    autocomplete.setComponentRestrictions({ country });

    autocomplete.addListener("place_changed", () => {
      onChange(autocomplete.getPlace());
    });
  };

  useEffect(() => {
    renderAutoComplete();
  }, [autocompleteEl.current, country]);

  return (
    <TextInput
      {...rest}
      placeholder={placeholder}
      className="form-control w-full"
      label={label}
      ref={autocompleteEl}
    />
  );
};

export default connect((state) => ({
  google: state.google,
  map: state.map,
}))(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })(CitySearchInput)
);
