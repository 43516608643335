import FormSteps from "components/common/FormSteps";
import CreateHero from "components/common/Hero/Create";

export default function FormHero({ currentStep, title, items }) {
  return (
    <>
      {currentStep <= items.length && (
        <>
          <CreateHero title={title} />
          <FormSteps
            currentStep={currentStep}
            className="-mt-10 mb-24 lg:mb-12"
            items={items}
          />
        </>
      )}
    </>
  );
}
