import checkIcon from "assets/images/icons/check-orange.svg";
import image1 from "assets/images/landing-how1.jpg";
import image2 from "assets/images/landing-how2.jpg";
import image3 from "assets/images/landing-how3.jpg";
import image4 from "assets/images/landing-how4.jpg";
import CenterContainer from "components/common/Container/Center";

export default function HomePageHowItWorks() {
  return (
    <div>
      <CenterContainer className="py-24 md:py-12">
        <div className="text-center mb-32 md:mb-6">
          <h2 className="text-4xl md:text-3xl font-semibold mb-5">
            How it works
          </h2>
          <p className="text-dgray-500 text-xl md:text-lg">
            You can enroll in curated programs and events with qualified hosts
            to improve your spoken English
          </p>
        </div>
      </CenterContainer>
      <div className="space-y-24 md:space-y-14">
        {items.map((item, i) => (
          <div
            key={i}
            className={`flex gap-24 md:gap-10 justify-between md:flex-col
              ${item.reverse ? "flex-row-reverse" : ""}`}
          >
            <div
              className={`basis-1/2 md:px-10 sm:px-4 ${
                item.reverse
                  ? "pr-64 2xl:pr-32 xl:pr-24"
                  : "pl-64 2xl:pl-32 xl:pl-24"
              }`}
            >
              <div className="text-primary-600 text-2xl font-semibold mb-2 md:mb-6">
                {item.label}
              </div>
              <h3 className="text-3xl md:text-2xl font-semibold mb-4">
                {item.title}
              </h3>
              <div className="text-lg md:text-base text-dgray-500 mb-8">
                {item.description}
              </div>
              <ul className="space-y-5 text-lg md:text-base text-dgray-500">
                {item.items.map((text, j) => (
                  <li key={j} className="flex">
                    <div className="shrink-0 bg-primary-100 rounded-full h-7 w-7 flex justify-center items-center mr-3">
                      <img src={checkIcon} alt="check icon" />
                    </div>
                    <p>{text}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="shrink-0 basis-1/2 md:px-10 sm:px-4">
              <img
                src={item.image}
                alt=""
                className="h-[35rem] md:h-80 sm:h-60 w-full object-cover"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const items = [
  {
    label: "01",
    title: "Explore Programs and Hosts to Guide Your English Practice",
    description:
      "Find carefully designed programs and topics to practice speaking. Connect with proficient English hosts from all around the world.",
    items: [
      "business communication",
      "Variety of sessions and events for different proficiency levels",
    ],
    reverse: false,
    image: image1,
  },
  {
    label: "02",
    title: "Sign Up and Attend Events and Sessions as a Speaker or Listener",
    description: "Found what you are looking for? Book your event right away.",
    items: [
      "Interact in smaller groups to get more time to speak and practice",
      "Not ready to speak and practice? No problem. Join  as a listener member to listen to various perspectives for free",
    ],
    reverse: true,
    image: image2,
  },
  {
    label: "03",
    title: "Engage, Interact, Practice and  Build Confidence",
    description: "Make progress with interactive, live sessions.",
    items: [
      "See yourself make progress and gain confidence",
      "Engage in higher proficiency sessions as you get better",
    ],
    reverse: false,
    image: image3,
  },
  {
    label: "... OR",
    title: "Become a Host!",
    description:
      "Apply with us to become a host on DDN.  We are selective  and carefully review all host applications.",
    items: [
      "Mentor and guide students on your time",
      "Flexible and open programs and sessions delivered as you see fit",
      "Make money and build your reputation to reach a global audience",
    ],
    reverse: true,
    image: image4,
  },
];
