/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
import Spinner from "views/Spinner";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { getHostApplicationList } from "actions/admin/hostApplications";
import * as Constants from "constants/index";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import overlayFactory from "react-bootstrap-table2-overlay";
//import CustomFilters from "./CustomFilters";

import { Button, Card, CardBody, Col, Row } from "reactstrap";

const ApplicationList = ({
  getHostApplicationList,
  applicationList: { data, count, page },
  sortingParams,
  loading,
  loadingListData,
}) => {
  console.log(data, "-- data --");
  //################## Initillise sorting searching parameters by default values ###################
  const [userParams, setUserParams] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
  });

  //################## to get updated list ###################

  useEffect(() => {
    getHostApplicationList(userParams);
  }, [getHostApplicationList, userParams]);

  //################## Handel table change(sorting, searching, status change) ###################
  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    setUserParams({
      ...userParams,
      page: type === "search" ? 1 : page,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      query: searchText,
    });
  };

  //#########################Colums defination start ###################

  const columns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "last_name",
      text: "Last Name",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "submitted_date",
      text: "Submitted Date",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      formatter: (cellContent, row) => (
        <Moment format="YYYY/MM/DD">{Number(row.submitted_date)}</Moment>
      ),
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: (cellContent, row) => (
        <div>
          <Link to={`/admin/host-applications/${row._id}`}>
            <Button type="button" size="sm" color="success">
              Details
            </Button>
          </Link>
          <Button
            type="button"
            size="sm"
            color="danger"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure you want to deny :\r ${row.name}?`
                )
              ) {
                
              }
            }}
          >
            Deny
          </Button>
        </div>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
  ];

  //#########################Colums defination end ###################

  //################## START Set properties(options) of datatables ###################

  // const headerSortingStyle = { backgroundColor: '#c8e6c9' };
  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li
      key={text}
      role="presentation"
      className="dropdown-item"
      onMouseDown={(e) => {
        e.preventDefault();
        onSizePerPageChange(page);
      }}
    >
      <Button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        className="sizePerPageaTag"
      >
        {text}
      </Button>
    </li>
  );

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <>
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    </>
  );

  const options = {
    page: parseInt(userParams.page),
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: count,
    sizePerPageOptionRenderer,
    sizePerPageList: [
      {
        text: Constants.DEFAULT_PAGE_SIZE,
        value: Constants.DEFAULT_PAGE_SIZE,
      },
      {
        text: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
        value: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
      },
      {
        text: "All",
        value: count,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <div className="animated fadeIn displayTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(options)}
                keyField="email"
                columns={columns}
                data={data}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="email"
                    data={data}
                    columns={columns}
                    search
                  >
                    {(toolkitprops) => (
                      <>
                        <>
                          <div className="per-page-div">
                            Record per page:
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          {/*<CustomFilters {...toolkitprops.searchProps} />*/}
                        </>
                        <BootstrapTable
                          {...toolkitprops.baseProps}
                          bootstrap4
                          remote={{
                            pagination: true,
                            filter: true,
                            sort: true,
                          }}
                          keyField="email"
                          data={data}
                          columns={columns}
                          {...paginationTableProps}
                          onTableChange={handleTableChange}
                          defaultSorted={defaultSorted}
                          noDataIndication={Constants.NO_RECORD}
                          bordered={false}
                          hover
                          loading={loading && !subLoading}
                          overlay={overlayFactory({
                            spinner: <Spinner />,
                            styles: {
                              overlay: (base) => ({
                                ...base,
                                background: "#E8E8E8",
                              }),
                            },
                          })}
                        />
                      </>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  applicationList: state.hostApplication.applicationList,
  loading: state.hostApplication.loading,
});

export default connect(mapStateToProps, {
  getHostApplicationList,
})(withRouter(ApplicationList));
