import { getCourseDetailById } from "actions/front/common/course";
import {
  createCourse,
  createDraftCourse,
  editDraftCourse,
  getDraftCourseById,
  editCourse,
} from "actions/front/educator/courses";
import { getEventDetail } from "actions/front/mentorDashboard";
import CenterContainer from "components/common/Container/Center";
import FormHero from "components/create-form/Hero";
import { RECURRING_TYPE_REVERSE } from "constants";
import { SESSION_TYPE } from "constants";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import StepWizard from "react-step-wizard";
import prepareOneOnOneEventData from "utils/createEvent/prepareOneOnOneEventData";
import createOneOnOneEventSchema from "utils/schemas/createOneOnOneEvent";
import Footer from "views/Front/Layout/Footer";
import Header from "views/Front/Layout/Header";
import Spinner from "views/Spinner";
import CreateEventOneOnOneStep1 from "./Step1";
import CreateEventOneOnOneStep2, { initialNewDuration } from "./Step2";
import { initialNewEventAvailability } from "./Step2/EventRows";
import CreateEventOneOnOneStep3 from "./Step3";
import CreateEventOneOnOneStep4 from "./Step4";

function CreateEventOneOnOne({
  createCourse,
  createDraftCourse,
  getDraftCourseById,
  editDraftCourse,
  getCourseDetailById,
  editCourse,
  getEventDetail,
}) {
  const [preparedData, setPreparedData] = useState(null);
  const [error, setError] = useState(null);
  const [draftError, setDraftError] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const location = useLocation();
  const draftId = new URLSearchParams(location.search).get("draftId");
  const editId = new URLSearchParams(location.search).get("editId");
  const [draftEvent, setDraftEvent] = useState();
  const [editableEvent, setEditableEvent] = useState();
  const [attendeeCount, setAttendeeCount] = useState(0);
  const [initialAvailabilities, setInitialAvailabilities] = useState([
    initialNewEventAvailability,
  ]);

  const getEditCourseDetails = () => {
    Promise.all([getCourseDetailById(editId), getEventDetail(editId)]).then(
      ([courseResponse, eventDetailResponse]) => {
        if (courseResponse.status) {
          console.log("getCourseDetailById", courseResponse.data);
          setEditableEvent(courseResponse.data);
        } else {
          setDraftError("Event not found");
        }
        if (eventDetailResponse.status) {
          const attendees = eventDetailResponse.response.map(
            (item) => item.learner
          );
          setAttendeeCount(attendees.length);
        }
        setLoading(false);
      }
    );
  };

  const getDraftCourse = async (id) => {
    setLoading(true);
    const res = await getDraftCourseById(id);
    if (res.status) {
      setDraftEvent(res.data);
    } else {
      setDraftError("Draft event not found");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (editId) {
      getEditCourseDetails(editId);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (draftId) {
      getDraftCourse(draftId);
    } else {
      setLoading(false);
    }
  }, []);

  const onEditEvent = async () => {
    const res = await editCourse(
      editId,
      {
        ...preparedData.courseInformation,
        isNewFileSelected:
          preparedData.courseInformation.image &&
          typeof preparedData.courseInformation.image === "object"
            ? 1
            : 0,
      },
      preparedData.sessionData,
      preparedData.typeOfSession
    );
    console.log("=================res",res);
    if (res.status) {
      return true;
    } else {
      setError(res.errors ? res.errors[0].msg : res.msg);
      return false;
    }
  };

  const onSaveDraft = async (values) => {
    if (draftEvent) {
      const prepData = prepareOneOnOneEventData(values, {
        oldImage: undefined,
        isNewFileSelected: 0,
      });
      const res = await editDraftCourse(
        draftId,
        prepData.courseInformation,
        prepData.sessionData,
        prepData.typeOfSession
      );
      return res;
    } else {
      const prepData = prepareOneOnOneEventData(values);
      const res = await createDraftCourse(
        prepData.courseInformation,
        prepData.sessionData,
        prepData.typeOfSession
      );
      return res;
    }
  };

  useEffect(() => {
    if (editableEvent) {
      const formEvents = editableSessionsToAvailabilities(
        editableEvent.session_detail
      );
      setInitialAvailabilities(formEvents);
    } else if (draftEvent) {
      const formEvents = draftSessionsToAvailabilities(
        draftEvent.session_detail
      );
      setInitialAvailabilities(formEvents);
    }
  }, [editableEvent, draftEvent]);

  return (
    <>
      <Header />
      <CenterContainer className="pb-24 md:pb-16">
        {isLoading ? (
          <Spinner />
        ) : draftError ? (
          <div className="text-center text-red-500">{draftError}</div>
        ) : (
          <Formik
            enableReinitialize
            initialValues={{
              category:
                editableEvent?.category_id ||
                draftEvent?.category_id ||
                undefined,
              title: editableEvent?.name || draftEvent?.name || "",
              description:
                editableEvent?.course_information ||
                draftEvent?.course_information ||
                "",
              idealFor: editableEvent?.ideal_for || draftEvent?.idealFor || "",
              image:
                editableEvent?.image && editableEvent?.image !== "undefined"
                  ? editableEvent.image
                  : draftEvent?.image && draftEvent?.image !== "undefined"
                  ? draftEvent.image
                  : undefined,
              interaction: "one_on_one",
              durations: editableEvent
                ? editableEvent.session_detail[0].duration_info.map((dur) => ({
                    value: dur.duration_per_session,
                    price: dur.price_per_session,
                  }))
                : draftEvent
                ? draftEvent.session_detail[0].duration_info.map((dur) => ({
                    value: dur.duration_per_session,
                    price: dur.price_per_session,
                  }))
                : [initialNewDuration],
              events: initialAvailabilities,
            }}
            validationSchema={createOneOnOneEventSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setError(null);
              if (editableEvent) {
                let res = await onEditEvent();
                if(res){
                  return true;
                }else{
                  setSubmitting(false);
                  return false
                }
              } else {
                const res = await createCourse(
                  preparedData.courseInformation,
                  preparedData.sessionData,
                  preparedData.typeOfSession
                );
                if (!res.status) {
                  // error
                  setError(res.errors ? res.errors[0].msg : res.msg);
                  setSubmitting(false);
                  return false;
                } else {
                  // success
                  return true;
                }
              }
            }}
          >
            {({ values, errors }) => (
              <Form>
                <StepWizard
                  transitions
                  nav={
                    <FormHero
                      title={`${
                        editId ? "Edit your" : "Create a"
                      } one on one session`}
                      items={[
                        {
                          title: "Event Information",
                          subtitle: "Event Interaction Details",
                        },
                        {
                          title: "Manage Availability",
                          subtitle: "Set up event availability",
                        },
                        {
                          title: "Review & Confirm",
                          subtitle: "Review event information and publish",
                        },
                      ]}
                    />
                  }
                >
                  <CreateEventOneOnOneStep1 onSaveDraft={onSaveDraft} />
                  <CreateEventOneOnOneStep2
                    disabled={attendeeCount > 0}
                    onSaveDraft={onSaveDraft}
                    onClickNext={() => {
                      if (Object.keys(errors).length === 0) {
                        const eventData = prepareOneOnOneEventData(
                          values,
                          SESSION_TYPE.SPECIFIC_INDIVIDUAL
                        );
                        setPreparedData(eventData);
                      } else {
                        setPreparedData(null);
                      }
                    }}
                  />
                  <CreateEventOneOnOneStep3
                    onSaveDraft={onSaveDraft}
                    isDraft={draftId}
                  />
                  <CreateEventOneOnOneStep4 />
                </StepWizard>
                {error && (
                  <div className="text-center text-red-500 mt-4">{error}</div>
                )}
              </Form>
            )}
          </Formik>
        )}
      </CenterContainer>
      <Footer />
    </>
  );
}

export default connect(() => ({}), {
  createCourse,
  createDraftCourse,
  getDraftCourseById,
  editDraftCourse,
  getCourseDetailById,
  editCourse,
  getEventDetail,
})(CreateEventOneOnOne);

function draftSessionsToAvailabilities(sessionDetails) {
  return sessionDetails[0].session_group.map((ses) => ({
    date: new Date(ses.sessions[0].start_at * 1000),
    endDate: new Date(ses.sessions[0].end_at * 1000),
    time: new Date(ses.sessions[0].end_at * 1000),
    isRecurring: ses.recurring_type !== RECURRING_TYPE_REVERSE.NONE,
    recurringInterval:
      ses.recurring_type !== RECURRING_TYPE_REVERSE.NONE
        ? ses.recurring_type
        : "",
    recurringEndDate: new Date(ses.recurring_end_date * 1000),
  }));
}

function editableSessionsToAvailabilities(sessionDetails) {
  const avilabilities = sessionDetails.map((ses) => {
    const isRecurring =
      ses.session_group.recurring_type !== RECURRING_TYPE_REVERSE.NONE;
    return {
      startDate: new Date(ses.session_group.sessions[0].start_at * 1000),
      endDate: new Date(ses.session_group.sessions[0].end_at * 1000),
      startTime: new Date(ses.session_group.sessions[0].start_at * 1000),
      endTime: new Date(ses.session_group.sessions[0].end_at * 1000),
      isRecurring,
      recurringInterval: isRecurring && ses.session_group.recurring_type,
      recurringEndDate: isRecurring
        ? new Date(ses.session_group.recurring_end_date * 1000)
        : new Date(),
    };
  });
  return avilabilities;
}
