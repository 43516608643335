import React from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import Alert from "views/Notifications/Alert";
import ProfileCourseRoutes from "views/Routing/ProfileCourseRoutes";
import PublicRoute from "views/Routing/PublicRoute";
import PrivateRoute from "views/Routing/PrivateRoute";
import MyaccountSidebar from "views/Front/Snippets/MyaccountSidebar";

const Index = () => {
  return (
    <div className="min-h-screen flex lg:flex-col">
      <MyaccountSidebar />
      <div className="bg-dgray-25 min-h-screen py-0 px-6 w-full ml-80 xl:ml-72 lg:ml-0">
      <Switch>
            {ProfileCourseRoutes.map((route, idx) => {
              return route.component ? (
                route.ispublic ? (
                  <PublicRoute
                    key={idx}
                    exact={route.exact}
                    path={route.path}
                    restricted={true}
                    component={route.component}
                    name={route.name}
                    isforall={route.isforall}
                  />
                ) : route.isprivate ? (
                  <PrivateRoute
                    key={idx}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                    name={route.name}
                    isforall={route.isforall}
                    isForEducator={route.isForEducator}
                    isForLearner={route.isForLearner}
                  />
                ) : (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <route.component {...props} name={route.name} />
                    )}
                  />
                )
              ) : null;
            })}
          </Switch>
      </div>
    </div>

    /*
    <div className="row course-section" id="courses">
      <div className="container-fluid">
        <Alert />
        <div className="row">
          <MyaccountSidebar />
          <Switch>
            {ProfileCourseRoutes.map((route, idx) => {
              return route.component ? (
                route.ispublic ? (
                  <PublicRoute
                    key={idx}
                    exact={route.exact}
                    path={route.path}
                    restricted={true}
                    component={route.component}
                    name={route.name}
                    isforall={route.isforall}
                  />
                ) : route.isprivate ? (
                  <PrivateRoute
                    key={idx}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                    name={route.name}
                    isforall={route.isforall}
                    isForEducator={route.isForEducator}
                    isForLearner={route.isForLearner}
                  />
                ) : (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <route.component {...props} name={route.name} />
                    )}
                  />
                )
              ) : null;
            })}
          </Switch>
        </div>
      </div>
    </div>
    */
  );
};

export default connect()(Index);
