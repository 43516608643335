import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as moment from 'moment-timezone';
import { getRefundList, getFullRefundList } from "actions/admin/educatorPayment";
import { SESSION_TYPE, SESSION_TYPE_REVERSE } from "constants/index";
import * as Constants from "constants/index";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import overlayFactory from "react-bootstrap-table2-overlay";
import CustomFilters from "views/Admin/Users/CustomFilters";
import { CSVLink } from "react-csv";

const RefundList = ({ getRefundList, getFullRefundList }) => {
  const REFUND_BY = {
    2: "By Mentor",
    3: "By Learner",
  };

  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [refundList, setRefundList] = useState([]);
  //################## Initillise sorting searching parameters by default values ###################
  const [refundParams, setRefundParams] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "paid_on",
    ascending: "desc",
    query: "",
  });

  useEffect(() => {
    getRefundList(refundParams).then((res) => {
      console.log(res);
      if (res.status) {
        setList(res.data);
        setCount(res.count);
      }
    });
  }, [refundParams]);

  
  useMemo(() => {
    if (count > 0) {
      getFullRefundList(count).then((res) => {
        let arr = []
        res.data.map((item) => {
          let obj ={};
          obj.learner_name = item.learner_name;
          obj.order_course_name = item.order_course_name;
          obj.mentor_username = item.mentor_username;
          obj.session_type = SESSION_TYPE_REVERSE[item.session_type];
          obj.refund_amount = item.refund_amount;
          obj.refund_on = moment.unix(item.paid_on).format("Do MMM, YYYY");
          obj.cancelled_by =  REFUND_BY[item.course_status];
          arr.push(obj)
        })
        setRefundList(arr);
      });
    }
  }, [count]);

  //################## Handel table change(sorting, searching, status change) ###################
  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    setRefundParams({
      ...refundParams,
      page: type === "search" ? 1 : page,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      query: searchText,
    });
  };

  const CSVHeaders = [
    { label: "Learner Name", key: "learner_name" },
    { label: "Course Name", key: "order_course_name" },
    { label: "Mentor UserName", key: "mentor_username" },
    { label: "Session Type", key: "session_type" },
    { label: "Refund Amount", key: "refund_amount" },
    { label: "Refund On", key: "refund_on" },
    { label: "Cancelled By", key: "cancelled_by" }
  ];
  
  const DownloadXls = () => (
    <a href="#" className="download-xls">
    <CSVLink
      className="fa fa-file-excel-o text-orange "
      data={refundList}
      headers={CSVHeaders}
      filename={`Refund-List ${moment.unix(moment().utc().unix()).format("MM/DD/YYYY HH:MM:ss")}.csv`}
      
    >
      {" "} 
      Download
    </CSVLink>
  </a>
  );

  const columns = [
    {
      dataField: "learner_name",
      text: "Learner Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "course_name",
      text: "Course Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "mentor_username",
      text: "Mentor UserName",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "session_type",
      text: "Session Type",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      formatter: (cellContent, row) => SESSION_TYPE_REVERSE[row.session_type],
    },
    {
      dataField: "session_data",
      text: "Session Details",
      sort: false,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">
            <small>Sessions:</small> <br />
            {row.session_type == SESSION_TYPE.GENERIC ? (
              <>
                <span>
                  {moment
                    .unix(row.session_data[0].start_at)
                    .format("DD MMM, YYYY")}{" "}
                  |{" "}
                  {moment.unix(row.session_data[0].start_at).format("hh:mm A")}{" "}
                  -{" "}
                  {moment
                    .unix(row.session_data[row.session_data.length - 1].end_at)
                    .format("hh:mm A")}
                </span>
                <br />
              </>
            ) : (
              row.session_data.map((singleSession) => {
                return (
                  <>
                    <span>
                      {moment
                        .unix(singleSession.start_at)
                        .format("DD MMM, YYYY hh:mm A")}{" "}
                    </span>
                    <br />{" "}
                  </>
                );
              })
            )}
          </p>
          <p className="mb-1">
            <small>No. of session</small>
            <br />
            <span>
              {row.session_type == SESSION_TYPE.GENERIC
                ? "N/A"
                : row.session_data.length}
            </span>
          </p>
        </>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },

    {
      dataField: "refund_amount",
      text: "Refund Amount",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "paid_on",
      text: "Refund On",
      sort: true,
      formatter: (cellContent, row) =>
        moment.unix(cellContent).format("Do MMM, YYYY"),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "course_status",
      text: "Cancelled By",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      formatter: (cellContent, row) => REFUND_BY[cellContent],
    },
  ];

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li
      key={text}
      role="presentation"
      className="dropdown-item"
      onMouseDown={(e) => {
        e.preventDefault();
        onSizePerPageChange(page);
      }}
    >
      <Button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        className="sizePerPageaTag"
      >
        {text}
      </Button>
    </li>
  );

  const defaultSorted = [
    {
      dataField: "paid_on",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <>
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    </>
  );

  const options = {
    page: parseInt(refundParams.page),
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: count,
    sizePerPageOptionRenderer,
    sizePerPageList: [
      {
        text: Constants.DEFAULT_PAGE_SIZE,
        value: Constants.DEFAULT_PAGE_SIZE,
      },
      {
        text: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
        value: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
      },
      {
        text: "All",
        value: count,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <div className="animated fadeIn displayTableList">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(options)}
                  keyField="_id"
                  columns={columns}
                  data={list}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="_id"
                      data={list}
                      columns={columns}
                      search
                    >
                      {(toolkitprops) => (
                        <>
                          <>
                            <div className="per-page-div">
                              Record per page:
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <DownloadXls />
                            <CustomFilters {...toolkitprops.searchProps} />
                          </>
                          <BootstrapTable
                            {...toolkitprops.baseProps}
                            bootstrap4
                            remote={{
                              pagination: true,
                              filter: true,
                              sort: true,
                            }}
                            keyField="_id"
                            data={list}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={handleTableChange}
                            defaultSorted={defaultSorted}
                            noDataIndication={Constants.NO_RECORD}
                            bordered={false}
                            hover
                            // loading={loading && !subLoading}
                            overlay={overlayFactory({
                              // spinner: <Spinner />,
                              styles: {
                                overlay: (base) => ({
                                  ...base,
                                  background: "#E8E8E8",
                                }),
                              },
                            })}
                          />
                        </>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

RefundList.propTypes = {
  getRefundList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getRefundList,
  // payToMentorNow,
  getFullRefundList,
})(withRouter(RefundList));
