import moment from "moment";

export default function NotificationItem({ category, details }) {
  switch (category) {
    case NOTIFICATION_TYPES.SESSION_SCHEDULE:
      return details.session_type === 1 ? (
        <li>
          A learner has scheduled session {details.course_name}{" "}
          <span className="text-primary-700 font-medium">
            {details.course_name}
          </span>{" "}
          with you on{" "}
          {moment
            .unix(details.session_detail[0].start_at)
            .format("MMM Do, YYYY")}{" "}
          | {moment.unix(details.session_detail[0].start_at).format("hh:mm A")}{" "}
          {" - "}
          {moment
            .unix(
              details.session_detail[details.session_detail.length - 1].end_at
            )
            .format("hh:mm A")}
        </li>
      ) : (
        details.session_detail.map((sessionInfo, i) => (
          <li>
            A learner has scheduled session {details.course_name}{" "}
            <span className="text-primary-700 font-medium">
              {details.course_name}
            </span>{" "}
            with you on{" "}
            {moment.unix(sessionInfo.start_at).format("MMM Do, YYYY")} |{" "}
            {moment.unix(sessionInfo.start_at).format("hh:mm A")} {" - "}
            {moment
              .unix(
                details.session_detail[details.session_detail.length - 1].end_at
              )
              .format("hh:mm A")}
          </li>
        ))
      );
    case NOTIFICATION_TYPES.SESSION_RESCHEDULE:
      return (
        <li>
          Your {details.course_name} is re-scheduled{" "}
          <span className="text-primary-700 font-medium">
            {details.course_name}
          </span>
        </li>
      );
    case NOTIFICATION_TYPES.SESSION_CANCEL:
      return (
        <li>
          Your{" "}
          <span className="text-primary-700 font-medium">
            {details.course_name}
          </span>{" "}
          is cancelled by a user
        </li>
      );
    case NOTIFICATION_TYPES.COURSE_ADD:
      return (
        <li>
          <div className="">
            <span className="font-medium text-dgray-700 mr-2">
              {details.mentorName}
            </span>
            {/* <span className="">2 mins ago</span> */}
          </div>
          <p>
            Added a new event{" "}
            <span className="text-primary-700 font-medium">
              {details.course_name}
            </span>
          </p>
        </li>
      );
    case NOTIFICATION_TYPES.CLASS_CANCEL:
      return (
        <li>
          Your mentor has cancelled the class for{" "}
          <span className="text-primary-700 font-medium">
            {details.course_name}
          </span>
        </li>
      );

    default:
      break;
  }
}

const NOTIFICATION_TYPES = {
  SESSION_SCHEDULE: "Session Scheduled",
  SESSION_RESCHEDULE: "Session ReScheduled",
  SESSION_CANCEL: "Session Cancelled",
  COURSE_ADD: "New Course Added",
  CLASS_CANCEL: "Class Cancelled",
};
