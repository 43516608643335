import {
    NOTIFICATION_UPDATED,
  } from "actions/types";
  
  const initalState = {
    loading: false,
    error: {},
    notificationDetails: [],
  };
  
  export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
      case NOTIFICATION_UPDATED:
        return {
          ...state,
          notificationDetails: payload,
        };
      default:
        return state;
    }
  }
  