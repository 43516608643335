import { createBlog, editBlog, getBlogById } from "actions/front/blog";
import { getCategories } from "actions/front/category";
import CenterContainer from "components/common/Container/Center";
import TextInput from "components/common/form/TextInput";
import CreateHero from "components/common/Hero/Create";
import { CreateFormRow } from "components/create-form/CreateFormRow";
import ImageDropzone from "components/common/form/ImageDropzone";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import createBlogSchema from "utils/schemas/createBlog";
import Footer from "views/Front/Layout/Footer";
import Header from "views/Front/Layout/Header";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Button from "components/common/Button";
import { ReactComponent as ArrowLeft } from "assets/images/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";
import DDNBlogFileAdapter from "views/Front/Utils/DDNBlogFileAdapter";
import { useHistory, useLocation } from "react-router-dom";
import SelectInput from "components/common/form/SelectInput";
import { WithContext as ReactTags } from "react-tag-input";

import "../../../../scss/tags.scss";
import ImageCropper from "components/common/ImageCropper";
import useImageCropper from "hooks/useImageCropper";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function CreateBlog({ auth, categories }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const location = useLocation();
  const editId = new URLSearchParams(location.search).get("editId");
  const [editableBlog, setEditableBlog] = useState(null);
  const [blogFetchingError, setBlogFetchingError] = useState(null);

  const onImageChange = (data, setFieldValue) => {
    let image_url = URL.createObjectURL(data);
    setFieldValue("header_image", image_url);

    /*setFormData({
      ...formData,
      selectedFile: data,
      header_image: image_url,
    });*/
  };

  const { cropperProps, show } = useImageCropper({
    src: file && URL.createObjectURL(file),
    onComplete: setFile,
    aspect: 2,
  });


  const getEditBlogDetails = () => {
    setLoading(true);
    getBlogById(editId).then((res) => {
      console.log("res", res);
      if (res.status) {
        setEditableBlog(res.data);
      } else {
        setBlogFetchingError("Blog not found");
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (editId) {
      getEditBlogDetails(editId);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Header />
      <CenterContainer className="pb-24 md:pb-16">
        <Formik
          enableReinitialize={true}
          key={editableBlog}
          initialValues={{
            title: editableBlog?.title ? editableBlog?.title : "",
            meta_description: editableBlog?.meta_description
              ? editableBlog?.meta_description
              : "",
            meta_keywords: editableBlog?.meta_keywords
              ? editableBlog?.meta_keywords.split(',').map((item) => ({
                  id: item,
                  text: item,
                }))
              : [],
            content: editableBlog?.content ? editableBlog?.content : "",
            category: editableBlog?.category ? editableBlog?.category : "",
            selectedFile: null,
            isDraft: editableBlog?.status ? editableBlog?.status === 0 ? true : false : false,
          }}
          validationSchema={createBlogSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const { isDraft, ...formValues } = values;
            const body = {
              ...formValues,
              meta_keywords: values.meta_keywords.map((item) => item.text),
            };
            body.selectedFile = file;
            if (isDraft) {
              if (editId) {
                await editBlog(body, history, editId, 0, true);
              } else {
                await createBlog(body, history, 0, true);
              }
            } else {
              if (editId) {
                await editBlog(body, history, editId, 1, true);
              } else {
                await createBlog(body, history, 1, true);
              }
            }
            setSubmitting(false);
          }}
        >
          {({ values, errors, submitForm, setFieldValue, isSubmitting }) => {
            return (
              <Form>
                <ImageCropper {...cropperProps} />
                <CreateHero
                  title={editableBlog ? "Edit Blog" : "Add New Blog"}
                />
                <h2 className="text-dgray-700 font-medium">
                  Blog Post Details
                </h2>
                <div className="pt-5 mt-5 border-t border-dgray-200"></div>
                <CreateFormRow
                  title="Blog Post Title"
                  description="Max 100 characters."
                  right={
                    <TextInput
                      name="title"
                      label=""
                      placeholder="Enter blog post title"
                      className="w-full"
                    />
                  }
                />

                <CreateFormRow
                  title="Blog Imagery"
                  description="Upload imagery that presents the topic. The imagery will be displayed as header image and thumbnail."
                  right={
                    <ImageDropzone
                      onDrop={(files) => {
                        setFile(files[0]);
                        show();
                      }}
                      name="selectedFile"
                    />
                  }
                />

                <CreateFormRow
                  title="Blog Post Meta Description"
                  description="Please enter the description for SEO. Max 150 chatacters."
                  right={
                    <TextInput
                      multiline={true}
                      name="meta_description"
                      label=""
                      placeholder="Enter blog post meta description"
                      className="w-full"
                      rows={8}
                    />
                  }
                />

                <CreateFormRow
                  title="Blog Post Meta Keywords"
                  description="Please enter the relevant keywords. Max 10 keywords are allowed."
                  right={
                    <ReactTags
                      name="meta_keywords"
                      placeholder="Enter blog post meta keywords"
                      className="w-full"
                      tags={values.meta_keywords}
                      delimiters={delimiters}
                      handleDelete={(i) => {
                        setFieldValue(
                          "meta_keywords",
                          values.meta_keywords.filter(
                            (tag, index) => index !== i
                          )
                        );
                      }}
                      handleAddition={(tag) => {
                        setFieldValue("meta_keywords", [
                          ...values.meta_keywords,
                          tag,
                        ]);
                      }}
                      allowUnique={false}
                      allowDragDrop={false}
                      inputFieldPosition="bottom"
                      rows={4}
                    />
                  }
                />

                <CreateFormRow
                  title="Select associated learning program"
                  description="Select which learning program the blog is associated with."
                  right={
                    <SelectInput
                      name="category"
                      options={
                        categories
                          ? categories.map((cat) => ({
                              label: cat.name,
                              value: cat._id,
                            }))
                          : []
                      }
                    />
                  }
                  bottom={values.associatedProgram}
                />

                <h2 className="text-dgray-700 font-medium">
                  Enter Your Blog Post
                </h2>
                <div className="border-b border-dgray-200 pb-5 mb-5 mt-4">
                  <CKEditor
                    editor={ClassicEditor}
                    data={values?.content}
                    id="overview"
                    name="overview"
                    // className="form-control"
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                      editor.plugins.get("FileRepository").createUploadAdapter =
                        (loader) => {
                          return new DDNBlogFileAdapter(
                            loader,
                            auth.token,
                            auth.user._id
                          );
                        };
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFieldValue("content", data);
                    }}
                    onBeforeLoad={(CKEDITOR) => {
                      CKEDITOR.disableAutoInline = true;
                    }}
                  />
                </div>

                {error && (
                  <div className="text-center text-red-500">{error}</div>
                )}

                <div className="flex justify-end gap-3">
                  <div className="flex gap-3 md:flex-row-reverse md:justify-between md:flex-1">
                    <Button
                      type="button"
                      className="!bg-white"
                      href="/dashboard/blogs"
                      disabled={false}
                      loading={false}
                      small
                      flat
                    >
                      Discard
                    </Button>
                    <Button
                      type="button"
                      onClick={async () => {
                        setFieldValue("isDraft", true);
                        await submitForm();
                      }}
                      small
                      secondary
                    >
                      Save as Draft
                    </Button>
                  </div>
                  <Button type="submit" disabled={isSubmitting} small>
                    Publish
                    <ArrowRight className="inline ml-2" />
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </CenterContainer>
      <Footer />
    </>
  );
}

export default connect(
  ({ auth, categories }) => ({ auth, categories: categories.data }),
  { getCategories }
)(CreateBlog);
