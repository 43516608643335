import * as Yup from "yup";
import { htmlStringSchema } from "./shared";

const createPublicEventSchema = Yup.object({
  maxSpeakers: Yup.number("Please enter a valid number")
    .required("Please enter a number")
    .typeError("Please enter a number"),
  allowAudience: Yup.bool(),
  maxAudience: Yup.number("Please enter a valid number")
    .typeError("Please enter a number")
    .test("coHostEmail", "Please enter a number", (value, { parent }) =>
      parent.allowAudience ? value : true
    ),
  inviteCoHost: Yup.bool(),
  coHosts: Yup.array().when("inviteCoHost", {
    is: true,
    then: Yup.array().of(
      Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter an email address")
    ),
  }),
  image: Yup.string().nullable(),
  associatedProgram: Yup.string().required("Please select"),
  proficiencyLevel: Yup.string().required("Please select"),
  title: Yup.string()
    .required("Please enter a title")
    .min(3, "Please enter at least 3 characters")
    .max(100, "Please enter at least 3 characters"),
  description: htmlStringSchema({
    min: 20,
    minMsg: "Please enter at least 20 characters",
    requiredMsg: "Please enter a description",
  }),
  date: Yup.string().required("Please select"),
  time: Yup.string().required("Please select"),
  duration: Yup.string().required("Please select"),
});

export default createPublicEventSchema;
