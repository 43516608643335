import { uploadFileContent } from "actions/front/blog";

export default class DDNBlogFileAdapter {
  constructor(loader, token, userId) {
    this.loader = loader;
    this.token = token;
    this.userId = userId;
  }
  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          uploadFileContent(file, this.token, this.userId).then((result) =>
            resolve(result)
          );
        })
    );
  }

  // Aborts the upload process.
  abort() {}
}
