import axios from "utils/axios";
import {

} from "actions/types";


export const sendContactUs = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const res = await axios.post("/api/front/contactus/contact", formData, config);
  return res.data;
};
