import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
import * as moment from 'moment';
import overlayFactory from "react-bootstrap-table2-overlay";
import Spinner from "views/Spinner";
import { CSVLink } from "react-csv";
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import { getUsersList, deleteUser, changeStatus } from "actions/admin/user";
import * as Constants from "constants/index";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Card, CardBody, Col, Row, Input } from "reactstrap";
const { SearchBar } = Search;
const actions = (
  <div className="add-button-div">
    <Link to="/admin/users/create" className="addNewElementClass">
      <Button color="primary" size="sm">
        <i className="fa fa-plus"></i> Create User
      </Button>
    </Link>
  </div>
);

const UsersList = ({
  getUsersList,
  deleteUser,
  changeStatus,
  history,
  userList: { data, count, page },
  sortingParams,
  loading,
  subLoading,
}) => {
  const [userParams, setUserParams] = useState({
    limit: sortingParams.limit,
    page: sortingParams.page,
    orderBy: sortingParams.orderBy,
    ascending: sortingParams.ascending,
    query: sortingParams.query
  });
  // const headerSortingStyle = { backgroundColor: '#c8e6c9' };
  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li
      key={text}
      role="presentation"
      className="dropdown-item"
      onMouseDown={e => {
        e.preventDefault();
        onSizePerPageChange(page);
      }}
    >
      <Button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        className="sizePerPageaTag"
      >
        {text}
      </Button>
    </li>
  );

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      }
      // headerSortingStyle
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      }
    },
    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      // headerSortingStyle,
      formatter: (cellContent, row) => (
        <Moment format="MM/DD/YYYY">{Number(row.created_at)}</Moment>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      }
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      },

      formatter: (cell, row) => {
        return (
          <div>
            <Input
              type="select"
              name="status"
              id={row._id}
              defaultValue={cell}
              onChange={(e, a) => {
                changeStatus(row._id, e.target.value);
              }}
            >
              {cell === 0 ? <option value="0">Pending</option> : ""}
              <option value="1">Active</option>
              <option value="2">UnVerified</option>
              <option value="3">Block</option>
            </Input>
          </div>
        );
      }
    },
    {
      dataField: "total_courses",
      text: "Course Count",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      },
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: (cellContent, row) => (
        <div>
          <Link to={`/admin/users/${row._id}`}>
            <Button type="button" size="sm" color="success">
              {/* <i className="fa fa-pencil"></i> */}
              Edit
            </Button>
          </Link>
          <Button
            type="button"
            size="sm"
            color="danger"
            onClick={e => {
              if (
                window.confirm(
                  `Are you sure you want to delete :\r ${row.name}?`
                )
              ) {
                deleteUser(row._id, history);
              }
            }}
          >
            {/* <i className="fa fa-trash"></i> */}
            Delete
          </Button>
        </div>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      }
    }
  ];

  useMemo(() => {
    /**
     * Due to asynchronous behavior it is possible that we are dealing with
     * Old value using setState({...params, propery:value });
     * SO, to eliminate this situation we are doing function update such as below
     */
    setUserParams((params) => ({ ...params, page }) );
  },[page]);

  useEffect(() => {
    getUsersList(userParams);
  }, [getUsersList, userParams]);

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc"
    }
  ];

  const customTotal = (from, to, size) => (
    <>
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    </>
  );

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <span className="custom-paginator">
      <span>Records per page: </span>
      <UncontrolledDropdown
        tag="span"
      >
        <DropdownToggle
         className="dropdown-btn-col-w dropdown-p-28"
         caret
        >
          {currSizePerPage}
        </DropdownToggle>
        <DropdownMenu>
          { options.map(option => <DropdownItem
            tag="li"
            key={option.text}
            onMouseDown={e => {
              e.preventDefault();
              onSizePerPageChange(option.page);
            }}
          >
            {option.text}
            </DropdownItem>)
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    </span>
  );

  const options = {
    page: parseInt(userParams.page),
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: count,
    // sizePerPageRenderer,
    sizePerPageOptionRenderer,
    sizePerPageList: [
      {
        text: Constants.DEFAULT_PAGE_SIZE,
        value: Constants.DEFAULT_PAGE_SIZE
      },
      {
        text: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
        value: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
      },
      {
        text: "All",
        value: count
      }
    ] // A numeric array is also available. the purpose of above example is custom the text
  };

  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    const params = {
      page: type === "search" ? 1 : page,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      query: searchText
    };

    setUserParams(params);

    //getUsersList(params);
  };

  const CSVHeaders = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Created At", key: "created_at" },
    { label: "Status", key: "status" },
    { label: "course_count", key: "total_courses" },
  ];
  let csv_data = data.map(row => ({...row, created_at: moment(row.created_at).format("MM/DD/YYYY HH:MM:ss")}))
  const DownloadXls = () => (
    <a href="#" className="download-xls">
    <CSVLink
      className="fa fa-file-excel-o text-orange "
      data={csv_data}
      headers={CSVHeaders}
      filename={`Users-List ${moment.unix(moment().utc().unix()).format("MM/DD/YYYY HH:MM:ss")}.csv`}
    >
      {" "} 
      Download
    </CSVLink>
  </a>
  );

  return (
    <div className="animated fadeIn displayTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              {actions}
              <PaginationProvider
                pagination={ paginationFactory(options) }
                keyField="email"
                columns={columns}
                data={data}
              >
              {
                ({
                  paginationProps,
                  paginationTableProps,
                }) =>
                    <ToolkitProvider
                      keyField="email"
                      data={ data }
                      columns={ columns }
                      search
                    >
                      {(toolkitprops) => (
                          <>
                            <>
                              <div className="per-page-div">
                                <SizePerPageDropdownStandalone {...paginationProps} />
                              </div>
                              <DownloadXls />
                              <SearchBar  {...toolkitprops.searchProps} />
                            </>
                            <BootstrapTable
                              {...toolkitprops.baseProps}
                              bootstrap4
                              remote={{ pagination: true, filter: true, sort: true }}
                              keyField="email"
                              data={data}
                              columns={columns}
                              {...paginationTableProps}
                              onTableChange={handleTableChange}
                              defaultSorted={defaultSorted}
                              noDataIndication={Constants.NO_RECORD}
                              bordered={false}
                              hover
                              loading={loading && !subLoading}
                              overlay={overlayFactory({
                                spinner: <Spinner />,
                                styles: {
                                  overlay: (base) => ({
                                    ...base,
                                    background: "#E8E8E8",
                                  }),
                                },
                              })}
                            />
                          </>
                        )
                      }
                    </ToolkitProvider>
                }
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

UsersList.propTypes = {
  getUsersList: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  userList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
  sortingParams: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  userList: state.user.usersList,
  loading: state.user.loading,
  subLoading: PropTypes.bool.isRequired,
  sortingParams: state.user.sortingParams
});

export default connect(mapStateToProps, {
  getUsersList,
  deleteUser,
  changeStatus
})(withRouter(UsersList));
