import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CurrencyInput from 'react-currency-input-field';
import { Button, Card, CardBody, Col, Row, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  getPaymentDetails,
  payToMentorNow,
} from "actions/admin/educatorPayment";
import * as Constants from "constants/index";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import overlayFactory from "react-bootstrap-table2-overlay";
import CustomFilters from "./CustomFilters";
import * as moment from 'moment-timezone';
import { CSVLink } from "react-csv";

const PayToEducators = ({ getPaymentDetails, payToMentorNow }) => {
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [errorExpense, setErrorExpense] = useState(false);
  const [paymentButtonId, setPaymentButtonId] = useState("");

  const toggle = () => {
    setModalData(modalData.expense_amount=0, modalData.final_amount=modalData.paying_amount);
    setErrorExpense(false)
    setModal(!modal);
  }
  //################## Initillise sorting searching parameters by default values ###################
  const [paymentParams, setPaymentParams] = useState({
    orderBy: "mentor_name",
    ascending: "asc",
    query: "",
  });

  useEffect(() => {
    getPaymentDetails(paymentParams).then((res) => {
      if (res.status) {
        setList(res.data.map(payment => ({...payment, expense_amount: 0, expense_description: "", final_amount: payment.paying_amount })).filter((iter) => iter.paying_amount > 0));
        // setCount(res.count);
      }
    });
  }, [paymentParams]);

  //################## Handel table change(sorting, searching, status change) ###################
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    setPaymentParams({
      ...paymentParams,
      orderBy: sortField,
      ascending: sortOrder,
      query: searchText,
    });
  };

  const CSVHeaders = [
    { label: "Mentor Name", key: "mentor_name" },
    { label: "Comission", key: "commission_amount" },
    { label: "To be Paid", key: "paying_amount" },
    { label: "Expense Amount", key: "expense_amount" }
  ];

  const DownloadXls = () => (
    <a href="#" className="download-xls">
    <CSVLink
      className="fa fa-file-excel-o text-orange "
      data={list}
      headers={CSVHeaders}
      filename={`Mentors-payout-List ${moment.unix(moment().utc().unix()).format("MM/DD/YYYY HH:MM:ss")}.csv`}
    >
      {" "} 
      Download
    </CSVLink>
  </a>
  );

  // //################## pay to mentor ###################

  const payNow = async (singleDetail) => {
    setPaymentButtonId(singleDetail._id);
    payToMentorNow(singleDetail).then(async (res) => {
      if (res.status) {
        let newList = [];

        let promise = await list.map((singleData) => {
          if (singleData._id != singleDetail._id) newList.push(singleData);
        });
        await Promise.all(promise);
        setPaymentButtonId("");
        setErrorExpense(false);
        setModalData(null);
        setModal(false);
        alert(res.msg);
        setList(newList);
      }
    });
  };

  const onExpenseChange = (payment) => {
    return e => {
      if (e.target.value)
      {
        if (parseFloat(e.target.value) * (-1) > payment.paying_amount)
        { setErrorExpense(true) }
        else { setErrorExpense(false)}
        const payment_index = list.findIndex(p => p._id === payment._id)
        payment.final_amount = payment.paying_amount + parseFloat(e.target.value)
        payment.final_amount = payment.final_amount.toFixed(2)
        payment.expense_amount = parseFloat(e.target.value)
        const state = [...list.slice(0, payment_index), payment, ...list.slice(payment_index + 1)]
        setList(state)
      }
    }
  }
  const prepaidPayNow = (row) => {
    setModal(!modal);
    let modalSetData = { ...row };
    setModalData(modalSetData);
  }

  const columns = [
    {
      dataField: "mentor_name",
      text: "Mentor Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "commission_amount",
      text: "Commission",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      formatter: (cellContent, row) => row.commission_amount.toFixed(2),
    },
    {
      dataField: "expense_amount",
      text: "Expense Amount",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      formatter: (cellContent, row) => row.expense_amount.toFixed(2),
    },
    {
      dataField: "paying_amount",
      text: "To Be Paid Amount",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      formatter: (cellContent, row) => row.paying_amount.toFixed(2),
    },

    {
      dataField: "can_be_paid",
      text: "Pay",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      formatter: (cellContent, row) => (
        <div>
          {paymentButtonId == row._id ? (
            <Spinner />
          ) : row.mentor_paypal_email ? (
            <Button
              type="button"
              size="sm"
              color="success"
              onClick={(e) => prepaidPayNow(row)}
            >
              Prepare Payment
            </Button>
          ) : (
            "No paypal email Id"
          )}
        </div>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "mentor_name",
      order: "asc",
    },
  ];

  return (
    <>
      <div className="animated fadeIn displayTableList">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <ToolkitProvider
                  keyField="mentor_name"
                  data={list}
                  columns={columns}
                  search
                >
                  {(toolkitprops) => (
                    <>
                      <CustomFilters {...toolkitprops.searchProps} />
                      <DownloadXls />
                      <BootstrapTable
                        {...toolkitprops.baseProps}
                        bootstrap4
                        remote={{
                          pagination: true,
                          filter: true,
                          sort: true,
                        }}
                        keyField="mentor_name"
                        data={list}
                        columns={columns}
                        onTableChange={handleTableChange}
                        defaultSorted={defaultSorted}
                        noDataIndication={Constants.NO_RECORD}
                        bordered={false}
                        hover
                        // loading={loading && !subLoading}
                        overlay={overlayFactory({
                          // spinner: <Spinner />,
                          styles: {
                            overlay: (base) => ({
                              ...base,
                              background: "#E8E8E8",
                            }),
                          },
                        })}
                      />
                    </>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Payment Detail</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label>Mentor Name: </label>
              {modalData?.mentor_name} 
            </div>
            <div className="form-group">
              <label>Commision: </label>
              {modalData?.commission_amount} 
            </div>
            <div className="form-group">
              <label>To Be Paid Amount: </label>
              {modalData?.paying_amount}
            </div>
            <div className="form-group">
              <label>Expense:</label>
              <CurrencyInput className={errorExpense ? "form-control is-invalid" : "form-control" } defaultValue={modalData?.expense_amount} onChange={onExpenseChange(modalData)} />
            </div>
            <div className="form-group">
              <label>Expense Description:</label>
              <input className="form-control" defaultValue={modalData?.expense_description}/>
            </div>
            <div className="form-group">
              <label>Net: </label>
              {modalData?.final_amount}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size="sm"
              color="success"
              onClick={(e) => payNow(modalData)}
              disabled={errorExpense}
            >
              Pay Now
            </Button>
            <Button color="secondary" className="m-1" onClick={toggle}>
              Close
          </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

PayToEducators.propTypes = {
  getPaymentDetails: PropTypes.func.isRequired,
  payToMentorNow: PropTypes.func.isRequired,
};

export default connect(null, {
  getPaymentDetails,
  payToMentorNow,
})(withRouter(PayToEducators));
