// extracts text content from html strings
export default function extractTextContent(s, space) {
  const span = document.createElement("span");
  span.innerHTML = s;
  if (space) {
    const children = span.querySelectorAll("*");
    for (let i = 0; i < children.length; i++) {
      if (children[i].textContent) children[i].textContent += " ";
      else children[i].innerText += " ";
    }
  }
  const textContent = [span.textContent || span.innerText]
    .toString()
    .replace(/ +/g, " ");

  span.remove();
  return textContent;
}
