import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import CourseImage from "assets/images/mock-event.jpg";
import Spinner from "views/Spinner";
import NoRecord from "views/NoRecord";

const Blogs = ({ blogsList, loading }) => {
    
  return loading ? (
    <Spinner />
  ) : blogsList.length > 0 ? (
        <div className="row">
          {blogsList.map((blog, index) => {
            return (
              <div key={index} className="col-md-4 mb-3">
                {/* Attended Card Start   */}
                <div className="card">
                  <img
                    src={
                      blog.header_image
                        ? process.env.REACT_APP_MEDIA_URL  + blog.header_image
                        : CourseImage
                    }
                    alt=""
                    className="card-thumb"
                    width="288px"
                    height="173px"
                  />
                  <div className="card-body pb-0">
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <Link to={`/blog/${blog.slug}`}>
                            <h4 className="title orange-text">
                            <strong style={{ fontSize: 16 }}>
                                {blog.title.length > 20
                                ? blog.title.substring(0, 20) + " ..."
                                : blog.title}
                            </strong>
                            </h4>
                        </Link>  
                      </div>
                    </div>
                    
                  </div>
                </div>
                {/* Attended Card End  /*/}
              </div>
            );
          })}
        </div>
  ) : (
    <NoRecord msg="No Blogs written yet"/>
  );
};

export default Blogs;
