import CenterContainer from "components/common/Container/Center";

export default function MentorsHero() {
  return (
    <CenterContainer
      tight
      className="text-center md:text-left py-24 md:pt-8 md:pb-0"
    >
      <h1 className="mb-3 text-primary-600 font-semibold">Hosts</h1>
      <h2 className="mb-6 text-5xl md:text-4xl font-semibold">
        Find the best host
      </h2>
      <p className="text-xl md:text-lg text-dgray-500">
        Search and explore DDN-vetted hosts around the world, specialized in
        various DDN programs.
      </p>
    </CenterContainer>
  );
}
