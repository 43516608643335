import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getInquiriesList,
  deleteInquiry,
  changeStatus
} from "actions/admin/inquiry";
import * as Constants from "constants/index";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory,  { PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Card, CardBody, Col, Row, Input } from "reactstrap";
import Spinner from "views/Spinner";
const { SearchBar } = Search;

const actions = (
  <div className="add-button-div">
    <Link to="/admin/inquiries/add" className="addNewElementClass">
      <Button color="primary" size="sm">
        <i className="fa fa-plus"></i> Add Inquiry
      </Button>
    </Link>
  </div>
);

const InquiriesList = ({
  getInquiriesList,
  deleteInquiry,
  changeStatus,
  history,
  inquiryList: { data, count, page },
  sortingParams,
  loading
}) => {
  const [inquiryParams, setInquiryParams] = useState({
    limit: sortingParams.limit,
    page: sortingParams.page,
    orderBy: sortingParams.orderBy,
    ascending: sortingParams.ascending,
    query: sortingParams.query
  });
  // let inquiryParams = {
  //   limit: sortingParams.limit,
  //   page: sortingParams.page,
  //   orderBy: sortingParams.orderBy,
  //   ascending: sortingParams.ascending,
  //   query: sortingParams.query
  // };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      }
      // headerSortingStyle
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      }
    },
    {
      dataField: "phone",
      text: "Phone",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      }
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      },
      formatter: (cell, row) => {
        return (
          <div>
            <Input
              type="select"
              name="status"
              id={row._id}
              defaultValue={cell}
              onChange={(e, a) => {
                changeStatus(row._id, e.target.value);
              }}
            >
              <option value="0">Closed</option>
              <option value="1">Open</option>
            </Input>
          </div>
        );
      }
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: (cellContent, row) => (
        <div>
          <Link to={`/admin/inquiries/${row._id}`}>
            <Button type="button" size="sm" color="success">
              <i className="fa fa-pencil"></i>
            </Button>
          </Link>
          <Button
            type="button"
            size="sm"
            color="danger"
            onClick={e => {
              if (
                window.confirm(
                  `Are you sure you want to delete :\r ${row.name}?`
                )
              ) {
                deleteInquiry(row._id, history);
              }
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      }
    }
  ];

  useMemo(() => {
    /**
     * Due to asynchronous behavior it is possible that we are dealing with 
     * Old value using setState({...params, propery:value });
     * So, to eliminate this situation we are doing function update such as below
     */
    setInquiryParams((params) => ({ ...params, page }) );
  },[page]);

  useEffect(() => {
    getInquiriesList(inquiryParams);
  }, [getInquiriesList, inquiryParams]);

  const defaultSorted = [
    {
      //dataField: "created_at",
      dataField: "status",
      order: "desc"
    }
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <span className="custom-paginator">
      <span>Records per page: </span>
      <UncontrolledDropdown
        tag="span"
      >
        <DropdownToggle 
          className="dropdown-btn-col-w dropdown-p-28"
          caret
        >
          {currSizePerPage}
        </DropdownToggle>
        <DropdownMenu>
          { options.map(option => <DropdownItem
            tag="li"
            key={option.text}
            onMouseDown={e => {
              e.preventDefault();
              onSizePerPageChange(option.page);
            }}
          >
            {option.text}
            </DropdownItem>) 
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    </span>
  );

  const options = {
    page: parseInt(inquiryParams.page),
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: count,
    sizePerPageRenderer,
    //sizePerPageOptionRenderer,
    sizePerPageList: [
      {
        text: Constants.DEFAULT_PAGE_SIZE,
        value: Constants.DEFAULT_PAGE_SIZE
      },
      {
        text: "20",
        value: 20
      },
      {
        text: "All",
        value: count
      }
    ] // A numeric array is also available. the purpose of above example is custom the text
  };

  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    setInquiryParams({
      page: type === "search" ? 1 : page,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      query: searchText
    });

    // getInquiriesList(inquiryParams);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn displayTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              {actions}
              <PaginationProvider
                pagination={ paginationFactory(options) }
                keyField="email"
                columns={columns}
                data={data}
              >
              {
                ({
                  paginationProps,
                  paginationTableProps,
                }) => 
                  <ToolkitProvider
                    keyField="email"
                    data={data}
                    columns={columns}
                    search
                  >
                    {toolkitprops => (
                      <>
                        <>
                          <SizePerPageDropdownStandalone {...paginationProps} />
                          <SearchBar {...toolkitprops.searchProps} />
                        </>
                        <BootstrapTable
                          {...toolkitprops.baseProps}
                          bootstrap4
                          remote={{ pagination: true, filter: true, sort: true }}
                          keyField="email"
                          data={data}
                          columns={columns}
                          {...paginationTableProps}
                          onTableChange={handleTableChange}
                          defaultSorted={defaultSorted}
                          noDataIndication={Constants.NO_RECORD}
                          bordered={false}
                          hover
                        />
                      </>
                    )}
                  </ToolkitProvider> 
              } 
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

InquiriesList.propTypes = {
  getInquiriesList: PropTypes.func.isRequired,
  deleteInquiry: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  inquiryList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  sortingParams: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  inquiryList: state.inquiry.inquiriesList,
  loading: state.inquiry.loading,
  sortingParams: state.inquiry.sortingParams
});

export default connect(mapStateToProps, {
  getInquiriesList,
  deleteInquiry,
  changeStatus
})(withRouter(InquiriesList));
