import { useState } from "react";
import { Link } from "react-router-dom";
import CenterContainer from "components/common/Container/Center";
import img2 from "assets/images/one-on-one.png";
import img3 from "assets/images/blog.png";
import img1 from "assets/images/event-types22.jpg";

import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";

export default function HomePageEventTypes() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <CenterContainer className="pt-40 md:pt-8 pb-24 md:pb-14">
      <div className="text-primary-700 font-semibold mb-3 md:text-sm">
        DingDingNow Event Types
      </div>
      <h2 className="text-4xl md:text-3xl font-semibold mb-5">
        Programs to meet your needs.
      </h2>
      <p className="text-dgray-500 mb-32 md:mb-14 text-xl md:text-lg">
        Learning a new language is not simple. Find the courses that best suit
        your needs.
      </p>
      <div className="flex md:flex-col items-center justify-between gap-16 xl:gap-8 basis-1/3">
        <div className="">
          {items.map(({ title, description, href, linkLabel }, i) => (
            <button
              key={i}
              onClick={() => setSelectedIndex(i)}
              className={`block py-4 pl-5 border-l-4 text-left ${
                i === selectedIndex ? "border-primary-600" : "border-gray-100"
              }`}
            >
              <div className="font-medium text-xl md:text-lg mb-2">{title}</div>
              <div className="text-dgray-500 mb-5">{description}</div>
              {href ? (
                <Link to={href} className="text-primary-700">
                  {linkLabel}
                </Link>
              ) : (
                <div className="text-primary-700">Coming soon!</div>
              )}
            </button>
          ))}
        </div>
        <div className="shrink-0 lg:shrink md:w-screen">
          <img
            src={items[selectedIndex].image}
            alt=""
            className="rounded-2xl md:rounded-none md:w-full md:h-80 md:object-cover"
          />
        </div>
      </div>
    </CenterContainer>
  );
}

const items = [
  {
    title: "Public Events",
    image: img1,
    description:
      "Access to unlimited talks with monthly subscription as a speaker.",
    href: "/events",
    linkLabel: (
      <>
        See public events
        <ArrowRight className="inline ml-2" />
      </>
    ),
  },
  {
    title: "One on One",
    image: img2,
    description: "Personalized focus when you need it on your schedule!",
    href: "/explore",
    linkLabel: (
      <>
        See one on one events
        <ArrowRight className="inline ml-2" />
      </>
    ),
  },
  {
    title: "Mentor's Resources",
    image: img3,
    description: "Learning experience shared by proficient ESL hosts.",
    href: "/blogs",
    linkLabel: (
      <>
        Go to DDN Blog
        <ArrowRight className="inline ml-2" />
      </>
    ),
  },
];
