import { getAllBlogs } from "actions/front/blog";
import BlogCard from "components/blog/BlogCard";
import CategoryPicker from "components/common/CategoryPicker";
import Pagination from "components/common/Pagination";
import Spinner from "views/Spinner";
import { connect } from "react-redux";

function BlogList({
  isLoading,
  setSelectedCategory,
  blogs,
  totalRecords,
  page,
  setPage,
  setFilters,
  filters,
}) {
  return (
    <div className="mb-16">
      <div className="text-sm text-primary-700 font-medium mb-5">
        Blog categories
      </div>
      <CategoryPicker
        onChange={setSelectedCategory}
        setPage={setPage}
        setFilters={setFilters}
        className="mb-16"
      />
      <div className="grid grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-6 mb-16">
        {isLoading ? (
          <div className="col-span-3">
            <Spinner />
          </div>
        ) : blogs && blogs.length > 0 ? (
          blogs.map((blog, i) => <BlogCard blog={blog} key={i} />)
        ) : (
          <div className="text-dgray-500 text-center col-span-3">
            No blogs found
          </div>
        )}
      </div>
      {blogs && (
        <Pagination
          page={page}
          setPage={setPage}
          pageSize={filters.limit}
          count={totalRecords}
        />
      )}
    </div>
  );
}

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  { getAllBlogs }
)(BlogList);
