import Button from "components/common/Button";
const Cards = ({
  title,
  content,
  price,
  describe,
  onClick,
  _id,
  handleMenberClientSecrert,
  ismonthly,
  type
}) => {
  return (
    <div className="rounded-xl bg-primary-50 my-2.5 flex justify-between p-3 items-center">
      <div>
        <p className="font-semibold">{title}</p>
        <div className="text-left">
          {describe.map((item, index) => {
            return <p key={index}>{item}</p>;
          })}
        </div>
      </div>
      {!type ? (
        <Button
          small
          className="!px-12"
          onClick={() => handleMenberClientSecrert(_id)}
        >
          ${Number(price).toFixed(2)}
        </Button>
      ) : (
        <Button
          small
          className="!px-12"
          onClick={() => handleMenberClientSecrert(_id)}
        >
          &nbsp;${Number(price).toFixed(2)}&nbsp;
        </Button>
      )}
    </div>
  );
};

export default Cards;
