import React from "react";
import Errors from "views/Notifications/Errors";

import { RECURRING_TYPE, RECURRING_TYPE_REVERSE } from "constants/index";

const SessionRecurringData = ({
  singleBundle,
  bundelIndex,
  onIndividualChange,
  isConfirmation,
  is_editable = true,
  is_recurring_editable = true,
  INPUT_TYPE,
}) => {
  return (
    <div className="row">
      <div
        className={`col-md-6 ${
          (!is_editable || !is_recurring_editable) && "disabled-session"
        }`}
      >
        <div className="form-group">
          <label htmlFor="recurring_type">
            Recurring <span className="text-danger">*</span>
          </label>
          <select
            className="form-control custom-select"
            id="individual_recurring_type"
            name="recurring_type"
            value={singleBundle.recurring_type}
            onChange={(e) =>
              onIndividualChange(e, INPUT_TYPE.RECURRING, 0, bundelIndex)
            }
            disabled={isConfirmation}
          >
            {Object.keys(RECURRING_TYPE).map((key) => {
              if (
                key == RECURRING_TYPE_REVERSE.NONE ||
                key >= singleBundle.recurring_type_min
              )
                return <option value={key}>{RECURRING_TYPE[key]}</option>;
            })}
          </select>
          {!isConfirmation && (
            <Errors
              current_key={`individual_recurring_type_${bundelIndex}`}
              key={`individual_recurring_type_${bundelIndex}`}
            />
          )}
        </div>
      </div>
      {singleBundle.recurring_type != RECURRING_TYPE_REVERSE.NONE && (
        <div
          className={`col-md-6 ${!is_recurring_editable && "disabled-session"}`}
        >
          <div className="form-group">
            <label htmlFor="recurring_end_date">
              Recurring End Date <span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              id="individual_recurring_end_date"
              name="recurring_end_date"
              min={singleBundle.recurring_min_date}
              value={singleBundle.recurring_end_date}
              onChange={(e) =>
                onIndividualChange(e, INPUT_TYPE.RECURRING, 0, bundelIndex)
              }
              disabled={isConfirmation}
            />
            {!isConfirmation && (
              <Errors
                current_key={`individual_recurring_end_date_${bundelIndex}`}
                key={`individual_recurring_end_date_${bundelIndex}`}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SessionRecurringData;
