import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import FilterImage from "assets/images/icons/filter.svg";

import { RECORD_PER_PAGE } from "constants/index";

import { getAllMentors } from "actions/front/myaccount";

import SearchText from "./SearchText";
import Filter from "./Filter";
import Sorting from "./Sorting";
import Mentors from "./Mentors";
import Pagination from "views/Front/Pagination";

const List = ({ getAllMentors }) => {
  const [mentorsList, setMentorList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchText, setSearchText] = useState("");

  const [filters, setFilters] = useState({
    limit: RECORD_PER_PAGE,
    page: 1,
    orderBy: "popular",
    ascending: "desc",
    query: "",
    custom_filters: "",
  });

  const paginate = (page_no) => {
    setFilters({ ...filters, page: page_no });
  };

  const onClickSearchText = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1, query: searchText });
  };

  const onClickopenNav = () => {
    //  Setting Side bar width accodring to screen
    const screenSize = window.matchMedia("(max-width: 700px)")
    if (screenSize.matches) { // If media query matches
      document.getElementById("mySidebar").style =
      "width:100% !important; margin-left:0px !important; background-color: white";
     } else {
     document.getElementById("mySidebar").style =
       "width:20% !important; margin-left:0px !important";
    }
    document.getElementById("main-content").style.width = "80%";
    document.getElementById("main-content").style.marginLeft = "20%";
    var element = document.getElementById("txt-filter");
    element.classList.add("text-white");
  };
  const onClickcloseNav = () => {
    document.getElementById("mySidebar").style = "width:0%";
    document.getElementById("main-content").style.width = "100%";
    document.getElementById("main-content").style.marginLeft = "0%";
    var element = document.getElementById("txt-filter");
    element.classList.remove("text-white");
  };

  useEffect(() => {
    setLoading(true);
    getAllMentors(filters).then((res) => {
      if (res.status) {
        setMentorList(res.data.data);
        setTotalRecords(res.data.metadata[0].totalRecord);
      }
      setLoading(false);
    });
  }, [filters]);
  return (
    <div>
      <SearchText
        searchText={searchText}
        setSearchText={setSearchText}
        onClickSearchText={onClickSearchText}
      />
      <div className="row course-section" id="slide-container">
        <div className="container-fluid">
          <div className="row">
            <Filter
              onClickcloseNav={onClickcloseNav}
              filters={filters}
              setFilters={setFilters}
            />

            <div id="main-content" className="mentor-content">
              <button onClick={(e) => onClickopenNav()} className="openbtn">
                <img src={FilterImage} alt="" />
                <span className="txt-filter" id="txt-filter">
                  Filter
                </span>
              </button>
              <div className="col-md-12">
                <Sorting filters={filters} setFilters={setFilters} />
                <Mentors mentorsList={mentorsList} loading={loading} />
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Pagination
                    totalRecords={totalRecords}
                    recordPerPage={RECORD_PER_PAGE}
                    paginate={paginate}
                    currentPage={filters.page}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

List.propTypes = {
  getAllMentors: PropTypes.func.isRequired,
};
export default connect(null, { getAllMentors })(withRouter(List));
