import {
    FETCH_CATEGORIES_LOADING,
    FETCH_CATEGORIES_COMPLETED
} from "actions/types";

const initalState = {
    loading: false,
    data: null,
};

export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_CATEGORIES_LOADING:
            return {
                ...state,
                loading: payload.is_loading ? payload.is_loading : false,
            };
        case FETCH_CATEGORIES_COMPLETED:
            
            return {
                ...state,
                data: [...payload],
                loading: false,
            };
        default:
            return state;
    }
}