import * as Yup from "yup";
import {
  matchingPasswordSchema,
  newPasswordSchema,
  selectOptionSchema,
} from "./shared";

const registerSchema = Yup.object({
  first_name: Yup.string().required("Please enter your first name"),
  last_name: Yup.string().required("Please enter your last name"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email address is required"),
  password: newPasswordSchema(),
  passwordRepeat: matchingPasswordSchema(
    "Please confirm your password",
    "Please enter matching passwords"
  ),
  country: Yup.string(),
  city: Yup.string(),
});

export default registerSchema;
