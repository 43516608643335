import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  RESET_LINK_SUCCESS,
  RESET_LINK_GET_DETAIL_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_LINK_FAIL,
  USER_LOADED,
  NEW_USER_LOADED,
  AUTH_ERROR,
  AUTH_FAIL,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  GOOGLE_SIGN_IN_SUCCESS,
  GOOGLE_SIGN_IN_FAIL,
  AUTH_LOADING,
  LOGIN_ERROR,
  CHECK_LINK_SUCCESS,
  CHECK_LINK_ERROR,
  USER_IMAGE_UPDATE_UPDATED,
  USER_MYACCOUNT_UPDATED,
  CART_COUNT_LOADING,
  CART_COUNT_SUCCESS,
  CART_COUNT_ERROR,
  CART_COUNT_FAIL,
  USER_STATE_UPDATE,
  UPDATE_CANCLE_PASS
} from "actions/types";
import { object } from "prop-types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  user: null,
  cart_count: 0,
  cart_count_loading: false,
  error: null,
  //email:'',
};

export default function authReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_LOADING:
      return {
        ...state,
        loading: payload,
        error: null,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };
    case NEW_USER_LOADED:
      return {
        ...state,
        user: {
          ...state.user,
          subscriptions: payload.subscriptions,
          is_premium: payload.is_premium,
          free_trial_ends_at: payload.free_trial_ends_at,
          free_trial_remaining_percent: payload.free_trial_remaining_percent,
        },
      };
    case UPDATE_CANCLE_PASS:
      let subscriptions = state.user.subscriptions
      let activIndex = subscriptions.findIndex(item => item.status == "succeeded")
      if(activIndex != -1){
        subscriptions[activIndex].pass = payload.pass
        subscriptions[activIndex].status = payload.status
        return {
          ...state,
          user: {
            ...state.user,
            subscriptions: subscriptions,
            is_premium:payload.is_premium
          },
        };
      }
    case REGISTER_SUCCESS:
    case RESET_LINK_SUCCESS:
    case RESET_LINK_GET_DETAIL_SUCCESS:
    case CHECK_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOGIN_SUCCESS:
      // case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: payload?.message,
        isinactive: payload?.isinactive,
        activateEmail: payload?.activateEmail,
      };
    case CHECK_LINK_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    case REGISTER_FAIL:
    case RESET_LINK_FAIL:
      return {
        ...state,
        // error: payload,
        loading: false,
      };
    case AUTH_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: payload,
      };
    case LOGOUT:
    case LOGIN_FAIL:
    case AUTH_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };

    case GOOGLE_SIGN_IN_SUCCESS:
      // localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };

    case GOOGLE_SIGN_IN_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };
    case USER_IMAGE_UPDATE_UPDATED:
      return {
        ...state,
        user: { ...state.user, avatar: payload },
      };
    case USER_MYACCOUNT_UPDATED:
      return {
        ...state,
        user: {
          ...state.user,
          first_name: payload.data.first_name,
          last_name: payload.data.last_name,
          email: payload.data.email,
          username: payload.data.username,
          avatar: payload.data.avatar,
          status: payload.data.status,
          role: payload.data.role,
          city: payload.data.city,
          state: payload.data.state,
          country: payload.data.country,
          timezone: payload.data.timezone,
          mentor: payload.data.mentor,
          learner: payload.data.learner,
        },
      };

    case CART_COUNT_LOADING:
      return {
        ...state,
        cart_count_loading: true,
      };
    case CART_COUNT_SUCCESS:
      return {
        ...state,
        cart_count_loading: false,
        cart_count: payload.data,
      };
    case CART_COUNT_ERROR:
      return {
        ...state,
        cart_count_loading: false,
      };
    case CART_COUNT_FAIL:
      return {
        ...state,
        cart_count_loading: false,
      };
    case USER_STATE_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          [payload.key]: payload.value,
        },
      };
    default:
      return state;
  }
}
