import { ReactComponent as BellTilted } from "assets/images/icons/bell-tilted.svg";
import IconCircle from "views/Front/HomePage/IconCircle";
import { Link } from "react-router-dom";

export default function FollowToast({ host, unfollow }) {
  return (
    <div className="flex gap-4 font-sans">
      <div className="shrink-0">
        <IconCircle>
          <BellTilted />
        </IconCircle>
      </div>
      <div className="flex-1 text-sm">
        <div className="font-medium text-dgray-900 mb-1">
          You are following {host.name}!
        </div>
        <p className="text-dgray-500 mb-4">
          You will get notifications for latest updates from John Doe and see
          them on your feed.
        </p>
        <div className="!font-medium">
          <button onClick={unfollow} className="text-dgray-500 mr-2">
            Undo
          </button>
          <Link to="/feed" className="text-primary-700">
            Go to Your Feed
          </Link>
        </div>
      </div>
    </div>
  );
}
