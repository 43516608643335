import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import HomePage from "views/Front";
import ProfileCourseManagement from "views/Front/ProfileCourseMgmt/Index";

import AdminPanel from "views/Admin/Layout";
import NotFoundPage from "views/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { initPrevPath, setPath } from "actions/front/path";

const Routes = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(initPrevPath());
  }, []);

  useEffect(() => {
    const path = `${location.pathname}${location.search}`;
    dispatch(setPath(path));
  }, [dispatch, location]);

  return (
    <span>
      <Switch>
        <Route exact path="/admin*" component={AdminPanel} />
        {/* <PrivateRoute path="/admin*" component={AdminPanel} /> */}
        <Route exact path="/*" name="Home" component={HomePage} />
        <Route component={NotFoundPage} />
      </Switch>
      <ToastContainer hideProgressBar style={{ width: 400 }} />
    </span>
  );
};

export default Routes;
