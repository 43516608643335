import React, { useState, useMemo } from "react";
import { COURSE_VIEW } from "constants/index";
import Courses from "views/Front/ProfileCourseMgmt/CourseManagement/Courses";

const AllCourses = ({ coursesList, loading }) => {
  const [durationPrice, setDurationPrice] = useState([]);
  const RESULTS = {VIEW_ALL_SESSIONS : 1}
  // const [minPriceCalculationLoading, setMinPriceCalculationLoading] = useState(false);
  var minPriceCalculationLoading = false;
  useMemo(async () => {
    minPriceCalculationLoading = true;
    let tempDurationPrice = [...durationPrice];
    await coursesList.map(async (course) => {
      await course.session.map(async (singleSession) => {
        await singleSession.duration_info.map((singleDuration) => {
          if (
            (tempDurationPrice[course.course_id] &&
              tempDurationPrice[course.course_id] >
                singleDuration.price_per_session) ||
            tempDurationPrice[course.course_id] == undefined
          ) {
            tempDurationPrice[course.course_id] = singleDuration.price_per_session;
          }
        });
      });
    });
    setDurationPrice(tempDurationPrice);
    minPriceCalculationLoading = false;
  }, [coursesList]);
  
  return (
    <div
      className="tab-pane"
    >
      <Courses page={RESULTS} courseList={coursesList} activeResult={RESULTS.VIEW_ALL_SESSIONS} courseView={COURSE_VIEW.DETAIL} loading={loading} minPriceCalculationLoading={minPriceCalculationLoading} durationPrice={durationPrice}/>
    </div>
  );
};

export default AllCourses;
