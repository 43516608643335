import React, { useState, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import bg5 from "assets/images/bg/how-it-works.jpg";
import img1 from "assets/images/sign-up-learner.png";
import img2 from "assets/images/learner-catalog.png";
import img3 from "assets/images/Interactive-course.png";
import img4 from "assets/images/reviews.png";


const ForLearner = () => {

  return (
    <div>
      <div className="row" id="banner">
        <div id="inner-banner" className="col-md-12 p-0">
          <img src={bg5} alt="" className="img-fluid" />
        </div>
      </div>
      <div className="row p-60">
        <div className="container cuetpoints">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-theme-orange">For Learners</h2>
              <p>
                DingDingNow is an integrated and global platform that welcomes
                people from all walks of life and background to sign up and
                connect with each other. When we say “Connect”, it is a real
                live people to people interaction through our advanced and
                all-in-one scheduling and interactive technology.{" "}
              </p>
            </div>
          </div>
          <div className="row p-60">
            <div className="col-md-6">
              <strong>Sign Up as a Learner</strong>
              <ul>
                <li className="disc">
                  It's free to sign up as a Learner and You can also switch
                  to Mentor after sign-up.
                </li>
              </ul>
            </div>

            <div className="col-md-6">
              <img src={img1} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="row p-60">
            <div className="col-md-6">
              <img src={img2} alt="" className="img-fluid" />
            </div>

            <div className="col-md-6">
              <strong>Browse Our Unique Catalog & Scheule with a Mentor</strong>
              <ul>
                <li className="disc">
                  View various sessions offered by mentors from all over the
                  world and broad industry spectrum based on your interests
                  and needs.
                    </li>
                <li className="disc">
                  Sessions can either be specific to a topic offered by a
                  mentor, or you can schedule time with a Mentor for a
                  general conversation called "DD Me for Anything"
                    </li>
              </ul>
            </div>
          </div>
          <div className="row p-60">
            <div className="col-md-6">
              <strong>Interactive Session Executed</strong>
              <ul>
                <li className="disc">Book sessions with peace of mind</li>
                <li className="disc">
                  You will see the meeting URL generated after your mentor activates the meeting room.
                </li>
                <li className="disc">
                  Start the conversation and Enjoy the learning!
                    </li>
              </ul>
            </div>
            <div className="col-md-6">
              <img src={img3} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="row p-60">
            <div className="col-md-6">
              <img src={img4} alt="" className="img-fluid" />
            </div>
            <div className="col-md-6">
              <strong>Rate and Review</strong>
              <ul>
                <li className="disc">Review and share your experience</li>
                <li className="disc">
                  Your voice will make our community better!
                  </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ForLearner.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ForLearner);
