import React from "react";
import * as moment from 'moment-timezone';

const SessionSchedule = ({ details: { course_name, session_detail, session_type }, timeZone }) => {
  return (
    <>
      {session_type === 1 ? (
        <div className="col-md-12">
          <div className="notification-box box">
            <div className="space-between">
              <a href="#" className="d-flex">
                <i className="fa fa-calendar"></i>
                <strong>Session Scheduled</strong>
              </a>
              {/* <small className="text-muted">3 sec ago</small> */}
            </div>
            <p>
              A learner has scheduled session {course_name} with you on{" "}
              {moment
                .unix(session_detail[0].start_at)
                .tz(timeZone)
                .format("MMM Do, YYYY")}{" "}
              |{" "}
              {moment
                .unix(session_detail[0].start_at)
                .tz(timeZone)
                .format("hh:mm A")}{" "}
              {" - "}
              {moment
                .unix(session_detail[session_detail.length - 1].end_at)
                .tz(timeZone)
                .format("hh:mm A")}
            </p>
          </div>
        </div>
      ) : (
        session_detail.map((sessionInfo, index) => {
          return (
            <div className="col-md-12" key={`sessionSchedule-${index}`}>
              <div className="notification-box box">
                <div className="space-between">
                  <a href="#" className="d-flex">
                    <i className="fa fa-calendar"></i>
                    <strong>Session Scheduled</strong>
                  </a>
                  {/* <small className="text-muted">3 sec ago</small> */}
                </div>
                <p>
                  A learner has scheduled session {course_name} with you on
                  {moment
                    .unix(sessionInfo.start_at)
                    .tz(timeZone)
                    .format("MMM Do, YYYY")}{" "}
                  |{" "}
                  {moment
                    .unix(sessionInfo.start_at)
                    .tz(timeZone)
                    .format("hh:mm A")}{" "}
                  {" - "}
                  {moment
                    .unix(sessionInfo.end_at)
                    .tz(timeZone)
                    .format("hh:mm A")}
                </p>
              </div>
            </div>
          );
        })
      )}
    </>
  );
};

export default SessionSchedule;
