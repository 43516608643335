import { ReactComponent as CheckCircleSmall } from "assets/images/icons/check-circle-small.svg";
import {
  checkDigit,
  checkLowercase,
  checkSpecialChar,
  checkUppercase,
} from "utils/schemas/shared";
import { useEffect, useState } from "react";
import TextInput from "./TextInput";

export default function ChangePasswordInput({
  name = "password",
  repeatName = "confirm_password",
  setFormData,
  formData
}) {
  const [focused, setFocused] = useState(false);
  const [blurred, setBlurred] = useState(false);
  const [value, setValue] = useState("");
  const [hasLength, setLength] = useState(false);
  const [hasUppercase, setUppercase] = useState(false);
  const [hasLowercase, setLowercase] = useState(false);
  const [hasSpecialChar, setSpecialChar] = useState(false);
  const [hasDigit, setDigit] = useState(false);

  useEffect(() => {
    setLength(value.length >= 8);
    setUppercase(checkUppercase(value));
    setLowercase(checkLowercase(value));
    setSpecialChar(checkSpecialChar(value));
    setDigit(checkDigit(value));
  }, [value]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setValue(e.target.value)
  };

  return (
    <>
      <div>
        <TextInput
          type="password"
          name={name}
          placeholder="Enter the new password"
          onChange={(e) => onChange(e)}
          label="New password"
          wFull
          onFocus={() => setFocused(true)}
          onBlur={() => setBlurred(true)}
          hideError
        />
        {focused && (
          <div className="space-y-1.5 mt-1.5">
            <PassCheck
              valid={hasLength}
              text="At least 8 characters"
              blurred={blurred}
            />
            <PassCheck
              valid={hasUppercase}
              text="At least one uppercase"
              blurred={blurred}
            />
            <PassCheck
              valid={hasLowercase}
              text="At least one lowercase"
              blurred={blurred}
            />
            <PassCheck
              valid={hasSpecialChar}
              text="At least one special character"
              blurred={blurred}
            />
            <PassCheck
              valid={hasDigit}
              text="At least one numeric digit"
              blurred={blurred}
            />
          </div>
        )}
      </div>
      <TextInput
        type="password"
        name={repeatName}
        placeholder="Re-enter the new password"
        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
        label="New password"
        wFull
      />
    </>
  );
}

function PassCheck({ text, valid, blurred }) {
  return (
    <div
      className={`text-sm flex items-center
        ${
          valid ? "text-green-600" : blurred ? "text-red-500" : "text-dgray-500"
        }`}
    >
      <CheckCircleSmall className={valid ? "opacity-100" : "opacity-0"} />
      <div className="ml-1.5">{text}</div>
    </div>
  );
}
