import axios from "utils/axios";
import {
  COURSE_CATEGORY_LOADING,
  COURSE_CATEGORY_LIST_UPDATED,
  COURSE_CATEGORY_FAIL,
  COURSE_CATEGORY_CREATED,
  COURSE_CATEGORY_CREATE_ERROR,
  COURSE_CATEGORY_UPDATED,
  COURSE_CATEGORY_UPDATE_ERROR,
  COURSE_CATEGORY_DELETED,
  COURSE_CATEGORY_DELETE_ERROR,
  COURSE_CATEGORY_CHANGE_STATUS,
  COURSE_CATEGORY_CHANGE_STATUS_ERROR,
  GET_COURSE_CATEGORY_BY_ID,
} from "actions/types";

//get CourseCategory list

export const getCourseCategoryList = (courseCategoryParams) => async (
  dispatch
) => {
  try {
    dispatch({
      type: COURSE_CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const query = courseCategoryParams.query
      ? courseCategoryParams.query
      : "&query=";
    const res = await axios.get(
      `/api/admin/coursecategory?limit=${courseCategoryParams.limit}&page=${courseCategoryParams.page}&orderBy=${courseCategoryParams.orderBy}&ascending=${courseCategoryParams.ascending}${query}`,
      config
    );

    dispatch({
      type: COURSE_CATEGORY_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: "success",
        data: res.data.response[0],
      },
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: COURSE_CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

// Create Course Category
export const create = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data;",
      },
    };

    const params = new window.FormData();
    params.append("category_type", "specific");
    params.append("category_name", formData.category_name);
    params.append("category_description", formData.category_description);
    params.append("category_image", formData.file);

    const res = await axios.post(
      `/api/admin/coursecategory/create`,
      params,
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: COURSE_CATEGORY_CREATED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
      history.push("/admin/course-program");
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: COURSE_CATEGORY_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: COURSE_CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

// Update Course Category
export const edit = (formData, history, coursecategory_id) => async (
  dispatch
) => {
  try {
    dispatch({
      type: COURSE_CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const params = new FormData();
    params.append("category_type", "specific");
    params.append("category_name", formData.category_name);
    params.append("category_description", formData.category_description);
    params.append("category_image", formData.file);

    const res = await axios.post(
      `/api/admin/coursecategory/${coursecategory_id}`,
      params,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: COURSE_CATEGORY_UPDATED,
        payload: {
          message: res.data.message,
          alertType: "success",
        },
      });
      history.push("/admin/course-program");
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: COURSE_CATEGORY_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: COURSE_CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

// Delete Course Category
export const deleteCourseCategory = (coursecategory_id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let res = await axios.delete(
      `/api/admin/coursecategory/${coursecategory_id}`,
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: COURSE_CATEGORY_DELETED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: COURSE_CATEGORY_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: COURSE_CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (coursecategory_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/admin/coursecategory/change-status/${coursecategory_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: COURSE_CATEGORY_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: COURSE_CATEGORY_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          data: coursecategory_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: COURSE_CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

// get Course Category by id
export const getCourseCategoryById = (coursecategory_id) => async (
  dispatch
) => {
  try {
    dispatch({
      type: COURSE_CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/admin/coursecategory/${coursecategory_id}`,
      config
    );
    await dispatch({
      type: GET_COURSE_CATEGORY_BY_ID,
      payload: {
        message: res.data.message,
        alertType: "success",
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: COURSE_CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};
