import React, { useState, useMemo, useEffect } from "react";
import * as moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const SpecificIndividualDetailPage = ({
  courseDetail,
  arrGroupedByMonth,
  selectedSessionId,
  showAllSessionsForSelectedDate,
  timeZone,
  sessions,
}) => {
  const dates_data = []
  arrGroupedByMonth.forEach((bundleSessions, i) => {
    bundleSessions.forEach((singleSession, j) => {
      let formattedDate = moment.unix(singleSession.start_at).tz(timeZone).format("YYYY-MM-DD")
      let index = dates_data.findIndex(d => d.format === formattedDate);
      let bookingCount = singleSession.session_booking_count;
      let startAt = moment.unix(singleSession.start_at).tz(timeZone).toDate();
      let endAt = moment.unix(singleSession.end_at).tz(timeZone).toDate();

      if(index !== -1){
        dates_data[index].availability.push({
          sessionId: singleSession._id,
          bookingCount: bookingCount,
          startAt: startAt,
          endAt: endAt,
        })
      }
      else {
        let date = {
          'date':  moment.unix(singleSession.start_at).tz(timeZone).startOf('day').toDate(),
          'formattedDate': formattedDate,
          'availability': [{ 
            sessionId: singleSession._id,
            bookingCount: bookingCount,
            startAt: startAt,
            endAt: endAt,
          }]
        }
        dates_data.push(date)
      }
      
    })
  })
  const [dates, setDates] = useState(dates_data);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availability, setAvailability] = useState([]);
  const [selectedTime, setselectedTime] = useState(null);
  const [sessionId, setsessionId] = useState("");

  const onSelect = (date) => {
    setSelectedDate(date);
    setselectedTime(null);
    setsessionId("");
  }

  const onClick = (item) => {
    setselectedTime(item.time);
    setsessionId(item.sessionId);
    showAllSessionsForSelectedDate(item.sessionId, arrGroupedByMonth, true)
  };

  useEffect(() => {
    let formatSelectedDate = moment(selectedDate).format("YYYY-MM-DD")
    let index = dates.findIndex(d => d.formattedDate === formatSelectedDate);
    if(index !== -1){
      let availabilities = []
      dates[index].availability.forEach((item, i)=>{
        availabilities.push({
          time: moment(item.startAt).tz(timeZone).format("HH:mm"),
          sessionId: item.sessionId
        })
      })
      setAvailability(availabilities);
    }
    else{
      setAvailability([]);
    }
  }, [selectedDate]);

  return (
    <ul className="info-list">
      {sessions.detail_for == 2 && (
        <>
          <li key="duration">
            <span>Duration</span>
            <div className="form-group m-0">
              <strong>
                {sessions.duration_info[0].duration_per_session}
                Mins
              </strong>
            </div>
          </li>
          <li key="time_slot">
          <span className="mb-1">Time Slots ({moment.tz(timeZone).format('z')})</span>
            <DatePicker
              minDate={new Date()}
              selected={selectedDate}
              onChange={onSelect}
              highlightDates={dates.map(d => d.date)}
              inline
            />
            <br />
            {selectedDate && availability && availability.length > 0 ? (
            <UncontrolledDropdown>
                <DropdownToggle>
                  {selectedTime ?? 'Pick A Time'} 
                </DropdownToggle>
                <DropdownMenu>
                  { availability.map(item => 
                  <DropdownItem
                  onClick={(e) => onClick(item)}
                  tag="li"
                  key={item.time}
                  >
                  {item.time}
                  </DropdownItem>
                  ) 
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              <span>Please select a date</span>
            )}    
            <br />
            {arrGroupedByMonth.map((bundleSessions, i) => {
              if (sessionId == bundleSessions[0]._id) {
                return bundleSessions.map((singleSession, j) => {
                  return (
                    <>
                      {j == 0 && (
                        <span className="session-grp-label">
                          Starting on{" "}
                          {moment
                            .unix(singleSession.start_at)
                            .tz(timeZone)
                            .format("MMM Do, YYYY")}
                        </span>
                      )}
                      <span className="session-number">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Interaction {j + 1} -
                      </span>
                      <span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {moment
                          .unix(singleSession.start_at)
                          .tz(timeZone)
                          .format("MMM Do, YYYY HH:mm A")}
                      </span>
                    </>
                  );
                });
              }
            })}
          </li>
        </>
      )}
    </ul>
  );
};

export default SpecificIndividualDetailPage;
