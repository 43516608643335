export default function Pill({ text, color, className = "" }) {
  return (
    <div
      className={`inline rounded-2xl py-0.5 px-2 text-xs font-medium whitespace-nowrap ${
        color === "blue"
          ? "bg-sky-100 text-sky-700"
          : color === "red"
          ? "bg-red-100 text-red-700"
          : color === "purple"
          ? "bg-purple-100 text-purple-700"
          : color === "orange"
          ? "bg-primary-50 text-primary-600"
          : color === "primary"
          ? "bg-primary-600 text-white"
          : color === "pink"
          ? "bg-pink-50 text-pink-700"
          : color === "indigo"
          ? "bg-indigo-50 text-indigo-700"
          : color === "green"
          ? "bg-green-50 text-green-700"
          : color === "teal"
          ? "bg-teal-50 text-teal-700"
          : "bg-dgray-100"
      } ${className}`}
    >
      {text}
    </div>
  );
}
