import { Dialog, Transition } from "@headlessui/react";
import { editPassword } from "actions/admin/profile";
import {
  currentUser,
  getConfigurations,
  update,
} from "actions/front/myaccount";
import { REMOVE_ERRORS } from "actions/types";
import { ReactComponent as CheckCircle } from "assets/images/icons/check-circle.svg";
import { ReactComponent as Close } from "assets/images/icons/close-icon.svg";
import { ReactComponent as InfoCircle } from "assets/images/icons/info-circle.svg";
import CenterContainer from "components/common/Container/Center";
import Button from "components/common/form/Button";
import ChangePasswordInput from "components/common/form/ChangePasswordInput";
import FormError from "components/common/form/FormError";
import SelectInput from "components/common/form/SelectInput";
import TextInput from "components/common/form/TextInput";
import Pill from "components/common/Pill";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { Fragment, useMemo, useState } from "react";
import Moment from "react-moment";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Spinner } from "reactstrap";
import Swal from "sweetalert2";
import getUserAvatar from "utils/getUserAvatar";
import changePasswordSchema from "utils/schemas/changePassword";
import { manageMySettingsSchema } from "utils/schemas/manageMyAccount";
import IconCircle from "views/Front/HomePage/IconCircle";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import timezones from "timezones-list";

const MyAccountSettings = ({
  getConfigurations,
  update,
  currentUser,
  user,
  location,
  errorList,
  isAuthenticated,
  authLoading,
  editPassword,
  onClosed,
}) => {
  /**************** dispatch to remove error *********************/
  const dispatch = useDispatch();
  const history = useHistory();

  /**************** remove errors when first loaded *********************/
  useMemo(() => {
    dispatch({ type: REMOVE_ERRORS });
  }, []);

  /********************Form data*****************/

  const initialData = {
    timezone: "",
    email: "",
    password: "",
    current_password: "",
    confirm_password: "",
    first_name,
    last_name,
    description,
    languages,
    expertise,
  };

  const [initialFormData, setInitialFormData] = useState(initialData);
  const [formData, setFormData] = useState(initialFormData);
  const [selectedAreaOfIntrest, setSelectedAreaOfIntrest] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpenTier, setIsOpenTier] = useState(false);
  const [passwordInfoModal, setPasswordInfoModal] = useState(false);

  const {
    timezone,
    email,
    password,
    current_password,
    confirm_password,
    first_name,
    last_name,
    description,
    languages,
    expertise,
    country,
    city,
  } = formData;

  /********************set user data to local state*****************/
  useMemo(() => {
    setLoading(true);
    if (user != null) {
      let updatedData = {
        email: user.email,
        timezone: user.timezone,
        current_password: "undefined",
        first_name: user.first_name,
        last_name: user.last_name,
        username: user.username,
        avatar: user.avatar,
        description: typeof user === "undefined" ? "" : user.description ?? "",
        languages: user.languages,
        expertise: user.expertise,
        city: user.city,
        state: user.state,
        country: user.country,
      };
      setInitialFormData(updatedData);
      setFormData(updatedData);
    }
    setLoading(false);
  }, [user]);

  // useMemo(() => {
  //   setFormData(initialFormData);
  // }, [initialFormData]);

  const reset = () => {
    setIsOpen(false);
    setIsOpenTier(false);
    setIsOpen(false);
    setFormData(initialData);
    dispatch({ type: REMOVE_ERRORS });
    onClosed();
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };

  /********************state to reset the state on submit success*****************/
  const [isSubmit, setIsSubmit] = useState(false);

  const savePassword = (e) => {
    editPassword(formData).then((res) => {
      //reset();
      if (res) {
        setIsOpen(false);
        setPasswordInfoModal(true);
      }
    });
  };

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpenTier(false);
    setIsOpen(false);
    setIsOpen(false);
    setPasswordInfoModal(false);
  }

  const pageOptions = [
    { label: "Edit My Profile", value: "edit" },
    { label: "Account Settings", value: "settings" },
  ];

  const selectedOptions = (selected) => {
    if (selected.value == "edit") history.push("/profile");
  };

  const onProfileSubmit = (e) => {
    var mergedData = { ...formData, ...e };
    update(mergedData).then((res) => {
      window.scrollTo(0, 0);
      if (res.status) {
        Swal.fire("", res.message, "success");
        setIsSubmit(false);
      }
    });
  };

  function openTierModal() {
    setIsOpenTier(true);
  }

  return (
    <>
      <Header />
      <CenterContainer>
        {loading ? (
          <Spinner />
        ) : (
          <div className="md:text-left pt-12 pb-12 xl:px-0 md:w-full md:px-4 mx-auto">
            <p className="text-dgray-900 mb-24 md:mb-8 text-5xl md:text-2xl text-center md:text-left">
              Manage my account
            </p>
            <div className="flex flex-row md:flex-col gap-4">
              <div className="flex flex-col">
                <div className="bg-white rounded-lg shadow border border-dgray-200 w-72 md:w-full p-4">
                  <div className="text-dgray-500 text-sm">
                    You are a <>{user?.userType === 3 ? "host" : "student"}</>{" "}
                    since{" "}
                    <Moment format="MMMM, YYYY">
                      {Number(user?.created_at)}
                    </Moment>
                  </div>
                  <div className="flex justify-between items-center mb-4 mt-4">
                    <div className="flex gap-2.5 justify-center items-center">
                      <img
                        src={getUserAvatar(user?.avatar)}
                        alt=""
                        className="rounded-full w-10 h-10"
                      />
                      <div>
                        <div className="text-sm font-medium">
                          {user?.full_name}
                        </div>
                      </div>
                    </div>
                    <button
                      className="justify-center items-center"
                      onClick={openTierModal}
                    >
                      <Pill text="Tier 3" color="indigo" className="text-sm" />
                    </button>
                  </div>
                </div>
                <SelectInput
                  options={pageOptions}
                  className="hidden md:block mt-4 text-sm"
                  defaultValue={pageOptions[1]}
                  onChange={selectedOptions}
                />
                <ul className="mt-4 space-y-1 md:hidden">
                  <li
                    className={`flex w-full justify-between cursor-pointer items-center rounded
                  ${
                    location.pathname === "/profile"
                      ? "text-primary-700 bg-primary-50"
                      : "text-dgray-700 hover:text-gray-500"
                  }
                `}
                  >
                    <Link
                      to="/profile"
                      className="flex items-center py-2 px-3 w-full"
                    >
                      <div
                        className={
                          location.pathname === "/profile"
                            ? "text-primary-600"
                            : "text-dgray-500"
                        }
                      ></div>
                      <span className="font-medium ml-2 md:ml-0">
                        Edit My Profile
                      </span>
                    </Link>
                  </li>
                  <li
                    className={`flex w-full justify-between cursor-pointer items-center rounded
                  ${
                    location.pathname === "/settings"
                      ? "text-primary-700 bg-primary-50"
                      : "text-dgray-700 hover:text-gray-500"
                  }
                `}
                  >
                    <Link
                      to="/settings"
                      className="flex items-center py-2 px-3 w-full"
                    >
                      <div
                        className={
                          location.pathname === "/settings"
                            ? "text-primary-600"
                            : "text-dgray-500"
                        }
                      ></div>
                      <span className="font-medium ml-2 md:ml-0">
                        Account Settings
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="w-full px-16 md:px-0">
                <Formik
                  initialValues={{
                    timezone: timezone,
                    email: email,
                    password: current_password,
                  }}
                  validationSchema={manageMySettingsSchema}
                  enableReinitialize
                  onSubmit={(e) => onProfileSubmit(e)}
                >
                  <Form>
                    <div className="flex flex-col md:w-full gap-5 w-1/2">
                      <TextInput
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Enter your email"
                        label="Email"
                        className="w-full"
                        disabled
                      />

                      <TextInput
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                        label="Password"
                        className="w-full"
                        value={password}
                        disabled
                      />
                      <button
                        type="button"
                        onClick={openModal}
                        className="text-sm md:text-left text-primary-700"
                      >
                        Change Password
                      </button>
                    </div>
                    <div className="border-t w-full mt-8 pt-5" />
                    <div className="w-1/2 md:w-full pt-5">
                      <SelectInput
                        label="Timezone"
                        name="timezone"
                        options={timezones.map((tz) => ({
                          value: tz.tzCode,
                          label: tz.label,
                        }))}
                        className="text-base text-dgray-900"
                      />
                    </div>
                    <label className="text-sm text-dgray-500">
                      Please note that the default timezone is derived from your
                      browser settings.
                    </label>
                    <div className="flex justify-end border-t mt-8 pt-5">
                      <div className="space-x-3">
                        <Button
                          type="button"
                          className="text-base mt-3 bg-white text-dgray-700 px-4 mr-2"
                          disabled={isSubmit}
                          onClick={reset}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="text-base text-white mt-3 bg-primary-600 px-4"
                          disabled={isSubmit}
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        )}
      </CenterContainer>
      <Footer />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={reset}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-10"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-10"
          >
            <div className="fixed inset-0 bg-black bg-dgray-700/70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <button
                    className="focus:outline-none absolute"
                    onClick={closeModal}
                    style={{ top: 16, right: 16 }}
                  >
                    <Close
                      style={{ width: "15px" }}
                      className="text-dgray-500"
                    />
                  </button>

                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium text-center items-center flex flex-col leading-6 text-dgray-900"
                  >
                    <div className="text-3xl mt-4 font-semibold">
                      Change Password
                    </div>
                  </Dialog.Title>
                  <Formik
                    initialValues={{
                      timezone: timezone,
                      email: email,
                    }}
                    validationSchema={changePasswordSchema}
                    onSubmit={(e) => savePassword(e)}
                    enableReinitialize
                  >
                    <Form>
                      {errorList &&
                        errorList.errorList.map((err) => (
                          <FormError>{err}</FormError>
                        ))}
                      <div className="mt-12 space-y-3">
                        <TextInput
                          type="password"
                          name="current_password"
                          placeholder="Enter the old password"
                          label="Old password"
                          minLength="6"
                          value={password}
                          onChange={(e) => onChange(e)}
                          className="w-full"
                        />
                        <ChangePasswordInput
                          setFormData={setFormData}
                          formData={formData}
                        />

                        <div className="text-center">
                          <Link
                            to={"/forgot-password"}
                            className="text-primary-700 text-sm"
                          >
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                      <div className="space-y-3 mt-4">
                        <Button
                          type="submit"
                          className="bg-primary-600 w-full py-2"
                        >
                          <label className="text-base text-white">
                            Change Password
                          </label>
                        </Button>
                        <Button
                          type="button"
                          className="w-full py-2 mr-3"
                          onClick={closeModal}
                        >
                          <label className="text-base text-dgray-700">
                            Cancel
                          </label>
                        </Button>
                      </div>
                    </Form>
                  </Formik>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/*****     Updated Password Modal    *****/}
      <Transition appear show={passwordInfoModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={reset}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-10"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-10"
          >
            <div className="fixed inset-0 bg-black bg-dgray-700/70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <button
                    className="focus:outline-none absolute"
                    onClick={closeModal}
                    style={{ top: 16, right: 16 }}
                  >
                    <Close
                      style={{ width: "15px" }}
                      className="text-dgray-500"
                    />
                  </button>

                  <Dialog.Title
                    as="h3"
                    className="text-lg text-center items-center flex flex-col leading-6"
                  >
                    <div className="flex flex-col mt-4 items-center">
                      <IconCircle color="green">
                        <CheckCircle />
                      </IconCircle>
                      <div className="text-dgray-900 text-lg font-medium mt-4">
                        Password successfully changed
                      </div>
                      <div className="text-dgray-500 text-sm mt-4">
                        You have successfully changed your password.
                      </div>
                      <Button
                        onClick={closeModal}
                        className="bg-primary-600 text-white px-40 mt-8 md:mt-0"
                      >
                        Ok
                      </Button>
                    </div>
                  </Dialog.Title>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpenTier} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={reset}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-10"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-10"
          >
            <div className="fixed inset-0 bg-black bg-dgray-700/70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <button
                    className="focus:outline-none absolute"
                    onClick={closeModal}
                    style={{ top: 16, right: 16 }}
                  >
                    <Close
                      style={{ width: "15px" }}
                      className="text-dgray-500"
                    />
                  </button>

                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium text-center items-center flex flex-col leading-6 text-dgray-900"
                  >
                    <div className="flex flex-col mt-4 items-center">
                      <IconCircle color="green">
                        <InfoCircle />
                      </IconCircle>
                      <div className="text-dgray-900 text-2xl font-semibold mt-4">
                        Host tiers
                      </div>
                    </div>
                  </Dialog.Title>
                  <div className="flex flex-col space-y-3">
                    <div className="text-lg text-dgray-500 mt-2 p-2">
                      DDN pays our host based on proficiency level. We have
                      three different tiers for hosts:{" "}
                    </div>
                    <div className="flex items-center p-2">
                      <Pill text="Tier 1" color="blue" className="text-sm" />
                      <div className="text-base text-sky-700 ml-4">
                        Compensated at $5/event
                      </div>
                    </div>
                    <div className="text-sm text-dgray-500">
                      Good English communication skill, and host is capable to
                      drive a conversion in a group.
                    </div>

                    <div className="flex items-center p-2">
                      <Pill text="Tier 2" color="pink" className="text-sm" />
                      <div className="text-base text-pink-700 ml-4">
                        Compensated at $8/event
                      </div>
                    </div>
                    <div className="text-sm text-dgray-500">
                      Advanced – Excellent communication skill, and host is able
                      to teach professional jargons and native expressions.
                    </div>

                    <div className="flex items-center p-2">
                      <Pill text="Tier 3" color="green" className="text-sm" />
                      <div className="text-base text-green-700 ml-4">
                        Compensated at $10/event
                      </div>
                    </div>
                    <div className="text-sm text-dgray-500">
                      English Native Speaker who is passion about helping ESL
                      learners to master the skill.
                    </div>
                    <div className="flex gap-3 justify-center">
                      <Button
                        type="button"
                        className="text-base text-white mt-3 bg-primary-600 w-1/2"
                        onClick={closeModal}
                      >
                        Ok
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/*****     Updated Password Modal    *****/}
      <Transition appear show={passwordInfoModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={reset}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-10"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-10"
          >
            <div className="fixed inset-0 bg-black bg-dgray-700/70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <button
                    className="focus:outline-none absolute"
                    onClick={closeModal}
                    style={{ top: 16, right: 16 }}
                  >
                    <Close
                      style={{ width: "15px" }}
                      className="text-dgray-500"
                    />
                  </button>

                  <Dialog.Title
                    as="h3"
                    className="text-lg text-center items-center flex flex-col leading-6"
                  >
                    <div className="flex flex-col mt-4 items-center">
                      <IconCircle color="green">
                        <CheckCircle />
                      </IconCircle>
                      <div className="text-dgray-900 text-lg font-medium mt-4">
                        Password successfully changed
                      </div>
                      <div className="text-dgray-500 text-sm mt-4">
                        You have successfully changed your password.
                      </div>
                      <Button
                        onClick={closeModal}
                        className="bg-primary-600 text-white px-40 mt-8 md:mt-0"
                      >
                        Ok
                      </Button>
                    </div>
                  </Dialog.Title>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

MyAccountSettings.propTypes = {
  editPassword: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loadingChangePassword: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  user: state.myaccount.user,
  profile_loading: state.myaccount.loading,
  isAuthenticated: state.auth.isAuthenticated,
  authLoading: state.auth.loading,
});

export default connect(mapStateToProps, {
  update,
  currentUser,
  getConfigurations,
  editPassword,
})(withRouter(MyAccountSettings));
