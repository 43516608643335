export function getErrorStep(names, errors) {
  const errorNames = Object.keys(errors);
  let minStepIndex = 99;
  for (let [i, step] of Object.entries(names)) {
    if (
      errorNames.find((errorName) => step.includes(errorName.split(".")[0]))
    ) {
      minStepIndex = +i;
      break;
    }
  }
  return minStepIndex + 1;
}
