import {
    LOG_ERROR,
    LOG_LIST_UPDATED,
    LOADING_LOG_LIST_DATA,
    REMOVE_ERRORS,
    LOG_LIST_COMPLETED,
  } from "actions/types";
  import * as Constants from "constants/index";

  const initialState = {
    logList: {
      page: 1,
      data: [],
      count: 0
    },
    currentLog: null,
    loadingLogList: true,
    loadingLog: true,
    loadingListData: true,
    error: {},
    sortingParams: {
      limit: Constants.DEFAULT_PAGE_SIZE,
      page: 1,
      orderBy: "created_at",
      ascending: "desc",
      query: "",
      filters:[],
    }
  };

  export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

      case LOG_ERROR:
        return {
          ...state,
          error: payload,
          loadingLog: false,
          loadingLogList: false,
          loadingListData: false,
        };
      case LOG_LIST_UPDATED:
        return {
          ...state,
          logList: {
            data: payload.data,
            page: payload.metadata[0].current_page,
            count: payload.metadata[0].totalRecord
          },
          // loadingLog: true,
          loadingLogList: false,
          loadingListData: false,
        };
      case LOADING_LOG_LIST_DATA:
        return {
          ...state,
          loadingListData: true
        };
        case LOG_LIST_COMPLETED:
        return {
          ...state,
          loadingListData: false
        };

      default:
        return state;
    }
  }
