import { ReactComponent as ChevronDown } from "assets/images/icons/chevron-down.svg";
import { ErrorMessage, Field } from "formik";
import { forwardRef, useState } from "react";
import ReactDatePicker from "react-datepicker";

export default function DatePicker({ name, onChange, disabled, ...props }) {
  if (name) {
    return (
      <Field name={name}>
        {({ field, meta, form }) => (
          <div>
            <DatePickerEl
              {...props}
              {...field}
              onChange={(date) => {
                field?.onChange(date.toISOString());
                onChange?.(date);
                form.setFieldValue(field.name, date);                  
              }}
              disabled={form.isSubmitting || disabled}
            />

            <ErrorMessage name={name}>
              {(msg) => (
                <div className="text-xs pt-2 pl-0.5 text-red-500">{msg}</div>
              )}
            </ErrorMessage>
          </div>
        )}
      </Field>
    );
  }

  return <DatePickerEl {...props} />;
}

function DatePickerEl({
  onChange,
  onSelect,
  label,
  excludePast,
  minDate,
  className = "",
  inline = false,
  selected = null,
  value,
  disabled,
  ...rest
}) {
  const [date, setDate] = useState(new Date());
  let props = {...rest}
  props.locale = navigator.language || "en-US";
  
  return (
    <div className={`${className}`}>
      {label && (
        <label
          htmlFor={label}
          className="text-sm text-dgray-700 font-medium inline-block mb-1.5 w-full"
        >
          {label}
        </label>
      )}

      <ReactDatePicker
        {...props}
        disabled={disabled}
        selected={selected ? selected : new Date(value) || date} //
        onSelect={(date) => {
          setDate(date);
          onChange?.(date);
          onSelect?.(date);
        }}
        minDate={minDate ? new Date(minDate) : excludePast && new Date()}
        showPopperArrow={false}
        customInput={<CustomInput disabled={disabled} />}
        inline={inline}
      />
    </div>
  );
}

const CustomInput = forwardRef(
  ({ value, onClick, disabled, className: _, ...props }, ref) => (
    <button
      type="button"
      className="relative border text-left border-dgray-300 disabled:bg-dgray-25 disabled:text-dgray-500 rounded-lg py-2 px-3.5 w-full"
      onClick={onClick}
      disabled={disabled}
      ref={ref}
      {...props}
    >
      {value}
      <ChevronDown className="absolute right-3.5 top-3" />
    </button>
  )
);
