import { ReactComponent as ArrowLeftIcon } from "assets/images/icons/arrow-left.svg";
import { ReactComponent as CheckCircleIcon } from "assets/images/icons/check-circle.svg";
import Button from "components/common/Button";
import CenterContainer from "components/common/Container/Center";
import { Link } from "react-router-dom";
import IconCircle from "../HomePage/IconCircle";

export default function BecomeAHostStep4({ ...props }) {
  return (
    <CenterContainer
      className="text-center my-24 md:my-0 w-[540px] md:w-full"
      tight
    >
      <IconCircle className="mx-auto mb-6" color="green">
        <CheckCircleIcon />
      </IconCircle>
      <h1 className="text-3xl font-semibold md:text-2xl mb-3 md:mb-2">
        Application submitted!
      </h1>
      <p className="text-dgray-500 mb-8">
        Your application for becoming an host has been successfully received.
        Our team will notify you about your application process soon.
      </p>
      <div>
        <Button to="/dashboard" className="w-full mb-6" small>
          Go to your DDN
        </Button>
        <Link
          to="/"
          className="text-sm text-dgray-500 font-medium flex items-center gap-2 justify-center p-2"
        >
          <ArrowLeftIcon />
          Back to home page
        </Link>
      </div>
    </CenterContainer>
  );
}
