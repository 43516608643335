import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types';
import { connect, useDispatch } from "react-redux";

import { getCourseById } from "actions/admin/courses";
import Spinner from "views/Spinner";
import Moment from "react-moment";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { getTimeZone } from "utils/time";

const CourseDetail = ({ getCourseById, match, user }) => {
  const [courses, setCourseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const tz = getTimeZone(user);

  // useEffect(() => {
  //   if (match.params.course_id)
  //     getCourseById(match.params.course_id);
  // }, [match.params.course_id]);

  useEffect(() => {
    setLoading(true);
    if (match.params.course_id) {
      getCourseById(match.params.course_id).then((data) => {
        setCourseData(data.response);
        setLoading(false);
      });
    }
  }, []);

  const getLogMessage = (log) => {
    switch (log.log_message) {
      case "MEETING_STARTED":
        return "Meeting started.";
      case "MEETING_ENDED":
        return "Meeting ended.";
      case "ATTENDEE_JOINED":
        return `${log.info.user.full_name} joined the meeting.`;
      case "ATTENDEE_LEFT":
        return `${log.info.user.full_name} left the meeting.`;
      case "ATTENDEE_DROPPED":
        return `${log.info.user.full_name} dropped from the meeting.`;
      default:
        break;
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    courses != null && (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>Course Detail</CardHeader>
              <CardBody>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>Course Name:</td>
                      <td>{courses.name}</td>
                    </tr>
                    <tr>
                      <td>Course Category</td>
                      <td>{courses.parent_category_name}</td>
                    </tr>
                    <tr>
                      <td>Course Overview</td>
                      <td>
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: courses.overview,
                          }}
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td>Course Ideal For</td>
                      <td>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: courses.ideal_for,
                          }}
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td>Course Information</td>
                      <td>
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: courses.course_information,
                          }}
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td>Host</td>
                      <td>{courses.mentor_name}</td>
                    </tr>
                    <tr>
                      <td>Date Created</td>
                      <td>
                        <Moment format="MM-DD-YYYY HH:ss a">
                          {Number(courses.created_at)}
                        </Moment>{" "}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {courses.meetings && courses.meetings.length > 0
          ? courses.meetings.map((meeting) => (
              <Row>
                <Col xs="12" sm="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={4}>
                          <h5>
                            <strong>Date</strong>:{" "}
                            <Moment
                              tz={tz}
                              date={meeting.created_at}
                              format="MM-DD-YYYY HH:ss a"
                            />
                          </h5>
                        </Col>
                        <Col md={4}>
                          <h5>
                            <strong>Attendee Count</strong>:{" "}
                            {meeting.attendees.length}
                          </h5>
                        </Col>
                        <Col md={4}>
                          <h5>
                            <strong>Meeting Status</strong>: {meeting.status}
                          </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <h3 className="my-4">Meeting Logs</h3>
                          <table
                            className="table table-bordered"
                            style={{ marginTop: "20px" }}
                          >
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Log</th>
                              </tr>
                            </thead>
                            <tbody>
                              {meeting.logs.map((log) => (
                                <tr>
                                  <td>
                                    <Moment
                                      tz={tz}
                                      date={log.created_at}
                                      format="MM-DD-YYYY HH:ss a"
                                    />
                                  </td>
                                  <td>{getLogMessage(log)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ))
          : null}
      </div>
    )
  );
};

// Dashboard.propTypes = {};

CourseDetail.propTypes = {};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getCourseById,
})(CourseDetail);
