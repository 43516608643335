import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";

const styles = {
  textAlign: "center",
};

const DateRangeInput = (props) => {
  const onSelect = (new_dates) => {
    props.setDates(new_dates);
  };

  return (
    <div>
      <DateRangePicker
        onSelect={onSelect}
        value={props.dates}
        singleDateRange={true}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(DateRangeInput);
