import DatePicker from "components/common/form/DatePicker";
import TimePicker from "components/common/form/DatePicker/TimePicker";
import RadioGroup from "components/common/form/RadioGroup";
import SelectInput from "components/common/form/SelectInput";
import { SESSION_DURATION } from "constants";
import { useFormikContext } from "formik";
import { useState } from "react";
import format from "date-fns/format";
import { getRecommendedTimes } from "actions/front/educator/courses";
import { useEffect } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";

export function Step2EventTimePicker({ disabled }) {
  const location = useLocation();
  const editId = new URLSearchParams(location.search).get("editId");

  return (
    <div>
      <RadioGroup
        disabled={disabled}
        defaultSelectedIndex={editId ? 1 : 0}
        items={[
          {
            label: "DDN recommended time",
            value: false,
            description:
              "This is a DDN-generated event date & time for the students.",
            expansion: <RecommendedTimes />,
          },
          {
            label: "Pick your own time",
            value: true,
            description:
              "Select from weekday & weekend based on your schedule.",
            expansion: <EvenTimePickerExpansion disabled={disabled} />,
          },
        ]}
      />
    </div>
  );
}

function RecommendedTimes() {
  const formikCtx = useFormikContext();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getRecommendedTimes()
      .then((res) => {
        setOptions(
          res.data.map((date) => ({
            label: moment(date).set({
              seconds: 0
            }).format("MMM DD YYYY, hh:mma"),
            value: date,
          }))
        );
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="flex gap-4">
      <SelectInput
        className="flex-1"
        label="Event Time"
        options={options}
        onChange={({ value }) => {
          formikCtx.setFieldValue("date", value);
        }}
      />
      <SelectInput
        label="Event Duration"
        name="duration"
        options={Object.entries(SESSION_DURATION).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    </div>
  );
}

function EvenTimePickerExpansion({ disabled }) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <div>
      <DatePicker
        className="mb-4"
        name="date"
        disabled={disabled}
        onSelect={(date) => {
          setFieldValue("date", date.toDateString());
        }}
        label="Event Date"
        excludePast
      />
      <div className="flex gap-4">
        <TimePicker
          disabled={disabled}
          label="Event start time"
          className="flex-1"
          selected={
            values.time
              ? moment(
                  `${moment().format("YYYY-MM-DDz")} ${values.time}`
                ).set({
                  seconds: 0
                }).toDate()
              : null
          }
          onChange={(time) => {
            setFieldValue("time", format(time, "HH:mm"));
          }}
        />
        <SelectInput
          disabled={disabled}
          className="flex-1"
          label="Event Duration"
          name="duration"
          options={Object.entries(SESSION_DURATION).map(([value, label]) => ({
            label,
            value,
          }))}
        />
      </div>
    </div>
  );
}
