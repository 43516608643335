import React, { useState, useMemo, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Errors from "views/Notifications/Errors";
import CKEditor from "ckeditor4-react";
import { Spinner } from "reactstrap";
import { REMOVE_ERRORS, EDUCATOR_COURSE_CREATE_ERROR } from "actions/types";
import { SESSION_TYPE, CHIME_CLIENT_URL } from "constants/index";
import moment from "moment";
import { getTimeZone } from "utils/time";
import validator from "validator";
import { editCourse, getCourseById } from "actions/front/educator/courses";
import ImageCrop from "views/Front/Utils/ImageCrop";

const EditLiveEvent = ({
  user,
  categories,
  token,
  isConfirmation = false,
  isEdit = false,
  isDraft = false,
  history,
  createCourse,
  priceConfirmation = false,
  editCourse,
  match,
  getCourseById,
}) => {
  const [categoryList, setCategoryList] = useState(categories.data || []);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [invalidImage, setInvalidImage] = useState(false);
  const [basicErrorMsg, setBasicErrorMsg] = useState("");
  const [sessionErrorMsg, setSessionErrorMsg] = useState("");
  const [typeOfSession, setTypeOfSession] = useState(SESSION_TYPE.LIVE_EVENT);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState("00:00");
  const [startNow, setStartNow] = useState(false);
  const [sessionFormData, setSessionFormData] = useState();
  const [meetingId, setMeetingId] = useState(null);
  const [editSessionData, seteditSessionData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const dispatch = useDispatch();
  let timeZone = getTimeZone(user);
  var current_date = moment.tz(timeZone).format("YYYY-MM-DD");

  /*********************get course category ************************/

  useEffect(() => {
    if (!categories.isLoading && categories.data) {
      setCategoryList(
        categories.data.filter((c) => c.category_type !== "generic")
      );
    }
  }, [categories]);

  /*********************reset session form data************************/
  const initialSessionFormData = {
    live_event: {
      duration_per_session: "",
      number_of_session: 1,
      price_per_session: "0",
      all_session_with_recurring: [],
      max_students: 250,
    },
  };

  const resetSessionFormData = () => {
    setSessionFormData({ ...initialSessionFormData });
  };

  /*********************Explode courseInformationFormData ************************/
  const [courseInformationFormData, setcourseInformationFormData] = useState({
    category_id: "",
    sub_category_id: "",
    name: "",
    image: "",
    overview: "",
    ideal_for: "",
    course_information: "",
    category_type: "",
    price_per_session: 0,
    max_students: 250,
    detail_for: 5,
    is_preview: false,
    oldImage: "",
  });

  const onStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const onStartTimeChange = (e) => {
    setStartTime(e.hour + ":" + e.minute);
  };

  /*********************get course data************************/
  useMemo(() => {
    if (match.params.course_id) {
      dispatch({ type: REMOVE_ERRORS });

      getCourseById(match.params.course_id).then(async (res) => {
        if (res.status) {
          let course = res.data;
          setcourseInformationFormData({
            ...courseInformationFormData,
            category_id: course.category_id,
            sub_category_id: course.sub_category_id,
            name: course.name,
            image: !course.image ? "" : process.env.REACT_APP_MEDIA_URL + course.image,
            oldImage: !course.image ? "" : course.image,
            overview: course.overview,
            ideal_for: course.ideal_for,
            course_information: course.course_information,
            category_type: course.category_type,
            is_preview: course.image ? true : false,
          });
          seteditSessionData({
            start_at: moment
              .unix(
                course.session_detail[0].session_group[0]
                  .all_session_with_recurring[0].start_at
              )
              .tz(timeZone)
              .format("YYYY-MM-DD"),
            start_time: moment
              .unix(
                course.session_detail[0].session_group[0]
                  .all_session_with_recurring[0].start_at
              )
              .tz(timeZone)
              .format("HH:mm"),
            _id: course.session_detail[0].session_group[0]
              .all_session_with_recurring[0]._id,
            session_booking_count:
              course.session_detail[0].session_group[0]
                .all_session_with_recurring[0].session_booking_count,
          });
          setStartDate(
            moment
              .unix(
                course.session_detail[0].session_group[0]
                  .all_session_with_recurring[0].start_at
              )
              .tz(timeZone)
              .format("YYYY-MM-DD")
          );
          setStartTime(
            moment
              .unix(
                course.session_detail[0].session_group[0]
                  .all_session_with_recurring[0].start_at
              )
              .tz(timeZone)
              .format("HH:mm")
          );
          if (
            course.session_detail[0].session_group[0]
              .all_session_with_recurring[0].meeting_id
          ) {
            setStartNow(true);
            setIsDisabled(true);
          }
        }
      });
    }
  }, [match.params.course_id]);

  useMemo(() => {
    if (courseInformationFormData.category_id != "") {
      let categoryDetail = categoryList.filter((item) =>
        item._id.includes(courseInformationFormData.category_id)
      );
      if (categoryDetail.length > 0) {
        let subcategory = categoryDetail[0].subcategories;
        setSubCategoryList(subcategory);
      } else setSubCategoryList([]);
    }
  }, [categoryList, courseInformationFormData]);

  useMemo(() => {
    let time_picker = null;
    let recurring = null;
    if (startNow) {
      const nowDate = moment().format("YYYY-MM-DD");
      const nowTime = moment().format("HH:mm");
      time_picker = {
        start_date: nowDate,
        start_time: nowTime,
        end_date: nowDate,
      };

      recurring = {
        start_at: moment
          .tz(nowDate + " " + nowTime, timeZone)
          .utc()
          .unix(),
        end_at: null,
      };
    } else if (startDate && startTime) {
      time_picker = {
        start_date: startDate,
        start_time: startTime,
        end_date: startDate,
      };

      recurring = {
        start_at: moment
          .tz(startDate + " " + startTime, timeZone)
          .utc()
          .unix(),
        end_at: null,
        _id: editSessionData._id,
        session_booking_count: editSessionData.session_booking_count
      };
    }
    if (time_picker && recurring) {
      setSessionFormData({
        live_event: {
          duration_per_session: courseInformationFormData.duration_per_session,
          number_of_session: 1,
          price_per_session: 0, //courseInformationFormData.price_per_session,
          max_students: 250,
          session_group: [
            {
              sessions: [{ ...time_picker }],
              all_session_with_recurring: [{ ...recurring }],
            },
          ],
          all_session_with_recurring: { ...recurring },
          start_date: startDate,
          end_date: startDate,
          form_data: [
            { ...courseInformationFormData, session_data: [{ ...recurring }] },
          ],
        },
      });
    }
  }, [startNow, startDate, startTime, courseInformationFormData]);

  const onChange = (e) => {
    if (e.target.name === "category_id") {
      if (e.target.value !== "") {
        let categoryDetail = categoryList.filter((item) =>
          item._id.includes(e.target.value)
        );
        let cat_type = "";
        if (categoryDetail.length > 0) {
          cat_type = categoryDetail[0].category_type;
          let subcategory = categoryDetail[0].subcategories;
          setSubCategoryList(subcategory);
        } else setSubCategoryList([]);

        setcourseInformationFormData({
          ...courseInformationFormData,
          [e.target.name]: e.target.value,
          sub_category_id: "",
          category_type: cat_type,
        });
      } else {
        setcourseInformationFormData({
          ...courseInformationFormData,
          [e.target.name]: "",
          sub_category_id: "",
          category_type: "",
        });
        setSubCategoryList([]);
      }
      //reset session form data if the category is changed
      resetSessionFormData();
    } else {
      setcourseInformationFormData({
        ...courseInformationFormData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onImageChange = (data) => {
    setInvalidImage(false);
    let image_url = URL.createObjectURL(data);
    setcourseInformationFormData({
      ...courseInformationFormData,
      selectedFile: data,
      image: image_url,
      isNewFileSelected: 1,
      is_preview: true,
    });
  };

  const removeImage = (e) => {
    setInvalidImage(false);
    setcourseInformationFormData({
      ...courseInformationFormData,
      thumbnail: "",
      image: "",
      is_preview: false,
      isNewFileSelected: 0,
      selectedFile: "",
    });
  };

  const handleOverviewChange = (event) => {
    setcourseInformationFormData({
      ...courseInformationFormData,
      overview: event.editor.getData(),
    });
    setBasicErrorMsg("");
    dispatch({ type: REMOVE_ERRORS });
  };

  const handleIdealForChange = (event) => {
    setcourseInformationFormData({
      ...courseInformationFormData,
      ideal_for: event.editor.getData(),
    });
    setBasicErrorMsg("");
    dispatch({ type: REMOVE_ERRORS });
  };

  const [courseInforTabErrorList, setCourseInforTabErrorList] = useState([]);

  useMemo(() => {
    let errorsList = [];
    let isValid = true;
    if (courseInformationFormData.category_id === "") {
      isValid = false;
      errorsList[`category_id`] = "Please Select Category";
    }
    if (courseInformationFormData.sub_category_id === "") {
      isValid = false;
      errorsList[`sub_category_id`] = "Please Select Sub Category";
    }
    if (courseInformationFormData.name.trim() === "") {
      isValid = false;
      errorsList[`name`] = "Please enter course name";
    } else if (
      courseInformationFormData.name.trim() !== "" &&
      !validator.isLength(courseInformationFormData.name.trim(), {
        min: 5,
        max: 500,
      })
    ) {
      isValid = false;
      errorsList[`name`] = "Course name must be 5-500 charaters long";
    }

    if (courseInformationFormData.overview === "") {
      isValid = false;
      errorsList[`overview`] = "Please enter overview";
    } else if (
      courseInformationFormData.overview !== "" &&
      !validator.isLength(courseInformationFormData.overview, {
        min: 30,
      })
    ) {
      isValid = false;
      errorsList[`overview`] = "Overview must be at least 30 charaters long";
    }

    if (courseInformationFormData.ideal_for === "") {
      isValid = false;
      errorsList[`ideal_for`] = "Please enter ideal for";
    } else if (
      courseInformationFormData.ideal_for !== "" &&
      !validator.isLength(courseInformationFormData.ideal_for, {
        min: 30,
      })
    ) {
      isValid = false;
      errorsList[`ideal_for`] = "Ideal for must be at least 30 charaters long";
    }
    if (isValid) {
      setCourseInforTabErrorList(null);
    } else {
      setCourseInforTabErrorList(errorsList);
    }
  }, [courseInformationFormData]);

  /*********************submit loader ************************/
  const [submitLoading, setSubmitLoading] = useState(false);

  const handelCourseInformationChange = (event) => {
    setcourseInformationFormData({
      ...courseInformationFormData,
      course_information: event.editor.getData(),
    });
    setBasicErrorMsg("");
    dispatch({ type: REMOVE_ERRORS });
  };

  /*********************on submit of  course form************************/
  const onSubmitForm = (e) => {
    e.preventDefault();
    if (courseInforTabErrorList != null) {
      dispatch({
        type: EDUCATOR_COURSE_CREATE_ERROR,
        payload: {
          message: "Please correct the errors",
          alertType: "danger",
          errorsList: courseInforTabErrorList,
        },
      });
      setBasicErrorMsg("Please fix validation errors identified below");
      window.scrollTo(0, 0);
    } else {
      setSubmitLoading(true);
      editCourse(
        match.params.course_id,
        courseInformationFormData,
        sessionFormData,
        typeOfSession,
        history,
        !startNow,
        startNow
      ).then((res) => {
        if (!res.status) {
          if (res.isErrorInBasic) {
            setBasicErrorMsg(res.msg);
          } else {
            setSessionErrorMsg(res.msg);
          }
          setSubmitLoading(false);
        } else {
          if (startNow) {
            setMeetingId(res.data.meetingId);
          }
          setSubmitLoading(false);
        }
      });
    }
  };

  if (!submitLoading && startNow && meetingId) {
    return (
      <div className="col-lg-9 pl-3">
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div className="section-head space-between mb-3">
                <h2>Add Live Event</h2>
              </div>
              <Link to="/dashboard">Back to Calendar</Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>Your event is ready! Click below to go to event!</p>
              <a
                href={`${CHIME_CLIENT_URL}join/${meetingId}?token=${token}`}
                className="btn btn-secondary"
                target="_blank"
              >
                Go to Event
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="col-lg-9 pl-3">
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <div className="section-head space-between mb-3">
              <h2>Edit Live Event</h2>
            </div>
            <Link to="/dashboard">Back to Calendar</Link>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="category_id">
                Category <span className="text-danger">*</span>
              </label>
              <select
                className="form-control custom-select"
                name="category_id"
                id="category_id"
                value={courseInformationFormData.category_id}
                onChange={(e) => onChange(e)}
                required
                disabled={isConfirmation || isEdit || isDraft}
                // invalid={errorList.category_id ? true : false}
              >
                <option value={""}> {"Select Category"} </option>
                {categoryList
                  ? categoryList.map((value, index) => {
                      return (
                        <option key={index} value={value._id}>
                          {value.name}
                        </option>
                      );
                    })
                  : ""}
              </select>
              <Errors current_key="category_id" key="category_id" />
              {/* <small className="error text-danger">Please select course type</small> */}
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="sub_category_id">
                Sub Category <span className="text-danger">*</span>
              </label>
              <select
                className="form-control custom-select"
                name="sub_category_id"
                id="sub_category_id"
                value={courseInformationFormData.sub_category_id}
                onChange={(e) => onChange(e)}
                required
                disabled={isConfirmation || isEdit || isDraft}

                // invalid={errorList.sub_category_id ? true : false}
              >
                <option value={""}> {"Select Sub Category"} </option>
                {subCategoryList
                  ? subCategoryList.map((value, index) => {
                      return (
                        <option key={index} value={value._id}>
                          {value.name}
                        </option>
                      );
                    })
                  : ""}
              </select>
              {/* <small className="error text-success">Success message will be like this</small> */}
              <Errors current_key="sub_category_id" key="sub_category_id" />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="name">
                Event Name <span className="text-danger">*</span>
                <br />
                <small className="text-muted">
                  (Please keep the name brief but impactful. For example,
                  ‘Effectively Communicating with Executives’ or ‘Career
                  Guidance in a post pandemic world’ etc.)
                </small>
              </label>
              <input
                className="form-control"
                placeholder="e.g. Effectively Communicating with Executives"
                type="text"
                id="name"
                name="name"
                maxLength="250"
                minLength="3"
                value={courseInformationFormData.name}
                required
                onChange={(e) => onChange(e)}
                disabled={isConfirmation}
                // invalid={errorList.name ? true : false}
              />
              <Errors current_key="name" key="name" />
            </div>
          </div>

          {(!isConfirmation ||
            (isConfirmation && courseInformationFormData.image != "")) && (
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="image">Event Image</label>
                <ImageCrop
                  image={courseInformationFormData.image}
                  disabled={isConfirmation}
                  className="form-control"
                  onComplete={onImageChange}
                  onRemove={removeImage}
                  aspect={5 / 3}
                />                
                <Errors current_key="image" key="image" />
              </div>
            </div>
          )}

          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="overview">
                Overview <span className="text-danger">*</span>
                <br />
                <small className="text-muted">
                  Provide brief overview of this event (effectively illustrate
                  and outline what this event is about) (Min: 30)
                </small>
              </label>
              <CKEditor
                data={courseInformationFormData.overview}
                id="overview"
                name="overview"
                // className="form-control"
                config={{
                  height: 200,
                  toolbar: [
                    ["Source"],
                    ["Bold", "Italic", "Strike"],
                    ["Cut", "Copy", "Paste"],
                    ["Undo", "Redo"],
                    ["SpellChecker"],
                    ["Link", "Unlink", "Anchor"],
                    ["Maximize"],
                    ["RemoveFormat"],
                    ["NumberedList", "BulletedList"],
                    ["DecreaseIndent", "IncreaseIndent"],
                    ["BlockQuote"],
                    ["Styles"],
                    ["Format"],
                  ],
                  readOnly: isConfirmation ? true : false,
                  // uiColor: isConfirmation ? "#e9ecef" : "none",
                }}
                onChange={(event) => handleOverviewChange(event)}
                onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                // disabled={isConfirmation}
                // invalid={errorList.expertise ? true : false}
              />
              <Errors current_key="overview" key="overview" />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="ideal_for">
                Ideal for <span className="text-danger">*</span>
                <br />
                <small className="text-muted">
                  Provide brief details (Min: 30)
                </small>
              </label>
              {/*<textarea
            cols={30}
            rows={3}
            className="form-control"
            id="ideal_for"
            name="ideal_for"
            value={ideal_for}
            onChange={(e) => onChange(e)}
            disabled={isConfirmation}
          />*/}
              <CKEditor
                data={courseInformationFormData.ideal_for}
                id="ideal_for"
                name="ideal_for"
                config={{
                  height: 200,
                  toolbar: [
                    ["Source"],
                    ["Bold", "Italic", "Strike"],
                    ["Cut", "Copy", "Paste"],
                    ["Undo", "Redo"],
                    ["SpellChecker"],
                    ["Link", "Unlink", "Anchor"],
                    ["Maximize"],
                    ["RemoveFormat"],
                    ["NumberedList", "BulletedList"],
                    ["DecreaseIndent", "IncreaseIndent"],
                    ["BlockQuote"],
                    ["Styles"],
                    ["Format"],
                  ],
                  readOnly: isConfirmation ? true : false,
                }}
                onChange={(event) => handleIdealForChange(event)}
                onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                disabled={isConfirmation}
                // invalid={errorList.expertise ? true : false}
              />
              <Errors current_key="ideal_for" key="ideal_for" />
            </div>
          </div>
          {/*<div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="price_per_session">
                    Price ($) <span className="text-danger">*</span>
                    <br />
                    <small>Enter 0 if you would like this event to be free</small>
                  </label>
                  <input
                    type="number"
                    min="0"
                    className="form-control"
                    id="price_per_session"
                    name="price_per_session"
                    defaultValue={courseInformationFormData.price_per_session}
                    onChange={(e) => onChange(e)}
                    disabled={priceConfirmation}
                  />
                  {!isConfirmation && (
                    <Errors
                      current_key="price_per_session"
                      key="price_per_session"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="max_students">
                    Max of Attendees <span className="text-danger">*</span>
                    <br />
                    <small>Enter if you want to limit number of attendees</small>
                  </label>
                  <input
                    type="text"
                    pattern="[0-9]*"
                    className="form-control"
                    id="max_students"
                    name="max_students"
                    defaultValue={courseInformationFormData.max_students}
                    onChange={(e) => onChange(e)}
                    disabled={isConfirmation}
                  />
                  {!isConfirmation && (
                    <Errors current_key="max_students" key="max_students" />
                  )}
                </div>
                  </div>*/}
        </div>

        <div className="row">
          {!startNow ? (
            <>
              <div className="col-md-4">
                <label htmlFor="start_date">
                  Date <span className="text-danger">*</span>
                </label>
                <div className="form-group">
                  <input
                    type="date"
                    className="form-control"
                    name="start_date"
                    onChange={(e) => onStartDateChange(e)}
                    value={startDate}
                    min={current_date}
                    disabled={isConfirmation}
                  />
                  {!isConfirmation && (
                    <Errors current_key={`start_date`} key={`start_date`} />
                  )}
                </div>

                <Errors current_key="start_date" key="start_date" />
              </div>
              <div className="col-md-5">
                <label htmlFor="start_time">
                  Start Time <span className="text-danger">*</span>
                </label>
                <div className="form-group w-75">
                  {/* 
                  <TimePicker
                    name="start_time"
                    onTimeChange={(e) => onStartTimeChange(e)}
                    theme="classic"
                    timeConfig={{
                      from: 0,
                      to: "23:45",
                      step: 15,
                      unit: "minutes",
                    }}
                    className="form-control"
                    time={startTime}
                    disabled={isConfirmation}
                  />

                  {!isConfirmation && (
                    <Errors
                      current_key={`live_event_start_time`}
                      key={`live_event_start_time`}
                    />
                  )}
                  */}
                </div>
              </div>
            </>
          ) : null}
          <div className="col-md-4 space-between">
            <label>
              <input
                type="checkbox"
                className="mr-1"
                onChange={(e) => setStartNow(e.target.checked)}
                checked={startNow}
                disabled={isDisabled}
              />
              Start Now
            </label>
          </div>

          <div className="col-md-4">
            <div className="form-group text-right mb-0 mr-0">
              {submitLoading ? (
                <Spinner />
              ) : (
                <button
                  className="btn btn-secondary mb-0"
                  onClick={(e) => onSubmitForm(e)}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  categories: state.categories,
  isAuthenticated: state.auth.isAuthenticated,
  authLoading: state.auth.loading,
  token: state.auth.token,
});

export default connect(mapStateToProps, { editCourse, getCourseById })(
  withRouter(EditLiveEvent)
);
