import React from "react";

const TabsHeader = ({
  TABS,
  activeTab,
  setActiveTab,
  sessionEnable,
  calendarConfirmationTabEnable,
  checkSessionValidation,
  submitLoading,
}) => {
  /*********************on tab header click************************/
  const onTabClick = async (e, tab) => {
    if (tab == TABS.CALENDAR || tab == TABS.CONFIRMATION) {
      let promise = await checkSessionValidation();
      let conflictStatus = false;
      await Promise.resolve(promise).then((value) => {
        conflictStatus = value;
      });
      if (conflictStatus) {
        setActiveTab(tab);
      }
    } else {
      setActiveTab(tab);
    }
  };

  return (
    <div className="row course-tab-header">
      <div
        className={`col-md-3 course-tab-panel ${
          activeTab === TABS.BASIC ? "active" : "inactive"
        } ${!submitLoading ? "enabled" : "disabled"}`}
        onClick={(e) => onTabClick(e, TABS.BASIC)}
      >
        <h4>
          <i className="fa fa-book" />
          Session Information
        </h4>
      </div>
      <div
        className={`col-md-3 course-tab-panel ${
          activeTab == TABS.SESSION ? "active" : "inactive"
        } ${sessionEnable && !submitLoading ? "enabled" : "disabled"}`}
        onClick={(e) => onTabClick(e, TABS.SESSION)}
      >
        {/* <div
        className={`col-md-3 course-tab-panel ${
          activeTab === TABS.SESSION ? "active" : "inactive"
        } enabled`}
        onClick={(e) => onTabClick(e, TABS.SESSION)}
      > */}
        <h4>
          <i className="fa fa-list" />
          Manage Availability
        </h4>
      </div>
      <div
        className={`col-md-3 course-tab-panel ${
          activeTab === TABS.CALENDAR ? "active" : "inactive"
        } ${
          calendarConfirmationTabEnable && !submitLoading
            ? "enabled"
            : "disabled"
        }`}
        onClick={(e) => onTabClick(e, TABS.CALENDAR)}
      >
        <h4>
          <i className="fa fa-user" />
          Calendar Preview
        </h4>
      </div>
      <div
        className={`col-md-3 course-tab-panel ${
          activeTab === TABS.CONFIRMATION ? "active" : "inactive"
        } ${
          calendarConfirmationTabEnable && !submitLoading
            ? "enabled"
            : "disabled"
        }`}
        onClick={(e) => onTabClick(e, TABS.CONFIRMATION)}
      >
        <h4>
          <i className="fa fa-group" />
          Confirmation
        </h4>
      </div>
    </div>
  );
};

export default TabsHeader;
