import { ReactComponent as FilterLines } from "assets/images/icons/filter-lines.svg";
import { ReactComponent as Target } from "assets/images/icons/target.svg";
import { getAllMentors } from "actions/front/myaccount";
import Button from "components/common/Button";
import CenterContainer from "components/common/Container/Center";
import SelectInput from "components/common/form/SelectInput";
import TextInput from "components/common/form/TextInput";
import Spinner from "views/Spinner";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import HostCardLarge from "./HostCardLarge";
import Pagination from "components/common/Pagination";
import countries from "constants/countries";
import languages from "constants/languages";

const initialFilters = {
  page: 1,
  query: "",
  country: "",
  language: "",

  limit: 4,
  orderBy: "created_at",
  custom_filters: "",
  ascending: "desc",
};

function HostListLarge() {
  const [hostData, setHostData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ ...initialFilters });
  const [page, setPage] = useState(1);

  const fetchData = async (f = filters) => {
    setLoading(true);
    const res = await getAllMentors({ ...f, page });
    if (res.status) {
      setHostData({
        count: res.data.metadata[0].totalRecord,
        hosts: res.data.data,
      });
    }
    setLoading(false);
  };

  const updateFilters = (filter, value) => {
    setFilters((f) => ({ ...f, [filter]: value }));
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const onClickClear = () => {
    setFilters(initialFilters);
    fetchData(initialFilters);
  };

  const onClickSearch = () => {
    fetchData({ ...filters, page: 0 });
  };

  return (
    <CenterContainer className="py-24 md:py-16">
      <HostListFilters
        onClickClear={onClickClear}
        onClickSearch={onClickSearch}
        isLoading={isLoading}
        filters={filters}
        updateFilters={updateFilters}
      />
      {isLoading ? (
        <Spinner />
      ) : hostData?.hosts?.length > 0 ? (
        <>
          <div className="lg:flex-col gap-8 md:gap-4 mb-16 space-y-6">
            {hostData.hosts.map((host, i) => (
              <HostCardLarge data={host} key={i} />
            ))}
          </div>
        </>
      ) : (
        <div className="text-dgray-500 text-center">No hosts found</div>
      )}

      {hostData && hostData?.hosts?.length > 0 ? (
        <Pagination
          className="border-t pt-5"
          page={page}
          setPage={setPage}
          count={hostData.count}
        />
      ) : null}
    </CenterContainer>
  );
}

export default connect(() => ({}), {})(HostListLarge);

function HostListFilters({
  onClickClear,
  onClickSearch,
  isLoading,
  updateFilters,
  filters,
}) {
  const [country, setCountry] = useState("");
  const [language, setLanguage] = useState("");
  const [spec, setSpec] = useState("");

  useEffect(() => updateFilters("country", country.value), [country]);
  useEffect(() => updateFilters("language", language.value), [language]);
  useEffect(() => updateFilters("specialization", spec.value), [spec]);

  return (
    <div className="mb-16 md:mb-6 lg:flex lg:flex-col-reverse gap-3">
      <div className="flex justify-between">
        <div className="flex gap-3 mb-4 lg:hidden">
          <SelectInput
            options={countryOptions}
            placeholder="Country"
            value={country}
            onChange={(val) => setCountry(val)}
            small
          />
          <SelectInput
            options={languageOptions}
            placeholder="Spoken Languages"
            value={language}
            onChange={(val) => setLanguage(val)}
            small
          />
          <SelectInput
            options={specializationOptions}
            placeholder="Specialization"
            value={spec}
            onChange={(val) => setSpec(val)}
            small
          />
        </div>
        <div className="lg:w-full">
          <Button className="lg:w-full block" flat small>
            <FilterLines className="inline mr-2" />
            <span className="lg:hidden">More filters</span>
            <span className="hidden lg:inline">Filters</span>
          </Button>
        </div>
      </div>
      <div className="flex gap-3">
        <div className="flex-1">
          <TextInput
            placeholder="Search by host name"
            className="w-full"
            value={filters.query}
            onChange={(e) => updateFilters("query", e.target.value)}
          />
        </div>
        <Button
          className="lg:hidden"
          small
          flat
          onClick={() => {
            setCountry("");
            setLanguage("");
            onClickClear();
          }}
        >
          Clear
        </Button>
        <Button
          className="lg:hidden"
          small
          onClick={onClickSearch}
          disabled={isLoading}
        >
          Search
        </Button>
      </div>
    </div>
  );
}

const languageOptions = languages.map(({ name, code }) => ({
  label: name,
  value: code,
}));

const specializationOptions = [
  { label: "Business English", value: "Business English" },
  { label: "Exam Preparation", value: "Exam Preparation" },
  { label: "Conversational English", value: "Conversational English" },
  { label: "Travel English", value: "Travel English" },
];

const countryOptions = countries.map(({ name }) => ({
  label: name,
  value: name,
}));
