import axios from "utils/axios";

import {
  EDUCATOR_LOADING,
  EDUCATOR_LIST_UPDATED,
  EDUCATOR_FAIL,
  EDUCATOR_CHANGE_STATUS,
  EDUCATOR_CHANGE_STATUS_ERROR,
  DELETE_USER,
} from "actions/types";
import { USER_TYPE } from "constants";

export const getFullEducatorsList = async (count) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(
      `/api/admin/educator?limit=${count}&user_type=${USER_TYPE.HOST}`,
      config
    );
    return res.data.response[0];
  } catch (err) {
    console.log(err);
  }
};

//get users list

export const getEducatorsList = (userParams) => async (dispatch) => {
  try {
    dispatch({
      type: EDUCATOR_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const query = userParams.query ? userParams.query : "&query=";
    const res = await axios.get(
      `/api/admin/educator?limit=${userParams.limit}&page=${userParams.page}&orderBy=${userParams.orderBy}&ascending=${userParams.ascending}${query}&user_type=${USER_TYPE.HOST}`,
      config
    );
    dispatch({
      type: EDUCATOR_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: "success",
        data: res.data.response[0],
      },
    });
    return res.data.response[0];
  } catch (err) {
    // console.log(err);
    dispatch({
      type: EDUCATOR_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

export const getEducatorDetail = async (id) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(
      `/api/admin/educator/${id}?`,
      config
    );
    return res.data.response;
  } catch (err) {
    // console.log(err);
  }
};


//change status
export const changeStatus = (user_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: EDUCATOR_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/admin/educator/change-status/${user_id}`,
      { status },
      config
    );

    if (res.data.status === true) {
      await dispatch({
        type: EDUCATOR_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: EDUCATOR_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          data: user_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: EDUCATOR_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

//delete educator
export const deleteUser = (user_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.delete(`/api/admin/educator/${user_id}`, config);
    if (res.data.status === true) {
      await dispatch({
        type: DELETE_USER,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: EDUCATOR_FAIL,
        payload: {
          message: res.data.message,
          alertType: "danger",
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: EDUCATOR_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};
