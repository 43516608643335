import mockEventImg from "assets/images/mock-event.jpg";
import NestedPill from "components/common/NestedPill";
import Pill from "components/common/Pill";
import { NOTIFICATION_EVENT, SESSION_TYPE } from "constants";
import moment from "moment";
import { connect } from "react-redux";
import { formatDateShort } from "utils/formatDate";
import getUserAvatar from "utils/getUserAvatar";
import { ReactComponent as HomeLineIcon } from "assets/images/icons/home-line.svg";
import { ReactComponent as ArrowRightIcon } from "assets/images/icons/arrow-right.svg";
import { ReactComponent as HourglassIcon } from "assets/images/icons/hourglass.svg";
import { ReactComponent as XIcon } from "assets/images/icons/x.svg";
import { ReactComponent as CheckCircleIcon } from "assets/images/icons/check-circle-small.svg";
import { ReactComponent as ClockStopwatchIcon } from "assets/images/icons/clock-stopwatch.svg";
import { Link } from "react-router-dom";

export default function NotificationEventItem({ event }) {
  switch (event.type) {
    case NOTIFICATION_EVENT.EVENT_CREATE:
    case NOTIFICATION_EVENT.BLOG_CREATE:
      return (
        <div className="flex gap-3 py-4">
          <div className="shrink-0">
            <img
              src={
                event.mentor_avatar
                  ? event.mentor_avatar
                  : getUserAvatar(event.mentor_avatar)
              }
              alt=""
              className="rounded-full w-12 h-12 border-2 border-primary-100"
            />
          </div>
          <div className="flex-1">
            <div>
              <div className="flex items-center gap-2">
                <div className="text-sm font-medium">{event.mentor_name}</div>
                <div className="text-xs text-dgray-500">
                  {moment(event.created_at).fromNow()}
                </div>
              </div>
              <div className="text-sm text-dgray-500 mb-4">
                Added a new{" "}
                {event.type === NOTIFICATION_EVENT.EVENT_CREATE
                  ? "event"
                  : "blog"}{" "}
                <span className="text-primary-700 font-medium">
                  {event.data.name}
                </span>
              </div>
              <FeedEventCard event={event} />
            </div>
          </div>
        </div>
      );
    case NOTIFICATION_EVENT.WELCOME:
      return (
        <div className="py-4">
          <div className="flex gap-3 text-sm rounded-lg bg-primary-25 border border-primary-300 p-4">
            <HomeLineIcon className="text-primary-600 shrink-0" />
            <div className="text-primary-700">
              <div className="mb-1 font-medium">Welcome to DDN</div>
              <p className="text-primary-600 mb-3">
                Welcome to DDN. Your email has been verified and you are
                registered on DDN. We hope you enjoy your time here.
              </p>
              <div className="font-medium flex gap-4">
                <Link to="/dashboard" className="flex items-center gap-2">
                  Go to My DDN <ArrowRightIcon />
                </Link>
                <Link to="/explore" className="flex items-center gap-2">
                  Browse Events <ArrowRightIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    case NOTIFICATION_EVENT.HOST_APPLICATION_RECEIVED:
      return (
        <div className="py-4">
          <div className="flex gap-3 text-sm rounded-lg bg-dgray-25 border border-dgray-300 p-4">
            <HourglassIcon className="text-dgray-600 shrink-0" />
            <div className="text-dgray-700">
              <div className="mb-1 font-medium">
                Your application to become a host has been received
              </div>
              <p className="text-dgray-600">
                Your application to become a host was received and is under
                review. We will get back to you after reviewing your
                application.
              </p>
            </div>
          </div>
        </div>
      );
    case NOTIFICATION_EVENT.HOST_APPLICATION_APPROVED:
      return (
        <div className="py-4">
          <div className="flex gap-3 text-sm rounded-lg bg-green-25 border border-green-300 p-4">
            <CheckCircleIcon className="text-green-600 shrink-0" />
            <div className="text-green-700">
              <div className="mb-1 font-medium">
                Your application to become a host has been approved
              </div>
              <p className="text-green-600 mb-3">
                Congratulations! Your application to become a host has been
                approved! You can now offer sessions, host public events and
                interact with others. Go to My DDN to get started.
              </p>
              <div className="font-medium flex gap-4">
                <Link to="/dashboard" className="flex items-center gap-2">
                  Go to My DDN <ArrowRightIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    case NOTIFICATION_EVENT.HOST_APPLICATION_REJECTED:
      return (
        <div className="py-4">
          <div className="flex gap-3 text-sm rounded-lg bg-red-25 border border-red-300 p-4">
            <XIcon className="text-red-600 shrink-0" />
            <div className="text-red-700">
              <div className="mb-1 font-medium">
                Your application to become a host has been rejected
              </div>
              <p className="text-red-600">
                Your application to become a host has been unfortunately
                rejected. We will email you with more information related to our
                decision.
              </p>
            </div>
          </div>
        </div>
      );
    case NOTIFICATION_EVENT.UPCOMING_EVENT_HOST:
      return (
        <div className="py-4">
          <div className="flex gap-3 text-sm rounded-lg bg-dgray-25 border border-dgray-300 p-4">
            <ClockStopwatchIcon className="text-dgray-600 shrink-0" />
            <div className="text-dgray-700">
              <div className="mb-1 font-medium">Upcoming event</div>
              <p className="text-dgray-600">
                You have an event starting in{" "}
                {moment(event.data.startAt).toNow(true)}.{" "}
                {event.data.signedUpCount} users have signed up for your event.
                You can initiate the session 5 mins before the start of the
                event. Please go to My DDN to see more information about your
                event.
              </p>
              <Link
                to="/dashboard"
                className="flex items-center gap-2 text-primary-600 font-medium my-3"
              >
                Go to My DDN <ArrowRightIcon />
              </Link>
              <FeedEventCard event={event} />
            </div>
          </div>
        </div>
      );
    case NOTIFICATION_EVENT.UPCOMING_EVENT_STUDENT:
      return (
        <div className="py-4">
          <div className="flex gap-3 text-sm rounded-lg bg-dgray-25 border border-dgray-300 p-4">
            <ClockStopwatchIcon className="text-dgray-600 shrink-0" />
            <div className="text-dgray-700">
              <div className="mb-1 font-medium">Upcoming event</div>
              <p className="text-dgray-600">
                {moment(event.data.startAt).toNow(true)}. Please be sure to join
                the session on time. Your host will initiate the session 5 mins
                before the start of the event. If you want to cancel the event,
                please go to My DDN.
              </p>
              <Link
                to={`/session/${event.data.id}`}
                className="flex items-center gap-2 text-primary-600 font-medium my-3"
              >
                Go to event <ArrowRightIcon />
              </Link>
              <FeedEventCard event={event} />
            </div>
          </div>
        </div>
      );
    default:
      return "";
  }
}

const FeedEventCard = connect((state) => ({
  categories: state.categories.data,
}))(({ event, categories }) => {
  return (
    <div className="rounded-xl border border-dgray-200 flex gap-3 p-4">
      <img
        className="w-36 min-h-full object-cover rounded"
        src={
          event.course_image
            ? `${process.env.REACT_APP_MEDIA_URL}${event.course_image}`
            : mockEventImg
        }
        alt=""
      />
      <div className="flex-1">
        <div className="flex justify-between items-center">
          <div className="font-semibold">{event.data.name}</div>
          {event.type === NOTIFICATION_EVENT.EVENT_CREATE && (
            <NestedPill
              innerText={
                event.data.course_type === SESSION_TYPE.LIVE_EVENT
                  ? "Public event"
                  : "Private event"
              }
            />
          )}
        </div>
        <div className="mb-3">
          <Pill
            color="blue"
            text={
              categories?.find((cat) => cat._id === event.data.category)?.name
            }
          />
        </div>
        {event.data.description ? (
          <p className="text-sm text-dgray-500 line-clamp-2">
            {event.data.description}
          </p>
        ) : (
          <div className="text-sm">
            <div className="font-semibold">
              {formatDateShort(event.data.date)}
            </div>
            <div className="text-dgray-500">{event.data.timezone}</div>
          </div>
        )}
      </div>
    </div>
  );
});
