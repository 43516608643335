import Spinner from "views/Spinner";
import JoinCTA from "components/common/JoinCTA";
import { Suspense } from "react";
import Header from "../../Layout/Header";
import HomePageEventTypes from "./EventTypes";
import HomePageExplained from "./Explained";
import HomePageExplore from "./Explore";
import HomePageHero from "./Hero";
import HomePageHosts from "./Hosts";
import HomePageHowItWorks from "./HowItWorks";
import HomePageTestimonials from "./Testimonials";

const HomepageNotSignedIn = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Header className="bg-primary-50" />
      <HomePageHero />
      <HomePageExplained />
      <HomePageExplore />
      <HomePageEventTypes />
      <HomePageHosts />
      <HomePageTestimonials />
      <HomePageHowItWorks />
      <JoinCTA />
    </Suspense>
  );
};

export default HomepageNotSignedIn;
