// import React from 'react';
import CourseManagement from "views/Front/ProfileCourseMgmt/CourseManagement/CourseManagement";
import Dashboard from "views/Front/ProfileCourseMgmt/Dashboard/Dashboard";
import ChangePassword from "views/Front/ProfileCourseMgmt/ChangePassword";
import PaymentHistory from "views/Front/ProfileCourseMgmt/PaymentHistory";
import MyCourses from "views/Front/ProfileCourseMgmt/MyCourses/MyCourses";
import AddCourse from "views/Front/Courses/AddCourse/AddCourse";
import Notification from "views/Front/Notification/Notification";
import NewSessionOrEvent from "views/Front/ProfileCourseMgmt/MyCourses/NewSessionOrEvent";
import BlogManagement from "views/Front/ProfileCourseMgmt/BlogManagement/BlogManagement";
import LiveEvent from "views/Front/ProfileCourseMgmt/MyCourses/LiveEvent";
import EditLiveEvent from "views/Front/Courses/AddCourse/EditLiveEvent";
import DashboardMyEvents from "views/Front/ProfileCourseMgmt/Dashboard/MyEvents";
import DashboardGetPaid from "views/Front/ProfileCourseMgmt/Dashboard/GetPaid";
import DashboardBalance from "views/Front/ProfileCourseMgmt/Dashboard/Balance";
import Myaccount from "views/Front/ProfileCourseMgmt/Myaccount";
import DashboardLearningJourney from "views/Front/ProfileCourseMgmt/Dashboard/LearningJourney";
import Calendar from "views/Front/ProfileCourseMgmt/Dashboard/MyCalendar";

const ProfileCourseRoutes = [
  {
    path: "/dashboard/calendar",
    exact: true,
    name: "My Calendar",
    component: Calendar,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/dashboard/changepassword",
    exact: true,
    name: "Change Password",
    component: ChangePassword,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/dashboard/events",
    exact: true,
    name: "My Events & Sessions",
    component: DashboardMyEvents,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/dashboard/session-management",
    exact: true,
    name: "Course Management",
    component: CourseManagement,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/dashboard/blogs",
    exact: true,
    name: "Blogs",
    component: BlogManagement,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/dashboard/new",
    exact: true,
    name: "New Session Or Event",
    component: NewSessionOrEvent,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },

  {
    path: "/dashboard/payment-and-metrics",
    exact: true,
    name: "Learner Payment History",
    component: PaymentHistory,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/dashboard/notifications",
    exact: true,
    name: "Notifications",
    component: Notification,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/dashboard/get-paid",
    exact: true,
    name: "Get Paid",
    component: DashboardGetPaid,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/dashboard/journey",
    exact: true,
    name: "Get Paid",
    component: DashboardLearningJourney,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/dashboard/balance",
    exact: true,
    name: "My Balance",
    component: DashboardBalance,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/dashboard/live-event",
    exact: true,
    name: "Live Event",
    component: LiveEvent,
    isprivate: true,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/dashboard/edit-live-event/:course_id",
    exact: true,
    name: "Edit Live Event",
    component: EditLiveEvent,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
];

export default ProfileCourseRoutes;
