import React from "react";
// import PropTypes from 'prop-types';

const Dashboard = (props) => {
  return <div>{ <h3>Welcome to DingDingNow Admin Panel</h3> }</div>;
};

// Dashboard.propTypes = {};

export default Dashboard;
