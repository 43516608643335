import CenterContainer from "../Container/Center";

export default function CreateHero({ top, title, bottom }) {
  return (
    <CenterContainer className="text-center py-24 lg:pt-16 lg:pb-12">
      <div className="text-primary-600 lg:text-sm font-semibold mb-3">
        {top}
      </div>
      <h1 className="text-5xl lg:text-3xl font-semibold mb-4">{title}</h1>
      <p className="text-xl lg:text-lg text-dgray-500">{bottom}</p>
    </CenterContainer>
  );
}
