import {
  COURSE_LOADING,
  COURSE_LIST_UPDATED,
  COURSE_FAIL,
  COURSE_CHANGE_STATUS,
  COURSE_CHANGE_STATUS_ERROR,
} from "actions/types";

const initalState = {
  coursesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentUser: null,
  loading: false,
  error: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case COURSE_LOADING:
      return {
        ...state,
        coursesList: payload.is_sub_element
          ? state.coursesList
          : initalState.coursesList,
        currentUser: payload.is_sub_element
          ? state.currentUser
          : initalState.currentUser,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case COURSE_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case COURSE_LIST_UPDATED:
      return {
        ...state,
        coursesList: {
          data: payload.data.data,
          page: payload.data.metadata[0].current_page,
          count: payload.data.metadata[0].totalRecord,
        },
        loading: false,
        isSubLoading: false,
      };

    case COURSE_CHANGE_STATUS:
      return {
        ...state,
        coursesList: {
          ...state.coursesList,
          data: state.coursesList.data.map((course) =>
            course._id === payload.data._id
              ? { ...course, status: payload.data.status }
              : course
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case COURSE_CHANGE_STATUS_ERROR:
      return {
        ...state,
        coursesList: {
          ...state.coursesList,
          data: state.coursesList.data.map((course) =>
            course._id === payload.data
              ? { ...course, is_failed: true }
              : { ...course, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    default:
      return state;
  }
}
