export function CreateFormRow({ title, description, right, bottom, disabled }) {
  return (
    <div className="border-b border-dgray-200 pb-5 mb-5">
      <div className="grid grid-cols-7 xl:grid-cols-5 gap-16 lg:gap-8 md:gap-5">
        <div className="col-span-2 lg:col-span-5 text-sm">
          <div className="text-dgray-700 font-medium">{title} {disabled && <><br/><br/><span className="text-primary-600 mt-2">Since the event has been RSVP'd, you can not change the time. Try cancelling and creating a new one.</span></>}</div>
          <p className="text-dgray-500">{description}</p>
        </div>
        <div className="col-span-3 md:col-span-5">{right}</div>
      </div>
      {bottom}
    </div>
  );
}
