import React from "react";
import { useDispatch } from "react-redux";
import Errors from "views/Notifications/Errors";
import { REMOVE_ERRORS, SET_FRONT_ERRORS } from "actions/types";

import { RECURRING_TYPE_REVERSE } from "constants/index";
import SessionDateTime from "./SessionDateTime";
import SessionRecurringData from "./SessionRecurringData";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as moment from 'moment-timezone';
import { getCurrentTimeZone } from "utils/time";

const SessionGroupInformation = ({
  session_group,
  INPUT_TYPE,
  auth: { user },
  sessionGenericFormData,
  setSessionGenericFormData,
  isConfirmation,
  onGenericChange,
  current_date,
  defaultGenericDateTimingInput,
  defaultGenericSessionGroup,
  isForEdit,
  setSessionErrorMsg,
}) => {
  /*********************Use Dispatch to set state ************************/
  const dispatch = useDispatch();

  let timeZone = getCurrentTimeZone(user);

  /*********************add new session ************************/
  const handleAddSessionChild = (e) => {
    e.preventDefault();
    let isAllAdded = true;
    session_group.map((singleSession) => {
      if (
        singleSession.sessions[0].start_date === "" ||
        singleSession.sessions[0].start_time === "" ||
        singleSession.sessions[0].end_date === "" ||
        singleSession.sessions[0].end_time === "" ||
        (singleSession.recurring_type != RECURRING_TYPE_REVERSE.NONE &&
          singleSession.recurring_end_date === "")
      )
        isAllAdded = false;
      return isAllAdded;
    });
    if (isAllAdded) {
      setSessionGenericFormData({
        ...sessionGenericFormData,
        session_group: [...session_group, defaultGenericSessionGroup],
      });
      dispatch({ type: REMOVE_ERRORS });
      setSessionErrorMsg("");
    } else {
      let errorsList = [];
      errorsList["generic_start_date"] =
        "Please fill valid values for above session(s).";
      dispatch({
        type: SET_FRONT_ERRORS,
        payload: {
          alertType: "danger",
          errorsList,
        },
      });
    }
  };

  /*********************remove session ************************/
  const handleSessionRemove = (e, index) => {
    e.preventDefault();
    if (window.confirm(`Are you sure you want to remove this session?`)) {
      let newSession = [...session_group];
      newSession.splice(index, 1);
      setSessionGenericFormData({
        ...sessionGenericFormData,
        session_group: newSession,
      });
      dispatch({ type: REMOVE_ERRORS });
      setSessionErrorMsg("");
    }
  };

  return (
    <div className="col-md-12">
      {/*********** all session group information start**************/}
      <small className="text-muted">Your timezone is <b>{moment.tz(timeZone).format('z')}</b>. To change your timezone, please edit your profile in MyDDN</small>

      {session_group.map((singleSession, index) => (
        <>
          <SessionDateTime
            key={`session-datetime-${index}`}
            singleSession={singleSession.sessions[0]}
            onGenericChange={onGenericChange}
            session_group={session_group}
            INPUT_TYPE={INPUT_TYPE}
            isConfirmation={isConfirmation}
            handleSessionRemove={handleSessionRemove}
            index={index}
            current_date={current_date}
            is_editable={singleSession.is_editable}
            isForEdit={isForEdit}
            is_recurring_editable={singleSession.is_recurring_editable}
          />
          <SessionRecurringData
            key={`session-data-${index}`}
            singleSession={singleSession}
            index={index}
            onGenericChange={onGenericChange}
            INPUT_TYPE={INPUT_TYPE}
            isConfirmation={isConfirmation}
            is_editable={singleSession.is_editable}
            is_recurring_editable={singleSession.is_recurring_editable}
          />
        </>
      ))}
      {/*********** all session group information end**************/}

      {/*********** all errors in session group start**************/}

      {!isConfirmation && (
        <>
          <Errors current_key="generic_start_date" key="generic_start_date" />
          <Errors current_key="generic_start_time" key="generic_start_time" />
        </>
      )}
      {/*********** all errors in session group end**************/}
      {/*********** add new session group start**************/}

      {!isConfirmation && (
        <div className="row">
          <div className="col-md-4">
            <button
              className="add-session"
              onClick={(e) => handleAddSessionChild(e)}
            >
              <i className="fa fa-plus" />
              Add additional availability
            </button>
          </div>
        </div>
      )}
      {/*********** add new session group end**************/}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  {}
)(withRouter(SessionGroupInformation));
