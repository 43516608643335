import StepCircle from "./StepCircle";

export default function FormSteps({ items, currentStep, className = "" }) {
  return (
    <div className={`flex justify-between md:flex-col ${className}`}>
      {items.map((item, i) => (
        <StepNode
          {...item}
          disabled={currentStep - 1 < i}
          completed={currentStep - 1 > i}
          isFirst={i === 0}
          isLast={i === items.length - 1}
          key={i}
        />
      ))}
    </div>
  );
}

function StepNode({ title, subtitle, completed, disabled, isFirst, isLast }) {
  return (
    <div className="text-xs flex-1 md:flex md:gap-3">
      <div className="flex items-center md:flex-col mb-3">
        <StepLine
          className={isFirst ? `bg-transparent` : ""}
          disabled={disabled}
        />
        <StepCircle
          className="mx-auto"
          disabled={disabled}
          completed={completed}
        />
        <StepLine
          className={`${isLast ? `bg-transparent` : ""}
          ${disabled || completed ? "" : "bg-dgray-200"}`}
          disabled={disabled}
        />
      </div>
      <div className="text-center md:text-left md:mt-3">
        <div
          className={`font-medium
          ${disabled || completed ? "text-dgray-700" : "text-primary-700"}`}
        >
          {title}
        </div>
        <div
          className={`${
            disabled || completed ? "text-dgray-500" : "text-primary-600"
          }
          ${completed ? "md:hidden" : ""}`}
        >
          {subtitle}
        </div>
      </div>
    </div>
  );
}

function StepLine({ disabled, className }) {
  return (
    <div
      className={`h-0.5 md:h-4 w-full md:w-0.5 -my-1.5
      ${disabled ? "bg-dgray-200 -mx-1" : "bg-primary-600"} ${className}`}
    />
  );
}
