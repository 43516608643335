import { resetLink } from "actions/auth";
import arrowLeft from "assets/images/icons/arrow-left.svg";
import { ReactComponent as KeyIcon } from "assets/images/icons/key.svg";
import Button from "components/common/form/Button";
import TextInput from "components/common/form/TextInput";
import forgotPasswordSchema from "utils/schemas/forgotPassword";
import IconCircle from "views/Front/HomePage/IconCircle";
import Header from "views/Front/Layout/Header";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SuccessScreen from "../SuccessScreen";

const ForgotPassword = ({ resetLink }) => {
  const [email, setEmail] = useState("");
  const [isSuccess, setSuccess] = useState(false);

  return (
    <>
      <Header dark />
      {isSuccess ? (
        <SuccessScreen
          title="Check your email"
          description="We sent a password reset link to your given email address."
        >
          <div className="text-sm text-center text-dgray-500">
            Didn’t receive the email?{" "}
            <button
              className="text-primary-700 font-medium"
              onClick={async () => {
                await resetLink({ email });
              }}
            >
              Click to resend
            </button>
          </div>
        </SuccessScreen>
      ) : (
        <div className="flex flex-col items-center my-24 max-w-sm mx-auto">
          <IconCircle large>
            <KeyIcon />
          </IconCircle>
          <div className="text-center mt-5">
            <div className="text-3xl md:text-2xl text-dgray-900 font-semibold mb-3">
              Forgot password?
            </div>
            <div className="text-dgray-500">
              No worries! We will send you reset instructions.
            </div>
          </div>
          <div className="mt-8 w-full">
            <Formik
              initialValues={{ email: "" }}
              validationSchema={forgotPasswordSchema}
              onSubmit={async ({ email }) => {
                setEmail(email);
                await resetLink({ email });
                setSuccess(true);
              }}
            >
              <Form>
                <TextInput
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="Enter your email"
                  className="w-full"
                />
                <Button
                  type="submit"
                  className="text-white bg-primary-600 w-full mt-6"
                  wFull
                >
                  Reset Password
                </Button>
              </Form>
            </Formik>
          </div>
          <div className="text-center flex flex-row text-dgray-500 text-sm mt-8 md:mt-8 xl:mt-10">
            <img src={arrowLeft} alt="Arrow left" className="mr-3" />
            <Link to="/login"> Back to log in </Link>
          </div>
        </div>
      )}
    </>
  );
};

ForgotPassword.propTypes = {
  resetLink: PropTypes.func.isRequired,
};

export default connect(() => ({}), {
  resetLink,
})(ForgotPassword);
