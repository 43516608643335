import React, { useState, useMemo, useRef } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import * as moment from 'moment-timezone';

import CourseInformation from "./CourseInformation";
import SessionInformation from "./Session/SessionInformation";
import CalendarInformation from "./Session/CalendarInformation";
import TabsHeader from "./TabsHeader";

import { RECURRING_TYPE_REVERSE, TABS, SESSION_TYPE } from "constants/index";
import { EDUCATOR_COURSE_CREATE_ERROR, REMOVE_ERRORS } from "actions/types";

import {
  createCourse,
  deleteDraftCourse,
  editDraftCourse,
  getDraftCourseById,
} from "actions/front/educator/courses";
import { Spinner } from "reactstrap";
import { getTimeZone } from "utils/time";

const EditDraftCourse = ({
  history,
  editDraftCourse,
  match,
  getDraftCourseById,
  user,
  deleteDraftCourse,
  createCourse
}) => {
  const dispatch = useDispatch();
  const wizardRef = useRef(null);

  /*********************current tab state************************/

  const [activeTab, setActiveTab] = useState(TABS.CONFIRMATION);

  /*********************error msg ************************/
  const [basicErrorMsg, setBasicErrorMsg] = useState("");
  const [sessionErrorMsg, setSessionErrorMsg] = useState("");

  /*********************loading************************/

  const [loading, setLoading] = useState(true);

  /*********************scroll to top when the tab is changed************************/
  useMemo(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);

  /*********************enable disable session tab************************/

  const [sessionEnable, setSessionEnable] = useState(false);
  /*********************enable disable calander and confirmation tab************************/

  const [
    calendarConfirmationTabEnable,
    setCalendarConfirmationTabEnable,
  ] = useState(false);

  useMemo(() => {
    if (!sessionEnable) {
      setCalendarConfirmationTabEnable(false);
    }
  }, [sessionEnable]);

  /*********************Information Tab Start Here************************/

  /*********************course basic information form fields ************************/
  const [courseInformationFormData, setcourseInformationFormData] = useState({
    category_id: "",
    sub_category_id: "",
    name: "",
    image: "",
    overview: "",
    ideal_for: "",
    course_information: "",
    category_type: "",
    is_individual: false,
    is_group: false,
    isNewFileSelected: 0,
    oldImage: "",
    is_preview: false,
  });

  /*********************Information Tab End Here************************/
  /*********************Session Tab Start Here************************/

  /*********************Type of session************************/

  const [typeOfSession, setTypeOfSession] = useState(false); //1: generic 1-1, 2: specific 1-1, 3: specific group, 4 specific both

  /*********************currnent date for default selection************************/
  let timeZone = getTimeZone(user);
  let current_date = moment.tz(timeZone).format("YYYY-MM-DD");
  let current_date_timestamp = moment.tz(timeZone).unix();
  // var curr = new Date();
  // var current_date = curr.toISOString().substr(0, 10);

  /*********************default object of date and time for specific and generic************************/
  const defaultSpecificIndividualDateTimingInput = {
    start_date: "",
    start_time: "00:00",
    is_new: true,
  };
  const defaultSpecificIndividualSessionGroup = {
    sessions: [{ ...defaultSpecificIndividualDateTimingInput }],
    recurring_type: RECURRING_TYPE_REVERSE.NONE,
    recurring_end_date: current_date,
    recurring_min_date: current_date,
    recurring_type_min: RECURRING_TYPE_REVERSE.NONE,
    is_new: true,
    all_session_with_recurring: [],
  };

  const defaultSpecificGroupDateTimingInput = {
    start_date: "",
    start_time: "00:00",
    is_new: true,
  };
  const defaultSpecificGroupSessionGroup = {
    sessions: [{ ...defaultSpecificGroupDateTimingInput }],
    recurring_type: RECURRING_TYPE_REVERSE.NONE,
    recurring_end_date: current_date,
    recurring_min_date: current_date,
    recurring_type_min: RECURRING_TYPE_REVERSE.NONE,
    is_new: true,
    all_session_with_recurring: [],
  };
  const defaultGenericDateTimingInput = {
    start_date: "",
    start_time: "00:00",
    min_end_date: current_date,
    max_end_date: "",
    max_end_time: "",
    min_end_time: "",
    end_date: "",
    end_time: "00:15",
    is_new: true,
  };
  const defaultGenericSessionGroup = {
    sessions: [{ ...defaultGenericDateTimingInput }],
    recurring_type: RECURRING_TYPE_REVERSE.NONE,
    recurring_end_date: current_date,
    recurring_min_date: current_date,
    recurring_type_min: RECURRING_TYPE_REVERSE.NONE,
    is_new: true,
    all_session_with_recurring: [],
  };

  /*********************initiall and usestate for session form data************************/
  const initiallSessionFormData = {
    specific_individual: {
      duration_per_session: "",
      price_per_session: "",
      number_of_session: 1,
      number_of_session_editable: true,
      can_add_new_bundle: true,
      session_group: [{ ...defaultSpecificIndividualSessionGroup }],
      all_session_with_recurring: [],
    },
    specific_group: {
      duration_per_session: "",
      price_per_session: "",
      max_students: "",
      number_of_session: 1,
      number_of_session_editable: true,
      session_group: [{ ...defaultSpecificGroupSessionGroup }],
      can_add_new_bundle: true,
      all_session_with_recurring: [],
    },
    generic: {
      duration_info: [{ duration_per_session: "", price_per_session: "" }],
      session_group: [{ ...defaultGenericSessionGroup }],
      all_session_with_recurring: [],
      // recurring_type: RECURRING_TYPE_REVERSE.NONE,
      // recurring_end_date: current_date,
    },
  };
  const [sessionFormData, setSessionFormData] = useState({
    ...initiallSessionFormData,
  });

  /*********************get course data************************/
  useMemo(() => {
    if (match.params.course_id) {
      dispatch({ type: REMOVE_ERRORS });

      getDraftCourseById(match.params.course_id).then(async (res) => {
        if (res.status) {
          let course = res.data;
          setActiveTab(course.active_tab);
          setTypeOfSession(course.course_type);
          setcourseInformationFormData({
            ...courseInformationFormData,
            category_id: course.category_id,
            sub_category_id: course.sub_category_id,
            name: course.name,
            oldImage: !course.image ? "" : course.image,
            image: !course.image
              ? ""
              : process.env.REACT_APP_MEDIA_URL + course.image,
            is_preview: !course.image ? false : true,
            overview: course.overview,
            ideal_for: course.ideal_for,
            course_information: course.course_information,
            category_type: course.category_type,
            is_individual:
              course.course_type == SESSION_TYPE.SPECIFIC_INDIVIDUAL ||
                course.course_type == SESSION_TYPE.SPECIFIC_BOTH
                ? true
                : false,
            is_edit_individual:
              course.course_type == SESSION_TYPE.SPECIFIC_INDIVIDUAL ||
                course.course_type == SESSION_TYPE.SPECIFIC_BOTH
                ? false
                : true,
            is_group:
              course.course_type == SESSION_TYPE.SPECIFIC_GROUP ||
                course.course_type == SESSION_TYPE.SPECIFIC_BOTH
                ? true
                : false,
            is_edit_group:
              course.course_type == SESSION_TYPE.SPECIFIC_GROUP ||
                course.course_type == SESSION_TYPE.SPECIFIC_BOTH
                ? false
                : true,
          });
          let editSessionData = { ...initiallSessionFormData };

          let timeZone = getTimeZone(user);

          let promise = await course.session_detail.map(
            async (singleSessionDetail) => {
              if (singleSessionDetail.detail_for == 1) {
                let editFullSession = [];

                let singleSessionDetailPromise = await singleSessionDetail.session_group.map(
                  async (singleSessionGroup) => {
                    let editSessionTimings = [];
                    let singleSessionGroupPromise = await singleSessionGroup.sessions.map(
                      (singleSession) => {
                        let min_start_date = moment();
                        let current_start_date = moment
                          .unix(singleSession.start_at)
                          .tz(timeZone);
                        if (current_start_date.unix() < min_start_date.unix())
                          min_start_date = current_start_date;
                        editSessionTimings.push({
                          start_date: moment
                            .unix(singleSession.start_at)
                            .tz(timeZone)
                            .format("YYYY-MM-DD"),
                          min_start_date: moment(min_start_date).format(
                            "YYYY-MM-DD"
                          ),

                          min_end_date: moment
                            .unix(singleSession.start_at)
                            .tz(timeZone)
                            .format("YYYY-MM-DD"),
                          start_time: moment
                            .unix(singleSession.start_at)
                            .tz(timeZone)
                            .format("HH:mm"),
                          end_date: moment
                            .unix(singleSession.end_at)
                            .tz(timeZone)
                            .format("YYYY-MM-DD"),

                          end_time: moment
                            .unix(singleSession.end_at)
                            .tz(timeZone)
                            .format("HH:mm"),
                          _id: singleSession._id,
                        });
                      }
                    );
                    await Promise.all(singleSessionGroupPromise);

                    let recurring_min_date;
                    let is_recurring_editable = true;
                    let recurring_type_min = RECURRING_TYPE_REVERSE.NONE;
                    if (singleSessionGroup.sessions[0]) {
                      //get the min recurring date/ min end date/ recurring type min
                      recurring_min_date = moment
                        .tz(
                          moment
                            .unix(singleSessionGroup.sessions[0].end_at)
                            .tz(timeZone)
                            .add(1, "days")
                            .format("YYYY-MM-DD"),
                          timeZone
                        )
                        .format("YYYY-MM-DD");
                      //change the recurring min end date to all_session_with_recurring max end date which have session_booking_count > 0
                      //or check it at submit time

                      // let start_date_value = new Date(
                      //   singleSessionGroup.sessions[0].start_at
                      // );
                      // let calculated_end_date_for_week = new Date(
                      //   singleSessionGroup.sessions[0].start_at
                      // );
                      // calculated_end_date_for_week.setDate(
                      //   calculated_end_date_for_week.getDate() + 7
                      // );
                      // let end_date_value = new Date(
                      //   singleSessionGroup.sessions[0].end_at
                      // );
                      let start_date_value = moment(
                        moment
                          .unix(singleSessionGroup.sessions[0].start_at)
                          .format("YYYY-MM-DD")
                      );
                      let calculated_end_date_for_week = moment(
                        moment
                          .unix(singleSessionGroup.sessions[0].start_at)
                          .clone()
                          .add(7, "days")
                          .format("YYYY-MM-DD")
                      );

                      let end_date_value = moment(
                        moment
                          .unix(singleSessionGroup.sessions[0].end_at)
                          .format("YYYY-MM-DD")
                      );
                      if (start_date_value.unix() == end_date_value.unix())
                        recurring_type_min = RECURRING_TYPE_REVERSE.DAILY;
                      else if (
                        calculated_end_date_for_week.unix() >
                        end_date_value.unix()
                      )
                        recurring_type_min = RECURRING_TYPE_REVERSE.WEEKLY;
                      else recurring_type_min = RECURRING_TYPE_REVERSE.MONTHLY;
                      //end---- get the min recurring date/ min end date/ recurring type min

                      //check if the recurring date can be changed or not

                      if (
                        singleSessionGroup.recurring_type !=
                        RECURRING_TYPE_REVERSE.NONE
                      ) {
                        let existing_recuring_end_date = moment
                          .unix(singleSessionGroup.recurring_end_date)
                          .tz(timeZone);
                        let currentDate = moment();
                        if (
                          existing_recuring_end_date.unix() < currentDate.unix()
                        ) {
                          is_recurring_editable = false;
                        }
                      }
                      //end---check if the recurring date can be changed or not
                    }

                    editFullSession.push({
                      _id: singleSessionGroup._id,
                      sessions: editSessionTimings.length > 0 ? [...editSessionTimings] : [{ ...defaultGenericDateTimingInput }],
                      recurring_type: singleSessionGroup.recurring_type,
                      old_recurring_end_date: moment
                        .unix(singleSessionGroup.recurring_end_date ? singleSessionGroup.recurring_end_date : current_date)
                        .tz(timeZone)
                        .format("YYYY-MM-DD"),
                      recurring_end_date: moment
                        .unix(singleSessionGroup.recurring_end_date ? singleSessionGroup.recurring_end_date : current_date)
                        .tz(timeZone)
                        .format("YYYY-MM-DD"),
                      recurring_min_date: recurring_min_date !== "" ? recurring_min_date : current_date,
                      recurring_type_min: recurring_type_min,
                      is_recurring_editable: is_recurring_editable,
                      is_booking_full: singleSessionGroup.is_booking_full,
                      is_booked: singleSessionGroup.is_booked,
                      is_editable: !singleSessionGroup.is_booked,
                      all_session_with_recurring: [
                        ...singleSessionGroup.all_session_with_recurring,
                      ],
                    });
                  }
                );
                await Promise.all(singleSessionDetailPromise);
                if (editFullSession && editFullSession.length > 0) {
                  editSessionData.generic = {
                    duration_info: singleSessionDetail.duration_info,
                    session_group: [...editFullSession],
                    all_session_with_recurring: [],
                  };
                }
                else {
                  editSessionData.generic = {
                    ...initiallSessionFormData.generic,
                    duration_info: singleSessionDetail.duration_info ? singleSessionDetail.duration_info : initiallSessionFormData.generic.duration_info,
                  }
                }

              }
              if (singleSessionDetail.detail_for == 2) {
                let editFullSession = [];
                let isNoOfSessionEditable = true;

                let singleSessionGroupPromise = await singleSessionDetail.session_group.map(
                  async (singleSessionGroup) => {
                    if (singleSessionGroup.is_booked) {
                      isNoOfSessionEditable = false;
                    }
                    let editSessionTimings = [];
                    let minDate = "";
                    let maxDate = "";
                    let singleSessionPromise = await singleSessionGroup.sessions.map(
                      (singleSession) => {
                        let min_start_date = moment();
                        let current_start_date = moment
                          .unix(singleSession.start_at)
                          .tz(timeZone);
                        if (current_start_date.unix() < min_start_date.unix())
                          min_start_date = current_start_date;
                        editSessionTimings.push({
                          start_date: moment
                            .unix(singleSession.start_at)
                            .tz(timeZone)
                            .format("YYYY-MM-DD"),
                          min_start_date: moment(min_start_date).format(
                            "YYYY-MM-DD"
                          ),

                          start_time: moment
                            .unix(singleSession.start_at)
                            .tz(timeZone)
                            .format("HH:mm"),

                          _id: singleSession._id,
                        });

                        if (
                          minDate == "" ||
                          minDate.unix() > current_start_date.unix()
                        ) {
                          minDate = current_start_date.clone();
                        }
                        if (
                          maxDate == "" ||
                          maxDate.unix() < current_start_date.unix()
                        ) {
                          maxDate = current_start_date.clone();
                        }
                      }
                    );
                    await Promise.all(singleSessionPromise);

                    let recurring_min_date = "";
                    let recurring_type_min = RECURRING_TYPE_REVERSE.NONE;
                    let is_recurring_editable = true;

                    if (minDate && maxDate) {
                      //get the min recurring date/ min end date/ recurring type min
                      recurring_min_date = moment(
                        moment(maxDate).add(1, "days").format("YYYY-MM-DD")
                      ).format("YYYY-MM-DD");

                      let calculated_end_date_for_week = moment(
                        minDate.clone().add(7, "days").format("YYYY-MM-DD")
                      );
                      let calculatedMinDate = moment(
                        minDate.clone().format("YYYY-MM-DD")
                      );
                      let calculatedMaxDate = moment(
                        maxDate.clone().format("YYYY-MM-DD")
                      );

                      if (calculatedMinDate.unix() == calculatedMaxDate.unix())
                        recurring_type_min = RECURRING_TYPE_REVERSE.DAILY;
                      else if (
                        calculatedMaxDate.unix() <
                        calculated_end_date_for_week.unix()
                      )
                        recurring_type_min = RECURRING_TYPE_REVERSE.WEEKLY;
                      else recurring_type_min = RECURRING_TYPE_REVERSE.MONTHLY;

                      //end-------for session recurring type minimum

                      //check if the recurring date can be changed or not

                      if (
                        singleSessionGroup.recurring_type !=
                        RECURRING_TYPE_REVERSE.NONE
                      ) {
                        let existing_recuring_end_date = moment
                          .unix(singleSessionGroup.recurring_end_date)
                          .tz(timeZone);
                        let currentDate = moment();
                        if (
                          existing_recuring_end_date.unix() < currentDate.unix()
                        ) {
                          is_recurring_editable = false;
                        }
                      }
                      //end---check if the recurring date can be changed or not
                    }
                    editFullSession.push({
                      _id: singleSessionGroup._id,
                      sessions: editSessionTimings.length > 0 ? [...editSessionTimings] : [{ ...defaultSpecificIndividualDateTimingInput }],
                      recurring_type: singleSessionGroup.recurring_type,
                      old_recurring_end_date: moment
                        .unix(singleSessionGroup.recurring_end_date ? singleSessionGroup.recurring_end_date : current_date_timestamp)
                        .tz(timeZone)
                        .format("YYYY-MM-DD"),
                      recurring_end_date: moment
                        .unix(singleSessionGroup.recurring_end_date ? singleSessionGroup.recurring_end_date : current_date_timestamp)
                        .tz(timeZone)
                        .format("YYYY-MM-DD"),
                      recurring_min_date: recurring_min_date !== "" ? recurring_min_date : current_date,
                      recurring_type_min: recurring_type_min,
                      is_recurring_editable: is_recurring_editable,
                      is_booking_full: singleSessionGroup.is_booking_full,
                      is_booked: singleSessionGroup.is_booked,
                      is_editable: !singleSessionGroup.is_booked,
                      all_session_with_recurring: [
                        ...singleSessionGroup.all_session_with_recurring,
                      ],
                    });
                  }
                );
                await Promise.all(singleSessionGroupPromise);
                if (editFullSession && editFullSession.length > 0) {
                  editSessionData.specific_individual = {
                    duration_per_session:
                      singleSessionDetail.duration_info[0].duration_per_session,
                    price_per_session:
                      singleSessionDetail.duration_info[0].price_per_session,
                    // max_students: singleSessionDetail.max_students,
                    can_add_new_bundle: true,
                    number_of_session: singleSessionDetail.number_of_session,
                    number_of_session_editable: isNoOfSessionEditable,
                    session_group: [...editFullSession],
                    all_session_with_recurring: [],
                  };
                }
                else {
                  editSessionData.specific_individual = {
                    ...initiallSessionFormData.specific_individual,
                    duration_per_session: singleSessionDetail.duration_info[0].duration_per_session ?
                      singleSessionDetail.duration_info[0].duration_per_session : initiallSessionFormData.specific_individual.duration_per_session,
                    price_per_session: singleSessionDetail.duration_info[0].price_per_session ?
                      singleSessionDetail.duration_info[0].price_per_session : initiallSessionFormData.specific_individual.price_per_session,
                    number_of_session: singleSessionDetail.number_of_session ?
                      singleSessionDetail.number_of_session : initiallSessionFormData.specific_individual.number_of_session
                  }
                }
              }
              if (singleSessionDetail.detail_for == 3) {
                let editFullSession = [];
                let isNoOfSessionEditable = true;

                let singleSessionGroupPromise = await singleSessionDetail.session_group.map(
                  async (singleSessionGroup) => {
                    if (singleSessionGroup.is_booked) {
                      isNoOfSessionEditable = false;
                    }
                    let editSessionTimings = [];
                    let minDate = "";
                    let maxDate = "";
                    let singleSessionPromise = await singleSessionGroup.sessions.map(
                      (singleSession) => {
                        let min_start_date = moment();
                        let current_start_date = moment
                          .unix(singleSession.start_at)
                          .tz(timeZone);
                        if (current_start_date.unix() < min_start_date.unix())
                          min_start_date = current_start_date;
                        editSessionTimings.push({
                          start_date: moment
                            .unix(singleSession.start_at)
                            .tz(timeZone)
                            .format("YYYY-MM-DD"),
                          min_start_date: moment(min_start_date).format(
                            "YYYY-MM-DD"
                          ),

                          start_time: moment
                            .unix(singleSession.start_at)
                            .tz(timeZone)
                            .format("HH:mm"),

                          _id: singleSession._id,
                        });

                        if (
                          minDate == "" ||
                          minDate.unix() > current_start_date.unix()
                        ) {
                          minDate = current_start_date.clone();
                        }
                        if (
                          maxDate == "" ||
                          maxDate.unix() < current_start_date.unix()
                        ) {
                          maxDate = current_start_date.clone();
                        }
                      }
                    );
                    await Promise.all(singleSessionPromise);

                    let recurring_min_date;
                    let is_recurring_editable = true;
                    let recurring_type_min = RECURRING_TYPE_REVERSE.NONE;
                    if (minDate && maxDate) {
                      //get the min recurring date/ min end date/ recurring type min
                      recurring_min_date = moment(
                        moment(maxDate).add(1, "days").format("YYYY-MM-DD")
                      ).format("YYYY-MM-DD");

                      let calculated_end_date_for_week = moment(
                        minDate.clone().add(7, "days").format("YYYY-MM-DD")
                      );
                      let calculatedMinDate = moment(
                        minDate.clone().format("YYYY-MM-DD")
                      );
                      let calculatedMaxDate = moment(
                        maxDate.clone().format("YYYY-MM-DD")
                      );
                      if (calculatedMinDate.unix() == calculatedMaxDate.unix())
                        recurring_type_min = RECURRING_TYPE_REVERSE.DAILY;
                      else if (
                        calculatedMaxDate.unix() <
                        calculated_end_date_for_week.unix()
                      )
                        recurring_type_min = RECURRING_TYPE_REVERSE.WEEKLY;
                      else recurring_type_min = RECURRING_TYPE_REVERSE.MONTHLY;

                      //end-------for session recurring type minimum

                      //check if the recurring date can be changed or not


                      if (
                        singleSessionGroup.recurring_type !=
                        RECURRING_TYPE_REVERSE.NONE
                      ) {
                        let existing_recuring_end_date = moment
                          .unix(singleSessionGroup.recurring_end_date)
                          .tz(timeZone);
                        let currentDate = moment();
                        if (
                          existing_recuring_end_date.unix() < currentDate.unix()
                        ) {
                          is_recurring_editable = false;
                        }
                      }
                      //end---check if the recurring date can be changed or not
                    }
                    editFullSession.push({
                      _id: singleSessionGroup._id,
                      sessions: editSessionTimings.length > 0 ? [...editSessionTimings] : [{ ...defaultSpecificGroupDateTimingInput }],
                      recurring_type: singleSessionGroup.recurring_type,
                      old_recurring_end_date: moment
                        .unix(singleSessionGroup.recurring_end_date ? singleSessionGroup.recurring_end_date : current_date_timestamp)
                        .tz(timeZone)
                        .format("YYYY-MM-DD"),
                      recurring_end_date: moment
                        .unix(singleSessionGroup.recurring_end_date ? singleSessionGroup.recurring_end_date : current_date_timestamp)
                        .tz(timeZone)
                        .format("YYYY-MM-DD"),
                      recurring_min_date: recurring_min_date !== "" ? recurring_min_date : current_date,
                      recurring_type_min: recurring_type_min,
                      is_recurring_editable: is_recurring_editable,
                      is_booking_full: singleSessionGroup.is_booking_full,
                      is_booked: singleSessionGroup.is_booked,
                      is_editable: !singleSessionGroup.is_booked,
                      all_session_with_recurring: [
                        ...singleSessionGroup.all_session_with_recurring,
                      ],
                    });
                  }
                );
                await Promise.all(singleSessionGroupPromise);
                if (editFullSession && editFullSession.length > 0) {
                  editSessionData.specific_group = {
                    duration_per_session:
                      singleSessionDetail.duration_info[0].duration_per_session,
                    price_per_session:
                      singleSessionDetail.duration_info[0].price_per_session,
                    max_students: singleSessionDetail.max_students,
                    number_of_session: singleSessionDetail.number_of_session,
                    number_of_session_editable: isNoOfSessionEditable,
                    session_group: [...editFullSession],
                    all_session_with_recurring: [],
                  };
                }
                else {
                  editSessionData.specific_group = {
                    ...initiallSessionFormData.specific_group,
                    duration_per_session:
                      singleSessionDetail.duration_info[0].duration_per_session ?
                        singleSessionDetail.duration_info[0].duration_per_session : initiallSessionFormData.specific_group.duration_per_session,
                    price_per_session:
                      singleSessionDetail.duration_info[0].price_per_session ? singleSessionDetail.duration_info[0].price_per_session : initiallSessionFormData.specific_group.price_per_session,
                    max_students: singleSessionDetail.max_students ? singleSessionDetail.max_students : initiallSessionFormData.specific_group.max_students,
                    number_of_session: singleSessionDetail.number_of_session ? singleSessionDetail.number_of_session : initiallSessionFormData.specific_group.number_of_session
                  }
                }
              }
              return true;
            }
          );
          await Promise.all(promise);
          setSessionFormData(editSessionData);
          setSessionEnable(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
  }, [match.params.course_id]);

  /*********************reset session form data************************/
  const resetSessionFormData = () => {
    setSessionFormData({ ...initiallSessionFormData });
  };

  /*********************Session Tab End Here************************/
  /*********************on click of back button************************/

  const onClickBackNextButton = async (e, onTab) => {
    e.preventDefault();
    if (onTab == TABS.CALENDAR || onTab == TABS.CONFIRMATION) {
      let promise = await checkSessionValidation();
      let conflictStatus = false;
      await Promise.resolve(promise).then((value) => {
        conflictStatus = value;
      });
      if (conflictStatus) {
        setActiveTab(onTab);
      }
    } else {
      setActiveTab(onTab);
    }
  };

  /*********************submit loader ************************/
  const [submitLoading, setSubmitLoading] = useState(false);
  /*********************on submit of  course form************************/
  const onSubmitForm = async (e) => {
    setSubmitLoading(true);
    let promise = await checkSessionValidation();
    let conflictStatus = false;
    await Promise.resolve(promise).then((value) => {
      conflictStatus = value;
    });
    if (conflictStatus) {
      createCourse(
        courseInformationFormData,
        sessionFormData,
        typeOfSession,
        history,
        false
      ).then((res) => {
        if (!res.status) {
          if (res.isErrorInBasic) {
            setBasicErrorMsg(res.msg);
            setActiveTab(TABS.BASIC);
          } else {
            setSessionErrorMsg(res.msg);
            setActiveTab(TABS.SESSION);
          }
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
          deleteDraftCourse(match.params.course_id).then((response) => {
            history.push("/dashboard");
          });
        }
      });
    } else {
      setActiveTab(TABS.SESSION);
      setSubmitLoading(false);
    }
  };

  const onSubmitDraftForm = (e) => {
    setSubmitLoading(true);
    editDraftCourse(
      match.params.course_id,
      courseInformationFormData,
      sessionFormData,
      typeOfSession,
      history,
      activeTab
    ).then((res) => {
      if (!res.status) {
        if (res.isErrorInBasic) {
          setBasicErrorMsg(res.msg);
          setActiveTab(TABS.BASIC);
        } else {
          setActiveTab(TABS.SESSION);
          setSessionErrorMsg(res.msg);
        }
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    });
  };

  /*****check for session validation while click on next button of session tab or calabder tab click*******/
  const checkSessionValidation = async () => {
    let errorsList = [];
    let isError = false;
    let allSession = [];
    let hasRecurringError = false;
    if (
      typeOfSession === SESSION_TYPE.SPECIFIC_INDIVIDUAL ||
      typeOfSession === SESSION_TYPE.SPECIFIC_BOTH
    ) {
      let allDataPromise = getSpecificIndividualAllSessionDataAndErrorIfAny();
      await Promise.resolve(allDataPromise).then((res) => {
        let allData = res;
        allSession.push(...allData.allSession);
        errorsList = allData.errorsList;
        hasRecurringError = allData.hasRecurringError;
        setSessionFormData({
          ...sessionFormData,
          specific_individual: {
            ...sessionFormData.specific_individual,
            session_group: allData.recurrenceSessionGroup,
            all_session_with_recurring: [...allSession],
          },
        });
      });
    }

    if (
      typeOfSession === SESSION_TYPE.SPECIFIC_GROUP ||
      typeOfSession === SESSION_TYPE.SPECIFIC_BOTH
    ) {
      let allDataPromise = getSpecificGroupAllSessionDataAndErrorIfAny();
      await Promise.resolve(allDataPromise).then((res) => {
        let allData = res;
        allSession.push(...allData.allSession);
        errorsList = allData.errorsList;
        hasRecurringError = allData.hasRecurringError;
        setSessionFormData({
          ...sessionFormData,
          specific_group: {
            ...sessionFormData.specific_group,
            session_group: allData.recurrenceSessionGroup,
            all_session_with_recurring: [...allSession],
          },
        });
      });
    }

    if (typeOfSession === SESSION_TYPE.GENERIC) {
      let allDataPromise = getGenericAllSessionDataAndErrorIfAny();
      await Promise.resolve(allDataPromise).then((res) => {
        let allData = res;
        allSession.push(...allData.allSession);
        errorsList = allData.errorsList;
        hasRecurringError = allData.hasRecurringError;
        setSessionFormData({
          ...sessionFormData,
          generic: {
            ...sessionFormData.generic,
            session_group: allData.recurrenceSessionGroup,
            all_session_with_recurring: [...allSession],
          },
        });
      });
    }
    if (allSession.length > 1 && !hasRecurringError) {
      let sortedSession = await allSession.sort(
        (a, b) => a.start_at - b.start_at
      );
      let checkValidationPromise = await sortedSession.map(
        async (session, idx) => {
          var remainingSortedSession = sortedSession.slice(
            idx + 1,
            sortedSession.length + 1
          );
          let remainingSessionPromise = await remainingSortedSession.map(
            async (remainingSession) => {
              if (
                (session.type == remainingSession.type &&
                  session.type != SESSION_TYPE.GENERIC &&
                  ((session.bundleIndex == remainingSession.bundleIndex &&
                    session.index != remainingSession.index) ||
                    session.bundleIndex != remainingSession.bundleIndex)) ||
                (session.type == SESSION_TYPE.GENERIC &&
                  session.index != remainingSession.index) ||
                session.type != remainingSession.type
              ) {
                let conflictStatus = {};
                let conflictPromise = await checkForConflict(
                  session,
                  remainingSession
                );

                await Promise.resolve(conflictPromise).then((value) => {
                  conflictStatus = value;
                });
                if (conflictStatus.hasError && !conflictStatus.hasException) {
                  isError = true;
                  if (
                    !errorsList[
                    `${conflictStatus.type == SESSION_TYPE.SPECIFIC_INDIVIDUAL
                      ? "individual_start_date_" +
                      conflictStatus.bundleIndex +
                      "_"
                      : conflictStatus.type == SESSION_TYPE.SPECIFIC_GROUP
                        ? "group_start_date_" +
                        conflictStatus.bundleIndex +
                        "_"
                        : "generic_start_date_"
                    }${conflictStatus.index}`
                    ]
                  ) {
                    errorsList[
                      `${conflictStatus.type == SESSION_TYPE.SPECIFIC_INDIVIDUAL
                        ? "individual_start_date_" +
                        conflictStatus.bundleIndex +
                        "_"
                        : conflictStatus.type == SESSION_TYPE.SPECIFIC_GROUP
                          ? "group_start_date_" +
                          conflictStatus.bundleIndex +
                          "_"
                          : "generic_start_date_"
                      }${conflictStatus.index}`
                    ] = `Session date and time conflict at ${moment(
                      conflictStatus.start_at
                    ).format("MMMM Do YYYY, h:mm A")} please check.`;
                  }
                }
              }
            }
          );

          await Promise.all(remainingSessionPromise);
        }
      );

      await Promise.all(checkValidationPromise);
    }
    if (isError || hasRecurringError) {
      dispatch({
        type: EDUCATOR_COURSE_CREATE_ERROR,
        payload: {
          message: "Please correct the errors",
          alertType: "danger",
          errorsList,
        },
      });
      return false;
    } else {
      return true;
    }
  };

  const getSpecificIndividualAllSessionDataAndErrorIfAny = async () => {
    let errorsList = [];
    let allSession = [];
    let hasRecurringError = false;
    let recurrenceSessionGroup = [
      ...sessionFormData.specific_individual.session_group,
    ];
    let sessionDuration =
      sessionFormData.specific_individual.duration_per_session;
    let sessionBundlePromise = recurrenceSessionGroup.map(
      async (singleBundle, bundleIndex) => {
        let new_all_session_with_recurring = [];
        let sessionPromise = await singleBundle.sessions.map(
          async (singleSession, index) => {
            let recurringEndDate = moment(
              moment(
                singleBundle.recurring_end_date + " " + singleSession.start_time
              )
                .add(sessionDuration, "minutes")
                .format("YYYY-MM-DD HH:mm")
            );

            let selected_start_date = moment(
              singleSession.start_date + " " + singleSession.start_time
            );
            let selected_end_date = moment(
              selected_start_date
                .clone()
                .add(sessionDuration, "minutes")
                .format("YYYY-MM-DD HH:mm")
            );

            if (
              singleBundle.recurring_type != RECURRING_TYPE_REVERSE.NONE &&
              recurringEndDate < selected_start_date
            ) {
              hasRecurringError = true;
              errorsList[`individual_recurring_end_date_${bundleIndex}`] =
                "Recurring Date must be greater than session date.";
            }

            let current_date_time = moment.tz(timeZone);
            if (
              (singleBundle.is_new || singleBundle.is_editable) &&
              singleBundle.is_recurring_editable &&
              current_date_time.unix() > selected_start_date.unix()
            ) {
              hasRecurringError = true;
              errorsList[`individual_start_date_${bundleIndex}_${index}`] =
                "Session date and time must be greater then current date and time.";
            } else {
              //check for the id if the session is not new ie is_new is false
              let newData = {
                session_id:
                  !singleBundle.is_new || !singleBundle.is_editable
                    ? singleSession._id
                    : "",
                start_date_time: moment(selected_start_date).format(
                  "YYYY-MM-DD HH:mm"
                ),
                end_date_time: moment(selected_end_date).format(
                  "YYYY-MM-DD HH:mm"
                ),
                start_at: selected_start_date,
                start_time: singleSession.start_time,
                end_at: selected_end_date,
                bundleIndex: bundleIndex,
                index: index,
                type: SESSION_TYPE.SPECIFIC_INDIVIDUAL,
                is_editable:
                  singleBundle.is_editable != undefined
                    ? singleBundle.is_editable
                    : true,
                is_new: singleBundle.is_new ? singleBundle.is_new : false,
                is_new_recurring_data: true,
              };
              if (singleBundle.recurring_type != RECURRING_TYPE_REVERSE.NONE) {
                let recurringDataPromise = getRecurringAllSessionData(
                  newData,
                  singleBundle.recurring_type,
                  recurringEndDate,
                  allSession
                );

                await Promise.resolve(recurringDataPromise).then((res) => {
                  let recurringData = res;
                  new_all_session_with_recurring = [
                    ...new_all_session_with_recurring,
                    ...recurringData,
                  ];
                  allSession = [...allSession, ...recurringData];
                });
              } else {
                allSession = [...allSession, newData];
                new_all_session_with_recurring = [
                  ...new_all_session_with_recurring,
                  { ...newData },
                ];
              }
            }
          }
        );

        await Promise.all(sessionPromise);
        if (
          recurrenceSessionGroup[bundleIndex].is_new ||
          recurrenceSessionGroup[bundleIndex].is_editable
        ) {
          recurrenceSessionGroup[
            bundleIndex
          ].all_session_with_recurring = new_all_session_with_recurring;
        } else if (recurrenceSessionGroup[bundleIndex].is_recurring_editable) {
          let temp_old_recurring_end_date = moment(
            recurrenceSessionGroup[bundleIndex].old_recurring_end_date
          );
          let recurringEndDate = moment(singleBundle.recurring_end_date);
          let temp_recurring_end_date = moment(
            recurringEndDate.clone().format("YYYY-MM-DD")
          );

          if (
            temp_old_recurring_end_date.unix() != temp_recurring_end_date.unix()
          ) {
            let sortedAllSession = await new_all_session_with_recurring.sort(
              (a, b) => a.start_at - b.start_at
            );
            recurrenceSessionGroup[bundleIndex].old_recurring_end_date = moment(
              recurringEndDate.clone().format("YYYY-MM-DD")
            );
            if (
              temp_old_recurring_end_date.unix() <
              temp_recurring_end_date.unix()
            ) {
              //and if the recurring date is increased then add those dates only by just untoching old one
              let newAddedData = await sortedAllSession.filter((session) => {
                let temp_start_at = moment(
                  session.start_at.clone().format("YYYY-MM-DD")
                );

                return (
                  temp_start_at.unix() > temp_old_recurring_end_date.unix()
                );
                // session._id.includes(e.target.value)
              });
              await newAddedData.map((newData) => {
                let is_exist = recurrenceSessionGroup[
                  bundleIndex
                ].all_session_with_recurring.filter((session) => {
                  return (
                    session.is_new != undefined &&
                    session.is_new &&
                    session.start_at.unix() == newData.start_at.unix()
                  );
                  // session._id.includes(e.target.value)
                });
                if (is_exist.length == 0) {
                  recurrenceSessionGroup[
                    bundleIndex
                  ].all_session_with_recurring.push({ ...newData });
                }
              });
            } else {
              let timeZone = getTimeZone(user);
              let is_error_in_decrease_recurring_end_date = false;
              let newRemovedExcludedData = recurrenceSessionGroup[
                bundleIndex
              ].all_session_with_recurring.filter((session) => {
                let temp_start_date = moment(
                  moment
                    .unix(session.start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DD")
                );

                if (
                  singleBundle.sessions[session.index]._id ==
                  session.session_id &&
                  temp_start_date.unix() > temp_recurring_end_date.unix() &&
                  session.session_booking_count > 0
                ) {
                  is_error_in_decrease_recurring_end_date = true;
                  hasRecurringError = true;
                  errorsList[
                    `individual_recurring_end_date_${bundleIndex}`
                  ] = `Session is already booked for ${moment
                    .unix(session.start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DD")}`;
                }
                return (
                  singleBundle.sessions[session.index]._id ==
                  session.session_id &&
                  temp_start_date.unix() <= temp_recurring_end_date.unix()
                );
                // session._id.includes(e.target.value)
              });
              if (!is_error_in_decrease_recurring_end_date) {
                recurrenceSessionGroup[
                  bundleIndex
                ].all_session_with_recurring = [...newRemovedExcludedData];
              }

              // and if the recurring date is decreased then remove the extra entries by retaining old
            }
          }

          //check if only recurring date is changed and
          //if it is then make the changes in existing recurrenceSessionGroup[index].all_session_with_recurring
          // and if the recurring date is decreased then remove the extra entries by retaining old
          //and if the recurring date is increased then add those dates only by just untoching old one
        }
        // }
      }
    );
    await Promise.all(sessionBundlePromise);

    return {
      errorsList,
      allSession,
      hasRecurringError,
      recurrenceSessionGroup,
    };
  };

  const getSpecificGroupAllSessionDataAndErrorIfAny = async () => {
    let errorsList = [];
    let allSession = [];
    let hasRecurringError = false;
    let recurrenceSessionGroup = [
      ...sessionFormData.specific_group.session_group,
    ];
    let sessionDuration = sessionFormData.specific_group.duration_per_session;
    let sessionBundlePromise = recurrenceSessionGroup.map(
      async (singleBundle, bundleIndex) => {
        let new_all_session_with_recurring = [];
        let sessionPromise = await singleBundle.sessions.map(
          async (singleSession, index) => {
            let recurringEndDate = moment(
              moment(
                singleBundle.recurring_end_date + " " + singleSession.start_time
              )
                .add(sessionDuration, "minutes")
                .format("YYYY-MM-DD HH:mm")
            );

            let selected_start_date = moment(
              singleSession.start_date + " " + singleSession.start_time
            );
            let selected_end_date = moment(
              selected_start_date
                .clone()
                .add(sessionDuration, "minutes")
                .format("YYYY-MM-DD HH:mm")
            );

            if (
              singleBundle.recurring_type != RECURRING_TYPE_REVERSE.NONE &&
              recurringEndDate < selected_start_date
            ) {
              hasRecurringError = true;
              errorsList[`group_recurring_end_date_${bundleIndex}`] =
                "Recurring Date must be greater than session date.";
            }

            let current_date_time = moment.tz(timeZone);
            if (
              (singleBundle.is_new || singleBundle.is_editable) &&
              singleBundle.is_recurring_editable &&
              current_date_time.unix() > selected_start_date.unix()
            ) {
              hasRecurringError = true;
              errorsList[`group_start_date_${bundleIndex}_${index}`] =
                "Session date and time must be greater then current date and time.";
            } else {
              //check for the id if the session is not new ie is_new is false
              let newData = {
                session_id:
                  !singleBundle.is_new || !singleBundle.is_editable
                    ? singleSession._id
                    : "",
                start_date_time: moment(selected_start_date).format(
                  "YYYY-MM-DD HH:mm"
                ),
                end_date_time: moment(selected_end_date).format(
                  "YYYY-MM-DD HH:mm"
                ),
                start_at: selected_start_date,
                start_time: singleSession.start_time,
                end_at: selected_end_date,
                bundleIndex: bundleIndex,
                index: index,
                type: SESSION_TYPE.SPECIFIC_GROUP,
                is_editable:
                  singleBundle.is_editable != undefined
                    ? singleBundle.is_editable
                    : true,
                is_new: singleBundle.is_new ? singleBundle.is_new : false,
                is_new_recurring_data: true,
              };
              if (singleBundle.recurring_type != RECURRING_TYPE_REVERSE.NONE) {
                let recurringDataPromise = getRecurringAllSessionData(
                  newData,
                  singleBundle.recurring_type,
                  recurringEndDate,
                  allSession
                );

                await Promise.resolve(recurringDataPromise).then((res) => {
                  let recurringData = res;
                  new_all_session_with_recurring = [
                    ...new_all_session_with_recurring,
                    ...recurringData,
                  ];
                  allSession = [...allSession, ...recurringData];
                });
              } else {
                allSession = [...allSession, newData];
                new_all_session_with_recurring = [
                  ...new_all_session_with_recurring,
                  { ...newData },
                ];
              }
            }
          }
        );

        await Promise.all(sessionPromise);
        if (
          recurrenceSessionGroup[bundleIndex].is_new ||
          recurrenceSessionGroup[bundleIndex].is_editable
        ) {
          recurrenceSessionGroup[
            bundleIndex
          ].all_session_with_recurring = new_all_session_with_recurring;
        } else if (recurrenceSessionGroup[bundleIndex].is_recurring_editable) {
          let temp_old_recurring_end_date = moment(
            recurrenceSessionGroup[bundleIndex].old_recurring_end_date
          );
          let recurringEndDate = moment(singleBundle.recurring_end_date);
          let temp_recurring_end_date = moment(
            recurringEndDate.clone().format("YYYY-MM-DD")
          );

          if (
            temp_old_recurring_end_date.unix() != temp_recurring_end_date.unix()
          ) {
            let sortedAllSession = await new_all_session_with_recurring.sort(
              (a, b) => a.start_at - b.start_at
            );
            recurrenceSessionGroup[bundleIndex].old_recurring_end_date = moment(
              recurringEndDate.clone().format("YYYY-MM-DD")
            );
            if (
              temp_old_recurring_end_date.unix() <
              temp_recurring_end_date.unix()
            ) {
              //and if the recurring date is increased then add those dates only by just untoching old one
              let newAddedData = await sortedAllSession.filter((session) => {
                let temp_start_at = moment(
                  session.start_at.clone().format("YYYY-MM-DD")
                );

                return (
                  temp_start_at.unix() > temp_old_recurring_end_date.unix()
                );
                // session._id.includes(e.target.value)
              });
              await newAddedData.map((newData) => {
                let is_exist = recurrenceSessionGroup[
                  bundleIndex
                ].all_session_with_recurring.filter((session) => {
                  return (
                    session.is_new != undefined &&
                    session.is_new &&
                    session.start_at.unix() == newData.start_at.unix()
                  );
                  // session._id.includes(e.target.value)
                });
                if (is_exist.length == 0) {
                  recurrenceSessionGroup[
                    bundleIndex
                  ].all_session_with_recurring.push({ ...newData });
                }
              });
            } else {
              let timeZone = getTimeZone(user);
              let is_error_in_decrease_recurring_end_date = false;
              let newRemovedExcludedData = recurrenceSessionGroup[
                bundleIndex
              ].all_session_with_recurring.filter((session) => {
                let temp_start_date = moment(
                  moment
                    .unix(session.start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DD")
                );

                if (
                  singleBundle.sessions[session.index]._id ==
                  session.session_id &&
                  temp_start_date.unix() > temp_recurring_end_date.unix() &&
                  session.session_booking_count > 0
                ) {
                  is_error_in_decrease_recurring_end_date = true;
                  hasRecurringError = true;
                  errorsList[
                    `group_recurring_end_date_${bundleIndex}`
                  ] = `Session is already booked for ${moment
                    .unix(session.start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DD")}`;
                }
                return (
                  singleBundle.sessions[session.index]._id ==
                  session.session_id &&
                  temp_start_date.unix() <= temp_recurring_end_date.unix()
                );
                // session._id.includes(e.target.value)
              });
              if (!is_error_in_decrease_recurring_end_date) {
                recurrenceSessionGroup[
                  bundleIndex
                ].all_session_with_recurring = [...newRemovedExcludedData];
              }

              // and if the recurring date is decreased then remove the extra entries by retaining old
            }
          }

          //check if only recurring date is changed and
          //if it is then make the changes in existing recurrenceSessionGroup[index].all_session_with_recurring
          // and if the recurring date is decreased then remove the extra entries by retaining old
          //and if the recurring date is increased then add those dates only by just untoching old one
        }
        // }
      }
    );
    await Promise.all(sessionBundlePromise);

    return {
      errorsList,
      allSession,
      hasRecurringError,
      recurrenceSessionGroup,
    };
  };
  const getGenericAllSessionDataAndErrorIfAny = async () => {
    let errorsList = [];
    let allSession = [];
    let hasRecurringError = false;
    let recurrenceSessionGroup = [...sessionFormData.generic.session_group];

    let sessionPromise = recurrenceSessionGroup.map(
      async (singleSession, index) => {
        let new_all_session_with_recurring = [];
        let recurringEndDate = moment(
          singleSession.sessions[0].end_time == "00:00"
            ? moment(singleSession.recurring_end_date)
              .add(1, "days")
              .format("YYYY-MM-DD")
            : singleSession.recurring_end_date +
            " " +
            singleSession.sessions[0].end_time
        );

        let selected_start_date = moment(
          singleSession.sessions[0].start_date +
          " " +
          singleSession.sessions[0].start_time
        );
        let selected_end_date = moment(
          singleSession.sessions[0].end_time == "00:00"
            ? moment(singleSession.sessions[0].start_date)
              .add(1, "days")
              .format("YYYY-MM-DD")
            : singleSession.sessions[0].start_date +
            " " +
            singleSession.sessions[0].end_time
        );
        let end_date = moment(
          singleSession.sessions[0].end_time == "00:00"
            ? moment(singleSession.sessions[0].end_date)
              .add(1, "days")
              .format("YYYY-MM-DD")
            : singleSession.sessions[0].end_date +
            " " +
            singleSession.sessions[0].end_time
        );

        if (
          singleSession.recurring_type != RECURRING_TYPE_REVERSE.NONE &&
          recurringEndDate < selected_start_date
        ) {
          hasRecurringError = true;
          errorsList[`generic_recurring_end_date_${index}`] =
            "Recurring Date must be greater than session date.";
        }

        let current_date_time = moment.tz(timeZone);
        if (
          (singleSession.is_new || singleSession.is_editable) &&
          singleSession.is_recurring_editable &&
          current_date_time.unix() > selected_start_date.unix()
        ) {
          hasRecurringError = true;
          errorsList[`generic_start_date_${index}`] =
            "Session date and time must be greater then current date and time.";
        } else {
          if (selected_end_date <= selected_start_date) {
            hasRecurringError = true;
            errorsList[`generic_start_date_${index}`] =
              "Session end date and time must be greater than start date and time.";
          } else {
            while (end_date.unix() > selected_start_date.unix()) {
              //check for the id if the session is not new ie is_new is false
              let newData = {
                session_id:
                  !recurrenceSessionGroup[index].is_new ||
                    !recurrenceSessionGroup[index].is_editable
                    ? singleSession.sessions[0]._id
                    : "",
                start_at: selected_start_date,
                start_date_time: moment(selected_start_date).format(
                  "YYYY-MM-DD HH:mm"
                ),
                end_date_time: moment(selected_end_date).format(
                  "YYYY-MM-DD HH:mm"
                ),
                start_time: singleSession.sessions[0].start_time,
                end_time: singleSession.sessions[0].end_time,
                end_at: selected_end_date,
                index: index,
                type: SESSION_TYPE.GENERIC,
                is_editable: singleSession.is_editable,
                is_new: singleSession.is_new ? singleSession.is_new : false,
                is_new_recurring_data: true,
              };
              if (singleSession.recurring_type != RECURRING_TYPE_REVERSE.NONE) {
                let recurringDataPromise = getRecurringAllSessionData(
                  newData,
                  singleSession.recurring_type,
                  recurringEndDate,
                  allSession
                );

                await Promise.resolve(recurringDataPromise).then((res) => {
                  let recurringData = res;
                  new_all_session_with_recurring.push(...recurringData);
                  allSession.push(...recurringData);
                });
              } else {
                allSession.push(newData);
                new_all_session_with_recurring.push({ ...newData });
              }

              selected_start_date = moment(
                selected_start_date
                  .clone()
                  .add(1, "days")
                  .format("YYYY-MM-DD HH:mm")
              );

              selected_end_date = moment(
                selected_end_date
                  .clone()
                  .add(1, "days")
                  .format("YYYY-MM-DD HH:mm")
              );
            }

            let new_all_session_with_recurring_chunks = [];
            let sessionChuncksPromise = getSessionChunks(
              new_all_session_with_recurring
            );

            recurrenceSessionGroup[
              index
            ].all_session_with_recurring_without_chuks = new_all_session_with_recurring;

            await Promise.resolve(sessionChuncksPromise).then(
              (sessionChunks) => {
                new_all_session_with_recurring_chunks = [...sessionChunks];
              }
            );

            if (
              recurrenceSessionGroup[index].is_new ||
              recurrenceSessionGroup[index].is_editable
            ) {
              recurrenceSessionGroup[
                index
              ].all_session_with_recurring = new_all_session_with_recurring_chunks;
            } else if (recurrenceSessionGroup[index].is_recurring_editable) {
              let temp_old_recurring_end_date = moment(
                recurrenceSessionGroup[index].old_recurring_end_date
              );
              let temp_recurring_end_date = moment(
                recurringEndDate.clone().format("YYYY-MM-DD")
              );

              if (
                temp_old_recurring_end_date.unix() !=
                temp_recurring_end_date.unix()
              ) {
                let sortedAllSession = await new_all_session_with_recurring_chunks.sort(
                  (a, b) => a.start_at - b.start_at
                );
                recurrenceSessionGroup[index].old_recurring_end_date = moment(
                  recurringEndDate.clone().format("YYYY-MM-DD")
                );
                if (
                  temp_old_recurring_end_date.unix() <
                  temp_recurring_end_date.unix()
                ) {
                  //and if the recurring date is increased then add those dates only by just untoching old one
                  let newAddedData = await sortedAllSession.filter(
                    (session) => {
                      let temp_start_at = moment(
                        session.start_at.clone().format("YYYY-MM-DD")
                      );

                      return (
                        temp_start_at.unix() >
                        temp_old_recurring_end_date.unix()
                      );
                      // session._id.includes(e.target.value)
                    }
                  );
                  await newAddedData.map((newData) => {
                    let is_exist = recurrenceSessionGroup[
                      index
                    ].all_session_with_recurring.filter((session) => {
                      return (
                        session.is_new != undefined &&
                        session.is_new &&
                        session.start_at.unix() == newData.start_at.unix()
                      );
                      // session._id.includes(e.target.value)
                    });
                    if (is_exist.length == 0) {
                      recurrenceSessionGroup[
                        index
                      ].all_session_with_recurring.push({ ...newData });
                    }
                  });
                } else {
                  let timeZone = getTimeZone(user);

                  let is_error_in_decrease_recurring_end_date = false;
                  let newRemovedExcludedData = recurrenceSessionGroup[
                    index
                  ].all_session_with_recurring.filter((session) => {
                    let temp_start_date = moment(
                      moment
                        .unix(session.start_at)
                        .tz(timeZone)
                        .format("YYYY-MM-DD")
                    );

                    if (
                      singleSession.sessions[0]._id == session.session_id &&
                      temp_start_date.unix() > temp_recurring_end_date.unix() &&
                      session.session_booking_count > 0
                    ) {
                      is_error_in_decrease_recurring_end_date = true;
                      hasRecurringError = true;
                      errorsList[
                        `generic_recurring_end_date_${index}`
                      ] = `Session is already booked for ${moment
                        .unix(session.start_at)
                        .tz(timeZone)
                        .format("YYYY-MM-DD")}`;
                    }
                    return (
                      singleSession.sessions[0]._id == session.session_id &&
                      temp_start_date.unix() <= temp_recurring_end_date.unix()
                    );
                    // session._id.includes(e.target.value)
                  });
                  if (!is_error_in_decrease_recurring_end_date) {
                    recurrenceSessionGroup[index].all_session_with_recurring = [
                      ...newRemovedExcludedData,
                    ];
                  }

                  // and if the recurring date is decreased then remove the extra entries by retaining old
                }
              }

              //check if only recurring date is changed and
              //if it is then make the changes in existing recurrenceSessionGroup[index].all_session_with_recurring
              // and if the recurring date is decreased then remove the extra entries by retaining old
              //and if the recurring date is increased then add those dates only by just untoching old one
            }
          }
        }
      }
    );
    await Promise.all(sessionPromise);
    return {
      errorsList,
      allSession,
      hasRecurringError,
      recurrenceSessionGroup,
    };
  };

  const getSessionChunks = async (new_all_session_with_recurring) => {
    let sessionChunks = [];
    let sessionChunksPromise = await new_all_session_with_recurring.map(
      async (singleSession) => {
        let selected_start_date = moment(
          singleSession.start_at.clone().format("YYYY-MM-DD HH:mm")
        );
        let selected_end_date = moment(
          singleSession.start_at
            .clone()
            .add(15, "minutes")
            .format("YYYY-MM-DD HH:mm")
        );
        let end_date = moment(
          singleSession.end_at.clone().format("YYYY-MM-DD HH:mm")
        );

        while (selected_end_date.unix() <= end_date.unix()) {
          // if (selected_start_date != currentSession.start_at)
          sessionChunks.push({
            ...singleSession,
            start_date_time: moment(selected_start_date).format(
              "YYYY-MM-DD HH:mm"
            ),
            end_date_time: moment(selected_end_date).format("YYYY-MM-DD HH:mm"),
            start_at: selected_start_date,
            end_at: selected_end_date,
          });
          selected_start_date = moment(
            selected_start_date
              .clone()
              .add(15, "minutes")
              .format("YYYY-MM-DD HH:mm")
          );
          selected_end_date = moment(
            selected_end_date
              .clone()
              .add(15, "minutes")
              .format("YYYY-MM-DD HH:mm")
          );
        }
      }
    );

    await Promise.all(sessionChunksPromise);
    return sessionChunks;
  };

  const getRecurringAllSessionData = async (
    currentSession,
    recurringType,
    recurringEndDate,
    allSession
  ) => {
    let sessionRecurringData = [];
    let selected_start_date = moment(
      currentSession.start_at.clone().format("YYYY-MM-DD HH:mm")
    );
    let selected_end_date = moment(
      currentSession.end_at.clone().format("YYYY-MM-DD HH:mm")
    );
    //check for the id if the session is not new ie is_new is false in each while
    switch (Number(recurringType)) {
      case RECURRING_TYPE_REVERSE.DAILY:
        while (selected_end_date.unix() <= recurringEndDate.unix()) {
          // if (selected_start_date != currentSession.start_at)
          sessionRecurringData.push({
            ...currentSession,
            start_date_time: moment(selected_start_date).format(
              "YYYY-MM-DD HH:mm"
            ),
            end_date_time: moment(selected_end_date).format("YYYY-MM-DD HH:mm"),
            start_at: selected_start_date,
            end_at: selected_end_date,
          });
          selected_start_date = moment(
            selected_start_date
              .clone()
              .add(1, "days")
              .format("YYYY-MM-DD HH:mm")
          );
          selected_end_date = moment(
            selected_end_date.clone().add(1, "days").format("YYYY-MM-DD HH:mm")
          );
        }
        break;
      case RECURRING_TYPE_REVERSE.WEEKLY:
        while (selected_end_date.unix() <= recurringEndDate.unix()) {
          // if (selected_start_date != currentSession.start_at)
          sessionRecurringData.push({
            ...currentSession,
            start_date_time: moment(selected_start_date).format(
              "YYYY-MM-DD HH:mm"
            ),
            end_date_time: moment(selected_end_date).format("YYYY-MM-DD HH:mm"),
            start_at: selected_start_date,
            end_at: selected_end_date,
          });
          selected_start_date = moment(
            selected_start_date
              .clone()
              .add(7, "days")
              .format("YYYY-MM-DD HH:mm")
          );
          selected_end_date = moment(
            selected_end_date.clone().add(7, "days").format("YYYY-MM-DD HH:mm")
          );
        }

        break;
      case RECURRING_TYPE_REVERSE.MONTHLY:
        let start_at = selected_start_date.clone();
        let end_at = selected_end_date.clone();
        let addMonth = 1;
        while (end_at.unix() <= recurringEndDate.unix()) {
          // if (selected_start_date != currentSession.start_at)
          let isExist = allSession.filter((session) => {
            return (
              session.start_at.unix() == start_at.unix() &&
              session.end_at.unix() == end_at.unix()
            );
          });
          if (isExist.length == 0) {
            sessionRecurringData.push({
              ...currentSession,
              start_date_time: moment(start_at).format("YYYY-MM-DD HH:mm"),
              end_date_time: moment(end_at).format("YYYY-MM-DD HH:mm"),
              start_at: start_at,
              end_at: end_at,
            });
          }
          start_at = moment(
            selected_start_date
              .clone()
              .add(addMonth, "months")
              .format("YYYY-MM-DD HH:mm")
          );
          end_at = moment(
            selected_end_date
              .clone()
              .add(addMonth, "months")
              .format("YYYY-MM-DD HH:mm")
          );
          addMonth += 1;
        }

        break;

      default:
        break;
    }
    return sessionRecurringData;
  };

  const checkForConflict = async (firstDate, secondDate) => {
    try {
      if (
        (firstDate.start_at.unix() < secondDate.start_at.unix() &&
          ((secondDate.start_at.unix() < firstDate.end_at.unix() &&
            firstDate.end_at.unix() <= secondDate.end_at.unix()) ||
            firstDate.end_at.unix() > secondDate.end_at.unix() ||
            secondDate.end_at.unix() <= firstDate.end_at.unix())) ||
        (firstDate.start_at.unix() == secondDate.start_at.unix() &&
          ((secondDate.start_at.unix() < firstDate.end_at.unix() &&
            firstDate.end_at.unix() < secondDate.end_at.unix()) ||
            secondDate.end_at.unix() <= firstDate.end_at.unix()))
      ) {
        return {
          hasError: true,
          index:
            secondDate.is_editable || secondDate.is_new
              ? secondDate.index
              : firstDate.index,
          bundleIndex:
            secondDate.is_editable || secondDate.is_new
              ? secondDate.bundleIndex
                ? secondDate.bundleIndex
                : 0
              : firstDate.bundleIndex
                ? firstDate.bundleIndex
                : 0,
          start_at:
            secondDate.is_editable || secondDate.is_new
              ? secondDate.start_at
              : firstDate.start_at,
          type:
            secondDate.is_editable || secondDate.is_new
              ? secondDate.type
              : firstDate.type,
          hasException: false,
        };
      } else {
        return { hasError: false, hasException: false };
      }
    } catch (error) {
      // console.log(error);
      return { hasError: true, hasException: true };
    }
  };
  return (
    <div className="row p-60 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-head space-between mb-3">
              <h2>Edit Session</h2>
              <Link to="/dashboard">Back to Dashboard</Link>
            </div>
            <div id="login" className="bg-white">
              <div className="wrapper">
                <div className="inner">
                  {loading ? (
                    <Spinner />
                  ) : (
                      <div id="wizard" ref={wizardRef}>
                        <TabsHeader
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                          TABS={TABS}
                          sessionEnable={sessionEnable}
                          calendarConfirmationTabEnable={
                            calendarConfirmationTabEnable
                          }
                          submitLoading={submitLoading}
                          checkSessionValidation={checkSessionValidation}
                        />

                        {/* SECTION 1 */}
                        {activeTab == TABS.BASIC && (
                          <CourseInformation
                            isDraft={true}
                            courseInformationFormData={courseInformationFormData}
                            setcourseInformationFormData={
                              setcourseInformationFormData
                            }
                            sessionEnable={sessionEnable}
                            setSessionEnable={setSessionEnable}
                            setTypeOfSession={setTypeOfSession}
                            setActiveTab={setActiveTab}
                            resetSessionFormData={resetSessionFormData}
                            basicErrorMsg={basicErrorMsg}
                            setBasicErrorMsg={setBasicErrorMsg}
                            onSubmitDraftForm={onSubmitDraftForm}
                          />
                        )}

                        {/* SECTION 2 */}
                        {activeTab == TABS.SESSION && (
                          <SessionInformation
                            typeOfSession={typeOfSession}
                            sessionFormData={sessionFormData}
                            setSessionFormData={setSessionFormData}
                            setActiveTab={setActiveTab}
                            setCalendarConfirmationTabEnable={
                              setCalendarConfirmationTabEnable
                            }
                            calendarConfirmationTabEnable={
                              calendarConfirmationTabEnable
                            }
                            checkSessionValidation={checkSessionValidation}
                            defaultGenericSessionGroup={
                              defaultGenericSessionGroup
                            }
                            defaultGenericDateTimingInput={
                              defaultGenericDateTimingInput
                            }
                            defaultSpecificIndividualSessionGroup={
                              defaultSpecificIndividualSessionGroup
                            }
                            defaultSpecificIndividualDateTimingInput={
                              defaultSpecificIndividualDateTimingInput
                            }
                            defaultSpecificGroupDateTimingInput={
                              defaultSpecificGroupDateTimingInput
                            }
                            defaultSpecificGroupSessionGroup={
                              defaultSpecificGroupSessionGroup
                            }
                            isForEdit={true}
                            current_date={current_date}
                            sessionErrorMsg={sessionErrorMsg}
                            setSessionErrorMsg={setSessionErrorMsg}
                            onSubmitDraftForm={onSubmitDraftForm}
                          />
                        )}

                        {/* SECTION 3 */}
                        {activeTab == TABS.CALENDAR && (
                          <CalendarInformation
                            courseInformationFormData={courseInformationFormData}
                            typeOfSession={typeOfSession}
                            sessionFormData={sessionFormData}
                            setActiveTab={setActiveTab}
                            calendarConfirmationTabEnable={
                              calendarConfirmationTabEnable
                            }
                            checkSessionValidation={checkSessionValidation}
                            setCalendarConfirmationTabEnable={setCalendarConfirmationTabEnable}
                            onSubmitDraftForm={onSubmitDraftForm}
                          />
                        )}

                        {/* SECTION 4 */}
                        {activeTab == TABS.CONFIRMATION && (
                          <>
                            <CourseInformation
                              courseInformationFormData={
                                courseInformationFormData
                              }
                              setcourseInformationFormData={
                                setcourseInformationFormData
                              }
                              sessionEnable={sessionEnable}
                              setSessionEnable={setSessionEnable}
                              setTypeOfSession={setTypeOfSession}
                              setActiveTab={setActiveTab}
                              resetSessionFormData={resetSessionFormData}
                              isConfirmation={true}
                              basicErrorMsg={basicErrorMsg}
                              setBasicErrorMsg={setBasicErrorMsg}
                              onSubmitDraftForm={onSubmitDraftForm}
                            />
                            <SessionInformation
                              typeOfSession={typeOfSession}
                              sessionFormData={sessionFormData}
                              setSessionFormData={setSessionFormData}
                              setActiveTab={setActiveTab}
                              setCalendarConfirmationTabEnable={
                                setCalendarConfirmationTabEnable
                              }
                              calendarConfirmationTabEnable={
                                calendarConfirmationTabEnable
                              }
                              isConfirmation={true}
                              defaultGenericSessionGroup={
                                defaultGenericSessionGroup
                              }
                              defaultGenericDateTimingInput={
                                defaultGenericDateTimingInput
                              }
                              current_date={current_date}
                              sessionErrorMsg={sessionErrorMsg}
                              setSessionErrorMsg={setSessionErrorMsg}
                              onSubmitDraftForm={onSubmitDraftForm}
                            />
                            <div className="col-md-12">
                              <hr />
                            </div>
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-4">
                                  {!submitLoading && (
                                    <button
                                      className="btn btn-primary"
                                      onClick={(e) => {
                                        onClickBackNextButton(e, TABS.CALENDAR);
                                      }}
                                    >
                                      Back
                                    </button>
                                  )}
                                </div>
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                  <div className="form-group text-right mb-0">
                                    {submitLoading ? (
                                      <Spinner />
                                    ) : (
                                        <button
                                          className="btn btn-secondary mb-0"
                                          onClick={(e) => onSubmitForm(e)}
                                        >
                                          Save
                                        </button>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  editDraftCourse,
  getDraftCourseById,
  createCourse,
  deleteDraftCourse
})(withRouter(EditDraftCourse));
