import { connect } from "react-redux";
import { follow, unFollow } from "actions/front/dingdong";
import { useEffect, useMemo, useState } from "react";
import { Spinner } from "reactstrap";
import dingdongnow from "assets/images/icons/dingdongnow.svg";
import dingdonging from "assets/images/icons/dingdonging.svg";
import { Tooltip } from "reactstrap";
import { withRouter } from "react-router";

const DingDongUser = ({
  isAuthenticated,
  user,
  followId,
  followName,
  follow,
  count = null,
  refetch = null,
  unFollow,
  authLoading,
  history
}) => {
  const [loading, setLoading] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [isFollowing, setFollowing] = useState(
    user &&
      user.following &&
      user.following.users &&
      user.following.users.findIndex((u) => u._id === followId) > -1
  );

  const toggle = () => setTooltipOpen(!isTooltipOpen);

  useEffect(() => {
    if (loading && !authLoading) {
      setLoading(false);
    }
  }, [authLoading]);

  if (isAuthenticated && user._id === followId) {
    return null;
  }

  const toggleFollow = async (e) => {
    if (isAuthenticated) {
      setLoading(true);
      if (isFollowing) {
        await unFollow(followId);
        setFollowing(false);
      } else {
        await follow(followId);
        setFollowing(true);
      }
    } else {
      history.push("/login");
    }
  };

  let text = "";
  if (!isAuthenticated) {
    text = "Sign in to start DingDonging!"
  } else {
    if (isFollowing) {
      text = `Click to stop receiving notifications from <b>${followName}</b>`;
    } else {
      text = `Click to DingDing <b>${followName}</b>, start receiving notifications!`;
    }
  }

  return (
    <div className="dingdong">
      <>
        <Tooltip
          placement="top"
          isOpen={isTooltipOpen}
          target={`dingdong-user${followId}`}
          toggle={toggle}
          dangerouslySetInnerHTML={{"__html": text}}
        >
        </Tooltip>
        <div className="dingdong-wrapper">

        <button
          id={`dingdong-user${followId}`}
          type="button"
          disabled={loading}
          className="btn-pure"
          onClick={toggleFollow}
          title={
            isFollowing
              ? ""
              : `Dingding ${followName} to receive notifications!`
          }
        >
          {loading ? (
            <div className="spinnerbox">
              <Spinner />
            </div>
          ) : (
            <>
              {isFollowing ? (
                <img src={dingdonging} />
              ) : (
                <img src={dingdongnow} />
              )}
            </>
          )}
        </button>
        </div>
      </>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  authLoading: auth.loading,
  user: auth.user,
  isAuthenticated: auth.isAuthenticated,
});

export default withRouter(connect(mapStateToProps, { follow, unFollow })(DingDongUser));
