import React, { useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { USER_TYPE } from "constants";

import HostMyAccountSettings from "views/Front/ProfileCourseMgmt/HostMyAccountSettings";
import StudentMyAccountSettings from "views/Front/ProfileCourseMgmt/StudentMyAccountSettings";

const MyAccountSettings = ({ user, isHosting }) => {

  /********************set user data to local state*****************/
  return (
    isHosting ? 
    <HostMyAccountSettings user={user}/> 
    :
    <StudentMyAccountSettings user={user}/> 
  ) 
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  isHosting: state.auth.user?.role[0].user_type === USER_TYPE.HOST,
});

export default connect(mapStateToProps)(withRouter(MyAccountSettings));
