import { ErrorMessage, Field } from "formik";
import Select from "react-select";
import { useState } from "react";
import InputError from "./InputError";

export default function SelectInput({
  name,
  className = "",
  noFormik, // this might be temporary, added cuz there are forms that use 'name' without formik
  onChange,
  disabled,
  ...props
}) {
  if (name && !noFormik) {
    return (
      <Field name={name}>
        {({ field, meta, form }) => (
          <div className={className}>
            <Input
              {...props}
              {...field}
              error={meta.error}
              touched={meta.touched}
              disabled={disabled || form.isSubmitting}
              onChange={(opt) => {
                form?.setFieldValue(field.name, opt.value);
                onChange?.(opt);
              }}
            />
            <InputError name={name} />
          </div>
        )}
      </Field>
    );
  }
  return <Input {...props} className={className} onChange={onChange} />;
}

function Input({
  options = [],
  label,
  onChange,
  small,

  className,
  value,
  defaultValue,
  hFull,
  disabled,
  padding = null,
  textLeft = false,
  ...rest
}) {
  const [selectedOpt, setSelectedOpt] = useState();

  return (
    <div className={className}>
      {label && (
        <div
          htmlFor={label}
          className="text-sm text-dgray-700 font-medium inline-block mb-1.5 w-full"
        >
          {label}
        </div>
      )}
      <Select
        value={value ? findMatchingValueOption(value, options) : selectedOpt}
        options={options}
        styles={getCustomStyles(small, hFull, padding, textLeft)}
        defaultValue={
          defaultValue
            ? defaultValue.value
              ? defaultValue
              : findMatchingValueOption(defaultValue, options)
            : undefined
        }
        onChange={(opt) => {
          onChange?.(opt);
          setSelectedOpt(opt);
          return opt;
        }}
        isDisabled={disabled}
        {...rest}
      />
    </div>
  );
}

function findMatchingValueOption(value, options) {
  return options.find((opt) => opt.value === value || opt.label === value);
}

const getCustomStyles = (small, hFull, padding, textLeft) => ({
  valueContainer: (styles) => ({
    ...styles,
    padding: padding ? padding :  "6.5px 14px",
    height: hFull ? "100%" : "auto",
    textAlign: textLeft ? 'left' : styles.textAlign
  }),
  control: (styles) => ({
    ...styles,
    border: "1px solid #D0D5DD",
    padding: "0",
    borderRadius: "8px",
    outline: "none",
    fontSize: small ? "14px" : "16px",
    boxShadow: "none",
    ":hover": {
      borderColor: "#D0D5DD",
    },
    ":focus": {
      borderColor: "#FF692E",
    },
    height: hFull ? "100%" : "auto",
  }),

  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    color: "#101828",
    outline: "none",
    border: "none",
    fontSize: "14px",
    backgroundColor: isSelected ? "#F9FAFB" : isFocused ? "#F9FAFB" : "none",
    ":hover": {
      backgroundColor: "#F9FAFB",
    },
  }),
  input: (styles) => ({
    ...styles,
    padding: "0px",
    margin: "0px",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: small ? "14px" : "16px",
    color: "#98A2B3",
    margin: "0px",
  }),
  indicatorSeparator: (styles) => ({ display: "none" }),
  menuPortal: (base) => ({ ...base, zIndex: 99999 }),
});
