import CenterContainer from "components/common/Container/Center";
import HostList from "../HostList";

export default function HomePageHosts() {
  return (
    <CenterContainer className="py-24 md:py-12">
      <div className="text-center mb-16">
        <h2 className="text-4xl md:text-3xl font-semibold mb-5">
          Connecting with hosts
        </h2>
        <p className="text-dgray-500 mb-32 md:mb-14 text-xl md:text-lg">
          Meet our hosts with solid professional and life experiences and
          interesting backgrounds.
        </p>
      </div>
      <HostList />
    </CenterContainer>
  );
}
