import axios from "utils/axios";
import { CART_COUNT_SUCCESS } from "actions/types";
import { setAlert } from "actions/alert";

//add data to cart
export const addSessionToCart =
  (course_id, order_session_detail) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        "/api/learner/order/add-to-cart",
        { course_id, order_session_detail },
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: CART_COUNT_SUCCESS,
          payload: {
            data: res.data.response,
          },
        });
        return { status: true, msg: res.data.message };
      } else {
        return {
          status: false,
          msg: res.data.message,
          type:res.data?.errors?.type
        };
      }
    } catch (err) {
      return {
        status: false,
        msg: "Something went wrong, please try again.",
      };
    }
  };

//get cart data
export const getCartDetail = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/learner/order/cart-details`, config);

    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      return {
        status: false,
      };
    }
  } catch (err) {
    return {
      status: false,
    };
  }
};

//get client token for braintree payment
export const getClientToken = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/learner/order/get-client-token`, config);

    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      return {
        status: false,
      };
    }
  } catch (err) {
    return {
      status: false,
    };
  }
};

//get payment intent for stripe payment
export const initializePayment = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/learner/order/initialize-payment`,
      config
    );

    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      return {
        status: false,
        msg:res.data.message
      };
    }
  } catch (err) {
    return {
      status: false,
    };
  }
};

export const initializeSubscription = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/learner/order/initialize-subscription`,
      config
    );

    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      return {
        status: false,
      };
    }
  } catch (err) {
    return {
      status: false,
    };
  }
};

export const getInvoice = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/learner/order/latest-invoice`, config);

    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      return {
        status: false,
      };
    }
  } catch (err) {
    return {
      status: false,
    };
  }
};

export const cancelSubscription = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.delete(
      `/api/learner/order/cancel-subscription`,
      config
    );

    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      dispatch(setAlert(res.data.message, "danger"));
      return {
        status: false,
        message: res.data.message,
      };
    }
  } catch (err) {
    return {
      status: false,
    };
  }
};

//add data to cart
export const payNow =
  (orderDetail, isFree = false) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        orderDetail: orderDetail.order,
        is_free: isFree ? 1 : 0, // 1 indicates free
        token: orderDetail.token,
      });

      const res = await axios.post("/api/learner/order/pay-now", body, config);
      if (res.data.status === true) {
        dispatch({
          type: CART_COUNT_SUCCESS,
          payload: {
            data: 0,
          },
        });
        return { status: true, msg: res.data.message };
      } else {
        return {
          status: false,
          msg: res.data.message,
        };
      }
    } catch (err) {
      return {
        status: false,
        msg: "Something went wrong, please try again.",
      };
    }
  };
//get cart data
export const RemoveFromCart = (item) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/learner/order/cart/remove`,
      { item },
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: CART_COUNT_SUCCESS,
        payload: {
          data: res.data.response,
        },
      });
      return { status: true, data: res.data.response };
    } else {
      return {
        status: false,
      };
    }
  } catch (err) {
    return {
      status: false,
    };
  }
};

//reschedule session
export const rescheduleSession =
  (course_id, order_session_detail, alreadyScheduledId) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        "/api/learner/order/reschedule",
        { course_id, order_session_detail, alreadyScheduledId },
        config
      );
      if (res.data.status === true) {
        return { status: true, msg: res.data.message };
      } else {
        return {
          status: false,
          msg: res.data.message,
        };
      }
    } catch (err) {
      return {
        status: false,
        msg: "Something went wrong, please try again.",
      };
    }
  };
