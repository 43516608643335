import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";

import { getStatistics } from "actions/front/mentorDashboard";
import { Link } from "react-router-dom";
import { getFollowingList, getFollowerList } from "actions/front/dingdong";

const Statistics = ({ getStatistics, statistics }) => {
  const [followingList, setFollowingList] = useState({
    loading: false,
    modalOpen: false,
    data: [],
    error: null,
  });
  const [followerList, setFollowerList] = useState({
    loading: false,
    modalOpen: false,
    data: [],
    error: null,
  });

  const fetchFollowing = async (e) => {
    try {
      setFollowingList({
        ...followingList,
        modalOpen: true,
        loading: true,
      });
      const list = await getFollowingList();
      setFollowingList({
        ...followingList,
        modalOpen: true,
        loading: false,
        data: list,
      });
    } catch (e) {
      setFollowingList({
        ...followingList,
        error: e,
        loading: false,
      });
    }
  };

  const toggleFollowingModal = () => {
    setFollowingList({
      ...followingList,
      modalOpen: false
    });
  }

  const closeFollowing = async () => {
    setFollowingList({
      ...followingList,
      modalOpen: false,
    });
  };

  const fetchFollowers = async (e) => {
    try {
      setFollowerList({
        ...followerList,
        modalOpen: true,
        loading: true,
      });
      const list = await getFollowerList();
      setFollowerList({
        ...followerList,
        modalOpen: true,
        loading: false,
        data: list,
      });
    } catch (e) {
      setFollowerList({
        ...followerList,
        error: e,
        loading: false,
      });
    }
  };

  const toggleFollowersModal = () => {
    setFollowerList({
      ...followerList,
      modalOpen: false
    });
  }


  const closeFollowers = async (e) => {
    setFollowerList({
      ...followerList,
      modalOpen: false,
    });
  };

  useMemo(() => {
    getStatistics();
  }, []);

  return (
    <div>
      <div className="row mb-4">
        <div className="col-md-12">
          <h3>Metrics</h3>
          <div className="row ml-0 mt-3">
            <div className="col-md-6">
              <div className="row">
                <div className="info-row text-center">
                  <span>
                    <i className="fa fa-book box-icon bg-theme" />
                    <Link to="/dashboard/session-management">
                      <strong className="text-muted ml-3">
                        Total Sessions Offered :
                        {statistics.loading ? (
                          <Spinner />
                        ) : statistics.data ? (
                          statistics.data.courseOffered
                        ) : (
                          "-"
                        )}
                      </strong>
                    </Link>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="info-row text-center">
                  <span>
                    <i className="fa fa-calendar box-icon bg-theme" />
                    <Link to="/dashboard/session-management">
                      <strong className="text-muted ml-3">
                        Bookable Interactions :{" "}
                        {statistics.loading ? (
                          <Spinner />
                        ) : statistics.loading ? (
                          <Spinner />
                        ) : statistics.data ? (
                          statistics.data.upcomingSessionsCount
                        ) : (
                          "-"
                        )}
                      </strong>
                    </Link>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="info-row text-center">
                  <span>
                    <i className="fa fa-credit-card box-icon bg-theme" />
                    <strong className="text-muted ml-3">
                      Total Spent :{" "}
                      {statistics.loading ? (
                        <Spinner />
                      ) : statistics.data ? (
                        "$" + statistics.data.totalSpent.toFixed(2)
                      ) : (
                        "-"
                      )}
                    </strong>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="info-row text-center">
                  <span>
                    <i className="fa fa-money box-icon bg-theme" />
                    <strong className="text-muted ml-3">
                      Total Earned :{" "}
                      {statistics.loading ? (
                        <Spinner />
                      ) : statistics.loading ? (
                        <Spinner />
                      ) : statistics.data ? (
                        "$" + statistics.data.totalEarned.toFixed(2)
                      ) : (
                        "-"
                      )}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="info-row text-center">
                  <span>
                    <i className="fa fa-user box-icon bg-theme" />
                    <strong className="text-muted ml-4">
                      Total people interacted with :{" "}
                      {statistics.loading ? (
                        <Spinner />
                      ) : statistics.data ? (
                        statistics.data.totalLearners
                      ) : (
                        "-"
                      )}
                    </strong>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="info-row text-center">
                  <span>
                    <i className="fa fa-user-plus box-icon bg-theme" />
                    <strong className="text-muted ml-3">
                      Number of people you have DingDinged :{" "}
                      {statistics.loading ? (
                        <Spinner />
                      ) : statistics.data ? (
                        <button className="btn-pure" onClick={fetchFollowing}>
                          {statistics.data.followingCount}
                        </button>
                      ) : (
                        "-"
                      )}
                    </strong>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="info-row text-center">
                  <span>
                    <i className="fa fa-users box-icon bg-theme" />
                    <strong className="text-muted ml-3">
                      Number of people that have DingDinged you :{" "}
                      {statistics.loading ? (
                        <Spinner />
                      ) : statistics.data ? (
                        <button className="btn-pure" onClick={fetchFollowers}>
                          {statistics.data.followersCount}
                        </button>
                      ) : (
                        "-"
                      )}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={followingList.modalOpen} toggle={toggleFollowingModal} onClosed={closeFollowing}>
        <ModalHeader className="modal-title">
          Dingdinged Users
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
              </thead>
              <tbody>
              {followingList.data.map((user, idx) => (
                <tr>
                  <td>{idx + 1}</td>
                  <td><Link to={`/profile/${user.username}`}>{user.fullName}</Link></td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            className="m-1"
            onClick={(e) => closeFollowing(e)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={followerList.modalOpen} toggle={toggleFollowersModal} onClosed={closeFollowers}>
        <ModalHeader className="modal-title">
          Users Dingdinged You
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
              </thead>
              <tbody>
              {followerList.data.map((user, idx) => (
                <tr>
                  <td>{idx + 1}</td>
                  <td><Link to={`/profile/${user.username}`}>{user.fullName}</Link></td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            className="m-1"
            onClick={(e) => closeFollowers(e)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
};

const mapStateToProps = (state) => ({
  statistics: state.statistics,
});

export default connect(mapStateToProps, {
  getStatistics,
})(Statistics);
