import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { ReactComponent as LogoutIcon } from "assets/images/icons/logout.svg";
import { logout } from "actions/auth";
import ddnLogoDark from "assets/images/ddn_logo_dark.svg";
import { isUserHost } from "utils/isUserHost";
import getUserAvatar from "utils/getUserAvatar";
import Header from "../Layout/Header";
import getMenuItems, { getBottomMenuItems } from "utils/getMenuItems";
import Moment from "react-moment";
import moment from "moment";
import { HOST_APPLICATION_STATUS } from "constants";

const MyaccountSidebar = ({
  auth: { isAuthenticated, loading, user },
  location,
}) => {
  const dispatch = useDispatch();
  const [showStatusCard, setShowStatusCard] = useState(false);
  useEffect(() => {
    const localValue = localStorage.getItem(getStatusCardLocalKey(user));
    if (!localValue || localValue === LOCAL_DISMISSED_TYPE.StayOn) {
      setShowStatusCard(true);
    }
  }, [user]);
  const learnerSuggestion = (
    <div className="cuetpoints">
      <b>
        Top tips and reminders for
        <span style={{ color: "blue" }}> Learners</span>
      </b>
      <ul className="tip">
        <li className="text-muted">
          Please check in to your MyDDN profile under{" "}
          <span className="orange-text">Session Management</span> at least five
          minutes before the start of the interaction with your mentor. Your
          mentor will generate the <br />
          meeting URL. ​
        </li>
        <li className="text-muted">Please be on time to sessions.</li>
        <li className="text-muted">
          Keep your profile up to date. It helps your mentor interact with you
          better​
        </li>
      </ul>
      <b>
        <p>
          ​Should you have any questions please email us at{" "}
          <Link to="#">info@dingdongnow.com</Link> or{" "}
          <Link to="/contact">contact us</Link>
        </p>
      </b>
    </div>
  );

  const UserSuggestion = (
    <div className="cuetpoints">
      <b>
        Top tips and reminders for
        <span style={{ color: "blue" }}> Mentors​</span>
      </b>
      <ul className="tip">
        <li className="text-muted">
          Please check in to your MyDDN profile under{" "}
          <span className="orange-text">Session Management</span> at least five
          minutes before the start of the interaction with learner to generate
          the meeting URL. It is your duty to do so
        </li>
        <li className="text-muted">
          Always ensure your{" "}
          <span className="orange-text">
            availability for listed sessions is
          </span>{" "}
          accurate
        </li>
        <li className="text-muted">Please be on time to sessions.</li>
        <li className="text-muted">
          Keep your profile up to date. It is reflected in{" "}
          <span className="orange-text">your public profile.</span> It is
          important that is always up to date
        </li>
      </ul>
      <b>
        <p>
          ​Should you have any questions please email us at{" "}
          <Link to="#">info@dingdongnow.com</Link> or{" "}
          <Link to="/contact">contact us</Link>
        </p>
      </b>
    </div>
  );

  const [dismissedStatusCard, setDismissedStatusCard] = useState(false);

  return (
    <>
      <Header className="hidden lg:block border-b border-dgray-200" />
      <div className="lg:hidden xl:w-72 w-80 shadow fixed inset-y-0 left-0 bg-white flex flex-col justify-between overflow-y-scroll">
        <div className="px-4">
          <div className="flex items-center flex-shrink-0 p-4">
            <Link to={"/"}>
              <img src={ddnLogoDark} alt="DDN Logo" />
            </Link>
          </div>
          <div
            style={{ flex: 1, height: "1px" }}
            className="bg-dgray-200 w-full"
          />
          <div className="flex gap-3 mt-5 form-group">
            <img
              className="h-12 w-12 shrink-0 rounded-full"
              src={getUserAvatar(user.avatar)}
            />
            <div className="flex flex-col justify-center">
              <label className="text-dgray-700 text-base font-medium">
                {user && user.first_name + " " + user.last_name}
              </label>
              <label className="text-dgray-500 text-base">
                You are a <>{user.userType === 3 ? "host" : "student"}</> since{" "}
                <Moment format="YYYY">{Number(user.created_at)}</Moment>
              </label>
            </div>
          </div>

          <ul className="mt-4 space-y-1">
            {getMenuItems(user).map(({ icon, label, href }, index) => (
              <li
                key={index}
                className={`flex w-full justify-between cursor-pointer items-center rounded
                ${
                  location.pathname === href
                    ? "text-primary-700 bg-primary-50"
                    : "text-dgray-700 hover:text-gray-500"
                }
              `}
              >
                <Link to={href} className="flex items-center py-2 px-3 w-full">
                  <div
                    className={
                      location.pathname === href
                        ? "text-primary-600"
                        : "text-dgray-500"
                    }
                  >
                    {icon}
                  </div>
                  <span className="font-medium ml-2">{label}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="px-4 mb-4">
          <ul className="mt-4 space-y-1">
            {showStatusCard &&
              (isUserHost(user) ? (
                <StatusCard
                  dismissed={dismissedStatusCard}
                  title={STATUS_CARD_CONTENT.Approved.title}
                  bottomContent={
                    <button
                      className="text-sm font-medium text-primary-500 mr-3"
                      onClick={() => {
                        setDismissedStatusLocal(
                          LOCAL_DISMISSED_TYPE.DontReapper,
                          user
                        );
                        setDismissedStatusCard(true);
                      }}
                    >
                      Dismiss
                    </button>
                  }
                />
              ) : user.free_trial_remaining_percent ? (
                <StatusCard
                  dismissed={dismissedStatusCard}
                  topContent={
                    <ProgressCircle
                      percentage={user.free_trial_remaining_percent}
                    />
                  }
                  title={STATUS_CARD_CONTENT.FreeTrial.getTitle(
                    user.premium_ends_at * 1000
                  )}
                  description={`You used ${user.free_trial_remaining_percent}% of your free trial duration.`}
                  bottomContent={
                    <>
                      <button
                        className="text-sm font-medium text-primary-500 mr-3"
                        onClick={() => {
                          setDismissedStatusLocal(
                            LOCAL_DISMISSED_TYPE.ReappearAfterLogin,
                            user
                          );
                          setDismissedStatusCard(true);
                        }}
                      >
                        Dismiss
                      </button>
                      <Link
                        to="/dashboard/balance"
                        className="text-sm font-medium text-primary-700"
                      >
                        Purchase Now
                      </Link>
                    </>
                  }
                />
              ) : user.host_application?.status ===
                HOST_APPLICATION_STATUS.SUBMITTED ? (
                <StatusCard
                  dismissed={dismissedStatusCard}
                  title={
                    statusCardHostContent[user.host_application?.status]?.title
                  }
                  description={
                    statusCardHostContent[user.host_application?.status]
                      ?.description
                  }
                />
              ) : user.host_application?.status ===
                HOST_APPLICATION_STATUS.ACCEPTED ? (
                <StatusCard
                  dismissed={dismissedStatusCard}
                  title={STATUS_CARD_CONTENT.Approved.title}
                  description={STATUS_CARD_CONTENT.Approved.description}
                  bottomContent={
                    <button
                      className="text-sm font-medium text-primary-500 mr-3"
                      onClick={() => {
                        setDismissedStatusLocal(
                          LOCAL_DISMISSED_TYPE.DontReapper,
                          user
                        );
                        setDismissedStatusCard(true);
                      }}
                    >
                      Dismiss
                    </button>
                  }
                />
              ) : user.is_premium ? (
                <StatusCard
                  dismissed={dismissedStatusCard}
                  title={STATUS_CARD_CONTENT.Subscribed.title}
                  bottomContent={
                    <>
                      <button
                        className="text-sm font-medium text-primary-500 mr-3"
                        onClick={() => {
                          setDismissedStatusLocal(
                            LOCAL_DISMISSED_TYPE.ReappearAfterLogin,
                            user
                          );
                          setDismissedStatusCard(true);
                        }}
                      >
                        Dismiss
                      </button>
                      {/* <Link
                        to="/dashboard/balance"
                        className="text-sm font-medium text-primary-700 "
                      >
                        Cancel Subscription
                      </Link> */}
                    </>
                  }
                />
              ) : user?.userType == 2 &&
                user?.subscriptions?.slice(-1)?.[0]?.status == "violation" ? (
                <StatusCard
                  dismissed={dismissedStatusCard}
                  title={STATUS_CARD_CONTENT.RevokedMember.title}
                  bottomContent={
                    <>
                      <button
                        className="text-sm font-medium text-primary-500 mr-3"
                        onClick={() => {
                          setDismissedStatusLocal(
                            LOCAL_DISMISSED_TYPE.ReappearAfterLogin,
                            user
                          );
                          setDismissedStatusCard(true);
                        }}
                      >
                        Dismiss
                      </button>
                      <Link
                        to="/dashboard/balance"
                        className="text-sm font-medium text-primary-700 "
                      >
                        Purchase Now
                      </Link>
                    </>
                  }
                />
              ) : user?.userType == 2 &&
                user?.subscriptions?.slice(-1)?.[0]?.status == "ended" &&
                !user?.subscriptions?.slice(-1)?.[0]?.ismonthly ? (
                <StatusCard
                  dismissed={dismissedStatusCard}
                  title={STATUS_CARD_CONTENT.PassExhaust.title}
                  bottomContent={
                    <>
                      <button
                        className="text-sm font-medium text-primary-500 mr-3"
                        onClick={() => {
                          setDismissedStatusLocal(
                            LOCAL_DISMISSED_TYPE.ReappearAfterLogin,
                            user
                          );
                          setDismissedStatusCard(true);
                        }}
                      >
                        Dismiss
                      </button>
                      <Link
                        to="/dashboard/balance"
                        className="text-sm font-medium text-primary-700 "
                      >
                        Purchase Now
                      </Link>
                    </>
                  }
                />
              ) : (
                <StatusCard
                  dismissed={dismissedStatusCard}
                  title={STATUS_CARD_CONTENT.NotSubscribed.title}
                  bottomContent={
                    <>
                      <button
                        className="text-sm font-medium text-primary-500 mr-3"
                        onClick={() => {
                          setDismissedStatusLocal(
                            LOCAL_DISMISSED_TYPE.ReappearAfterLogin,
                            user
                          );
                          setDismissedStatusCard(true);
                        }}
                      >
                        Dismiss
                      </button>
                      <Link
                        to="/dashboard/balance"
                        className="text-sm font-medium text-primary-700 "
                      >
                        Purchase Now
                      </Link>
                    </>
                  }
                />
              ))}
            {/*  */}
            {getBottomMenuItems().map(({ icon, label, href }, index) => (
              <li
                key={index}
                className={`flex w-full justify-between cursor-pointer items-center rounded
                ${
                  location.pathname === href
                    ? "text-primary-700 bg-primary-50"
                    : "text-dgray-700 hover:text-gray-500"
                }
              `}
              >
                <Link to={href} className="flex items-center py-2 px-3 w-full">
                  <div
                    className={
                      location.pathname === href
                        ? "text-primary-600"
                        : "text-dgray-500"
                    }
                  >
                    {icon}
                  </div>
                  <span className="font-medium ml-2">{label}</span>
                </Link>
              </li>
            ))}
            <li
              className="flex w-full justify-between cursor-pointer items-center rounded"
              onClick={() => {
                dispatch(logout());
              }}
            >
              <button
                className="flex items-center py-2 px-3 w-full"
                type="button"
              >
                <div className="text-dgray-500">
                  <LogoutIcon />
                </div>
                <span className="font-medium ml-2">Logout</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errorList: state.frontErrors,
  myAccountLoading: state.myaccount.isSubLoading,
});

export default connect(mapStateToProps, {})(withRouter(MyaccountSidebar));

function StatusCard({
  title,
  description,
  topContent,
  bottomContent,
  dismissed = false,
}) {
  let titleIsBool = Array.isArray(title);
  let boxTextMiddleStyle = { boxSizing: "border-box", padding: "0 2.4rem" };
  return (
    dismissed || (
      <div className="bg-primary-50 rounded-lg text-sm py-5 px-4 mb-8">
        {topContent && <div className="mb-5">{topContent}</div>}
        {titleIsBool ? (
          title.map((item, index) => (
            <div
              className="text-primary-700 font-medium mb-1"
              style={{ textAlign: "center" }}
              key={index}
            >
              {item}
            </div>
          ))
        ) : (
          <div
            className="text-primary-700 font-medium mb-1"
            style={{ textAlign: "center" }}
          >
            {title}
          </div>
        )}
        <p className="text-primary-600">{description}</p>
        {bottomContent && (
          <div
            className="mt-4"
            style={titleIsBool ? { ...boxTextMiddleStyle } : {}}
          >
            {bottomContent}
          </div>
        )}
      </div>
    )
  );
}

const statusCardHostContent = {
  freeTrial: "",
  [HOST_APPLICATION_STATUS.SUBMITTED]: {
    title: "Host application is under review",
    description: (
      <>
        Your application to become a Host is currently under review by our team.
        You will be notified once the review process is complete.
        <div>In the meantime, please enjoy our free trial</div>
      </>
    ),
  },
  [HOST_APPLICATION_STATUS.IN_REVIEW]: {
    title: "Host application is under review",
    description: (
      <>
        Your application to become a Host is currently under review by our team.
        You will be notified once the review process is complete.
        <div>In the meantime, please enjoy our free trial</div>
      </>
    ),
  },
  [HOST_APPLICATION_STATUS.ACCEPTED]: { title: "", description: "" },
  [HOST_APPLICATION_STATUS.REJECTED]: { title: "", description: "" },
};

function ProgressCircle({ percentage }) {
  return (
    <div
      style={{
        width: "58px",
        height: "58px",
        backgroundImage: `
        conic-gradient(
          ${
            percentage === 0
              ? "#FFE6D5 0deg"
              : `${percentage >= 100 ? "#1B998B" : "#FF692E"} 0deg,
                 ${percentage >= 100 ? "#1B998B" : "#FF692E"}
                 ${getDeg(percentage)}`
          },
          #FFE6D5 ${getDeg(percentage)})
        `,
      }}
      className="rounded-full flex justify-center items-center transition-all"
    >
      <div className="h-[48px] w-[48px] rounded-full bg-primary-50 flex justify-center items-center text-primary-700 text-sm font-medium">
        %{percentage.toFixed()}
      </div>
    </div>
  );
}

function getDeg(percentage) {
  return `${(percentage / 100) * 360}deg`;
}

const STATUS_CARD_CONTENT = {
  FreeTrial: {
    getTitle: (date) =>
      `Your Free Trial as a Speaker ends on ${moment(date).format(
        "MMM DD YYYY"
      )}`,
    description: "",
    hideDismiss: false,
    buttonText: "Purchase Now",
    buttonHref: "/dashboard/balance",
  },
  UnderReview: {
    title:
      "Your application to become a Host is currently under review by our team. You will be notified once the review process is complete.",
    description: "In the meantime, please enjoy our free trial",
    hideDismiss: true,
  },
  Approved: {
    title: "Congratulations, your request to become a Host has been approved.",
    description: "",
    hideDismiss: false,
    buttonText: "",
    buttonHref: "/dashboard/balance",
  },
  Subscribed: {
    title: "Your Speaker Pass is now active! Enjoy!",
    description: "",
    hideDismiss: false,
    buttonText: "",
    buttonHref: "/dashboard/balance",
  },
  NotSubscribed: {
    title:
      "Your Free Trial has ended You still can listen in our events as a Listener",
    description: "",
    hideDismiss: false,
    buttonText: "Purchase Now",
    buttonHref: "/dashboard/balance",
  },
  RevokedMember: {
    title: [
      "Sorry , your Monthly Pass is suspended",
      "due to 3 times no show. You can buy a new Monthly Pass or choose other plans.",
    ],
    description: "",
    hideDismiss: false,
    buttonText: "Purchase Now",
    buttonHref: "/dashboard/balance",
  },
  PassExhaust:{
    title: "Your current pass has been fully utilized. To RSVP as a speaker, please purchase a new plan",
    description: "",
    hideDismiss: false,
    buttonText: "Purchase Now",
    buttonHref: "/dashboard/balance",
  }
};

export const LOCAL_DISMISSED_TYPE = {
  DontReapper: "DontReapper",
  ReappearAfterLogin: "ReapperAfterLogin",
  StayOn: "StayOn",
};

export const getStatusCardLocalKey = (user) =>
  `sidebarCardDismissedStatusType-${btoa(user.email)}`;

function setDismissedStatusLocal(dismissType, user) {
  // using user email here to differentiate between users
  localStorage.setItem(getStatusCardLocalKey(user), dismissType);
}
