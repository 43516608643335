import { googleLogin, login } from "actions/auth";
import designScreen from "assets/images/design-screen.png";
import Button from "components/common/form/Button";
import Checkbox from "components/common/form/Checkbox";
import TextInput from "components/common/form/TextInput";
import loginSchema from "utils/schemas/login";
import Header from "views/Front/Layout/Header";
import { Form, Formik } from "formik";
import * as moment from "moment-timezone";
import PropTypes from "prop-types";
import GoogleLogin from "react-google-login";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as GoogleLogo } from "assets/images/google.svg";
import FormError from "components/common/form/FormError";
const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

const Login = ({ login, googleLogin, history, error,isinactive,activateEmail, path, ...props }) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const onGoogleResponse = (res) => {
    googleLogin(
      res.profileObj,
      undefined,
      moment.tz.guess(true),
      history,
      path.prev
    );
  };

  return (
    <>
      <Header />
      <div className="flex md:flex-col flex-row" id="login-area">
        <div className="w-1/2 mx-auto lg:w-full flex flex-col min-h-screen justify-center items-center lg:max-w-lg px-32 xl:px-20 lg:px-16">
          <div className="text-center md:mt-5 mb-8">
            <h1 className="text-3xl 2xl:text-4xl text-dgray-900">
              Welcome Back!
            </h1>
            <h2 className="text-base text-dgray-500">
              Please enter your details to log in.
            </h2>
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={loginSchema}
            onSubmit={async ({ email, password }) => {
              await login(email, password, history, props, path.last);
            }}
          >
            <Form>
              {error && <FormError isinactive={isinactive} activateEmail={activateEmail}>{error}</FormError>}
              <TextInput
                type="text"
                name="email"
                label="Email"
                placeholder="Enter your email"
                className="mb-3"
                wFull
              />
              <TextInput
                type="password"
                name="password"
                label="Password"
                placeholder="&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;"
                wFull
              />
              <div className="flex justify-between mt-5">
                <Checkbox label="Remember me" name="remember" checked={true} />{" "}
                <Link to={"/forgot-password"} className="text-primary-700">
                  Forgot password?
                </Link>
              </div>
              <Button
                type="submit"
                className="text-base text-white mt-3 bg-primary-600"
                wFull
              >
                Sign In
              </Button>
{/*
              <div className="flex flex-row items-center mt-5">
                <div
                  style={{ flex: 1, height: "1px" }}
                  className="bg-dgray-200"
                />
                <label className="text-xs text-dgray-500 mx-8">OR</label>
                <div
                  style={{ flex: 1, height: "1px" }}
                  className="bg-dgray-200"
                />
              </div>

              <GoogleLogin
                clientId={REACT_APP_GOOGLE_CLIENT_ID}
                onSuccess={(e) => onGoogleResponse(e)}
                onFailure={(e) => onGoogleResponse(e)}
                render={(renderProps) => (
                  <Button
                    onClick={renderProps.onClick}
                    type="button"
                    className="text-base mt-3 !p-2.5 border opacity- w-full"
                  >
                    <div className="flex items-center justify-center">
                      <GoogleLogo className="w-6 h-6" />
                      <span className="ml-3">Sign up with Google</span>
                    </div>
                  </Button>
                )}
              />
               */}
              <div className="text-center mt-3">
                <label className="text-dgray-500 text-sm">
                  Don't have an account?{" "}
                  <Link to="/register" className="text-primary-700">
                    Sign up
                  </Link>
                </label>
              </div>
            </Form>
          </Formik>
        </div>
        <div className="md:hidden w-1/2 flex flex-row bg-dgray-100 rounded-l-[80px] pl-20">
          <div className="flex items-center w-full justify-end overflow-hidden">
            <div className="border-4 border-r-0 rounded-l-2xl border-dgray-1000 overflow-hidden lg:-mr-20">
              <img
                src={designScreen}
                alt="Design Screen"
                className="object-contain h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Login.proTypes = {
  login: PropTypes.func.isRequired,
};

export default connect(({ auth, path }) => ({ error: auth.error,isinactive:auth.isinactive,activateEmail:auth.activateEmail, path }), {
  login,
  googleLogin,
})(Login);
