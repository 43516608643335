import Pagination from "../Pagination";

export default function Table({
  columns, // [{ key, label, Content }], Content is optional
  rows, // [{ ... }]
  top,
  className = "",

  page,
  setPage,
  pageSize,
  count,
}) {
  return (
    <div className={`bg-white border rounded-lg overflow-hidden ${className}`}>
      {top && (
        <div className="flex items-center gap-2 py-5 px-6">
          <div className="text-lg font-medium flex-1">{top}</div>
        </div>
      )}
      <div
        className={`overflow-x-auto relative border-dgray-200
        ${top ? "border-t" : ""}
        ${page ? "border-b" : ""}
        `}
      >
        <table className="w-full text-sm text-left">
          <thead className="text-xs text-dgray-500 bg-dgray-50">
            <tr>
              {columns.map((col, i) => (
                <th scope="col" className="py-3 px-6" key={i}>
                  {col.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr
                className="bg-white border-t border-dgray-200 text-dgray-500"
                key={i}
              >
                {columns.map((col, j) => (
                  <td className="py-4 px-6" key={j}>
                    {col.Content ? <col.Content data={row} /> : row[col.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {page && (
        <Pagination
          className="py-3 px-5"
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          count={count}
        />
      )}
    </div>
  );
}
