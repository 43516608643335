import mockEventImg from "assets/images/mock-event.jpg";
import CenterContainer from "components/common/Container/Center";
import NestedPill from "components/common/NestedPill";
import Pill from "components/common/Pill";
import TabPicker from "components/common/TabPicker";
import { SESSION_TYPE } from "constants";
import { connect } from "react-redux";
import moment from "moment";
import getUserAvatar from "utils/getUserAvatar";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import FeedSidebar from "./Sidebar";
import { useEffect, useState } from "react";
import Spinner from "views/Spinner";
import { formatDateShort } from "utils/formatDate";
import { NOTIFICATION_EVENT } from "constants";
import NotificationEventItem from "components/notifications/NotificationEventItem";

export default function Feed() {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [latestEvents, setLatestEvents] = useState();
  const [olderEvents, setOlderEvents] = useState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // send request
    setLatestEvents(fakeLatestEvents);
    setOlderEvents(fakeOlderEvents);
    setLoading(false);
  }, [selectedTab]);

  const onLoadMore = () => {};

  return (
    <>
      <Header />
      <CenterContainer>
        <div className="py-24 md:py-16">
          <h1 className="text-dgray-900 text-5xl md:text-4xl font-semibold text-center mb-16">
            My Feed
          </h1>
          <TabPicker onChange={setSelectedTab} items={tabs} />
        </div>
        <div className="flex items-start lg:flex-col gap-12 md:gap-8">
          <div className="flex-1">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <Separator label="Latest" className="mb-12" />
                <div className="divide-y">
                  {latestEvents?.map((event) => (
                    <NotificationEventItem event={event} />
                  ))}
                </div>
                <Separator label="Older" className="my-12" />
                <div className="divide-y">
                  {olderEvents?.map((event) => (
                    <NotificationEventItem event={event} />
                  ))}
                </div>
              </>
            )}
          </div>
          <FeedSidebar />
        </div>
        <div className="text-center border-t border-dgray-200 pt-5 mt-16 mb-24 md:mb-16">
          <button
            className="text-sm text-dgray-500 font-medium py-2.5 px-4"
            onClick={onLoadMore}
          >
            Load More
          </button>
        </div>
      </CenterContainer>
      <Footer />
    </>
  );
}

const tabs = [
  { label: "All Updates", value: false },
  { label: "Upcoming Public Events", value: "public" },
  { label: "Upcoming One on One sessions", value: "one-on-one" },
  { label: "Hosts’ Blog ", value: "blog" },
];

function Separator({ label, className = "" }) {
  return (
    <div className={`flex items-center gap-8 ${className}`}>
      <div className="h-px bg-dgray-200 flex-1" />
      <div className="uppercase text-dgray-500 text-xs font-semibold">
        {label}
      </div>
      <div className="h-px bg-dgray-200 flex-1" />
    </div>
  );
}

const fakeEventCreate = {
  mentor_name: "Olivia Rhye",
  mentor_avatar: "",
  created_at: new Date().toISOString(),
  type: NOTIFICATION_EVENT.EVENT_CREATE,
  data: {
    name: "Example Event Name",
    date: new Date().toISOString(),
    category: "5fff08662fc7be00539299a9",
    timezone: "GMT +3",
  },
};

const fakeBlogCreate = {
  mentor_name: "Olivia Rhye",
  mentor_avatar: "",
  created_at: new Date().toISOString(),
  type: NOTIFICATION_EVENT.BLOG_CREATE,
  data: {
    name: "Example Blog Name",
    category: "5fff08662fc7be00539299a9",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id.",
  },
};

const fakeLatestEvents = [fakeEventCreate, fakeBlogCreate];
const fakeOlderEvents = [fakeEventCreate, fakeBlogCreate];
