import axios from "utils/axios";
import { NOTIFICATION_UPDATED } from "actions/types";

export const getNotification = (filterParams) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/front/notification?limit=${filterParams.limit}&page=${filterParams.page}&orderBy=${filterParams.orderBy}`
    );

    if (res.status) {
        dispatch({
            type: NOTIFICATION_UPDATED,
            payload: res.data.response.data,
        })
    }
    return { status: true, data: res.data.response };
  } catch (error) {
    return { status: false };
  }
};

export const markNotificationRead = () => async (dispatch) => {
  try {
    const res = await axios.put(
      `/api/front/notification/mark-read`
    );
    return { status: true, data: res.data.response };
  } catch (error) {
    return { status: false };
  }
};
