import React from "react";

const NoRecord = ({ msg = "No Records Found", className = null }) => {
  return (
    <div className="row" id="noData">
      <div className="col-md-12">
        <div className={"no-records " + (className ?? "")}>
          <h4 className="help-text">{msg}</h4>
        </div>
      </div>
    </div>
  );
};

export default NoRecord;
