/* eslint-disable */
import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  edit,
  getCourseSubCategoryById,
  getCourseCategories,
} from "actions/admin/coursesubcategory";

import { REMOVE_ERRORS } from "actions/types";

import Spinner from "views/Spinner";
import Errors from "views/Admin/Layout/Errors";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";

const EditCourseSubCategory = ({
  getCourseSubCategoryById,
  edit,
  coursesubcategory: { currentCourseSubCategory, loading },
  history,
  match,
  errorList,
  getCourseCategories,
}) => {
  /***********************get all categories ********************/
  const [categoryList, setCategoryList] = useState([]);
  useMemo(() => {
    getCourseCategories().then((res) => {
      setCategoryList(res);
    });
  }, []);

  /**************** dispatch to remove error *********************/
  const dispatch = useDispatch();
  /********************Form data*****************/
  const [initallFormData, setInitallFormData] = useState({
    category_id: "",
    subcategory_name: "",
    subcategory_description: "",
  });

  const [formData, setFormData] = useState(initallFormData);

  /********************Use effect to get current subcategory*****************/
  useEffect(() => {
    if (match.params.coursesubcategory_id)
      getCourseSubCategoryById(match.params.coursesubcategory_id);
  }, [match.params.coursesubcategory_id]);

  /********************update current category detail*****************/
  useEffect(() => {
    if (
      currentCourseSubCategory != null &&
      Object.keys(currentCourseSubCategory).length > 0
    ) {
      let category = currentCourseSubCategory;

      let newData = {
        ...initallFormData,
        category_id: category.category_id,
        subcategory_name: category.name,
        subcategory_description: category.description,
      };
      setInitallFormData(newData);
      setFormData(newData);
    }
  }, [currentCourseSubCategory]);

  /********************Expolde form data*****************/
  const { category_id, subcategory_name, subcategory_description } = formData;

  /********************On change event*****************/
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };

  /********************On Submit event*****************/
  const onSubmit = (e) => {
    e.preventDefault();
    edit(formData, history, match.params.coursesubcategory_id);
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card>
              <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="category_id">
                      Category <span>*</span>
                    </Label>
                    <Input
                      type="select"
                      id="category_id"
                      name="category_id"
                      maxLength="25"
                      value={category_id}
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.category_id ? true : false}
                    >
                      <option value="">Please Select</option>
                      {categoryList
                        ? categoryList.map((fieldData, index) => {
                            return (
                              <option key={index} value={fieldData._id}>
                                {fieldData.name}
                              </option>
                            );
                          })
                        : ""}
                    </Input>
                    <Errors current_key="category_id" key="category_id" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="subcategory_name">
                      Name<span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="subcategory_name"
                      name="subcategory_name"
                      maxLength="25"
                      value={subcategory_name}
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.subcategory_name ? true : false}
                    />
                    <Errors
                      current_key="subcategory_name"
                      key="subcategory_name"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="subcategory_description">Description</Label>
                    <Input
                      type="textarea"
                      id="subcategory_description"
                      name="subcategory_description"
                      value={subcategory_description}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/course-subcategory">
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

EditCourseSubCategory.propTypes = {
  getCourseSubCategoryById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  coursesubcategory: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  getCourseCategories: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  coursesubcategory: state.coursesubcategory,
  errorList: state.errors.errorList,
});

export default connect(mapStateToProps, {
  getCourseSubCategoryById,
  edit,
  getCourseCategories,
})(EditCourseSubCategory);
