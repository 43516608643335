import { getAllCourses } from "actions/front/common/course";
import { getUpcomingSessions } from "actions/front/educator/courses";
import Button from "components/common/Button";
import SelectInput from "components/common/form/SelectInput";
import TextInput from "components/common/form/TextInput";
import NotFoundText from "components/common/NotFoundText";
import EventCard from "components/event/EventCard";
import { CATEGORIES, SORTING } from "constants";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Spinner from "views/Spinner";

function DashboardMyEventsUpcoming({ auth }) {
  const [selectedCategory, setSelectedCategory] = useState();
  const [upcomingEvents, setUpcomingEvents] = useState();
  const [isLoading, setLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState();

  const fetchData = (f = {}) => {
    setLoading(true);
    getAllCourses(
      {
        ...filters,
        query,
        sortBy: sortBy?.value,
        ...f,
      },
      auth.user._id,
      "0",
      "2",
      1
    ).then((res) => {
      if (res.data?.data) {
        setUpcomingEvents(res.data?.data);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    setFilters((f) => ({
      ...f,
      custom_filters: {
        ...f.custom_filters,
        course_category: selectedCategory,
      },
    }));
  }, [selectedCategory, sortBy]);

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    fetchData();
  }, []);


  const onSearch = () => {
    fetchData();
  };
  const onClear = () => {
    setQuery("");
    fetchData({ query: "" });
  };

  return (
    <>
      <div className="flex justify-between md:flex-col gap-4 md:gap-12">
        <div className="flex gap-3">
          <div className="flex-1">
            <TextInput
              className="w-full"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search by event name"
            />
          </div>
          <Button className="md:hidden" small flat onClick={onClear}>
            Clear
          </Button>
          <Button className="md:hidden" small onClick={onSearch}>
            Search
          </Button>
        </div>

        <div className="flex gap-3 md:flex-col">
          <div className="md:flex-1">
            <SelectInput
              className="w-48 md:w-full"
              value={selectedCategory}
              onChange={setSelectedCategory}
              options={Object.entries(CATEGORIES).map(
                ([cat, { label: catText }]) => ({ label: catText, value: cat })
              )}
            />
          </div>
          <div className="md:flex-1">
            <SelectInput
              className="w-48 md:w-full"
              value={sortBy}
              onChange={setSortBy}
              options={sortOptions}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-1 gap-8 pt-12 pb-36">
        {isLoading ? (
          <div className="col-span-2">
            <Spinner />
          </div>
        ) : upcomingEvents?.length > 0 ? (
          upcomingEvents.map((event, i) => (
            <EventCard
              pillText="Upcoming"
              full
              data={event}
              canViewAttendeeList
              key={event._id._id}
            />
          ))
        ) : (
          <NotFoundText className="py-10 col-span-2">
            No upcoming events
          </NotFoundText>
        )}
      </div>
    </>
  );
}

const sortOptions = [
  { label: "Alphabetical (A-Z)", value: SORTING.ALPHABET_ASC },
  { label: "Alphabetical (Z-A)", value: SORTING.ALPHABET_DESC },
  { label: "Date: Newest First", value: SORTING.DATE_DESC },
  { label: "Date: Oldest First", value: SORTING.DATE_ASC },
  {
    label: "Rating: Low to High",
    value: SORTING.RATING_LOW_HIGH,
  },
  {
    label: "Rating: High to Low ",
    value: SORTING.RATING_HIGH_LOW,
  },
];

const initialFilters = {
  limit: 999,
  page: 1,
  orderBy: "start_at",
  ascending: "desc",
  query: "",
  custom_filters: "",
  activeResultsFor: 2,
};

export default connect(({ auth }) => ({ auth }))(DashboardMyEventsUpcoming);
