import React, { useState, useMemo } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { USER_TYPE } from "constants/index";

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, loading, user },
  forAdmin,
  isForEducator,
  isForLearner,
  name,
  isforall,
  ...rest
}) => {
  return (
    !loading &&
    isAuthenticated != null && (
      <Route
        {...rest}
        render={(props) => {
          if (!isAuthenticated && !loading) {
            return <Redirect to="/login" />;
          } else {
            if (forAdmin) {
              if (user !== null) {
                if (user.isAdmin) return <Component {...props} />;
                return <Redirect to="/" />;
              }
              return <Redirect to="/login" />;
            } else if (isForEducator) {
              if (user !== null) {
                if (user.login_role === USER_TYPE["EDUCATOR"])
                  return <Component {...props} />;
                return <Redirect to="/" />;
              }
              return <Redirect to="/login" />;
            } else if (isForLearner) {
              if (user !== null) {
                if (user.login_role === USER_TYPE["LEARNER"])
                  return <Component {...props} />;
                return <Redirect to="/" />;
              }
              return <Redirect to="/login" />;
            } else {
              if (user !== null) {
                if (user.isAdmin) {
                  return <Redirect to="/admin" />;
                } else {
                  return <Component {...props} name={name} />;
                }
              }
              return <Redirect to="/login" />;
            }
          }
        }}
      />
    )
  );
};

PrivateRoute.defaultProps = {
  forAdmin: false,
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
