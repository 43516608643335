import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { editExpertiseList, getExpertiseList } from "actions/admin/setting";
import Spinner from "views/Spinner";
import Errors from "views/Admin/Layout/Errors";
import { REMOVE_ERRORS } from "actions/types";
import cronParser from "cron-parser";
import cronstrue from "cronstrue";
import Cron, { HEADER } from "react-cron-generator";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const ExpertiseList = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [expertise, setExpertise] = useState();
  const [loading, setLoading] = useState(true);
  const [adding, setAdding] = useState(false);
  const [newExpertise, setNewExpertise] = useState("");

  useEffect(() => {
    getExpertiseList().then((res) => {
      setExpertise(res.data.response);
      setLoading(false);
    });
  }, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };
  const onSubmit = (e) => {
    e.preventDefault();
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="6">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <h4>Host Expertise List</h4>
                <br></br>
                <ul>
                  {expertise.map((item) => (
                    <li key={item}>
                      <div className="flex flex-row justify-between">
                        <span>{item}</span>
                        <div>
                          <Button
                            size="sm"
                            color="danger"
                            type="button"
                            onClick={(e) => {
                              setExpertise((prev) => [
                                ...prev.filter((exp) => exp !== item),
                              ]);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                {adding ? (
                  <li className="flex flex-row justify-between">
                    <Input type="text" placeholder="Expertise Name" onChange={(e) => {
                        setNewExpertise(e.target.value);
                    }} />
                    <Button type="button" onClick={() => {
                        setExpertise(prev => [...prev, newExpertise]);
                        setNewExpertise("");
                        setAdding(false);
                    }}>Add to List</Button>
                  </li>
                ) : null}
                <Button
                  onClick={(e) => {
                    setAdding(true);
                  }}
                >
                  Add New
                </Button>
              </CardBody>
              <CardFooter>
                <Button
                  type="submit"
                  size="sm"
                  color="primary"
                  onClick={() => {
                    editExpertiseList(expertise).then(
                      (res) => {
                        window.alert("Updated!");
                      }
                    );
                  }}
                >
                  <i className="fa fa-dot-circle-o"></i> Update
                </Button>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ExpertiseList);
