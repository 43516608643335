import * as moment from 'moment-timezone';

const DEFAULT_TIMEZONE = "UTC";

const getCurrentTimeZone = function (user) {
  const currentTimeZone =
    moment.tz.guess(true) == "Asia/Calcutta"
      ? "Asia/Kolkata"
      : moment.tz.guess(true);
  let timeZone =
    user && user.timezone
      ? user.timezone == "Asia/Kolkata"
        ? "Asia/Calcutta"
        : user.timezone
      : currentTimeZone;
  return timeZone;
};

const getTimeZone = function (user) {
  let timeZone =
    user && user.timezone
      ? user.timezone == "Asia/Kolkata"
        ? "Asia/Calcutta"
        : user.timezone
      : DEFAULT_TIMEZONE;
  return timeZone;
};

const getDuration = (duration) => {
  let durationType = "";
  let dur = duration.asWeeks();
  if (dur < 1) {
    dur = duration.asDays();
    if (dur < 1) {
      dur = duration.asHours();
      if (dur < 1) {
        dur = duration.asMinutes();
        if (dur < 1) {
          durationType = "now";
        } else {
          if (dur > 1) {
            durationType = "minutes";
          } else {
            durationType = "minute";
          }
        }
      } else {
        if (dur > 1) {
          durationType = "hours";
        } else {
          durationType = "hour";
        }
      }
    } else {
      if (dur > 1) {
        durationType = "days";
      } else {
        durationType = "day";
      }
    }
  } else {
    if (dur > 1) {
      durationType = "weeks";
    } else {
      durationType = "week";
    }
  }

  return {
    duration: Math.ceil(dur),
    unit: durationType
  }
};

const getClosestQuarterTime = () => {
  const roundUpTo = roundTo => x => Math.ceil(x / roundTo) * roundTo;
  const roundUpTo15Minutes = roundUpTo(1000 * 60 * 15);
  const msUp = roundUpTo15Minutes(new Date())
  const date = new Date(msUp)
  date.setSeconds(0);
  return date;
}

export { getCurrentTimeZone, getTimeZone, getDuration, getClosestQuarterTime };
