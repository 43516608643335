import {
  generateMeetingLink,
  getCourseById,
} from "actions/front/educator/courses";
import { ReactComponent as CalendarIcon } from "assets/images/icons/calendar.svg";
import Button from "components/common/Button";
import NestedPill from "components/common/NestedPill";
import Pill from "components/common/Pill";
import { CATEGORIES } from "constants";
import { CHIME_CLIENT_URL, SESSION_TYPE, USER_TYPE } from "constants";
import inRange from "lodash.inrange";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import getEventImage from "utils/getEventImage";
import { getCurrentTimeZone } from "utils/time";
import IconCircle from "views/Front/HomePage/IconCircle";

function UpcomingEventCard({
  data,
  user,
  token,
  getCourseById,
  generateMeetingLink,
}) {
  const [closestSession, setClosestSession] = useState();
  const [generatedMeetingId, setGeneratedMeetingId] = useState();

  useEffect(() => {
    //setClosestSession(getClosestSession(data));
  }, []);

  useEffect(() => {
    setClosestSession(data);
  }, [data]);

  let timeZone = getCurrentTimeZone(user);

  let now = moment(
    moment.unix(moment.utc().unix()).tz(timeZone).format("YYYY-MM-DD HH:mm:ss")
  );

  const [isEventActivating, setEventActivating] = useState(false);

  const activateEvent = () => {
    setEventActivating(true);
    getCourseById(closestSession.course_id).then((res) => {
      const recurringId =
        res.data.session_detail[0].session_group[0]
          .all_session_with_recurring[0]._id;
      generateMeetingLink(recurringId, closestSession.course_id).then((res) => {
        setGeneratedMeetingId(res.meetingId);
        setEventActivating(false);
      });
    });
  };

  return (
    <div className="bg-white rounded-lg border border-dgray-200 w-[665px] md:w-auto">
      <div className="flex items-center gap-3 py-5 px-6 border-b border-inherit">
        <IconCircle>
          <CalendarIcon />
        </IconCircle>
        <div>
          <div className="text-lg font-medium">Upcoming Event</div>
          <div className="text-sm text-dgray-500 md:hidden">
            {data.type === "hosting"
              ? "Please activate your event prior to 5 min start time."
              : "Event will be activated prior to 5 min start time."}
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="flex gap-4">
          <div className="w-60 md:w-40 md:h-40 sm:w-24 sm:h-24 md:mb-4 shrink-0">
            <img
              className="rounded-lg object-cover h-150"
              src={getEventImage(data)}
              alt=""
            />
          </div>
          <div>
            <div className="flex justify-between items-center mb-3">
              <Pill
                text={data.category_name}
                color={CATEGORIES[data.category_name]?.color}
                className="md:hidden"
              />
              <NestedPill
                innerText={
                  data.course_type === SESSION_TYPE.LIVE_EVENT
                    ? "Public event"
                    : "Private event"
                }
                outerText={
                  data.course_type === SESSION_TYPE.LIVE_EVENT &&
                  "Audience seats available"
                }
              />
            </div>
            <div className="text-xl md:text-lg font-semibold mb-2">
              {data._id.name || data.course_name}
            </div>
            <div className="md:hidden">
              <Description data={data} closestSession={closestSession} />
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <Description data={data} closestSession={closestSession} />
        </div>
      </div>
      <div className="py-4 px-6 flex justify-between items-center border-t border-inherit">
        {closestSession?.type === "hosting" ? (
          generatedMeetingId ? (
            <div className="w-full text-right">
              <Button
                small
                target="_blank"
                external={true}
                href={`${CHIME_CLIENT_URL}join/${generatedMeetingId}?token=${token}`}
              >
                Join the event
              </Button>
            </div>
          ) : (
            <>
              {closestSession?.meeting_id ? (
                <>
                  <Button
                    small
                    target="_blank"
                    external={true}
                    href={`${CHIME_CLIENT_URL}join/${closestSession?.meeting_id}?token=${token}`}
                  >
                    Join the event
                  </Button>
                </>
              ) : (
                <>
                  {inRange(
                    moment
                      .duration(
                        now.diff(
                          moment
                            .unix(closestSession?.start_at)
                            .tz(timeZone)
                            .format("YYYY-MM-DD HH:mm:ss")
                        )
                      )
                      .asMinutes(),
                    -5,
                    10
                  ) ? (
                    <>
                      <label className="text-dgray-500 text-sm">
                        Event starting in{" "}
                        {parseInt(
                          moment
                            .duration(
                              now.diff(
                                moment
                                  .unix(closestSession?.start_at)
                                  .tz(timeZone)
                                  .format("YYYY-MM-DD HH:mm:ss")
                              )
                            )
                            .asMinutes() * -1
                        )}{" "}
                        mins
                      </label>
                      <Button
                        small
                        onClick={activateEvent}
                        loading={isEventActivating}
                      >
                        Activate the event
                      </Button>
                    </>
                  ) : (
                    <>
                      <label className="text-dgray-500 text-sm">
                        Event will be activated prior to starting time
                      </label>
                      <Button small disabled className="disabled:bg-dgray-200">
                        Activate the event
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          )
        ) : (
          <>
            {closestSession?.meeting_id ? (
              <>
                <label className="text-dgray-500 text-sm">
                  Event will be activated prior to starting time
                </label>
                <Button
                  small
                  external={true}
                  href={`${CHIME_CLIENT_URL}join/${closestSession?.meeting_id}?token=${token}`}
                >
                  Attend the event
                </Button>
              </>
            ) : (
              <>
                <label className="text-dgray-500 text-sm">
                  Event will be activated prior to starting time
                </label>
                <Button small disabled className="disabled:bg-dgray-200">
                  Attend the event
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function Description({ data, closestSession }) {
  return (
    <>
      <p
        className="text-sm text-gray-500 mb-3 line-clamp-3 whitespace-pre-line"
        dangerouslySetInnerHTML={{ __html: data.course_overview }}
      />
      <time className="text-sm font-semibold">
        {moment
          .unix(closestSession ? closestSession.start_at : data.start_at)
          .format("MMM D, h:mm A")}
      </time>
      <div className="text-sm text-dgray-500">{data.timezone}</div>
    </>
  );
}

export default connect(
  (state) => ({
    isHosting: state.auth.user?.role[0].user_type === USER_TYPE.HOST,
    user: state.auth.user,
    token: state.auth.token,
  }),
  { getCourseById, generateMeetingLink }
)(UpcomingEventCard);
