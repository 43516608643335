import { ErrorMessage, Field } from "formik";
import { useState } from "react";

export default function Textarea({
  name,
  noFormik,
  onChange,
  disabled,
  hideError,
  maxLength,
  ...props
}) {
  if (name && !noFormik) {
    return (
      <Field name={name}>
        {({ field, meta, form }) => (
          <div>
            <TextareaEl
              {...props}
              {...field}
              onChange={(e) => {
                field?.onChange(e);
                onChange?.(e);
              }}
              onBlur={(e) => {
                field?.onBlur(e);
              }}
              error={meta.error}
              disabled={form.isSubmitting || disabled}
              maxLength={maxLength}
            />
            {!hideError && (
              <ErrorMessage name={name}>
                {(msg) => (
                  <div className="text-xs pt-2 pl-0.5 text-red-500">{msg}</div>
                )}
              </ErrorMessage>
            )}
          </div>
        )}
      </Field>
    );
  }
  return (
    <TextareaEl
      {...props}
      onChange={onChange}
      disabled={disabled}
      maxLength={maxLength}
      name={name}
    />
  );
}

function TextareaEl({
  placeholder,
  onChange,
  label,
  className = "",
  maxLength,
  ...rest
}) {
  const [value, setValue] = useState("");

  return (
    <div>
      {label && (
        <label
          htmlFor={label}
          className="text-sm text-dgray-700 font-medium inline-block mb-1.5 w-full"
        >
          {label}
        </label>
      )}
      <textarea
        {...rest}
        id={label}
        className={`leading-none text-dgray-500 focus:outline-none border border-dgray-300 rounded-lg py-2.5 px-3.5 w-full
          ${className}
        `}
        placeholder={placeholder}
        onChange={(e) => {
          if (!maxLength) {
            onChange(e);
            setValue(e.target.value);
          } else if (maxLength - e.target.value.length >= 0) {
            onChange(e);
            setValue(e.target.value);
          }
        }}
        rows={8}
      ></textarea>
      {maxLength && (
        <div className="text-sm text-dgray-500">
          {maxLength - value.length} characters left
        </div>
      )}
    </div>
  );
}
