import { ReactComponent as GlobeIcon } from "assets/images/icons/globe.svg";
import Attendees from "components/common/Attendees";
import NestedPill from "components/common/NestedPill";
import Pill from "components/common/Pill";
import { CATEGORIES } from "constants";
import { SESSION_TYPE } from "constants";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getClosestSession } from "utils/course";
import getEventImage from "utils/getEventImage";
import getUserAvatar from "utils/getUserAvatar";

export default function WeeklyEventCard({ data }) {
  const [closestSession, setClosestSession] = useState();

  useEffect(() => {
    setClosestSession(getClosestSession(data));
  }, []);

  return (
    <div className="bg-white rounded-lg border border-dgray-200 md:w-auto">
      <div className="p-4">
        <div className="flex gap-4">
          <div className="w-60 md:w-40 md:h-40 sm:w-24 sm:h-24 md:mb-4 shrink-0">
            <img
              className="rounded-lg object-cover"
              src={getEventImage(data)}
              alt=""
            />
          </div>
          <div className="w-full">
            <div className="flex justify-between items-center mb-3">
              <Pill
                text={data.category_name}
                color={CATEGORIES[data.category_name]?.color}
                className="md:hidden"
              />
              <NestedPill
                innerText={
                  data.course_type === SESSION_TYPE.LIVE_EVENT
                    ? "Public event"
                    : "Private event"
                }
                outerText={data.isPublic && "Audience seats available"}
              />
            </div>
            <div className="text-xl text-left md:text-lg font-semibold mb-2">
              <Link to={`/session/${data.course_id}`}>{data.course_name}</Link>
            </div>
            <div className="flex w-full justify-between mb-4">
              <div className="flex gap-2.5">
                <img
                  src={getUserAvatar(data.mentor_avatar)}
                  alt=""
                  className="rounded-full w-10 h-10"
                />
                <div>
                  <div className="text-sm font-medium">{data.mentor_name}</div>
                  <div className="text-xs text-dgray-500 flex items-center">
                    <GlobeIcon className="inline mr-1" />
                    <span>{data.host ? data.host?.country : data.country}</span>
                  </div>
                </div>
              </div>
              <div>
                <Attendees
                  // count={closestSession?.session_booking_count}
                  count={3}
                />
              </div>
            </div>

            <div className="border-t text-left">
              <Description data={data} closestSession={closestSession} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Description({ data, closestSession }) {
  return (
    <>
      <time className="text-sm font-semibold">
        {moment.unix(closestSession?.start_at).format("MMM D, h:mm A")}
      </time>
      <div className="text-sm text-dgray-500">{data.timezone}</div>
    </>
  );
}
