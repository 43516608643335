import {
  SETTING_ERROR,
  SETTING_UPDATED,
  GET_SETTING,
  LOADING_ON_SETTINGS_SUBMIT,
} from "actions/types";

const initalState = {
  currentSetting: [],
  loading: true,
  error: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case SETTING_UPDATED:
      return {
        ...state,
        currentSetting: payload.data,
        loading: false,
      };
    case SETTING_ERROR:
      return {
        ...state,
        error: payload.data,
        loading: false,
      };
    case GET_SETTING:
      return {
        ...state,
        currentSetting: payload.data,
        loading: false,
      };
    case LOADING_ON_SETTINGS_SUBMIT:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
