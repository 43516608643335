import { getMycourseSessions } from "actions/front/learner/courses";
import Button from "components/common/Button";
import TextInput from "components/common/form/TextInput";
import NotFoundText from "components/common/NotFoundText";
import Pill from "components/common/Pill";
import Table from "components/common/Table";
import { CATEGORIES, RESULTS, SESSION_TYPE_REVERSE } from "constants";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formatDateShort } from "utils/formatDate";
import { getCurrentTimeZone } from "utils/time";
import Spinner from "views/Spinner";

function DashboardJourneyCompleted({ user, getMycourseSessions }) {
  const [completedEvents, setCompletedEvents] = useState();
  const [isLoading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState();

  let timeZone = getCurrentTimeZone(user);

  const fetchData = (f = {}) => {
    setLoading(true);
    getMycourseSessions({ ...filters, query, session_type: 2, page, ...f }, RESULTS.ALL).then(
      (res) => {
        setCompletedEvents(res.data.data);
        setTotalRecords(res.data.metadata[0].totalRecord);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const onSearch = () => {
    fetchData();
  };
  const onClear = () => {
    setQuery("");
    setPage(1);
    fetchData({ query: "", page: 1 });
  };

  return (
    <>
      <div className="flex gap-3 w-1/2 md:w-full">
        <div className="flex-1">
          <TextInput
            className="w-full"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search by event name"
          />
        </div>
        <Button className="md:hidden" small flat onClick={onClear}>
          Clear
        </Button>
        <Button className="md:hidden" small onClick={onSearch}>
          Search
        </Button>
      </div>
      {isLoading ? (
        <Spinner />
      ) : completedEvents?.length > 0 ? (
        <div className="py-12">
          <Table
            page={page}
            setPage={setPage}
            pageSize={filters.limit}
            count={totalRecords}
            top={
              <>
                Completed
                <Pill
                  className="ml-2"
                  text={`${totalRecords} events & sessions`}
                  color="orange"
                />
              </>
            }
            columns={[
              { label: "Event Name", key: "course_name" },
              {
                label: "Event Type",
                key: "event_type",
                Content: ({ data }) => (
                  <div>{SESSION_TYPE_REVERSE[data.session_type]}</div>
                ),
              }, //
              // { label: "Role", key: "role" },
              {
                label: "Date",
                key: "date",
                Content: ({ data }) => (
                  <div>
                    {formatDateShort(moment.unix(data?.start_at).tz(timeZone))}
                  </div>
                ),
              },
              {
                label: "Time",
                key: "time",
                Content: ({ data }) => (
                  <div className="whitespace-nowrap">
                    {moment.unix(data?.start_at).tz(timeZone).format("hh:mm A")}
                  </div>
                ),
              },
              {
                label: "Category",
                key: "category",
                Content: ({ data }) => (
                  <Pill
                    text={
                      data.category.name
                    }
                    color={CATEGORIES[data.category_name]?.color}
                  />
                ),
              },
            ]}
            rows={completedEvents}
          />
        </div>
      ) : (
        <NotFoundText>No events found</NotFoundText>
      )}
    </>
  );
}

export default connect(() => ({}), { getMycourseSessions })(
  DashboardJourneyCompleted
);

const filters = {
  limit: 7,
  page: 1,
  orderBy: "start_at",
  ascending: "desc",
  query: "",
  custom_filters: "",
};
