import { PAYOUT_METHODS } from "constants";
import * as Yup from "yup";

const updatePaymentMethodSchema = Yup.object({
  payoutMethod: Yup.string().required("Please select your payout method"),
  paypalEmail: Yup.string().when("payoutMethod", {
    is: (val) => val === PAYOUT_METHODS.PayPal,
    then: Yup.string()
      .email("Please enter a valid email address")
      .required("Please enter your payout email address"),
  }),
  weChatPayFile: Yup.mixed().when("payoutMethod", {
    is: (val) => val === PAYOUT_METHODS.WeChat,
    then: Yup.mixed().required("Please upload your QR Code"),
  }),
});

export default updatePaymentMethodSchema;
