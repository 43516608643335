import { getDraftAllCourses } from "actions/front/common/course";
import { getUpcomingSessions } from "actions/front/educator/courses";
import Button from "components/common/Button";
import SelectInput from "components/common/form/SelectInput";
import TextInput from "components/common/form/TextInput";
import NotFoundText from "components/common/NotFoundText";
import EventCard from "components/event/EventCard";
import { RESULTS } from "constants";
import { CATEGORIES, SORTING } from "constants";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import Spinner from "views/Spinner";

function DashboardMyEventsDrafts({ user, getDraftAllCourses }) {
  const [selectedCategory, setSelectedCategory] = useState();
  const [draftEvents, setDraftEvents] = useState();
  const [isLoading, setLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState();

  const fetchData = (f = {}) => {
    setLoading(true);
    getDraftAllCourses(filters, user._id, "", RESULTS.DRAFT_COURSES, 1).then(
      (res) => {
        if (res.status) {
          setDraftEvents(res.data.data);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, [selectedCategory, sortBy]);

  const onSearch = () => {
    fetchData();
  };
  const onClear = () => {
    setQuery("");
    fetchData({ query: "" });
  };

  return (
    <>
      <div className="flex justify-between md:flex-col gap-4 md:gap-12">
        <div className="flex gap-3">
          <div className="flex-1">
            <TextInput
              className="w-full"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search by event name"
            />
          </div>
          <Button className="md:hidden" small flat onClick={onClear}>
            Clear
          </Button>
          <Button className="md:hidden" small onClick={onSearch}>
            Search
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-1 gap-8 pt-12 pb-36">
        {isLoading ? (
          <div className="col-span-2">
            <Spinner />
          </div>
        ) : draftEvents?.length > 0 ? (
          draftEvents.map((event, i) => (
            <EventCard full data={event} isDraft key={i} />
          ))
        ) : (
          <NotFoundText className="py-10 col-span-2">
            No draft events
          </NotFoundText>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user_id: state.auth.user._id,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getDraftAllCourses,
})(DashboardMyEventsDrafts);

const initialFilters = {
  limit: 999,
  page: 1,
  orderBy: "start_at",
  ascending: "desc",
  query: "",
  custom_filters: "",
};
