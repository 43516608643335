import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
// import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { edit, cancelSave, getUserById, notFound } from "actions/admin/user";
import Spinner from "views/Spinner";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import Errors from "views/Notifications/Errors";

const EditUser = ({
  getUserById,
  edit,
  cancelSave,
  notFound,
  user: { currentUser, loading },
  history,
  match,
  errorList,
}) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    status: "",
  });
  useMemo(() => {
    getUserById(match.params.user_id).then((res) => {
      if (res === undefined) notFound(history);
      else
        setFormData({
          first_name: loading || !res.first_name ? "" : res.first_name,
          last_name: loading || !res.last_name ? "" : res.last_name,
          email: loading || !res.email ? "" : res.email,
          status: loading || !res.status ? "" : res.status,
        });
    });
  }, [loading, getUserById, match.params.user_id]);

  const { first_name, last_name, email, status } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    edit(formData, history, match.params.user_id);
  };
  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(history);
  };
  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="6">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <FormGroup>
                  <Label htmlFor="first_name">
                    First Name <span>*</span>
                  </Label>
                  <Input
                    type="text"
                    id="first_name"
                    name="first_name"
                    maxLength="25"
                    value={first_name}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.first_name ? true : false}
                  />
                  <Errors current_key="first_name" key="first_name" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="last_name">Last Name</Label>
                  <Input
                    type="text"
                    id="last_name"
                    name="last_name"
                    maxLength="25"
                    value={last_name}
                    onChange={(e) => onChange(e)}
                  />
                  <FormFeedback>Houston, we have a problem...</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="email">
                    Email <span>*</span>
                  </Label>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    maxLength="50"
                    value={email}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.email ? true : false}
                  />
                  <Errors current_key="email" key="email" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="status">Select</Label>
                  <Input
                    type="select"
                    name="status"
                    id="status"
                    value={status}
                    onChange={(e) => onChange(e)}
                  >
                    {status === 0 && <option value="0">Pending</option>}
                    <option value="1">Active</option>
                    <option value="2">UnVerified</option>
                    <option value="3">Block</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="status">Premium Status</Label>
                  <p className="text-sm">User is premium ✅</p>
                </FormGroup>
              </CardBody>
              <CardFooter>
                <div className="flex justify-between">
                  <div className="flex">
                    <Button
                      type="submit"
                      className="m-1"
                      size="sm"
                      color="primary"
                    >
                      <i className="fa fa-dot-circle-o"></i> Submit
                    </Button>
                    <a onClick={onClickHandel} href="#!">
                      <Button
                        type="reset"
                        className="m-1"
                        size="sm"
                        color="danger"
                      >
                        <i className="fa fa-ban"></i> Cancel
                      </Button>
                    </a>
                  </div>
                  <div className="flex flex-row">
                    <Button
                      type="submit"
                      className="m-1"
                      size="sm"
                      color="success"
                    >
                      Cancel Subscription
                    </Button>
                    <Button
                      type="submit"
                      className="m-1"
                      size="sm"
                      color="success"
                    >
                      Cancel Subscription Immediately
                    </Button>
                  </div>
                </div>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditUser.propTypes = {
  getUserById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  cancelSave: PropTypes.func.isRequired,
  notFound: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  errorList: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getUserById,
  edit,
  cancelSave,
  notFound,
})(EditUser);
