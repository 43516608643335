import * as Yup from "yup";
import { passwordSchema } from "./shared";

const loginSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email address is required"),
  password: passwordSchema(
    "Please enter a password",
    "Password must be at least 8 characters long"
  ),
});

export default loginSchema;
