import { useState } from "react";
import Accordion from "./Accordion";

export default function AccordionList({ items = [] }) {
  const [openIndex, setOpenIndex] = useState(0);

  return (
    <ul className="space-y-4">
      {items.map((item, i) => (
        <Accordion
          {...item}
          key={i}
          open={() => setOpenIndex(i)}
          isOpen={openIndex === i}
        />
      ))}
    </ul>
  );
}
