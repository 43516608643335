
export function getShareLinkTwitter(title) {
    return `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        title
    )}&url=${encodeURIComponent(window.location.href)}`;
}

export function getShareLinkFacebook(title) {
    return `https://facebook.com/sharer/sharer.php?u="${window.location.href}"&t=${title}`;
}

export function getShareLinkLinkedin(title) {
    return `https://www.linkedin.com/sharing/share-offsite?url=${window.location.href
        }&amp;title=${title}&amp;source=${encodeURIComponent(
            window.location.hostname
        )}`;
}
