import { ReactComponent as BellPlus } from "assets/images/icons/bell-plus.svg";
import { ReactComponent as BellTilted } from "assets/images/icons/bell-tilted.svg";
import Button from ".";

export default function FollowButton({
  isFollowing,
  isLoadingFollow,
  toggleFollow,
}) {
  return isFollowing ? (
    <Button
      disabled={isLoadingFollow}
      className="lg:px-2.5 py-2.5 px-3.5 rounded-md mr-2 font-medium bg-primary-50 text-primary-700 !border-primary-50"
      onClick={toggleFollow}
      small
      flat
    >
      <BellTilted className="inline mr-2 lg:m-0" />
      <span className="lg:hidden">Following</span>
    </Button>
  ) : (
    <Button
      disabled={isLoadingFollow}
      className="lg:px-2.5"
      onClick={toggleFollow}
      small
      flat
    >
      <BellPlus className="inline mr-2 lg:m-0" />
      <span className="lg:hidden">Follow Host</span>
    </Button>
  );
}
