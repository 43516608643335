import React from "react";
import BackgroundImage from "assets/images/bg/bg5.jpg";

const SearchText = ({ searchText, setSearchText, onClickSearchText }) => {
  return (
    <div className="row" id="banner">
      <div id="inner-banner" className="col-md-12 p-0">
        <img src={BackgroundImage} alt="" className="img-fluid" />
        <div className="inner-caption d-md-block">
          <h2 className="mb-3">Find Connections</h2>
          <form
            className="form-horizontal"
            onSubmit={(e) => onClickSearchText(e)}
          >
            <div className="input-group">
              <input
                type="text"
                name="txt_search"
                id="txt_search"
                placeholder="Search Mentor..."
                className="form-control"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-secondary"
                  type="submit"
                  onClick={(e) => onClickSearchText(e)}
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchText;
