import Button from "components/common/Button";
import CenterContainer from "components/common/Container/Center";

export default function BecomeAHostCTA() {
  return (
    <CenterContainer className="py-24">
      <div className="bg-dgray-800 rounded-lg text-center py-16">
        <h2 className="text-white text-4xl lg:text-3xl font-semibold mb-5">
          Apply today to become a host!
        </h2>
        <p className="text-dgray-100 mb-8">Join our growing community!</p>
        <Button to="/become-a-host/apply" small className="px-10">
          Get started
        </Button>
      </div>
    </CenterContainer>
  );
}
