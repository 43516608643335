import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";
import { ReactComponent as GlobeIcon } from "assets/images/icons/globe-large.svg";
import { ReactComponent as PinIcon } from "assets/images/icons/marker-pin.svg";
import Pill from "components/common/Pill";
import useFollowHost from "hooks/useFollowHost";
import getUserAvatar from "utils/getUserAvatar";
import FollowButton from "components/common/Button/Follow";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { EXPERTISE_COLOR } from "constants";

function HostCardLarge({ user, data, hideUpcoming = false }) {
  const followProps = useFollowHost(data);

  return (
    <div className="rounded-2xl shadow-lg bg-white flex lg:block p-6 lg:p-0 border border-dgray-200">
      <div className="flex flex-1 lg:p-6 lg:pb-0">
        <div className="shrink-0">
          <img
            src={getUserAvatar(data.avatar)}
            alt="host"
            className="h-[200px] md:h-20 w-[200px] md:w-20 object-cover rounded-md"
          />
        </div>
        <div className="w-full pl-6">
          <div className="flex justify-between">
            <div>
              <Link
                to={`/hosts/${data.username}`}
                className="text-lg font-bold"
              >
                {data.name}
              </Link>
              <div className="text-sm text-dgray-500 flex gap-6 lg:block mt-1 mb-4">
                {[data.city, data.state, data.country].filter(i => i && i !== "").length > 0 && (
                  <div className="mb-1">
                    <PinIcon className="inline mr-1 text-dgray-400" />
                    {[data.city, data.state, data.country].filter(i => i && i !== "").join(", ")}
                  </div>
                )}
                {data.languages?.length > 0 && (
                  <div>
                    <GlobeIcon className="inline mr-1 text-dgray-400" />
                    {data.languages.join(", ")}
                  </div>
                )}
              </div>
            </div>
            <div>
              {user?.id !== data._id && <FollowButton {...followProps} />}
            </div>
          </div>

          <div className="lg:hidden">
            {data.expertise_list && data.expertise_list.length > 0 && (
              <div className="mb-4">
                <div className="text-primary-700 text-sm font-semibold mb-1">
                  Specialized in
                </div>
                <div className="flex flex-wrap gap-2">
                  {data.expertise_list.map((exp, i) => (
                    <Pill key={i} text={exp} color={EXPERTISE_COLOR[exp]} />
                  ))}
                </div>
              </div>
            )}
            {data.description && (
              <div>
                <div className="text-primary-700 text-sm font-semibold mb-1">
                  About
                </div>
                <div className="">
                  <div
                    className="text-sm text-dgray-500 overflow-hidden line-clamp-2"
                    dangerouslySetInnerHTML={{
                      __html: `${data.description.substring(0, 130)}...`,
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="hidden lg:block px-6 py-4">
        {data.expertise_list && data.expertise_list.length > 0 && (
          <div className="mb-4">
            <div className="text-dgray-500 text-xs font-semibold mb-1">
              Specialized in
            </div>
            <div className="flex flex-wrap gap-2">
              {data.expertise_list.map((exp, i) => (
                <Pill key={i} text={exp} color={EXPERTISE_COLOR[exp]} />
              ))}
            </div>
          </div>
        )}
        {data.description && (
          <div className="">
            <div className="text-dgray-500 text-sm md:text-xs font-semibold mb-1">
              About
            </div>
            <div className="text-dgray-500 text-sm line-clamp-3">
              <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
            </div>
          </div>
        )}
      </div>

      {!hideUpcoming && (
        <div className="text-sm text-primary-700 font-medium border-l lg:border-l-0 lg:border-t border-dgray-200 flex items-center pl-8 lg:p-6 ml-6 lg:ml-0">
          <Link to={`/hosts/${data.username}`}>
            {data.closest_courses?.length} Upcoming Events
            <ArrowRight className="inline ml-2 align-top" />
          </Link>
        </div>
      )}
    </div>
  );
}

export default connect(({ auth }) => ({
  user: auth.user,
}))(HostCardLarge);
