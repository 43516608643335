import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormText } from "reactstrap";

const FrontError = ({ errorList, current_key }) => {
  if (current_key in errorList) {
    return (
      <FormText className="invalid-feedback-text error text-danger">
        {errorList[current_key]}
      </FormText>
    );
    // return <FormFeedback>{errorList[current_key]}</FormFeedback>;
  }
  return "";
};

FrontError.propTypes = {
  errorList: PropTypes.array.isRequired,
  current_key: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.frontErrors,
});

export default connect(mapStateToProps)(FrontError);
