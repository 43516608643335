import { PATH_CHANGE, PATH_INITIALIZE } from "actions/types";

const initalState = {
  prev: "/",
  last: "/",
  history: [],
};

export default function reducer(state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case PATH_INITIALIZE:
      let localLast = "/";
      localLast = localStorage.getItem("lastPath");

      return { ...state, last: localLast };

    case PATH_CHANGE:
      const history = [...state.history];
      const prev = history[history.length-1];

      const isExcluded = excludedPaths.some((excPath) =>
        payload.path.startsWith(excPath)
      );

      if (!isExcluded) {
        if (prev) localStorage.setItem("lastPath", payload.path);
        if (prev !== payload.path) history.push(payload.path);
      }

      return {
        ...state,
        prev: prev || "/",
        history,
      };
    default:
      return state;
  }
}

const excludedPaths = [
  "/login",
  "/register",
  "/resetPassword",
  "/forgot-password",
  "/confirmUser",
  "/verificationEmail",
];
