import {
  USER_PROFILE_MODAL_LOADING,
  USER_PROFILE_LOADING,
  USER_PROFILE_UPDATED,
  USER_PROFILE_UPDATE_ERROR,
  USER_PROFILE_FAIL,
  USER_PROFILE_PASSWORD_UPDATED,
  USER_PROFILE_PASSWORD_UPDATE_ERROR,
} from "actions/types";

const initalState = {
  loading: false,
  loadingChangePassword: false,
  error: {},
  user: null,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_PROFILE_MODAL_LOADING:
      return {
        ...state,
        loadingChangePassword: true,
      };
    case USER_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_PROFILE_UPDATED:
      return {
        ...state,
        user: payload.data,
        loading: false,
      };
    case USER_PROFILE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        loadingChangePassword: false,
      };

    case USER_PROFILE_PASSWORD_UPDATED:
      return {
        ...state,
        user: payload.data,
        loadingChangePassword: false,
      };
    case USER_PROFILE_PASSWORD_UPDATE_ERROR:
      return {
        ...state,
        loadingChangePassword: false,
      };

    default:
      return state;
  }
}
