import { getAllCourses } from "actions/front/common/course";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Spinner from "views/Spinner";
import EventCard from "components/event/EventCard";
import moment from "moment";

function HostEvents({ hostId }) {
  const [isUpcoming, setUpcoming] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [events, setEvents] = useState();

  useEffect(() => {
    setLoading(true);
    if (hostId) {
      getAllCourses(filters, hostId, "all").then((res) => {
        if (res.status) {
          setEvents(res.data.data);
        }
        setLoading(false);
      });
    }
  }, [isUpcoming]);

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-6">Events by the Host</h2>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="pb-20">
          {/* <button
            onClick={() => setUpcoming(true)}
            className={`py-2.5 px-3.5 rounded-md mr-2 font-medium
          ${isUpcoming ? "bg-primary-50 text-primary-700" : "text-dgray-500"}
        `}
          >
            Upcoming
          </button>
          <button
            onClick={() => setUpcoming(false)}
            className={`py-2.5 px-3.5 rounded-md mr-2 font-medium
          ${!isUpcoming ? "bg-primary-50 text-primary-700" : "text-dgray-500"}
        `}
          >
            Previous Events
          </button> */}

          {events?.length > 0 ? (
            <div className="grid grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-6">
              {events.map((course, i) => (
                <div key={i} className="grid">
                  <EventCard data={course} />
                </div>
              ))}
            </div>
          ) : (
            <div className="text-dgray-500 text-center mt-8">
              No events found
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default connect(() => ({}), { })(HostEvents);

const filters = {
  limit: 0,
  page: 1,
  orderBy: "start_at",
  ascending: "asc",
  query: "",
  custom_filters: "",
};
