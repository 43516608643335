import axios from "utils/axios";

export const getAllCourses = async (
  courseParams,
  mentor_id = "",
  isAll = "",
  activeResultsFor = "",
  is_for_self = 0
) => {
  try {
    const custom_filters = courseParams.custom_filters
      ? courseParams.custom_filters
      : "&session_type=&course_category=&rating=";
      let home_page = false
      if (courseParams.home_page) home_page = true 

    const res = await axios.get(
      `/api/front/courses/getallcourses?limit=${courseParams.limit}&page=${courseParams.page}&orderBy=${courseParams.orderBy}&ascending=${courseParams.ascending}&query=${courseParams.query}${custom_filters}&mentorId=${mentor_id}&activeResultsFor=${activeResultsFor}&isAll=${isAll}&is_for_self=${is_for_self}&homePage=${home_page}`
    );
    return { status: true, data: res.data.response };
  } catch (error) {
    return { status: false };
  }
};

export const getPrivateCourses = async (
  courseParams,
  mentor_id = "",
  isAll = "",
  activeResultsFor = "",
  is_for_self = 0
) => {
  try {
    const custom_filters = courseParams.custom_filters
      ? courseParams.custom_filters
      : "&session_type=&course_category=&rating=";
      let home_page = false
      if (courseParams.home_page) home_page = true 

    const res = await axios.get(
      `/api/front/courses/getprivatecourses?limit=${courseParams.limit}&page=${courseParams.page}&orderBy=${courseParams.orderBy}&ascending=${courseParams.ascending}&query=${courseParams.query}${custom_filters}&mentorId=${mentor_id}&activeResultsFor=${activeResultsFor}&isAll=${isAll}&is_for_self=${is_for_self}&homePage=${home_page}`
    );
    return { status: true, data: res.data.response };
  } catch (error) {
    return { status: false };
  }
};

export const getFeaturedEventCourses = async (
  courseParams,
  mentor_id = "",
  isAll = "",
  activeResultsFor = "",
  is_for_self = 0
) => {
  try {
    const custom_filters = courseParams.custom_filters
      ? courseParams.custom_filters
      : "&session_type=&course_category=&rating=";

    const res = await axios.get(
      `/api/front/courses/getfeaturedevent?not_in=&limit=${courseParams.limit}&page=${courseParams.page}&orderBy=${courseParams.orderBy}&ascending=${courseParams.ascending}&query=${courseParams.query}${custom_filters}&mentorId=${mentor_id}&activeResultsFor=${activeResultsFor}&isAll=${isAll}&is_for_self=${is_for_self}`
    );
    return { status: true, data: res.data.response };
  } catch (error) {
    return { status: false };
  }
};



export const getLiveEventCourses = async (
  courseParams,
  mentor_id = "",
  isAll = "",
  activeResultsFor = "",
  is_for_self = 0
) => {
  try {
    const custom_filters = courseParams.custom_filters
      ? courseParams.custom_filters
      : "&session_type=&course_category=&rating=";

    const res = await axios.get(
      `/api/front/courses/getliveevent?not_in=${courseParams.not_in.join(',')}&limit=${courseParams.limit}&page=${courseParams.page}&orderBy=${courseParams.orderBy}&ascending=${courseParams.ascending}&query=${courseParams.query}${custom_filters}&mentorId=${mentor_id}&activeResultsFor=${activeResultsFor}&isAll=${isAll}&is_for_self=${is_for_self}`
    );
    return { status: true, data: res.data.response };
  } catch (error) {
    return { status: false };
  }
};

export const getCourseDetailById = (course_id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/front/courses/getCourseDetailById/${course_id}`
    );
    if (res.data.status) return { status: true, data: res.data.response };
    else return { status: false, is_full: true };
  } catch (error) {
    return { status: false, is_full: false };
  }
};

export const getDraftAllCourses = (
  courseParams,
  mentor_id = "",
  isAll = "",
  activeResultsFor = "",
  is_for_self = 0
) => async (dispatch) => {
  try {
    const custom_filters = courseParams.custom_filters
      ? courseParams.custom_filters
      : "&session_type=&course_category=&rating=";

    const res = await axios.get(
      `/api/front/courses/getalldraftcourses?limit=${courseParams.limit}&page=${courseParams.page}&orderBy=${courseParams.orderBy}&ascending=${courseParams.ascending}&query=${courseParams.query}${custom_filters}&mentorId=${mentor_id}&activeResultsFor=${activeResultsFor}&isAll=${isAll}&is_for_self=${is_for_self}`
    );
    return { status: true, data: res.data.response };
  } catch (error) {
    return { status: false };
  }
};
