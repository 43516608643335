import TextInput from "components/common/form/TextInput";
import RadioGroup from "components/common/form/RadioGroup";
import { CreateFormRow } from "components/create-form/CreateFormRow";
import CreateStepControls from "../../StepControls";
import Checkbox from "components/common/form/Checkbox";
import { FieldArray, useFormikContext } from "formik";
import { ReactComponent as PlusIcon } from "assets/images/icons/plus.svg";

export default function CreateEventPublicStep1({ ...props }) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <h2 className="text-lg font-medium border-b border-dgray-200 pb-5 mb-5">
        Event Settings
      </h2>
      <CreateFormRow
        title="Speaker Interaction"
        description="You can set a maximum number for speaker seat for optimal event interaction."
        right={
          <TextInput
            type="number"
            min="0"
            name="maxSpeakers"
            label="Set max. number of speakers"
            placeholder="Enter a number"
            className="w-full"
          />
        }
      />
      <CreateFormRow
        title="Listeners"
        description="Listeners can join events with camera and microphone turned off, just to listen."
        right={
          <>
            <RadioGroup
              name="allowAudience"
              items={[
                {
                  label: "Allow listeners",
                  value: true,
                  description:
                    "Attendees that are registered as listener will be able to join and observe the event.",
                  expansion: (
                    <TextInput
                      type="number"
                      min={0}
                      name="maxAudience"
                      label="Set max. number of listeners"
                      placeholder="Enter a number"
                      className="w-full"
                    />
                  ),
                },
                {
                  label: "Do not allow",
                  value: false,
                  description:
                    "Attendees will not able to join & observe the event.",
                },
              ]}
            />
          </>
        }
      />
      <CreateFormRow
        title="Invite co-host (optional)"
        description="You can include co-hosts to your events to perform the events together."
        right={
          <>
            <Checkbox
              name="inviteCoHost"
              label="Yes"
              className="mb-4"
              onChange={(val) => {
                if (!val) setFieldValue("coHosts", [""]);
              }}
            />
            {values.inviteCoHost ? (
              <FieldArray
                name="coHosts"
                render={(arrayHelpers) => (
                  <div className="space-y-3">
                    {values.coHosts?.length > 0 &&
                      values.coHosts.map((friend, i) => (
                        <div className="" key={i}>
                          <TextInput
                            name={`coHosts.${i}`}
                            label={
                              <span>
                                Co-host mail address {i + 1}{" "}
                                {i > 0 && i === values.coHosts.length - 1 && (
                                  <button
                                    className="text-primary-600"
                                    onClick={() => arrayHelpers.remove(i)}
                                  >
                                    (remove)
                                  </button>
                                )}
                              </span>
                            }
                            placeholder="mail@example.com"
                            className="w-full"
                          />
                          {i === values.coHosts.length - 1 && (
                            <button
                              className="flex items-center gap-1 text-sm text-primary-600 p-2 ml-auto"
                              type="button"
                              onClick={() => arrayHelpers.push("")}
                            >
                              Add Co-host
                              <PlusIcon />
                            </button>
                          )}
                        </div>
                      ))}
                  </div>
                )}
              />
            ) : (
              <TextInput
                label="Co-host mail address"
                placeholder="mail@example.com"
                className="w-full"
                disabled
              />
            )}
          </>
        }
      />
      <CreateStepControls {...props} />
    </>
  );
}
