import React, { useState, useMemo, useEffect } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Errors from "views/Notifications/Errors";
import Alert from "views/Notifications/Alert";
import { REMOVE_ERRORS, EDUCATOR_COURSE_CREATE_ERROR } from "actions/types";
import validator from "validator";
import { SESSION_TYPE, TABS } from "constants/index";
import CKEditor from "ckeditor4-react";
import ImageCrop from "views/Front/Utils/ImageCrop";

const CourseInformation = ({
  isEdit = false,
  isDraft = false,
  categories,
  setcourseInformationFormData,
  courseInformationFormData,
  sessionEnable,
  setSessionEnable,
  setTypeOfSession,
  setActiveTab,
  resetSessionFormData,
  isConfirmation = false,
  basicErrorMsg,
  setBasicErrorMsg,
  onSubmitDraftForm
}) => {
  /*********************Use Dispatch to set state ************************/

  const dispatch = useDispatch();

  /*********************State for course category and sub category list************************/

  const [categoryList, setCategoryList] = useState(categories.data || []);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [invalidImage, setInvalidImage] = useState(false);

  /*********************get course category ************************/

  useEffect(() => {
    if (!categories.isLoading) {
      setCategoryList(categories.data);
    }
  }, [categories]);

  /*********************Explode courseInformationFormData ************************/
  const {
    category_id,
    sub_category_id,
    name,
    selectedFile, // image file
    image, // object url of image (image src)
    thumbnail,
    overview,
    ideal_for,
    course_information,
    category_type,
    is_individual,
    is_group,
    is_edit_individual = true,
    is_edit_group = true,
    is_preview = false,
  } = courseInformationFormData;

  /*********************on change event************************/
  const onChange = (e, isCheckbox = false) => {
    if (isCheckbox) {
      setcourseInformationFormData({
        ...courseInformationFormData,
        [e.target.name]: e.target.checked,
      });
    } else if (e.target.name === "category_id") {
      if (e.target.value !== "") {
        let categoryDetail = categoryList.filter((item) =>
          item._id.includes(e.target.value)
        );
        let cat_type = "";
        if (categoryDetail.length > 0) {
          cat_type = categoryDetail[0].category_type;
          let subcategory = categoryDetail[0].subcategories;
          setSubCategoryList(subcategory);
        } else setSubCategoryList([]);

        setcourseInformationFormData({
          ...courseInformationFormData,
          [e.target.name]: e.target.value,
          sub_category_id: "",
          category_type: cat_type,
          is_individual: false,
          is_group: false,
        });
      } else {
        setcourseInformationFormData({
          ...courseInformationFormData,
          [e.target.name]: "",
          sub_category_id: "",
          category_type: "",
          is_individual: false,
          is_group: false,
        });
        setSubCategoryList([]);
      }
      //reset session form data if the category is changed
      resetSessionFormData();
    } else if (e.target.name === "thumbnail") {
      if (!e) return;
    } else {
      setcourseInformationFormData({
        ...courseInformationFormData,
        [e.target.name]: e.target.value,
      });
    }
    setBasicErrorMsg("");
    dispatch({ type: REMOVE_ERRORS });
  };

  const onImageChange = (data) => {
      setInvalidImage(false)
      let image_url = URL.createObjectURL(data);
      setcourseInformationFormData({
        ...courseInformationFormData,
        selectedFile: data,
        image: image_url,
        isNewFileSelected: 1,
        is_preview: true,
      });
  }

  const removeImage = (e) => {
    setInvalidImage(false);
     setcourseInformationFormData({
      ...courseInformationFormData,
       thumbnail: "",
       image: "",
       is_preview: false,
       isNewFileSelected: 0,
       selectedFile: '',
     });
  };

  const handelOverviewChange = (event) => {
    setcourseInformationFormData({
      ...courseInformationFormData,
      overview: event.editor.getData(),
    });
    setBasicErrorMsg("");
    dispatch({ type: REMOVE_ERRORS });
  };

  const handelIdealForChange = (event) => {
    setcourseInformationFormData({
      ...courseInformationFormData,
      ideal_for: event.editor.getData(),
    });
    setBasicErrorMsg("");
    dispatch({ type: REMOVE_ERRORS });
  };

  const handelCourseInformationChange = (event) => {
    setcourseInformationFormData({
      ...courseInformationFormData,
      course_information: event.editor.getData(),
    });
    setBasicErrorMsg("");

    dispatch({ type: REMOVE_ERRORS });
  };

  useMemo(() => {
    if (categoryList && categoryList.length > 0 && category_id != "") {
      let categoryDetail = categoryList.filter((item) =>
        item._id.includes(category_id)
      );
      if (categoryDetail.length > 0) {
        let subcategory = categoryDetail[0].subcategories;
        setSubCategoryList(subcategory);
      } else setSubCategoryList([]);
    }
  }, [categoryList]);

  /*****error lists*******/
  const [courseInforTabErrorList, setCourseInforTabErrorList] = useState([]);

  /*********************useMemo to enable the session tab check form validation************************/
  useMemo(() => {
    let errorsList = [];
    let isValid = true;
    // if (
    //   courseInformationFormData.category_id === "" ||
    //   courseInformationFormData.sub_category_id === "" ||
    //   courseInformationFormData.name === "" ||
    //   (courseInformationFormData.name !== "" &&
    //     !validator.isAlphanumeric(courseInformationFormData.name) &&
    //     !validator.isLength(courseInformationFormData.name, {
    //       min: 5,
    //       max: 500,
    //     })) ||
    //   courseInformationFormData.overview === "" ||
    //   (courseInformationFormData.overview !== "" &&
    //     !validator.isAlphanumeric(courseInformationFormData.overview) &&
    //     !validator.isLength(courseInformationFormData.overview, {
    //       min: 100,
    //       //max: 5000,
    //     })) ||
    //   courseInformationFormData.ideal_for === "" ||
    //   (courseInformationFormData.ideal_for !== "" &&
    //     !validator.isAlphanumeric(courseInformationFormData.ideal_for) &&
    //     !validator.isLength(courseInformationFormData.ideal_for, {
    //       min: 100,
    //       //max: 50,
    //     })) ||
    //   (courseInformationFormData.course_information !== "" &&
    //     !validator.isAlphanumeric(
    //       courseInformationFormData.course_information
    //     ) &&
    //     !validator.isLength(courseInformationFormData.course_information, {
    //       min: 100,
    //       //max: 500000000,
    //     })) ||
    //   (courseInformationFormData.category_type === "specific" &&
    //     !courseInformationFormData.is_individual &&
    //     !courseInformationFormData.is_group)
    // ) {
    //   isValid = false;
    // }
    if (courseInformationFormData.category_id === "") {
      isValid = false;
      errorsList[`category_id`] = "Please Select Category";
    }
    if (courseInformationFormData.sub_category_id === "") {
      isValid = false;
      errorsList[`sub_category_id`] = "Please Select Sub Category";
    }

    if (courseInformationFormData.name.trim() === "") {
      isValid = false;
      errorsList[`name`] = "Please enter course name";
    } else if (
      courseInformationFormData.name.trim() !== "" &&
      !validator.isLength(courseInformationFormData.name.trim(), {
        min: 5,
        max: 500,
      })
    ) {
      isValid = false;
      errorsList[`name`] = "Course name must be 5-500 charaters long";
    }

    if (courseInformationFormData.overview === "") {
      isValid = false;
      errorsList[`overview`] = "Please enter overview";
    } else if (
      courseInformationFormData.overview !== "" &&
      !validator.isLength(courseInformationFormData.overview, {
        min: 30,
      })
    ) {
      isValid = false;
      errorsList[`overview`] = "Overview must be at least 30 charaters long";
    }

    if (courseInformationFormData.ideal_for === "") {
      isValid = false;
      errorsList[`ideal_for`] = "Please enter ideal for";
    } else if (
      courseInformationFormData.ideal_for !== "" &&
      !validator.isLength(courseInformationFormData.ideal_for, {
        min: 30,
      })
    ) {
      isValid = false;
      errorsList[`ideal_for`] = "Ideal for must be at least 30 charaters long";
    }

    if (
      courseInformationFormData.course_information.trim() !== "" &&
      !validator.isLength(courseInformationFormData.course_information.trim(), {
        min: 30,
      })
    ) {
      isValid = false;
      errorsList[`course_information`] =
        "Course information must be at least 30 charaters long";
    }

    if (
      courseInformationFormData.category_type === "specific" &&
      !courseInformationFormData.is_individual &&
      !courseInformationFormData.is_group
    ) {
      isValid = false;
      errorsList[`course_type`] = "Please select at least one course type.";
    }
    if (isValid) {
      setCourseInforTabErrorList(null);
    } else {
      setCourseInforTabErrorList(errorsList);
    }
    setSessionEnable(isValid);
  }, [courseInformationFormData]);

  /*********************useMemo to set the session type************************/
  useMemo(() => {
    if (category_type === "generic") {
      setTypeOfSession(SESSION_TYPE.GENERIC);
    } else if (category_type === "specific") {
      if (is_individual && is_group) {
        setTypeOfSession(SESSION_TYPE.SPECIFIC_BOTH);
      } else if (is_individual) {
        setTypeOfSession(SESSION_TYPE.SPECIFIC_INDIVIDUAL);
      } else if (is_group) {
        setTypeOfSession(SESSION_TYPE.SPECIFIC_GROUP);
      }
      else{
        setTypeOfSession(false);
      }
    } else {
      setTypeOfSession(false);
    }
  }, [category_type, is_individual, is_group]);

  /*********************on submit event************************/
  const onCourseInformationSubmit = (e) => {
    e.preventDefault();
    if (!sessionEnable && courseInforTabErrorList != null) {
      dispatch({
        type: EDUCATOR_COURSE_CREATE_ERROR,
        payload: {
          message: "Please correct the errors",
          alertType: "danger",
          errorsList: courseInforTabErrorList,
        },
      });
      setBasicErrorMsg("Please fix validation errors identified below");
      window.scrollTo(0, 0);
    } else setActiveTab(TABS.SESSION);
  };
  const onClickDraftForm = (e) => {
    onSubmitDraftForm();
  };
  const courseDetail = (
    <>
      <div className="row mt-3">
        <div className="col-md-12">
          <small className="text-muted">
            Hint: If you would like to offer a session where a learner can talk
            to you about anything regardless of topic, please choose ‘DD me for
            Anything’ as the Session Category
          </small>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="category_id">
              Category <span className="text-danger">*</span>
            </label>
            <select
              className="form-control custom-select"
              name="category_id"
              id="category_id"
              value={category_id}
              onChange={(e) => onChange(e)}
              required
              disabled={isConfirmation || isEdit || !isDraft}
              // invalid={errorList.category_id ? true : false}
            >
              <option value={""}> {"Select Category"} </option>
              {categoryList
                ? categoryList.map((value, index) => {
                    return (
                      <option key={index} value={value._id}>
                        {value.name}
                      </option>
                    );
                  })
                : ""}
            </select>
            <Errors current_key="category_id" key="category_id" />
            {/* <small className="error text-danger">Please select course type</small> */}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="sub_category_id">
              Sub Category <span className="text-danger">*</span>
            </label>
            <select
              className="form-control custom-select"
              name="sub_category_id"
              id="sub_category_id"
              value={sub_category_id}
              onChange={(e) => onChange(e)}
              required
              disabled={isConfirmation || isEdit || !isDraft}

              // invalid={errorList.sub_category_id ? true : false}
            >
              <option value={""}> {"Select Sub Category"} </option>
              {subCategoryList
                ? subCategoryList.map((value, index) => {
                    return (
                      <option key={index} value={value._id}>
                        {value.name}
                      </option>
                    );
                  })
                : ""}
            </select>
            {/* <small className="error text-success">Success message will be like this</small> */}
            <Errors current_key="sub_category_id" key="sub_category_id" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="name">
              Session Name <span className="text-danger">*</span>
              <br />
              <small className="text-muted">
                (Please keep the name brief but impactful. For example,
                ‘Effectively Communicating with Executives’ or ‘Career Guidance
                in a post pandemic world’ etc.)
              </small>
            </label>
            <input
              className="form-control"
              placeholder="e.g. Effectively Communicating with Executives"
              type="text"
              id="name"
              name="name"
              maxLength="250"
              minLength="3"
              value={name}
              required
              onChange={(e) => onChange(e)}
              disabled={isConfirmation}

              // invalid={errorList.name ? true : false}
            />
            <Errors current_key="name" key="name" />
          </div>
        </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="image">Session Image</label>
                <>
                  <ImageCrop image={image} className="form-control" onComplete={onImageChange} onRemove={removeImage} aspect={5/3} />
                </>
              <Errors current_key="image" key="image" />
            </div>
          </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="overview">
              Overview <span className="text-danger">*</span>
              <br />
              <small className="text-muted">
                Provide brief overview of this session (effectively illustrate
                and outline what this session is about) (Min: 30)
              </small>
            </label>
            {/*<textarea
        cols={30}
        rows={3}
        className="form-control"
        id="overview"
        name="overview"
        required
        value={overview}
        onChange={(e) => onChange(e)}
        disabled={isConfirmation}
      />*/}
            <CKEditor
              data={overview}
              id="overview"
              name="overview"
              // className="form-control"
              config={{
                height: 200,
                toolbar: [
                  ["Source"],
                  ["Bold", "Italic", "Strike"],
                  ["Cut", "Copy", "Paste"],
                  ["Undo", "Redo"],
                  ["SpellChecker"],
                  ["Link", "Unlink", "Anchor"],
                  // ["Maximize"],
                  ["RemoveFormat"],
                  ["NumberedList", "BulletedList"],
                  ["DecreaseIndent", "IncreaseIndent"],
                  ["BlockQuote"],
                  ["Styles"],
                  ["Format"],
                ],
                readOnly: isConfirmation ? true : false,
                // uiColor: isConfirmation ? "#e9ecef" : "none",
              }}
              onChange={(event) => handelOverviewChange(event)}
              onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
              // disabled={isConfirmation}
              // invalid={errorList.expertise ? true : false}
            />
            <Errors current_key="overview" key="overview" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="ideal_for">
              Ideal for <span className="text-danger">*</span>
              <br />
              <small className="text-muted">
                Provide brief details (Min: 30)
              </small>
            </label>
            {/*<textarea
        cols={30}
        rows={3}
        className="form-control"
        id="ideal_for"
        name="ideal_for"
        value={ideal_for}
        onChange={(e) => onChange(e)}
        disabled={isConfirmation}
      />*/}
            <CKEditor
              data={ideal_for}
              id="ideal_for"
              name="ideal_for"
              config={{
                height: 200,
                toolbar: [
                  ["Source"],
                  ["Bold", "Italic", "Strike"],
                  ["Cut", "Copy", "Paste"],
                  ["Undo", "Redo"],
                  ["SpellChecker"],
                  ["Link", "Unlink", "Anchor"],
                  // ["Maximize"],
                  ["RemoveFormat"],
                  ["NumberedList", "BulletedList"],
                  ["DecreaseIndent", "IncreaseIndent"],
                  ["BlockQuote"],
                  ["Styles"],
                  ["Format"],
                ],
                readOnly: isConfirmation ? true : false,
              }}
              onChange={(event) => handelIdealForChange(event)}
              onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
              disabled={isConfirmation}
              // invalid={errorList.expertise ? true : false}
            />
            <Errors current_key="ideal_for" key="ideal_for" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="course_information">
              Session Information <br />
              <small className="text-muted">
                Provide Detailed session information (Min: 30)
              </small>
            </label>
            {/*<textarea
        cols={30}
        rows={5}
        className="form-control"
        id="course_information"
        name="course_information"
        value={course_information}
        onChange={(e) => onChange(e)}
        disabled={isConfirmation}
      />*/}

            <CKEditor
              data={course_information}
              id="course_information"
              name="course_information"
              config={{
                height: 200,
                toolbar: [
                  ["Source"],
                  ["Bold", "Italic", "Strike"],
                  ["Cut", "Copy", "Paste"],
                  ["Undo", "Redo"],
                  ["SpellChecker"],
                  ["Link", "Unlink", "Anchor"],
                  // ["Maximize"],
                  ["RemoveFormat"],
                  ["NumberedList", "BulletedList"],
                  ["DecreaseIndent", "IncreaseIndent"],
                  ["BlockQuote"],
                  ["Styles"],
                  ["Format"],
                ],
                readOnly: isConfirmation ? true : false,
              }}
              onChange={(event) => handelCourseInformationChange(event)}
              onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
              disabled={isConfirmation}
              // invalid={errorList.expertise ? true : false}
            />
            <Errors current_key="course_information" key="course_information" />
          </div>
        </div>
      </div>
      {category_type == "specific" && (
        <fieldset className="box p-3 col-md-12 mb-0 mt-3">
          <legend>Session offering</legend>

          <div className="row" id="chkGroup">
            <div className="col-md-12">
              <div className="form-group mb-1">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="is_individual"
                    id="is_individual"
                    checked={is_individual}
                    onChange={(e) => onChange(e, true)}
                    disabled={isConfirmation || (isEdit && !is_edit_individual)}
                  />
                  <label className="form-check-label" htmlFor="one">
                    I want to offer 1-1 interactions for this session
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group mb-0">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="is_group"
                    id="is_group"
                    checked={is_group}
                    onChange={(e) => onChange(e, true)}
                    disabled={isConfirmation || (isEdit && !is_edit_group)}
                  />
                  <label className="form-check-label" htmlFor="group">
                    I want to offer group interactions for this session
                  </label>
                </div>
              </div>
            </div>
            {/* {isConfirmation && (
            <div className="col-md-12">
              <hr />
            </div>
          )} */}
            <Errors current_key="course_type" key="course_type" />
          </div>
          {courseDetail}
        </fieldset>
      )}{" "}
    </>
  );

  return (
    <section className="card-body">
      {!isConfirmation && basicErrorMsg != "" && (
        <div className="alert alert-danger fade show" role="alert">
          {basicErrorMsg}
        </div>
      )}

      <form
        onSubmit={(e) => {
          onCourseInformationSubmit(e);
        }}
      >
        {isConfirmation ? (
          <fieldset className="box p-3 col-md-12 mb-0 mt-3">
            <legend>Session Information</legend>
            <button
              className="action editIcon"
              onClick={(e) => setActiveTab(TABS.BASIC)}
            >
              <i className="fa fa-pencil"></i> &nbsp;Edit
            </button>
            {courseDetail}
          </fieldset>
        ) : (
          courseDetail
        )}
        {!isConfirmation && (
          <div className="row">
            <div className="col-md-12">
              <hr />
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  {/* <a href="#" className="btn btn-secondary">Back</a> */}
                </div>
                <div className="col-md-4" />
                <div className="col-md-4">
                  <div className="form-group text-right mb-0">
                    <button
                      className="btn btn-secondary mb-0 mr-3"
                      onClick={(e) => onClickDraftForm(e)}
                    >
                    Save Draft
                    </button>
                    <input
                      type="submit"
                      className="btn btn-secondary mb-0"
                      disabled={invalidImage}
                      value="Next"
                      onClick={(e) => onCourseInformationSubmit(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </section>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  authLoading: state.auth.loading,
  categories: state.categories
});

export default connect(mapStateToProps)(withRouter(CourseInformation));
