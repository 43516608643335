/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
import * as moment from "moment";
import Spinner from "views/Spinner";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import {
  getCoursesList,
  changeStatus,
  deleteCourse,
  getFullCoursesList,
} from "actions/admin/courses";
import * as Constants from "constants/index";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import overlayFactory from "react-bootstrap-table2-overlay";
import CustomFilters from "./CustomFilters";
import { CSVLink } from "react-csv";

import { Button, Card, CardBody, Col, Row, Input } from "reactstrap";

const CoursesList = ({
  getCoursesList,
  changeStatus,
  courseList: { data, count, page },
  loading,
  subLoading,
  deleteCourse,
}) => {
  //################## Initillise sorting searching parameters by default values ###################
  const [userParams, setUserParams] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "start_at",
    ascending: "desc",
    query: "",
  });

  const [courseList, setCourseList] = useState([]);
  const [totalRecord, setTotalRecords] = useState(0);

  //################## to get updated list ###################
  useEffect(() => {
    getCoursesList(userParams).then((res) => {
      setTotalRecords(res.metadata[0].totalRecord);
    });
  }, [userParams]);

  useMemo(() => {
    if (totalRecord > 0) {
      getFullCoursesList(totalRecord).then((res) => {
        let arr = res.data.map((item) => {
          console.log(item);
          let obj = {};
          obj.name = item.name;
          obj.parent_category_name = item.parent_category_name[0];
          obj.mentor_name = item.mentor_name;
          obj.created_at = moment(item.created_at).format("MM/DD/YYYY");
          obj.course_type = Constants.SESSION_TYPE_REVERSE[item.course_type];
          obj.event_date =((item.course_type == 5)
              ? ( moment
                  .unix(item.session_start?.start_at)
                  .format("MM/DD/YYYY HH:mm") +
                "-" +
                moment
                  .unix(item.session_end?.start_at)
                  .add(
                      item.duration_info.slice(-1)?.[0]?.duration_per_session,
                    "minutes"
                  )
                  .format("MM/DD/YYYY HH:mm"))
              : (moment
                  .unix(item.session_start?.start_at)
                  .format("MM/DD/YYYY HH:mm") +
                "-" +
                moment
                  .unix(item.session_start?.end_at)
                  .format("MM/DD/YYYY HH:mm")));
          return obj;
        });
        setCourseList(arr);
      });
    }
  }, [totalRecord]);

  //################## Handel table change(sorting, searching, status change) ###################
  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    setUserParams({
      ...userParams,
      page: type === "search" ? 1 : page,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      query: searchText,
    });
  };

  const CSVHeaders = [
    { label: "Name", key: "name" },
    { label: "Event Date", key: "event_date" },
    { label: "Category Name", key: "parent_category_name" },
    { label: "Host Name", key: "mentor_name" },
    { label: "Type", key: "course_type" },
    { label: "Date Created", key: "created_at" },
  ];

  const DownloadXls = () => (
      <a href="#" className="download-xls">
      <CSVLink
        className="fa fa-file-excel-o text-orange "
        data={courseList}
        headers={CSVHeaders}
        filename={`Courses-List ${moment
          .unix(moment().utc().unix())
          .format("MM/DD/YYYY HH:MM:ss")}.csv`}
      >
        {" "}
        Download
      </CSVLink>
    </a>
  );

  //#########################Colums defination start ###################
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      // headerSortingStyle
    },
    {
      dataField: "start_at",
      text: "Event Date",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      formatter: (cellContent, row) => {
        return (
          <>
            <p className="mb-1">
              {row.course_type == 5 ? (
                <span>
                  <span>
                    {moment
                      .unix(row.session_start?.start_at)
                      .format("DD MMM, YYYY HH:mm")}
                  </span>{" "}
                  -
                  <br />
                  <span>
                    {moment
                      .unix(row.session_end?.start_at)
                      .add(
                        Number(
                          row.duration_info.slice(-1)[0]?.duration_per_session
                        ),
                        "minutes"
                      )
                      .format("DD MMM, YYYY HH:mm")}
                  </span>
                </span>
              ) : (
                <span>
                  <span>
                    {moment
                      .unix(row.session_start?.start_at)
                      .format("DD MMM, YYYY HH:mm")}
                  </span>{" "}
                  -
                  <br />
                  <span>
                    {moment
                      .unix(row.session_end?.end_at)
                      .format("DD MMM, YYYY HH:mm")}
                  </span>
                </span>
              )}
            </p>
          </>
        );
      },
    },
    {
      dataField: "parent_category_name",
      text: "Category Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "mentor_name",
      text: "Host Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "course_type",
      text: "Type",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      formatter: (cellContent, row) => (
        <div>
          {row.course_type
            ? Constants.SESSION_TYPE_REVERSE[row.course_type]
            : "-"}
        </div>
      ),
    },
    {
      dataField: "created_at",
      text: "Date Created",
      sort: true,
      // headerSortingStyle,
      formatter: (cellContent, row) => (
        <Moment format="MM/DD/YYYY">{Number(row.created_at)}</Moment>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: (cellContent, row) => (
        <div>
          <Link to={`/admin/courses/${row._id}`}>
            <Button type="button" size="sm" color="success">
              {/* <i className="fa fa-eye"></i> */}
              Details
            </Button>
          </Link>
          <Button
            type="button"
            size="sm"
            color="danger"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure you want to delete :\r ${row.name}?`
                )
              ) {
                deleteCourse(row._id).then((res) => {
                  if (res) getCoursesList(userParams);
                });
              }
            }}
          >
            {/* <i className="fa fa-trash"></i> */}
            Delete
          </Button>
        </div>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
  ];

  //#########################Colums defination end ###################

  //################## START Set properties(options) of datatables ###################

  // const headerSortingStyle = { backgroundColor: '#c8e6c9' };
  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li
      key={text}
      role="presentation"
      className="dropdown-item"
      onMouseDown={(e) => {
        e.preventDefault();
        onSizePerPageChange(page);
      }}
    >
      <Button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        className="sizePerPageaTag"
      >
        {text}
      </Button>
    </li>
  );

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <>
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    </>
  );

  const options = {
    page: parseInt(userParams.page),
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: count,
    // sizePerPageRenderer,
    sizePerPageOptionRenderer,
    sizePerPageList: [
      {
        text: Constants.DEFAULT_PAGE_SIZE,
        value: Constants.DEFAULT_PAGE_SIZE,
      },
      {
        text: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
        value: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
      },
      {
        text: "All",
        value: count,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <div className="animated fadeIn displayTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(options)}
                keyField="email"
                columns={columns}
                data={data}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="email"
                    data={data}
                    columns={columns}
                    search
                  >
                    {(toolkitprops) => (
                      <>
                        <>
                          <div className="per-page-div">
                            Record per page:
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <DownloadXls />
                          <CustomFilters {...toolkitprops.searchProps} />
                        </>
                        <BootstrapTable
                          {...toolkitprops.baseProps}
                          bootstrap4
                          remote={{
                            pagination: true,
                            filter: true,
                            sort: true,
                          }}
                          keyField="email"
                          data={data}
                          columns={columns}
                          {...paginationTableProps}
                          onTableChange={handleTableChange}
                          defaultSorted={defaultSorted}
                          noDataIndication={Constants.NO_RECORD}
                          bordered={false}
                          hover
                          loading={loading && !subLoading}
                          overlay={overlayFactory({
                            spinner: <Spinner />,
                            styles: {
                              overlay: (base) => ({
                                ...base,
                                background: "#E8E8E8",
                              }),
                            },
                          })}
                        />
                      </>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

CoursesList.propTypes = {
  getCoursesList: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  courseList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  courseList: state.courses.coursesList,
  loading: state.courses.loading,
  subLoading: state.courses.isSubLoading,
});

export default connect(mapStateToProps, {
  getCoursesList,
  getFullCoursesList,
  changeStatus,
  deleteCourse,
})(withRouter(CoursesList));
