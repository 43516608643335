import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllCourses, getDraftAllCourses } from "actions/front/common/course";
import { changeStatus, deleteCourse } from "actions/front/educator/courses";
import CompletedSession from "./CompletedSession";
import MyCompletedSession from "../MyCourses/CompletedSession";
import UpcomingSession from "./UpcomingSession";
import ListedDraftCourses from "./ListedDraftCourses";

const CourseManagement = (props) => {
  const RESULTS = {
    MY_LISTED_COURSES: 1,
    UPCOMING_SESSION: 2,
    COMPLETED_SESSION: 3,
    ALL: 4,
    DRAFT_COURSES: 5,
  };

  const COURSE_LIST = {
    UPCOMING: 1,
    COMPLETED: 2,
    ALL: 3,
  };

  const COMPLETED_TYPE = {
    HOSTED: 0,
    ATTENDED: 1
  }

  const [activeResults, setActiveResults] = useState(
    props.location.activeResult && props.location.activeResult === 1
      ? RESULTS.UPCOMING_SESSION
      : props.location.activeResult === 2
      ? RESULTS.DRAFT_COURSES
      : props.location.activeResult === 3
      ? RESULTS.COMPLETED_SESSION
      : RESULTS.UPCOMING_SESSION
      ? RESULTS.UPCOMING_SESSION
      : RESULTS.UPCOMING_SESSION
  );

  const [completedType, setCompletedType] = useState(COMPLETED_TYPE.HOSTED);

  return (
    <div className="col-lg-9">
      <div className="row">
        <div className="col-md-12">
        <Link to="/dashboard">Back to Calendar</Link>
          <div className="section-head mb-4 flex justify-between">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeResults === RESULTS.UPCOMING_SESSION && "active"
                  }`}
                  id="upcoming-tab"
                  onClick={(e) => setActiveResults(RESULTS.UPCOMING_SESSION)}
                >
                  Upcoming Sessions
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeResults === RESULTS.DRAFT_COURSES && "active"
                  }`}
                  id="upcoming-tab"
                  onClick={(e) => setActiveResults(RESULTS.DRAFT_COURSES)}
                >
                  Draft Sessions
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeResults == RESULTS.COMPLETED_SESSION && "active"
                  }`}
                  id="completed-tab"
                  onClick={(e) => setActiveResults(RESULTS.COMPLETED_SESSION)}
                >
                  Completed sessions{" "}
                </button>
              </li>
            </ul>
            <Link to="/dashboard/new" className="btn btn-secondary ml-3">
              Manage Availability and Offer Sessions/Events
            </Link>
          </div>
          <div className="tab-content" id="myTabContent">
            {activeResults === RESULTS.UPCOMING_SESSION && (
              <UpcomingSession RESULTS={RESULTS} />
            )}
            {activeResults === RESULTS.DRAFT_COURSES && (
              <ListedDraftCourses RESULTS={RESULTS} />
            )}
            {activeResults === RESULTS.COMPLETED_SESSION && (
              <>
                <ul className="nav nav-tabs" style={{display: "inline-flex"}} id="myTab" role="tablist">
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        completedType === COMPLETED_TYPE.HOSTED && "active"
                      }`}
                      id="upcoming-tab"
                      onClick={(e) =>
                        setCompletedType(COMPLETED_TYPE.HOSTED)
                      }
                    >
                      Hosted
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        completedType === COMPLETED_TYPE.ATTENDED && "active"
                      }`}
                      id="upcoming-tab"
                      onClick={(e) =>
                        setCompletedType(COMPLETED_TYPE.ATTENDED)
                      }
                    >
                      Attended
                    </button>
                  </li>
                </ul>
                <div className="tab-content pt-4" id="myTabContent">
                  {completedType === COMPLETED_TYPE.HOSTED ? (
                    <CompletedSession RESULTS={RESULTS} />
                  ) : (
                    <MyCompletedSession COURSE_LIST={COURSE_LIST}  />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_id: state.auth.user._id,
});

export default connect(mapStateToProps, {
  getDraftAllCourses,
  getAllCourses,
  changeStatus,
  deleteCourse,
})(withRouter(CourseManagement));
