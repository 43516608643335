import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types';

import { getHostApplicationById, updateHostApplication } from "actions/admin/hostApplications";
import Spinner from "views/Spinner";
import Moment from "react-moment";
import { useParams, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Button,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const ApplicationDetail = ({ match }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (match.params.id) {
      getHostApplicationById(match.params.id).then((data) => {
        setApplication(data.data.data.response);
        setLoading(false);
      });
    }
  }, []);

  const changeApplication = async (id, status) => {
    setSubmitting(true);
    await updateHostApplication(id, status)
    setSubmitting(false);
    history.push("/admin/host-applications");
  }

  return loading ? (
    <Spinner />
  ) : (
    application != null && (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardBody>
                <div className="form-group">
                  <div>
                    <strong>Name : </strong> {application.first_name}{" "}
                    {application.last_name}
                  </div>
                  <div>
                    <strong>Email :</strong> {application.email}{" "}
                  </div>
                  <div>
                    <strong>City & Country :</strong> {application.city}{" "}
                    {application.country}
                  </div>
                  <div>
                    <strong>Languages :</strong>{" "}
                    {application.languages.join(", ")}
                  </div>
                  <div>
                    <strong>About :</strong> {application.about}{" "}
                  </div>
                  <div>
                    <strong>Experience :</strong> {application.experience}{" "}
                  </div>
                  <div>
                    <strong>Tier :</strong> {application.host_tier}{" "}
                  </div>
                  <div>
                    <strong>Documents :</strong>{" "}
                    {application.documents && application.documents.length > 0
                      ? application.documents.map((doc) => (
                          <p>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={`${process.env.REACT_APP_MEDIA_URL}${doc}`}
                            >
                              {doc}
                            </a>
                          </p>
                        ))
                      : "None"}{" "}
                  </div>

                  <div>
                    <strong> Date Submitted : </strong>
                    <Moment format="YYYY/MM/DD">
                      {Number(application.submitted_date)}
                    </Moment>{" "}
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <div>
                  <Button type="button" size="sm" color="success" onClick={async e => {
                    if(window.confirm("This will change the user to host. Are you sure?")) {
                      console.log(application);
                      await changeApplication(application._id, "accepted")
                    }
                  }}>
                    APPROVE
                  </Button>
                  <Button type="button" size="sm" color="danger" onClick={async e => {
                    if(window.confirm("This will deny the host request. Are you sure?")) {
                      await changeApplication(application._id, "rejected")
                    }
                  }}>
                    DENY
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    )
  );
};

export default ApplicationDetail;
