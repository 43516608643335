//@DEPRECATED
/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
import * as moment from "moment";
import Spinner from "views/Spinner";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import {
  getLearnersList,
  changeStatus,
  deleteUser,
  getFullLearnersList,
} from "actions/admin/learner";
import * as Constants from "constants/index";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import overlayFactory from "react-bootstrap-table2-overlay";
import CustomFilters from "./CustomFilters";
import { CSVLink } from "react-csv";

import { Button, Card, CardBody, Col, Row, Input } from "reactstrap";
import { getTimeZone } from "utils/time";

const SpeakerList = ({
  getLearnersList,
  changeStatus,
  deleteUser,
  learnerList: { data, count, page },
  loading,
  subLoading,
}) => {
  //################## Initillise sorting searching parameters by default values ###################
  const [userParams, setUserParams] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
  });

  const [learnerList, setLearnerList] = useState([]);
  const [totalRecord, setTotalRecords] = useState(0);

  //################## to get updated list ###################
  useEffect(() => {
    getLearnersList(userParams).then((res) => {
      setTotalRecords(res.metadata[0].totalRecord);
    });
  }, [userParams]);

  useMemo(() => {
    console.log("==========userMemo");
    if (totalRecord > 0) {
      getFullLearnersList(totalRecord).then((res) => {
        let arr = [];
        res.data.map((item) => {
          let obj = {};
          obj.name = item.name;
          obj.username = item.username;
          obj.email = item.email;
          obj.subscribe_date = item.subscriptions
            ? item.subscriptions.find((i) => i.status === "active")
              ? moment(
                  item.subscriptions.find((i) => i.status === "active")
                ).format("YYYY/MM/DD")
              : ""
            : "";
          obj.unsubscribe_date =
            item.premium_ends_at || item.free_trial_ends_at
              ? moment
                  .unix(item.premium_ends_at || item.free_trial_ends_at)
                  .format("MM/DD/YYYY")
              : "";
          obj.created_at = moment(item.created_at).format("MM/DD/YYYY");
          obj.status = getUserSubscriptionStatus(item);
           obj.location = [
            item.country,
            item.state,
            item.city,
          ].filter(Boolean).join(", ");
          obj.statusTracking = getUserStatusTracking(item,true)
          console.log("====",obj.statusTracking );
          arr.push(obj);
        });
        setLearnerList(arr);
      });
    }
  }, [totalRecord]);

  //################## Handel table change(sorting, searching, status change) ###################
  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    setUserParams({
      ...userParams,
      page: type === "search" ? 1 : page,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      query: searchText,
    });
  };

  const CSVHeaders = [
    { label: "Name", key: "name" },
    { label: "Location", key: "location" },
    { label: "Created At", key: "created_at" },
    { label: "Email", key: "email" },
    { label: "Status", key: "status" },
    { label: "Status Tracking", key: "statusTracking" },
  ];

  const DownloadXls = () => (
    <a href="#" className="download-xls">
      <CSVLink
        className="fa fa-file-excel-o text-orange "
        data={learnerList}
        headers={CSVHeaders}
        filename={`Learners-List ${moment
          .unix(moment().utc().unix())
          .format("MM/DD/YYYY HH:MM:ss")}.csv`}
      >
        {" "}
        Download
      </CSVLink>
    </a>
  );

  //#########################Colums defination start ###################
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: { backgroundColor: Constants.TABLE_BORDER_COLOR },
    },
    {
      dataField: "",
      text: "Location",
      sort: true,
      headerStyle: { backgroundColor: Constants.TABLE_BORDER_COLOR },
      formatter: (cellContent, row) =>
        [row.country, row.state, row.city].filter(Boolean).join(", "),
    },
    {
      dataField: "created_at",
      text: "Date Created",
      sort: true,
      // headerSortingStyle,
      formatter: (cellContent, row) => (
        <Moment format="DD MMM YYYY">{Number(row.created_at)}</Moment>
      ),
      headerStyle: { backgroundColor: Constants.TABLE_BORDER_COLOR },
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: { backgroundColor: Constants.TABLE_BORDER_COLOR },
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: { backgroundColor: Constants.TABLE_BORDER_COLOR },
      formatter: (cellContent, row) => (
        <div>{getUserSubscriptionStatus(row)}</div>
      ),
    },
    {
      dateField: "",
      text: "Status Tracking",
      headerStyle: { backgroundColor: Constants.TABLE_BORDER_COLOR },
      formatter: (cellContent, row) => <div>{getUserStatusTracking(row)}</div>,
    },
    // {
    //   dataField: "",
    //   text: "Subscribe Date",
    //   headerStyle: { backgroundColor: Constants.TABLE_BORDER_COLOR },
    //   formatter: (cellContent, row) =>
    //     row.subscriptions?.find((i) => i.status === "succeeded") ? (
    //       <Moment
    //         date={
    //           row.subscriptions.find((i) => i.status === "succeeded").created_at
    //         }
    //         format="DD MMM YYYY"
    //       ></Moment>
    //     ) : (
    //       ""
    //     ),
    // },
    // {
    //   dataField: "",
    //   text: "Unsubscribe Date",
    //   headerStyle: { backgroundColor: Constants.TABLE_BORDER_COLOR },
    //   formatter: (cellContent, row) =>
    //     row.premium_ends_at || row.free_trial_ends_at ? (
    //       <Moment format="DD MMM YYYY">
    //         {(row.premium_ends_at || row.free_trial_ends_at) * 1000}
    //       </Moment>
    //     ) : (
    //       <div className="text-center">-</div>
    //     ),
    // },
    // {
    //   dataField: "",
    //   text: "Status Tracking",
    //   headerStyle: { backgroundColor: Constants.TABLE_BORDER_COLOR },
    // }
    // {
    //   dataField: "status",
    //   text: "Status",
    //   sort: true,
    //   headerStyle: { backgroundColor: Constants.TABLE_BORDER_COLOR },

    //   formatter: (cell, row) => {
    //     return (
    //       <div>
    //         <Input
    //           type="select"
    //           name="status"
    //           id={row._id}
    //           defaultValue={cell}
    //           onChange={(e, a) => {
    //             changeStatus(row._id, e.target.value);
    //           }}
    //         >
    //           {cell === 0 ? <option value="0">Pending</option> : ""}
    //           <option value="1">Active</option>
    //           <option value="2">UnVerified</option>
    //           <option value="3">Block</option>
    //         </Input>
    //       </div>
    //     );
    //   },
    // },
    {
      dataField: "_id",
      text: "Actions",
      formatter: (cellContent, row) => (
        <div>
          <Link to={`/admin/speakers-and-listeners/${row._id}`}>
            <Button type="button" size="sm" color="success">
              <i className="fa fa-eye"></i>Details
            </Button>
          </Link>
          <Button
            type="button"
            size="sm"
            color="danger"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure you want to delete :\r ${row.name}?`
                )
              ) {
                deleteUser(row._id).then((res) => {
                  if (res) getLearnersList(userParams);
                });
              }
            }}
          >
            <i className="fa fa-trash"></i>Delete
          </Button>
          {row.status !== Constants.USER_STATUS_REVERSE.UNVERIFIED ? (
            <Button
              type="button"
              size="sm"
              color="info"
              onClick={(e) => {
                if (row.status === Constants.USER_STATUS_REVERSE.ACTIVE) {
                  if (
                    window.confirm(
                      `Are you sure you want to block :\r ${row.name}?`
                    )
                  ) {
                    changeStatus(
                      row._id,
                      Constants.USER_STATUS_REVERSE.BLOCKED
                    );
                  }
                } else {
                  if (
                    window.confirm(
                      `Are you sure you want to activate :\r ${row.name}?`
                    )
                  ) {
                    changeStatus(row._id, Constants.USER_STATUS_REVERSE.ACTIVE);
                  }
                }
              }}
            >
              <i className="fa fa-trash"></i>
              {row.status === Constants.USER_STATUS_REVERSE.ACTIVE
                ? "Block User"
                : "Unblock User"}
            </Button>
          ) : null}
        </div>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
  ];

  //#########################Colums defination end ###################

  //################## START Set properties(options) of datatables ###################

  // const headerSortingStyle = { backgroundColor: '#c8e6c9' };
  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li
      key={text}
      role="presentation"
      className="dropdown-item"
      onMouseDown={(e) => {
        e.preventDefault();
        onSizePerPageChange(page);
      }}
    >
      <Button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        className="sizePerPageaTag"
      >
        {text}
      </Button>
    </li>
  );

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <>
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    </>
  );

  const options = {
    page: parseInt(userParams.page),
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: count,
    // sizePerPageRenderer,
    sizePerPageOptionRenderer,
    sizePerPageList: [
      {
        text: Constants.DEFAULT_PAGE_SIZE,
        value: Constants.DEFAULT_PAGE_SIZE,
      },
      {
        text: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
        value: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
      },
      {
        text: "All",
        value: count,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <div className="animated fadeIn displayTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(options)}
                keyField="email"
                columns={columns}
                data={data}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="email"
                    data={data}
                    columns={columns}
                    search
                  >
                    {(toolkitprops) => (
                      <>
                        <>
                          <div className="per-page-div">
                            Record per page:
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <DownloadXls />
                          <CustomFilters {...toolkitprops.searchProps} />
                        </>
                        <BootstrapTable
                          {...toolkitprops.baseProps}
                          bootstrap4
                          remote={{
                            pagination: true,
                            filter: true,
                            sort: true,
                          }}
                          keyField="email"
                          data={data}
                          columns={columns}
                          {...paginationTableProps}
                          onTableChange={handleTableChange}
                          defaultSorted={defaultSorted}
                          noDataIndication={Constants.NO_RECORD}
                          bordered={false}
                          hover
                          loading={loading && !subLoading}
                          overlay={overlayFactory({
                            spinner: <Spinner />,
                            styles: {
                              overlay: (base) => ({
                                ...base,
                                background: "#E8E8E8",
                              }),
                            },
                          })}
                        />
                      </>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

SpeakerList.propTypes = {
  getLearnersList: PropTypes.func.isRequired,
  // deleteUser: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  learnerList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  learnerList: state.learner.learnersList,
  loading: state.learner.loading,
  subLoading: state.educator.isSubLoading,
});

export default connect(mapStateToProps, {
  getLearnersList,
  getFullLearnersList,
  changeStatus,
  deleteUser,
})(withRouter(SpeakerList));

const USER_SUBSCRIPTION_STATUS = {
  Subscribed: "Active",
  Grace: "Grace",
  Unsubscribed: "Not Subscribed",
  FreeTrial: "Free Trial", // might make it same as .Subscribed
  Listener: "Listener",
  OnePass: "One Pass",
  PackagePass: "Package Pass",
  MonthlyPass: "Monthly Pass",
};

function getUserSubscriptionStatus(user) {
  let activeScription = user.subscriptions?.find(
    (i) => i.status === "succeeded"
  );
  let is_premium = false;
  if (
    activeScription ||
    moment(user.free_trial_ends_at * 1000).isAfter(new Date())
  ) {
    is_premium = true;
  }
  if (user.status === Constants.USER_STATUS_REVERSE.UNVERIFIED) {
    return "Not Verified";
  }
  if (!is_premium && !activeScription) {
    return USER_SUBSCRIPTION_STATUS.Listener;
  }
  if (is_premium) {
    if (
      user.free_trial_ends_at &&
      moment(user.free_trial_ends_at * 1000).isAfter(new Date())
    ) {
      return USER_SUBSCRIPTION_STATUS.FreeTrial;
    } else {
      // 判断是月卡 | 次数卡
      if (activeScription.ismonthly) {
        // 判断月卡是否过期或者锁卡了
        if (
          moment(activeScription.ends_at * 1000).isAfter(new Date()) &&
          activeScription.frequency.length < activeScription.rule[0].limitation
        ) {
          return USER_SUBSCRIPTION_STATUS.MonthlyPass;
        } else {
          return USER_SUBSCRIPTION_STATUS.Listener;
        }
      } else {
        let now = moment().unix();
        let passEndList = activeScription.frequency.filter(item => now > item.end_at)
        let total = activeScription.total
        if (activeScription.pass > 0 && passEndList.length < total) {
          if (activeScription.type == 1) {
            return USER_SUBSCRIPTION_STATUS.OnePass;
          } else {
            return USER_SUBSCRIPTION_STATUS.PackagePass;
          }
        } else {
          return USER_SUBSCRIPTION_STATUS.Listener;
        }
      }
    }
  }

  // if (user.is_premium) {
  //   if (!user.premium_ends_at) {
  //     return USER_SUBSCRIPTION_STATUS.Subscribed;
  //   } else if (
  //     user.free_trial_ends_at &&
  //     moment(user.free_trial_ends_at * 1000).isAfter(new Date())
  //   ) {
  //     return USER_SUBSCRIPTION_STATUS.FreeTrial;
  //   } else if (
  //     user.premium_ends_at &&
  //     moment(user.premium_ends_at * 1000).isAfter(new Date())
  //   ) {
  //     return USER_SUBSCRIPTION_STATUS.Grace;
  //   }
  // } else {
  //   if (
  //     user.free_trial_ends_at &&
  //     moment(user.free_trial_ends_at * 1000).isAfter(new Date())
  //   ) {
  //     return USER_SUBSCRIPTION_STATUS.FreeTrial;
  //   }
  //   return USER_SUBSCRIPTION_STATUS.Unsubscribed;
  // }
}

function getUserStatusTracking(user,date=false) {
  let dateForm = date ? "MM/DD/YYYY" : "DD MMM YYYY"
  let activeScription = user.subscriptions?.find(
    (i) => i.status === "succeeded"
  );
  let is_premium = false;
  if (
    activeScription ||
    moment(user.free_trial_ends_at * 1000).isAfter(new Date())
  ) {
    is_premium = true;
  }
  if (!is_premium) return "Blank";
  if (is_premium) {
    if (
      user.free_trial_ends_at &&
      moment(user.free_trial_ends_at * 1000).isAfter(new Date())
    ) {
      return (
        moment
            .unix(user.free_trial_ends_at)
            .format(dateForm)
      );
    } else {
      if (activeScription.ismonthly) {
        if (
          moment(activeScription.ends_at * 1000).isAfter(new Date()) &&
          activeScription.frequency.length < activeScription.rule[0].limitation
        ) {
          return moment
            .unix(activeScription.ends_at)
            .format(dateForm);
        } else {
          return "Blank";
        }
      } else {
        let now = moment().unix();
        let passEndList = activeScription.frequency.filter(item => now > item.end_at)
        let total = activeScription.total
        if (activeScription.pass > 0 && passEndList.length < total) {
          return activeScription.pass;
        } else {
          console.log("=============2222");
          return "Blank";
        }
      }
    }
  }
}
