import {
    STATISTICS_LOADING,
    STATISTICS_SUCCESS,
    STATISTICS_FAILED
} from "actions/types";

const initalState = {
    loading: false,
    data: null,
    error: null
};

export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
        case STATISTICS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case STATISTICS_SUCCESS:
            return {
                ...state,
                data: payload.data,
                loading: false,
            };
        case STATISTICS_FAILED: 
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;
    }
}