import { ReactComponent as CoinsHandIcon } from "assets/images/icons/coins-hand.svg";
import { ReactComponent as CheckCircleIcon } from "assets/images/icons/check-circle.svg";
import { ReactComponent as AnnouncementIcon } from "assets/images/icons/announcement.svg";
import Header from "views/Front/Layout/Header";
import Button from "../../../../components/common/Button";
import IconCircle from "../IconCircle";
import BecomeAHostApplicationProcess from "./ApplicationProcess";
import BecomeAHostRequirements from "./Requirements";
import BecomeAHostFAQ from "./FAQ";
import BecomeAHostCTA from "./CTA";
import CenterContainer from "components/common/Container/Center";
import Footer from "views/Front/Layout/Footer";

export default function HomePageBecomeAHost() {
  return (
    <>
      <Header className="bg-dgray-800 text-white" light />
      <CenterContainer className="bg-dgray-800 text-center pt-24 pb-48 lg:pb-40">
        <h1 className="text-white text-5xl font-semibold mb-6">
          Become a Host
        </h1>
        <p className="text-dgray-200 text-xl mb-10">
          Apply to become a DDN host. Meet, mentor and interact with users
          around the world.
        </p>
        <Button to="/become-a-host/apply" small className="md:w-full py-3">
          Get started
        </Button>
      </CenterContainer>
      <CenterContainer>
        <div className="border-b border-dgray-100 pb-14">
          <div className="bg-dgray-50 rounded-3xl text-center p-16 -mt-24">
            <h2 className="text-4xl lg:text-3xl font-semibold mb-11">
              Why Become a Host?
            </h2>
            <div className="flex gap-8 lg:flex-col">
              {whyItems.map(({ Icon, title, description }) => (
                <div className="flex-1">
                  <IconCircle className="mx-auto mb-4">
                    <Icon />
                  </IconCircle>
                  <div className="text-xl font-semibold mb-2">{title}</div>
                  <div className="text-dgray-700">{description}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <BecomeAHostRequirements />
      </CenterContainer>
      <BecomeAHostApplicationProcess />
      <BecomeAHostFAQ />
      <BecomeAHostCTA />
      <Footer />
    </>
  );
}

const whyItems = [
  {
    Icon: CoinsHandIcon,
    title: "Monetize your language skill",
    description:
      "You get paid from your one-on-one sessions and from hosting public events",
  },
  {
    Icon: AnnouncementIcon,
    title: "Marketing channel for hosts",
    description:
      "DDN provides various tools to promote your profile and your personal brand",
  },
  {
    Icon: CheckCircleIcon,
    title: "Integrated Solution",
    description:
      "DDN takes care of all the technology you need and puts you in the driver’s seat -scheduling, payment and video conferencing",
  },
];
