import React, { useState, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import bg5 from "assets/images/bg/how-it-works.jpg";
import img1 from "assets/images/sign-up-mentor.png";
import img2 from "assets/images/schedule-executed.png";
import img3 from "assets/images/offer-session.png";
import img4 from "assets/images/get-paid-reviewed.png";

const ForMentor = () => {
  return (
    <div>
      <div className="row" id="banner">
        <div id="inner-banner" className="col-md-12 p-0">
          <img src={bg5} alt="" className="img-fluid" />
        </div>
      </div>
      <div className="row p-60">
        <div className="container cuetpoints">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-theme-orange">For Mentors</h2>
              <p>
                DingDingNow is an integrated and global platform that welcomes
                people from all walks of life and background to sign up and
                connect with each other. When we say “Connect”, it is a real
                live people to people interaction through our advanced and
                  all-in-one scheduling and interactive technology.{" "}
              </p>
            </div>
          </div>
          <div className="row p-60">
            <div className="col-md-6">
              <strong>Sign Up as a mentor</strong>
              <ul>
                <li className="disc">
                  It's free to sign up as a Mentor and you can also switch
                  to Leaner after sign-up
                    </li>
                <li className="disc">
                  You are encouraged to complete your profile on MyDDN, a
                  personalized dashboard and advanced tool that gives you
                  the power to run your own business
                    </li>
              </ul>
            </div>
            <div className="col-md-6">
              <img src={img1} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="row p-60">
            <div className="col-md-6">
              <img src={img3} alt="" className="img-fluid" />
            </div>
            <div className="col-md-6">
              <strong>Offer Sessions & Manage Availability</strong>
              <ul>
                <li className="disc">
                  Offer sessions, set the price and design your schedules on
                  My DDN
                    </li>
                <li className="disc">
                  DDN offers two session types: Specific and General. You
                  can offer sessions focused on a specific topic - either
                  1-1 or grouping setting. Or you can offer a General
                  session to engage with learners for any topics under "DD
                  Me for Anything"
                    </li>
              </ul>
            </div>
          </div>
          <div className="row p-60">
            <div className="col-md-6">
              <strong>Learner Schedule and Session Executed</strong>
              <ul>
                <li className="disc">
                  You have the ability to generate a secure meeting URL and kick off the meeting
                   session at least 5 mins before the start of your session on the scheduled
                    date and time. No need to have your own licenses or tools
                </li>
                <li className="disc">
                  Start the conversation and Enjoy the mentoring!
                    </li>
              </ul>
            </div>
            <div className="col-md-6">
              <img src={img2} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="row p-60">
            <div className="col-md-6">
              <img src={img4} alt="" className="img-fluid" />
            </div>
            <div className="col-md-6">
              <strong>Get Paid and Get Reviewed</strong>
              <ul>
                <li className="disc">DDN payout and Learner Review</li>
                <li className="disc">Your success is our success!</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

ForMentor.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ForMentor);
