import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { getMycourseSessions } from "actions/front/learner/courses";
import { DURATION_LIST } from "constants/index";
import * as moment from 'moment-timezone';
import Pagination from "views/Front/Pagination";
import { RECORD_PER_PAGE, COURSE_VIEW } from "constants/index";
import { getCurrentTimeZone } from "utils/time";
import Courses from "views/Front/ProfileCourseMgmt/CourseManagement/Courses";

const AllSession = ({ COURSE_LIST, getMycourseSessions, user }) => {
  let timeZone = getCurrentTimeZone(user);

  const [loader, setLoader] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [duration, setDuration] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const activeResults = COURSE_LIST.ALL;
  useMemo(() => {
    setLoader(true);
    getMycourseSessions(COURSE_LIST.ALL, page, duration).then((res) => {
      if (res.status) {
        let tempData = [];
        const mycourses = res.data.data.sort((a, b) => a.start_at - b.start_at);
        mycourses.map((singleSession) => {
          let start_at = moment.unix(singleSession.start_at).tz(timeZone);
          let end_at = moment.unix(singleSession.end_at).tz(timeZone);
          tempData.push({
            ...singleSession,
            start_at: start_at,
            end_at: end_at,
          });
        });
        setSessions(tempData);
        setTotalRecords(res.data.metadata[0].totalRecord);
      }
      setLoader(false);
    });
  }, [duration, page]);

  const paginate = (page_no) => {
    setPage(page_no);
  };

  return (
    <div className="tab-pane fade show active" id="enrolled">
      <div className="row">
        <div className="col-md-4">
          <div className="form-group selectGroup">
            <select
              className="form-control custom-select"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            >
              <option value={5}>All</option>
              {Object.keys(DURATION_LIST).map((idx) => (
                <option value={idx}>{DURATION_LIST[idx]}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <Courses activeResult={activeResults} page={COURSE_LIST} courseList={sessions} courseView={COURSE_VIEW.RESCHEDULE} loading={loader} />
      <div className="row">
        <div className="col-md-12 mt-5">
          <Pagination
            totalRecords={totalRecords}
            recordPerPage={RECORD_PER_PAGE}
            paginate={paginate}
            currentPage={page}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { getMycourseSessions })(AllSession);
