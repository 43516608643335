import { checklink } from "actions/auth";
import arrowLeft from "assets/images/icons/arrow-left.svg";
import successIcon from "assets/images/success-icon.svg";
import { ReactComponent as InfoCircle } from "assets/images/icons/info-circle.svg";
import Button from "components/common/Button";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import IconCircle from "views/Front/HomePage/IconCircle";
import Header from "views/Front/Layout/Header";

const ConfirmUser = ({ checklink, match, history }) => {
  const [responsedata, setresponsedata] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setLoading(true);
      if (!responsedata) {
        checklink(match.params.confirmation_token).then((data) => {
          setresponsedata(data);
          setLoading(false);
        });
      }
    }
  }, []);

  return (
    <>
      <Header dark />
      <div className="flex flex-col p-20 md:p-10 md:px-10 mx-10 md:mx-30 sm:mx-0 lg:mx-0 justify-center items-center sm:mt-0 mt-0 md:mt-10 xl:mt-20">
        {responsedata?.success ? (
          <img src={successIcon} alt="Success Icon" />
        ) : (
          <IconCircle className="bg-red">
            <InfoCircle />
          </IconCircle>
        )}
        <div className="text-center mt-5">
          <div className="text-3xl text-dgray-900 font-semibold">
            {responsedata?.status
              ? "Account verified"
              : "Account is not verified."}
          </div>
          <div className="text-dgray-500 mt-5 lg:mt-5 xl:mt-10 md:w-full mx-auto">
            {responsedata?.message}
          </div>
        </div>
        {responsedata?.status ? (
          <>
            <div className="mt-10 md:mt-10 xl:mt-15 ">
              <div>
                <Button href="/login" type="button" className="!px-24" wFull>
                  Continue
                </Button>
              </div>
            </div>
            <div className="text-center flex flex-row text-dgray-500 text-sm mt-8 md:mt-8 xl:mt-10">
              <img src={arrowLeft} alt="Arrow left" className="mr-3" />
              <Link href="/login"> Back to log in </Link>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {
  checklink,
})(ConfirmUser);
