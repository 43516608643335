import React from "react";
import { useDispatch } from "react-redux";
import Errors from "views/Notifications/Errors";
import { REMOVE_ERRORS, SET_FRONT_ERRORS } from "actions/types";
import SessionDateTime from "./SessionDateTime";
import SessionRecurringData from "./SessionRecurringData";

const SessionGroupInformation = ({
  session_group,
  isConfirmation,
  sessionIndividualFormData,
  setSessionIndividualFormData,
  defaultSpecificIndividualDateTimingInput,
  defaultSpecificIndividualSessionGroup,
  current_date,
  onIndividualChange,
  isForEdit,
  can_add_new_bundle = true,
  INPUT_TYPE,
  number_of_session,
  checkRecurring,
  setSessionErrorMsg,
}) => {
  /*********************Use Dispatch to set state ************************/
  const dispatch = useDispatch();

  /*********************add new session ************************/
  const handleAddSessionChild = (e, bundleIndex) => {
    e.preventDefault();
    if (session_group[bundleIndex].sessions.length < number_of_session) {
      let isAllAdded = true;
      session_group[bundleIndex].sessions.map((singleSession) => {
        if (singleSession.start_date === "" || singleSession.start_time === "")
          isAllAdded = false;
        return isAllAdded;
      });
      if (isAllAdded) {
        let newSessionGrpoupData = [...session_group];
        newSessionGrpoupData[bundleIndex].sessions.push({
          ...defaultSpecificIndividualDateTimingInput,
        });
        setSessionIndividualFormData({
          ...sessionIndividualFormData,
          session_group: [...newSessionGrpoupData],
        });
        dispatch({ type: REMOVE_ERRORS });
        setSessionErrorMsg("");
      } else {
        let errorsList = [];
        errorsList[`individual_start_date_${bundleIndex}`] =
          "Please fill valid values for above session(s).";
        dispatch({
          type: SET_FRONT_ERRORS,
          payload: {
            alertType: "danger",
            errorsList,
          },
        });
      }
    } else {
      let errorsList = [];
      errorsList[`individual_start_date_${bundleIndex}`] =
        "Maximum session limit reached.";
      dispatch({
        type: SET_FRONT_ERRORS,
        payload: {
          alertType: "danger",
          errorsList,
        },
      });
    }
  };

  /*********************remove session ************************/
  const handleSessionRemove = async (e, index, bundelIndex) => {
    e.preventDefault();
    if (window.confirm(`Are you sure you want to remove this session?`)) {
      let newSessionGroupData = [...session_group];
      newSessionGroupData[bundelIndex].sessions.splice(index, 1);

      //check for recurrence type and date
      let checkRecurringPromise = await checkRecurring(
        newSessionGroupData,
        bundelIndex
      );
      await Promise.resolve(checkRecurringPromise).then((res) => {
        newSessionGroupData = res;
      });
      //end----check for recurrence type and date

      setSessionIndividualFormData({
        ...sessionIndividualFormData,
        session_group: newSessionGroupData,
      });
      dispatch({ type: REMOVE_ERRORS });
      setSessionErrorMsg("");
    }
  };

  /*********************add new bundle ************************/
  const handleAddNewBundle = (e) => {
    e.preventDefault();
    let newSessionGrpoupData = [...session_group];
    newSessionGrpoupData.push({ ...defaultSpecificIndividualSessionGroup });
    setSessionIndividualFormData({
      ...sessionIndividualFormData,
      // can_add_new_bundle: false,
      session_group: [...newSessionGrpoupData],
    });
  };

  /*********************delete bundle ************************/
  const deleteBundle = (e, bundelIndex) => {
    e.preventDefault();
    if (window.confirm(`Are you sure you want to delete this group?`)) {
      let newSessionGroupData = [...session_group];
      newSessionGroupData.splice(bundelIndex, 1);

      setSessionIndividualFormData({
        ...sessionIndividualFormData,
        session_group: newSessionGroupData,
      });
      dispatch({ type: REMOVE_ERRORS });
      setSessionErrorMsg("");
    }
  };
  console.log(session_group)
  return (
    <>
      {session_group.map((singleBundle, bundelIndex) => (
        <>
          <div className="col-md-12 mt-2">
            {singleBundle.sessions.map((singleSession, index) => (
              <SessionDateTime
               key={`session-datetime-${index}`}
                singleSession={singleSession}
                isConfirmation={isConfirmation}
                index={index}
                bundelIndex={bundelIndex}
                sessionsLength={singleBundle.sessions.length}
                onIndividualChange={onIndividualChange}
                handleSessionRemove={handleSessionRemove}
                current_date={current_date}
                is_editable={singleBundle.is_editable}
                isForEdit={isForEdit}
                INPUT_TYPE={INPUT_TYPE}
                is_recurring_editable={singleBundle.is_recurring_editable}
              />
            ))}

            {!isConfirmation && (
              <>
                <Errors
                  current_key={`individual_start_date_${bundelIndex}`}
                  key={`individual_start_date_${bundelIndex}`}
                />
                <Errors
                  current_key={`individual_start_time_${bundelIndex}`}
                  key={`individual_start_time_${bundelIndex}`}
                />
              </>
            )}

            {!isConfirmation &&
              singleBundle.sessions.length < number_of_session && (
                <div className="row">
                  <div className="col-md-12">
                    <button
                      className="add-session"
                      onClick={(e) => handleAddSessionChild(e, bundelIndex)}
                    >
                      <i className="fa fa-plus" />
                      Add interaction
                    </button>
                  </div>
                </div>
              )}
            <SessionRecurringData
              key={`session-data-${bundelIndex}`}
              singleBundle={singleBundle}
              bundelIndex={bundelIndex}
              onIndividualChange={onIndividualChange}
              isConfirmation={isConfirmation}
              is_editable={singleBundle.is_editable}
              is_recurring_editable={singleBundle.is_recurring_editable}
              INPUT_TYPE={INPUT_TYPE}
            />
            {!isConfirmation &&
              session_group.length > 1 &&
              (singleBundle.is_editable || singleBundle.is_new) && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4" />

                      <div className="col-md-4" />
                      <div className="col-md-4">
                        <div className="form-group text-right mb-0">
                          <button
                            className="btn btn-secondary mb-0"
                            onClick={(e) => deleteBundle(e, bundelIndex)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
          <div className="col-md-12">
            <hr />
          </div>
        </>
      ))}
      {can_add_new_bundle && !isConfirmation && (
        <div className="row">
          <div className="col-md-12">
            <button
              className="add-session"
              onClick={(e) => handleAddNewBundle(e)}
            >
              <i className="fa fa-plus" />
              Add additional availability
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SessionGroupInformation;
