import axios from "utils/axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  LOG_ERROR,
  LOG_LIST_UPDATED,
  LOADING_LOG_LIST_DATA,
  REMOVE_ERRORS,
  LOG_LIST_COMPLETED,
} from "actions/types";
import { logout } from "actions/auth";


//get LOG list
export const getLogList = (logParams) => async dispatch => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    try {
      dispatch({ type: REMOVE_ERRORS });
      dispatch({ type: LOADING_LOG_LIST_DATA });

      const query = logParams.query ? logParams.query : "&query=";

      const res = await axios.get(`/api/admin/logs?limit=${logParams.limit}&page=${logParams.page}&orderBy=${logParams.orderBy}&ascending=${logParams.ascending}${query}`,
        config
      );
      console.log("log response :::" + res);
      dispatch({
        type: LOG_LIST_UPDATED,
        payload: res.data.response[0]
      });
      dispatch({
        type: LOG_LIST_COMPLETED,
      });
      return res.data?res.data:{status:false};
    } catch (err) {
      // console.log(err.response);
      if(err.response){
        if(err.response.data && err.response.data.tokenStatus === 0){
          dispatch(logout());
        }
        else{
          dispatch({
            type: LOG_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
          });
          dispatch(setAlert(err.response.message, "danger"));
        }
      }
    }
  };
