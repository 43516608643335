import moment from "moment";
import * as Yup from "yup";
import { htmlStringSchema } from "./shared";

const createOneOnOneEventSchema = Yup.object({
  category: Yup.string().required("Please select"),
  title: Yup.string()
    .required("Please enter a title")
    .min(3, "Please enter at least 3 characters")
    .max(100, "Please enter at least 3 characters"),
  description: htmlStringSchema({
    min: 20,
    minMsg: "Please enter at least 20 characters",
    requiredMsg: "Please enter a description",
  }),
  idealFor: htmlStringSchema({
    min: 20,
    minMsg: "Please enter at least 20 characters",
    requiredMsg: "Please enter",
  }),
  image: undefined,
  interaction: Yup.string().required("Please select"),
  durations: Yup.array().of(
    Yup.object().shape({
      value: Yup.number().required("Please select a duration"),
      price: Yup.number().required("Please enter a price"),
    })
  ),
  events: Yup.array().of(
    Yup.object().shape({
      startDate: Yup.date().required(),
      endDate: Yup.date().test(
        "endDateIsAfter",
        "Please select an end date that is after the start date",
        (value, { parent }) => moment(value).isSameOrAfter(parent.date, "days")
      ),
      startTime: Yup.date().required(),
      endTime: Yup.date().required(),
      isRecurring: Yup.bool(),
      recurringInterval: Yup.string().when("isRecurring", {
        is: true,
        then: Yup.string().required("Please select the recurring interval"),
      }),
      recurringEndDate: Yup.string().when("isRecurring", {
        is: true,
        then: Yup.string()
          .required()
          .test(
            "endIsAfter",
            "Please select a recurring end date that is after the event end date",
            (value, { parent }) => moment(value).isAfter(parent.endDate, "days")
          ),
      }),
    })
  ),

  // maxAttendee: Yup.number()
  //   .required("Please enter a number")
  //   .typeError("Please enter a number"),
  // interactionCount: Yup.string().required("Please enter"),
  // date: Yup.string().required("Please select a date"),
  // time: Yup.string().required("Please select a start time"),
  // recurringInterval: Yup.string().required("Please select"),
});

export default createOneOnOneEventSchema;
