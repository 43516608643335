import { getAllUserBlogs } from "actions/front/blog";
import BlogCard from "components/blog/BlogCard";
import Spinner from "views/Spinner";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

function HostBlogs({ hostId, getAllUserBlogs }) {
  const [isLoading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState();

  useEffect(() => {
    setLoading(true);
    if (hostId != null) {
      getAllUserBlogs(filters, hostId, "all").then((res) => {
        if (res.status) {
          setBlogs(res.data.data);
        }
        setLoading(false);
      });
    }
  }, [filters, hostId]);

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-6">Host's Blog Posts</h2>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="pb-24">
          {blogs?.length > 0 ? (
            <div className="grid grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-6">
              {blogs.map((blog, i) => (
                <BlogCard blog={blog} key={i} />
              ))}
            </div>
          ) : (
            <div className="text-dgray-500 text-center mt-8">
              No blogs found
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const filters = {
  limit: 0,
  page: 1,
  orderBy: "created_at",
  ascending: "desc",
  query: "",
  custom_filters: "",
};

const fakeBlog = {
  title: "Example Blog Name",
  slug: "example-blog-name",
  header_image: "",

  category: "Business English",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
  createdAt: new Date().toISOString(),
  host: { name: "Jane Doe", avatar: "" },
};

const fakeBlogs = [fakeBlog, fakeBlog, fakeBlog];

export default connect(() => ({}), { getAllUserBlogs })(HostBlogs);
