import { getAllMentors } from "actions/front/myaccount";
import HostCard from "components/host/HostCard";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Spinner from "views/Spinner";

function HostList() {
  const [loading, setLoading] = useState(false);
  const [hosts, setHosts] = useState();

  const fetchData = async () => {
    setLoading(true);
    const res = await getAllMentors(filters);
    if (res.status) {
      setHosts(res.data.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex justify-center lg:flex-col gap-8 md:gap-4 mb-16">
      {loading ? (
        <Spinner />
      ) : (
        hosts?.map((host, i) => <HostCard data={host} key={i} />)
      )}
    </div>
  );
}

export default connect((state) => ({}))(HostList);

const filters = {
  limit: 3,
  page: 1,
  orderBy: "created_at",
  ascending: "desc",
  query: "",
  custom_filters: "",
};
