//@DEPRECATED
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Row, Input } from "reactstrap";
import { connect } from "react-redux";
import { getLearnerDetail } from "actions/admin/learner";
import { SESSION_TYPE_REVERSE } from "constants/index";
import * as moment from "moment-timezone";
import { CSVLink } from "react-csv";

const LearnerDetails = ({
  getLearnerDetail,
  learnerDetail,
  loading,
  subLoading,
  match,
}) => {
  const [mappedData, setMappedData] = useState();

  useEffect(() => {
    if (match.params.id) {
      getLearnerDetail(match.params.id);
    }
  }, []);

  useEffect(() => {
    console.log("=======================learnerDetail", learnerDetail);
    const items = learnerDetail.map((item) => {
      return {
        course_name: item.course_name,
        mentor_username: item.mentor_username,
        attend_status: item.attend_status,
        price_text:
          item.course_price === 0 ? "Free" : `$${item.course_price.toFixed(2)}`,
        session_type: SESSION_TYPE_REVERSE[item.session_type],
        start_at: moment
          .unix(item?.session_data?.[0]?.start_at)
          .tz(item.timezone)
          .format("DD MMM, YYYY HH:mm"),
        end_at: item.session_data[item.session_data.length - 1].end_at
          ? moment
              .unix(item?.session_data?.[item.session_data.length - 1]?.end_at)
              .tz(item.timezone)
              .format("DD MMM, YYYY HH:mm")
          : "",
          role:item.learner_role == 2 ? 'speaker' : 'listener'
      };
    });
    console.log(items);
    setMappedData(items);
  }, [learnerDetail]);

  if (loading) {
    return <Row>Loading, please wait...</Row>;
  }

  const CSVHeaders = [
    { label: "Course Name", key: "course_name" },
    { label: "Mentor Username", key: "mentor_username" },
    { label: "Price", key: "price_text" },
    { label: "Session Type", key: "session_type" },
    { label: "Session Start At", key: "start_at" },
    { label: "Session End At", key: "end_at" },
    { label: "Role", key: "role" },
    { label: "Attend Status", key: "attend_status" },
  ];

  const DownloadXls = () => (
    <>
      <div className="download-xls">
        <CSVLink
          className="fa fa-file-excel-o text-orange "
          data={mappedData}
          headers={CSVHeaders}
          filename={`${learnerDetail[0].name} ${moment
            .unix(moment().utc().unix())
            .format("MM/DD/YYYY HH:MM:ss")}.csv`}
        >
          {" "}
          Download
        </CSVLink>
      </div>
    </>
  );

  return !loading && learnerDetail.length > 0 ? (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Row>
              <Col md={4}>
                <h5>
                  <strong>Name</strong>: {learnerDetail[0].name}
                </h5>
              </Col>
              <Col md={4}>
                <h5>
                  <strong>Email</strong>: {learnerDetail[0].email}
                </h5>
              </Col>
              <Col md={4}>
                <h5>
                  <strong>User Name</strong>: {learnerDetail[0].username}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <DownloadXls />
              </Col>
            </Row>
            <Row>
              <table
                className="table table-bordered"
                style={{ marginTop: "20px" }}
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Course Name</th>
                    <th>Mentor UserName</th>
                    <th>Course Price</th>
                    <th>Session Type</th>
                    <th>Session Detail</th>
                    <th>Role</th>
                    <th>Attendance Status</th>
                  </tr>
                </thead>
                <thead>
                  {learnerDetail.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{++index}</td>
                        <td>{item.course_name}</td>
                        <td>{item.mentor_username}</td>
                        <td>
                          {item.course_price === 0
                            ? "Free"
                            : `$${item.course_price}`}
                        </td>
                        <td>{SESSION_TYPE_REVERSE[item.session_type]}</td>
                        <td>
                          {moment
                            .unix(item.session_data?.[0]?.start_at)
                            .tz(item.timezone)
                            .format("DD MMM, YYYY HH:mm") +
                            " - " +
                            moment
                              .unix(
                                item.session_data?.[item?.session_data?.length - 1]
                                  .end_at
                              )
                              .tz(item.timezone)
                              .format("DD MMM, YYYY HH:mm")}
                        </td>
                        <td>
                          {item.learner_role == 2 ? 'speaker' : 'listener'}
                        </td>
                        <td>{item.attend_status}</td>
                      </tr>
                    );
                  })}
                </thead>
              </table>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  ) : null;
};

const mapStateToProps = (state) => ({
  learnerDetail: state.learner.learnerDetail,
  loading: state.learner.loading,
  subLoading: state.educator.isSubLoading,
});

export default connect(mapStateToProps, {
  getLearnerDetail,
})(withRouter(LearnerDetails));
