import getUserAvatar from "utils/getUserAvatar";

export default function Attendees({ attendees }) {
  const className = "relative rounded-full w-8 h-8 border-2 border-white";

  return (
    attendees && (
      <div className="flex items-center text-xs text-dgray-500">
        <div className="flex justify-between">
          {attendees.slice(0, 3).map((attend, i) =>
            i === 2 ? (
              <div className="shrink-0" key={i}>
                {attendees?.length > 3 ? (
                  <div
                    className={`${className} flex justify-center items-center bg-dgray-200 ${
                      i === 1 ? "-left-2" : i === 2 ? "-left-4" : ""
                    }`}
                  >
                    +{attendees?.length - 3}
                  </div>
                ) : (
                  <img
                    src={getUserAvatar(attend.avatar)}
                    alt=""
                    className={`${className} ${
                      i === 1 ? "-left-2" : i === 2 ? "-left-4" : ""
                    }`}
                  />
                )}
              </div>
            ) : (
              <div className="shrink-0" key={i}>
                <img
                  src={getUserAvatar(attend.avatar)}
                  alt=""
                  className={`${className} ${
                    i === 1 ? "-left-2" : i === 2 ? "-left-4" : ""
                  }`}
                />
              </div>
            )
          )}
        </div>
        <div
          className={`whitespace-nowrap ${
            attendees?.length > 3
              ? "relative -left-2"
              : attendees?.length === 1
              ? "ml-2"
              : ""
          }`}
        >
          View attendees
        </div>
      </div>
    )
  );
}
