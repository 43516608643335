import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { SORTING } from "constants/index";

const Sorting = ({ filters, setFilters }) => {
  const [sortingFilter, setSortingFilter] = useState("");

  const onChangeSorting = (e) => {
    setSortingFilter(e.target.value);
  };
  useEffect(() => {
    let sortBy = "start_at";
    let orderDirection = "desc";
    if (sortingFilter === SORTING.ALPHABET_ASC) {
      sortBy = "course_name";
      orderDirection = "asc";
    } else if (sortingFilter === SORTING.ALPHABET_DESC) {
      sortBy = "course_name";
      orderDirection = "desc";
    } else if (sortingFilter === SORTING.DATE_DESC) {
      sortBy = "start_at";
      orderDirection = "desc";
    } else if (sortingFilter === SORTING.DATE_ASC) {
      sortBy = "start_at";
      orderDirection = "asc";
    } else if (sortingFilter === SORTING.RATING_LOW_HIGH) {
      sortBy = "avg_rating";
      orderDirection = "asc";
    } else if (sortingFilter === SORTING.RATING_HIGH_LOW) {
      sortBy = "avg_rating";
      orderDirection = "desc";
    }
    setFilters({ ...filters, orderBy: sortBy, ascending: orderDirection });
  }, [sortingFilter]);

  return (
    <div className="col-md-4">
      <div className="form-group selectGroup">
        <select
          name="select-profession"
          className="form-control custom-select"
          value={sortingFilter}
          onChange={(e) => onChangeSorting(e)}
        >
          <option value="">Sort By</option>
          <option value={SORTING.ALPHABET_ASC}>A-Z</option>
          <option value={SORTING.ALPHABET_DESC}>Z-A</option>
          <option value={SORTING.DATE_DESC}>Date : Newest First</option>
          <option value={SORTING.DATE_ASC}>Date : Oldest First</option>
          <option value={SORTING.RATING_LOW_HIGH}>Rating : Low to High</option>
          <option value={SORTING.RATING_HIGH_LOW}>Rating : High to Low </option>
        </select>
      </div>
    </div>
  );
};

export default Sorting;
