import { getAllCourses } from "actions/front/common/course";
import CategoryPicker from "components/common/CategoryPicker";
import CenterContainer from "components/common/Container/Center";
import JoinCTA from "components/common/JoinCTA";
import Pagination from "components/common/Pagination";
import EventCard from "components/event/EventCard";
import EventCardLarge from "components/event/EventCardLarge";
import Header from "views/Front/Layout/Header";
import Spinner from "views/Spinner";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Footer from "views/Front/Layout/Footer";
import { getClosestSession, sortEventsByClosest } from "utils/course";
import { SESSION_TYPE } from "constants";
import { SelectableButton } from "components/common/Button/Selectable";
import { event } from "jquery";

const initialFilters = {
  limit: 7,
  page: 1,
  orderBy: "start_at",
  ascending: "asc",
  query: "",
  custom_filters: "",
};
const eventList=[
  {value:"View All",label:"View All"},
  {value:"Live",label:"Live"},
  {value:"View All1",label:"One on One"}
];

const ExploreEvents = () => {
  const [isLoading, setLoading] = useState(false);
  const [events, setEvents] = useState();
  const [filters, setFilters] = useState(initialFilters);
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [featuredEvent, setFeaturedEvent] = useState();
  const [ignore, setIgnore] = useState(false);

  const fetchData =  () => {
    setLoading(true);
    getAllCourses({
      ...filters,
      custom_filters: selectedCategory
        ? `&session_type=&course_category=${selectedCategory}&rating=`
        : "",
    }).then((res) => {
      if (res.status) {
        const sortedResponse = res.data.data || []
        const closestLiveEvents = sortEventsByClosest(sortedResponse.filter(i => i.course_type === SESSION_TYPE.LIVE_EVENT))
        console.log("sortedResponse",sortedResponse.length)
        if (closestLiveEvents.length > 0) {
            setEvents(res.data.data.filter(i => i.course_id !== closestLiveEvents[0].course_id));
            setFeaturedEvent(closestLiveEvents[0]);
        } else {
          if(sortedResponse.length > 0){
              setEvents(sortedResponse.slice(1));
              setFeaturedEvent(sortedResponse[0]);
          }
        }
        setTotalRecords(res.data.metadata[0].totalRecord);
      }
      setLoading(false);
    }).catch((err)=>{
      console.log("================err",err);
    });
  };

  useEffect(() => {
    if(ignore){
      fetchData()
    }
    // let ignore = true;
    // setLoading(true);
    // getAllCourses({
    //   ...filters,
    //   custom_filters: selectedCategory
    //     ? `&session_type=&course_category=${selectedCategory}&rating=`
    //     : "",
    // }).then((res) => {
    //   if (res.status && ignore) {
    //     const sortedResponse = res.data.data || []
    //     const closestLiveEvents = sortEventsByClosest(sortedResponse.filter(i => i.course_type === SESSION_TYPE.LIVE_EVENT))
    //     console.log("sortedResponse",sortedResponse.length)
    //     if (closestLiveEvents.length > 0) {
    //         setEvents(res.data.data.filter(i => i.course_id !== closestLiveEvents[0].course_id));
    //         setFeaturedEvent(closestLiveEvents[0]);
    //     } else {
    //       if(sortedResponse.length > 0){
    //           setEvents(sortedResponse.slice(1));
    //           setFeaturedEvent(sortedResponse[0]);
    //       }
    //     }
    //     setTotalRecords(res.data.metadata[0].totalRecord);
    //   }
    //   setLoading(false);
    // }).catch((err)=>{
    //   console.log("================err",err);
    // });
    // return () => {
    //   ignore = false;
    // };
  }, [filters]);




  useEffect(() => {
    let limit = 7;
    if (page > 1) {
      setFeaturedEvent(null);
    }
    if (page > 2) {
      limit = 6;
    }
    setFilters({ ...filters, page, limit });
    setIgnore(true)
  }, [page, selectedCategory]);

  return (
    <>
      <Header />
      <CenterContainer>
        <div className="text-center md:text-left pt-24 pb-12 xl:px-0 w-2/3 md:w-full md:px-4 mx-auto">
          <p className="text-primary-600 mb-6 text-base md:text-sm">Events</p>
          <h1 className="text-dgray-900 text-5xl md:text-4xl font-semibold mb-6">
            Explore the live and one on one events
          </h1>
          <p className="text-dgray-500 mb-12 text-xl md:text-lg">
            Explore the public & premium events and find the best event based on
            your learning goals.
          </p>
        </div>
        {/* <div className="text-md text-primary-700 font-medium mb-5">
          Event type
        </div>
        <ul  className="flex md:hidden gap-2 flex-wrap mb-4 md:mb-12">
        {eventList.map((item, i) => (
          <li key={i}>
            <SelectableButton
              // onClick={() => function(){}}
              isSelected={item.value === "Live"}
            >
              {item.label}
            </SelectableButton>
          </li>
        ))}
      </ul> */}
        <div className="text-md text-primary-700 font-medium mb-5">
          Event categories
        </div>
        <CategoryPicker
          onChange={(category) => {
            setSelectedCategory(category);
            setPage(1)
          }}
          className="mb-16 md:mb-12"
        />
        {!isLoading && featuredEvent && (
          <>
            <div className="mb-12 md:hidden">
              <EventCardLarge data={featuredEvent} full large />
            </div>
            <div className="mb-12 hidden">
              <EventCardLarge data={featuredEvent} full large />
            </div>
          </>
        )}

        <div className="grid grid-cols-2 md:grid-cols-1 gap-6 mb-16">
          {isLoading ? (
            <div className="col-span-3">
              <Spinner />
            </div>
          ) : events && events.length > 0 ? (
            events.map((event, i) => (
              <EventCard data={event} key={i} full large />
            ))
          ) : (
            !featuredEvent && (
              <div className="text-dgray-500 text-center col-span-3">
                No events found
              </div>
            )
          )}
        </div>
        {events && (
          <Pagination
            page={page}
            setPage={setPage}
            pageSize={filters.limit}
            count={totalRecords}
          />
        )}

        <JoinCTA />
      </CenterContainer>
      <Footer />
    </>
  );
};

export default connect(null)(withRouter(ExploreEvents));
