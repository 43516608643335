import { ReactComponent as XClose } from "assets/images/icons/x-close.svg";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";
import { ReactComponent as AlertCircle } from "assets/images/icons/alert-circle.svg";
import { Link } from "react-router-dom";

export default function SignInToast({ host, unfollow }) {
  return (
    <div className="font-sans text-sm">
      <AlertCircle className="text-red-600 mb-3" />
      <div className="font-medium text-red-700 mb-1">You are not signed in</div>
      <p className="text-red-600 mb-3">
        Please sign in to subscribe to hosts and get notifications and follow
        your feed on latest updates from them.
      </p>

      <Link to="/login" className="text-red-700 font-medium">
        Sign in
        <ArrowRight className="inline align-top ml-2" />
      </Link>
    </div>
  );
}

export const signinToastOptions = {
  className: "border border-red-300",
  closeButton: (
    <div>
      <XClose className="text-red-500 right-10 block" />
    </div>
  ),
};
