import axios from "utils/axios";
import { RECORD_PER_PAGE } from "constants/index";

//get all the sessions of learner
export const getSessions = () => async (dispatch) => {
  const res = await axios.get("/api/learner/courses/calendar");
  return res.data;
};

// get Statistics for learner dashboard
export const getStatistics = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/learner/courses/statistics`, config);
    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      return { status: false };
    }
  } catch (error) {
    return { status: false };
  }
};

//get all the sessions of learner according to condition
export const getMycourseSessions = ({ session_type, page, duration = 0 }) => async (
  dispatch
) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/learner/courses/session-list`,
      { session_type, page, duration, limit: RECORD_PER_PAGE },
      config
    );
    if (res.data.status === true) {
      return { status: true, data: res.data.response[0] };
    } else {
      return { status: false };
    }
  } catch (error) {
    return { status: false };
  }
};

// get Statistics for learner sessions
export const getSessionStatistics = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/learner/courses/session-statistics`,
      config
    );
    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      return { status: false };
    }
  } catch (error) {
    return { status: false };
  }
};

//cancel booked course
export const cancelCourseScheduled = async (course_detail_id) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.delete(
      `/api/learner/courses/cancel-session/${course_detail_id}`,
      config
    );
    console.log(res)
    if (res.data.status === true) {
      return { status: true, data: res.data.response, msg: res.data.message };
    } else {
      return { status: false, msg: res.data.message };
    }
  } catch (error) {
    return { status: false, msg: "Something went wrong. Please try again." };
  }
};
