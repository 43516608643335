import { getFollowingList } from "actions/front/dingdong";
import { ReactComponent as GlobeIcon } from "assets/images/icons/globe.svg";
import { useEffect, useState } from "react";
import getUserAvatar from "utils/getUserAvatar";
import Spinner from "views/Spinner";

export default function FeedSidebar() {
  const [hosts, setHosts] = useState([]);
  const [query, setQuery] = useState("");
  const [isLoading, setLoading] = useState(false);

  const getHosts = async () => {
    setLoading(true);
    const res = await getFollowingList({ ...filters, query });
    setHosts(res);
    setLoading(false);
  };

  useEffect(() => {
    getHosts();
  }, [query]);

  // const onShowMore = () => {
  //   setPage((p) => p + 1);
  // };

  const onUnsubscribe = (id) => {};

  return (
    <div className="w-[400px] xl:w-[300px] max-h-[700px] overflow-y-scroll md:w-full rounded-lg border border-dgray-200 p-6">
      <div className="text-lg font-medium mb-5">Following</div>
      {/* <TextInput
        placeholder="Search"
        onChange={(e) => setQuery(e.target.value)}
      /> */}
      <ul className="mt-5 mb-4">
        {isLoading ? (
          <Spinner />
        ) : (
          hosts.map((host, i) => (
            <SidebarHostItem
              host={host}
              onUnsubscribe={onUnsubscribe}
              key={i}
            />
          ))
        )}
      </ul>
      <div className="text-right">
        {/* {isLoading ? (
          <Rolling className="text-primary-600" />
        ) : (
          hosts.length < totalRecords && (
            <button
              className="text-sm text-primary-700 font-medium"
              onClick={onShowMore}
            >
              Show more
            </button>
          )
        )} */}
      </div>
    </div>
  );
}

const filters = {
  limit: 7,
  orderBy: "created_at",
  ascending: "desc",
  custom_filters: "",

  query: "",
  page: 1,
};

function SidebarHostItem({ host, onUnsubscribe }) {
  return (
    <li className="flex justify-between items-center border-b border-dgray-200 py-4">
      <div className="flex gap-2.5">
        <img
          src={host.avatar ? host.avatar : getUserAvatar(host.avatar)}
          alt=""
          className="rounded-full w-10 h-10"
        />
        <div>
          <div className="text-sm font-medium">{host.fullName}</div>
          {host.location && (
            <div className="text-xs text-dgray-500">
              <GlobeIcon className="inline mr-1" />
              {[host.city, host.state, host.country].filter(i => i !== null && i !== "").join(", ")}
            </div>
          )}
        </div>
      </div>
      <button
        className="text-sm text-dgray-500 font-medium"
        onClick={() => onUnsubscribe(host.id)}
      >
        Unsubscribe
      </button>
    </li>
  );
}
