import mockImage from "assets/images/mock-event.jpg";

// neither should really return mockImage
// since we have a 'course_image' or a 'category_image'
export default function getEventImage(event) {
  return event.course_image && event.course_image !== "undefined"
    ? `${process.env.REACT_APP_MEDIA_URL}${event.course_image}`
    : event.category_image
    ? `${process.env.REACT_APP_MEDIA_URL}${event?.category_image}`
    : mockImage;
}

export function getCategoryImage(categoryId, categories) {
  const category = categories?.data?.find((cat) => cat._id === categoryId);
  return category && category.category_image
    ? `${process.env.REACT_APP_MEDIA_URL}${category.category_image}`
    : mockImage;
}

export function getBlogImage(blog) {
  return blog.header_image && blog.header_image !== "undefined"
    ? `${process.env.REACT_APP_MEDIA_URL}${blog.header_image}`
    : blog.program && blog.program.image
    ? `${process.env.REACT_APP_MEDIA_URL}${blog.program.image}`
    : mockImage;
}
