import Button from "components/common/Button";
import FileDropzone from "components/common/form/FileDropzone";
import SelectInput from "components/common/form/SelectInput";
import TextInput from "components/common/form/TextInput";
import { PAYOUT_METHODS } from "constants";
import { useFormikContext } from "formik";
import { getErrorStep } from "utils/stepForms";
import CreateStepControls from "../Create/StepControls";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";
import InputError from "components/common/form/InputError";

export default function BecomeAHostStep3({ goToStep, nextStep, ...props }) {
  const { values, isSubmitting, isValid, submitForm, errors, setFieldValue } =
    useFormikContext();

  return (
    <>
      <div className="border-b border-dgray-200 pb-24 md:pb-5 mb-5">
        <div className="pb-12 mb-12 border-b border-dgray-200">
          <SelectInput
            className="w-1/2 md:w-full"
            name="payoutMethod"
            label="Payout method"
            options={payoutMethods}
            defaultValue={payoutMethods[0]}
          />
        </div>
        {values.payoutMethod === PAYOUT_METHODS.PayPal ? (
          <>
            <TextInput
              className="w-2/5 lg:w-1/2 md:w-full mb-4"
              name="paypalEmail"
              label="Email address"
              placeholder="Enter your email address"
            />
            <div className="text-sm text-dgray-500">
              The email address should be owned by the account holder.
            </div>
          </>
        ) : values.payoutMethod === PAYOUT_METHODS.WeChatPay ? (
          <div className="w-1/2 lg:w-full">
            <div className="text-sm text-dgray-700 font-medium mb-2">
              Upload QR code
            </div>
            <FileDropzone
              onDrop={(files) => {
                setFieldValue("weChatPayFile", files[0]);
              }}
              accept={{
                "image/png": [".png", ".jpg", ".jpeg"],
                "application/pdf": [".pdf"],
              }}
              description="PNG, JPG or PDF (max. 2000 kb)"
              maxFiles={1}
            />
            <InputError name="weChatPayFile" />
          </div>
        ) : (
          ""
        )}
      </div>
      <CreateStepControls
        {...props}
        nextButton={
          <Button
            type="submit"
            disabled={isSubmitting}
            onClick={async () => {
              if (!isValid) {
                window.scrollTo(0, 0);
                goToStep(getErrorStep(fieldNames, errors));
              } else {
                const res = await submitForm();
                if (res) nextStep();
              }
            }}
            small
          >
            Submit
            <ArrowRight className="inline ml-2" />
          </Button>
        }
      />
    </>
  );
}

const fieldNames = [
  [
    "firstName",
    "lastName",
    "email",
    "country",
    "city",
    "languages",
    "about",
    "profilePicture",
  ],
  ["hostTier", "expertise", "purpose", "experience", "documents"],
  ["payoutMethod", "paypalEmail", "weChatPayFile"],
];

const payoutMethods = [
  { label: "PayPal", value: PAYOUT_METHODS.PayPal },
  { label: "WeChat Pay", value: PAYOUT_METHODS.WeChatPay },
];
