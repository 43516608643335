import {
  HOST_APPLICATION_ERROR,
  HOST_APPLICATION_LIST_UPDATED,
  LOADING_HOST_APPLICATION_LIST_DATA,
  REMOVE_ERRORS,
  HOST_APPLICATION_LIST_COMPLETED,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  applicationList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentApplication: null,
  loadingApplicationList: true,
  loadingApplication: true,
  loadingListData: true,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HOST_APPLICATION_ERROR:
      return {
        ...state,
        error: payload,
        loadingApplication: false,
        loadingApplicationList: false,
        loadingListData: false,
      };
    case HOST_APPLICATION_LIST_UPDATED:
      return {
        ...state,
        applicationList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingApplicationList: false,
        loadingListData: false,
      };
    case LOADING_HOST_APPLICATION_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case HOST_APPLICATION_LIST_COMPLETED:
      return {
        ...state,
        loadingListData: false,
      };

    default:
      return state;
  }
}
