import { SESSION_TYPE } from "constants";
import moment from "moment";
import parse from "date-fns/parse";

export default function prepareLiveEventData(
  {
    title,
    description,
    idealFor,
    image = "",
    coHosts,
    associatedProgram,
    allowAudience,
    maxAudience,
    maxSpeakers,
    date,
    time,
    category,
    interaction, //
    duration,
    price,
    events,
    sessionDetail,
    proficiencyLevel,
  },
  typeOfSession = SESSION_TYPE.LIVE_EVENT
) {

 
  // each key is a parameter for the "createCourse" action
  return {
    courseInformation: {
      name: title,
      image: image || "",
      overview: description,
      ideal_for: "",
      course_information: description, // ?
      is_individual: false,
      is_group: false,
      duration,
      coHosts,
      category,
      category_type: "specific",
      category_id: associatedProgram,
      proficiencyLevel,
    },
    sessionData: getSessionFormData({
      duration,
      price,
      events,
      sessionDetail,
      allowAudience,
      maxAudience,
      maxSpeakers,
      date,
      time,
      title,
      image,
      description,
      idealFor,
    }),
    typeOfSession,
  };
}

const getSessionFormData = ({
  duration,
  price,
  events,
  sessionDetail,
  allowAudience,
  maxAudience,
  maxSpeakers,
  date,
  time,
  title,
  image,
  description,
  idealFor,
  categoryId,
}) => {
  const topAllSessions = [];
  const [hour, minute] = time.split(":");
  return {
    specific_group: {},
    generic: {},
    specific_individual: {},
    live_event: {
      max_students: allowAudience ? +maxAudience : 0,
      max_speakers: maxSpeakers,
      number_of_session: 1,
      duration_per_session: parseInt(duration),
      price_per_session: price || 0,
      // session_group: sessionDetail
      //   ? [sessionDetail[0].session_group]
      //   : [
      //       getSessionGroup(
      //         {
      //           duration,
      //           price,
      //           events,
      //           maxAudience,
      //           date,
      //           time,
      //         },
      //         topAllSessions
      //       ),
      //     ],
      session_group: [
      getSessionGroup(
        {
          duration,
          price,
          events,
          maxAudience,
          date,
          time,
        },
        topAllSessions
      ),
      ],

      start_date: moment(date).format("YYYY-MM-DD"),
      end_date: moment(date).format("YYYY-MM-DD"),

      form_data: [
        {
          category_id: categoryId, // ?
          sub_category_id: "60147c4f348baf0012d0bca3", // ?
          name: title,
          image: image || "",
          oldImage: "",
          overview: description,
          ideal_for: idealFor || "", // missing on the form
          course_information: description || "", // missing on the form
          category_type: "specific",
          price_per_session: price,
          max_students: maxAudience,
          max_speakers: maxSpeakers,
          detail_for: SESSION_TYPE.LIVE_EVENT,
          is_preview: false,
          session_data: [
            {
              start_at: moment(date)
                .set({
                  hour: parseInt(hour),
                  minute: parseInt(minute),
                  seconds: 0,
                })
                .unix(),
              end_at: null,
            },
          ],
        },
      ],
    },
  };
};

const getSessionGroup = (event, topAllSessions) => {
  let s= moment(event.date).format("YYYY-MM-DD")+ ' ' +event.time
  let e=moment(s).add(event.duration,"m").format("YYYY-MM-DD HH:mm")
  return {
    sessions: [
      {

        start_date: s,
        start_time: event.time,
        end_date: e,
     
      },
    ],

    all_session_with_recurring: [
      getSessionData(
        { date: event.date, time: event.time, duration: event.duration },
        topAllSessions
      ),
    ],
  };
};

const getSessionData = ({ date, time, duration }, topAllSessions) => {
  const [hour, minute] = time.split(":");
  console.log(hour, minute,"hour, minute")
  const session = {
    start_at: moment(date)
      .set({
        hour: parseInt(hour),
        minute: parseInt(minute),
      })
      .unix(),
      end_at: null,
    // end_at: moment(date)
    // .set({
    //   hour: parseInt(hour),
    //   minute: parseInt(minute),
    // }).add(parseInt(duration),"minutes").unix(),
  };
  topAllSessions.push(session);
  return session;
};
