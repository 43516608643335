import {
  LOGOUT,
  REMOVE_ERRORS,
  SET_FRONT_ERRORS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  RESET_LINK_SUCCESS,
  RESET_LINK_GET_DETAIL_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_LINK_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  GOOGLE_SIGN_IN_SUCCESS,
  // GOOGLE_SIGN_IN_FAIL,
  AUTH_LOADING,
  LOGIN_ERROR,
  CHECK_LINK_SUCCESS,
  CHECK_LINK_ERROR,
  MYACCOUNT_LOADING, //my account
  USER_MYACCOUNT_UPDATED,
  USER_MYACCOUNT_UPDATED_ERROR,
  USER_MYACCOUNT_FAIL,
  USER_MYACCOUNT,
  USER_MYACCOUNT_ERROR,
  USER_IMAGE_LOADING,
  USER_IMAGE_UPDATE_UPDATED,
  USER_IMAGE_UPDATE_ERROR,
  USER_IMAGE_UPDATE_FAIL,
  EDUCATOR_COURSE_LOADING, //mentor course
  EDUCATOR_COURSE_CREATED,
  EDUCATOR_COURSE_CREATE_ERROR,
  EDUCATOR_COURSE_FAIL,
  EDUCATOR_COURSE_EDITED,
  EDUCATOR_COURSE_EDIT_ERROR,
  BLOG_CREATED_ERROR
} from "actions/types";

const initialState = [];

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGOUT:
    case REMOVE_ERRORS:
      return initialState;
    case AUTH_LOADING:
      return initialState;
    case USER_LOADED:
    case REGISTER_SUCCESS:
    case RESET_LINK_SUCCESS:
    case RESET_LINK_GET_DETAIL_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case LOGIN_SUCCESS:
    case GOOGLE_SIGN_IN_SUCCESS:
    case CHECK_LINK_SUCCESS:
      return initialState;
    case AUTH_ERROR:
    case LOGIN_ERROR:
    case CHECK_LINK_ERROR:
    case SET_FRONT_ERRORS:
      return payload.errorsList ? payload.errorsList : [];
    case REGISTER_FAIL:
    case RESET_LINK_FAIL:
    case LOGIN_FAIL:
      // case GOOGLE_SIGN_IN_FAIL:
      return initialState;
    case MYACCOUNT_LOADING:
    case USER_IMAGE_LOADING:
    case EDUCATOR_COURSE_LOADING:
      return [];
    case USER_MYACCOUNT_UPDATED:
    case USER_MYACCOUNT:
    case USER_IMAGE_UPDATE_UPDATED:
    case EDUCATOR_COURSE_CREATED:
    case EDUCATOR_COURSE_EDITED:
      return initialState;
    case USER_MYACCOUNT_UPDATED_ERROR:
    case USER_MYACCOUNT_ERROR:
    case USER_IMAGE_UPDATE_ERROR:
    case EDUCATOR_COURSE_CREATE_ERROR:
    case EDUCATOR_COURSE_EDIT_ERROR:
      return payload.errorsList ? payload.errorsList : [];
    case USER_MYACCOUNT_FAIL:
    case USER_IMAGE_UPDATE_FAIL:
    case BLOG_CREATED_ERROR:
      return payload.errorsList ? payload.errorsList : [];
    case EDUCATOR_COURSE_FAIL:
      return initialState;
    default:
      return state;
  }
}
