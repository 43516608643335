import businessComImage from "assets/images/business-communication.jpg";
import interviewImage from "assets/images/mock-interview.jpg";
import impromptuImage from "assets/images/impromptu-speaking.jpg";
import examsImage from "assets/images/academic-exams.jpg";
import comfortImage from "assets/images/comfort-zone.jpg";
import talkImage from "assets/images/table-talk.jpg";
import checkIcon from "assets/images/icons/check-orange.svg";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";
import { useState } from "react";
import Button from "../../../../components/common/Button";
import CenterContainer from "components/common/Container/Center";

export default function HomePageExplore() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <CenterContainer className="text-white bg-blue-800 py-24 md:py-16">
      <h2 className="text-center text-5xl md:text-2xl font-semibold mb-20 md:mb-8">
        Explore Programs and Interesting Topics
      </h2>
      <div className="flex md:block gap-12 justify-between">
        <div className="basis-2/5 shrink-0 md:flex md:flex-wrap md:justify-center gap-2">
          {items.map(({ title }, i) => (
            <button
              key={i}
              onClick={() => setSelectedIndex(i)}
              className={`block rounded font-semibold md:font-medium text-left py-4 md:py-2 px-7 md:px-3.5 text-xl md:text-sm
              ${i === selectedIndex ? "text-primary-600 bg-white" : ""}
            `}
            >
              {title}
            </button>
          ))}
        </div>
        <div className="text-dgray-50">
          <img
            src={items[selectedIndex].image}
            alt=""
            className="mb-16 md:mb-6 md:mt-12 h-[21.875rem] md:h-40 object-cover rounded-lg md:mx-auto"
          />
          <h3 className="text-3xl md:text-lg font-semibold mb-4 md:text-center">
            {items[selectedIndex].title}
          </h3>
          <div className="text-lg md:text-base">
            <p className="mb-8">{items[selectedIndex].description}</p>
            <div className="space-y-5">
              {items[selectedIndex].features.map((feat, i) => (
                <div key={i} className="flex items-center gap-3">
                  <div className="shrink-0 bg-primary-100 rounded-full h-7 w-7 flex justify-center items-center">
                    <img src={checkIcon} alt="check icon" />
                  </div>
                  <div>{feat}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="text-center mt-16 md:mt-12">
            <Button to="/explore" className="md:py-2.5 md:px-4 md:text-sm">
              <span>See all events & sessions</span>
              <ArrowRight className="inline ml-2 md:ml-1" />
            </Button>
          </div>
        </div>
      </div>
    </CenterContainer>
  );
}

const items = [
  {
    title: "Business Communication",
    description:
      "Tailored for professionals who want to gain confidence in communicating with clients and executing meetings at work.",
    image: businessComImage,
    features: ["Various business simulations", "Mock business presentations"],
  },
  {
    title: "Mock Interview",
    description:
      "The perfect place for students, recent graduates, and professionals of all ages to hone their interview skills.",
    image: interviewImage,
    features: [
      "Build advanced vocabulary and expressions",
      "Learn the tactics to leverage and articulate your professional  experience",
    ],
  },
  {
    title: "Impromptu Public Speaking",
    description:
      "Learn to gather your thoughts and speak in front of an audience without preparation.",
    image: impromptuImage,
    features: [
      "Critical thinking and how to think on your feet",
      "Practice expressing yourself succinctly",
    ],
  },
  {
    title: "Academic Exams",
    description: "Get practice on IELTS, TOFEL exams with various topics.",
    image: examsImage,
    features: [
      "Mock exams on relevant topics",
      "Improve proficiency by learning advanced and native expressions",
    ],
  },
  {
    title: "Beginner’s Comfort Zone",
    description: "In case you are just starting out and you need extra help.",
    image: comfortImage,
    features: [
      "Gain confidence in speaking out",
      "Smaller group practice without pressure or judgement",
    ],
  },
  {
    title: "Round Table Talk",
    description: "Participate in closed live conversation on various topics.",
    image: talkImage,
    features: [
      "Closed-door sessions for small groups to engage in meaningful conversation",
      "Sessions are differentiated by proficiency level",
    ],
  },
];
