import DashboardHeader from "components/common/dashboard/Header";
import TabPicker from "components/common/TabPicker";
import { EVENT_STATUSES } from "constants";
import { useState } from "react";
import DashboardMyEventsCompleted from "./Completed";
import DashboardMyEventsDrafts from "./Drafts";
import DashboardMyEventsUpcoming from "./Upcoming";

export default function DashboardMyEvents() {
  const [selectedStatus, setSelectedStatus] = useState(EVENT_STATUSES.UPCOMING);

  return (
    <div className="py-8">
      <DashboardHeader
        title="My Events & Sessions"
        description="List your upcoming events and completed events you’ve organized and attended."
      />
      <TabPicker
        className="mb-16 md:mb-3"
        onChange={setSelectedStatus}
        items={Object.values(EVENT_STATUSES).map((stat) => ({
          value: stat,
          label: stat,
        }))}
      />
      {selectedStatus === EVENT_STATUSES.UPCOMING ? (
        <DashboardMyEventsUpcoming />
      ) : selectedStatus === EVENT_STATUSES.COMPLETED ? (
        <DashboardMyEventsCompleted />
      ) : (
        <DashboardMyEventsDrafts />
      )}
    </div>
  );
}
