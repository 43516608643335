import React from "react";
import { useDispatch } from "react-redux";
import Errors from "views/Notifications/Errors";
import { REMOVE_ERRORS, SET_FRONT_ERRORS } from "actions/types";

import { SESSION_DURATION } from "constants/index";

const DurationInformation = ({
  duration_info,
  isConfirmation,
  onGenericChange,
  setSessionGenericFormData,
  sessionGenericFormData,
  INPUT_TYPE,
  setSessionErrorMsg,
}) => {
  /*********************Use Dispatch to set state ************************/
  const dispatch = useDispatch();
  /*********************add new duration ************************/
  const handleAddDurationChild = (e) => {
    e.preventDefault();
    let isAllAdded = true;
    duration_info.map((singleDuration) => {
      if (
        singleDuration.duration_per_session === "" ||
        singleDuration.price_per_session === ""
      )
        isAllAdded = false;
      return isAllAdded;
    });
    if (isAllAdded) {
      setSessionGenericFormData({
        ...sessionGenericFormData,
        duration_info: [
          ...duration_info,
          { duration_per_session: "", price_per_session: "" },
        ],
      });
      dispatch({ type: REMOVE_ERRORS });
      setSessionErrorMsg("");
    } else {
      let errorsList = [];
      errorsList["generic_duration_per_session"] =
        "Please fill valid values for above duration(s).";
      dispatch({
        type: SET_FRONT_ERRORS,
        payload: {
          alertType: "danger",
          errorsList,
        },
      });
    }
  };

  /*********************remove duration ************************/
  const handleDurationRemove = (e, index) => {
    e.preventDefault();
    if (window.confirm(`Are you sure you want to remove this duration?`)) {
      let newDuration = [...duration_info];
      newDuration.splice(index, 1);
      setSessionGenericFormData({
        ...sessionGenericFormData,
        duration_info: newDuration,
      });
      dispatch({ type: REMOVE_ERRORS });
      setSessionErrorMsg("");
    }
  };

  return (
    <>
      {/*********** all duration information**************/}

      {duration_info.map((singleDuration, index) => (
        <>
          {/*********** duration information start **************/}
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="duration_per_session">
                Duration <span className="text-danger">*</span>
              </label>
              <select
                name="duration_per_session"
                className="form-control custom-select"
                value={singleDuration.duration_per_session}
                onChange={(e) => onGenericChange(e, INPUT_TYPE.DURATION, index)}
                disabled={isConfirmation}
              >
                <option value=""> -- Select Duration -- </option>
                {Object.keys(SESSION_DURATION).map((key) => {
                  let isExistDuration = duration_info.filter(
                    (singleDurationInfo, filterIndex) => {
                      return (
                        singleDurationInfo.duration_per_session == key &&
                        filterIndex != index
                      );
                    }
                  );
                  if (isExistDuration.length == 0)
                    return <option value={key}>{SESSION_DURATION[key]}</option>;
                })}
              </select>
              {!isConfirmation && (
                <Errors
                  current_key={`generic_duration_per_session_${index}`}
                  key={`generic_duration_per_session_${index}`}
                />
              )}
            </div>
          </div>
          {/*********** duration information end **************/}
          {/*********** price per duration start **************/}

          <div
            className={`col-md-6 ${
              duration_info.length > 1 && !isConfirmation && "space-between"
            }`}
          >
            <div className="form-group w-75">
              <label htmlFor="price_per_session">
                Price ($) <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                min="0"
                name="price_per_session"
                value={singleDuration.price_per_session}
                onChange={(e) => onGenericChange(e, INPUT_TYPE.DURATION, index)}
                disabled={isConfirmation}
              />
              {!isConfirmation && (
                <Errors
                  current_key={`generic_price_per_session_${index}`}
                  key={`generic_price_per_session_${index}`}
                />
              )}
            </div>
            {duration_info.length > 1 && !isConfirmation && (
              <div className="form-group w-25 text-center m-0">
                <button
                  className="remove-session"
                  onClick={(e) => handleDurationRemove(e, index)}
                >
                  <i className="fa fa-minus" />
                </button>
              </div>
            )}
          </div>
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <label className="text-muted">Enter a price per Interaction.  Enter 0 if you would like this session to be free</label>
              </div>
          {/*********** price per duration end **************/}
        </>
      ))}
      {/*********** Errors if any start**************/}

      {!isConfirmation && (
        <>
          <Errors
            current_key="generic_price_per_session"
            key="generic_price_per_session"
          />
          <Errors
            current_key="generic_duration_per_session"
            key="generic_duration_per_session"
          />
        </>
      )}
      {/*********** Errors if any end**************/}
      {/*********** Add new duration div start**************/}
      {!isConfirmation &&
        Object.keys(SESSION_DURATION).length > duration_info.length && (
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <button
                  className="add-session"
                  onClick={(e) => handleAddDurationChild(e)}
                >
                  <i className="fa fa-plus" />
                  Add Duration
                </button>
              </div>
            </div>
          </div>
        )}
      {/*********** Add new duration div end**************/}

      <div className="col-md-12">
        <hr />
      </div>
    </>
  );
};
export default DurationInformation;
