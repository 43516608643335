/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { edit, getCourseCategoryById } from "actions/admin/coursecategory";

import { REMOVE_ERRORS } from "actions/types";

import Errors from "views/Admin/Layout/Errors";
import Spinner from "views/Spinner";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const EditCourseCategory = ({
  getCourseCategoryById,
  edit,
  coursecategory: { currentCourseCategory, loading },
  history,
  match,
  errorList,
}) => {
  
  /**************** dispatch to remove error *********************/
  const dispatch = useDispatch();

  /********************Form data*****************/
  const [initallFormData, setInitallFormData] = useState({
    category_name: "",
    category_description: "",
  });
  const [formData, setFormData] = useState(initallFormData);

  /********************Use effect to get current category*****************/
  useEffect(() => {
    if (match.params.coursecategory_id)
      getCourseCategoryById(match.params.coursecategory_id);
  }, [match.params.coursecategory_id]);

  /********************update current category detail*****************/
  useEffect(() => {
    if (
      currentCourseCategory != null &&
      Object.keys(currentCourseCategory).length > 0
    ) {
      let category = currentCourseCategory;

      let newData = {
        ...initallFormData,
        category_name: category.name,
        category_description: category.description,
        category_image: category.image
      };
      setInitallFormData(newData);
      setFormData(newData);
    }
  }, [currentCourseCategory]);

  /********************Expolde form data*****************/
  const { category_name, category_description, category_image } = formData;

  /********************On change event*****************/
  const onChange = (e) => {
    if (e.target.name === "category_image") {
      let image_file = e.target.files[0];
      let image_url = URL.createObjectURL(image_file);

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        file: image_file,
        image: image_url
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    dispatch({ type: REMOVE_ERRORS });
  };

  /********************On Submit event*****************/
  const onSubmit = (e) => {
    e.preventDefault();
    edit(formData, history, match.params.coursecategory_id);
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card>
              <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="category_name">
                      Name<span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="category_name"
                      name="category_name"
                      maxLength="25"
                      value={category_name}
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.category_name ? true : false}
                    />
                    <Errors current_key="category_name" key="category_name" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="category_description">Description</Label>
                    <Input
                      type="textarea"
                      id="category_description"
                      name="category_description"
                      value={category_description}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="image">Image</Label>
                    {category_image || formData.file ? <img src={formData.file ? URL.createObjectURL(formData.file) : `${process.env.REACT_APP_MEDIA_URL}${category_image}`} /> : null}
                    <Input
                      type="file"
                      id="category_image"
                      name="category_image"
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/course-program">
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

EditCourseCategory.propTypes = {
  getCourseCategoryById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  coursecategory: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  coursecategory: state.coursecategory,
  errorList: state.errors.errorList,
});

export default connect(mapStateToProps, {
  getCourseCategoryById,
  edit,
})(EditCourseCategory);
