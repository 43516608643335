import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";
import Button from "components/common/Button";
import { SanitizeHTML } from "components/common/SanitizeHtml";
import EventCardLarge from "components/event/EventCardLarge";
import { PROFICIENCY_LEVELS } from "constants";
import { PROFICIENCY_DESCRIPTION } from "constants";
import { useFormikContext } from "formik";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { getErrorStep } from "utils/stepForms";
import CreateStepControls from "../../StepControls";
import { SESSION_TYPE } from "constants";
import moment from "moment";

function CreateEventPublicStep3({
  user,
  categories,
  goToStep,
  nextStep,
  saveDraft,
  ...props
}) {
  const { values, isSubmitting, isValid, errors, submitForm } =
    useFormikContext();
  const location = useLocation();
  const editId = new URLSearchParams(location.search).get("editId");

  return (
    <>
      {/* <h2 className="text-lg font-medium border-b border-dgray-200 pb-5 mb-5">
        Your Calendar
      </h2> */}
      <div className="border-b border-dgray-200 pb-8 mb-8">
        <h2 className="text-lg font-medium border-b border-dgray-200 pb-5 mb-5">
          Event Preview
        </h2>
        <EventCardLarge
          isPreview
          data={{
            course_image: values.image
              ? typeof values.image === "string"
                ? `${process.env.REACT_APP_MEDIA_URL}${values.image}`
                : URL.createObjectURL(values.image)
              : "",
            course_type: SESSION_TYPE.LIVE_EVENT,
            course_name: values.title,
            course_overview: values.description,
            category_id: values.associatedProgram,
            category_name:
              categories && values.associatedProgram
                ? categories.find((cat) => cat._id === values.associatedProgram)
                    ?.name
                : "",
            date: moment(new Date(values.date))
              .set("hour", values.time.split(":")[0])
              .set("minute", values.time.split(":")[1]),

            mentor_avatar: user.avatar,
            mentor_name: user.full_name,

            host: user,

            session_booking_count: "",
            timezone: user.timezone,
          }}
        />
        <div className="flex md:flex-col gap-16 md:gap-10 mt-10">
          <p className="text-lg text-dgray-500 flex-1 whitespace-pre-line">
            {values.description ? (
              <SanitizeHTML html={values.description} />
            ) : (
              <span className="w-full text-base">No description...</span>
            )}
          </p>
          <div className="bg-dgray-100 rounded-2xl py-4 px-6 basis-1/3 h-min">
            <div className="text-2xl text-primary-600 font-semibold">
              Ideal for
            </div>
            <div className="text-sm text-dgray-600 mb-2">
              {
                PROFICIENCY_LEVELS.find(
                  (lvl) => lvl.value === values.proficiencyLevel
                )?.label
              }
            </div>
            <p className="text-dg-800">
              {values.proficiencyLevel ? (
                PROFICIENCY_DESCRIPTION[values.proficiencyLevel]
              ) : (
                <span className="w-full text-sm text-dgray-500">
                  No proficiency level selected...
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
      <CreateStepControls
        {...props}
        saveDraft={saveDraft}
        nextStep={nextStep}
        nextButton={
          <Button
            type="button"
            disabled={isSubmitting}
            onClick={async () => {
              if (!isValid) {
                window.scrollTo(0, 0);
                goToStep(getErrorStep(fieldNames, errors));
              } else {
                let res =await submitForm()
                if (res) nextStep();
              }
            }}
            small
          >
            {editId ? "Edit" : "Publish"} Event
            <ArrowRight className="inline ml-2" />
          </Button>
        }
      />
    </>
  );
}

export default connect(({ auth, categories }) => ({
  user: auth.user,
  categories: categories.data,
}))(CreateEventPublicStep3);

const fieldNames = [
  ["maxSpeakers", "allowAudience", "maxAudience", "inviteCoHost", "coHosts"],
  [
    "image",
    "associatedProgram",
    "proficiencyLevel",
    "title",
    "description",
    "date",
    "time",
    "duration",
  ],
];
