import * as Yup from "yup";
import {
  matchingPasswordSchema,
  newPasswordSchema,
  passwordSchema,
  selectOptionSchema,
} from "./shared";

const changePasswordSchema = Yup.object({
    current_password: passwordSchema(
        "Please enter a password",
        "Password must be at least 8 characters long"
      ),
    password: newPasswordSchema(),
    confirm_password: matchingPasswordSchema(
    "Please confirm your password",
    "Please enter matching passwords"
  ),
});

export default changePasswordSchema;
