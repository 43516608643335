import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ReactComponent as AlertCircle } from "assets/images/icons/alert-circle.svg";
import IconCircle from "views/Front/HomePage/IconCircle";
import Button from "components/common/Button";
import { ReactComponent as Close } from "assets/images/icons/close-icon.svg";

export default function HandleModal({
  isOpen,
  modalContent,
  onClose,
  onbtn
}) {
  function closeModal() {
    onClose()
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-10"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-10"
        >
          <div className="fixed inset-0 bg-black bg-dgray-700/70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <button
                  className="focus:outline-none absolute"
                  onClick={closeModal}
                  style={{ top: 16, right: 16 }}
                >
                  <Close style={{ width: "15px" }} className="text-dgray-500" />
                </button>

                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium text-center items-center flex flex-col leading-6 text-dgray-900"
                >
                  <IconCircle color="yellow">
                    <AlertCircle />
                  </IconCircle>
                  <div className="text-lg mt-4 font-medium">
                    {modalContent.title}
                  </div>
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-lg text-dgray-500 text-center">
                    {modalContent.content}
                  </p>
                </div>
                <div className="flex flex-row gap-2.5 mt-8">
                  <Button
                    type="button"

                    className="w-full py-2"
                    onClick={onbtn}
                  >
                    Learn more
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
