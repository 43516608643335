import AccordionList from "components/common/AccordionList";
import CenterContainer from "components/common/Container/Center";
import TextLink from "components/common/TextLink";
import JoinCTA from "components/common/JoinCTA";
import { useState } from "react";
import FooterLinksLayout from "../Layout/FooterLinksLayout";

export default function HelpPage() {
  const [isForLearners, setForLearners] = useState(true);

  return (
    <FooterLinksLayout
      title="Help"
      subtitle="Check out Frequently Asked Questions that address common questions and concerns."
    >
      <CenterContainer className="py-24" tight>
        <h2 className="text-4xl font-semibold text-center mb-16">
          Frequently asked questions
        </h2>
        <div>
          <div className="text-sm text-primary-700 font-semibold mb-5">
            Roles
          </div>
          <div className="mb-12">
            <RoleButton
              isSelected={isForLearners}
              onClick={() => setForLearners(true)}
            >
              for Learners
            </RoleButton>
            <RoleButton
              isSelected={!isForLearners}
              onClick={() => setForLearners(false)}
            >
              for Hosts
            </RoleButton>
          </div>
        </div>
        <div className="-mx-8 lg:mx-0">
          <AccordionList items={isForLearners ? learnerItems : hostItems} />
        </div>
      </CenterContainer>
      <JoinCTA />
    </FooterLinksLayout>
  );
}

function RoleButton({ isSelected, onClick, children }) {
  return (
    <button
      className={`py-2.5 px-3.5 rounded-md mr-2 font-medium
        ${isSelected ? "bg-primary-50 text-primary-700" : "text-dgray-500"}
      `}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function SmallTitle({ children }) {
  return <div className="font-medium">{children}</div>;
}

const learnerItems = [
  {
    title: "Can I sign up as both learner and host at the same time?",
    content: (
      <>
        Yes. At first time signing up, you sign up as learner and then you can
        go to My DDN to apply to become a host. We expect more information from
        host applicants to evaluate their proficiency. You can find more
        information on <TextLink to="/become-a-host">Become a Host</TextLink>{" "}
        page.
      </>
    ),
  },
  {
    title: "Can I cancel my subscription?",
    content:
      "If you have paid for the monthly subscription service to attend live events you may cancel the subscription at any time.  The cancellation will take effect the next billing cycle and you will  continue to have access to DDN until the next cycle",
  },
  {
    title: "What are the cancellation, rescheduling and refund policies?",
    content: (
      <div className="space-y-4">
        <SmallTitle>Cancellation of One Time Events/Sessions</SmallTitle>
        <p>
          If a host cancels a 1-1 session with a student, the learner will
          receive a full refund. Hosts should make every effort to honor
          sessions scheduled by learners
        </p>
        <p>
          If learners cancel 1-1 sessions with more than 24 hours notice, a full
          refund will be provided for the session. 1-1 Sessions cancelled within
          24 hours will only be eligible for 50% refund.
        </p>
        <SmallTitle>Cancellation of Live Events</SmallTitle>
        <p>
          Hosts should make every effort to honor time set and scheduled by
          learners for live events. Hosts can cancel live events if they are
          unable to make the time. DDN will require hosts to schedule make up
          sessions in the event of a cancellation to honor subscriptions paid by
          students. Hosts will not get paid in case of cancelled events without
          a make up session.
        </p>
        <p>
          Learners are free to cancel registration to a live event at any time
          with no additional cost or charges.
        </p>
        <SmallTitle>Rescheduling</SmallTitle>
        Hosts cannot reschedule an existing event. They are free to cancel if
        they are unavailable for the planned time.
        <p className="text-primary-600">
          Learners can reschedule any available sessions prior to 24 hours
          without any cost. For the rescheduling needs within 24 hours, learners
          need to cancel the session.
        </p>
      </div>
    ),
  },
  {
    title: "What if I am unable to attend my enrolled session?",
    content:
      "Please make every effort to respect the hosts’ time commitment. You can look to reschedule or cancelling the session in advance if you will not be make on time. Please refer to cancellation and rescheduling policy for refund terms.",
  },
  {
    title: "Are there topics that DingDingNow does not cover?",
    content:
      "DingDingNow primarly seeks to connect English learners with proficient hosts to practice and improve their speaking skills. We do not encourage discussions on sensitive topics such as religion, politics, medical records, etc. We welcome everyone on our platform and expect our users to be respectful to others.",
  },
  {
    title: "I don’t live in United States. Can I use DingDingNow?",
    content:
      "Yes, absolutely! We are currently providing payments in USD and we plan to enable transactions across different currencies in the near future.",
  },
  {
    title: "What information is stored and displayed on the website?",
    content: (
      <div className="space-y-4">
        <p>
          We collect and store information that are requested at the sign up
          step (and Host Application step if applied) including but not limited
          to: name, email, country/city, interests, expertise, etc. For
          learners, only the name and initial of surname is displayed on the
          platform. Hosts’ publicly displayed information includes their name,
          location, spoken languages and their profile picture.
        </p>
        <p>
          Please note that no payment related information is stored in our
          database. Payments are taken care through secure third-party payment
          providers.
        </p>
      </div>
    ),
  },
  {
    title: "How do I attend sessions?",
    content: (
      <div>
        <p>Please go to My DDN to find your upcoming events/sessions.</p>
        <p>
          If you are a learner, please wait until the host activates the event.
          Once the host activates the event, you will see that event attendance
          is enabled. Please do not forget to refresh your page while you are
          waiting for the event to be activated.
        </p>
        <p>
          If you are a host, you will see that “Activate the Event” button will
          be enabled 5 minutes before the event start time. Please note that it
          is hosts’ responsibility to activate the event and attendees can then
          will be able to join the event.
        </p>
      </div>
    ),
  },
  {
    title: "How does DingDingNow video streaming works?",
    content:
      "Our platform provides an integrated video platform for our users. An event URL automatically expires in 5 minutes after the hosts activates the event but no one has joined. The event also automatically ends in 30 minutes if only one person (either host or an attendee alone) is present in the event. We encourage DDN users to be respectful of other users’ time and be one time for each session.",
  },
  {
    title: "Why my video is not working?",
    content:
      "Test audio and video before attending your session including your permissions. If it still doesn’t work, please exit out and re-join to the session.",
  },
  {
    title: "Can I use my phone to join sessions on DingDingNow?",
    content:
      "Yes! Our platform is also mobile friendly. You can join a session through your mobile anytime, anywhere.",
  },
];

const hostItems = [
  {
    title: "Can I sign up as both learner and host at the same time?",
    content: (
      <>
        Yes. At first time signing up, you sign up as learner and then you can
        go to My DDN to apply to become a host. We expect more information from
        host applicants to evaluate their proficiency. You can find more
        information on <TextLink to="/become-a-host">Become a Host</TextLink>{" "}
        page.
      </>
    ),
  },
  // {
  //   title: "Why can’t I book a session?",
  //   content: "",
  // },
  {
    title:
      "The session I would like to offer on DDN does not fit in any of existing DDN programs. What should I do?",
    content: (
      <>
        We welcome new program suggestions! Please go to
        <TextLink to="/contact-us">Contact Us</TextLink> and select
        “Suggestions” under Topics. Briefly describe the subject you would like
        to offer or the suggested DDN Program.
      </>
    ),
  },
  {
    title: "What are the cancellation, rescheduling and refund policies?",
    content: (
      <div className="space-y-4">
        <SmallTitle>Cancellation of One Time Events/Sessions</SmallTitle>
        <p>
          If a host cancels a 1-1 session with a student, the learner will
          receive a full refund. Hosts should make every effort to honor
          sessions scheduled by learners
        </p>
        <p>
          If learners cancel 1-1 sessions with more than 24 hours notice, a full
          refund will be provided for the session. 1-1 Sessions cancelled within
          24 hours will only be eligible for 50% refund.
        </p>
        <SmallTitle>Cancellation of Live Events</SmallTitle>
        <p>
          Hosts should make every effort to honor time set and scheduled by
          learners for live events. Hosts can cancel live events if they are
          unable to make the time. DDN will require hosts to schedule make up
          sessions in the event of a cancellation to honor subscriptions paid by
          students. Hosts will not get paid in case of cancelled events without
          a make up session.
        </p>
        <p>
          Learners are free to cancel registration to a live event at any time
          with no additional cost or charges.
        </p>
        <SmallTitle>Rescheduling</SmallTitle>
        Hosts cannot reschedule an existing event. They are free to cancel if
        they are unavailable for the planned time.
        <p className="text-primary-600">
          Learners can reschedule any available sessions prior to 24 hours
          without any cost. For the rescheduling needs within 24 hours, learners
          need to cancel the session.
        </p>
      </div>
    ),
  },
  {
    title: "Are there topics that DingDingNow does not cover?",
    content:
      "DingDingNow primarly seeks to connect English learners with proficient hosts to practice and improve their speaking skills. We do not encourage discussions on sensitive topics such as religion, politics, medical records, etc. We welcome everyone on our platform and expect our users to be respectful to others.",
  },
  {
    title: "I don’t live in United States. Can I use DingDingNow?",
    content:
      "Yes, absolutely! We are currently providing payments in USD and we plan to enable transactions across different currencies in the near future.",
  },
  {
    title: "What information is stored and displayed on the website?",
    content: (
      <div className="space-y-4">
        <p>
          We collect and store information that are requested at the sign up
          step (and Host Application step if applied) including but not limited
          to: name, email, country/city, interests, expertise, etc. For
          learners, only the name and initial of surname is displayed on the
          platform. Hosts’ publicly displayed information includes their name,
          location, spoken languages and their profile picture.
        </p>
        <p>
          Please note that no payment related information is stored in our
          database. Payments are taken care through secure third-party payment
          providers.
        </p>
      </div>
    ),
  },
  // {
  //   title:
  //     "I am a host, why there is no upcoming sessions after I published mine?",
  //   content: "",
  // },
  {
    title: "How do I attend sessions?",
    content: (
      <>
        <p>Please go to My DDN to find your upcoming events/sessions.</p>
        <p>
          If you are a learner, please wait until the host activates the event.
          Once the host activates the event, you will see that event attendance
          is enabled. Please do not forget to refresh your page while you are
          waiting for the event to be activated.
        </p>
        <p>
          If you are a host, you will see that “Activate the Event” button will
          be enabled 5 minutes before the event start time. Please note that it
          is hosts’ responsibility to activate the event and attendees can then
          will be able to join the event.
        </p>
      </>
    ),
  },
  {
    title: "How does DingDingNow video streaming works?",
    content:
      "Our platform provides an integrated video platform for our users. An event URL automatically expires in 5 minutes after the hosts activates the event but no one has joined. The event also automatically ends in 30 minutes if only one person (either host or an attendee alone) is present in the event. We encourage DDN users to be respectful of other users’ time and be one time for each session.",
  },
  {
    title: "Why my video is not working?",
    content:
      "Test audio and video before attending your session including your permissions. If it still doesn’t work, please exit out and re-join to the session.",
  },
  {
    title: "How does hosts get paid?",
    content: (
      <>
        <p>
          While applying to become a host, we ask for preferred payout methods.
          Currently, we provide 2 options, PayPal and WeChat Pay. Host payouts
          are processed one week after an event/session is completed
          successfully.
        </p>
        <p>DingDingNow sets a commission rate at 15% for service support.</p>
      </>
    ),
  },
  // {
  //   title: "How does hosts get paid?",
  //   content: "",
  // },
  {
    title: "Can I use my phone to join sessions on DingDingNow?",
    content:
      "Yes! Our platform is also mobile friendly. You can join a session through your mobile anytime, anywhere.",
  },
];
