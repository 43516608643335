import { ReactComponent as GlobeIcon } from "assets/images/icons/globe-large.svg";
import { ReactComponent as PinIcon } from "assets/images/icons/marker-pin.svg";
import { getMentorById } from "actions/front/myaccount";
import { ReactComponent as VerifiedTick } from "assets/images/icons/verified-tick.svg";
import BreadCrumbs from "components/common/Breadcrumbs";
import FollowButton from "components/common/Button/Follow";
import CenterContainer from "components/common/Container/Center";
import useFollowHost from "hooks/useFollowHost";
import getUserAvatar from "utils/getUserAvatar";
import Header from "views/Front/Layout/Header";
import Spinner from "views/Spinner";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import HostEvents from "./HostEvents";
import HostBlogs from "./HostBlogs";
import Pill from "components/common/Pill";
import Footer from "views/Front/Layout/Footer";
import { EXPERTISE_COLOR } from "constants";

function HostDetails({ user, match, getMentorById }) {
  const [isLoading, setLoading] = useState(false);
  const [host, setHost] = useState();
  const followProps = useFollowHost(host);

  useEffect(() => {
    setLoading(true);
    if (match.params.hostUsername) {
      getMentorById(match.params.hostUsername).then((data) => {
        if (data.status) {
          setHost(data.response[0]);
        } else {
          setHost(null);
        }
        setLoading(false);
      });
    }
  }, []);

  return (
    <>
      <Header />
      {isLoading ? (
        <Spinner />
      ) : (
        host && (
          <CenterContainer>
            <BreadCrumbs
              crumbs={[
                { label: "Home", href: "/" },
                { label: "Hosts", href: "/hosts" },
                {
                  label: "Host Details",
                  href: `/hosts/${match.params.hostUsername}`,
                },
              ]}
            />
            <div className="mt-10 flex items-center gap-6 mb-16 md:mb-12 md:px-4">
              <div className="shrink-0 rounded-full md:rounded-lg h-40 md:h-20 w-40 md:w-20 shadow-lg md:shadow-none relative">
                <img
                  src={getUserAvatar(host.avatar)}
                  alt="Host"
                  className="rounded-full md:rounded-lg h-full w-full bg-praimry-500 border-4 md:border-0 border-white"
                />
                {host.is_verified ? (
                  <VerifiedTick className="absolute bottom-2 right-1 z-10 md:hidden" />
                ) : null}
              </div>
              <div className="flex-1 flex justify-between">
                <div>
                  <h1 className="text-3xl md:text-lg font-medium mb-1 md:mb-0">
                    {host.name}
                  </h1>
                  <p>
                    <PinIcon className="inline mr-2 align-top" />
                    <span className="text-dgray-700 font-medium">
                      {[host.city, host.state, host.country]
                        .filter((i) => i !== null && i !== "")
                        .join(", ")}
                    </span>
                  </p>
                </div>
                {user?.id !== host._id && <FollowButton {...followProps} />}
              </div>
            </div>
            <div className="mb-16">
              <h2 className="text-2xl font-semibold mb-6">Host Details</h2>
              {host.expertise_list && host.expertise_list.length > 0 && (
                <div className="pb-5 mb-6 border-b border-dgray-200">
                  <h3 className="font-medium mb-2">Specialized in</h3>
                  <div className="flex flex-wrap gap-2">
                    {host.expertise_list?.map((exp, i) => (
                      <Pill text={exp} color={EXPERTISE_COLOR[exp]} key={i} />
                    ))}
                  </div>
                </div>
              )}
              <div className="flex md:flex-col gap-16 md:gap-8">
                <div className="flex-1">
                  <h3 className="font-medium mb-2">About me</h3>
                  <p
                    className="text-dgray-500 mb-5"
                    dangerouslySetInnerHTML={{
                      __html: host.description
                        ? host.description.replace(/\n/g, "<br />")
                        : "",
                    }}
                  ></p>
                  {/* <button className="font-medium text-primary-700">
                  Read more
                </button> */}
                </div>
                <div className="basis-1/4 shrink-0">
                  <div className="text-dgray-500 text-sm font-medium mb-2.5">
                    Location
                  </div>
                  <div className="mb-8">
                    <PinIcon className="inline mr-2 align-top" />
                    <span className="text-dgray-700 font-medium">
                      {[host.city, host.state, host.country]
                        .filter((i) => i !== null && i !== "")
                        .join(", ")}
                    </span>
                  </div>
                  <div className="text-dgray-500 text-sm font-medium mb-2.5">
                    Spoken Languages
                  </div>
                  <div className="flex items-center">
                    <GlobeIcon className="inline mr-2 align-middle" />
                    <span className="space-x-2 flex">
                      {host.languages.map((lang, i) => (
                        <Fragment key={lang}>
                          {i > 0 && (
                            <span className="text-dgray-500">&bull;</span>
                          )}
                          <span key={i} className="text-dgray-700 font-medium">
                            {lang}
                          </span>
                        </Fragment>
                      ))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <HostEvents hostId={host._id} />
            <HostBlogs hostId={host._id} />
          </CenterContainer>
        )
      )}
      <Footer />
    </>
  );
}

export default connect(
  ({ auth }) => ({
    user: auth.user,
  }),
  { getMentorById }
)(HostDetails);
