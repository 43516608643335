import DashboardHeader from "components/common/dashboard/Header";
import TabPicker from "components/common/TabPicker";
import { EVENT_STATUSES } from "constants";
import { useState } from "react";
import DashboardJourneyUpcoming from "./Upcoming";
import DashboardJourneyCompleted from "./Completed";
import DashboardUserTier from "components/dashboard/DashboardUserTier";
import DashboardCard from "components/dashboard/DashboardCars";

export default function DashboardLearningJourney() {
  const [selectedStatus, setSelectedStatus] = useState(EVENT_STATUSES.UPCOMING);

  return (
    <div className="py-8 min-h-screen">
      <DashboardHeader
        title="My Learning Journey"
        description="Monitor your learning journey."
      />
      <DashboardUserTier />
      <div className="grid grid-cols-3 gap-8 lg:gap-7 mt-8 mb-14">
        <DashboardCard>
          <div className="text-sm text-dgray-500 font-medium mb-2">
            Events joined per week
          </div>
          <div className="text-4xl lg:text-2xl font-semibold">4</div>
        </DashboardCard>
        <DashboardCard>
          <div className="text-sm text-dgray-500 font-medium mb-2">
            Events joined per month
          </div>
          <div className="text-4xl lg:text-2xl font-semibold">17</div>
        </DashboardCard>
        <DashboardCard>
          <div className="text-sm text-dgray-500 font-medium mb-2">
            Events joined per year
          </div>
          <div className="text-4xl lg:text-2xl font-semibold">208</div>
        </DashboardCard>
      </div>
      <TabPicker
        className="mb-16 md:mb-3"
        onChange={setSelectedStatus}
        items={Object.values(EVENT_STATUSES)
          .slice(0, 2)
          .map((stat) => ({
            value: stat,
            label: stat,
          }))}
      />
      {selectedStatus === EVENT_STATUSES.UPCOMING ? (
        <DashboardJourneyUpcoming />
      ) : (
        selectedStatus === EVENT_STATUSES.COMPLETED && (
          <DashboardJourneyCompleted />
        )
      )}
    </div>
  );
}
