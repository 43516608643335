import axios from "utils/axios";
import CenterContainer from "components/common/Container/Center";
import FormHero from "components/create-form/Hero";
import { PAYOUT_METHODS } from "constants";
import { Formik, Form } from "formik";
import { useState } from "react";
import StepWizard from "react-step-wizard";
import becomeAHostSchema from "utils/schemas/becomeAHost";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import HostFormStartInfo from "./StartInfo";
import BecomeAHostStep1 from "./Step1";
import BecomeAHostStep2 from "./Step2";
import BecomeAHostStep3 from "./Step3";
import BecomeAHostStep4 from "./Step4";
import { connect } from "react-redux";
import countries from "constants/countries";

function BecomeAHostForm({ user }) {
  const [hasStarted, setHasStarted] = useState(false);
  const [error, setError] = useState(null);

  return (
    <>
      <Header />

      {hasStarted ? (
        <CenterContainer className="pb-24 md:pb-16">
          <Formik
            initialValues={{
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              country: countries.find(c => c.name === user.country) ? countries.find(c => c.name === user.country).code : null,
              city: user.city,
              languages: [],
              about: "",
              profilePicture: user.avatar,
              hostTier: "",
              expertise: [],
              purpose: "",
              experience: "",
              documents: [],

              payoutMethod: PAYOUT_METHODS.PayPal,
              paypalEmail: "",
              weChatPayFile: "",
            }}
            validationSchema={becomeAHostSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setError("");
              const formData = new FormData();
              for (const key in values) {
                if (key === "documents") {
                  values.documents.forEach((file) => {
                    formData.append("documents", file);
                  });
                } else {
                  formData.append(key, values[key]);
                }
              }
              formData.set(
                "languages",
                values.languages.map(({ value }) => value)
              );
              formData.set(
                "expertise",
                values.expertise.map(({ value }) => value)
              );
              const res = await submitHostApplication(formData);
              if (res.status) {
                return true;
              } else {
                setSubmitting(false);
                setError("Got an error");
                return false;
              }
            }}
          >
            {({ values, errors }) => {
              return (
                <Form>
                  <StepWizard
                    transitions
                    nav={
                      <FormHero
                        title="Become a DDN Host"
                        items={[
                          {
                            title: "Personal Information",
                            subtitle: "Public profile information",
                          },
                          {
                            title: "Experience & Qualification",
                            subtitle: "English proficiency ",
                          },
                          {
                            title: "Payout method",
                            subtitle: "Cardholder details",
                          },
                          {
                            title: "Submit for review",
                            subtitle: "Review and approval",
                          },
                        ]}
                      />
                    }
                  >
                    <BecomeAHostStep1 />
                    <BecomeAHostStep2 />
                    <BecomeAHostStep3 />
                    <BecomeAHostStep4 />
                  </StepWizard>
                  {error && (
                    <div className="text-center text-red-500">{error}</div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </CenterContainer>
      ) : (
        <HostFormStartInfo setHasStarted={setHasStarted} />
      )}

      <Footer />
    </>
  );
}

export default connect(({ auth }) => ({ user: auth.user }))(BecomeAHostForm);

async function submitHostApplication(values) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
  };
  try {
    return await axios.post("/api/myaccount/become-a-host", values, config);
  } catch (error) {
    return error;
  }
}
