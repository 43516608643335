import image1 from "assets/images/landing-how1.jpg";
import image2 from "assets/images/landing-how2.jpg";
import CenterContainer from "components/common/Container/Center";
import FooterLinksLayout from "../Layout/FooterLinksLayout";
import CheckCircle from "components/common/CheckCircle";
import { ReactComponent as CalendarCheck } from "assets/images/icons/calendar-check.svg";
import { ReactComponent as CreditCardCheck } from "assets/images/icons/credit-card-check.svg";
import { ReactComponent as MessageChatCircle } from "assets/images/icons/message-chat-circle.svg";
import { ReactComponent as MessageHeartSquare } from "assets/images/icons/message-heart-square.svg";
import { ReactComponent as SearchMd } from "assets/images/icons/search-md.svg";
import { ReactComponent as UserCheck } from "assets/images/icons/user-check.svg";
import { ReactComponent as PresentationChart } from "assets/images/icons/presentation-chart.svg";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";
import IconCircle from "../HomePage/IconCircle";
import JoinCTA from "components/common/JoinCTA";
import TextLink from "components/common/TextLink";

export default function HowItWorks() {
  return (
    <FooterLinksLayout
      title="How it works?"
      subtitle="DingDingNow is an integrated global platform that aims to improve spoken English communication for students and professionals.  We welcome people from all walks of life to connect on DDN through our advanced all-in-one scheduling and video technology."
    >
      <CenterContainer className="pt-40 pb-24 lg:py-0 grid grid-cols-2 lg:block">
        <div className="pr-16 lg:pr-0 lg:pt-16 lg:pb-12 border-r lg:border-none border-primary-300">
          <h2 className="text-4xl font-semibold mb-8">For Hosts</h2>
          <img src={image1} alt="" className="mb-16 lg:mb-10 rounded-xl" />
          <List items={hostItems} />
        </div>
        <div className="pl-16 lg:pl-0 lg:pt-16 lg:pb-12 border-l lg:border-none border-primary-300">
          <h2 className="text-4xl font-semibold mb-8">For Learners</h2>
          <img src={image2} alt="" className="mb-16 lg:mb-10 rounded-xl" />
          <List items={learnerItems} />
        </div>
      </CenterContainer>
      <JoinCTA />
    </FooterLinksLayout>
  );
}

function List({ items }) {
  return (
    <div className="space-y-12">
      {items.map((item, i) => (
        <div key={i} className="flex lg:block items-start gap-5">
          <div className="">
            <IconCircle>{item.icon}</IconCircle>
          </div>
          <div>
            <h3 className="text-xl font-medium mt-4 mb-8">{item.title}</h3>
            <div className="space-y-5">
              {item.items.map((text, j) => (
                <div className="flex gap-3">
                  <CheckCircle small />
                  <p key={j} className="text-lg md:text-base text-dgray-500">
                    {text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

const hostItems = [
  {
    icon: <CalendarCheck />,
    title: "Sign up on DDN and apply to be a host",
    items: [
      "We are selective in our approval and hosts will be interviewed. Once approved, hosts can offer 1-1 sessions, live events for different types of English communication programs for learners.",
      "DDN offers both public events (free for subscribers) and paid 1-1 sessions where hosts name their price.",
    ],
  },
  {
    icon: <PresentationChart />,
    title: "Learner schedule and session executed",
    items: [
      "You have the ability to generate a secure meeting URL and kick off the meeting session at least 5 mins before the start of your event on the scheduled date and time. No extra tools or additional license required. We provide them all!",
      "Initiate and guide the conversation and enjoy hosting sessions!",
    ],
  },
  {
    icon: <CreditCardCheck />,
    title: "Get paid and get reviewed",
    items: [
      "As a host, you will get paid via paypal in a regular basis. You will get paid a fixed fee for public events hosted and you name your price for the 1-1 sessions you host.",
      "The more sessions you offer, the more your reputation builds through reviews and the more learners you will reach.",
    ],
  },
];

const learnerItems = [
  {
    icon: <UserCheck />,
    title: "Sign up as a student",
    items: [
      "Its free to sign up as a learner.  You can take paid 1-1 sessions and join public events for a subscription fee.",
      "If you want to just listen in to our public events as a listener member, it is completely free",
    ],
  },
  {
    icon: <SearchMd />,
    title: "Browse our unique catalog and schedule with a host",
    items: [
      "View various sessions offered by hosts from all over the world and broad industry spectrum based on your interests and needs.",
      "Events are specific topics that fall under carefully designed DDN programs. You can attend to Public Events and Paid Events based on your membership plan.",
    ],
  },
  {
    icon: <MessageChatCircle />,
    title: "Interactive session executed",
    items: [
      "Book sessions with peace of mind.",
      "You will see the meeting URL generated after your host activates the meeting room.",
      "Join the conversation and have fun!",
    ],
  },
  {
    icon: <MessageHeartSquare />,
    title: "Share your experience with us!",
    items: [
      <>
        <div className="mb-5">
          Reach out to us to share your experience, thoughts or suggestions on
          events and/or DDN programs."
        </div>
        <TextLink to="/contact">
          Contact us <ArrowRight className="inline" />
        </TextLink>
      </>,
    ],
  },
];
