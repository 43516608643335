//@DEPRECATED
import { getAllCourses } from "actions/front/common/course";
import { getUpcomingSessions } from "actions/front/educator/courses";
import { getStatistics } from "actions/front/mentorDashboard";
import { ReactComponent as AttendeeIcon } from "assets/images/icons/attendee-icon.svg";
import Calendar from "components/calendar";
import DashboardHeader from "components/common/dashboard/Header";
import Pill from "components/common/Pill";
import getTimezoneOffset from "components/common/TimezoneToGMT";
import DashboardCalendar from "components/dashboard/DashboardCalendar";
import DashboardCalendarToolbar from "components/dashboard/DashboardCalendarToolbar";
import EmptyUpcomingEventCardLarge from "components/event/EmptyUpcomingEventCardLarge";
import UpcomingEventCardLarge from "components/event/UpcomingEventCardLarge";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import getUserAvatar from "utils/getUserAvatar";
import { getCurrentTimeZone } from "utils/time";
import Spinner from "views/Spinner";

const HostDashboard = ({
  getStatistics,
  statistics,
  statisticsLoading,
  user,
  setOnlyId,
  onlyId
}) => {
  const [coursesList, setCoursesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [featuredEvent, setFeaturedEvent] = useState();

  const [calendarView, setCalendarView] = useState(dateOptions[0]);
  const [selectedDateRange, setSelectedDateRange] = useState(new Date());

  let timeZone = getCurrentTimeZone(user);
  let gmt = getTimezoneOffset(timeZone);

  const onStatisticsLoad = (data) => {
    // setStatistics(data); // messes up the actual statistics
  };

  const [filters, setFilters] = useState({
    limit: 1,
    page: 1,
    orderBy: "start_at",
    ascending: "asc",
    query: "",
    custom_filters: "",
  });

  useEffect(() => {
    setLoading(true);
    getUpcomingSessions({ ...filters }).then((res) => {
      if (res.status) {
        console.log(res.data);
        setFeaturedEvent(res.data.data[0]);
      }
      setLoading(false);
    });
    getStatistics();
  }, []);

  const refetch = async () => {
    getUpcomingSessions({ ...filters }).then((res) => {
      if (res.status) {
        console.log("====================getUpcomingSessions",res.data.data[0]);
        setFeaturedEvent(res.data.data[0]);
      } else {
        setFeaturedEvent(null);
      }
      setLoading(false);
    });
  };

  return (
    <div className="">
      <div className="md:w-full">
        <>
          <div className="md:w-full py-8 md:p-0">
            <DashboardHeader title={`Welcome back, ${user.first_name}!`} />
            {loading || statisticsLoading ? (
              <Spinner />
            ) : statistics ? (
              <>
                <div className="flex flex-col mt-6">
                  <div className="flex flex-row md:flex-col">
                    <div className="w-3/5 h-90 md:w-full">
                      {featuredEvent ? (
                        <UpcomingEventCardLarge
                          featuredEvent={featuredEvent}
                          gmt={gmt}
                          timeZone={timeZone}
                          refetch={refetch}
                          setOnlyId={setOnlyId}
                        />
                      ) : (
                        <EmptyUpcomingEventCardLarge />
                      )}
                    </div>
                    <div className="rounded-lg shadow border h-90 w-2/5 md:w-full md:ml-0 ml-3 relative bg-white md:mt-8">
                      <div className="flex flex-col p-5 mt-2">
                        <div className="text-base text-dgray-900 font-medium">
                          My Mentor Stats
                        </div>
                        <div className="text-sm text-dgray-500 mt-4">
                          Earnings to day
                        </div>
                        <div className="flex gap-4 mt-2">
                          <div className="text-3xl text-dgray-900 font-semibold">
                            ${statistics.totalEarned}
                          </div>
                          <div className="flex text-center items-center">
                            <Pill text={"8.1% vs last month"} color="green" />
                          </div>
                        </div>
                        <div className="text-sm text-dgray-500 mt-3">
                          Students attended
                        </div>
                        <div className="flex gap-4 mt-2">
                          <div className="text-3xl text-dgray-900 font-semibold">
                            {statistics.totalLearners}
                          </div>
                          <div className="flex text-center items-center">
                            <Pill text={"6.6% vs last month"} color="green" />
                          </div>
                        </div>
                        <div className="text-sm text-dgray-500 mt-3">
                          Classes to date
                        </div>
                        <div className="flex gap-4 mt-2">
                          <div className="text-3xl text-dgray-900 font-semibold">
                            {statistics.courseOffered}
                          </div>
                          <div className="justify-center text-center items-center">
                            <Pill text={"9.2% vs last month"} color="green" />
                          </div>
                        </div>
                      </div>
                      <div className="pb-8">
                        {coursesList.reverse().map(
                          (course, i) =>
                            i < 3 && (
                              <>
                                <div className="flex justify-between px-4 pt-4 pb-2">
                                  <div className="text-sm text-dgray-900 font-medium">
                                    {course.course_name}
                                  </div>
                                  <div>
                                    <div className="text-xs text-dgray-500">
                                      {moment
                                        .unix(course.start_at)
                                        .format("MMM D, Y")}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex justify-between px-4">
                                  <div className="flex gap-2.5">
                                    <img
                                      src={getUserAvatar(null)}
                                      alt=""
                                      className="rounded-full w-8 h-8"
                                    />
                                    <div className="">
                                      <div className="text-xs text-dgray-500 md:hidden">
                                        hosted by
                                      </div>
                                      <div className="text-sm font-medium">
                                        {course.mentor_name}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                        )}
                      </div>
                      <div className="border-t text-end p-4 font-medium text-primary-700 text-sm absolute md:relative inset-x-0 bottom-0">
                        <Link to={`/dashboard/get-paid`}>View report</Link>
                      </div>
                    </div>
                  </div>
                  <div className="flex md:flex-col gap-3">
                    <div className="w-2/5 md:w-full rounded-lg shadow border h-96 mt-3 relative bg-white">
                      <div className="p-3 mt-2">
                        <div className="text-base text-dgray-900 font-semibold mb-6">
                          Trending Topics (TBD)
                        </div>
                        <div className="flex gap-2.5 p-1">
                          <div className="w-full">
                            <div className="flex justify-between items-center">
                              Coming soon!
                            </div>
                          </div>
                        </div>
                        {/*
                        {trendings.map((course) => (
                          <div className="flex gap-2.5 p-1">
                            <div className="w-full">
                              <div>
                                <div className="flex justify-between items-center">
                                  <div className="text-sm font-medium text-dgray-900">
                                    {course.course_name}
                                  </div>
                                  <div className="text-sm text-primary-700">
                                    Details
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="flex justify-between items-center">
                                  <div className="text-sm text-dgray-500">
                                    <Pill text={course.category} color="blue" />
                                  </div>
                                  <div className="flex items-center text-xs text-dgray-500 text-primary-700 gap-2.5">
                                    <AttendeeIcon />
                                    <div>123 Attendess</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        */}
                      </div>
                      {/*
                      <div className="p-3 mt-2 border-t">
                        <div className="text-base text-dgray-900 font-semibold mb-6">
                          New on DDN
                        </div>
                        <div className="flex gap-2.5 p-1">
                          <div className="w-full">
                            <div>
                              <div className="flex justify-between items-center">
                                <div className="text-sm text-dgray-500">
                                  New payout method update from DDN
                                </div>
                                <div className="text-sm text-primary-700 gap-2.5">
                                  Details
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-t text-end p-4 font-medium text-primary-700 text-sm absolute md:relative inset-x-0 bottom-0">
                        View all
                      </div>
                      */}
                    </div>
                    <div className="w-full rounded-lg shadow border h-96 mt-3 relative bg-white">
                      <DashboardCalendar
                        calendarView={calendarView}
                        selectedDateRange={selectedDateRange}
                        Toolbar={DashboardCalendarToolbar}
                        refetch={refetch}
                        onlyId={onlyId}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <div className="col-md-12 space-between mt-4 mb-4 dashboard-btns"></div>
            <div className="col-md-12">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="calendar"
                  role="tabpanel"
                  aria-labelledby="calendar-tab"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <Calendar onStatisticsLoad={onStatisticsLoad} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

const trending = {
  course_name: "Deneme course name",
  category: "Businees",
  attendees: ["aylin", "arel"],
};

const trendings = [trending, trending, trending];

const mapStateToProps = (state) => ({
  user: state.auth.user,
  statistics: state.statistics.data,
  statisticsLoading: state.statistics.loading,
});

export default connect(mapStateToProps, {
  getAllCourses,
  getStatistics,
})(withRouter(HostDashboard));

const dateOptions = [
  {
    label: "Week",
    value: "week",
  },
  {
    label: "Month",
    value: "month",
  },
];

{
  /*
    <div className="col-md-9">
      <div className="row mb-3 dashboard-cards">
        <div className="col-md-3">
          <div className="box">
            <div className="card-body">
              <div className="info-row text-center">
                <i className="fa fa-book box-icon bg-theme" />
                <Link to="/dashboard/session-management">
                  <strong className="text-muted mb-1">
                    Total Sessions Offered
                  </strong>
                </Link>
                <h4>
                  {statisticsLoading ? <Spinner /> : statistics.total_courses}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box">
            <div className="card-body">
              <div className="info-row text-center">
                <i className="fa fa-calendar box-icon bg-theme" />
                <Link to="/dashboard/session-management">
                  <strong className="text-muted mb-1">
                    Bookable Interactions
                  </strong>
                </Link>
                <h4>
                  {statisticsLoading ? (
                    <Spinner />
                  ) : (
                    statistics.total_upcoming_session
                  )}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box">
            <div className="card-body">
              <div className="info-row text-center">
                <i className="fa fa-user box-icon bg-theme" />
                <strong className="text-muted mb-1">Total Learners</strong>
                <h4>
                  {statisticsLoading ? <Spinner /> : statistics.total_learner}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box">
            <div className="card-body">
              <div className="info-row text-center">
                <i className="fa fa-money box-icon bg-theme" />
                <Link to="#">
                  <strong className="text-muted mb-1">Total Earned</strong>
                 </Link>
                <h4>
                  {statisticsLoading ? (
                    <Spinner />
                  ) : (
                    statistics.total_earned.toFixed(2)
                  )}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 space-between mb-4 dashboard-btns">
          <div className="section-head space-between">
            <div>
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="calendar-tab"
                    data-toggle="tab"
                    href="#calendar"
                    role="tab"
                    aria-controls="calendar"
                    aria-selected="true"
                  >
                    <i className="fa fa-calendar" />
                  </a>
                </li>
              </ul>
            </div>
            <h4 className="ml-3">My DDN Calendar</h4>
          </div>
          <div>
            <Link to="/add-session" className="btn btn-secondary ml-3">
              Add New Session
            </Link>
            <Link
              to={{
                pathname: "/dashboard/session-management",
                activeResult: 1,
              }}
              className="btn btn-secondary ml-3"
            >
              View Upcoming Session
            </Link>
          </div>
        </div>
        <div className="col-md-12">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="calendar"
              role="tabpanel"
              aria-labelledby="calendar-tab"
            >
              <div className="row">
                <div className="col-md-12">
                  <MentorCalendar />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
            */
}
