import { getEventDetail } from "actions/front/mentorDashboard";
import { ReactComponent as GlobeIcon } from "assets/images/icons/globe.svg";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import LargeModal from "components/common/Modal/LargeModal";
import { ATTENDEE_ROLE, SESSION_TYPE } from "constants";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import getUserAvatar from "utils/getUserAvatar";
import { getTimeZone } from "utils/time";
import Spinner from "views/Spinner";
import moment from "moment";
import { educatorCancelCourseScheduled } from "actions/front/mentorDashboard";
const modalContentForHost = {
  title: "Event cancelation",
  content:
    "Are you sure you want to cancel your event? All registered attendees will be notified.",
};
function AttendeeListModal({
  getEventDetail,
  isOpen,
  setIsOpen,
  eventId,
  eventType,
  user,
  all,
  start_at,
  end_at,
}) {
  const tz = getTimeZone(user);
  const [isLoading, setIsLoading] = useState(true);
  const [speakers, setSpeakers] = useState([]);
  const [audience, setAudience] = useState([]);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  function openModal() {
    setIsCancelOpen(true);
  }

  const confirmCancel = (featuredEvent) => {
      featuredEvent = {...featuredEvent,...featuredEvent.session_data}
      educatorCancelCourseScheduled(featuredEvent).then((res) => {
        setIsLoading(true)
        setIsCancelOpen(false);
        init();
      }).catch(()=>{
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if(isOpen) {
      init();
    }
  }, [isOpen]);

  const init = async () => {
    const res = await getEventDetail(eventId);
    if (res.status) {
      const attendees = res.response.map((item) => item.learner);
      const speakerList = [];
      const audienceList = [];
      let endIndexList = [];
      let startIndexList = [];
      let count = 0;
      attendees.forEach((att, i) => {
        let singeAudience = { ...att };
        let index = i + 1;
        let isMap = count;
        if (eventType == 1) {
          if (
            att._id == attendees[index]?._id &&
            att.session_data.interaction_number <
              attendees[index]?.session_data?.interaction_number
          ) {
            startIndexList.push(i);
          } else {
            endIndexList.push(i);
            ++count;
          }
          if (!(isMap == count)) {
            let endSingleIndex = endIndexList.slice(-1)[0];
            let startSingleIndex = startIndexList[0] || 0;
            if (startSingleIndex == 0 && endIndexList.length > 1) {
              startSingleIndex = i;
            }
            attendees.forEach((single, k) => {
              if (k == endSingleIndex) {
                singeAudience.session_data.end_at = single.session_data.end_at;
                singeAudience.session_data.start_at =
                  attendees[startSingleIndex].session_data.start_at;
                if (!all) {
                  if (singeAudience.session_data.end_at == end_at) {
                    audienceList.push(singeAudience);
                  }
                } else {
                  audienceList.push(singeAudience);
                }
                startIndexList = [];
              }
            });
          }
        } else {
          if (att.role === ATTENDEE_ROLE.SPEAKER) speakerList.push(att);
          else {
            audienceList.push(att);
          }
        }
      });
      audienceList.sort(
        (a, b) => b.session_data.start_at - a.session_data.start_at
      );
      speakerList.sort(
        (a, b) => b.session_data.start_at - a.session_data.start_at
      );
      setAudience(audienceList);
      setSpeakers(speakerList);
    }
    setIsLoading(false);
  };

  return (
    <LargeModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <h2 className="text-2xl font-semibold text-center mb-2">Attendees</h2>
      {isLoading ? (
        <Spinner />
      ) : eventType === SESSION_TYPE.LIVE_EVENT ? (
        <>
          <div className="flex justify-between md:flex-col gap-6 w-3/4 mx-auto lg:w-full py-10">
            <div className="flex-1 md:mb-10">
              <div className="font-medium text-center text-dgray-800 mb-4">
                audience
              </div>
              <ul className="space-y-4 overflow-y-scroll max-h-72">
                {audience?.length === 0 ? (
                  <div className="text-dgray-500 text-center text-sm">
                    No audience found
                  </div>
                ) : (
                  audience.map((att) => (
                    <div className="flex gap-2">
                      <img
                        src={getUserAvatar(att.avatar)}
                        alt="speaker avatar"
                        className="rounded-full w-10 h-10"
                      />
                      <div>
                        <div className="text-sm font-medium mb-1">
                          {att.first_name} {att.last_name}
                        </div>
                        <div className="text-xs text-dgray-500">
                          <GlobeIcon className="inline mr-1 align-top" />
                          {att?.country || att?.city}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </ul>
            </div>
            <div className="flex-1 md:mb-10">
              <div className="font-medium text-center text-dgray-800 mb-4">
                Speakers
              </div>
              <ul className="space-y-4 overflow-y-scroll max-h-72">
                {speakers?.length === 0 ? (
                  <div className="text-dgray-500 text-center text-sm">
                    No speakers found
                  </div>
                ) : (
                  speakers.map((att) => (
                    <div className="flex gap-2">
                      <img
                        src={getUserAvatar(att.avatar)}
                        alt="speaker member avatar"
                        className="rounded-full w-10 h-10"
                      />
                      <div>
                        <div className="text-sm font-medium mb-1">
                          {att.first_name} {att.last_name}
                        </div>
                        <div className="text-xs text-dgray-500">
                          <GlobeIcon className="inline mr-1 align-top" />
                          {att?.country || att?.city}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex w-3/4 mx-auto lg:w-full py-10">
            <div className="flex-1 flex justify-between md:flex-col gap-6">
              <div className="flex-1 md:mb-10">
                {audience?.length === 0 ? (
                  <div className="text-dgray-500 text-center text-sm">
                    No students found.
                  </div>
                ) : (
                  audience.map((att) => (
                    <div className="flex-1 flex w-full justify-between items-center mb-4">
                      <div className="flex gap-2">
                        {/* {moment.unix(startAt).format("MMM D, h:mm A")} */}
                        {moment
                          .unix(att.session_data.start_at)
                          .tz(tz)
                          .format("MMM D, h:mm A")}{" "}
                        -
                        {moment
                          .unix(att.session_data.end_at)
                          .tz(tz)
                          .format("h:mm A")}
                      </div>
                      <div className="flex gap-3">
                        <img
                          src={getUserAvatar(att.avatar)}
                          alt="listener member avatar"
                          className="rounded-full w-10 h-10"
                        />
                        <div>
                          <div className="text-sm font-medium mb-1">
                            {att.first_name} {att.last_name}
                          </div>
                          <div className="text-xs text-dgray-500">
                            <GlobeIcon className="inline mr-1 align-top" />
                            {att?.country || att?.city}
                          </div>
                        </div>
                        {/* //&&
                        //  */}
                        {all && moment.unix(att.session_data.start_at).tz(tz) >  moment.unix().tz(tz) ? (
                          <div className="text-red-700 text-sm flex justify-between items-center">
                            <button
                              type="button"
                              onClick={openModal}
                              className=""
                            >
                              Cancel the event
                            </button>
                          </div>
                        ) : null}
                        <Modal
                          isOpen={isCancelOpen}
                          setIsOpen={setIsCancelOpen}
                          modalContent={modalContentForHost}
                          confirmCancel={() => confirmCancel(att)}
                        />
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="text-center">
        <Button
          type="button"
          className="w-1/2 md:w-full"
          onClick={() => setIsOpen(false)}
          small
          secondary
        >
          Close
        </Button>
      </div>
    </LargeModal>
  );
}

export default connect(({ auth }) => ({ user: auth.user }), { getEventDetail })(
  AttendeeListModal
);
