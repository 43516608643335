import janeImg from "assets/images/jane-doe.jpg";
import mockEventImg from "assets/images/mock-event.jpg";
import EventCard from "components/event/EventCard";
import { getAllCourses } from "actions/front/common/course";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../../../components/common/Button";
import CenterContainer from "components/common/Container/Center";
import Spinner from "views/Spinner";

const filters = {
  limit: 3,
  page: 1,
  orderBy: "created_at",
  ascending: "asc",
  query: "",
  custom_filters: "",
};

function HomePageSignedInEvents() {
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState();

  useEffect(() => {
    setLoading(true);
    getAllCourses(filters).then((res) => {
      if (res.status) {
        setCourses(res.data.data);
      }
      setLoading(false);
    });
  }, []);

  return (
    <CenterContainer className="text-center mb-24 md:mb-16">
      <h2 className="text-4xl md:text-3xl font-semibold mb-5">
        Events You Might Like
      </h2>
      <p className="text-dgray-500 mb-16 md:mb-10 text-xl md:text-lg">
        Outstanding courses based on your interests and learning history.
      </p>
      <div className="flex justify-center lg:flex-col gap-8 md:gap-4 mb-16 md:mb-8">
        {loading ? (
          <Spinner />
        ) : (
          courses &&
          courses.length > 0 &&
          courses.map((course, i) => <EventCard data={course} key={i} />)
        )}
      </div>
      <Button href="/events" secondary small>
        See All Courses
      </Button>
    </CenterContainer>
  );
}

export default connect((state) => ({
  user: state.auth.user,
}))(HomePageSignedInEvents);

