export function SelectableButton({ isSelected, onClick, children }) {
  return (
    <button
      onClick={onClick}
      className={`py-2.5 px-3.5 rounded-md mr-2 font-medium
        ${isSelected ? "bg-primary-50 text-primary-700" : "text-dgray-500"}
      `}
    >
      {children}
    </button>
  );
}
