import React, { useState, useMemo, useEffect } from "react";
import * as moment from 'moment-timezone';
import { SESSION_TYPE } from "constants/index";
import { getCurrentTimeZone } from "utils/time";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const AskMeAnythingDetailPage = ({
  courseDetail,
  user,
  setSendSessionData,
  setResetData,
  resetData,
  genericSessionTiming,
  setIsGenericExist,
  isGenericExist,
}) => {
  let timeZone = getCurrentTimeZone(user);

  const [sessionDuration, setSessionDuration] = useState(genericSessionTiming);
  const [sessionDates, setSessionDates] = useState([]);
  const [selectedSessionDate, setSelectedSessionDate] = useState("");
  const [sessionTime, setSessionTime] = useState([]);
  const [selectedSessionTime, setSelectedSessionTime] = useState("");
  const [durationText, setDurationText] = useState(null);

  const SELECTED_SESSION_DURATION = {
    15: 1,
    30: 2,
    45: 3,
    60: 4,
    90: 6,
    120: 8,
  };

  const SELECTED_SESSION_DURATION_REVERSE = {
    1: 15,
    2: 30,
    3: 45,
    4: 60,
    6: 90,
    8: 120,
  };

  const checkSessionAvaiblity = (knowAboutSessionDate) => {
    let selectedDuration = courseDetail.session_detail[0].duration_info.filter(
      (singleDuration) => {
        return (
          singleDuration.duration_per_session ==
          SELECTED_SESSION_DURATION_REVERSE[sessionDuration]
        );
      }
    );

    let newSessionTime = [];
    courseDetail.session_detail.map((singleSessionDetail) => {
      let sortedSessions = singleSessionDetail.session_group.all_session_with_recurring.sort(
        (a, b) => a.start_at - b.start_at
      );
      
      let currentDate = moment().tz(timeZone).format("YYYY-MM-DD HH:mm");
      sortedSessions = sortedSessions.filter(
        (session_filter) =>
          moment
            .unix(session_filter.start_at)
            .tz(timeZone)
            .format("YYYY-MM-DD HH:mm") > currentDate
      );
      sortedSessions.map((singleSession, idx) => {
        let customDate = moment
          .unix(singleSession.start_at)
          .tz(timeZone)
          .format("YYYY-MM-DD");

        if (
          customDate === knowAboutSessionDate &&
          singleSession.session_booking_count === 0 &&
          SELECTED_SESSION_DURATION[selectedDuration[0].duration_per_session] +
            idx <=
            sortedSessions.length
        ) {
          let customTime = moment
            .unix(singleSession.start_at)
            .tz(timeZone)
            .format("HH:mm A");
          let count = 1;
          let isDoesExist = true;
          let selectedDateTime = moment(
            moment
              .unix(singleSession.start_at)
              .tz(timeZone)
              .format("YYYY-MM-DD HH:mm")
          );
          let selectedChunckGroup = [singleSession];
          let nextShouldBeSession = moment(
            selectedDateTime
              .clone()
              .add(15, "minutes")
              .format("YYYY-MM-DD HH:mm")
          );
          while (
            isDoesExist &&
            count <
              SELECTED_SESSION_DURATION[
                selectedDuration[0].duration_per_session
              ]
          ) {
            if (sortedSessions[idx + count].session_booking_count === 0) {
              let nextSession = moment(
                moment
                  .unix(sortedSessions[idx + count].start_at)
                  .tz(timeZone)
                  .format("YYYY-MM-DD HH:mm")
              );
              if (nextShouldBeSession.unix() != nextSession.unix()) {
                isDoesExist = false;
              } else {
                selectedChunckGroup.push(sortedSessions[idx + count]);
              }
            } else {
              isDoesExist = false;
            }
            nextShouldBeSession = moment(
              nextShouldBeSession
                .clone()
                .add(15, "minutes")
                .format("YYYY-MM-DD HH:mm")
            );
            count++;
          }
          if (isDoesExist) {
            newSessionTime.push({
              selectedChunck: {
                groupId: singleSessionDetail.session_group._id,
                selectedSessions: [...selectedChunckGroup],
              },
              label: customTime,
            });
          }
        }
      });
    });
    return newSessionTime;
  };

  useMemo(async () => {
    if (sessionDuration != "") {
      let newSessionDates = [];
      let currentDate = moment().tz(timeZone).format("YYYY-MM-DD HH:mm");
      let sessionExist = false;
      let courseDetailPromise = await courseDetail.session_detail.map(
        async (singleSessionDetail) => {
          let sortedSessions = singleSessionDetail.session_group.all_session_with_recurring.sort(
            (a, b) => a.start_at - b.start_at
          );
          let sortedSessionPromise = await sortedSessions.map(
            (singleSession) => {
              if (
                singleSession.session_booking_count === 0 &&
                moment
                  .unix(singleSession.start_at)
                  .tz(timeZone)
                  .format("YYYY-MM-DD HH:mm") > currentDate
              ) {
                let customDate = moment
                  .unix(singleSession.start_at)
                  .tz(timeZone)
                  .format("YYYY-MM-DD");
                if (!newSessionDates[customDate]) {
                  let selectedSessionTimeList = checkSessionAvaiblity(
                    customDate
                  );
                  if (selectedSessionTimeList.length > 0) {
                    sessionExist = true;
                    newSessionDates[customDate] = customDate;
                  }
                }
              }
            }
          );
          await Promise.all(sortedSessionPromise);
        }
      );
      await Promise.all(courseDetailPromise);
      setIsGenericExist(sessionExist);

      setSessionDates(newSessionDates);
    }
    setSelectedSessionDate("");
    setSelectedSessionTime("");
  }, [sessionDuration]);

  useMemo(() => {
    if (selectedSessionDate != "") {
      let selectedSessionTimeList = checkSessionAvaiblity(selectedSessionDate);

      setSessionTime([...selectedSessionTimeList]);
    } else {
      setSessionTime([]);
    }
    setSelectedSessionTime("");
  }, [selectedSessionDate]);

  useMemo(() => {
    // console.log(selectedSessionTime);
    if (selectedSessionTime != "") {
      let finalSessionSelected = sessionTime.filter((singleSessionTime) => {
        return singleSessionTime.label === selectedSessionTime;
      });
      let selectedDuration = courseDetail.session_detail[0].duration_info.filter(
        (singleDuration) => {
          return (
            singleDuration.duration_per_session ==
            SELECTED_SESSION_DURATION_REVERSE[sessionDuration]
          );
        }
      );
      setSendSessionData({
        ...finalSessionSelected[0].selectedChunck,
        duration: selectedDuration[0].duration_per_session,
      });
    } else {
      setSendSessionData(null);
    }
  }, [selectedSessionTime]);

  useMemo(() => {
    if (resetData) {
      setResetData(null);
      setSessionDuration("");
      setSelectedSessionDate("");
      setSelectedSessionTime("");
      setSessionDates([]);
      setSessionTime([]);
      setSendSessionData(null);
    }
  }, [resetData]);

  return isGenericExist ? (
    <div className="select-type-details">
      <h5>One on One</h5>
      <ul className="info-list">
        <li>
          <span>Duration</span>
          <div className="form-group m-0">
            <strong>
              {SELECTED_SESSION_DURATION_REVERSE[sessionDuration]}
              Mins
            </strong>
          </div>
        </li>

        {sessionDuration != "" && (
            <li>
            <span className="mb-1">Date</span>
            <DatePicker
              minDate={new Date()}
              selected={selectedSessionDate ? moment(selectedSessionDate, "YYYY-MM-DD").toDate() : null}
              onChange={date => setSelectedSessionDate(moment(date).format("YYYY-MM-DD"))}
              highlightDates={Object.keys(sessionDates).map(d => moment(d, "YYYY-MM-DD").toDate())}
              inline
            />
          </li>
        )}
        {selectedSessionDate != "" && (
          <li>
          <span>Time ({moment.tz(timeZone).format("z")})</span>
          <UncontrolledDropdown>
              <DropdownToggle>
                {selectedSessionTime ?? 'Pick A Time'} 
              </DropdownToggle>
              <DropdownMenu>
              {sessionTime.map((singleTime, i) =>
                {
                  return (
                    <DropdownItem onClick={(e) => {setSelectedSessionTime(singleTime.label);}}>
                      {singleTime.label}
                    </DropdownItem>
                  );
                }
              )}
              </DropdownMenu>
          </UncontrolledDropdown>
        </li>
        )}
      </ul>
    </div>
  ) : (
    <div> No Sessions Available </div>
  );
};

export default AskMeAnythingDetailPage;
