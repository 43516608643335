import React, { Component, Fragment } from "react";

const Pagination = ({ recordPerPage, totalRecords, currentPage, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalRecords / recordPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <Fragment>
      <nav aria-label="Page navigation">
        <ul className="pagination justify-content-center">
          {totalRecords > recordPerPage ? (
            <li className="page-item">
              {currentPage > 1 ? (
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </button>
              ) : (
                <button className="page-link">Previous</button>
              )}
            </li>
          ) : (
            ""
          )}

          {pageNumbers.map((page, index) => {
            return (
              <li
                key={index}
                className={
                  currentPage == page ? "page-item active" : "page-item"
                }
              >
                <button className="page-link" onClick={() => paginate(page)}>
                  {page}
                </button>
              </li>
            );
          })}
          {totalRecords > recordPerPage ? (
            <li className="page-item">
              {currentPage < Math.ceil(totalRecords / recordPerPage) ? (
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </button>
              ) : (
                <button className="page-link">Next</button>
              )}
            </li>
          ) : (
            ""
          )}
        </ul>
      </nav>
    </Fragment>
  );
};
export default Pagination;
