import { useState } from "react";

export default function useImageCropper({ src, onComplete, aspect }) {
    const [isOpen, setIsVisible] = useState(false);
    const show = () => setIsVisible(true);
    const hide = () => setIsVisible(false);
  
    return {
      show,
      cropperProps: { hide, isOpen, src, onComplete, aspect },
    };
  }
  