import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import Spinner from "views/Spinner";
import * as moment from "moment-timezone";
import {
  getEducatorOneOnOnePayments,
  getEducatorFullPaymentList,
} from "actions/admin/educatorPayment";
import {
  SESSION_TYPE,
  SESSION_TYPE_REVERSE,
  PAYMENT_STATUS,
} from "constants/index";
import * as Constants from "constants/index";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import overlayFactory from "react-bootstrap-table2-overlay";
import CustomFilters from "./CustomFiltersList";
// import { payToMentorNow } from "actions/admin/educatorPayment";
import { CSVLink } from "react-csv";
import { getCurrentTimeZone } from "utils/time";

const OneOnOnePaymentList = ({
  auth: { user },
  getEducatorOneOnOnePayments,
  // payToMentorNow,
}) => {
  const [setList, setOrderList] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const [payoutList, setPayoutList] = useState([]);
  const [totalRecord, setTotalRecords] = useState(0);

  //################## Initillise sorting searching parameters by default values ###################
  const [userParams, setUserParams] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "session_data",
    ascending: "asc",
    query: "",
  });

  let timeZone = getCurrentTimeZone(user);

  useEffect(() => {
    getEducatorOneOnOnePayments(userParams).then((res) => {
      if (res?.status) {
        setLoading(false);
        setTotalRecords(res.count);
        setOrderList(res.data);
        setCount(res.count);
      } else {
        setOrderList([]);
        setCount(0);
      }
    });
  }, [userParams]);

  useMemo(() => {
    if (totalRecord > 0) {
      getEducatorFullPaymentList(totalRecord).then((res) => {
        let arr = [];
        res.data.map((item) => {
          let obj = {};
          obj.course_name = item.course_name;
          obj.mentor_name = item.mentor_name;
          obj.mentor_username = item.mentor_username;
          obj.session_type = SESSION_TYPE_REVERSE[item.session_type];
          obj.user_fullname = item.user_fullname;
          obj.net_amount = item.net_amount;
          obj.commission = item.commission;
          obj.order_id = item.order_id;
          obj.session_detail =
            moment
              .unix(item.session_data[0].start_at)
              .tz("UTC")
              .format("DD MMM, YYYY hh:mm A") +
            " to " +
            moment
              .unix(item.session_data[item.session_data.length - 1].end_at)
              .tz("UTC")
              .format("DD MMM, YYYY hh:mm A");
          obj.meeting_id = item.session_data[0].meeting_id;
          obj.payment_status =
            item.net_amount === 0 ? "N/A" : PAYMENT_STATUS[item.payment_status];
          arr.push(obj);
        });
        setPayoutList(arr);
      });
    }
  }, [totalRecord]);

  //################## Handel table change(sorting, searching, status change) ###################
  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    setUserParams({
      ...userParams,
      page: type === "search" ? 1 : page,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      query: searchText,
    });
  };

  // //################## pay to mentor ###################

  // const [paymentButtonId, setPaymentButtonId] = useState("");

  // const payNow = async (mentor_username, payment_detail, _id) => {
  //   setPaymentButtonId(_id);
  //   payToMentorNow(mentor_username, payment_detail).then(async (res) => {
  //     if (res.status) {
  //       setPaymentButtonId("");
  //       let newList = [];

  //       let promise = await setList.map((singleData) => {
  //         newList.push(
  //           singleData._id == _id
  //             ? { ...singleData, order_status: 2 }
  //             : singleData
  //         );
  //       });
  //       await Promise.all(promise);
  //       setOrderList(newList);
  //     }
  //   });
  // };

  const CSVHeaders = [
    { label: "Course Name", key: "course_name" },
    { label: "Mentor Name", key: "mentor_name" },
    { label: "Mentor UserName", key: "mentor_username" },
    { label: "Session Type", key: "session_type" },
    { label: "Session Details", key: "session_detail" },
    { label: "Attendee name", key: "user_fullname" },
    { label: "Total Amount", key: "net_amount" },
    { label: "Comission", key: "commission" },
    { label: "Order Id", key: "order_id" },
    { label: "Payment Status", key: "payment_status" },
    { label: "Meeting Id", key: "meeting_id" },
  ];

  const DownloadXls = () => (
    <a href="#" className="download-xls">
      <CSVLink
        className="fa fa-file-excel-o text-orange "
        data={payoutList}
        headers={CSVHeaders}
        filename={`Payment-List ${moment
          .unix(moment().utc().unix())
          .format("MM/DD/YYYY HH:MM:ss")}.csv`}
      >
        {" "}
        Download
      </CSVLink>
    </a>
  );

  const columns = [
    {
      dataField: "course_name",
      text: "Course Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "mentor_name",
      text: "Mentor Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "mentor_username",
      text: "User Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "session_data",
      text: "Session Details",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">
            <small>Sessions:</small> <br />
            {row.session_type == SESSION_TYPE.GENERIC ? (
              <>
                <span>
                  {moment
                    .unix(row.session_data[0].start_at)
                    .tz(timeZone)
                    .format("DD MMM, YYYY")}{" "}
                  |{" "}
                  {moment
                    .unix(row.session_data[0].start_at)
                    .tz(timeZone)
                    .format("hh:mm A")}{" "}
                  -{" "}
                  {moment
                    .unix(row.session_data[row.session_data.length - 1].end_at)
                    .tz(timeZone)
                    .format("hh:mm A")}
                </span>
                <br />
              </>
            ) : (
              row.session_data.map((singleSession) => {
                return (
                  <>
                    <span>
                      {moment
                        .unix(singleSession.start_at)
                        .tz(timeZone)
                        .format("DD MMM, YYYY hh:mm A")}{" "}
                    </span>
                    <br />{" "}
                  </>
                );
              })
            )}
          </p>
          <p className="mb-1">
            <small>No. of session</small>
            <br />
            <span>
              {row.session_type == SESSION_TYPE.GENERIC
                ? "N/A"
                : row.session_data.length}
            </span>
          </p>
        </>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "user_fullname",
      text: "Attendee name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "net_amount",
      text: "Total Amount",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      formatter: (cellContent, row) => row.net_amount.toFixed(2),
    },
    {
      dataField: "commission",
      text: "Commission",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      formatter: (cellContent, row) => row.commission.toFixed(2),
    },
    {
      dataField: "session_data",
      text: "Meeting Id",
      sort: true,
      formatter: (cellContent, row) => (
        <span>{row.session_data[0].meeting_id}</span>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "order_id",
      text: "Order Id",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "payment_status",
      text: "Payment Status",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      formatter: (cellContent, row) =>
        row.net_amount === 0 ? "N/A" : PAYMENT_STATUS[row.payment_status],
    },
    // {
    //   dataField: "can_be_paid",
    //   text: "Commission",
    //   headerStyle: {
    //     backgroundColor: Constants.TABLE_BORDER_COLOR,
    //   },
    //   formatter: (cellContent, row) => (
    //     <div>
    //       {row.order_status == 1 ? (
    //         row.can_be_paid ? (
    //           paymentButtonId == row._id ? (
    //             <Spinner />
    //           ) : (
    //             <Button
    //               type="button"
    //               size="sm"
    //               color="success"
    //               onClick={(e) =>
    //                 payNow(row.mentor_username, row.payment_detail, row._id)
    //               }
    //             >
    //               Pay Now
    //             </Button>
    //           )
    //         ) : (
    //           "-"
    //         )
    //       ) : (
    //         "Paid"
    //       )}
    //     </div>
    //   ),
    // },
  ];

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li
      key={text}
      role="presentation"
      className="dropdown-item"
      onMouseDown={(e) => {
        e.preventDefault();
        onSizePerPageChange(page);
      }}
    >
      <Button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        className="sizePerPageaTag"
      >
        {text}
      </Button>
    </li>
  );

  const defaultSorted = [
    {
      dataField: "session_data",
      order: "asc",
    },
  ];

  const customTotal = (from, to, size) => (
    <>
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    </>
  );

  const options = {
    page: parseInt(userParams.page),
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: count,
    sizePerPageOptionRenderer,
    sizePerPageList: [
      {
        text: Constants.DEFAULT_PAGE_SIZE,
        value: Constants.DEFAULT_PAGE_SIZE,
      },
      {
        text: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
        value: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
      },
      {
        text: "All",
        value: count,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <div className="animated fadeIn displayTableList">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(options)}
                  keyField="_id"
                  columns={columns}
                  data={setList}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="_id"
                      data={setList}
                      columns={columns}
                      search
                    >
                      {(toolkitprops) => (
                        <>
                          <>
                            <div className="per-page-div">
                              Record per page:
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <DownloadXls />
                            <CustomFilters {...toolkitprops.searchProps} />
                          </>
                          <BootstrapTable
                            {...toolkitprops.baseProps}
                            bootstrap4
                            remote={{
                              pagination: true,
                              filter: true,
                              sort: true,
                            }}
                            keyField="_id"
                            data={setList}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={handleTableChange}
                            defaultSorted={defaultSorted}
                            noDataIndication={Constants.NO_RECORD}
                            bordered={false}
                            hover
                            loading={loading}
                            overlay={overlayFactory({
                              spinner: <Spinner />,
                              styles: {
                                overlay: (base) => ({
                                  ...base,
                                  background: "#E8E8E8",
                                }),
                              },
                            })}
                          />
                        </>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

OneOnOnePaymentList.propTypes = {
  getEducatorOneOnOnePayments: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userList: state.user.usersList,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getEducatorOneOnOnePayments,
  getEducatorFullPaymentList,
  // payToMentorNow,
})(withRouter(OneOnOnePaymentList));
