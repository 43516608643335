import {
  STATISTICS_FAILED,
  STATISTICS_LOADING,
  STATISTICS_SUCCESS,
} from "actions/types";
import axios from "utils/axios";

//to find all the courses and events of user
export const getEvents = () => async (dispatch) => {
  const res = await axios.get("/api/dashboard/mentor/events");
  return res.data;
};

export const getEventDetail = (id) => async (dispatch) => {
  const res = await axios.get(`/api/dashboard/mentor/events/${id}`);
  return res.data;
};

export const getEventDetailState = (id,state) => async (dispatch) => {
  const res = await axios.get(`/api/dashboard/mentor/eventsState/${id}?state=${state}`);
  return res.data;
};

// get Statistics for educator dashboard
export const getStatistics = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({
      type: STATISTICS_LOADING,
      payload: { is_loading: true },
    });
    const res = await axios.get(`/api/myaccount/statistics`, config);
    if (res.data.status === true) {
      dispatch({
        type: STATISTICS_SUCCESS,
        payload: { data: res.data.response },
      });
    } else {
      dispatch({
        type: STATISTICS_FAILED,
        payload: { error: res.data.response },
      });
    }
  } catch (error) {
    dispatch({
      type: STATISTICS_FAILED,
      payload: { error },
    });
  }
};

//educator cancel booked course
export const educatorCancelCourseScheduled = async (course_detail)  => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `/api/educator/course/cancel-session`,
        course_detail,
        config
      );

      if (res.data.status === true) {
        return { status: true, data: res.data.response, msg: res.data.message };
      } else {
        return { status: false, msg: res.data.message };
      }
    } catch (error) {
      return { status: false, msg: "Something went wrong. Please try again." };
    }
  };
