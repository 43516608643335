import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import {
  getMycourseSessions,
  cancelCourseScheduled,
} from "actions/front/learner/courses";
import {
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormText,
} from "reactstrap";
import NoRecord from "views/NoRecord";
import * as moment from "moment-timezone";
import CourseImage from "assets/images/mock-event.jpg";
import Pagination from "views/Front/Pagination";
import {
  RECORD_PER_PAGE,
  COURSE_VIEW,
  BOOKED_SESSION_STATUS,
  SESSION_TYPE,
  CHIME_CLIENT_URL,
} from "constants/index";
import Spinner1 from "views/Spinner1";
import { getCourseDetailById } from "actions/front/common/course";
import { rescheduleSession } from "actions/front/learner/order";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import AskMeAnythingDetailPage from "views/Front/ProfileCourseMgmt/MyCourses/RescheduleSession/AskMeAnythingDetailPage";
import SpecificDetailPage from "views/Front/ProfileCourseMgmt/MyCourses/RescheduleSession/SpecificDetailPage";
import { getCurrentTimeZone } from "utils/time";
import Courses from "views/Front/ProfileCourseMgmt/CourseManagement/Courses";

const UpcomingSession = ({
  COURSE_LIST,
  getMycourseSessions,
  cancelCourseScheduled,
  user,
  token,
  rescheduleSession,
  getCourseDetailById,
  history,
}) => {
  let timeZone = getCurrentTimeZone(user);
  let currentDate = moment().tz(timeZone);

  let unixTimeStamp = moment.utc().unix();
  let now = moment(
    moment.unix(unixTimeStamp).tz(timeZone).format("YYYY-MM-DD HH:mm:ss")
  );

  const [loader, setLoader] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [cancelLoader, setCancelLoader] = useState(false);
  const activeResults = COURSE_LIST.UPCOMING;

  const getCourses = () => {
    setLoader(true);

    getMycourseSessions(COURSE_LIST.UPCOMING, page).then((res) => {
      if (res.status) {
        let tempData = [];
        // const mycourses = res.data.data.sort((a, b) => a.start_at - b.start_at);
        res.data.data.map((singleSession) => {
          let start_at = moment.unix(singleSession.start_at).tz(timeZone);
          let end_at = moment.unix(singleSession.end_at).tz(timeZone);
          let check_start_date = moment
            .unix(singleSession.check_session_cancelation_start_at)
            .tz(timeZone);
          let check_Date = moment(
            moment(currentDate)
              .add(singleSession.cancellation_maxhour, "hours")
              .format("YYYY-MM-DD HH:mm")
          );

          tempData.push({
            ...singleSession,
            start_at: start_at,
            end_at: end_at,
            can_be_canceled:
              check_start_date.unix() > check_Date.unix() ? true : false,
          });
        });
        setSessions(tempData);
        setTotalRecords(res.data.metadata[0].totalRecord);
      }
      setLoader(false);
    });
  };

  useMemo(() => {
    getCourses();
  }, [page]);

  const paginate = (page_no) => {
    setPage(page_no);
  };

  /****************************cancel schedule************************ */
  const onCancelClick = async (e, course_detail_id) => {
    confirmAlert({
      title: <h6>Please be aware of our cancellation policy:</h6>,
      message: (
        <div>
          <span>1- Cancelling &gt; 24 hours is free</span>
          <br />
          <span>2- Only 50% will be refunded if Cancelling &lt; 24 hours</span>
        </div>
      ),
      buttons: [
        {
          label: "Ok",
          onClick: () => confirmCancel(course_detail_id),
        },
        {
          label: "Back",
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const confirmCancel = async (course_detail_id) => {
    setCancelLoader(true);
    cancelCourseScheduled(course_detail_id).then(async (res) => {
      if (res.status) {
        let newSessionData = [...sessions];
        await newSessionData.map((singleSession) => {
          if (singleSession.course_detail_id === course_detail_id) {
            singleSession.course_status =
              BOOKED_SESSION_STATUS.CANCLLED_BY_LEARNER;
          }
        });
        Swal.fire(res.msg, "", "success");
        setSessions([...newSessionData]);
        setCancelLoader(false);
      } else {
        Swal.fire("Oops...", res.msg, "error");
        setCancelLoader(false);
      }
    });
  };
  /****************************Reschedule************************************/
  const [modal, setModal] = useState(false);
  const [courseDetail, setCourseDetail] = useState(null);
  const [isCourseFull, setIsCourseFull] = useState(false);
  const [sessionType, setSessionType] = useState(null);
  const [genericSessionTiming, setGenericSessionTiming] = useState("");
  const [alreadyScheduledId, setAlreadyScheduledId] = useState(null);
  const toggle = () => setModal(!modal);
  const onRescheduleClick = (
    e,
    already_scheduled_course_detail_id,
    course_id,
    session_type,
    session_timing
  ) => {
    getCourseDetailById(course_id).then((res) => {
      if (res.status) {
        setIsCourseFull(false);
        setGenericSessionTiming(session_timing);
        setSessionType(session_type);
        setAlreadyScheduledId(already_scheduled_course_detail_id);
        setCourseDetail(res.data);
        setModal(!modal);
      } else {
        if (res.is_full) {
          setIsCourseFull(true);
          setModal(!modal);
        }
      }
    });
  };

  /********************in form submit, to schedule *****************/
  const [sendSessionData, setSendSessionData] = useState(null);
  const [errorSuccess, setErrorSuccess] = useState({ isError: null, msg: "" });
  const [scheduleLoader, setScheduleLoader] = useState(false);
  const [resetData, setResetData] = useState(null);

  const onScheduleClick = (e) => {
    e.preventDefault();
    if (sendSessionData === null) {
      setErrorSuccess({
        isError: true,
        msg: "Please select at least one session.",
        cartBtn: false,
      });
      setTimeout(() => setErrorSuccess({ isError: null, msg: "" }), 10000);
    } else {
      setScheduleLoader(true);
      rescheduleSession(
        courseDetail._id,
        sendSessionData,
        alreadyScheduledId
      ).then((res) => {
        if (!res.status) {
          setErrorSuccess({
            isError: true,
            msg: res.msg,
          });
          setScheduleLoader(false);
          setTimeout(() => setErrorSuccess({ isError: null, msg: "" }), 10000);
        } else {
          setResetData(true);
          alert(res.msg);
          getCourses();
          setModal(!modal);
          setScheduleLoader(false);
        }
      });
    }
  };

  /********************check if no session exist in individual or group if type is SPECIFIC_BOTH *****************/
  const [isIndividualExist, setIsIndividualExist] = useState(false);
  const [isGroupExist, setIsGroupExist] = useState(false);
  const [isGenericExist, setIsGenericExist] = useState(false);

  return (
    <div className="tab-pane fade show active" id="upcoming">
      {cancelLoader && <Spinner1 />}
      {loader ? (
        <Spinner />
      ) : sessions.length > 0 ? (
        <>
          <div className="row">
            <div className="col-md-12">
              <ul className="tip">
                <li>
                  You mentor will be responsbile for generating the link{" "}
                  <strong>
                    <span className="text-orange">at least 5</span>
                  </strong>{" "}
                  mins before the start of the session. The link will not be
                  generate prior to 5 mins before the start. Use the{" "}
                  <strong>
                    <span className="text-orange">refresh button</span>
                  </strong>{" "}
                  to check back
                </li>
                <li>
                  If link is not generated after{" "}
                  <strong>
                    <span className="text-orange">
                      10 mins after the start time
                    </span>
                  </strong>{" "}
                  of your please{" "}
                  <strong>
                    <Link to="/contact" className="text-orange">
                      contact us
                    </Link>
                  </strong>{" "}
                  or email us at{" "}
                  <strong>
                    <span className="text-orange">info@dingdongnow.com</span>
                  </strong>
                  . We will contact your mentor to fix the problem
                </li>
              </ul>
            </div>
          </div>
          {/*<Courses activeResult={activeResults} page={COURSE_LIST} courseList={sessions} courseView={COURSE_VIEW.RESCHEDULE} loading={loader} />*/}

          <div className="row">
            {sessions.map((singleSession) => (
              <div className="col-md-4 mb-3">
                {/* Upcoming Card Start   */}
                <div className="course">
                  <div className="col">
                    <div className="row bg-dark-grey">
                      <Link to={`/session/${singleSession.course_id}`}>
                        <h4 className="title white-text">
                          <strong style={{ fontSize: 16 }}>
                            <i
                              class="fa fa-calendar mr-2"
                              style={{ fontSize: 25 }}
                            ></i>
                            {singleSession.course_name.length > 20
                              ? singleSession.course_name.substring(0, 20) +
                                " ..."
                              : singleSession.course_name}
                          </strong>
                        </h4>
                      </Link>
                    </div>
                    <div className="info-row mt-3">
                      <span>Session Type</span>
                      <small>
                        <span
                          className="text-orange"
                          style={{ fontWeight: 700 }}
                        >
                          {singleSession.session_type === 1
                            ? "DD Me for Anything"
                            : null}
                          {singleSession.session_type === 2
                            ? "1-1 Session Offered"
                            : null}
                          {singleSession.session_type === 3
                            ? "Group Session Offered"
                            : null}
                          {singleSession.session_type === 4
                            ? "1-1 and Group Session Offered"
                            : null}
                          {singleSession.session_type === 5
                            ? "Live Event"
                            : null}
                        </span>
                      </small>
                    </div>

                    <div className="info-row">
                      <span>Meeting Link</span>
                      {singleSession.meeting_id ? (
                        <a
                          href={`${CHIME_CLIENT_URL}join/${singleSession.meeting_id}?token=${token}`}
                          target="_blank"
                        >
                          <strong className="btn btn-primary">
                            {singleSession.session_type === 5
                              ? "Attend Event"
                              : "Attend Session"}
                          </strong>
                        </a>
                      ) : (
                        <>
                          {moment
                            .duration(
                              now.diff(
                                moment
                                  .unix(singleSession.start_at)
                                  .tz(timeZone)
                                  .format("YYYY-MM-DD HH:mm:ss")
                              )
                            )
                            .asMinutes() > 5 ? (
                            <>
                              <strong>
                                <span className="orange-text">
                                  You've missed the window to generate link
                                </span>
                              </strong>
                            </>
                          ) : (
                            <span className="orange-text">
                              Please check back at least 5 min before the start
                            </span>
                          )}
                        </>
                      )}
                    </div>

                    <div className="info-row">
                      <span>Timezone</span>
                      <strong>{moment.tz(timeZone).format("z")}</strong>
                    </div>
                    <div className="info-row">
                      <span>Session Date/Time</span>
                      <strong>
                        {moment(singleSession.start_at).format("MMM Do, YYYY")}{" "}
                        | {moment(singleSession.start_at).format("hh:mm a")}{" "}
                        {singleSession.end_at
                          ? -" " +
                            moment(singleSession.end_at).format("hh:mm a")
                          : null}
                      </strong>
                    </div>

                    <div className="info-row">
                      <span>Mentor Name</span>
                      <strong>{singleSession.mentor_name}</strong>
                    </div>
                    <div className="info-row">
                      <span>Interaction</span>
                      <strong>
                        {singleSession.interaction_number &&
                        (singleSession.session_type !== 1 ||
                          singleSession.session_type !== 5)
                          ? singleSession.interaction_number
                          : "N/A"}
                      </strong>
                    </div>
                  </div>
                  <div className="flex-edit-container" style={{ height: 65 }}>
                    {singleSession.course_status !=
                    BOOKED_SESSION_STATUS.BOOKED ? (
                      <span>Cancelled</span>
                    ) : (
                      <>
                        {singleSession.check_session_cancelation_start_at >
                        unixTimeStamp ? (
                          <button
                            className="btn bg-primary btn-sm"
                            onClick={(e) =>
                              onCancelClick(e, singleSession.course_detail_id)
                            }
                          >
                            Cancel
                          </button>
                        ) : (
                          <span className="disabled-reschedule-btn">
                            Cancel
                          </span>
                        )}

                        {singleSession.can_be_canceled ? (
                          <>
                            <button
                              className="btn bg-secondary btn-sm"
                              onClick={(e) =>
                                onRescheduleClick(
                                  e,
                                  singleSession.course_detail_id,
                                  singleSession.course_id,
                                  singleSession.session_type,
                                  singleSession.session_type ==
                                    SESSION_TYPE.GENERIC
                                    ? singleSession.session_timing
                                    : ""
                                )
                              }
                            >
                              Reschedule →
                            </button>
                          </>
                        ) : (
                          <>
                            {/* <span className="disabled-cancel-btn">Cancel</span> */}
                            <span className="disabled-reschedule-btn">
                              Reschedule →
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {/* Upcoming Card end   /*/}
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-md-12">
              <Pagination
                totalRecords={totalRecords}
                recordPerPage={RECORD_PER_PAGE}
                paginate={paginate}
                currentPage={page}
              />
            </div>
          </div>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Reschedule</ModalHeader>
            <ModalBody>
              {modal && (
                <form id="indiviual-form" onSubmit={(e) => onScheduleClick(e)}>
                  {isCourseFull ? (
                    <div> No Sessions Available </div>
                  ) : (
                    <>
                      {/* Specific course page */}
                      {sessionType != SESSION_TYPE.GENERIC && (
                        <SpecificDetailPage
                          courseDetail={courseDetail}
                          user={user}
                          setSendSessionData={setSendSessionData}
                          setResetData={setResetData}
                          resetData={resetData}
                          setIsIndividualExist={setIsIndividualExist}
                          setIsGroupExist={setIsGroupExist}
                          isIndividualExist={isIndividualExist}
                          isGroupExist={isGroupExist}
                          setErrorSuccess={setErrorSuccess}
                          sessionType={sessionType}
                        />
                      )}
                      {/* One to One page */}
                      {sessionType === SESSION_TYPE.GENERIC && (
                        <AskMeAnythingDetailPage
                          courseDetail={courseDetail}
                          user={user}
                          // history={history}
                          genericSessionTiming={genericSessionTiming}
                          setResetData={setResetData}
                          resetData={resetData}
                          setSendSessionData={setSendSessionData}
                          setIsGenericExist={setIsGenericExist}
                          isGenericExist={isGenericExist}
                        />
                      )}
                      {((sessionType === SESSION_TYPE.SPECIFIC_INDIVIDUAL &&
                        isIndividualExist) ||
                        (sessionType === SESSION_TYPE.SPECIFIC_GROUP &&
                          isGroupExist) ||
                        (sessionType === SESSION_TYPE.GENERIC &&
                          isGenericExist)) && (
                        <>
                          {errorSuccess.isError === true && (
                            <FormText
                              className={`invalid-feedback-text error ${
                                errorSuccess.isError && "error text-danger"
                              }`}
                            >
                              {errorSuccess.msg}
                            </FormText>
                          )}

                          <br />
                          {scheduleLoader === true ? (
                            <Spinner />
                          ) : (
                            <button
                              type="submit"
                              className="btn bg-secondary d-block btn-lg"
                            >
                              Re-Schedule →
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </form>
              )}
            </ModalBody>
            <ModalFooter>
              {/* {modeldata.can_be_cancelled && (
            <button
              className="btn bg-primary btn-sm"
              onClick={(e) => onCancelClick()}
            >
              Cancel
            </button>
          )}
          <Button color="secondary" className="m-1" onClick={toggle}>
            Close
          </Button> */}
            </ModalFooter>
          </Modal>
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
});

export default connect(mapStateToProps, {
  getMycourseSessions,
  cancelCourseScheduled,
  rescheduleSession,
  getCourseDetailById,
})(UpcomingSession);
