import { ReactComponent as GridIcon } from "assets/images/icons/grid.svg";
import { ReactComponent as CalendarIcon } from "assets/images/icons/calendar.svg";
import { ReactComponent as PresentationIcon } from "assets/images/icons/presentation-chart.svg";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import { ReactComponent as CreditCardUpIcon } from "assets/images/icons/credit-card-up.svg";
import { ReactComponent as CreditCardIcon } from "assets/images/icons/credit-card.svg";
import { ReactComponent as ManageIcon } from "assets/images/icons/cog.svg";

import { isUserHost } from "./isUserHost";

export default function getMenuItems(user) {
  return isUserHost(user) ? sidebarItemsHost : sidebarItemsStudent;
}

export function getBottomMenuItems() {
  return sidebarBottomItems
}

const sidebarItemsHost = [
  {
    icon: <GridIcon />,
    label: "Dashboard",
    href: "/dashboard",
  },
  {
    icon: <CalendarIcon />,
    label: "My Calendar",
    href: "/dashboard/calendar",
  },
  {
    icon: <PresentationIcon />,
    label: "My Events & Sessions",
    href: "/dashboard/events",
  },
  {
    icon: <EditIcon />,
    label: "My Blogs",
    href: "/dashboard/blogs",
  },
  {
    icon: <CreditCardUpIcon />,
    label: "Get Paid",
    href: "/dashboard/get-paid",
  },
];

const sidebarItemsStudent = [
  {
    icon: <GridIcon />,
    label: "Dashboard",
    href: "/dashboard",
  },
  {
    icon: <CalendarIcon />,
    label: "My Calendar",
    href: "/dashboard/calendar",
  },
  {
    icon: <PresentationIcon />,
    label: "My Learning Journey",
    href: "/dashboard/journey",
  },
  {
    icon: <CreditCardIcon />,
    label: "My Balance",
    href: "/dashboard/balance",
  },
];

const sidebarBottomItems = [
  {
    icon: <ManageIcon />,
    label: "Manage My Account",
    href: "/profile",
  }
]