import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect,useState } from "react";
import PaymentStatus from "./PaymentStatus";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function PaymentComplete() {
  const [courseId,setCourseId] = useState("")
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const courseId = urlParams.get("course_id");
    setCourseId(courseId)
    console.log("Course ID:", courseId);
  }, []);
  return (
    <>
      <Elements stripe={stripePromise}>
        <PaymentStatus course_id={courseId}/>
      </Elements>
    </>
  );
}

export default PaymentComplete;
