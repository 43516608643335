import cronParser from "cron-parser";
import axios from "utils/axios";
import {
  EDUCATOR_COURSE_LOADING,
  EDUCATOR_COURSE_CREATED,
  EDUCATOR_COURSE_CREATE_ERROR,
  EDUCATOR_COURSE_FAIL,
  EDUCATOR_COURSE_EDITED,
  EDUCATOR_COURSE_EDIT_ERROR,
  MEETING_LINK_GENERATED,
} from "actions/types";

export const createCourse =
  (
    course_Information,
    session_information,
    typeOfSession,
    history,
    redirect = true,
    startNow = false
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EDUCATOR_COURSE_LOADING,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let param = new window.FormData();
      if (course_Information.selectedFile)
        param.append(
          "file",
          course_Information.selectedFile,
          course_Information.selectedFile.name
        );
      param.append("category_id", course_Information.category_id);
      param.append("name", course_Information.name);
      param.append("image", course_Information.image);
      param.append("overview", course_Information.overview);
      param.append("ideal_for", course_Information.ideal_for);
      param.append("course_information", course_Information.course_information);
      param.append("cohosts", JSON.stringify(course_Information.coHosts));
      param.append("category_type", course_Information.category_type);
      param.append("is_individual", course_Information.is_individual);
      param.append("is_group", course_Information.is_group);
      param.append("proficiency_level", course_Information.proficiencyLevel);
      param.append("typeOfSession", typeOfSession);
      param.append(
        "individual_session_information",
        JSON.stringify({ ...session_information.specific_individual })
      );
      param.append(
        "group_session_information",
        JSON.stringify({ ...session_information.specific_group })
      );

      param.append(
        "generic_session_information",
        JSON.stringify({ ...session_information.generic })
      );

      param.append(
        "live_event_information",
        JSON.stringify({ ...session_information.live_event })
      );

      if (startNow) {
        param.append("startNow", true);
      }

      const res = await axios.post(
        "/api/educator/course/create-course",
        param,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: EDUCATOR_COURSE_CREATED,
          payload: {
            message: res.data.message,
            alertType: "success",
            data: res.data.response,
          },
        });
        if (redirect) {
          history?.push("/dashboard");
        }
        return { status: true, data: res.data.response };
      } else {
        const errors = res.data.errors.sendError
          ? res.data.errors.sendError
          : [];
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }

        dispatch({
          type: EDUCATOR_COURSE_CREATE_ERROR,
          payload: {
            message: res.data.message,
            alertType: "danger",
            errorsList,
          },
        });
        return {
          status: false,
          isErrorInBasic: res.data.errors.isErrorInBasic,
          errors: res.data?.errors?.sendError,
          msg: res.data.message,
        };
      }
    } catch (err) {
      dispatch({
        type: EDUCATOR_COURSE_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : "Something went wrong.",
          alertType: "danger",
          error: err,
        },
      });
      return {
        status: false,
        isErrorInBasic: true,
        msg:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
      };
    }
  };
export const createDraftCourse =
  (
    course_Information,
    session_information,
    typeOfSession,
    history,
    activeTab = 1
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EDUCATOR_COURSE_LOADING,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let param = new window.FormData();
      param.append("file", course_Information.selectedFile);
      param.append("category_id", course_Information.category_id);
      param.append("sub_category_id", course_Information.sub_category_id);
      param.append("name", course_Information.name);
      param.append("image", course_Information.image);
      param.append("overview", course_Information.overview);
      param.append("ideal_for", course_Information.ideal_for);
      param.append("course_information", course_Information.course_information);
      param.append("cohosts", JSON.stringify(course_Information.coHosts));
      param.append("category_type", course_Information.category_type);
      param.append("is_individual", course_Information.is_individual);
      param.append("is_group", course_Information.is_group);
      param.append("typeOfSession", typeOfSession);
      param.append("activeTab", activeTab);
      param.append(
        "individual_session_information",
        JSON.stringify({ ...session_information.specific_individual })
      );
      param.append(
        "group_session_information",
        JSON.stringify({ ...session_information.specific_group })
      );

      param.append(
        "generic_session_information",
        JSON.stringify({ ...session_information.generic })
      );

      const res = await axios.post(
        "/api/educator/course/create-draft-course",
        param,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: EDUCATOR_COURSE_CREATED,
          payload: {
            message: res.data.message,
            alertType: "success",
            data: res.data.response,
          },
        });
        history?.push("/dashboard");
        return { status: true };
      } else {
        const errors = res.data.errors.sendError
          ? res.data.errors.sendError
          : [];
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }

        dispatch({
          type: EDUCATOR_COURSE_CREATE_ERROR,
          payload: {
            message: res.data.message,
            alertType: "danger",
            errorsList,
          },
        });
        return {
          status: false,
          isErrorInBasic: res.data.errors.isErrorInBasic,
          msg: res.data.message,
        };
      }
    } catch (err) {
      dispatch({
        type: EDUCATOR_COURSE_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : "Something went wrong.",
          alertType: "danger",
          error: err,
        },
      });
      return {
        status: false,
        isErrorInBasic: true,
        msg:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
      };
    }
  };

export const generateMeetingLink =
  (recurring_id, course_id,timeZone,session_data,course_type) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `/api/front/courses/generate-meeting-Link/${recurring_id}/${course_id}`,
        {
          session_data:session_data,
          timeZone:timeZone,
          course_type:course_type
        },
        config,
      );

      if (res.status) {
        dispatch({
          type: MEETING_LINK_GENERATED,
        });
        return { status: true, meetingId: res.data.response.meetingId };
      }
    } catch (err) {
      return {
        status: false,
        msg: "Something went wrong.",
      };
    }
  };

export const editCourse =
  (
    course_id,
    course_Information,
    session_information,
    typeOfSession,
    history,
    redirect = true,
    startNow = false
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EDUCATOR_COURSE_LOADING,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let param = new window.FormData();
      param.append("file", course_Information.selectedFile);
      param.append("category_id", course_Information.category_id);
      param.append("sub_category_id", course_Information.sub_category_id);
      param.append("name", course_Information.name);
      param.append("oldImage", course_Information.image);
      param.append("image", course_Information.image);
      param.append("proficiency_level", course_Information.proficiencyLevel);
      param.append("isNewFileSelected", course_Information.isNewFileSelected);
      param.append("overview", course_Information.overview);
      param.append("ideal_for", course_Information.ideal_for);
      param.append("course_information", course_Information.course_information);
      param.append("cohosts", JSON.stringify(course_Information.coHosts));
      param.append("category_type", course_Information.category_type);
      param.append("is_individual", course_Information.is_individual);
      param.append("is_group", course_Information.is_group);
      param.append("typeOfSession", typeOfSession);
      param.append(
        "individual_session_information",
        JSON.stringify({ ...session_information.specific_individual })
      );
      param.append(
        "group_session_information",
        JSON.stringify({ ...session_information.specific_group })
      );

      param.append(
        "generic_session_information",
        JSON.stringify({ ...session_information.generic })
      );

      param.append(
        "live_event_information",
        JSON.stringify({ ...session_information.live_event })
      );

      if (startNow) {
        param.append("startNow", true);
      }

      const res = await axios.post(
        `/api/educator/course/${course_id}`,
        param,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: EDUCATOR_COURSE_EDITED,
          payload: {
            message: res.data.message,
            alertType: "success",
            data: res.data.response,
          },
        });
        if (redirect) {
          history?.push("/dashboard");
        }
        return { status: true, data: res.data.response };
      } else {
        const errors = res.data.errors.sendError
          ? res.data.errors.sendError
          : [];
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }

        dispatch({
          type: EDUCATOR_COURSE_EDIT_ERROR,
          payload: {
            message: res.data.message,
            alertType: "danger",
            errorsList,
          },
        });
        return {
          status: false,
          isErrorInBasic: res.data.errors.isErrorInBasic,
          msg: res.data.message,
          errors: res.data?.errors?.sendError,
        };
      }
    } catch (err) {
      dispatch({
        type: EDUCATOR_COURSE_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : "Something went wrong.",
          alertType: "danger",
          error: err,
        },
      });
      return {
        status: false,
        isErrorInBasic: true,
        msg:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
      };
    }
  };
export const editDraftCourse =
  (
    course_id,
    course_Information,
    session_information,
    typeOfSession,
    history,
    activeTab = 1
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EDUCATOR_COURSE_LOADING,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let param = new window.FormData();
      param.append("file", course_Information.selectedFile);
      // param.append("category_id", course_Information.category_id);
      // param.append("sub_category_id", course_Information.sub_category_id);
      param.append("name", course_Information.name);
      param.append("oldImage", course_Information.oldImage);
      param.append("image", course_Information.image);
      param.append("isNewFileSelected", course_Information.isNewFileSelected);
      param.append("overview", course_Information.overview);
      param.append("ideal_for", course_Information.ideal_for);
      param.append("course_information", course_Information.course_information);
      // param.append("category_type", course_Information.category_type);
      param.append("is_individual", course_Information.is_individual);
      param.append("is_group", course_Information.is_group);
      param.append("typeOfSession", typeOfSession);
      param.append(
        "individual_session_information",
        JSON.stringify({ ...session_information.specific_individual })
      );
      param.append(
        "group_session_information",
        JSON.stringify({ ...session_information.specific_group })
      );

      param.append(
        "generic_session_information",
        JSON.stringify({ ...session_information.generic })
      );
      param.append("active_tab", activeTab);
      const res = await axios.post(
        `/api/educator/course/draft/${course_id}`,
        param,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: EDUCATOR_COURSE_EDITED,
          payload: {
            message: res.data.message,
            alertType: "success",
            data: res.data.response,
          },
        });
        history?.push("/dashboard");
        return { status: true };
      } else {
        const errors = res.data.errors.sendError
          ? res.data.errors.sendError
          : [];
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }

        dispatch({
          type: EDUCATOR_COURSE_EDIT_ERROR,
          payload: {
            message: res.data.message,
            alertType: "danger",
            errorsList,
          },
        });
        return {
          status: false,
          isErrorInBasic: res.data.errors.isErrorInBasic,
          msg: res.data.message,
        };
      }
    } catch (err) {
      dispatch({
        type: EDUCATOR_COURSE_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : "Something went wrong.",
          alertType: "danger",
          error: err,
        },
      });
      return {
        status: false,
        isErrorInBasic: true,
        msg:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
      };
    }
  };
// export const editCourse = (
//   course_Information,
//   session_information,
//   typeOfSession,
//   history
// ) => async (dispatch) => {
//   try {
//     dispatch({
//       type: EDUCATOR_COURSE_LOADING,
//       payload: true,
//     });
//     const config = {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     };

//     let param = new window.FormData();
//     param.append("file", course_Information.selectedFile);
//     param.append("category_id", course_Information.category_id);
//     param.append("sub_category_id", course_Information.sub_category_id);
//     param.append("name", course_Information.name);
//     param.append("image", course_Information.image);
//     param.append("overview", course_Information.overview);
//     param.append("ideal_for", course_Information.ideal_for);
//     param.append("course_information", course_Information.course_information);
//     param.append("category_type", course_Information.category_type);
//     param.append("is_individual", course_Information.is_individual);
//     param.append("is_group", course_Information.is_group);
//     param.append("typeOfSession", typeOfSession);
//     param.append(
//       "individual_session_information",
//       JSON.stringify({ ...session_information.specific_individual })
//     );
//     param.append(
//       "group_session_information",
//       JSON.stringify({ ...session_information.specific_group })
//     );

//     param.append(
//       "generic_session_information",
//       JSON.stringify({ ...session_information.generic })
//     );

//     const res = await axios.post(
//       "/api/educator/course/create-course",
//       param,
//       config
//     );
//     if (res.data.status === true) {
//       dispatch({
//         type: EDUCATOR_COURSE_EDITED,
//         payload: {
//           message: res.data.message,
//           alertType: "success",
//           data: res.data.response,
//         },
//       });
//       history.push("/");
//       return true;
//     } else {
//       const errors = res.data.errors.sendError ? res.data.errors.sendError : [];
//       let errorsList = [];
//       if (errors) {
//         await errors.forEach((error) => {
//           errorsList[error.param] = error.msg;
//         });
//       }

//       dispatch({
//         type: EDUCATOR_COURSE_EDITED_ERROR,
//         payload: {
//           message: res.data.message,
//           alertType: "danger",
//           errorsList,
//         },
//       });
//       return {
//         status: false,
//         isErrorInBasic: res.data.errors.isErrorInBasic,
//       };
//     }
//   } catch (err) {
//     dispatch({
//       type: EDUCATOR_COURSE_FAIL,
//       payload: {
//         message:
//           err.response && err.response.statusText
//             ? err.response.statusText
//             : "Something went wrong.",
//         alertType: "danger",
//         error: err,
//       },
//     });
//     return {
//       status: false,
//       isErrorInBasic: true,
//     };
//   }
// };

export const changeStatus = (course_id, status) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/educator/course/change-status/${course_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      return { status: true, value: status, msg: res.data.message };
    } else {
      return { status: false, msg: res.data.message };
    }
  } catch (err) {
    return {
      status: false,
      msg: "Unable to change the status, please try again later.",
    };
  }
};

export const deleteCourse = async (course_id) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.delete(`/api/educator/course/${course_id}`, config);
    if (res.data.status === true) {
      return { status: true };
    } else {
      return { status: false, msg: res.data.message };
    }
  } catch (err) {
    return {
      status: false,
      msg: "Unable to delete the course, please try again later.",
    };
  }
};

// get Course by id
export const getCourseById = (course_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/educator/course/${course_id}`, config);
    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      return { status: false };
    }
  } catch (err) {
    return { status: false };
  }
};

export const getDraftCourseById = (course_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/educator/course/draft/${course_id}`,
      config
    );
    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      return { status: false };
    }
  } catch (err) {
    return { status: false };
  }
};

export const deleteDraftCourse = (course_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.delete(
      `/api/educator/course/draft/${course_id}`,
      config
    );
    if (res.data.status === true) {
      return { status: true };
    } else {
      return { status: false, msg: res.data.message };
    }
  } catch (err) {
    return {
      status: false,
      msg: "Unable to delete the course, please try again later.",
    };
  }
};

export const getUpcomingSessions = async (
  sessionParams,
  activeResultsFor = ""
) => {
  try {
    const custom_filters = sessionParams.custom_filters
      ? sessionParams.custom_filters
      : "&from_date=&to_date";

    const res = await axios.get(
      `/api/front/courses/upcoming-sessions?limit=${sessionParams.limit}&page=${sessionParams.page}&orderBy=${sessionParams.orderBy}&ascending=${sessionParams.ascending}&query=${sessionParams.query}${custom_filters}&activeResultsFor=${activeResultsFor}&sortBy=${sessionParams.sortBy}`
    );
    return { status: true, data: res.data.response };
  } catch (error) {
    return { status: false };
  }
};

//get All sessions
export const getAllSessions =
  (sessionParams, activeResultsFor = "") =>
  async (dispatch) => {
    try {
      const custom_filters = sessionParams.custom_filters
        ? sessionParams.custom_filters
        : "&from_date=&to_date";

      const res = await axios.get(
        `/api/front/courses/getallsessions?limit=${sessionParams.limit}&page=${sessionParams.page}&orderBy=${sessionParams.orderBy}&ascending=${sessionParams.ascending}&query=${sessionParams.query}${custom_filters}&activeResultsFor=${activeResultsFor}`
      );
      return { status: true, data: res.data.response };
    } catch (error) {
      return { status: false };
    }
  };

export const getRecommendedTimes = async (dateCount = 5) => {
  try {
    const res = await axios.get(`/api/educator/course/recommended-times`);

    const data = res.data.response;
    const dates = data
      .map((rec) =>
        cronParser.parseExpression(
          rec.cron.slice(0, -2), //
          { iterator: true, tz: rec.timezone }
        )
      )
      .map((interval) =>
        [...Array(5).keys()]
          .map(() => interval.next())
          .map((item) => item.value.toDate())
      )
      .reduce((arr1, arr2) => arr1.concat(arr2));
    return { status: true, data: dates };
  } catch (ex) {
    return { status: false };
  }
};

export const getExpertiseList = async (dateCount = 5) => {
  try {
    const res = await axios.get(`/api/educator/course/expertise-list`);

    const data = res.data.response;
    return { status: true, data: data };
  } catch (ex) {
    return { status: false };
  }
};

export const updatePaymentMethod = async (formData) => {
  try {
    const res = await axios.post(
      `/api/myaccount/update-payment-method`,
      formData
    );
    const data = res.data.response;
    return { status: true, data: data };
  } catch (ex) {
    return { status: false };
  }
};

// //get All upcoming booked sessions
// export const getAllUpcomingSessions = (
//   sessionParams,
//   activeResultsFor = ""
// ) => async (dispatch) => {
//   try {
//     const custom_filters = sessionParams.custom_filters
//       ? sessionParams.custom_filters
//       : "&from_date=&to_date";

//     const res = await axios.get(
//       `/api/front/courses/getupcomingsessions?limit=${sessionParams.limit}&page=${sessionParams.page}&orderBy=${sessionParams.orderBy}&ascending=${sessionParams.ascending}&query=${sessionParams.query}${custom_filters}&activeResultsFor=${activeResultsFor}`
//     );
//     return { status: true, data: res.data.response };
//   } catch (error) {
//     return { status: false };
//   }
// };
