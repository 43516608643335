import {
  FRONT_COURSE_RATING_FAIL,
  FRONT_COURSE_RATING_CREATED,
  FRONT_COURSE_RATING_CREATE_ERROR,
  FRONT_COURSE_RATING_LOADER,
} from "actions/types";

const initalState = {
  ratingloading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONT_COURSE_RATING_LOADER:
      return {
        ...state,

        ratingloading: payload,
      };
    case FRONT_COURSE_RATING_CREATED:
      return {
        ...state,
        ratingloading: false,
      };
    case FRONT_COURSE_RATING_CREATE_ERROR:
      return {
        ...state,
        ratingloading: false,
      };
    case FRONT_COURSE_RATING_FAIL:
      return {
        ...state,
        ratingloading: false,
      };

    default:
      return state;
  }
}
