import React from "react";
import Errors from "views/Notifications/Errors";

const SessionDateTime = ({
  singleSession,
  index,
  onGenericChange,
  session_group,
  INPUT_TYPE,
  current_date,
  isConfirmation,
  handleSessionRemove,
  is_editable = true,
  is_recurring_editable = true,
  isForEdit,
}) => {
  return (
    <>
      <div
        className={`row ${
          (!is_editable || !is_recurring_editable) && "disabled-session"
        }`}
        key={index}
      >
        {/*********** single session start date**************/}

        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="start_date">
              Start Date<span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              name="start_date"
              onChange={(e) => onGenericChange(e, INPUT_TYPE.SESSION, index)}
              value={singleSession.start_date}
              min={
                isForEdit && !singleSession.is_new
                  ? singleSession.min_start_date
                  : current_date
              }
              disabled={isConfirmation}
            />
            {/* {!isConfirmation && (
            <Errors
              current_key={`generic_start_date_${index}`}
              key={`generic_start_date_${index}`}
            />
          )} */}
          </div>
        </div>
        {/*********** single session end date**************/}
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="end_date">
              End Date <span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              name="end_date"
              onChange={(e) => onGenericChange(e, INPUT_TYPE.SESSION, index)}
              disabled={singleSession.start_date ? false : true}
              value={singleSession.end_date}
              min={singleSession.min_end_date}
              max={singleSession.max_end_date}
              // TODO: check
              // disabled={isConfirmation}
            />
            {!isConfirmation && (
              <Errors
                current_key={`generic_end_date_${index}`}
                key={`generic_end_date_${index}`}
              />
            )}
          </div>
        </div>
        {/*********** single session start time**************/}

        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="start_time">
              Start Time <span className="text-danger">*</span>
            </label>
            {/* 
            <TimePicker
              name="start_time"
              onTimeChange={(e) =>
                onGenericChange(e, INPUT_TYPE.SESSION, index, "start_time")
              }
              theme="classic"
              timeConfig={{
                from: 0,
                to: "23:45",
                step: 15,
                unit: "minutes",
              }}
              className="form-control"
              time={singleSession.start_time}
              disabled={isConfirmation}
            />

            {!isConfirmation && (
              <Errors
                current_key={`generic_start_time_${index}`}
                key={`generic_start_time_${index}`}
              />
            )}
            */}
          </div>
        </div>

        {/*********** single session end time**************/}
        <div
          className={`col-md-3 ${
            session_group.length > 1 && !isConfirmation && "space-between"
          }`}
        >
          <div className="form-group w-75">
            <label htmlFor="end_time">
              End Time <span className="text-danger">*</span>
            </label>
            {/* 
            <TimePicker
              name="end_time"
              onTimeChange={(e) =>
                onGenericChange(e, INPUT_TYPE.SESSION, index, "end_time")
              }
              theme="classic"
              timeConfig={{
                from: "00:15",
                to: "00:00",
                step: 15,
                unit: "minutes",
              }}
              className="form-control"
              time={singleSession.end_time}
              disabled={isConfirmation}
              minTime={singleSession.start_time}
            />

            {!isConfirmation && (
              <Errors
                current_key={`generic_end_time_${index}`}
                key={`generic_end_time_${index}`}
              />
            )}
            */}
          </div>
          {/*********** delete session**************/}
          {session_group.length > 1 && !isConfirmation && (
            <div className="form-group w-25 text-center m-0">
              <button
                className="remove-session"
                onClick={(e) => handleSessionRemove(e, index)}
              >
                <i className="fa fa-minus" />
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        className={`row ${
          (!is_editable || !is_recurring_editable) && "disabled-session"
        }`}
        key={index}
        style={{
          paddingLeft: 14,
        }}
      >
        {!isConfirmation && (
          <Errors
            current_key={`generic_start_date_${index}`}
            key={`generic_start_date_${index}`}
          />
        )}
      </div>
    </>
  );
};

export default SessionDateTime;
