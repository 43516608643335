/**
 * String Fold Hash Function
 * @param {String} s
 */
const sFold = (s) => {
  let sum = 0,
    mul = 1;
  for (let i = 0; i < s.length; i++) {
    mul = i % 4 === 0 ? 1 : mul * 256;
    sum += s.charCodeAt(i) * mul;
  }
  return sum.toString();
};
export const sfold = sFold;

/**
 * Remove Script or Style from header
 * based on their data-id attribute
 *
 * @param {String} pack package name
 * @param {String} type type of package
 */
export const removeScripts = (pack, type) => {
  const seed = sFold(pack);
  switch (type) {
    case "style":
      const link = document.querySelectorAll(`[data-id="${seed}"]`);
      for (let style of link) {
        if (style) {
          style.parentNode.removeChild(style);
        }
      }
      break;
    case "script":
      const js = document.querySelectorAll(`[data-id="${seed}"]`);
      for (let script of js) {
        if (script) {
          script.parentNode.removeChild(script);
        }
      }
      break;
  }
};

/**
 * Check if script/style is added or not.
 * @param {String} pack name of the package
 * @param {String} type type of the script
 */
export const checkScripts = (pack, type) => {
  const seed = sFold(pack);
  switch (type) {
    case "style":
      const link = document.querySelectorAll(`[data-id="${seed}"]`);
      return !!link.length;
    case "script":
      const js = document.querySelectorAll(`[data-id="${seed}"]`);
      return !!js.length;
  }
};

export const filterString = (text) => {
    let regex = /(<([^>]+)>)/ig;
    let text1 = text.replace(regex, '');
    let text2 = text1.replace(/&nbsp;/g,' ');
  return text2;
};
