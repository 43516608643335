import {
    currentUser,
    getConfigurations, update, uploadProfileImage
} from "actions/front/myaccount";
import { REMOVE_ERRORS } from "actions/types";
import CenterContainer from "components/common/Container/Center";
import Button from "components/common/form/Button";
import ProfilePicInput from "components/common/form/ProfilePicInput";
import SelectInput from "components/common/form/SelectInput";
import TextInput from "components/common/form/TextInput";
import countries from "constants/countries";
import { Field, Form, Formik, useFormikContext } from "formik";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Spinner } from "reactstrap";
import Swal from 'sweetalert2';
import CitySearchInput from "utils/CitySearchInput";
import getUserAvatar from "utils/getUserAvatar";
import {studentManageMyAccountSchema} from "utils/schemas/manageMyAccount";
import IconCircle from "../HomePage/IconCircle";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { languagesExtended } from "../Utils/LanguageSelector";
  
  
  const StudentMyAccount = ({
    getConfigurations,
    update,
    uploadProfileImage,
    currentUser,
    user,
    location,
    errorList,
    isAuthenticated,
    authLoading,
    onClosed
  }) => {
    /**************** dispatch to remove error *********************/
    const dispatch = useDispatch();
    const history = useHistory();
  
    /**************** remove errors when first loaded *********************/
    useMemo(() => {
      dispatch({ type: REMOVE_ERRORS });
    }, []);
  
    /********************Form data*****************/
  
    const [initialFormData, setInitialFormData] = useState({
      email: "",
      first_name: "",
      last_name: "",
      description: "",
      languages: [],
      avatar: "",
      expertise: []
    });
    const [formData, setFormData] = useState(initialFormData);
    const [selectedAreaOfIntrest, setSelectedAreaOfIntrest] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);    
    const [file, setFile] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [formError, setFormError] = useState(null);
    const {
      email,
      first_name,
      last_name,
      description,
      languages,
      avatar,
      expertise
    } = formData;
  
    const [addressFormData, setaddressFormData] = useState({
      city: "",
      country: "",
      state: "",
    });
    const { city, country, state } = addressFormData;
  
    /********************get logged in user detail and configrations data*****************/
    useEffect(() => {
      currentUser();
      getConfigurations().then((data) => {
        var config_obj = [];
        data.response.map((configuration, i) => {
          config_obj[configuration.config_key] = configuration.config_value;
        });
      });
    }, []);
  
    /********************set user data to local state*****************/
    useMemo(() => {
      setLoading(true)
      if (user != null) {
        let languageObj = [];
        user.languages?.map((value) =>
        languageObj.push({
              value: value,
              label: value,
            })
          )
        let updatedData = {
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          username: user.username,
          avatar: user.avatar,
          description:
            typeof user === "undefined"
              ? ""
              : user.description ?? "",
          timezone: user.timezone,
          languages: languageObj,
          expertise: user.expertise
        };
        setInitialFormData(updatedData);
        setFormData(updatedData);
        setaddressFormData({
          city: user.city,
          state: user.state,
          country: user.country,
        });
      }
      setLoading(false)
    }, [user]);
  
    // useMemo(() => {
    //   setFormData(initialFormData);
    // }, [initialFormData]);
  

    const setGMapPlace = (location) => {
      const addresses = location.address_components;
      let city = "";
      let state = "";
      let country = "";
  
      addresses.forEach((e) => {
        switch (e.types[0]) {
          case "locality":
          case "sublocality_level_1":
          case "postal_town":
            city = e.long_name;
            break;
          case "administrative_area_level_1":
            state = e.long_name;
            break;
          case "country":
            country = e.long_name;
            break;
        }
      });
      setaddressFormData({
        ...addressFormData,
        city,
        state,
        country,
      });
    };
  
    const pageOptions = [
      {label: "Edit My Profile", value: "edit"},
      {label:"Account Settings", value: "settings"},
      {label: "Become A Host", value: "becomeAHost"}
    ]

    const selectedOptions = (selected) => {
      if (selected.value == "settings")
        history.push("/settings");
    if (selected.value == "becomeAHost")
        history.push("/account/become-a-host");
    }
  
    const formikCtx = useFormikContext();
  
  
    const { cropperProps, show } = useImageCropper({
      src: file && URL.createObjectURL(file),
      onComplete: setFile,
      aspect: 2 / 1,
    });
  
    useEffect(() => {
      formikCtx?.setFieldValue("image", file);
    }, [file]);
  
    const onDrop = (acceptedFiles) => {
      setFormData({...formData, avatar: acceptedFiles[0]})
      setFile(acceptedFiles[0]);
      show();
    };
  
    const handleChange = (data) => {
      let image_url = URL.createObjectURL(data);
      let payload = {
        ...formData,
        avatar: data,
        image: image_url,
      };
      uploadProfileImage(payload).then((res) => {
        if (res.status === false) {
          setFormError(res.data);
        } else {
          setImage({
            preview: image_url,
            raw: data,
          });
          Swal.fire("", "Image Updated", "success");
          setFormData(payload);
          setFormError(null);
        }
      });
    };
  
    /********************On Submit event*****************/
    const onProfileSubmit = (values) => {
      setIsSubmit(true);
      var mergedData = { ...formData, ...addressFormData, ...values, languages: values.languages.map(item => item.value)};
      update(mergedData).then((res) => {
        window.scrollTo(0, 0);
        if (res.status) {
          Swal.fire("", res.message, "success")
          setIsSubmit(false);
        }
        setLoading(false);
      });
    };

    const onUpdate = (avatar) => {
      let image_url = URL.createObjectURL(avatar);
      uploadProfileImage({avatar, image:image_url }).then((res) => {
        if (res.status) {
          Swal.fire("", res.message, "success")
        }
      });
    };
  
  
    return (
      <>
        <Header />
        <CenterContainer>
        {loading ? (
          <Spinner />
        ) : (
          <div className="md:text-left pt-12 pb-12 xl:px-0 md:w-full mx-auto">
            <p className="text-dgray-900 mb-24 md:mb-8 text-5xl md:text-2xl text-center md:text-left">Manage my account</p>
            <div className="flex flex-row md:flex-col gap-4">
              <div className="flex flex-col w-72 md:w-full">
                <SelectInput
                  options={pageOptions}
                  className="hidden md:block mt-4 text-sm"
                  defaultValue={pageOptions[0]}
                  onChange={selectedOptions}
                />
                <ul className="mt-4 space-y-1 md:hidden">
                  <li
                    className={`flex w-full justify-between cursor-pointer items-center rounded
                  ${location.pathname === "/profile"
                        ? "text-primary-700 bg-primary-50"
                        : "text-dgray-700 hover:text-gray-500"
                      }
                `}
                  >
                    <Link to="/profile" className="flex items-center py-2 px-3 w-full">
                      <div
                        className={
                          location.pathname === "/profile"
                            ? "text-primary-600"
                            : "text-dgray-500"
                        }
                      >
                      </div>
                      <span className="font-medium ml-2 md:ml-0">Edit My Profile</span>
                    </Link>
                  </li>
                  <li
                    className={`flex w-full justify-between cursor-pointer items-center rounded
                  ${location.pathname === "/settings"
                        ? "text-primary-700 bg-primary-50"
                        : "text-dgray-700 hover:text-gray-500"
                      }
                `}
                  >
                    <Link to="/settings" className="flex items-center py-2 px-3 w-full">
                      <div
                        className={
                          location.pathname === "/settings"
                            ? "text-primary-600"
                            : "text-dgray-500"
                        }
                      >
                      </div>
                      <span className="font-medium ml-2 md:ml-0">Account Settings</span>
                    </Link>
                  </li>
                  <li
                    className={`flex w-full justify-between cursor-pointer items-center rounded
                  ${location.pathname === "/settings"
                        ? "text-primary-700 bg-primary-50"
                        : "text-dgray-700 hover:text-gray-500"
                      }
                `}
                  >
                    <Link to="/account/become-a-host" className="flex items-center py-2 px-3 w-full">
                      <div
                        className={
                          location.pathname === "/account/become-a-host"
                            ? "text-primary-600"
                            : "text-dgray-500"
                        }
                      >
                      </div>
                      <span className="font-medium ml-2 md:ml-0">Become A Host</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="w-full px-16 md:px-0">
                <Formik
                  initialValues={{
                    first_name: first_name,
                    last_name: last_name,
                    country: country,
                    city: city,
                    description: description,
                    languages: languages,
                    avatar: avatar,
                    expertise: expertise
                  }}
                  validationSchema={studentManageMyAccountSchema}
                  onSubmit={(e) => onProfileSubmit(e)}
                  enableReinitialize
                >
                  <Form>
                    <div className="grid grid-cols-2 md:grid-cols-1 gap-5 w-full">
                      <TextInput
                        type="text"
                        name="first_name"
                        placeholder="Enter your first name"
                        label="First Name"
                        className="w-full"
                      />
                      <TextInput
                        type="text"
                        name="last_name"
                        placeholder="Enter your last name"
                        label="Last Name"
                        className="w-full"
                      />
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-1 gap-5 w-full pt-4">
                      <SelectInput
                        label="Country"
                        name="country"
                        options={countries.map((country) => ({
                          label: country.name,
                          value: country.code,
                        }))}
                        value={country}
                        className="flex-1 w-full"
                      />
                      <Field name="city">
                        {({ field, form }) => (
                          <CitySearchInput
                            label="City"
                            country={countries.find(country => country.name === form.values.country)?.code}
                            onChange={(city) =>
                              form.setFieldValue(field.name, city.name)
                            }
                            defaultValue={city}
                          />
                        )}
                      </Field>
                    </div>
                      <ProfilePicInput name="avatar" defaultSrc={getUserAvatar(avatar)} onUpdate={onUpdate} />
                    <div className="flex justify-end border-t mt-8 pt-5">
                      <div className="space-x-3">
                        <Button
                          type="button"
                          className="text-base mt-3 bg-white text-dgray-700 px-4"
                          disabled={isSubmit}
                        >Cancel</Button>
                        <Button
                          type="submit"
                          className="text-base text-white mt-3 bg-primary-600 px-4"
                          disabled={isSubmit}
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        )}
        </CenterContainer>
        <Footer />
      </>
    );
  };
  
  function useImageCropper({ src, onComplete, aspect }) {
    const [isOpen, setIsVisible] = useState(false);
    const show = () => setIsVisible(true);
    const hide = () => setIsVisible(false);
  
    return {
      show,
      cropperProps: { hide, isOpen, src, onComplete, aspect },
    };
  }
  
  const mapStateToProps = (state) => ({
    errorList: state.errors,
    profile_loading: state.myaccount.loading,
    isAuthenticated: state.auth.isAuthenticated,
    authLoading: state.auth.loading,
  });
  
  export default connect(mapStateToProps, {
    update,
    currentUser,
    getConfigurations,
    uploadProfileImage,
  })(withRouter(StudentMyAccount));
  