import React, { useState, useMemo, useEffect } from "react";
import * as moment from 'moment-timezone';
import { SESSION_TYPE } from "constants/index";
import SpecificIndividualDetailPage from "./SpecificIndividualDetailPage";
import SpecificGroupDetailPage from "./SpecificGroupDetailPage";
import sortBy from 'lodash.sortby';
import { getCurrentTimeZone } from "utils/time";

const SpecificDetailPage = ({
  courseDetail,
  user,
  setSendSessionData,
  setResetData,
  resetData,
  setIsIndividualExist,
  setIsGroupExist,
  isIndividualExist,
  isGroupExist,
  setErrorSuccess,
  sessionType,
}) => {

  let timeZone = getCurrentTimeZone(user);
  let currentDate = moment().tz(timeZone).format("YYYY-MM-DD HH:mm");

  /********************set timing slots*****************/

  const getSessions = (session_detail, type) => {
    let arrGroupedByMonth = [];
    let innerGroupedArray = [];
    let sessionToSend = "";
    let groupIds = [];
    
    // Sort Sessions Date Wise
   sortBy(session_detail, ['session_group.all_session_with_recurring[0].start_at']).map((sessions, i) => {
      if (sessions.detail_for === type) {
        sessionToSend = sessions;
        let sessionCount = sessions.session_group.sessions.length;
        let sortedSessions = sessions.session_group.all_session_with_recurring.sort(
          (a, b) => a.start_at - b.start_at
        );
        sortedSessions.map((item, i) => {
          innerGroupedArray.push({
            ...item,
            max_students: sessions.max_students,
          });
          if ((i + 1) % sessionCount === 0) {
            let chkDateTime = moment
              .unix(innerGroupedArray[0].start_at)
              .tz(timeZone)
              .format("YYYY-MM-DD HH:mm");

            if (
              ((type === SESSION_TYPE.SPECIFIC_INDIVIDUAL &&
                innerGroupedArray[0].session_booking_count === 0) ||
                (type === SESSION_TYPE.SPECIFIC_GROUP &&
                  innerGroupedArray[0].session_booking_count <
                    sessions.max_students)) &&
              chkDateTime > currentDate
            ) {
              groupIds[innerGroupedArray[0]._id] = sessions.session_group._id;
              arrGroupedByMonth.push(innerGroupedArray);
            }
            innerGroupedArray = [];
          }
        });
      }
    });
    if (type === SESSION_TYPE.SPECIFIC_INDIVIDUAL) {
      if (arrGroupedByMonth.length > 0) setIsIndividualExist(true);
      else {
        setIsIndividualExist(false);
      }
    }

    if (type === SESSION_TYPE.SPECIFIC_GROUP) {
      if (arrGroupedByMonth.length > 0) {
        setIsGroupExist(true);
      } else {
        setIsGroupExist(false);
      }
    }

    return {
      sessions: sessionToSend,
      allGroupedSessions: arrGroupedByMonth,
      groupIds,
    };
  };

  let arrGroupedByMonth = getSessions(
    courseDetail.session_detail,
    SESSION_TYPE.SPECIFIC_INDIVIDUAL
  );

  let arrGroupedByMonthGroup = getSessions(
    courseDetail.session_detail,
    SESSION_TYPE.SPECIFIC_GROUP
  );

  const [selectedSessionId, setSelectedDateWiseSessions] = useState(null);

  const showAllSessionsForSelectedDate = (
    sessionId,
    sessionGroup,
    isIndividual = false
  ) => {
    if (sessionId!= "") {
      setErrorSuccess({
        isError: false,
        cartBtn: false,
        msg: "",
      });
      let filteredData = sessionGroup.filter((bundleSession) => {
        return bundleSession[0]._id === sessionId;
      });

      setSelectedDateWiseSessions(sessionId);
      setSendSessionData({
        selectedSessions: filteredData[0],
        groupId: isIndividual
          ? arrGroupedByMonth.groupIds[sessionId]
          : arrGroupedByMonthGroup.groupIds[sessionId],
      });
    } else {
      setSelectedDateWiseSessions(null);
      setSendSessionData(null);
    }
  };

  useMemo(() => {
    if (resetData) {
      setResetData(null);
      setSelectedDateWiseSessions(null);
      setSendSessionData(null);
    }
  }, [resetData]);

  return (
    <>
      {sessionType === SESSION_TYPE.SPECIFIC_INDIVIDUAL &&
        (isIndividualExist ? (
          <div className="select-type-details">
            <h5>Individual</h5>
            <SpecificIndividualDetailPage
              courseDetail={courseDetail}
              sessions={arrGroupedByMonth.sessions}
              arrGroupedByMonth={arrGroupedByMonth.allGroupedSessions}
              selectedSessionId={selectedSessionId}
              showAllSessionsForSelectedDate={showAllSessionsForSelectedDate}
              timeZone={timeZone}
            />
          </div>
        ) : (
          <div> No Sessions Available </div>
        ))}

      {sessionType === SESSION_TYPE.SPECIFIC_GROUP &&
        (isGroupExist ? (
          <div className="select-type-details">
            <h5>Group Session</h5>
            <SpecificGroupDetailPage
              courseDetail={courseDetail}
              sessions={arrGroupedByMonthGroup.sessions}
              arrGroupedByMonthGroup={arrGroupedByMonthGroup.allGroupedSessions}
              selectedSessionId={selectedSessionId}
              showAllSessionsForSelectedDate={showAllSessionsForSelectedDate}
              timeZone={timeZone}
            />
          </div>
        ) : (
          <div> No Sessions Available </div>
        ))}
    </>
  );
};

export default SpecificDetailPage;
