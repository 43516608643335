import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const PublicRoute = ({
  component: Component,
  auth: { isAuthenticated, loading, user },
  restricted,
  forAdmin,
  name,
  isforall,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated && !loading && !restricted) {
          return <Component {...props} name={name} />;
        } else {
          if (user !== null) {
            if (user.isAdmin) {
              if (isforall) {
                return <Component {...props} name={name} />;
              } else return <Redirect to="/admin" />;
            } else {
              if (isforall) {
                return <Component {...props} name={name} />;
              } else return <Redirect to="/" />;
            }
          }

          return <Component {...props} name={name} />;
        }
      }}
    />
  );
};

PublicRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PublicRoute);
