/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
import * as moment from "moment";
import Spinner from "views/Spinner";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import {
  getCoursesList,
  changeStatus,
  deleteCourse,
  getFullCoursesList,
} from "actions/admin/courses";
import * as Constants from "constants/index";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import overlayFactory from "react-bootstrap-table2-overlay";
import CustomFilters from "./CustomFilters";
import StarRatings from "react-star-ratings";
import { CSVLink } from "react-csv";

import { Button, Card, CardBody, Col, Row, Input } from "reactstrap";

const SessionList = ({
  getCoursesList,
  changeStatus,
  courseList: { data, count, page },
  loading,
  subLoading,
  deleteCourse,
}) => {
  //################## Initillise sorting searching parameters by default values ###################
  const [userParams, setUserParams] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
  });

  const [courseList, setCourseList] = useState([]);
  const [totalRecord, setTotalRecords] = useState(0);

  //################## to get updated list ###################
  useEffect(() => {
    getCoursesList(userParams).then((res) => {
      setTotalRecords(res.metadata[0].totalRecord);
    });
  }, [userParams]);

  useMemo(() => {
    if (totalRecord > 0) {
      getFullCoursesList(totalRecord).then((res) => {
        let arr = [];
        res.data.map((item) => {
          let obj = {};
          obj.name = item.name;
          obj.parent_category_name = item.parent_category_name;
          obj.sub_category_name = item.sub_category_name;
          obj.mentor_name = item.mentor_name;
          obj.mentor_username = item.mentor_username;
          obj.created_at = moment(item.created_at).format("MM/DD/YYYY");
          arr.push(obj);
        });
        setCourseList(arr);
      });
    }
  }, [totalRecord]);

  //################## Handel table change(sorting, searching, status change) ###################
  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    setUserParams({
      ...userParams,
      page: type === "search" ? 1 : page,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      query: searchText,
    });
  };

  const CSVHeaders = [
    { label: "Name", key: "name" },
    { label: "Category Name", key: "parent_category_name" },
    { label: "Sub Category Name", key: "sub_category_name" },
    { label: "Mentor Name", key: "mentor_name" },
    { label: "Mentor UserName", key: "mentor_username" },
    { label: "Date Created", key: "created_at" },
  ];

  const DownloadXls = () => (
    <a href="#" className="download-xls">
      <CSVLink
        className="fa fa-file-excel-o text-orange "
        data={courseList}
        headers={CSVHeaders}
        filename={`Courses-List ${moment
          .unix(moment().utc().unix())
          .format("MM/DD/YYYY HH:MM:ss")}.csv`}
      >
        {" "}
        Download
      </CSVLink>
    </a>
  );

  //#########################Colums defination start ###################
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      // headerSortingStyle
    },
    {
      dataField: "parent_category_name",
      text: "Category Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "sub_category_name",
      text: "Sub Category Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "total_rating_count",
      text: "Ratings",
      sort: true,
      formatter: (cellContent, row) =>
        row.total_rating_count ? (
          <StarRatings
            rating={row.avg_rating_star}
            numberOfStars={5}
            name="rating"
            starDimension="16px"
            starSpacing="0px"
            starRatedColor="orange"
            starEmptyColor="#a9a9a9"
          />
        ) : (
          "N/A"
        ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "mentor_name",
      text: "Mentor Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "mentor_username",
      text: "Mentor UserName",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "created_at",
      text: "Date Created",
      sort: true,
      // headerSortingStyle,
      formatter: (cellContent, row) => (
        <Moment format="MM/DD/YYYY">{Number(row.created_at)}</Moment>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },

      formatter: (cell, row) => {
        return (
          <div>
            <Input
              type="select"
              name="status"
              id={row._id}
              defaultValue={cell}
              onChange={(e, a) => {
                changeStatus(row._id, e.target.value);
              }}
            >
              <option value="0">Inactive</option>
              <option value="1">Active</option>
            </Input>
          </div>
        );
      },
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: (cellContent, row) => (
        <div>
          <Link to={`/admin/courses/${row._id}`}>
            <Button type="button" size="sm" color="success">
              {/* <i className="fa fa-eye"></i> */}
              Details
            </Button>
          </Link>
          <Button
            type="button"
            size="sm"
            color="danger"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure you want to delete :\r ${row.name}?`
                )
              ) {
                deleteCourse(row._id).then((res) => {
                  if (res) getCoursesList(userParams);
                });
              }
            }}
          >
            {/* <i className="fa fa-trash"></i> */}
            Delete
          </Button>
        </div>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
  ];

  //#########################Colums defination end ###################

  //################## START Set properties(options) of datatables ###################

  // const headerSortingStyle = { backgroundColor: '#c8e6c9' };
  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li
      key={text}
      role="presentation"
      className="dropdown-item"
      onMouseDown={(e) => {
        e.preventDefault();
        onSizePerPageChange(page);
      }}
    >
      <Button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        className="sizePerPageaTag"
      >
        {text}
      </Button>
    </li>
  );

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <>
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    </>
  );

  const options = {
    page: parseInt(userParams.page),
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: count,
    // sizePerPageRenderer,
    sizePerPageOptionRenderer,
    sizePerPageList: [
      {
        text: Constants.DEFAULT_PAGE_SIZE,
        value: Constants.DEFAULT_PAGE_SIZE,
      },
      {
        text: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
        value: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
      },
      {
        text: "All",
        value: count,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  return "";
  return (
    <div className="animated fadeIn displayTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(options)}
                keyField="email"
                columns={columns}
                data={data}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="email"
                    data={data}
                    columns={columns}
                    search
                  >
                    {(toolkitprops) => (
                      <>
                        <>
                          <div className="per-page-div">
                            Record per page:
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <DownloadXls />
                          <CustomFilters {...toolkitprops.searchProps} />
                        </>
                        <BootstrapTable
                          {...toolkitprops.baseProps}
                          bootstrap4
                          remote={{
                            pagination: true,
                            filter: true,
                            sort: true,
                          }}
                          keyField="email"
                          data={data}
                          columns={columns}
                          {...paginationTableProps}
                          onTableChange={handleTableChange}
                          defaultSorted={defaultSorted}
                          noDataIndication={Constants.NO_RECORD}
                          bordered={false}
                          hover
                          loading={loading && !subLoading}
                          overlay={overlayFactory({
                            spinner: <Spinner />,
                            styles: {
                              overlay: (base) => ({
                                ...base,
                                background: "#E8E8E8",
                              }),
                            },
                          })}
                        />
                      </>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

SessionList.propTypes = {
  getCoursesList: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  courseList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  courseList: state.courses.coursesList,
  loading: state.courses.loading,
  subLoading: state.courses.isSubLoading,
});

export default connect(mapStateToProps, {
  getCoursesList,
  getFullCoursesList,
  changeStatus,
  deleteCourse,
})(withRouter(SessionList));
