export default function RadioButton({ label, description, selected, select }) {
  return (
    <div className="text-sm flex gap-2">
      <Radio selected={selected} select={select} />
      <div>
        <label
          htmlFor={label}
          className="text-dgray-700 font-medium inline-block mb-1.5 w-full"
        >
          {label}
        </label>
        <p className="text-dgray-500">{description}</p>
      </div>
    </div>
  );
}

function Radio({ selected, select }) {
  return (
    <button
      type="button"
      onClick={select}
      className={`shrink-0 h-4 w-4 p-1 rounded-full border
      ${selected ? "border-primary-600 bg-primary-50" : "bg-white"}`}
    >
      {selected && <div className="h-1.5 w-1.5 rounded-full bg-primary-600" />}
    </button>
  );
}
