import { ReactComponent as UserLargeIcon } from "assets/images/icons/user-large.svg";
import ImageDropzone from "components/common/form/ImageDropzone";
import InputError from "components/common/form/InputError";
import { useFormikContext } from "formik";
import useImageCropper from "hooks/useImageCropper";
import { useEffect, useState } from "react";
import ImageCropper from "../ImageCropper";

export default function ProfilePicInput({
  name = "profilePicture",
  defaultSrc,
  onUpdate,
}) {
  const { setFieldValue } = useFormikContext();
  const [file, setFile] = useState();
  useEffect(() => {
    if (file) setFieldValue?.(name, file);
  }, [file]);
  const { cropperProps, show } = useImageCropper({
    src: file && URL.createObjectURL(file),
    onComplete: setFile,
    aspect: 1,
  });

  return (
    <div className="mt-5 flex">
      <ImageCropper {...cropperProps} />
      <div className="mr-12">
        <div className="text-sm text-dgray-700 font-medium">
          Profile picture
        </div>
        <div className="text-sm text-dgray-500 mb-1.5">
          This will be displayed on your profile.
        </div>
        <div className="flex justify-between">
          {file ? (
            <img
              className="h-16 w-16 rounded-full"
              src={URL.createObjectURL(file)}
              alt=""
            />
          ) : (
            <>
              {defaultSrc ? (
                <img
                  className="h-16 w-16 rounded-full"
                  src={defaultSrc}
                  alt=""
                />
              ) : (
                <div className="bg-dgray-50 flex justify-center items-center h-16 w-16 rounded-full">
                  <UserLargeIcon className="text-dgray-600" />
                </div>
              )}
            </>
          )}
          <div>
            <button
              type="button"
              onClick={() => setFile(undefined)}
              disabled={!file}
              className="text-sm font-medium mx-2 my-1 text-dgray-500 disabled:text-dgray-300"
            >
              Delete
            </button>
            {onUpdate && (
              <button
                type="button"
                onClick={() => onUpdate(file)}
                disabled={!file}
                className={`text-sm font-medium mx-2 my-1 
                ${file ? "text-primary-600" : "text-dgray-300"}`}
              >
                Update
              </button>
            )}
          </div>
        </div>
        <InputError name="profilePicture" />
      </div>
      <div className="flex-1">
        <ImageDropzone
          onDrop={(files) => {
            setFile(files[0]);
            show();
          }}
          name={name}
        />
      </div>
    </div>
  );
}
