import { ReactComponent as ArrowLeft } from "assets/images/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";
import ReactPaginate from "react-paginate";

export default function Pagination({
  page,
  setPage,
  count,
  pageSize = 4,
  className = "",
}) {
  const pageCount = Math.ceil(count / pageSize);

  return (
    <div className={`flex justify-between items-center ${className}`}>
      <SideButton
        iconLeft={<ArrowLeft />}
        onClick={() => {
          if (page > 1) setPage((p) => p - 1);
        }}
      >
        Previous
      </SideButton>
      <ReactPaginate
        className="flex md:hidden"
        previousClassName="hidden"
        nextClassName="hidden"
        pageLinkClassName="rounded-full text-sm w-10 h-10 text-dgray-500 flex justify-center items-center"
        activeLinkClassName="text-dgray-800 bg-dgray-50"
        breakClassName="text-sm w-10 h-10 text-dgray-500 flex justify-center items-center"
        breakLabel="..."
        pageCount={pageCount}
        marginPagesDisplayed={3}
        pageRangeDisplayed={2}
        onPageChange={({ selected }) => setPage(selected + 1)}
        forcePage={page - 1}
      />
      <div className="hidden md:block text-sm text-dgray-500">
        Page <span className="font-medium">{page}</span> of{" "}
        <span className="font-medium">{pageCount}</span>
      </div>
      <SideButton
        iconRight={<ArrowRight />}
        onClick={() => {
          if (page < pageCount) setPage((p) => p + 1);
        }}
      >
        Next
      </SideButton>
    </div>
  );
}

function SideButton({ children, iconLeft, iconRight, onClick }) {
  return (
    <button
      className="text-sm text-dgray-500 flex items-center gap-2 md:border md:border-dgray-300 py-2 md:px-2 md:rounded-lg"
      onClick={onClick}
    >
      {iconLeft}
      <span className="md:hidden">{children}</span>
      {iconRight}
    </button>
  );
}
