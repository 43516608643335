import { ReactComponent as PlusIcon } from "assets/images/icons/plus.svg";
import Checkbox from "components/common/form/Checkbox";
import DatePicker from "components/common/form/DatePicker";
import TimePicker from "components/common/form/DatePicker/TimePicker";
import SelectInput from "components/common/form/SelectInput";
import { CreateFormRow } from "components/create-form/CreateFormRow";
import { RECURRING_TYPE_REVERSE } from "constants";
import { FieldArray, useFormikContext } from "formik";
import moment from "moment";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { getClosestQuarterTime, getTimeZone } from "utils/time";

function OneOnOneNewEventRows({ name = "events", user, disabled }) {
  const { values } = useFormikContext();
  const tz = getTimeZone(user);
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => {
        return (
          <>
            {values[name].map((event, i) => (
              <NewEventRow
                tz={tz}
                key={i}
                arrayHelpers={arrayHelpers}
                nameBase={name}
                index={i}
                disabled={disabled}
              />
            ))}
          </>
        );
      }}
    />
  );
}

export default connect(({ auth }) => ({ user: auth.user }))(OneOnOneNewEventRows);

function NewEventRow({ arrayHelpers, nameBase, index, tz, disabled }) {
  const { values: formValues, setFieldValue } = useFormikContext();
  const location = useLocation();
  const editId = new URLSearchParams(location.search).get("editId");
  const availabilities = formValues[nameBase];
  const availability = availabilities[index];
  const getName = (name) => `${nameBase}.${index}.${name}`;

  const getRecurringList = () => {
    const startTime = moment(availability.startTime);
    const fullStartDate = moment(availability.startDate).set("hours", startTime.hours()).set("minutes", startTime.hours()).clone();
  
    const endTime = moment(availability.endTime);
    const fullEndTime = moment(availability.endDate).set("hours", endTime.hours()).set("minutes", endTime.hours()).clone();

    if (fullStartDate.clone().add(1, "month").isBefore(fullEndTime)) {
      return [];
    } else if (fullStartDate.clone().add(1, "week").isBefore(fullEndTime)) {
      return [{
        'label': "Monthly",
        'value': RECURRING_TYPE_REVERSE.MONTHLY
      }]
    } else if (fullStartDate.clone().add(1, "day").isBefore(fullEndTime)) {
      return [{
        'label': "Weekly",
        'value': RECURRING_TYPE_REVERSE.WEEKLY
      }, {
        'label': "Monthly",
        'value': RECURRING_TYPE_REVERSE.MONTHLY
      }]
    } else {
      return [{
        'label': "Daily",
        'value': RECURRING_TYPE_REVERSE.DAILY
      }, {
        'label': "Weekly",
        'value': RECURRING_TYPE_REVERSE.WEEKLY
      }, {
        'label': "Monthly",
        'value': RECURRING_TYPE_REVERSE.MONTHLY
      }]
    }
  }

  useEffect(() => {
    if (moment(availability.startDate).isAfter(availability.endDate)) {
      setFieldValue(getName("endDate"), availability.startDate)
    }
  }, [availability.startDate]);

  useEffect(() => {
    if (availability.isRecurring && moment(availability.endDate).isAfter(availability.recurringEndDate)) {
      setFieldValue(getName("recurringEndDate"), moment(availability.endDate).add(1, 'day').toDate())
    }
  }, [availability.endDate]);

  return (
    <CreateFormRow
      title={
        <>
          <div>
            Event Availability {index + 1}{" "}
            {index > 0 && index === availabilities.length - 1 && (
              <button
                type="button"
                onClick={() => arrayHelpers.remove(index)}
                className="text-primary-600 disabled:opacity-70 ml-1"
                disabled={disabled}
              >
                remove
              </button>
            )}
          </div>
        </>
      }
      right={
        <>
          <DatePicker
            className="mb-4"
            name={getName("startDate")}
            label="Event Date"
            excludePast
            disabled={disabled}
            onChange={() => {
              setFieldValue(getName("recurringInterval"), "");
            }}
          />
          <DatePicker
            name={getName("endDate")}
            label="Event End Date"
            excludePast
            disabled={disabled}
            key={getName("endDate")}
          />
          <TimePicker
            className="mt-4"
            label="Start time"
            selected={
              availability.startTime ? new Date(availability.startTime) : null
            }
            disabled={disabled}
            onChange={(time) => {
              setFieldValue(getName("startTime"), time.toISOString());
              if (!availability.endTime || moment(availability.endTime).isBefore(moment(time))) {
                setFieldValue(getName("endTime"), moment(time).tz(tz).add(1, "hour").toISOString());
              }
            }}
          />
          <TimePicker
            label="End time"
            selected={
              availability.endTime ? new Date(availability.endTime) : null
            }
            disabled={disabled}
            onChange={(time) => {
              setFieldValue(getName("endTime"), time.toISOString());
            }}
          />

          <div className="flex gap-2 mt-4">
            <Checkbox
              name={getName("isRecurring")}
              disabled={!formValues.durations[0].value || disabled}
              checked={availability.isRecurring}
              label="Is Recurring?"
            />
            {!formValues.durations[0].value && (
              <div className="text-sm text-dgray-500">
                (Please select a duration)
              </div>
            )}
          </div>
          {availability.isRecurring && (
            <div>
              <SelectInput
                disabled={disabled}
                key={
                  // to force a rerender when date or endDate changes
                  `${
                    availability.date
                      ? moment(availability.date).format("DD-MM-yyyy")
                      : ""
                  }
                  ${
                    availability.endDate
                      ? moment(availability.endDate).format("DD-MM-yyyy")
                      : ""
                  }`
                }
                className="my-4"
                name={getName("recurringInterval")}
                label="Event recurring intervals"
                options={getRecurringList()}
              />
              <DatePicker
                disabled={disabled}
                minDate={moment(availability.date).add(1)}
                name={getName("recurringEndDate")}
                label="Recurring End Date"
                excludePast
              />
            </div>
          )}
          {index === availabilities.length - 1 && (
            <button
              disabled={disabled}
              type="button"
              className="flex items-center gap-2 text-primary-600 disabled:opacity-70 mt-4"
              onClick={() => {
                arrayHelpers.push(initialNewEventAvailability);
              }}
            >
              <PlusIcon className="" />
              <span>Add availability</span>
            </button>
          )}
        </>
      }
    />
  );
}

function getRecurringIntervals(date, time, duration, endDate) {
  if (!date || !time || !duration) return [];
  const intervals = [];
  const timeDisplay = `${moment(time).format("h:mma")}-${moment(time)
    .add(duration, "minutes")
    .format("h:mma")}`;

  const dateM = moment(date);
  const endDateM = moment(endDate);
  if (endDateM.isBefore(dateM.endOf("day"))) {
    intervals.push({
      label: `Every day at ${timeDisplay} until...`,
      value: RECURRING_TYPE_REVERSE.DAILY,
    });
  }
  if (endDateM.isBefore(dateM.endOf("isoWeek"))) {
    intervals.push({
      label: `Every ${moment(date).format("ddd")}-${moment(endDate).format(
        "ddd"
      )} at ${timeDisplay} until...`,
      value: RECURRING_TYPE_REVERSE.WEEKLY,
    });
  }
  if (endDateM.isBefore(dateM.endOf("month"))) {
    intervals.push({
      label: `Every ${moment(date).format("Do")}-${moment(endDate).format(
        "Do"
      )} at ${timeDisplay} until...`,
      value: RECURRING_TYPE_REVERSE.MONTHLY,
    });
  }

  return intervals;
}

const closest = getClosestQuarterTime();

export const initialNewEventAvailability = {
  startDate: closest,
  endDate: moment(closest).add(15, 'minutes').toDate(),
  startTime: closest,
  endTime: moment(closest).add(15, 'minutes').toDate(),
  isRecurring: false,
  recurringInterval: "",
  recurringEndDate: new Date(),
};
