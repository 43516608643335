import React, { useState, useMemo, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { SESSION_TYPE, TABS } from "constants/index";
import { Spinner } from "reactstrap";
import { getSessions } from "actions/front/learner/courses";
import { getEvents } from "actions/front/mentorDashboard";
import { getCurrentTimeZone } from "utils/time";

const CalendarInformation = ({
  getEvents,
  getSessions,
  courseInformationFormData,
  typeOfSession,
  sessionFormData,
  setActiveTab,
  calendarConfirmationTabEnable,
  user,
  checkSessionValidation,
  setCalendarConfirmationTabEnable,
  onSubmitDraftForm
}) => {
  const localizer = momentLocalizer(moment);

  const [eventData, seteventData] = useState([]);
  const [otherEvents, setOtherEvents] = useState([]);
  const [sessions, setSessions] = useState([]);

  const onBackButtonClick = (e) => {
    e.preventDefault();
    setActiveTab(TABS.SESSION);
  };
  const onNextButtonClick = (e) => {
    e.preventDefault();
    setActiveTab(TABS.CONFIRMATION);
  };

  const [minDate, setMinDate] = useState(null);

  const processEvents = async (allSession) => {
    let evenProcessedData = [];
    var currentDate = moment();
    let currentMinDate =
      minDate != null
        ? moment(minDate.clone().format("YYYY-MM-DD HH:mm"))
        : null;

    let allSessionPromise = await allSession.map((session) => {
      if (
        currentMinDate == null ||
        (session.start_at.unix() < currentMinDate.unix() &&
          session.start_at.unix() > currentDate.unix())
      )
        currentMinDate = moment(
          session.start_at.clone().format("YYYY-MM-DD HH:mm")
        );
      evenProcessedData.push({
        start: new Date(moment(session.start_at).format("MM/DD/YYYY HH:mm")),
        end: new Date(moment(session.end_at).format("MM/DD/YYYY HH:mm")),
        title: courseInformationFormData.name,
      });
    });

    await Promise.all(allSessionPromise);
    setMinDate(currentMinDate);
    return evenProcessedData;
  };
  
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false)
  useEffect(async () => {
    if (checkSessionValidation) {
      let promise = await checkSessionValidation();
      let conflictStatus = false;
      await Promise.resolve(promise).then((value) => {
        conflictStatus = value;
      });
      if (conflictStatus) {
        setValidated(true);
        setCalendarConfirmationTabEnable(true);
      }
    } else {
      setValidated(true);
      if (setCalendarConfirmationTabEnable) setCalendarConfirmationTabEnable(true);
    }
  }, [])
  const onClickDraftForm = (e) => {
    onSubmitDraftForm();
  };

  useMemo(async () => {
    if (validated) {
      setLoading(true);
      let events = [];
      if (
        typeOfSession === SESSION_TYPE.SPECIFIC_INDIVIDUAL ||
        typeOfSession === SESSION_TYPE.SPECIFIC_BOTH
      ) {
        events = await processEvents(
          sessionFormData.specific_individual.all_session_with_recurring
        );
      }
      if (
        typeOfSession === SESSION_TYPE.SPECIFIC_GROUP ||
        typeOfSession === SESSION_TYPE.SPECIFIC_BOTH
      ) {
        events = await processEvents(
          sessionFormData.specific_group.all_session_with_recurring
        );
      }
      if (typeOfSession === SESSION_TYPE.GENERIC) {
        events = await processEvents(
          sessionFormData.generic.all_session_with_recurring
        );
      }
      seteventData([...eventData, ...events]);
      setLoading(false);
    }
  }, [sessionFormData, validated]);

  let timeZone = getCurrentTimeZone(user);
  let currentDate = moment().tz(timeZone);

  const getCalendarData = () => {
    Promise.all([getEvents(), getSessions()]).then(async (response) => {
      const eventData = response[0];
      const sessionData = response[1];

      if (eventData.status) {
        let eventlists = [];
        let sessionPromise = await eventData.response.events.map(
          async (singleSession) => {
            if (
              singleSession.session_type != SESSION_TYPE.GENERIC ||
              (singleSession.session_type == SESSION_TYPE.GENERIC &&
                singleSession.session_booking_count == 0)
            ) {
              let start_time = moment
                .unix(singleSession.start_at)
                .tz(timeZone)
                .format("YYYY-MM-DDTHH:mm:ssZ");
              let end_time = moment
                .unix(singleSession.end_at)
                .tz(timeZone)
                .format("YYYY-MM-DDTHH:mm:ssZ");

              eventlists.push({
                type: "event",
                start: new Date(start_time),
                end: new Date(end_time),
                title: singleSession.course_name,
                course_id: singleSession.course_id,
                is_booked:
                  singleSession.session_booking_count > 0 ? true : false,
                all_session_with_recurring_id:
                  singleSession.session_type != SESSION_TYPE.GENERIC
                    ? singleSession.all_session_with_recurring_id
                    : "",
                session_group_id: singleSession.session_group_id,
                start_at: singleSession.start_at,
                end_at: singleSession.end_at,
                detail_for: singleSession.session_type,
                check_session_cancelation_start_at:
                  singleSession.check_session_cancelation_start_at,
                is_cancelled:
                  singleSession.session_type != SESSION_TYPE.GENERIC
                    ? singleSession.is_cancelled
                      ? singleSession.is_cancelled
                      : false
                    : false,
              });
            } else {
              let sortedSession = await Promise.all(singleSession.booked_sessions.sort(
                (a, b) => a.start_at - b.start_at
              ));
            
              if (sortedSession.length !== 0) {
                if (sortedSession[0].start_at != singleSession.start_at) {
                  let start_time = moment
                    .unix(singleSession.start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
                  let end_time = moment
                    .unix(sortedSession[0].start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
            
                  eventlists.push({
                    start: new Date(start_time),
                    end: new Date(end_time),
                    title: singleSession.course_name,
                    course_id: singleSession.course_id,
                    is_booked: false,
                    all_session_with_recurring_id: "",
                    session_group_id: singleSession.session_group_id,
                    start_at: singleSession.start_at,
                    end_at: sortedSession[0].start_at,
                    detail_for: singleSession.session_type,
                    check_session_cancelation_start_at: singleSession.check_session_cancelation_start_at,
                    is_cancelled: false,
                  });
                }
                let sessionIndex = 0;
                while (sessionIndex < sortedSession.length) {
                  let start_time = moment
                    .unix(sortedSession[sessionIndex].start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
                  let end_time = moment
                    .unix(sortedSession[sessionIndex].end_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
            
                  eventlists.push({
                    start: new Date(start_time),
                    end: new Date(end_time),
                    title: singleSession.course_name,
                    course_id: singleSession.course_id,
                    is_booked: true,
                    all_session_with_recurring_id: "",
                    session_group_id: singleSession.session_group_id,
                    start_at: sortedSession[sessionIndex].start_at,
                    end_at: sortedSession[sessionIndex].end_at,
                    detail_for: singleSession.session_type,
                    check_session_cancelation_start_at: singleSession.check_session_cancelation_start_at,
                    is_cancelled:
                      sortedSession[sessionIndex].is_cancelled == 2
                        ? true
                        : false,
                  });
            
                  if (
                    sessionIndex + 1 != sortedSession.length &&
                    sortedSession[sessionIndex].end_at !=
                    sortedSession[sessionIndex + 1].start_at
                  ) {
                    let start_time = moment
                      .unix(sortedSession[sessionIndex].end_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
                    let end_time = moment
                      .unix(sortedSession[sessionIndex + 1].start_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
            
                    eventlists.push({
                      start: new Date(start_time),
                      end: new Date(end_time),
                      title: singleSession.course_name,
                      course_id: singleSession.course_id,
                      is_booked: true,
                      all_session_with_recurring_id: "",
                      session_group_id: singleSession.session_group_id,
                      start_at: sortedSession[sessionIndex].end_at,
                      end_at: sortedSession[sessionIndex + 1].start_at,
                      detail_for: singleSession.session_type,
                      is_cancelled: false,
                      check_session_cancelation_start_at: singleSession.check_session_cancelation_start_at,
                    });
                  }
            
                  sessionIndex++;
                }
                
                  if (
                    sortedSession[sortedSession.length - 1].end_at !=
                    singleSession.end_at
                  ) {
                    let start_time = moment
                      .unix(sortedSession[sortedSession.length - 1].end_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
                    let end_time = moment
                      .unix(singleSession.end_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
            
                    eventlists.push({
                      start: new Date(start_time),
                      end: new Date(end_time),
                      title: singleSession.course_name,
                      course_id: singleSession.course_id,
                      is_booked: false,
                      all_session_with_recurring_id: "",
                      session_group_id: singleSession.session_group_id,
                      start_at: sortedSession[sortedSession.length - 1].end_at,
                      end_at: singleSession.end_at,
                      detail_for: singleSession.session_type,
                      is_cancelled: false,
                      check_session_cancelation_start_at:
                        singleSession.check_session_cancelation_start_at,
                    });
                  }
                } else {
                  let start_time = moment
                      .unix(singleSession.start_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
                  let end_time = moment
                    .unix(singleSession.end_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
            
                  eventlists.push({
                    start: new Date(start_time),
                    end: new Date(end_time),
                    title: singleSession.course_name,
                    course_id: singleSession.course_id,
                    is_booked: singleSession.session_booking_count > 0 ? true : false,
                    all_session_with_recurring_id: "",
                    session_group_id: singleSession.session_group_id,
                    start_at: singleSession.start_at,
                    end_at: singleSession.end_at,
                    detail_for: singleSession.session_type,
                    check_session_cancelation_start_at: singleSession.check_session_cancelation_start_at,
                    is_cancelled:
                      singleSession.is_cancelled == 2
                        ? true
                        : false,
                  });
                }
              }
          }
        );
        await Promise.all(sessionPromise);
        setOtherEvents(eventlists);
      }
      if (sessionData.status) {
        let eventlists = [];
        let promise = await sessionData.response.map(
          async (singleSessionGroup, i) => {
            if (singleSessionGroup.session_type == SESSION_TYPE.GENERIC) {
              let start_time = moment
                .unix(singleSessionGroup.session_detail[0].start_at)
                .tz(timeZone)
                .format("YYYY-MM-DDTHH:mm:ssZ");
              let end_time = moment
                .unix(
                  singleSessionGroup.session_detail[
                    singleSessionGroup.session_detail.length - 1
                  ].end_at
                )
                .tz(timeZone)
                .format("YYYY-MM-DDTHH:mm:ssZ");
              eventlists.push({
                type: "session",
                start: new Date(start_time),
                end: new Date(end_time),
                title: singleSessionGroup.course_name,
                course_id: singleSessionGroup.course_id,
                mentor_id: singleSessionGroup.mentor_id,
                mentor_name: singleSessionGroup.mentor_name,
                is_cancelled:
                  singleSessionGroup.is_cancelled === 3 ||
                  singleSessionGroup.is_cancelled === 2
                    ? true
                    : false,
                mentor_username: singleSessionGroup.mentor_username,
              });
            } else {
              let sessionPromise = await singleSessionGroup.session_detail.map(
                (session) => {
                  let start_time = moment
                    .unix(session.start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
                  let end_time = moment
                    .unix(session.end_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
                  eventlists.push({
                    type: "session",
                    start: new Date(start_time),
                    end: new Date(end_time),
                    title: singleSessionGroup.course_name,
                    course_id: singleSessionGroup.course_id,
                    mentor_id: singleSessionGroup.mentor_id,
                    mentor_name: singleSessionGroup.mentor_name,
                    is_cancelled:
                      singleSessionGroup.is_cancelled === 3 ||
                      singleSessionGroup.is_cancelled === 2
                        ? true
                        : false,
                    mentor_username: singleSessionGroup.mentor_username,
                  });
                }
              );
              await Promise.all(sessionPromise);
            }
          }
        );
        await Promise.all(promise);
        // console.log(eventlists);
        setSessions(eventlists);
      }
    });
  };

  useMemo(() => {
    getCalendarData();
  }, []);

  const eventStyleGetter = (event, start, end, isSelected) => {
    if (event.type === "event") {
      //var backgroundColor = '#' + event.hexColor;
      // const start_at = moment(start).tz(timeZone).format("YYYY-MM-DD HH:mm");
      const start_at = moment(start);
      // let backgroundColor = start_at > currentDate ? '#3174ad' : 'lightblue';
      let backgroundColor = "";
      if (event.is_booked && !event.is_cancelled) {
        backgroundColor = "#28a745"; // for booked and not cancelled
      }  else if (start_at.unix() > currentDate.unix()) {
        backgroundColor = "#f39263"; // for upcoming and other
      }

      if (start_at.unix() < currentDate.unix()) {
        backgroundColor = "red"; // for past
      }

      if (event.is_cancelled) {
        backgroundColor = "black"; // for cancelled
      }
      var style = {
        backgroundColor: backgroundColor,
        borderRadius: "0px",
        opacity: 0.8,
        color: "#FFFFFF",
        border: "0px",
        display: "block",
      };
      return {
        style: style,
      };
    } else {
      const start_at = moment(start);
      // let backgroundColor = start_at > currentDate ? '#3174ad' : 'lightblue';
      let backgroundColor = "#28a745";
      var border = "0px";
      if (event.is_cancelled) {
        backgroundColor = "black"; // if session is cacled
      } else if (start_at.unix() < currentDate.unix()) {
        backgroundColor = "red"; // for past
      } else if (event.type === "event" && start_at.unix() > currentDate.unix()) {
        backgroundColor = "#28a745"; // for upcoming and other
      } else if(event.type == undefined){
        backgroundColor = "#005eff"; 
        border = "1px dashed #FFFFFF";
      }
      // else {
      //   backgroundColor = "#3174ad"; // for upcoming and other
      // }
      var style = {
        backgroundColor: backgroundColor,
        borderRadius: "0px",
        border: border,
        opacity: 0.8,
        color: "#FFFFFF",
        display: "block",
      };
      return {
        style: style,
      };
    }
  };
  return loading ? (
    <Spinner />
  ) : (
    <section className="card-body">
      <ul className="colorList">
      <li>
          <span className="blue"></span> Availability new for session
        </li>
        <li>
          <span className="orange"></span> Availability for sessions
        </li>
        <li>
          <span className="green"></span> Booked
        </li>
        <li>
          <span className="Red"></span> Completed/Expired
        </li>
        <li>
          <span className="black"></span> Cancelled
        </li>
      </ul>
      <Calendar
        localizer={localizer}
        events={[...otherEvents, ...sessions, ...eventData]}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        defaultDate={moment(minDate).toDate()}
        eventPropGetter={eventStyleGetter}
      />
      <div className="row">
        <div className="col-md-12">
          <hr />
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <button
                className="btn btn-primary"
                onClick={(e) => onBackButtonClick(e)}
              >
                Back
              </button>
            </div>
            <div className="col-md-4" />
            <div className="col-md-4">
              <div className="form-group text-right mb-0">
                <button
                    className="btn btn-secondary mb-0 mr-3"
                      onClick={(e) => onClickDraftForm(e)}
                    >
                    Save Draft
                </button>
                <input
                  type="button"
                  className="btn btn-secondary mb-0"
                  onClick={(e) => onNextButtonClick(e)}
                  value="Next"
                  disabled={!calendarConfirmationTabEnable}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// const mapStateToProps = (state) => ({});
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getEvents,
  getSessions
})(CalendarInformation);