import { ReactComponent as CalendarIcon } from "assets/images/icons/calendar.svg";
import { ReactComponent as SearchIcon } from "assets/images/icons/search-icon.svg";
import Button from "components/common/Button";
import { USER_TYPE } from "constants";
import { connect } from "react-redux";
import IconCircle from "views/Front/HomePage/IconCircle";
import { Link } from "react-router-dom";

function UpcomingEventCardLarge({ isHosting }) {
  return (
    <div className="bg-white rounded-lg shadow border border-dgray-200 w-full md:w-auto">
      <div className="flex items-center gap-3 py-4 px-5 border-b border-inherit">
        <IconCircle color="gray">
          <CalendarIcon />
        </IconCircle>
        <div>
          <div className="text-lg font-medium">No Upcoming Event</div>
          <div className="text-sm text-dgray-500">
            You have no upcoming event scheduled.
          </div>
        </div>
      </div>
      <div className="p-12">
        <div className="flex flex-col gap-4 justify-center items-center">
          <IconCircle color="gray">
            <SearchIcon />
          </IconCircle>
          {isHosting ? (
            <>
              <div className="text-base text-dgray-900">
                Create your next event
              </div>
              <div className="text-sm text-dgray-500">
                Go ahead to create your next event and connect with your
                audience.
              </div>
              <Button
                type="button"
                className="bg-primary-600 px-4 py-2"
                href="/create"
              >
                <span className="text-base text-white">Create events</span>
              </Button>
            </>
          ) : (
            <>
              <div className="text-base text-dgray-900 front-medium">
                Find your next event
              </div>
              <div className="text-sm text-dgray-500 front-medium">
                Explore upcoming events and find the one for your interests.
              </div>
              <Link to="/explore">
                <Button type="button" className="bg-primary-600 px-4 py-2">
                  <span className="text-base text-white front-medium">
                    Explore events
                  </span>
                </Button>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    isHosting: state.auth.user?.role[0].user_type === USER_TYPE.HOST,
  }),
  {}
)(UpcomingEventCardLarge);
