
import { Fragment, useState,useEffect,useMemo} from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as Close } from "assets/images/icons/close-icon.svg";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

function SettingsDialog({ isOpen, setOpen,price,updateSubscribe,setMenberPrice}) {

  const closeModal = () => {
    setOpen(false);
  };
  const onChange=(e)=>{
    setMenberPrice(e.target.value)
  }
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-10"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-10"
        >
          <div className="fixed inset-0 bg-black bg-dgray-700/70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative mx-auto w-[750px] lg:w-[600px] rounded-xl bg-white p-8 h-[85%] max-h-[90%] overflow-hidden overflow-y-auto">
                <button
                  className="focus:outline-none absolute"
                  onClick={closeModal}
                  style={{ top: 16, right: 16 }}
                >
                  <Close style={{ width: "15px" }} className="text-dgray-500" />
                </button>
                <Dialog.Title className="text-center">
                  Choose the product you want to purchase
                </Dialog.Title>
                  <div>
                  <FormGroup className="flex items-center">
                    <Label htmlFor="price"  className="mr-2">Price:</Label>
                    <Input
                      className="flex-1"
                      type="number"
                      id="price"
                      name="price"
                      maxLength="100"
                      value={price}
                      onChange={(e) => onChange(e)}
                      required
                    />

                  </FormGroup>
                  <Button
                        size="sm"
                        color="danger"
                        onClick={updateSubscribe}
                      >
                        <i className="fa fa-dot-circle-o"></i> Confirm
                      </Button>
                  </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default connect(null)(SettingsDialog);
