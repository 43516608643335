import React, { useState, useRef, useMemo, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { editBlog, getBlogById } from "actions/front/blog";
import Errors from "views/Notifications/Errors";
import { REMOVE_ERRORS } from "actions/types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DDNBlogFileAdapter from "../Utils/DDNBlogFileAdapter";
import ImageCrop from "../Utils/ImageCrop";

const EditBlog = ({ getBlogById, editBlog, history, loading, match, auth }) => {
  const [formData, setFormData] = useState({
    title: "",
    header_image: "",
    meta_description: "",
    meta_keywords: "",
    selectedFile: "",
  });
  const [content, setContent] = useState("");
  const dispatch = useDispatch();

  const { title, header_image, meta_description, meta_keywords, selectedFile } =
    formData;

  useEffect(() => {
    if (match.params.blog_id) {
      getBlogById(match.params.blog_id).then((res) => {
        if (res.status) {
          let blog = res.data;
          setFormData({
            title: loading || !blog.title ? "" : blog.title,
            meta_description:
              loading || !blog.meta_description ? "" : blog.meta_description,
            meta_keywords:
              loading || !blog.meta_keywords ? "" : blog.meta_keywords,
            header_image:
              loading || !blog.header_image
                ? ""
                : process.env.REACT_APP_MEDIA_URL + blog.header_image,
          });
          setContent(blog.content);
        }
      });
    }
    dispatch({ type: REMOVE_ERRORS });
  }, []);

  const onChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };

  const onImageChange = (data) => {
    let image_url = URL.createObjectURL(data);
    setFormData({
      ...formData,
      selectedFile: data,
      header_image: image_url,
    });
    dispatch({ type: REMOVE_ERRORS });
  }

  const removeImage = () => {
    setFormData({
      ...formData,
      selectedFile: null,
      header_image: null,
    });
  }

  const handleContentChange = (value) => {
    setContent(value);
    dispatch({ type: REMOVE_ERRORS });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    editBlog(formData, content, history, match.params.blog_id);
  };

  useEffect(() => {
    return () => {
      dispatch({ type: REMOVE_ERRORS });
    };
  }, []);

  return (
    <div className="row p-60 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-head space-between mb-3">
              <h2>Edit Blog</h2>
              <Link to="/dashboard">Back to Dashboard</Link>
            </div>
            <div className="section-head space-between mb-3 bg-white py-4">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="title">
                    Blog title <span className="text-danger">*</span>
                    <br />
                  </label>
                  <input
                    className="form-control"
                    placeholder="e.g. Effectively Communicating with Executives"
                    type="text"
                    id="title"
                    name="title"
                    maxLength="250"
                    minLength="3"
                    required
                    onInput={onChange("title")}
                    defaultValue={title}
                  />
                  <Errors current_key="title" key="title" />
                </div>
                <div className="form-group">
                  <label htmlFor="header_image">Upload Banner Image</label>
                  <ImageCrop image={formData.header_image} className="form-control" onComplete={onImageChange} onRemove={removeImage} aspect={3/1} />
                </div>

                <div className="form-group">
                  <label htmlFor="content">
                    Content <span className="text-danger">*</span>
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={content}
                    id="overview"
                    name="overview"
                    // className="form-control"
                    config={{
                      height: 400,
                    }}
                    onReady={(editor) => {
                      editor.plugins.get("FileRepository").createUploadAdapter =
                        (loader) => {
                          return new DDNBlogFileAdapter(loader, auth.token, auth.user._id);
                        };
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleContentChange(data);
                    }}
                    onBeforeLoad={(CKEDITOR) => {
                      CKEDITOR.disableAutoInline = true;
                    }}
                    // disabled={isConfirmation}
                    // invalid={errorList.expertise ? true : false}
                  />                  
                  <Errors current_key="content" key="content" />
                </div>
                <div className="form-group">
                  <label htmlFor="ideal_for">
                    Description <span className="text-danger">*</span>
                    <br />
                  </label>
                  <textarea
                    className="form-control"
                    type="textarea"
                    id="meta_description"
                    name="meta_description"
                    onInput={onChange("meta_description")}
                    defaultValue={formData.meta_description}
                  ></textarea>
                  <Errors
                    current_key="meta_description"
                    key="meta_description"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="title">
                    Keywords <span className="text-danger">*</span>
                    <br />
                  </label>
                  <input
                    className="form-control"
                    placeholder="e.g. Effectively Communicating with Executives"
                    type="text"
                    id="meta_keywords"
                    name="meta_keywords"
                    maxLength="250"
                    minLength="3"
                    required
                    onInput={onChange("meta_keywords")}
                    defaultValue={meta_keywords}
                  />
                  <Errors current_key="meta_keywords" key="meta_keywords" />
                </div>
                <button
                  className="btn btn-secondary mb-0"
                  onClick={(e) => onSubmitForm(e)}
                >
                  Save Blog
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { editBlog, getBlogById })(
  withRouter(EditBlog)
);
