import { getUpcomingSessions } from "actions/front/educator/courses";
import { getMycourseSessions } from "actions/front/learner/courses";
import heroPattern from "assets/images/landing-hero-pattern.svg";
import UpcomingEventCard from "components/event/UpcomingEventCard";
import { COURSE_LIST, USER_TYPE } from "constants";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../../../components/common/Button";

const filters = {
  limit: 1,
  page: 1,
  orderBy: "session_detail.session_group.all_session_with_recurring.start_at",
  ascending: "asc",
  query: "",
  custom_filters: "",
};

function HomePageSignedInHero({ user, getMycourseSessions }) {
  const [upcomingSession, setUpcomingSession] = useState();

  const setResToState = (res) => setUpcomingSession(res.data?.data[0]);
  useEffect(() => {
    getUpcomingSessions(filters).then(setResToState);
  }, []);

  return (
    <>
      <div className="h-24 lg:h-40 md:h-60 bg-primary-50" />
      <div
        className={`relative mb-72
          ${upcomingSession ? "md:mb-80 sm:mb-96" : ""}
        `}
      >
        <img src={heroPattern} alt="background" className="w-full" />
        <div className="bg-primary- absolute inset-x-0 top-0 lg:-top-24 md:-top-48">
          <div className="xl:px-0 w-2/3 2xl:w-3/4 lg:w-full md:px-8 mx-auto flex lg:flex-col justify-between lg:items-center gap-10">
            <div
              className={`lg:text-center
                ${upcomingSession ? "" : "text-center mx-auto"}
              `}
            >
              <h1 className="text-primary-900 text-5xl md:text-4xl font-semibold mb-6">
                Welcome back, {user.first_name}!
              </h1>
              <Button href="/dashboard">Go to my DDN</Button>
            </div>
            {upcomingSession && <UpcomingEventCard data={upcomingSession} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  { getMycourseSessions }
)(HomePageSignedInHero);
