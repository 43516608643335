import * as Yup from "yup";
import {
  matchingPasswordSchema,
  newPasswordSchema,
  selectOptionSchema,
} from "./shared";

export const studentManageMyAccountSchema = Yup.object({
  first_name: Yup.string().required("Please enter your first name"),
  last_name: Yup.string().required("Please enter your last name"),
  city: Yup.string(),
  avatar: undefined,
});

export const hostManageMyAccountSchema = Yup.object({
  first_name: Yup.string().required("Please enter your first name"),
  last_name: Yup.string().required("Please enter your last name"),
  city: Yup.string(),
  country: Yup.string(),
  avatar: undefined,
  timezone: Yup.string(),
  description: Yup.string().min(20, "Please enter at least 20 characters"),
});

export const manageMySettingsSchema = Yup.object({
  timezone: Yup.string(),
});
