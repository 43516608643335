import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { ReactComponent as AlertCircle } from "assets/images/icons/alert-circle.svg";
import IconCircle from "views/Front/HomePage/IconCircle";
import { ReactComponent as Close } from "assets/images/icons/close-icon.svg";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function Modal({ isOpen, setOpen, clientSecret, payText,course_id}) {
  const closeModal = () => {
    setOpen(false);
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-60" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-10"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-10"
        >
          <div className="fixed inset-0 bg-black bg-dgray-700/70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <button
                  className="focus:outline-none absolute"
                  onClick={closeModal}
                  style={{ top: 16, right: 16 }}
                >
                  <Close style={{ width: "15px" }} className="text-dgray-500" />
                </button>
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium text-center items-center flex flex-col leading-6 text-dgray-900"
                  >
                    <IconCircle color="yellow">
                      <AlertCircle />
                    </IconCircle>
                  </Dialog.Title>
                  {/* stripe支付 */}
                  <PaymentForm closeModal={closeModal} payText={payText} course_id={course_id} />
                </Elements>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
