import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as XIcon } from "assets/images/icons/x.svg";
import { Fragment } from "react";

export default function LargeModal({ isOpen, setIsOpen, children, canOverflow = true }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen?.(false)}
        className="relative z-50"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-10"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-10"
        >
          <div className="fixed inset-0 bg-black bg-dgray-700/70" />
        </Transition.Child>

        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="relative mx-auto w-[750px] lg:w-[600px] rounded-xl bg-white p-8 h-[85%] max-h-[90%] overflow-hidden overflow-y-auto">
            <button
              className="absolute top-8 right-8 text-dgray-500"
              onClick={() => setIsOpen?.(false)}
            >
              <XIcon />
            </button>
            {children}
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
}
