import { ReactComponent as CheckCircle } from "assets/images/icons/check-circle.svg";
import { setAlert } from "actions/alert";
import {
  getEmailById,
  resetPassword,
  setPasswordError,
  tokenInvalid,
} from "actions/auth";
import arrowLeft from "assets/images/icons/arrow-left.svg";
import { ReactComponent as KeyIcon } from "assets/images/icons/key.svg";
import Button from "components/common/Button";
import NewPasswordInput from "components/common/form/NewPasswordInput";
import resetPasswordSchema from "utils/schemas/resetPassword";
import IconCircle from "views/Front/HomePage/IconCircle";
import Header from "views/Front/Layout/Header";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SuccessScreen from "../SuccessScreen";

const ResetPassword = ({
  resetPassword,
  getEmailById,
  tokenInvalid,
  history,
  match,
}) => {
  const [email, setEmail] = useState("");
  const [isSuccess, setSuccess] = useState(false);

  /**************** get email by id and check for the token validation *********************/
  useMemo(() => {
    getEmailById(match.params.id, match.params.token).then((data) => {
      data && data.status
        ? setEmail(data.response.email)
        : tokenInvalid(history);
    });
  }, [match.params]);

  return (
    <>
      <Header dark />
      {isSuccess ? (
        <SuccessScreen
          title="Password reset"
          description={
            <div>
              Your password has been successfully reset.
              <div>Click below to log in to DDN.</div>
            </div>
          }
          iconColor="green"
          icon={<CheckCircle />}
        >
          <Button className="w-full" to="/login" small>
            Continue
          </Button>
        </SuccessScreen>
      ) : (
        <div className="flex flex-col items-center my-24 max-w-sm mx-auto">
          <IconCircle large>
            <KeyIcon />
          </IconCircle>
          <div className="text-center mt-5">
            <div className="text-3xl md:text-2xl text-dgray-900 font-semibold mb-3">
              Set new password
            </div>
            <div className="text-dgray-500">
              Your new password must be different to previously used passwords.
            </div>
          </div>
          <div className="mt-8 w-full">
            <Formik
              initialValues={{
                password: "",
                passwordRepeat: "",
              }}
              validationSchema={resetPasswordSchema}
              onSubmit={async ({ password }) => {
                await resetPassword(password, email, match.params.id, history);
                setSuccess(true);
              }}
            >
              <Form>
                <div className="space-y-3">
                  <NewPasswordInput />
                </div>
                <Button
                  type="submit"
                  className="text-white bg-primary-600 w-full mt-6"
                  disabled={!email}
                >
                  Set Password
                </Button>
              </Form>
            </Formik>
          </div>
          <div className="text-center flex flex-row text-dgray-500 text-sm mt-8 md:mt-8 xl:mt-10">
            <img src={arrowLeft} alt="Arrow left" className="mr-3" />
            <Link to="/login"> Back to log in </Link>
          </div>
        </div>
      )}
    </>
  );
};

ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  setPasswordError: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  getEmailById: PropTypes.func.isRequired,
  tokenInvalid: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.frontErrors,
});

export default connect(mapStateToProps, {
  setAlert,
  setPasswordError,
  resetPassword,
  getEmailById,
  tokenInvalid,
})(ResetPassword);
