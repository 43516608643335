import {
  EDUCATOR_COURSE_LOADING,
  EDUCATOR_COURSE_CREATED,
  EDUCATOR_COURSE_CREATE_ERROR,
  EDUCATOR_COURSE_FAIL,
  EDUCATOR_COURSE_EDITED,
  EDUCATOR_COURSE_EDIT_ERROR,
  MEETING_LINK_GENERATED,
} from "actions/types";

const initalState = {
  loading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case EDUCATOR_COURSE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case MEETING_LINK_GENERATED:
      return {
        ...state,
        loading: false,
      }
    case MEETING_LINK_GENERATED:
    case EDUCATOR_COURSE_EDITED:
      return {
        ...state,
        loading: false,
      };
    case EDUCATOR_COURSE_CREATE_ERROR:
    case EDUCATOR_COURSE_EDIT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case EDUCATOR_COURSE_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
