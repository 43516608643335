import React from "react";
import Errors from "views/Notifications/Errors";

const SessionDateTime = ({
  singleSession,
  isConfirmation,
  index,
  bundelIndex,
  sessionsLength,
  onGroupChange,
  current_date,
  handleSessionRemove,
  isForEdit,
  is_editable = true,
  is_recurring_editable = true,
  INPUT_TYPE,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-md-2">Name</div>
        <div className="col-md-5">
          <label htmlFor="start_date">
            Date <span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-md-5">
          <label htmlFor="start_time">
            Start Time <span className="text-danger">*</span>
          </label>
        </div>
      </div>
      <div
        className={`row ${
          (!is_editable || !is_recurring_editable) && "disabled-session"
        }`}
        key={index}
      >
        <div className="col-md-2 mt-2">Interactions : {index + 1} </div>
        <div className="col-md-5">
          <div className="form-group">
            <input
              type="date"
              className="form-control"
              name="start_date"
              onChange={(e) =>
                onGroupChange(e, INPUT_TYPE.SESSION, index, bundelIndex)
              }
              value={singleSession.start_date}
              min={
                isForEdit && !singleSession.is_new
                  ? singleSession.min_start_date
                  : current_date
              }
              disabled={isConfirmation}
            />
            {!isConfirmation && (
              <Errors
                current_key={`group_start_date_${bundelIndex}_${index}`}
                key={`group_start_date_${bundelIndex}_${index}`}
              />
            )}
          </div>
        </div>
        <div className="col-md-5 space-between">
          <div className="form-group w-75">
            {/* 
            <TimePicker
              name="start_time"
              onTimeChange={(e) =>
                onGroupChange(
                  e,
                  INPUT_TYPE.SESSION,
                  index,
                  bundelIndex,
                  "start_time"
                )
              }
              theme="classic"
              timeConfig={{
                from: 0,
                to: "23:45",
                step: 15,
                unit: "minutes",
              }}
              className="form-control"
              time={singleSession.start_time}
              disabled={isConfirmation}
            />

            {!isConfirmation && (
              <Errors
                current_key={`group_start_time_${bundelIndex}_${index}`}
                key={`group_start_time_${bundelIndex}_${index}`}
              />
            )}
            */}
          </div>
          {sessionsLength > 1 && !isConfirmation && (
            <div className="form-group w-25 text-center m-0">
              <button
                className="remove-session"
                onClick={(e) => handleSessionRemove(e, index, bundelIndex)}
              >
                <i className="fa fa-minus" />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SessionDateTime;
