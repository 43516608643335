export default function Button({
  type,
  onClick,
  children,
  loading,
  loadingText = "Loading",
  wFull,
  className,
  style,
  disabled,
  ...rest
}) {
  return (
    <button
      {...rest}
      type={type}
      className={`focus:ring-2 ring-offset-2 text-sm leading-none focus:outline-none border rounded-md py-3 
        ${loading || disabled ? "opacity-80" : ""}
        ${wFull ? "w-full" : ""}
        ${className || "bg-color"}
        `}
      onClick={onClick}
      disabled={loading}
      style={style}
    >
      {loading ? loadingText : children}
    </button>
  );
}
