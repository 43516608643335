import extractTextContent from "utils/extractTextContent";
import * as Yup from "yup";

export const htmlStringSchema = ({ min, minMsg, requiredMsg } = {}) => {
  if (min) {
    return Yup.string()
      .test("min-textcontent", minMsg, (value) => {
        const textContent = extractTextContent(value);
        return textContent ? textContent.length >= min : false;
      })
      .min(min, minMsg)
      .required(requiredMsg);
  }
  return Yup.string();
};

export const passwordSchema = (requiredMsg, minMsg) =>
  Yup.string().required(requiredMsg).min(8, minMsg);

export const matchingPasswordSchema = (
  requiredMsg,
  matchMsg,
  passwordKey = "password"
) =>
  Yup.string()
    .required(requiredMsg)
    .test(
      "match-passwords",
      matchMsg,
      (value, { parent }) => parent[passwordKey] === value
    );

export const selectOptionSchema = () =>
  Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  });

export const newPasswordSchema = () =>
  Yup.string("Please enter a password")
    .min(8, "At least 8 characters")
    .required("Please enter a password")
    .test("oneUppercase", "At least one uppercase", checkUppercase)
    .test("oneLowercase", "At least one lowercase", checkLowercase)
    .test("oneSpecial", "At least one special character", checkSpecialChar)
    .test("oneNumeric", "At least one numeric digit", checkDigit);

export const checkUppercase = (value) => /[A-Z]/.test(value);

export const checkLowercase = (value) => /[a-z]/.test(value);

export const checkSpecialChar = (value) =>
  /[!'^+%&\/()=?_*>£#$½§@{[\]\}\|\-]/.test(value);

export const checkDigit = (value) => /\d/.test(value);
