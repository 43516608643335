import Button from "components/common/Button";
import FileDropzone from "components/common/form/FileDropzone";
import InputError from "components/common/form/InputError";
import SelectInput from "components/common/form/SelectInput";
import TextInput from "components/common/form/TextInput";
import { PAYOUT_METHODS } from "constants";
import { Formik, Form } from "formik";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import updatePaymentMethodSchema from "utils/schemas/updatePaymentMethod";
import Spinner from "views/Spinner";
import { updatePaymentMethod } from "actions/front/educator/courses";
import DashboardCard from "components/dashboard/DashboardCars";
import { updateUserState } from "actions/front/myaccount";
import Pill from "components/common/Pill";

function GetPaidPaymentMethodPicker({ user }) {
  const [isLoading, setLoading] = useState(false);
  const [currentMethod, setCurrentMethod] = useState(user.payout_method);
  const [isEditing, setEditing] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className="mb-8">
      <DashboardCard>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="text-gray-600 text-lg font-medium mb-2">
              Payment Method
            </div>
            {isEditing ? (
              <>
                <Formik
                  enableReinitialize
                  initialValues={{
                    payoutMethod: currentMethod?.type,
                    paypalEmail: user?.paypal_email,
                    weChatPayFile: "",
                  }}
                  validationSchema={updatePaymentMethodSchema}
                  onSubmit={async (values) => {
                    let formData = new FormData();
                    formData.append("paymentMethod", values.payoutMethod);
                    if (values.payoutMethod === PAYOUT_METHODS.PayPal) {
                      formData.append("paypalEmail", values.paypalEmail);
                    } else if (
                      values.payoutMethod === PAYOUT_METHODS.WeChatPay
                    ) {
                      formData.append("weChatPayFile", values.weChatPayFile);
                    }
                    try {
                      const response = await updatePaymentMethod(formData);

                      dispatch(
                        updateUserState(
                          "payout_method",
                          response.data.payout_method
                        )
                      );
                      if (
                        response.data.payout_method ===
                        PAYOUT_METHODS.PayPal
                      ) {
                        dispatch(
                          updateUserState(
                            "paypal_email",
                            response.data.paypal_email
                          )
                        );
                      }
                      setEditing(false);
                    } catch (ex) {}
                  }}
                >
                  {({ values, setFieldValue }) => (
                    <>
                      <Form className="w-2/5 lg:w-1/2 md:w-full">
                        <SelectInput
                          label="Change Payment Method"
                          options={payoutMethods}
                          className="mb-4"
                          name="payoutMethod"
                        />
                        {values.payoutMethod === PAYOUT_METHODS.PayPal ? (
                          <>
                            <TextInput
                              className="mb-4"
                              name="paypalEmail"
                              label="Email address"
                              placeholder="Enter your email address"
                            />
                            <div className="text-sm text-dgray-500">
                              The email address should be owned by the account
                              holder.
                            </div>
                          </>
                        ) : (
                          values.payoutMethod === PAYOUT_METHODS.WeChatPay && (
                            <div>
                              <div className="text-sm text-dgray-700 font-medium mb-2">
                                Upload QR code
                              </div>
                              <FileDropzone
                                onDrop={(files) => {
                                  setFieldValue("weChatPayFile", files[0]);
                                }}
                                accept={{
                                  "image/png": [".png", ".jpg", ".jpeg"],
                                }}
                                description="PNG, JPG or PDF (max. 2000 kb)"
                                maxFiles={1}
                              />
                              <InputError name="weChatPayFile" />
                            </div>
                          )
                        )}
                        <Button type="submit" className="mt-4" small>
                          Update
                        </Button>
                      </Form>
                    </>
                  )}
                </Formik>
              </>
            ) : (
              <>
                <div className="flex flex-row justify-between">
                  <div className="text-3xl font-semibold mb-2 flex items-center">
                    {user.payout_method === PAYOUT_METHODS.PayPal
                      ? <>PayPal <Pill className="!font-medium !text-base ml-3 mt-1" color="orange" text={user.paypal_email}></Pill></>
                      : user.payout_method === PAYOUT_METHODS.WeChatPay
                      ? "WeChat Pay"
                      : "Not Set"}
                  </div>
                  <Button
                    type="button"
                    secondary
                    small
                    onClick={() => setEditing(true)}
                  >
                    Update
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </DashboardCard>
    </div>
  );
}

export default connect((state) => ({ user: state.auth.user }))(
  GetPaidPaymentMethodPicker
);

const payoutMethods = [
  { label: "PayPal", value: PAYOUT_METHODS.PayPal },
  { label: "WeChat Pay", value: PAYOUT_METHODS.WeChatPay },
];
