import {
  COURSE_SUBCATEGORY_LOADING,
  COURSE_SUBCATEGORY_LIST_UPDATED,
  COURSE_SUBCATEGORY_FAIL,
  COURSE_SUBCATEGORY_CREATED,
  COURSE_SUBCATEGORY_CREATE_ERROR,
  COURSE_SUBCATEGORY_UPDATED,
  COURSE_SUBCATEGORY_UPDATE_ERROR,
  COURSE_SUBCATEGORY_DELETED,
  COURSE_SUBCATEGORY_DELETE_ERROR,
  COURSE_SUBCATEGORY_CHANGE_STATUS,
  COURSE_SUBCATEGORY_CHANGE_STATUS_ERROR,
  GET_COURSE_SUBCATEGORY_BY_ID,
} from "actions/types";

const initalState = {
  courseSubCategoriesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentCourseSubCategory: null,
  loading: false,
  isSubLoading: false,
  error: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case COURSE_SUBCATEGORY_LOADING:
      return {
        ...state,
        courseSubCategoriesList: payload.is_sub_element
          ? state.courseSubCategoriesList
          : initalState.courseSubCategoriesList,
        currentCourseSubCategory: payload.is_sub_element
          ? state.currentCourseSubCategory
          : initalState.currentCourseSubCategory,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case COURSE_SUBCATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case COURSE_SUBCATEGORY_LIST_UPDATED:
      return {
        ...state,
        courseSubCategoriesList: {
          data: payload.data.data,
          page: payload.data.metadata[0].current_page,
          count: payload.data.metadata[0].totalRecord,
        },
        loading: false,
        isSubLoading: false,
      };
    case COURSE_SUBCATEGORY_CREATED:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case COURSE_SUBCATEGORY_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case COURSE_SUBCATEGORY_UPDATED:
      return {
        ...state,
        currentCourseSubCategory: null,
        loading: false,
      };
    case COURSE_SUBCATEGORY_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case COURSE_SUBCATEGORY_DELETED:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case COURSE_SUBCATEGORY_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };

    case GET_COURSE_SUBCATEGORY_BY_ID:
      return {
        ...state,
        currentCourseSubCategory: payload.data,
        loading: false,
      };
    case COURSE_SUBCATEGORY_CHANGE_STATUS:
      return {
        ...state,
        courseSubCategoriesList: {
          ...state.courseSubCategoriesList,
          data: state.courseSubCategoriesList.data.map((category) =>
            category._id === payload.data._id
              ? { ...category, status: payload.data.status }
              : category
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case COURSE_SUBCATEGORY_CHANGE_STATUS_ERROR:
      return {
        ...state,
        courseSubCategoriesList: {
          ...state.courseSubCategoriesList,
          data: state.courseSubCategoriesList.data.map((category) =>
            category._id === payload.data
              ? { ...category, is_failed: true }
              : { ...category, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    default:
      return state;
  }
}
