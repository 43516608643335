import React from "react";
import Errors from "views/Notifications/Errors";
import { SESSION_DURATION } from "constants/index";

const DurationInformation = ({
  duration_per_session,
  price_per_session,
  number_of_session,
  onIndividualChange,
  number_of_session_editable = true,
  isConfirmation,
}) => {
  return (
    <>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="duration_per_session">
            Duration per interaction <span className="text-danger">*</span>
          </label>
          <select
            style={{ marginTop: 40 }}
            name="duration_per_session"
            id="individual_duration_per_session"
            className="form-control custom-select"
            value={duration_per_session}
            onChange={(e) => onIndividualChange(e)}
            disabled={isConfirmation || !number_of_session_editable}
          >
            <option value=""> -- Select Duration -- </option>
            {Object.keys(SESSION_DURATION).map((key) => (
              <option value={key}>{SESSION_DURATION[key]}</option>
            ))}
          </select>
          {!isConfirmation && (
            <Errors
              current_key="individual_duration_per_session"
              key="individual_duration_per_session"
            />
          )}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="price_per_session">
            Price per Interaction ($) <span className="text-danger">*</span><br />
            <small>Enter a price per Interaction.  Enter 0 if you would like this session to be free</small>
          </label>
          <input
            type="number"
            min="0"
            className="form-control"
            id="individual_price_per_session"
            name="price_per_session"
            value={price_per_session}
            onChange={(e) => onIndividualChange(e)}
            disabled={isConfirmation}
          />
          {!isConfirmation && (
            <Errors
              current_key="individual_price_per_session"
              key="individual_price_per_session"
            />
          )}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="number_of_session">
            Number of interactions <span className="text-danger">*</span><br />
            <small>Hint: please only add multiple sessions if each session build on the previous interaction</small>
          </label>
          <input
            type="number"
            className="form-control"
            id="individual_number_of_session"
            name="number_of_session"
            value={number_of_session}
            onChange={(e) => onIndividualChange(e)}
            disabled={isConfirmation || !number_of_session_editable}
            min={1}
          />
          {!isConfirmation && (
            <Errors
              current_key="individual_number_of_session"
              key="individual_number_of_session"
            />
          )}
        </div>
      </div>

      <div className="col-md-12">
        <hr />
      </div>
    </>
  );
};

export default DurationInformation;
