import moment from "moment";

let unixTimeStamp = moment.utc().unix();

export function getClosestSession(course) {
  const closestSessions = course.session
    ? course.session.map((c) => {
      if (c.detail_for === 1) {
        const filtered = c.session.all_session_with_recurring_without_chuks
          .filter((r) => r.end_at > unixTimeStamp)
          .sort((s1, s2) =>
            s1.start_at === s2.start_at
              ? 0
              : s1.start_at < s2.start_at
                ? -1
                : 1
          );
        if (filtered.length > 0) {
          return filtered[0];
        }
      } else
        if (c.detail_for === 5) {
          return c.session.all_session_with_recurring[0];
        } else {
          const filtered = c.session.all_session_with_recurring
            .filter((r) => r.start_at > unixTimeStamp)
            .sort((s1, s2) =>
              s1.start_at === s2.start_at
                ? 0
                : s1.start_at < s2.start_at
                  ? -1
                  : 1
            );

          if (filtered.length > 0) {
            return filtered[0];
          }
        }
      return null;
    })
    : null;
  if (closestSessions && closestSessions.length > 1) {
    closestSessions.sort((s1, s2) =>
      s1.start_at === s2.start_at
        ? 0
        : s1.start_at < s2.start_at
          ? -1
          : 1
    );
  }
  if (closestSessions && closestSessions.length > 0) {
    return closestSessions[0];
  } else {
    return null;
  }
}

export function sortEventsByClosest(data) {
  const sortedResponse = data.map(c => ({
    ...c,
    closestSession: getClosestSession(c)
  })).sort((i1, i2) => {
    if (i1.closestSession.start_at > i2.closestSession.start_at) {
      return 1
    } else if (i1.closestSession.start_at < i2.closestSession.start_at) {
      return -1
    }
    return 0
  });

  return sortedResponse
}

export function getSubCategory(course, categories) {
  if (!categories) return null;
  let subCat = null;
  if (course?.category_id && categories) {
    const category = categories.filter(
      (item) => item._id === course.category_id
    );
    if (!category[0]) return null;

    subCat = category[0].subcategories.filter(
      (item) => item._id === course.sub_category_id
    );
  }

  return subCat;
}
