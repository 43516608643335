import heroPattern from "assets/images/landing-hero-pattern.svg";
import heroImage from "assets/images/landing-hero-image.png";
import iphoneImage from "assets/images/landing-iphone-mockup.png";
import Button from "../../../../components/common/Button";

export default function HomePageHero() {
  return (
    <div className="mb-72 md:mb-64">
      <div className="bg-primary-50">
        <div className="text-center pt-24 pb-12 xl:px-0 w-2/3 md:w-full md:px-8 mx-auto">
          <h1 className="text-primary-900 text-6xl md:text-4xl font-semibold mb-6">
            Gain confidence in your English speaking!
          </h1>
          <p className="text-primary-700 mb-12 md:text-xl">
            <span className="block">
              Improve your spoken English proficiency by engaging in guided
              online conversations.
            </span>
            Practice with qualified hosts and interact with peers.
          </p>
          <Button href="/register">Get started for free</Button>
          <p className="text-primary-700 mb-12 mt-6">
            Free trial as a speaker for 7 days, always free to listen in!
          </p>
        </div>
      </div>
      <div className="relative">
        <img src={heroPattern} alt="background" className="w-full" />
        <div className="absolute top-0 inset-x-0">
          <img src={heroImage} alt="background" className="mx-auto md:hidden" />
          <img
            src={iphoneImage}
            alt="background"
            className="mx-auto hidden md:block"
          />
        </div>
      </div>
    </div>
  );
}
