import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import StarRatings from "react-star-ratings";
import { BOOKED_SESSION_STATUS, CHIME_CLIENT_URL } from "constants/index";
import mockEventImg from "assets/images/mock-event.jpg";
import * as moment from 'moment-timezone';
import EditImage from "assets/images/edit.svg";
import { filterString } from "utils/sFold";
import { getCurrentTimeZone } from "utils/time";
import { COURSE_VIEW, SESSION_TYPE } from "constants/index";
import { confirmAlert } from "react-confirm-alert"; // Import
import Moment from "react-moment";
import Swal from "sweetalert2";
import { getCourseDetailById } from "actions/front/common/course";
import { rescheduleSession } from "actions/front/learner/order";
import { changeStatus, deleteCourse } from "actions/front/educator/courses";
import { cancelCourseScheduled } from "actions/front/learner/courses";
import { getCategories } from "actions/front/category";
import DeleteImage from "assets/images/delete.svg";
import UserImage from "assets/images/user.jpg";
import getUserAvatar from "utils/getUserAvatar";
const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
const Course = ({
  activeResult,
  course,
  courseView,
  user,
  page,
  token,
  changeStatus,
  deleteCourse,
  categories,
  durationPrice,
  modal,
  onUpdate
}) => {
  let timeZone = getCurrentTimeZone(user);
  let unixTimeStamp = moment.utc().unix();
  let now = moment(
    moment.unix(unixTimeStamp).tz(timeZone).format("YYYY-MM-DD HH:mm:ss")
  );
  const [coursesList, setCoursesList] = useState([]);
  
  let closestSession = course.session
    ? course.session.map((c) => {
        if (c.detail_for === 1) {
          const filtered = c.session.all_session_with_recurring_without_chuks
            .filter((r) => r.start_at > unixTimeStamp)
            .sort((s1, s2) =>
              s1.start_at === s2.start_at
                ? 0
                : s1.start_at < s2.start_at
                ? -1
                : 1
            );
          if (filtered.length > 0) {
            return filtered[0];
          }
        } else if (c.detail_for === 5) {
         return c.session.all_session_with_recurring[0];
        } else {
          const filtered = c.session.all_session_with_recurring
            .filter((r) => r.start_at > unixTimeStamp)
            .sort((s1, s2) =>
              s1.start_at === s2.start_at
                ? 0
                : s1.start_at < s2.start_at
                ? -1
                : 1
            );

          if (filtered.length > 0) {
            return filtered[0];
          }
        }
        return null;
      })
    : null;

  if (closestSession && closestSession.length > 0) {
    closestSession = closestSession[0];
  } else {
    closestSession = null;
  }

  const onChangeStatus = (e, course_id) => {
    changeStatus(course_id._id, e.target.checked ? 1 : 0).then((res) => {
      if (res.status) {
        let updated_course_list = [...coursesList];
        updated_course_list = updated_course_list.map((course) =>
          course._id == course_id._id ? { ...course, status: res.value } : course
        );
        setCoursesList(updated_course_list);
        if (onUpdate)
        {
          onUpdate();
        }
      } else {
        alert(res.msg);
      }
    });
  };

  const onDeleteClick = (e, course_id) => {
    if (window.confirm(`Are you sure you want to delete?`)) {
      deleteCourse(course_id._id).then((res) => {
        if (res.status) {
          let updated_course_list = coursesList.filter(
            (course) => course._id !== course_id._id
          );
          setCoursesList(updated_course_list);
          if (onUpdate)
          {
            onUpdate();
          }
        } else {
          alert(res.msg);
        }
      });
    }
  };

  const getBackgroundImage = (course) => {
    if (course.course_image === "") {
      return `url(/assets/images/mock-event.jpg)`;
    } else {
      return `url(${MEDIA_URL}${course.course_image})`;
    }
  };

  return (
    <div>
      <div className="col box-body">
        <div className="pd-container">
          <h5 className="category-title"></h5>
          <Link
            to={`/session/${course.course_id}`}
            className="overflow-ellipsis"
          >
            <h4 className="course-title">{course.course_name}</h4>
          </Link>
        </div>
        <div
          className="image"
          style={{ backgroundImage: getBackgroundImage(course) }}
        ></div>
        <div className="pd-container flex-1 flex flex-col">
          <div className="mt-3">
            <div className="flex justify-between">
              <div className="course-next-event">
                {(activeResult == page.ALL && courseView != COURSE_VIEW.EDIT) ||
                activeResult == page.UPCOMING ||
                activeResult == page.MY_LISTED_COURSES ||
                activeResult == page.COMPLETED ||
                activeResult == page.VIEW_ALL_SESSIONS ? (
                  <>
                    {closestSession && closestSession.start_at > unixTimeStamp ? (
                      <div className="flex next-event-time align-center">
                        <i
                          className="fa fa-clock-o mr-2"
                          style={{ fontSize: 30, color: "#FFA72A" }}
                        ></i>
                        <div>
                          <span className="info-text">Next Session </span>
                          <strong>
                            {moment
                              .unix(closestSession.start_at)
                              .tz(timeZone)
                              .format("ddd MMMM D, HH:mm")}
                          </strong>
                        </div>
                      </div>
                    ) : (
                      <strong className="text-muted expired">
                        {course.course_type === 5 ? <>{(closestSession.start_at < unixTimeStamp && !closestSession.end_at) ? <span className="text-orange shining">Live Now!</span> : "Ended"}</>: "Availability Expired"}
                      </strong>
                    )}
                  </>
                ) : (
                  <>
                    {closestSession && closestSession.start_at > unixTimeStamp ? (
                      <strong>
                        <i
                          className="fa fa-clock-o mr-2"
                          style={{ fontSize: 30, color: "#FFA72A" }}
                        ></i>
                        <span>Next Session</span> -
                        {moment
                          .unix(closestSession.start_at)
                          .tz(timeZone)
                          .format("ddd MMMM D, HH:mm")}
                      </strong>
                    ) : (
                      <strong className="text-muted expired">
                        {course.course_type === 5 ? <>{(closestSession.start_at < unixTimeStamp && !closestSession.end_at) ? <span className="text-orange shining">Live Now!</span>  : "Ended"}</>: "Availability Expired"}
                      </strong>
                    )}
                  </>
                )}
              </div>
              <div>
                <span className="text-orange" style={{ fontWeight: 700 }}>
                  {course.session_type === 1 || course.course_type === 1 ? (
                    <>
                      <div className="info-row">
                        <div className="flex-col flex-center">
                          <i
                            className="fa fa-user-circle-o"
                            aria-hidden="true"
                            style={{ fontSize: 20 }}
                          ></i>
                          <div className="text-muted flex text-center">
                            <small>
                              DD Me
                              <br /> for Anything
                            </small>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {course.session_type === 2 || course.course_type === 2 ? (
                    <div className="info-row">
                      <div className="flex-col flex-center">
                        <i
                          className="fa fa-user-circle-o"
                          aria-hidden="true"
                          style={{ fontSize: 20 }}
                        ></i>
                        <div className="text-muted flex text-center">
                          <small>
                            1-1
                            <br /> Sessions
                          </small>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {course.session_type === 3 || course.course_type === 3 ? (
                    <div className="info-row">
                      <div className="flex-col flex-center">
                        <i
                          className="fa fa-users"
                          aria-hidden="true"
                          style={{ fontSize: 20 }}
                        ></i>
                        <div className="text-muted flex text-center">
                          <small>
                            Group
                            <br /> Sessions
                          </small>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {course.session_type === 4 || course.course_type === 4 ? (
                    <div className="info-row">
                      <div className="ul list flex">
                        <div className="flex-col flex-center">
                          <i
                            className="fa fa-user-circle-o"
                            aria-hidden="true"
                            style={{ fontSize: 20 }}
                          ></i>
                          <div className="text-muted flex text-center">
                            <small>
                              1-1
                              <br /> Sessions
                            </small>
                          </div>
                        </div>
                        <div className="flex-col flex-center ml-2">
                          <i
                            className="fa fa-users"
                            aria-hidden="true"
                            style={{ fontSize: 20 }}
                          ></i>
                          <div className="text-muted flex text-center">
                            <small>
                              Group
                              <br /> Sessions
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {course.session_type === 5 || course.course_type === 5 ? (
                    <div className="info-row">
                      <div className="flex-col flex-center">
                        <i
                          className="fa fa-video-camera"
                          aria-hidden="true"
                          style={{ fontSize: 20 }}
                        ></i>
                        <div className="text-muted flex text-center">
                          <small>
                            Live
                            <br /> Event
                          </small>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col between flex-1">
            <div>
              {activeResult == page.UPCOMING && (
                <div className="info-row">
                  <span>Meeting Link</span>
                  {course.meeting_id ? (
                    <a
                      href={`${CHIME_CLIENT_URL}join/${course.meeting_id}?token=${token}`}
                      target="_blank"
                    >
                      <strong className="btn btn-primary">
                        Attend Session
                      </strong>
                    </a>
                  ) : (
                    <>
                      {moment
                        .duration(
                          now.diff(
                            moment
                              .unix(course.start_at)
                              .tz(timeZone)
                              .format("YYYY-MM-DD HH:mm:ss")
                          )
                        )
                        .asMinutes() > 5 ? (
                        <>
                          <strong>
                            <span className="orange-text">
                              You've missed the window to generate link
                            </span>
                          </strong>
                        </>
                      ) : (
                        <span className="orange-text">
                          Please check back at least 5 min before the start
                        </span>
                      )}
                    </>
                  )}
                </div>
              )}
              <div className="info-row o-hidden h-20 mt-2">
                <b>Overview: </b>
                <div className="text-muted">
                  <strong
                    dangerouslySetInnerHTML={{
                      __html:
                        course.course_overview.length > 110
                          ? filterString(course.course_overview).substring(
                              0,
                              110
                            ) + "..."
                          : filterString(course.course_overview),
                    }}
                  ></strong>
                </div>
              </div>
              <div className="info-row o-hidden h-20">
                <b>Ideal For: </b>
                <div className="text-muted">
                  <strong
                    dangerouslySetInnerHTML={{
                      __html:
                        course.course_ideal_for.length > 80
                          ? filterString(course.course_ideal_for).substring(
                              0,
                              80
                            ) + "..."
                          : filterString(course.course_ideal_for),
                    }}
                  ></strong>
                </div>
              </div>
            </div>
            <div>
                <div className="mt-3">
                  <div className="offered-by">
                    <img
                      className="small-profile-image"
                      src={getUserAvatar(course.mentor_avatar)}
                      alt=""
                    />
                    <div className="bottom-title">
                      <div className="top-title">Offered By:</div>
                      <Link
                        className=""
                        to={`/profile/${course.mentor_username}`}
                      >
                        <span>{course.mentor_name}</span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  {activeResult !== 4 && activeResult !== 3 ? (
                    <>
                      <div className="flex between align-end">
                        {activeResult ==
                          (page.MY_LISTED_COURSES ||
                            page.VIEW_ALL_SESSIONS) && (
                          <>
                            <h5 className="price-text flex-1">
                              {durationPrice &&
                              durationPrice[course.course_id] === 0 ? (
                                <>
                                  <strong>Free <span>session</span></strong>
                                </>
                              ) : (
                                <>
                                  <div className="text-muted">
                                    <b>Starting at</b>
                                  </div>
                                  <div className="flex align-center">
                                    <strong>
                                      ${durationPrice[course.course_id]}
                                    </strong>
                                    <small className="text-muted ml-1">
                                      {" "}
                                      / per session
                                    </small>
                                  </div>
                                </>
                              )}
                            </h5>
                          </>
                        )}

                        <div className="flex-1 text-right">
                          <Link
                            className="btn btn-different"
                            to={`/session/${course.course_id}`}
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
          </div>
        </div>

        {(activeResult == page.ALL ||
          activeResult == page.VIEW_ALL_SESSIONS ||
          activeResult == page.COMPLETED_SESSION ||
          activeResult == page.COMPLETED) && (
          <>
            {activeResult != page.VIEW_ALL_SESSIONS && (
              <div className="row">
                <div className="col-md-6">
                  <div className="info-row">
                    <strong>
                      Booked{" "}
                      <button
                        type="button"
                        className="btn-transparent"
                        onClick={
                          modal
                            ? (e) =>
                                modal.showLearnerList(course.enrolled_students)
                            : () => {}
                        }
                      >
                        {course.session_booking_count}
                      </button>{" "}
                      times
                    </strong>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {courseView == COURSE_VIEW.EDIT && (
        <div className="flex-edit-container bg-white">
          {course.course_type === 5 ? 
          <Link to={`/dashboard/edit-live-event/${course.course_id}`}>
            <img src={EditImage} alt="" className="icon" />
          </Link> 
          : 
          <Link to={`/edit-session/${course.course_id}`}>
          <img src={EditImage} alt="" className="icon" />
          </Link>
          }
          {activeResult == page.MY_LISTED_COURSES && (
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input cwidth"
                name="staus"
                checked={course.course_status == 1 ? true : false}
                onChange={(e) => onChangeStatus(e, course._id)}
              />
              <label className="custom-control-label" htmlFor="customSwitches">
                {course.status == 1 ? "Active" : "Inactive"}
              </label>
            </div>
          )}

          {activeResult == page.MY_LISTED_COURSES && (
            <>
              {course.session[0].session.is_booked ? null : (
                <button
                  className="delete-course-btn"
                  onClick={(e) => onDeleteClick(e, course._id)}
                  disabled={course.session[0].session.is_booked}
                >
                  <img src={DeleteImage} alt="" className="icon" />
                </button>
              )}
            </>
          )}
        </div>
      )}
      {courseView == COURSE_VIEW.REVIEW_COURSE && (
        <div className="flex-edit-container" style={{ height: 62 }}>
          {course.course_status != BOOKED_SESSION_STATUS.BOOKED ? (
            <span>Cancelled</span>
          ) : (
            <>
              {course.eligible_for_rating ? (
                <Link
                  to={`/session/${course.course_id}/review?user=${user._id}`}
                  className="btn btn-primary"
                >
                  Review Course
                </Link>
              ) : (
                <span>Review Course</span>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  categories: state.categories.data,
});

export default connect(mapStateToProps, {
  cancelCourseScheduled,
  getCourseDetailById,
  rescheduleSession,
  changeStatus,
  deleteCourse,
  getCategories,
})(Course);
