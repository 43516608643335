import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as moment from "moment-timezone";
import {
  getPaidUserList,
  getFullPaidUserList,
} from "actions/admin/educatorPayment";
import { SESSION_TYPE, SESSION_TYPE_REVERSE } from "constants/index";
import * as Constants from "constants/index";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import overlayFactory from "react-bootstrap-table2-overlay";
import CustomFilters from "views/Admin/Users/CustomFilters";
import { CSVLink } from "react-csv";

const PaidUsersList = ({ getPaidUserList, getFullPaidUserList }) => {
  const REFUND_BY = {
    2: "By Mentor",
    3: "By Learner",
  };

  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [paidUsersList, setPaidUsersList] = useState([]);
  //################## Initillise sorting searching parameters by default values ###################
  const [paidUsersParams, setPaidUsersParams] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "paid_on",
    ascending: "asc",
    query: "",
  });

  useEffect(() => {
    getPaidUserList(paidUsersParams).then((res) => {
      if (res.status) {
        setList(res.data);
        setCount(res.count);
      }
    });
  }, [paidUsersParams]);

  useMemo(() => {
    if (count > 0) {
      getFullPaidUserList(count).then((res) => {
        let arr = [];
        res.data.map((item) => {
          let obj = {};
          obj.user_name = item.user_name;
          obj.user_email = item.user_email;
          obj.paid_items =
            item.subscriptions?.title || item.subscriptions?.name;

          if (typeof item.payment_start_time === "number") {
            obj.payment_start_time = moment
              .unix(item.payment_start_time)
              .format("MM/DD/YYYY HH:MM:ss");
          } else if (typeof item.payment_start_time === "string") {
            obj.payment_start_time = moment(item.payment_start_time).format(
              "MM/DD/YYYY HH:MM:ss"
            );
          }
          // obj.status =  item.subscriptions.status;
          arr.push(obj);
        });
        setPaidUsersList(arr);
      });
    }
  }, [count]);

  //################## Handel table change(sorting, searching, status change) ###################
  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    setPaidUsersParams({
      ...paidUsersParams,
      page: type === "search" ? 1 : page,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      query: searchText,
    });
  };

  const CSVHeaders = [
    { label: "User Name", key: "user_name" },
    { label: "User Emial", key: "user_email" },
    { label: "Paid Items", key: "paid_items" },
    { label: "Payment start time", key: "payment_start_time" },
    // { label: "Status", key:"status"}
  ];

  const DownloadXls = () => (
    <a href="#" className="download-xls">
      <CSVLink
        className="fa fa-file-excel-o text-orange "
        data={paidUsersList}
        headers={CSVHeaders}
        filename={`Refund-List ${moment
          .unix(moment().utc().unix())
          .format("MM/DD/YYYY HH:MM:ss")}.csv`}
      >
        {" "}
        Download
      </CSVLink>
    </a>
  );

  const columns = [
    {
      dataField: "user_name",
      text: "User Name",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "user_email",
      text: "User Emial",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "",
      text: "Paid Items",
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      formatter: (cellContent, row) =>
        row.subscriptions.title || row.subscriptions.name,
    },
    {
      dataField: "created_at",
      text: "Payment start time",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      formatter: (cellContent, row) => {
        if (typeof row.payment_start_time === "number") {
          return moment
            .unix(row.payment_start_time)
            .format("DD MMM, YYYY hh:mm A");
        } else if (typeof row.payment_start_time === "string") {
          return moment(row.payment_start_time).format("DD MMM, YYYY hh:mm A");
        }
      },
    },
    // {
    //   dataField: "",
    //   text: "Status",
    //   headerStyle: {
    //     backgroundColor: Constants.TABLE_BORDER_COLOR,
    //   },
    //   formatter: (cellContent, row) => row.subscriptions.status
    // }
  ];

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li
      key={text}
      role="presentation"
      className="dropdown-item"
      onMouseDown={(e) => {
        e.preventDefault();
        onSizePerPageChange(page);
      }}
    >
      <Button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        className="sizePerPageaTag"
      >
        {text}
      </Button>
    </li>
  );

  const defaultSorted = [
    {
      dataField: "paid_on",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <>
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    </>
  );

  const options = {
    page: parseInt(paidUsersParams.page),
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: count,
    sizePerPageOptionRenderer,
    sizePerPageList: [
      {
        text: Constants.DEFAULT_PAGE_SIZE,
        value: Constants.DEFAULT_PAGE_SIZE,
      },
      {
        text: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
        value: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
      },
      {
        text: "All",
        value: count,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <div className="animated fadeIn displayTableList">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(options)}
                  keyField="_id"
                  columns={columns}
                  data={list}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="_id"
                      data={list}
                      columns={columns}
                      search
                    >
                      {(toolkitprops) => (
                        <>
                          <>
                            <div className="per-page-div">
                              Record per page:
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <DownloadXls />
                            <CustomFilters {...toolkitprops.searchProps} />
                          </>
                          <BootstrapTable
                            {...toolkitprops.baseProps}
                            bootstrap4
                            remote={{
                              pagination: true,
                              filter: true,
                              sort: true,
                            }}
                            keyField="_id"
                            data={list}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={handleTableChange}
                            defaultSorted={defaultSorted}
                            noDataIndication={Constants.NO_RECORD}
                            bordered={false}
                            hover
                            // loading={loading && !subLoading}
                            overlay={overlayFactory({
                              // spinner: <Spinner />,
                              styles: {
                                overlay: (base) => ({
                                  ...base,
                                  background: "#E8E8E8",
                                }),
                              },
                            })}
                          />
                        </>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

PaidUsersList.propTypes = {
  getPaidUserList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getPaidUserList,
  // payToMentorNow,
  getFullPaidUserList,
})(withRouter(PaidUsersList));
