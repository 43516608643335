import { loadUser } from "actions/auth";
import axios from "utils/axios";

export const follow = (following_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        following_id
      });
      const res = await axios.post(`/api/front/dingdong/follow`, body, config);
      if (res.data.status === true) {
        dispatch(loadUser());
      }
    } catch (err) {}
  }

  export const unFollow = (unfollow_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        unfollow_id
      });
      const res = await axios.post(`/api/front/dingdong/unfollow`, body, config);
      if (res.data.status === true) {
        dispatch(loadUser());
      }
    } catch (err) {}
  }
  
  export const getFollowingList = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(`/api/front/dingdong/following`, config);
      if (res.data.status === true) {
        return res.data.response.list;
      }
    } catch (err) {}
  } 

  export const getFollowerList = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(`/api/front/dingdong/followers`, config);
      if (res.data.status === true) {
        return res.data.response.list;
      }
    } catch (err) {}
  } 