import { getMyOrders } from "actions/front/myaccount";
import { menberProductList } from "actions/menber";
import Swal from "sweetalert2";
import Button from "components/common/Button";
import NotFoundText from "components/common/NotFoundText";
import Pill from "components/common/Pill";
import Table from "components/common/Table";
import MenberPicker from "./MenberPicker";
import { SESSION_TYPE_REVERSE } from "constants";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "views/Spinner";
import DashboardHeader from "components/common/dashboard/Header";
import InfoModal from "components/common/Modal/Info";
import {
  cancelSubscription,
  getInvoice,
  initializeSubscription,
} from "actions/front/learner/order";
import { loadUser, loadUserLastInfo } from "actions/auth";
import PaymentModal from "views/Front/Order/Cart/PaymentModal";
import { getTimeZone } from "utils/time";
import { data } from "jquery";
const describeList = [
  [
    "* Unlimited access to public event as a Speaker",
    "* However , your monthly pass will be suspended if you have 3 times no show",
    "* Make sure cancel your speaker seat prior to event start time to avoid no show penalty",
  ],
  [
    "* You can attend four public events as a speaker",
    "* Your event will be consumed even if you don't show up without cancelling",
    "* No expiration date for the four events",
  ],
  [
    "* You can attend one public event",
    "* Your event will be consumed even if you don't show up without cancelling",
    "* No expiration date for the event pass",
  ],
];
function DashboardBalance({
  getMyOrders,
  initializeSubscription,
  cancelSubscription,
  user,
  loadUser,
  loadUserLastInfo,
  menberProductList,
}) {
  const tz = getTimeZone();
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(initialFilters);
  const [items, setItems] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [subscriptionRequest, setSubscriptionRequest] = useState();
  const [isCycleEnds, setCycleEnds] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [nextMoths, setNextMoths] = useState();
  const [menberList, setMenberList] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState([]);
  const [errorSuccess, setErrorSuccess] = useState({ isError: null, msg: "" });
  const fetchData = () => {
    setLoading(true);
    getMyOrders({ ...filters, page }).then((res) => {
      setItems(res?.data);
      setTotalRecords(res.total);
      setLoading(false);
    });
  };
  const fetchSubscriptionInfo = () => {
    const canceledSubscription = user?.subscriptions?.filter(
      (s) => s.status === "none"
    );
    if (canceledSubscription.length > 0) {
      const nowUTCTime = moment
        .unix(moment(moment.utc().unix()))
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");

      const nextMothUTCTime = moment(canceledSubscription[0].created_at)
        .add(1, "month")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      console.log(
        "======setCycleEnds",
        nowUTCTime,
        nextMothUTCTime,
        nowUTCTime < nextMothUTCTime
      );
      setCycleEnds(nowUTCTime < nextMothUTCTime);
      setNextMoths(
        moment(nextMothUTCTime, "YYYY-MM-DD HH:mm:ss").format("MMM D")
      );
    }
  };
  useEffect(() => {
    console.log("===========/dashboard/balance");
    const activeSubscriptionNew = user?.subscriptions?.filter(
      (s) => s.status === "succeeded"
    );
    setActiveSubscription(activeSubscriptionNew);
    console.log(
      "activeSubscriptionNew",
      activeSubscription,
      activeSubscriptionNew
    );
  }, [user]);
  useEffect(() => {
    fetchData();
    fetchSubscriptionInfo();
  }, [page]);
  const subscribe = async () => {
    setClientSecret(null);
    let response = await menberProductList();
    if (!response?.status) {
      setErrorSuccess({
        isError: true,
        msg: response?.msg,
        cartBtn: true,
      });
      return;
    }
    response?.data?.member_list.map((item, index) => {
      item.describe = describeList[index];
    });
    setMenberList(response?.data?.member_list);
    setModalOpen(true);
    // console.log("setModalOpen", isModalOpen);
  };
  const seeInvoice = async () => {
    const response = await getInvoice();
    window.open(response.data.url, "_blank");
  };
  return (
    <div className="py-8 min-h-screen">
      <InfoModal
        modalContent={{ title: "Info", content: errorSuccess.msg }}
        onClose={() => setErrorSuccess({ isError: null, msg: "" })}
        isOpen={errorSuccess.isError !== null}
      ></InfoModal>
      <DashboardHeader
        title="My Balance"
        description="Monitor your subscription history and spending."
      />
      <div className="pb-10">
        <div className="text-2xl font-medium mb-6">
          Your DDN Membership Plan
        </div>
        {/* 是否是会员 */}
        <div className="bg-white border rounded-lg">
          {/* {user?.updated_at} */}
          {user?.is_premium ? (
            <>
              <div className="gap-2 py-5 px-6">
                {activeSubscription.length > 0 && !user.free_trial_ends_at ? (
                  <h3 className="text-dgray-900 text-3xl font-semibold">
                    {activeSubscription[0].ismonthly
                      ? activeSubscription[0]?.title +
                        "  $" +
                        activeSubscription[0]?.price
                      : "Package Pass"}
                  </h3>
                ) : (
                  <h3 className="text-dgray-500 text-lg font-medium">
                    {activeSubscription.length === 0 &&
                    user?.free_trial_ends_at &&
                    moment.unix(user?.free_trial_ends_at).tz(tz).unix() >
                      moment().unix()
                      ? "Purchase Member since" +
                        " " +
                        moment().utc(user?.created_at).format("MMM D")
                      : ""}
                  </h3>
                )}
                <div className="flex justify-between mt-4">
                  {activeSubscription.length === 0 &&
                  user?.free_trial_ends_at &&
                  moment.unix(user?.free_trial_ends_at).tz(tz).unix() >
                    moment().unix() ? (
                    <h2 className="text-dgray-900 text-3xl font-semibold">{`You're on Free Trial (ending in ${moment
                      .unix(user?.free_trial_ends_at)
                      .tz(tz)
                      .diff(moment(), "days")} days)`}</h2>
                  ) : (
                    <div
                      className="flex justify-between gap-2 text-2xl"
                      style={{ paddingTop: 0 }}
                    >
                      {activeSubscription.length > 0 &&
                      !user?.free_trial_ends_at
                        ? activeSubscription?.[0]?.ismonthly
                          ? activeSubscription?.[0]?.describe
                          : `${activeSubscription?.[0]?.describe.replace(
                              "$",
                              activeSubscription?.[0]?.total
                            )} ${activeSubscription?.[0]?.pass} (no expiration)`
                        : null}
                    </div>
                  )}
                  {
                    activeSubscription?.length == 0 &&
                    user?.free_trial_ends_at &&
                    moment.unix(user?.free_trial_ends_at).tz(tz).unix() >
                      moment().unix() ? (
                      <Button small className="!px-12" onClick={subscribe}>
                        Purchase Now
                      </Button>
                    ) : null
                    // <Button small secondary onClick={seeInvoice}>
                    //   See Invoice
                    // </Button>
                  }
                </div>
              </div>
              {activeSubscription.length > 0 &&
              !user.free_trial_ends_at &&
              activeSubscription?.[0]?.ismonthly ? (
                <div
                  className="flex justify-between gap-2 py-5 px-6 text-2xl"
                  style={{ paddingTop: 0 }}
                >
                  {moment(activeSubscription?.[0]?.ends_at * 1000).format(
                    "MMM DD, YYYY"
                  )}
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div className="gap-3 py-5 px-6 flex justify-between">
                <div>
                  <h3 className="text-dgray-500 text-lg font-medium">
                    {/*  */}
                    {isCycleEnds
                      ? "You have canceled your subscription! Your subscription cycle ends on " +
                        nextMoths
                      : "You are currently on free plan"}
                  </h3>
                  <div className="flex justify-between mt-4">
                    <h2 className="text-dgray-900 text-3xl font-semibold">
                      Upgrade your membership
                    </h2>
                  </div>
                  {/* <div className="flex justify-between mt-4">$20 per month</div> */}
                </div>
                {/* <div className="text-dgray-500">
                  <div className="text-center">
                    <p>Monthly Pass $20</p>
                    <p>Your Monthly Pass will expire on Month Day Year</p>
                    <p>(February 26,2024)</p>
                  </div>
                  <div className="text-center">
                    <p>Event Package $20 for 4 Events</p>
                    <p>Your remainning available event:3(no expiration)</p>
                  </div>
                  <p className="text-center">
                    You have one Time Event Pass(no expiration)
                  </p>
                </div> */}
                <div className="flex items-center">
                  <Button small className="!px-12" onClick={subscribe}>
                    Purchase Now
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {!clientSecret ? (
        <MenberPicker
          setClientSecret={setClientSecret}
          isOpen={isModalOpen}
          setOpen={setModalOpen}
          getMenberPickInfo={menberList}
        />
      ) : (
        <PaymentModal
          isOpen={isModalOpen}
          setOpen={setModalOpen}
          clientSecret={clientSecret}
          select={true}
          title="Purchase a Plan"
        />
      )}
      {/* 订单列表 */}
      <div className="pb-10">
        <div className="text-2xl font-medium mb-6">Spending History</div>
        {isLoading ? (
          <Spinner />
        ) : items?.length > 0 ? (
          <div>
            <Table
              page={page}
              setPage={setPage}
              pageSize={filters.limit}
              count={totalRecords}
              top={
                <>
                  Paid Events
                  <Pill
                    className="ml-2"
                    text={`${totalRecords} events & sessions`}
                    color="orange"
                  />
                </>
              }
              columns={[
                {
                  label: "Date",
                  key: "",
                  Content: ({ data }) => (
                    <div>
                      {moment.unix(data.booked_on).format("MMM DD, YYYY")}
                    </div>
                  ),
                },
                { label: "Event Name", key: "course_name" },
                {
                  label: "Host",
                  key: "name",
                  Content: ({ data }) => (
                    <>
                      <div className="font-medium">{data.mentor_name}</div>
                      <div>@{data.mentor_username}</div>
                    </>
                  ),
                },
                {
                  label: "Event Type",
                  key: "name",
                  Content: ({ data }) => (
                    <div>{SESSION_TYPE_REVERSE[data.session_type]}</div>
                  ),
                },
                // { label: "DDN Program", key: "name" },
                {
                  label: "Price",
                  key: "course_price",
                  Content: ({ data }) => (
                    <div className="text-dgray-900 font-medium">
                      $
                      {data.course_price.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </div>
                  ),
                },
                // { label: "Invoice", key: "name" },
              ]}
              rows={items}
            />
          </div>
        ) : (
          <NotFoundText>No payments found.</NotFoundText>
        )}
      </div>
    </div>
  );
}

export default connect((state) => ({ user: state.auth.user }), {
  getMyOrders,
  initializeSubscription,
  cancelSubscription,
  loadUser,
  menberProductList,
  loadUserLastInfo,
})(DashboardBalance);

const initialFilters = {
  limit: 7,
  page: 1,
  orderBy: "created_at",
  ascending: "desc",
  query: "",
  custom_filters: "",
};
