import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
import {
  getEmailTemplateList,
  deleteEmailTemplate,
  changeStatus
} from "actions/admin/emailTemplate";
import * as Constants from "constants/index";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Card, CardBody, Col, Row } from "reactstrap";
import Spinner from "views/Spinner";
const { SearchBar } = Search;

const actions = (
  <div className="add-button-div">
    <Link to="/admin/email-templates/create" className="addNewElementClass">
      <Button color="primary" size="sm">
        <i className="fa fa-plus"></i> Create Email Template
      </Button>
    </Link>
  </div>
);

const EmailTemplateList = ({
  getEmailTemplateList,
  deleteEmailTemplate,
  history,
  emailTemplateList: { data, count, page },
  sortingParams,
  changeStatus,
  loading
}) => {
  const [emailTemplateParams, setEmailTemplateParams] = useState({
    limit: sortingParams.limit,
    page: sortingParams.page,
    orderBy: sortingParams.orderBy,
    ascending: sortingParams.ascending,
    query: sortingParams.query
  });


  // const headerSortingStyle = { backgroundColor: '#c8e6c9' };
  // Removed and Replaced from sizePerPageRenderer
  // const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
  //   <li
  //     key={text}
  //     role="presentation"
  //     className="dropdown-item"
  //     onMouseDown={e => {
  //       e.preventDefault();
  //       onSizePerPageChange(page);
  //     }}
  //   >
  //     <Button
  //       tabIndex="-1"
  //       role="menuitem"
  //       data-page={page}
  //       className="sizePerPageaTag"
  //     >
  //       {text}
  //     </Button>
  //   </li>
  // );

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      }
      // headerSortingStyle
    },
    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      // headerSortingStyle,
      formatter: (cellContent, row) => (
        <Moment format="YYYY/MM/DD">{Number(row.created_at)}</Moment>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      }
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      },
      formatter: (cell, row) => {
        return (
          <div
            className="updateStatus"
            onClick={(e, a) => {
              changeStatus(row._id, cell === 0 ? 1 : 0);
            }}
          >
            {cell === 0 ? (
              <i
                className="fa fa-2x fa-thumbs-down"
                title="Click to Activate"
              ></i>
            ) : (
              <i
                className="fa fa-2x fa-thumbs-up"
                title="Click to Deactivate"
              ></i>
            )}
          </div>
        );
      }
      // headerSortingStyle
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: (cellContent, row) => (
        <div>
          <Link to={`/admin/email-templates/${row._id}`}>
            <Button type="button" size="sm" color="success">
              <i className="fa fa-pencil"></i>
            </Button>
          </Link>
          <Button
            type="button"
            size="sm"
            color="danger"
            onClick={e => {
              if (
                window.confirm(
                  `Are you sure you want to delete :\r ${row.title}?`
                )
              ) {
                deleteEmailTemplate(row._id, history);
              }
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR
      }
    }
  ];

  useMemo(() => {
    /**
     * Due to asynchronous behavior it is possible that we are dealing with 
     * Old value using setState({...params, propery:value });
     * So, to eliminate this situation we are doing function update such as below
     */
    setEmailTemplateParams((params) => ({ ...params, page }) );
  }, [page]);


  useEffect(() => {
    getEmailTemplateList(emailTemplateParams);
  }, [getEmailTemplateList, emailTemplateParams]);

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc"
    }
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <span className="custom-paginator">
      <span>Records per page: </span>
      <UncontrolledDropdown
        tag="span"
      >
        <DropdownToggle caret
          className="dropdown-btn-col-w dropdown-p-28">
          {currSizePerPage}
        </DropdownToggle>
        <DropdownMenu>
          { options.map(option => <DropdownItem
            tag="li"
            key={option.text}
            onMouseDown={e => {
              e.preventDefault();
              onSizePerPageChange(option.page);
            }}
          >
            {option.text}
            </DropdownItem>) 
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    </span>
  );

  const options = {
    page: parseInt(emailTemplateParams.page),
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: count,
    sizePerPageRenderer,
    //sizePerPageOptionRenderer,
    sizePerPageList: [
      {
        text: Constants.DEFAULT_PAGE_SIZE,
        value: Constants.DEFAULT_PAGE_SIZE
      },
      {
        text: "20",
        value: 20
      },
      {
        text: "All",
        value: count
      }
    ] // A numeric array is also available. the purpose of above example is custom the text
  };

  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    setEmailTemplateParams({
      page: type === "search" ? 1 : page,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      query: searchText
    });
    // getEmailTemplateList(emailTemplateParams);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn displayTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              {actions}
              <PaginationProvider
                pagination={ paginationFactory(options) }
                keyField="title"
                columns={columns}
                data={data}
              >
              {
                ({
                  paginationProps,
                  paginationTableProps,
                }) => 
                  <ToolkitProvider
                    keyField="title"
                    data={data}
                    columns={columns}
                    search
                  >
                    {toolkitprops => (
                      <>
                        <>
                          <SizePerPageDropdownStandalone { ...paginationProps } />
                          <SearchBar {...toolkitprops.searchProps} />
                        </>
                        <BootstrapTable
                          {...toolkitprops.baseProps}
                          bootstrap4
                          remote={{ pagination: true, filter: true, sort: true }}
                          keyField="title"
                          data={data}
                          columns={columns}
                          { ...paginationTableProps }
                          onTableChange={handleTableChange}
                          defaultSorted={defaultSorted}
                          noDataIndication={Constants.NO_RECORD}
                          bordered={false}
                          hover
                        />
                      </>
                    )}
                  </ToolkitProvider>
                }
                </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EmailTemplateList.propTypes = {
  getEmailTemplateList: PropTypes.func.isRequired,
  deleteEmailTemplate: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  emailTemplateList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  sortingParams: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  emailTemplateList: state.emailTemplate.emailTemplateList,
  loading: state.emailTemplate.loading,
  sortingParams: state.emailTemplate.sortingParams
});

export default connect(mapStateToProps, {
  getEmailTemplateList,
  deleteEmailTemplate,
  changeStatus
})(withRouter(EmailTemplateList));
