import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "actions/auth";
import * as moment from 'moment';

import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Row,
  Label,
  Col,
} from "reactstrap";

import {
  //AppAsideToggler,
  AppNavbarBrand,
  AppSidebarToggler,
} from "@coreui/react";
import logo from "assets/img/brand/logo.svg";
import defaultImage from "assets/img/avatars/user.jpg";
import sygnet from "assets/img/brand/sygnet.svg";

const DefaultHeader = ({
  auth: { isAuthenticated, loading, user },
  logout,
}) => (
  <Fragment>
    <AppSidebarToggler className="d-lg-none" display="md" mobile />
    <AppNavbarBrand
      href={"/"}
      full={{ src: logo, width: 110, height: 45, alt: "Logo" }}
      minimized={{ src: sygnet, width: 30, height: 30, alt: "Logo" }}
    />
    <AppSidebarToggler className="d-md-down-none" display="lg" />

    <Label className='ml-6'>Your timezone is <strong>{moment.tz(user?.timezone ? user?.timezone : 'UTC').format("z")}</strong></Label>
    <Nav className="ml-auto" navbar>
      <UncontrolledDropdown nav direction="down">
        <DropdownToggle nav>
          <img
            src={defaultImage}
            className="img-avatar"
            alt={user && user.first_name}
          />
        </DropdownToggle>
        <DropdownMenu right>
          <Row>
            <Col>
              <Link to="/admin/profile" className="addNewElementClass">
                <DropdownItem>
                  <i className="fa fa-user"></i> Profile
                </DropdownItem>
              </Link>
              <DropdownItem onClick={logout}>
                <i className="fa fa-lock"></i> Logout
              </DropdownItem>
            </Col>
          </Row>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  </Fragment>
);

DefaultHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logout })(DefaultHeader);
