import React from "react";
import Errors from "views/Notifications/Errors";

import { RECURRING_TYPE, RECURRING_TYPE_REVERSE } from "constants/index";

const SessionRecurringData = ({
  singleSession,
  index,
  onGenericChange,
  INPUT_TYPE,
  isConfirmation,
  is_editable = true,
  is_recurring_editable = true,
}) => {
  return (
    <div className="row">
      {/*********** single session recurring type**************/}

      <div
        className={`col-md-6 ${
          (!is_editable || !is_recurring_editable) && "disabled-session"
        }`}
      >
        <div className="form-group">
          <label htmlFor="recurring_type">
            Recurring <span className="text-danger">*</span>
          </label>
          <select
            className="form-control custom-select"
            id="generic_recurring_type"
            name="recurring_type"
            value={singleSession.recurring_type}
            onChange={(e) => onGenericChange(e, INPUT_TYPE.SESSION, index)}
            disabled={isConfirmation}
          >
            {Object.keys(RECURRING_TYPE).map((key) => {
              if (
                key == RECURRING_TYPE_REVERSE.NONE ||
                key >= singleSession.recurring_type_min
              )
                return <option value={key}>{RECURRING_TYPE[key]}</option>;
            })}
          </select>
          {!isConfirmation && (
            <Errors
              current_key={`generic_recurring_type_${index}`}
              key={`generic_recurring_type_${index}`}
            />
          )}
        </div>
      </div>

      {/*********** single session recurring date (only if recurring type is not NONE**************/}

      {singleSession.recurring_type != RECURRING_TYPE_REVERSE.NONE && (
        <div
          className={`col-md-6 ${!is_recurring_editable && "disabled-session"}`}
        >
          <div className="form-group">
            <label htmlFor="recurring_end_date">
              Recurring End Date <span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              id="generic_recurring_end_date"
              name="recurring_end_date"
              min={singleSession.recurring_min_date}
              value={singleSession.recurring_end_date}
              onChange={(e) => onGenericChange(e, INPUT_TYPE.SESSION, index)}
              disabled={isConfirmation}
            />
            {!isConfirmation && (
              <Errors
                current_key={`generic_recurring_end_date_${index}`}
                key={`generic_recurring_end_date_${index}`}
              />
            )}
          </div>
        </div>
      )}
      <div className="col-md-12">
        <hr />
      </div>
    </div>
  );
};

export default SessionRecurringData;
