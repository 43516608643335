import { getBlog } from "actions/front/blog";
import CourseImage from "assets/images/mock-event.jpg";
import { ReactComponent as CopyIcon } from "assets/images/icons/copy.svg";
import { ReactComponent as FacebookIcon } from "assets/images/icons/facebook.svg";
import { ReactComponent as LinkedinIcon } from "assets/images/icons/linkedin.svg";
import { ReactComponent as TwitterIcon } from "assets/images/icons/twitter.svg";
import CenterContainer from "components/common/Container/Center";
import DarkHero from "components/common/Hero/Dark";
import JoinCTA from "components/common/JoinCTA";
import Pill from "components/common/Pill";
import getUserAvatar from "utils/getUserAvatar";
import {
  getShareLinkFacebook,
  getShareLinkLinkedin,
  getShareLinkTwitter,
} from "utils/socialShare";
import Spinner from "views/Spinner";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import HostCardLarge from "../Hosts/HostCardLarge";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
const { REACT_APP_APP_URL, REACT_APP_MEDIA_URL } = process.env;

export default function Blog({ match }) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const blog = await getBlog(match.params.slug);
      if (blog.length === 0) {
        history.push("/");
      }
      setBlog(blog);
      setLoading(false);
    };
    init();
  }, []);

  return (
    <>
      <Header className="bg-dgray-800" light />
      {false ? (
        <Spinner />
      ) : (
        blog && (
          <>
            <Helmet>
              <title>{blog.title}</title>
              <meta name="description" content={blog.meta_description} />
              {blog.meta_keywords ? (
                <meta name="keywords" content={blog.meta_keywords} />
              ) : null}
              <meta property="og:image" content={blog.header_image} />
              <meta property="og:title" content={blog.title} />
              <meta property="og:description" content={blog.meta_description} />
              <meta property="og:type" content="article" />
              <meta
                property="article:published_time"
                content={moment(blog.created_at).toISOString()}
              />
              <meta property="og:locale" content="en_US" />
              <meta
                property="og:url"
                content={`${REACT_APP_APP_URL}blog/${match.params.slug}`}
              />
              <meta property="og:site_name" content="DingDingNow" />
              {blog.updatedDate ? (
                <meta
                  property="article:updated_time"
                  content={moment(blog.updated_at).toISOString()}
                />
              ) : null}
            </Helmet>
            <DarkHero
              className="pb-40"
              title={blog.title}
              supertitle="DDN Blog"
              subtitle={"Blog description."}
              bottom={
                <div className="flex items-center justify-center gap-3">
                  <img
                    src={getUserAvatar(blog.publisher_avatar)}
                    alt="Author"
                    className="h-12 w-12 rounded-full bg-dgray-700"
                  />
                  <div className="text-left">
                    <div className="text-white font-medium">
                      {blog.publisher_name}
                    </div>
                    <div className="text-dgray-100">
                      {moment(blog.created_at).format("MMM DD, YYYY")}
                    </div>
                  </div>
                </div>
              }
            />
            <CenterContainer>
              <div className="-mt-20 md:-mt-10 h-[516px] md:h-60">
                <img
                  className="rounded-3xl w-full h-full object-cover"
                  src={
                    blog.header_image
                      ? REACT_APP_MEDIA_URL + blog.header_image
                      : CourseImage
                  }
                  alt=""
                />
              </div>
            </CenterContainer>
            <CenterContainer className="pt-24" tight>
              <div className="flex justify-between items-center pb-6 mb-12 border-b boder-dgray-200">
                {blog.program ? <Pill text={blog.program.name} color="blue" />: <div></div>}
                <div className="flex gap-3">
                  <button
                    className="border border-dgray-300 py-2.5 px-4 rounded-lg text-dgray-700"
                    onClick={() => {
                      navigator.clipboard.writeText(window.location.href);
                    }}
                  >
                    <CopyIcon className="inline mr-2" />
                    Copy link
                  </button>
                  {[
                    {
                      icon: <TwitterIcon />,
                      href: getShareLinkTwitter(blog.title),
                    },
                    {
                      icon: <FacebookIcon />,
                      href: getShareLinkFacebook(),
                    },
                    {
                      icon: <LinkedinIcon />,
                      href: getShareLinkLinkedin(blog.title),
                    },
                  ].map(({ icon, href }) => (
                    <a
                      href={href}
                      target="_blank"
                      rel="noreferrer"
                      className="p-2.5 border rounded-lg text-dgray-400"
                    >
                      {icon}
                    </a>
                  ))}
                </div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
              <div className="mt-16">
                <div className="text-lg font-semibold border-b border-dgray-200 pb-2 mb-6">
                  About the author
                </div>
                <HostCardLarge
                  data={{
                    _id: blog.publisher_id,
                    name: blog.publisher_name,
                    location: blog.publisher_location,
                    city: blog.publisher_city,
                    country: blog.publisher_country,
                    state: blog.publisher_state,
                    languages: blog.publisher_languages,
                    description: blog.publisher_description,
                    avatar: blog.avatar,
                    username: blog.publisher_username,
                    expertise_list: blog.publisher_expertise_list,
                  }}
                  hideUpcoming
                />
              </div>
            </CenterContainer>
            <JoinCTA />
            <Footer />
          </>
        )
      )}
    </>
  );
}

const fakeHost = {
  name: "Jane Doe",
  location: "London, England",
  languages: ["English", "Spanish"],
  about:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mi non dui habitant parturient. Aliquam viverra tristique laoreet posuere mauris quam interdum etiam quis.",
};
