import { ReactComponent as EditLineIcon } from "assets/images/icons/edit-line.svg";
import { connect } from "react-redux";
import { isUserHost } from "utils/isUserHost";
import Button from "../Button";

function DashboardHeader({ user, title, description }) {
  return (
    <header className="flex lg:flex-col justify-between items-start lg:items-start mb-14 lg:mb-10 gap-4">
      <div>
        <h1 className="text-3xl md:text-2xl font-medium mb-1">{title}</h1>
        <p className="text-dgray-500">{description}</p>
      </div>
      <div className="flex gap-3">
        <Button small flat className="flex items-center gap-2" href="/profile">
          <EditLineIcon />
          Edit your profile
        </Button>
        {isUserHost(user) ? (
          <>
            <Button to="/create" small>
              Create new event
            </Button>
          </>
        ) : (
          <>
            <Button to="/events" small>
              Explore events
            </Button>
          </>
        )}
      </div>
    </header>
  );
}

export default connect(({ auth }) => ({
  user: auth.user,
}))(DashboardHeader);
