import React from "react";
import Spinner from "views/Spinner";
import NoRecord from "views/NoRecord";
import Mentor from "views/Front/Mentor";

const Mentors = ({ mentorsList, loading }) => {
  return loading ? (
    <Spinner />
  ) : mentorsList.length > 0 ? (
    <div className="row pb-5">
      <div className="col-md-12">
        <div className="all-courses row">
          {mentorsList.map((user, index) => {
            return (
              <div key={index} className="col-xl-4 col-lg-6 col-md-6 mb-3">
                <Mentor user={user} page={"mentorsList"}/>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : (
    <NoRecord />
  );
};

export default Mentors;
