import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import MentorPaymentHistory from "views/Front/Order/MentorPaymentHistory";
import LearnerPaymentHistory from "views/Front/Order/LearnerPaymentHistory";
import NoRecord from "views/NoRecord";
import { CSVLink } from "react-csv";
import * as moment from 'moment-timezone';
import { SESSION_TYPE } from "constants/index";
import { RECORD_PER_PAGE } from "constants/index";
import {
  getMyOrders,
  currentUser,
  getMentorsOrders,
} from "actions/front/myaccount";
import { getCurrentTimeZone } from "utils/time";
import Statistics from "./Statistics";

const PaymentHistory = ({
  errorList,
  getMyOrders,
  auth: { user },
  currentUser,
  getMentorsOrders,
}) => {
  const [mentorOrderList, setMentorOrderList] = useState([]);
  const [learnerOrderList, setLearnerOrderList] = useState([]);
  const [learnersRecord, setDownloadLearnersRecord] = useState([]);
  const [mentorRecord, setDownloadMentorRecord] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [mentorTotalRecords, setMentorTotalRecords] = useState(0);

  const [activeTab, setActiveTab] = useState(1);

  const [filters, setFilters] = useState({
    limit: RECORD_PER_PAGE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    custom_filters: "",
  });

  const [mentorFilters, setMentorFilters] = useState({
    limit: RECORD_PER_PAGE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    custom_filters: "",
  });

  const paginate = (page_no) => {
    setFilters({ ...filters, page: page_no });
  };

  const mentorPaginate = (page_no) => {
    setMentorFilters({ ...mentorFilters, page: page_no });
  };

  let timeZone = getCurrentTimeZone(user);

  useEffect(() => {
    getMyOrders(filters).then((res) => {
      if (res.status) {
        setTotalRecords(res.total);
        setLearnerOrderList(res.data);
      }
    });
  }, [filters.page]);

  useEffect(() => {
    getMentorsOrders(filters).then((res) => {
      if (res.status) {
        setMentorOrderList(res.data.data);
        setMentorTotalRecords(res.data.metadata[0]?.totalRecord);
      }
    });
  }, [mentorFilters.page]);

  useEffect(() => {
    // CSV Download Objects Mentor
    const csvObjArr = [];
    mentorOrderList.map((item) => {
      item.session_data.every((itr, index) => {
        let obj = {};

        if (item.session_type === SESSION_TYPE.GENERIC) {
          obj = genericCsvObjectMentor(false, item);
          csvObjArr.push(obj);
          return false;
        } else if (index === 0) {
          obj = mentorCsvObject(false, item, itr);
        } else {
          obj = mentorCsvObject(true, item, itr);
        }
        csvObjArr.push(obj);
      });
    });
    setDownloadMentorRecord(csvObjArr);
  }, [mentorOrderList]);

  useEffect(() => {
    // CSV Download Objects Learner
    const csvObjArr = [];
    learnerOrderList.map((item) => {
      item.session_data.every((itr, index) => {
        let obj = {};

        if (item.session_type === SESSION_TYPE.GENERIC) {
          obj = genericCsvObject(false, item);
          csvObjArr.push(obj);
          return false;
        } else if (index === 0) {
          obj = csvObject(false, item, itr);
        } else {
          obj = csvObject(true, item, itr);
        }
        csvObjArr.push(obj);
      });
    });
    setDownloadLearnersRecord(csvObjArr);
  }, [learnerOrderList]);

  const genericCsvObjectMentor = (key, item) => {
    let obj = {};
    obj.course_name = key ? "" : item.course_name;
    obj.student_username = key ? "" : item.student_username;
    obj.Total = key ? "" : item.order_total;
    obj.net_amount = obj.net_amount ? "" : item.net_amount;
    obj.paid_refund_amount = key ? "N/A" : item.paid_refund_amount;
    obj.startDate = moment
      .unix(item.session_data[0].start_at)
      .tz(timeZone)
      .format("DD MMM, YYYY HH:mm A");
    obj.endDate = moment
      .unix(item.session_data[item.session_data.length - 1].end_at)
      .tz(timeZone)
      .format("DD MMM, YYYY HH:mm A");
    obj.commission = item.commission;
    obj.booked_on = moment
      .unix(item.booked_on)
      .tz(timeZone)
      .format("DD MMM, YYYY");
    obj.order_status =
      item.order_status === 1
        ? "Not Paid"
        : item.order_status === 2
        ? "Paid"
        : "Refunded";
    return obj;
  };

  const genericCsvObject = (key, item) => {
    let obj = {};
    obj.course_name = key ? "" : item.course_name;
    obj.mentor = key ? "" : item.mentor_name;
    obj.Total = key ? "" : item.course_price;
    obj.refund_amount = key ? "N/A" : item.refund_amount;
    obj.startDate = moment
      .unix(item.session_data[0].start_at)
      .tz(timeZone)
      .format("DD MMM, YYYY HH:mm A");
    obj.endDate = moment
      .unix(item.session_data[item.session_data.length - 1].end_at)
      .tz(timeZone)
      .format("DD MMM, YYYY HH:mm A");
    obj.total = key ? "" : item.course_price;
    obj.booked_on = moment
      .unix(item.booked_on)
      .tz(timeZone)
      .format("DD MMM, YYYY");
    obj.payemtStatus = key
      ? ""
      : item.order_booking_status === 1
      ? item.order_status === 1
        ? "Pending"
        : item.order_status === 2
        ? "Success"
        : item.order_status === 3
        ? "Fail"
        : item.order_status === 4 && "Free"
      : "Refunded";
    return obj;
  };

  const mentorCsvObject = (key, item, itr) => {
    let obj = {};

    obj.course_name = key ? "" : item.course_name;
    obj.student_username = key ? "" : item.student_username;
    obj.Total = key ? "" : item.order_total;
    obj.net_amount = obj.net_amount ? "" : item.net_amount;
    obj.paid_refund_amount = key ? "N/A" : item.paid_refund_amount;
    obj.startDate = moment
      .unix(itr.start_at)
      .tz(timeZone)
      .format("DD MMM, YYYY HH:mm A");
    obj.endDate = moment
      .unix(itr.end_at)
      .tz(timeZone)
      .format("DD MMM, YYYY HH:mm A");
    obj.booked_on = moment
      .unix(item.booked_on)
      .tz(timeZone)
      .format("DD MMM, YYYY");
    obj.commission = item.commission;
    obj.order_status =
      item.order_status === 1
        ? "Not Paid"
        : item.order_status === 2
        ? "Paid"
        : "Refunded";
    return obj;
  };

  const csvObject = (key, item, itr) => {
    let obj = {};
    obj.course_name = key ? "" : item.course_name;
    obj.mentor = key ? "" : item.mentor_name;
    obj.Total = key ? "" : item.course_price;
    obj.refund_amount = key ? "N/A" : item.refund_amount;
    obj.startDate = moment
      .unix(itr.start_at)
      .tz(timeZone)
      .format("DD MMM, YYYY HH:mm A");
    obj.endDate = moment
      .unix(itr.end_at)
      .tz(timeZone)
      .format("DD MMM, YYYY HH:mm A");
    obj.total = key ? "" : item.course_price;
    obj.booked_on = moment
      .unix(item.booked_on)
      .tz(timeZone)
      .format("DD MMM, YYYY");
    obj.payemtStatus = key
      ? ""
      : item.order_booking_status === 1
      ? item.order_status === 1
        ? "Pending"
        : item.order_status === 2
        ? "Success"
        : item.order_status === 3
        ? "Fail"
        : item.order_status === 4 && "Free"
      : "Refunded";
    return obj;
  };

  const CSVHeaders = [
    { label: "Course Name", key: "course_name" },
    { label: "Mentor Name", key: "mentor" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" },
    { label: "Total Amount", key: "total" },
    { label: "Refund Amount", key: "refund_amount" },
    { label: "Booked Date", key: "booked_on" },
    { label: "Payment Status", key: "payemtStatus" },
  ];

  const CSVMentorHeaders = [
    { label: "Session Name", key: "course_name" },
    { label: "Learner Username", key: "student_username" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" },
    { label: "Total", key: "Total" },
    { label: "Commission", key: "commission" },
    { label: "Net Earned", key: "net_amount" },
    { label: "Refund Amouunt", key: "paid_refund_amount" },
    { label: "Booked Date", key: "booked_on" },
    { label: "Payment Status", key: "order_status" },
  ];

  return learnerOrderList != null ? (
    <div className="col-lg-9">
      <Link to="/dashboard">Back to Calendar</Link>
      <Statistics />
      <div className="row pb-5" id="paymentHistory">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 1 && "active"}`}
              id="spending-tab"
              onClick={(e) => setActiveTab(1)}
            >
              Spending History
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 2 && "active"}`}
              id="earning-tab"
              onClick={(e) => setActiveTab(2)}
            >
              Earning History
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          {activeTab === 1 ? (
            <>
              {learnersRecord.length ? (
                <div className="col-md-12">
                  <hr />
                  <strong>
                    Note:{" "}
                    <span>
                      All payment amounts are in{" "}
                      <span className="text-orange">US dollars.</span> In the
                      future, DingDingNow will have payment capabilities in
                      other currencies
                    </span>
                  </strong>
                  <a href="#" className="download-xls">
                    <CSVLink
                      className="fa fa-file-excel-o text-orange "
                      data={learnersRecord}
                      headers={CSVHeaders}
                      filename={`payment-history ${Date.now()}.csv`}
                    >
                      {" "}
                      Learner Payment Download
                    </CSVLink>
                  </a>
                </div>
              ) : null}
              <LearnerPaymentHistory
                learnerOrderList={learnerOrderList}
                paginate={paginate}
                totalRecords={totalRecords}
                filters={filters}
              />
            </>
          ) : null}
          {activeTab === 2 ? (
            <>
              {mentorRecord.length ? (
                <div className="col-md-12">
                  <hr />
                  <a href="#" className="download-xls">
                    <CSVLink
                      className="fa fa-file-excel-o text-orange "
                      data={mentorRecord}
                      headers={CSVMentorHeaders}
                      filename={`payment-history-mentor ${Date.now()}.csv`}
                    >
                      {" "}
                      Mentor Payment Download
                    </CSVLink>
                  </a>
                </div>
              ) : null}
              <MentorPaymentHistory
                mentorOrderList={mentorOrderList}
                user={user}
                paginate={mentorPaginate}
                totalRecords={mentorTotalRecords}
                filters={mentorFilters}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <NoRecord />
  );
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  user: state.myaccount.user,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getMyOrders,
  currentUser,
  getMentorsOrders,
})(withRouter(PaymentHistory));
