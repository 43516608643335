import {
  CMS_CREATED,
  CMS_ERROR,
  DELETE_CMS,
  CMS_UPDATED,
  CMS_LIST_UPDATED,
  GET_CMS_BY_ID,
  CHANGE_CMS_STATUS,
  LOADING_ON_CMS_SUBMIT,
  CMS_SEARCH_PARAMATERS_UPDATE,
} from "actions/types";
import * as Constants from "constants/index";

const initalState = {
  cmsList: {
    page: 1,
    data: [],
    count: 0
  },
  currentCMS: [],
  loading: true,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: ""
  }
};

export default function(state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case CMS_CREATED:
      return {
        ...state,
        loading: false
      };
    case CMS_UPDATED:
      return {
        ...state,
        currentCMS: payload,
        sortingParams: initalState.sortingParams,
        loading: false
      };
    case CMS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case DELETE_CMS:
      const currentCount = state.cmsList.count;
      const currentLimit = initalState.sortingParams.limit;
      return {
        ...state,
        cmsList: {
          data: state.cmsList.data.filter(cms => cms._id !== payload),
          count: currentCount - 1,
          page: Math.ceil((currentCount - 1)/currentLimit),
        },
        sortingParams: initalState.sortingParams,
        loading: false
      };
    case GET_CMS_BY_ID:
      return {
        ...state,
        currentCMS: payload,
        loading: false
      };
    case CMS_LIST_UPDATED:
      return {
        ...state,
        cmsList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord
        },
        loading: false
      };
    case CMS_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload }
      };
    case CHANGE_CMS_STATUS:
      return {
        ...state,
        cmsList: {
          ...state.cmsList,
          data: state.cmsList.data.map(cms =>
            cms._id === payload._id ? { ...cms, status: payload.status } : cms
          )
        }
      };
      case LOADING_ON_CMS_SUBMIT: 
        return {
          ...state,
          loading: true
        };
      
    default:
      return state;
  }
}
