import { ReactComponent as BookOpenIcon } from "assets/images/icons/book-open.svg";
import { ReactComponent as StandIcon } from "assets/images/icons/stand.svg";
import { ReactComponent as Users1Icon } from "assets/images/icons/users-1.svg";
import { ReactComponent as Users2Icon } from "assets/images/icons/users-2.svg";
import CenterContainer from "components/common/Container/Center";
import IconCircle from "../IconCircle";

export default function HomePageExplained() {
  return (
    <CenterContainer className="text-center py-24">
      <div className="text-primary-600 font-semibold mb-3">
        DingDingNow Explained
      </div>
      <h2 className="font-semibold text-5xl md:text-3xl mb-16 md:mb-12">
        All in One English Practice Platform
      </h2>
      <div className="flex lg:flex-col justify-between gap-8">
        {items.map(({ icon, title, description }, i) => (
          <div key={i} className="flex flex-col items-center ">
            <IconCircle>{icon}</IconCircle>
            <div className="mt-5 mb-2 text-xl font-medium">{title}</div>
            <div className="text-dgray-500">{description}</div>
          </div>
        ))}
      </div>
    </CenterContainer>
  );
}

const items = [
  {
    icon: <BookOpenIcon />,
    title: "Daily Interesting Topics",
    description:
      "Engage with live hosts on various curated and diverse topics from DDN programs.",
  },
  {
    icon: <StandIcon />,
    title: "Specialized Programs",
    description:
      "Created to help you reach a certain goal. Whether it's passing an exam or improving your interviewing skills!",
  },
  {
    icon: <Users1Icon />,
    title: "Personalized One on One",
    description:
      "Connect in a 1-1 session based on your schedule and your desired learning goal.",
  },
  {
    icon: <Users2Icon />,
    title: "Social Networking",
    description:
      "Gain exposure to diverse ideas and network within a cohort-type setting.",
  },
];
