import { ErrorMessage } from "formik";

export default function InputError({ name, message }) {
  if (name)
    return (
      <ErrorMessage name={name}>
        {(msg) => <div className="text-xs pt-2 pl-0.5 text-red-500">{msg}</div>}
      </ErrorMessage>
    );
  return <div className="text-xs pt-2 pl-0.5 text-red-500">{message}</div>;
}
