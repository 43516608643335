import { getCourseDetailById } from "actions/front/common/course";
import { addSessionToCart } from "actions/front/learner/order";
import { menberProductList } from "actions/menber";
import { getCartCount } from "actions/front/myaccount";
import { ReactComponent as FacebookIcon } from "assets/images/icons/facebook.svg";
import { ReactComponent as GlobeIcon } from "assets/images/icons/globe.svg";
import { ReactComponent as HelpIcon } from "assets/images/icons/help-icon.svg";
import { ReactComponent as LinkIcon } from "assets/images/icons/link.svg";
import { ReactComponent as LinkedinIcon } from "assets/images/icons/linkedin.svg";
import { ReactComponent as TwitterIcon } from "assets/images/icons/twitter.svg";
import { ReactComponent as EditLineIcon } from "assets/images/icons/edit-line.svg";
import AccordionList from "components/common/AccordionList";
import Attendees from "components/common/Attendees";
import BreadCrumbs from "components/common/Breadcrumbs";
import Button from "components/common/Button";
import CenterContainer from "components/common/Container/Center";
import NestedPill from "components/common/NestedPill";
import Pill from "components/common/Pill";
import PaymentModal from "views/Front/Order/Cart/PaymentModal";
import MenberPicker from "../../ProfileCourseMgmt/Dashboard/Balance/MenberPicker";
import { getClosestSession } from "utils/course";
import {
  ATTENDEE_ROLE,
  CHIME_CLIENT_URL,
  PROFICIENCY_DESCRIPTION,
  PROFICIENCY_LEVELS,
  SESSION_TYPE,
} from "constants/index";
import getUserAvatar from "utils/getUserAvatar";
import {
  getShareLinkFacebook,
  getShareLinkLinkedin,
  getShareLinkTwitter,
} from "utils/socialShare";
import { getCurrentTimeZone, getTimeZone } from "utils/time";
import Header from "views/Front/Layout/Header";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import getTimezoneOffset from "components/common/TimezoneToGMT";
import Modal from "components/common/Modal";
import HandleModal from "components/common/Modal/handle";
import LargeModal from "components/common/Modal/LargeModal";
import SelectInput from "components/common/form/SelectInput";
import DatePicker from "components/common/form/DatePicker";
import getEventImage from "utils/getEventImage";
import { Link } from "react-router-dom";
import { SanitizeHTML } from "components/common/SanitizeHtml";
import InfoModal from "components/common/Modal/Info";
import debounce from "lodash.debounce";
import { loadUserLastInfo } from "actions/auth";
const Detail = ({
  getCourseDetailById,
  match,
  user,
  token,
  history,
  addSessionToCart,
  getCartCount,
  menberProductList,
  loadUserLastInfo,
}) => {
  const [courseDetail, setCourseDetail] = useState(null);
  const [liveEvent, setLiveEvent] = useState(null);
  let timeZone = getCurrentTimeZone(user);
  let gmt = getTimezoneOffset(timeZone);
  const describeList = [
    [
      "* Unlimited access to public event as a Speaker",
      "* However , your monthly pass will be suspended if you have 3 times no show",
      "* Make sure cancel your speaker seat prior to event start time to avoid no show penalty",
    ],
    [
      "* You can attend four public events as a speaker",
      "* Your event will be consumed even if you don't show up without cancelling",
      "* No expiration date for the four events",
    ],
    [
      "* You can attend one public event",
      "* Your event will be consumed even if you don't show up without cancelling",
      "* No expiration date for the event pass",
    ],
  ];
  /********************Use effect to get current course detail*****************/
  const [loading, setLoading] = useState(false);
  const [closestSession, setClosestSession] = useState();
  let getNewCourseDetailById = (course_id) => {
    if (course_id)
      getCourseDetailById(course_id).then((res) => {
        if (res.status) {
          setCourseDetail(res.data);
          const course = { ...res.data };
          course.session = course?.session_detail;
          course.session.forEach((s) => {
            s.session = s.session_group;
          });

          setClosestSession(getClosestSession(course));

          if (loading) {
            setIsModalOpen(true);
          }
          setLoading(false);
          if (res.data.course_type === SESSION_TYPE.LIVE_EVENT) {
            try {
              if (
                res.data.session_detail[0].session_group
                  .all_session_with_recurring[0]
              ) {
                const event =
                  res.data.session_detail[0].session_group
                    .all_session_with_recurring[0];
                event.session_id =
                  res.data.session_detail[0].session_group.sessions[0]._id;
                setLiveEvent(event);
              }
            } finally {
            }
          }
        }
      });
  };
  useMemo(() => {
    getNewCourseDetailById(match.params.course_id);
  }, [match.params.course_id]);

  let onGetNewCourseDetailClick = () => {
    setLoading(true);
    console.log("================onGetNewCourseDetailClick");
  };
  useEffect(() => {
    if (loading) {
      getNewCourseDetailById(match.params.course_id);
    }
  }, [loading]);

  const [isIndividualSpecific, setIsIndividualSpecific] = useState("");

  /********************in form submit, to schedule *****************/
  const [sendSessionData, setSendSessionData] = useState(null);
  const [errorSuccess, setErrorSuccess] = useState({ isError: null, msg: "" });
  const [scheduleLoader, setScheduleLoader] = useState(false);
  const [resetData, setResetData] = useState(null);

  /*****************  用户无会员 **********************/
  const [isPremium, setIsPremium] = useState(false);
  const [menberList, setMenberList] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [isPayMentModel, setIsPayMentModel] = useState(false);
  const [notPremiumContent, setNotPremiumContent] = useState(
    "Registering as a speaker requires purchase"
  );
  const subscribe = async () => {
    setClientSecret(null);
    let response = await menberProductList();
    if (!response?.status) return;
    response?.data?.member_list.map((item, index) => {
      item.describe = describeList[index];
    });
    setMenberList(response?.data?.member_list);
    setIsPremium(false);
    setIsPayMentModel(true);
  };
  const isFormTouched = (e) => {
    e.preventDefault();
    setErrorSuccess({ isError: null, msg: "" });
  };
  useEffect(() => {
    if (user?.userType == 2) {
      loadUserLastInfo();
      console.log("============session");
    }
  }, []);
  useEffect(() => {
    if (liveEvent) {
      const liveEventScheduleData = {
        groupId: courseDetail.session_detail[0].session_group._id,
        selectedSessions: [liveEvent],
      };
      setSendSessionData(liveEventScheduleData);
    }
  }, [liveEvent]);

  const [isClickable, setClickable] = useState(true);
  const handleNotPreium = (isSpeaker, res) => {
    if (isSpeaker && user.userType == 2) {
      setClickable(true);
      setIsPremium(true);
      setNotPremiumContent(res.msg);
    }
  };
  const onScheduleClick = debounce(
    (e, selectedSession = null, isSpeaker = false) => {
      if (isClickable) {
        setClickable(false);
        e?.preventDefault();
        if (sendSessionData === null && !selectedSession) {
          setErrorSuccess({
            isError: true,
            msg: "Please select at least one session.",
            cartBtn: false,
          });
          setTimeout(() => setErrorSuccess({ isError: null, msg: "" }), 10000);
        } else if (!user) {
          history.push(`/login?redirect=${window.location.pathname}`, {
            from: "Purchase Course",
          });
        } else {
          // // speaker 是否是会员
          setScheduleLoader(true);
          addSessionToCart(courseDetail._id, {
            ...(selectedSession ? selectedSession : sendSessionData),
            learnerRole: isSpeaker
              ? ATTENDEE_ROLE.SPEAKER
              : ATTENDEE_ROLE.ATTENDEE,
          }).then((res) => {
            if (!res.status) {
              switch (res.type) {
                case "Free":
                  handleNotPreium(isSpeaker, res);
                  break;
                case "None":
                  handleNotPreium(isSpeaker, res);
                  break;
                case "Monthly":
                  handleNotPreium(isSpeaker, res);
                case "Exceed":
                  setErrorSuccess({
                    isError: true,
                    msg: res.msg,
                    cartBtn: false,
                  });
                default:
                  setErrorSuccess({
                    isError: true,
                    msg: res.msg,
                    cartBtn: false,
                  });
              }
              setScheduleLoader(false);

              setTimeout(
                () => setErrorSuccess({ isError: null, msg: "" }),
                5000
              );
            } else {
              setErrorSuccess({
                isError: false,
                msg: res.msg,
                cartBtn: true,
              });
              if (isIndividualExist && !isGroupExist) {
                setIsIndividualSpecific(1);
              } else if (!isIndividualExist && isGroupExist) {
                setIsIndividualSpecific(2);
              }
              setResetData(true);
              setScheduleLoader(false);
              getCartCount();
              history.push("/cart");
              // setTimeout(() => setErrorSuccess({ isError: null, msg: "" }), 10000);
            }
          });
        }
        setClickable(true);
      }
    },
    300
  );

  /********************check if no session exist in individual or group if type is SPECIFIC_BOTH *****************/
  const [isIndividualExist, setIsIndividualExist] = useState(false);
  const [isGroupExist, setIsGroupExist] = useState(false);

  useEffect(() => {
    if (
      courseDetail !== null &&
      courseDetail.course_type === SESSION_TYPE.SPECIFIC_BOTH
    ) {
      if (isIndividualExist && !isGroupExist) {
        setIsIndividualSpecific(1);
      }
      if (!isIndividualExist && isGroupExist) {
        setIsIndividualSpecific(2);
      }
    }
  }, [isIndividualExist, isGroupExist, courseDetail]);

  const isAudienceRegisterDisabled =
    courseDetail?.course_type === SESSION_TYPE.GENERIC
      ? /*? courseDetail?.session_detail[0]?.session_group
          .all_session_with_recurring[0].session_booking_count === 1
        ? true
        : false*/
        false
      : courseDetail?.session_detail[0]?.max_students === 0
      ? false
      : courseDetail?.session_detail[0]?.max_students >
        courseDetail?.session_detail[0]?.session_group
          .all_session_with_recurring[0].session_booking_count
      ? false
      : true;

  const isSpeakerRegisterDisabled =
    courseDetail?.course_type === SESSION_TYPE.GENERIC
      ? false
      : courseDetail?.session_detail[0]?.max_speakers === 0
      ? false
      : courseDetail?.session_detail[0]?.max_speakers >
        courseDetail?.session_detail[0]?.session_group
          .all_session_with_recurring[0].session_booking_speaker_count
      ? false
      : true;
  const isHosting = courseDetail?.mentor_id === user?.id;
  const isCoHosting = courseDetail?.cohosts?.includes(user?.email);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getPriceForSession = (courseDetail) => {
    let price = 0;
    const durations = courseDetail?.session_detail[0].duration_info.filter(
      (i) => i.duration_per_session !== 15
    );
    const hourLongSession = durations.find(
      (i) => i.duration_per_session === 60
    );
    if (hourLongSession && hourLongSession.price_per_session > 0) {
      return `$ ${hourLongSession.price_per_session?.toFixed(2)}`;
    }
    if (durations.length === 0) {
      return "Free";
    }
    const sorted = durations.sort((i1, i2) =>
      i1.duration_per_session === i2.duration_per_session
        ? 0
        : i1.duration_per_session > i2.duration_per_session
        ? 1
        : -1
    );
    if (sorted[0].price_per_session === 0) {
      return "Free";
    }
    return `$ ${sorted[0].price_per_session?.toFixed(2)}`;
  };

  return (
    <>
      {courseDetail && (
        <PickSessionModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          courseDetail={courseDetail}
          onSubmit={(session) => {
            onScheduleClick(null, session);
          }}
          timeZone={timeZone}
        />
      )}
      <Header></Header>
      {courseDetail && (
        <CenterContainer>
          <div className="justify-center text-center pb-8">
            <BreadCrumbs
              crumbs={[
                { label: "Home", href: "/" },
                { label: "Events", href: "/events" },
                {
                  label: `${courseDetail.name}`,
                  href: `/session/${courseDetail._id}`,
                },
              ]}
            />
          </div>
          <div className="text-center flex flex-row md:flex-col-reverse md:items-stretch gap-16">
            <div className="flex flex-1 text-center">
              <div className="text-left max-w-xl flex-1 lg:mx-auto bg-white">
                <div className="flex justify-between items-center mb-4 text-dgray-900">
                  {<Pill text={courseDetail.category_name} color="blue" />}
                  <NestedPill
                    innerText={
                      courseDetail.course_type === SESSION_TYPE.LIVE_EVENT
                        ? "Public event"
                        : "Private event"
                    }
                    // outerText={`${courseDetail.speakerCount} Speaker Seats`}
                  />
                </div>

                <div className="text-5xl md:text-lg font-semibold mb-8">
                  {courseDetail.name}
                </div>

                <div className="flex justify-between mb-4">
                  <div className="flex gap-2.5">
                    <img
                      src={getUserAvatar(courseDetail?.mentor_avatar)}
                      alt="host avatar"
                      className="rounded-full w-10 h-10"
                    />
                    <div>
                      <label className="text-base text-dgray-500 md:hidden">
                        hosted by
                      </label>
                      <Link
                        to={`/hosts/${courseDetail.mentor_username}`}
                        className="block text-sm font-medium"
                      >
                        {courseDetail.mentor_name}
                      </Link>
                      <div className="text-xs text-dgray-500">
                        <GlobeIcon className="inline mr-1" />
                        {courseDetail.host
                          ? courseDetail.host?.country
                          : courseDetail.country}
                      </div>
                    </div>
                  </div>
                  <div>
                    <Attendees
                      count={
                        courseDetail?.session_detail[0].session_group
                          ?.all_session_with_recurring[0]?.session_booking_count
                      }
                    />
                  </div>
                </div>
                <div className="text-sm pt-4 mt-4 md:hidden mb-2">
                  <div className="flex justify-between">
                    <div className="font-semibold text-2xl md:text-sm">
                      {closestSession &&
                        moment
                          .unix(closestSession?.start_at)
                          .format("MMM D, YYYY")}
                      {/* {courseDetail.course_type===SESSION_TYPE.GENERIC?
                        moment.unix(
                          closestSession?.start_at
                        )
                        .format("MMM D, YYYY"):
                        moment.unix(
                          courseDetail?.session_detail[0].session_group
                            .all_session_with_recurring[0].start_at
                        )
                        .format("MMM D, YYYY")} */}
                    </div>
                  </div>
                  <div className="text-xl md:text-sm mt-1">
                    <span className="font-semibold">
                      {closestSession && (
                        <>
                          {moment
                            .unix(closestSession?.start_at)
                            .format("HH:mm")}
                          {" - "}
                          {moment.unix(closestSession?.end_at).format("HH:mm")}
                        </>
                      )}
                      {/* {courseDetail.course_type === SESSION_TYPE.GENERIC ? (
                        <>
                          {moment
                            .unix(
                              closestSession?.start_at
                            )
                            .format("HH:mm")}
                          {" - "}
                          {moment
                            .unix(
                              closestSession?.end_at
                            )
                            .format("HH:mm")}
                        </>
                      ) : (
                        <>
                          {moment
                            .unix(
                              courseDetail?.session_detail[0].session_group
                                .all_session_with_recurring[0].start_at
                            )
                            .format("HH:mm")}
                          {" - "}
                          {moment
                            .unix(
                              courseDetail?.session_detail[0].session_group
                                .all_session_with_recurring[0].start_at
                            )
                            .add(
                              courseDetail?.session_detail[0].duration_info[0]
                                .duration_per_session,
                              "minutes"
                            )
                            .format("HH:mm")}
                        </>
                      )} */}
                    </span>
                    {/* <span className="text-dgray-500 ml-4">
                      <i>
                        (GMT {gmt > 0 ? "+" : null}
                        {gmt})
                      </i>
                    </span> */}
                  </div>
                  {courseDetail.course_type === SESSION_TYPE.GENERIC ? (
                    <div className="text-xl md:text-sm">
                      <span className="font-medium">
                        Price: {getPriceForSession(courseDetail)}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="text-sm pt-4 mt-4 hidden md:block">
                  <div className="flex justify-between">
                    <div className="font-semibold text-2xl md:text-sm">
                      {moment
                        .unix(
                          courseDetail?.session_detail[0].session_group
                            .all_session_with_recurring[0].start_at
                        )
                        .tz(timeZone)
                        .format("MMM D, HH:mm")}
                    </div>
                  </div>
                </div>
                {/* Register modle */}
                {courseDetail && (isHosting || isCoHosting) ? null : (
                  <div className="border-t flex flex-wrap">
                    {courseDetail.course_type === SESSION_TYPE.LIVE_EVENT ? (
                      <>
                        <div className="mr-4 mt-4">
                          {/* 判断host是否开会议 */}
                          {courseDetail?.meeting_id ? (
                            <Button
                              small
                              secondary
                              target="_blank"
                              href={`${CHIME_CLIENT_URL}join/${courseDetail?.meeting_id}?token=${token}`}
                            >
                              Join the event
                            </Button>
                          ) : (
                            <Button
                              small
                              loading={!isClickable}
                              secondary
                              {...(isAudienceRegisterDisabled
                                ? {
                                    "data-tip": true,
                                    "data-for": "help-popup",
                                    nonClicking: true,
                                  }
                                : {
                                    nonClicking: false,
                                    onClick: (e) =>
                                      onScheduleClick(e, null, false),
                                  })}
                            >
                              Register as Listener
                            </Button>
                          )}
                        </div>
                        <ReactTooltip
                          id="help-popup"
                          aria-haspopup="true"
                          place="bottom"
                          effect="solid"
                          type="light"
                          className="shadow-lg"
                        >
                          <p className="text-dgray-700 text-xs">
                            Listener seats are fully booked.
                            <br /> Please look for upcoming events.
                          </p>
                        </ReactTooltip>

                        <div className="mr-4 mt-4 flex items-center md:flex-col gap-3">
                          <Button
                            small
                            loading={!isClickable}
                            {...(isSpeakerRegisterDisabled &&
                            user?.userType !== 1
                              ? {
                                  "data-tip": true,
                                  "data-for": "speaker-popup",
                                  nonClicking: true,
                                }
                              : {
                                  nonClicking: false,
                                  onClick: (e) =>
                                    onScheduleClick(e, null, true),
                                })}
                          >
                            Register as Speaker
                          </Button>
                          <ReactTooltip
                            id="speaker-popup"
                            aria-haspopup="true"
                            place="bottom"
                            effect="solid"
                            type="light"
                            className="shadow-lg"
                          >
                            <p className="text-dgray-700 text-xs">
                              Speaker seats are fully booked.
                              <br /> Please check for listener seats.
                            </p>
                          </ReactTooltip>

                          {courseDetail?.session_detail[0]?.max_speakers -
                            courseDetail?.session_detail[0]?.session_group
                              .all_session_with_recurring[0]
                              .session_booking_speaker_count >
                          0 ? (
                            <div className="text-sm text-primary-500 justify-center">
                              *
                              {courseDetail?.session_detail[0]?.max_speakers -
                                courseDetail?.session_detail[0]?.session_group
                                  .all_session_with_recurring[0]
                                  .session_booking_speaker_count}{" "}
                              seats remaining
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : (
                      <div className="mr-4 mt-4">
                        <Button
                          small
                          secondary
                          disabled={isAudienceRegisterDisabled}
                          onClick={() => onGetNewCourseDetailClick()}
                          loading={loading}
                        >
                          Book an Appointment
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="flex-1 sm:-mx-4">
              <img
                src={getEventImage(courseDetail)}
                alt=""
                className="h-96 xl:h-72 md:h-60 w-full object-cover mb-4"
              />
            </div>
          </div>
          <div className="pt-32 md:pt-16">
            <div className="">
              <h1 className="text-4xl md:text-3xl text-dgray-900 font-semibold">
                Learn More About the Event
              </h1>
            </div>
            <div className="flex flex-row lg:flex-col mt-12">
              <div className="w-2/3 lg:w-full">
                <SanitizeHTML
                  className="max-character mb-0 text-lg text-dgray-500 whitespace-pre-line"
                  html={courseDetail.overview ? courseDetail.overview : "-"}
                />
                <div className="bg-dgray-200 rounded-2xl p-8 lg:px-4 mt-16">
                  <label className="text-2xl text-primary-600 font-semibold">
                    Ideal for
                  </label>
                  <SanitizeHTML
                    className="max-character mb-0 text-lg text-dgray-500 whitespace-pre-line"
                    html={
                      courseDetail.ideal_for
                        ? courseDetail.ideal_for
                        : courseDetail.proficiency_level
                        ? PROFICIENCY_DESCRIPTION[
                            courseDetail.proficiency_level
                          ]
                        : "-"
                    }
                  />
                </div>
              </div>
              <div className="w-1/3 lg:w-full mt-0 lg:mt-4">
                <div className="flex flex-col space-y-4 pl-32 lg:pl-0 md:mt-16">
                  {courseDetail.proficiency_level && (
                    <>
                      <label className="text-primary-600 text-base font-semibold">
                        Proficiency level
                      </label>
                      <label className="text-dgray-500 text-base border-b pb-4">
                        {
                          PROFICIENCY_LEVELS.find(
                            (lvl) =>
                              lvl.value === courseDetail.proficiency_level
                          ).label
                        }
                      </label>
                    </>
                  )}
                  <div className="flex items-center justify-between pt-4">
                    <label className="text-primary-600 text-base font-semibold">
                      {courseDetail.course_type === SESSION_TYPE.LIVE_EVENT
                        ? "Public event"
                        : "Private event"}
                    </label>
                    {user?.id &&
                      courseDetail?.mentor_id &&
                      user?.id === courseDetail?.mentor_id && (
                        <Link
                          to={`/create/${
                            courseDetail?.course_type === SESSION_TYPE.GENERIC
                              ? "one-on-one"
                              : "event"
                          }?editId=${courseDetail._id}`}
                          className="text-sm text-primary-700 font-medium flex items-center gap-1 whitespace-nowrap"
                        >
                          Edit event
                          <EditLineIcon />
                        </Link>
                      )}
                  </div>
                  {courseDetail.course_type === SESSION_TYPE.LIVE_EVENT ? (
                    <>
                      <label className="text-dgray-500 text-base">
                        {
                          courseDetail.session_detail[0].session_group
                            .all_session_with_recurring[0].session_booking_count
                        }{" "}
                        listeners
                      </label>
                      <label className="text-dgray-500 text-base pb-4">
                        {
                          courseDetail.session_detail[0].session_group
                            .all_session_with_recurring[0]
                            .session_booking_speaker_count
                        }{" "}
                        speakers
                      </label>
                    </>
                  ) : (
                    <div className="text-dgray-500">
                      One On One Practice with the Mentor
                    </div>
                  )}
                  <label className="text-primary-600 text-base font-semibold border-t pt-8">
                    Share the event
                  </label>
                  <div className="flex gap-3">
                    <button
                      className="text-dgray-700 border py-2.5 px-3 rounded-lg border-dgray-300"
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                      }}
                    >
                      <LinkIcon />
                    </button>
                    {[
                      {
                        icon: <TwitterIcon />,
                        href: getShareLinkTwitter(courseDetail.name),
                      },
                      {
                        icon: <FacebookIcon />,
                        href: getShareLinkFacebook(courseDetail.name),
                      },
                      {
                        icon: <LinkedinIcon />,
                        href: getShareLinkLinkedin(courseDetail.name),
                      },
                    ].map(({ icon, href }, index) => (
                      <a
                        href={href}
                        key={index}
                        target="_blank"
                        rel="noreferrer"
                        className="p-2.5 border rounded-lg text-dgray-400"
                      >
                        {icon}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {courseDetail && (isHosting || isCoHosting) ? null : (
              <div className="bg-blue-800 text-center rounded-2xl p-16 lg:px-4 w-full mt-32 md:mt-8">
                <h3 className="text-white font-semibold text-4xl md:text-3xl mb-8 md:mb-4">
                  Reserve your seat for the event!
                </h3>
                <p className="text-dgray-100 mb-8 md:mb-4">
                  Register to event and reserve your seat.
                </p>
                <div className="flex flex-row md:flex-col-reverse text-center justify-center">
                  <Button
                    small
                    flat
                    className="text-dgray-700 mr-4 md:mr-0 md:mt-2"
                    disabled={isAudienceRegisterDisabled}
                    onClick={(e) => {
                      if (
                        courseDetail.course_type === SESSION_TYPE.LIVE_EVENT
                      ) {
                        onScheduleClick(e);
                      } else {
                        setIsModalOpen(true);
                      }
                    }}
                  >
                    {courseDetail.course_type === SESSION_TYPE.LIVE_EVENT
                      ? "Register as listener"
                      : "Book an appointment"}
                  </Button>
                  {courseDetail.course_type === SESSION_TYPE.LIVE_EVENT && (
                    <Button
                      small
                      disabled={isSpeakerRegisterDisabled}
                      onClick={(e) => onScheduleClick(e, null, true)}
                    >
                      Register as speaker
                    </Button>
                  )}
                </div>
              </div>
            )}
            <div className="p-12 md:p-0 md:mt-16 text-center mt-24 md:mt-8">
              <label className="text-4xl text-dgray-900 font-semibold">
                Frequently asked questions
              </label>
              <div className="max-w-3xl mx-auto text-left mt-12 md:mt-8">
                <AccordionList items={items} />
              </div>
            </div>
          </div>
          <Modal modalContent={{ title: "test" }} isOpen={false}></Modal>
          <InfoModal
            modalContent={{ title: "Error", content: errorSuccess.msg }}
            onClose={() => setErrorSuccess({ isError: null, msg: "" })}
            isOpen={errorSuccess.isError !== null}
          ></InfoModal>
          <HandleModal
            modalContent={{
              content: notPremiumContent,
            }}
            onClose={() => setIsPremium(false)}
            isOpen={isPremium}
            onbtn={subscribe}
          ></HandleModal>
          <div className="relative z-50">
            {!clientSecret ? (
              <MenberPicker
                setClientSecret={setClientSecret}
                isOpen={isPayMentModel}
                setOpen={setIsPayMentModel}
                getMenberPickInfo={menberList}
              />
            ) : (
              <PaymentModal
                isOpen={isPayMentModel}
                setOpen={setIsPayMentModel}
                clientSecret={clientSecret}
                select={true}
                course_id={match.params.course_id}
                title="选择产品进行支付"
              />
            )}
          </div>
        </CenterContainer>
      )}{" "}
    </>
  );
};

const items = [
  {
    title: "Does the event time my local time?",
    content:
      "Yes, the event time is automatically converted to your time zone based on your IP Address",
  },
  {
    title: "What is the difference between Speaker and Listener?",
    content: (
      <>
        <p>
          In order to be a <b>Speaker</b>, you need to subscribe to DDN.
          Speakers will be able to turn on mic and participate in the event.
        </p>
        <br />
        <b>Listener:</b> All users signup as an Listener member by default, and
        you are able to attend any event as an Listener for free. However, an
        Listener member is not able to turn on the mic to participate in the
        talk. Listener members are free to listen in any events as long as a
        host opts to open Listener seats.
      </>
    ),
  },
  {
    title: "How do I join the event? Is there a meeting ID?",
    content:
      "DDN has built-in audio and video capabilities. There is no meeting ID required. Just sign up for a role (either Speaker or Listener) and you can hop in the meeting after your host has activated the meeting room. Meeting entry can be found on your landing page after you sign in, or you can find it under MyDDN.",
  },
  {
    title: "Can I attend the meeting using my mobile?",
    content:
      "Yes, you can use your mobile to join the meeting. Make sure you log in to dingdingnow.com on a browser, NOT within any App. Although we do not have an App at this moment, DDN is mobile friendly. The DDN App is currently being planned.",
  },
  {
    title: "I live in China, do I need a VPN?",
    content:
      "No, DDN should be accessible from anywhere around the world. If you experience some connection instability, try to log out and log back in. We always recommend you to join the meeting on a laptop with a stable internet connection.",
  },
  {
    title: "What are the different proficiency levels?",
    content: (
      <>
        <p>
          Users can self-evaluate your spoken English proficiency level using
          the scale below. We highly recommend users select the right level
          events based on self-evaluation. DDN also offers sessions to evaluate
          your proficiency level for a fraction of the cost.
        </p>
        <br />
        <div class="overflow-x-auto relative border rounded-lg">
          <table class="w-full text-sm text-left">
            <thead class="text-xs text-dgray-500">
              <tr>
                <th scope="col" class="py-3 px-6">
                  Proficiency Level
                </th>
                <th scope="col" class="py-3 px-6">
                  IELTS
                </th>
                <th scope="col" class="py-3 px-6">
                  TOEFL
                </th>
                <th scope="col" class="py-3 px-6">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white border-b border-dgray-300">
                <th
                  scope="row"
                  class="py-4 px-6 font-medium text-dgray-900 whitespace-nowrap"
                >
                  <Pill text="A1 - Beginner" color="blue" />
                </th>
                <td class="py-4 px-6">N/A</td>
                <td class="py-4 px-6">N/A</td>
                <td class="py-4 px-6">
                  Student can understand about 50% of the conversation, however,
                  they can only reply with basic words, broken sentences and
                  speak slowly. Not much confidence at all.
                </td>
              </tr>
              <tr class="bg-white border-b border-dgray-300">
                <th
                  scope="row"
                  class="py-4 px-6 font-medium text-dgray-900 whitespace-nowrap"
                >
                  <Pill text="A2 - Elementary" color="blue" />
                </th>
                <td class="py-4 px-6">N/A</td>
                <td class="py-4 px-6">N/A</td>
                <td class="py-4 px-6">
                  Student can understand about 70%-80% of the conversation, and
                  they can take part in simple exchanges on familiar topics.
                  Student feels a little bit confident but{" "}
                  <b>struggles with fluent conversation</b>.
                </td>
              </tr>
              <tr class="bg-white border-b border-dgray-300">
                <th
                  scope="row"
                  class="py-4 px-6 font-medium text-dgray-900 whitespace-nowrap"
                >
                  <Pill text="B1 - Intermediate" color="indigo" />
                </th>
                <td class="py-4 px-6">N/A</td>
                <td class="py-4 px-6">N/A</td>
                <td class="py-4 px-6">
                  Student can understand 90%-95% of the conversation, but might
                  not be able to catch some advanced expressions. Student is
                  able to engage in a <b>simple conversation</b> and express
                  opinions using <b>basic grammar and vocabulary</b>.
                </td>
              </tr>
              <tr class="bg-white border-b border-dgray-300">
                <th
                  scope="row"
                  class="py-4 px-6 font-medium text-dgray-900 whitespace-nowrap"
                >
                  <Pill text="B2 - Upper Intermediate" color="indigo" />
                </th>
                <td class="py-4 px-0">5.5 - 6</td>
                <td class="py-4 px-0">71 - 90</td>
                <td class="py-4 px-6">
                  Student can understand the conversation. Student is able to
                  communicate with native speakers and express their ideas
                  fluently on <b>complex topics however</b> using{" "}
                  <b>basic grammar and vocabulary</b>.
                </td>
              </tr>
              <tr class="bg-white border-b border-dgray-300">
                <th
                  scope="row"
                  class="py-4 px-6 font-medium text-dgray-900 whitespace-nowrap"
                >
                  <Pill text="C1 - Advanced" color="purple" />
                </th>
                <td class="py-4 px-0">6.5 - 7.5</td>
                <td class="py-4 px-0">91 - 100</td>
                <td class="py-4 px-6">
                  Student is able to communicate with native speakers and
                  express their ideas fluently on <b>complex topics</b> using{" "}
                  <b>complex grammar</b> structure and{" "}
                  <b>advanced vocabulary</b>.
                </td>
              </tr>
              <tr class="bg-white border-b border-dgray-300">
                <th
                  scope="row"
                  class="py-4 px-6 font-medium text-dgray-900 whitespace-nowrap"
                >
                  <Pill text="C2 - Proficiency" color="purple" />
                </th>
                <td class="py-4 px-6">8 - 9</td>
                <td class="py-4 px-0">101 - 120</td>
                <td class="py-4 px-6">
                  Student is able to articulate their opinions in any context,
                  have strong logical thinking, and present as a polished
                  conversationalist as an ESL.
                </td>
              </tr>
              <tr class="bg-white border-b border-dgray-300">
                <th
                  scope="row"
                  class="py-4 px-6 font-medium text-dgray-900 whitespace-nowrap"
                >
                  <Pill text="D - Native" color="pink" />
                </th>
                <td class="py-4 px-6"></td>
                <td class="py-4 px-6"></td>
                <td class="py-4 px-6">
                  Or an ESL equivalent to Native Speaker
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    ),
  },
];
Detail.propTypes = {
  getCourseDetailById: PropTypes.func.isRequired,
  addSessionToCart: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  categories: state.categories,
});

export default connect(mapStateToProps, {
  getCourseDetailById,
  addSessionToCart,
  getCartCount,
  menberProductList,
  loadUserLastInfo,
})(Detail);

function PickSessionModal({
  isModalOpen,
  setIsModalOpen,
  courseDetail,
  onSubmit,
  timeZone,
}) {
  const [sessionDayToTime, setSessionDayToTime] = useState({});
  const [durationOptions, setDurationOptions] = useState([]);
  const [priceOptions, setPriceOptions] = useState({});
  const [selectedDuration, setSelectedDuration] = useState();
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedSessions, setSelectedSessions] = useState();

  useEffect(() => {
    if (!isModalOpen) return;
    console.log("=====================PickSessionModal", courseDetail);
    const allSessions = courseDetail.session_detail.map((session) => [
      session,
      session.session_group.all_session_with_recurring.filter(
        (i) => i.session_booking_count === 0
      ),
    ]);

    const newSessionDayToTime = {};

    // get all days
    allSessions.forEach(([, sess]) => {
      sess.forEach((s) => {
        if (moment.unix(s.start_at).tz(timeZone).valueOf() > Date.now()) {
          const day = moment.unix(s.start_at).tz(timeZone).format("YYYY-MM-DD");
          newSessionDayToTime[day] = [];
        }
      });
    });

    // pair the days with their sessions
    allSessions.forEach(([sessDetail, sess]) => {
      sess.forEach((s) => {
        if (moment.unix(s.start_at).tz(timeZone).valueOf() > Date.now()) {
          const sessionDate = moment.unix(s.start_at).tz(timeZone);
          const sessionDay = sessionDate.format("YYYY-MM-DD");
          const sessionHour = sessionDate.format("HH:mm");
          newSessionDayToTime[sessionDay].push([
            sessionHour,
            { sessionDetail: sessDetail, selectedSession: s },
          ]);
        }
      });
    });
    setSessionDayToTime(newSessionDayToTime);
  }, [isModalOpen]);

  useEffect(() => {
    if (!isModalOpen) return;
    const durs = courseDetail.session_detail[0].duration_info.map((dur) => ({
      label: `${dur.duration_per_session} minutes`,
      value: dur.duration_per_session,
    }));
    setDurationOptions(durs);
    let prices = {};
    courseDetail.session_detail[0].duration_info.map((item) => {
      prices[item.duration_per_session] = item.price_per_session;
    });
    setPriceOptions(prices);
    setSelectedDuration(durs[0].value);
  }, [isModalOpen]);

  useEffect(() => {
    if (!isModalOpen) return;
    const dates = [
      ...Object.keys(sessionDayToTime)
        .map((i) => moment(i, "YYYY-MM-DD"))
        .sort((d1, d2) => (d1.isAfter(d2) ? 1 : -1)),
    ].map((d) => d.format("YYYY-MM-DD"));
    if (dates.length > 0) {
      setSelectedDay(dates[0]);
    }
  }, [sessionDayToTime, isModalOpen]);

  return (
    <LargeModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
      <div className="text-lg text-center font-medium">Book an Appointment</div>
      <div className="space-y-4">
        <SelectInput
          label="Duration"
          options={durationOptions}
          onChange={({ value }) => setSelectedDuration(value)}
          defaultValue={durationOptions[0]}
        />
        <DatePicker
          label="Date"
          highlightDates={[
            {
              "react-datepicker__day--session-highlight": Object.keys(
                sessionDayToTime
              ).map((day) => {
                return moment(day, "YYYY-MM-DD").toDate();
              }),
            },
          ]}
          excludePast
          onSelect={(date) => {
            setSelectedDay(moment(date).format("YYYY-MM-DD"));
            setSelectedSessions(null);
          }}
          value={moment(selectedDay, "YYYY-MM-DD").toDate()}
        />
        {selectedDay && sessionDayToTime[selectedDay] && (
          <SelectInput
            key={selectedDay} // for rerendering to clear selection
            label="Time"
            options={sessionDayToTime[selectedDay].map((session) => ({
              label: session[0],
              value: session[1],
            }))}
            onChange={({ label, value }) => {
              const counts = selectedDuration / 15;
              const index = sessionDayToTime[selectedDay].findIndex(
                (i) => i[0] === label
              );
              let sessions = [];
              console.log(
                "===================",
                sessionDayToTime,
                selectedDay,
                label
              );
              for (let i = index; i < counts + index; i++) {
                const session = sessionDayToTime[selectedDay]?.[i]?.[1];
                sessions.push(session);
              }
              // 过滤不合理的
              let filterSessions = sessions.filter((item) => !!item);
              let session = [];
              let first = true;
              if (filterSessions.length > 1) {
                for (let i = 0; i < filterSessions.length; i++) {
                  let preSession =
                    filterSessions[i + 1]?.selectedSession?.start_at;
                  let curSession = filterSessions[i].selectedSession.end_at;
                  if (curSession == preSession && first) {
                    session.push(filterSessions[i]);
                  } else {
                    if (i == 0) {
                      session.push(filterSessions[i]);
                      first = false;
                    }
                    let preSession =
                      filterSessions[i - 1]?.selectedSession?.end_at;
                    let curSession = filterSessions[i].selectedSession.start_at;
                    if (preSession == curSession && first) {
                      session.push(filterSessions[i]);
                      first = false;
                    }
                  }
                }
              } else {
                session = filterSessions;
              }
              setSelectedSessions(session);
            }}
          />
        )}
        <div>
          <div className="text-sm text-dgray-700 font-medium inline-block mb-1.5 w-full">
            Price
          </div>
          <p className="text-dgray-700 text-lg">
            {priceOptions[selectedDuration] === 0
              ? "Free"
              : `$ ${priceOptions[selectedDuration]?.toFixed(2)}`}
          </p>
        </div>
        <div className="text-center">
          <Button
            onClick={() => {
              const data = {
                groupId: selectedSessions[0].sessionDetail.session_group._id,
                selectedSessions: selectedSessions.map(
                  (s) => s?.selectedSession
                ),
                duration: selectedDuration,
              };
              onSubmit(data);
            }}
            disabled={!(selectedSessions && selectedDuration)}
            small
          >
            Register
          </Button>
        </div>
      </div>
    </LargeModal>
  );
}
