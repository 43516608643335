import { Link } from "react-router-dom";
import MyListedCourse from "../CourseManagement/MyListedCourse";

const NewSessionOrEvent = () => {
  const RESULTS = {
    MY_LISTED_COURSES: 1,
    UPCOMING_SESSION: 2,
    COMPLETED_SESSION: 3,
    ALL: 4,
    DRAFT_COURSES: 5,
  };
  return (
    <div className="col-lg-9">
      <div className="row">
        <div className="col-md-12">
          <Link to="/dashboard">Back to Calendar</Link>
        </div>
        <div className="col-md-12">
          <h3 className="text-center">Offer Sessions or Host Public Events</h3>
        </div>
        <div className="col-md-8 offset-md-2 mt-4">
          <div className="row">
            <div className="col-md-6 text-center">
              <div className="row">
                <div className="col-md-12">
                  <Link
                    to="/dashboard/live-event"
                    className="btn btn-secondary"
                  >
                    Host Public Events
                  </Link>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-8 offset-md-2">
                  <small>
                    Host public live events that everyone can join and
                    socialize!
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center mt-xs-4 mt-md-0">
              <div className="row">
                <div className="col-md-12">
                  <Link to="/add-session" className="btn btn-secondary">
                    Manage Availability/Offer Sessions
                  </Link>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-8 offset-md-2">
                  <small>
                    Offer your availability for personalized 1-1 or group to
                    connect with people
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <h3 className="text-center mb-4">Your Listed Sessions and Events</h3>
          <MyListedCourse RESULTS={RESULTS} />
        </div>
      </div>
    </div>
  );
};

export default NewSessionOrEvent;
