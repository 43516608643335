import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CourseImage from "assets/images/mock-event.jpg";
import {
  SESSION_DURATION,
  SESSION_TYPE,
  SESSION_TYPE_REVERSE,
} from "constants/index";
import * as moment from 'moment-timezone';
import { getCurrentTimeZone } from "utils/time";

const CartItems = ({ order, removeFromCart, user }) => {

  let timeZone = getCurrentTimeZone(user);

  return (
    <div className="card-body">
      <div className="table-responsive bg-white">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Session</th>
              <th>Session Type</th>
              <th>Provided By</th>
              <th>Interactions Information</th>
              <th>Amount</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <thead>
            {order.map((item, index) => (
              <tr key={`cartItem_${index}`}>
                <td className="w-25">
                  <img
                    src={
                      item.course_image
                        ? process.env.REACT_APP_MEDIA_URL  + item.course_image
                        : CourseImage
                    }
                    alt=""
                    className="rounded  img-thumbnail"
                  />
                  <p className="mt-2">{item.course_name}</p>
                </td>
                <td>{SESSION_TYPE_REVERSE[item.order_for]}</td>
                <td>{item.educator_name}</td>
                <td>
                  <p className="mb-1">
                    <small>Date/Time:</small> <br />
                    {item.order_for === SESSION_TYPE.GENERIC ? (
                      <>
                        <span>
                          {moment
                            .unix(item.session_data[0].start_at)
                            .tz(timeZone)
                            .format("DD MMM, YYYY HH:mm A")}{" "}
                          -{" "}
                          {moment
                            .unix(
                              item.session_data[item.session_data.length - 1]
                                .end_at
                            )
                            .tz(timeZone)
                            .format("HH:mm A")}
                        </span>
                        <br />
                      </>
                    ) : (
                      item.session_data.map((session, index) => (
                        <React.Fragment key={`session_${index}`}>
                          <span>
                            {moment
                              .unix(session.start_at)
                              .tz(timeZone)
                              .format("DD MMM, YYYY HH:mm A")}{" "}
                              {session.end_at ? <>-{" "}
                            {moment
                              .unix(session.end_at)
                              .tz(timeZone)
                              .format("HH:mm A")}</>: null}

                          </span>
                          <br />
                        </React.Fragment>
                      ))
                    )}
                  </p>
                  <p className="mb-1">
                    {item.order_for !== SESSION_TYPE.LIVE_EVENT ?? (<>
                    {item.order_for == SESSION_TYPE.GENERIC ? (
                      <>
                        <small>Session Duration:</small> <br />
                        <span>{SESSION_DURATION[item.session_duration]}</span>
                      </>
                    ) : (
                      <>
                        <small>No. of Session:</small> <br />
                        <span>{item.session_data.length}</span>
                      </>
                    )}
                    </>)}
                  </p>
                </td>
                <td>${item.totalPriceOfSession}</td>
                <td>
                  <div className="cart-action">
                    <button
                      className="delete-cart-item"
                      onClick={(e) => {
                        if (
                          window.confirm(
                            `Are you sure you want to delete :\r ${item.course_name}?`
                          )
                        ) {
                          removeFromCart(item);
                        }
                      }}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </thead>
        </table>
      </div>
    </div>
  );
};

CartItems.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CartItems);
