import CenterContainer from "components/common/Container/Center";
import Header from "views/Front/Layout/Header";
import React from "react";
import Footer from "./Front/Layout/Footer";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";

export default function MeetingEnd() {
  return (
    <>
      <Header />
      <CenterContainer>
        <div className="text-center md:text-left pt-24 pb-12 xl:px-0 w-2/3 md:w-full md:px-4 mx-auto">
          <p className="text-primary-600 mb-6 text-base md:text-sm">Meeting</p>
          <h1 className="text-dgray-900 text-5xl md:text-4xl font-semibold mb-6">
            Meeting has ended.
          </h1>
          <p className="text-dgray-500 mb-12 text-xl md:text-lg">
            Explore the upcoming public & premium events and find the best event
            based on your learning goals.
          </p>
          <CenterContainer>
            <Link to="/explore" className="text-primary-700 mt-4 text-lg">
              Explore <ArrowRight className="inline ml-2" />
            </Link>
          </CenterContainer>
        </div>
      </CenterContainer>
      <Footer />
    </>
  );
}
