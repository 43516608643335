import hostImg from "assets/images/host-application.jpg";
import CenterContainer from "components/common/Container/Center";

export default function BecomeAHostApplicationProcess() {
  return (
    <div className="py-24">
      <CenterContainer className="text-center mb-16 lg:mb-12">
        <h2 className="text-center text-5xl lg:text-4xl md:text-3xl font-semibold mb-4">
          Host Application Process
        </h2>
        <p className="text-dgray-500">
          The application process is fairly straightforward. Please follow the
          steps to complete an online application.
        </p>
      </CenterContainer>
      <div className="flex lg:flex-col justify-between">
        <div className="basis-1/2 pl-64 2xl:pl-32 xl:pl-24 md:pl-10 pr-24 lg:mb-16">
          <ul className="space-y-8 border-l-2">
            {items.map(({ title, description }) => (
              <li className="pl-8">
                <div className="font-medium relative">
                  <div className="absolute -top-3.5 -left-[57px]">
                    <Disc />
                  </div>
                  {title}
                </div>
                <div className="text-dgray-500">{description}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="basis-1/2">
          <img
            src={hostImg}
            alt=""
            className="lg:px-4 lg:mx-auto w-full object-cover lg:h-[420px] sm:h-60"
          />
        </div>
      </div>
    </div>
  );
}

function Disc() {
  return (
    <div className="rounded-full border-4 border-primary-100 inline-block">
      <div className="rounded-full w-10 h-10 border-2 border-primary-600 flex justify-center items-center bg-primary-50">
        <div className="rounded-full w-3 h-3 bg-primary-600" />
      </div>
    </div>
  );
}

const items = [
  {
    title: "Basic Personal Information",
    description:
      "Name, location, a brief introduction of yourself. Don’t forget to upload a good picture of yourself.",
  },
  {
    title: "A Brief Evaluation",
    description:
      "Answer some questions to demonstrate you have the passion and patience to help our users to improve their English-speaking skills.",
  },
  {
    title: "Payout Setup",
    description:
      "At this moment, we only accept PayPal and Wechat payout to hosts. Provide PayPal information or Wechat code to get paid.",
  },
  {
    title: "Review and Confirmation",
    description:
      "It will take about 3-5 days to process your application. If we have additional questions, an interview will be followed.",
  },
  {
    title: "Get Approved and Start Hosting Events",
    description:
      "Once approved, you will be able to offer live events or one-on-one sessions.",
  },
];
