import React, { useState, useMemo, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import StarRatings from "react-star-ratings";
import Pagination from "views/Front/Pagination";
import { RECORD_PER_PAGE, SESSION_TYPE } from "constants/index";
import { getDraftAllCourses } from "actions/front/common/course";
import {
  changeStatus,
  deleteDraftCourse,
} from "actions/front/educator/courses";
import Spinner from "views/Spinner";
import NoRecord from "views/NoRecord";
import CourseImage from "assets/images/mock-event.jpg";
import * as moment from 'moment-timezone';
import EditImage from "assets/images/edit.svg";
import DeleteImage from "assets/images/delete.svg";
import { filterString } from "utils/sFold";
import { getCurrentTimeZone } from "utils/time";

const ListedDraftCourses = ({
  getDraftAllCourses,
  user_id,
  changeStatus,
  deleteDraftCourse,
  RESULTS,
  user,
}) => {
  const [draftCoursesList, setDraftCoursesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchText, setSearchText] = useState("");
  const activeResults = RESULTS.DRAFT_COURSES;

  const [filters, setFilters] = useState({
    limit: RECORD_PER_PAGE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    custom_filters: "",
  });

  let timeZone = getCurrentTimeZone(user);

  const paginate = (page_no) => {
    setFilters({ ...filters, page: page_no });
  };

  const onClickSearchText = (e) => {
    e.preventDefault();
    setFilters({ ...filters, query: searchText });
  };

  useEffect(() => {
    setLoading(true);
    getDraftAllCourses(filters, user_id, "", activeResults, 1).then((res) => {
      if (res.status) {
        console.log(res.data.data);
        setDraftCoursesList(res.data.data);
        setTotalRecords(res.data.metadata[0].totalRecord);
      }
      setLoading(false);
    });
  }, [filters, activeResults]);

  const onDeleteClick = (e, course_id) => {
    if (window.confirm(`Are you sure you want to delete?`)) {
      deleteDraftCourse(course_id).then((res) => {
        if (res.status) {
          let updated_course_list = draftCoursesList.filter(
            (course) => course._id !== course_id
          );
          console.log(res.status);
          setDraftCoursesList(updated_course_list);
        } else {
          alert(res.msg);
        }
      });
    }
  };

  return (
    <div
      className="tab-pane fade show active"
      id="listed"
      role="tabpanel"
      aria-labelledby="listed-tab"
    >
      <div className="row">
        <div className="col-md-4 class-for-padding">
          <form
            className="form-horizontal"
            onSubmit={(e) => onClickSearchText(e)}
          >
            <div className="input-group">
              <input
                type="text"
                placeholder="Search Sessions..."
                className="form-control"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-secondary"
                  type="submit"
                  onClick={(e) => onClickSearchText(e)}
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* <div className="col-md-8 mb-2">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <input type="date" className="form-control" />
            </div>
          </div>
          <div className="col-md-4">
            <a href="#" className="mr-2">
              Clear All
            </a>
            <input
              type="submit"
              className="btn btn-primary mb-0"
              defaultValue="Apply"
            />
          </div>
        </div>
      </div>
     */}
      </div>
      {draftCoursesList.length > 0 ? (
        <div className="row">
          {draftCoursesList.map((course, index) => {
            let session_group =
              course.session_detail.length > 0
                ? course.session_detail[0].session_group
                : null;
            return (
              <div key={index} className="col-md-4 mb-3">
                {/* Attended Card Start   */}
                <div className="card">
                  <img
                    src={
                      course.image
                        ? process.env.REACT_APP_MEDIA_URL + course.image
                        : CourseImage
                    }
                    alt=""
                    className="card-thumb"
                    width="288px"
                    height="173px"
                  />
                  <span className="badge badge-info">Draft</span>
                  <div className="card-body pb-0">
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <h4 className="title orange-text">
                          <strong style={{ fontSize: 16 }}>
                            {course.name.length > 20
                              ? course.name.substring(0, 20) + " ..."
                              : course.name}
                          </strong>
                        </h4>
                      </div>
                    </div>
                    <div className="info-row">
                      <span>Session Date/Time</span>
                      <strong>
                        {session_group &&
                        session_group[0]?.sessions[0]?.start_at ? (
                          <>
                            {moment
                              .unix(session_group[0].sessions[0].start_at)
                              .tz(timeZone)
                              .format("MMM Do, YYYY")}{" "}
                            |{" "}
                            {moment
                              .unix(session_group[0].sessions[0].start_at)
                              .tz(timeZone)
                              .format("hh:mm A")}{" "}
                          </>
                        ) : (
                          <>(Not Selected)</>
                        )}
                      </strong>
                    </div>
                    <div className="row" style={{ height: 65 }}>
                      <div className="col-md-8">
                        <div className="info-row">
                          <span>Session Type</span>
                          <small>
                            <span
                              className="text-orange"
                              style={{ fontWeight: 700 }}
                            >
                              {course.course_type ? (
                                <>
                                  {course.course_type === 1
                                    ? "DD Me for Anything"
                                    : null}
                                  {course.course_type === 2
                                    ? "1-1 Session Offered"
                                    : null}
                                  {course.course_type === 3
                                    ? "Group Session Offered"
                                    : null}
                                  {course.course_type === 4
                                    ? "1-1 and Group Session Offered"
                                    : null}
                                </>
                              ) : (
                                "(Not Selected)"
                              )}
                            </span>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer bg-white">
                    <Link to={`/edit-draft-session/${course._id}`}>
                      <img src={EditImage} alt="" className="icon" />
                    </Link>
                    <button
                      className="delete-course-btn"
                      onClick={(e) => onDeleteClick(e, course._id)}
                    >
                      <img src={DeleteImage} alt="" className="icon" />
                    </button>
                  </div>
                </div>
                {/* Attended Card End  /*/}
              </div>
            );
          })}
        </div>
      ) : (
        <NoRecord />
      )}
      <div className="row">
        <div className="col-md-12 mt-5">
          <Pagination
            totalRecords={totalRecords}
            recordPerPage={RECORD_PER_PAGE}
            paginate={paginate}
            currentPage={filters.page}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_id: state.auth.user._id,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getDraftAllCourses,
  changeStatus,
  deleteDraftCourse,
})(withRouter(ListedDraftCourses));
