//@DEPRECATED
/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
import * as moment from "moment";
import Spinner from "views/Spinner";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import {
  getEducatorDetail,
  changeStatus,
  deleteUser,
} from "actions/admin/educator";
import * as Constants from "constants/index";

import { Button, Card, CardBody, Col, Row, Input, Table, CardHeader } from "reactstrap";

const HostDetail = ({
  match,
  deleteUser,
}) => {
  //################## Initillise sorting searching parameters by default values ###################
  const [loading, setLoading] = useState(false);
  const [host, setHost] = useState();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (match.params.id) {
      getEducatorDetail(match.params.id).then((data) => {
        console.log({ data });
        setHost(data);
        setLoading(false);
      });
    }
  }, []);


  return (
    <div className="animated fadeIn displayTableList">
      <Row>
        <Col>
          <Card>
            <CardHeader>
              Host Detail:
            </CardHeader>
            <CardBody>
              <Table bordered striped>
                <tbody>
                  <tr>
                    <td>Email:</td>
                    <td>{host?.email}</td>
                  </tr>
                  <tr>
                    <td>Full Name:</td>
                    <td>{host?.full_name}</td>
                  </tr>
                  <tr>
                    <td>Tier:</td>
                    <td>{host?.host_tier}</td>
                  </tr>
                  <tr>
                    <td>Host Since:</td>
                    <td>{host?.host_start_at}</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  educatorList: state.educator.educatorsList,
  loading: state.educator.loading,
  subLoading: state.educator.isSubLoading,
});

export default connect(mapStateToProps, {
  changeStatus,
  deleteUser,
})(withRouter(HostDetail));
