import SelectInput from "components/common/form/SelectInput";
import TextEditor from "components/common/form/TextEditor";
import TextInput from "components/common/form/TextInput";
import { CreateFormRow } from "components/create-form/CreateFormRow";
import { PROFICIENCY_LEVELS } from "constants";
import { useFormikContext } from "formik";
import { connect } from "react-redux";
import CreateStepControls from "../../../StepControls";
import { Step2EventTimePicker } from "./EventTimePicker";
import Step2ProgramImagery from "./ProgramImagery";

function CreateEventPublicStep2({ categories, disabled, ...props }) {
  const { values } = useFormikContext();
  return (
    <>
      <h2 className="text-lg font-medium border-b border-dgray-200 pb-5 mb-5">
        Event Settings
      </h2>
      <CreateFormRow
        title="Select associated learning program"
        description="Select which learning program the event is associated with."
        right={
          <SelectInput
            name="associatedProgram"
            options={
              categories
                ? categories.map((cat) => ({
                    label: cat.name,
                    value: cat._id,
                  }))
                : []
            }
          />
        }
        bottom={values.associatedProgram && values.editId === null && <Step2ProgramImagery />}
      />
      <CreateFormRow
        title="Event proficiency level"
        description="Select which proficiency level the event targets."
        right={
          <SelectInput name="proficiencyLevel" options={PROFICIENCY_LEVELS} />
        }
      />
      <CreateFormRow
        title="Event Title"
        description="Max. xx characters"
        right={
          <TextInput
            name="title"
            className="w-full"
            placeholder="Event title"
          />
        }
      />
      <CreateFormRow
        title="Event Description"
        description="A quick introduction of event agenda, details and highlighted topics. (50 ch min.)"
        right={<TextEditor name="description" />}
      />
      <CreateFormRow title="Event Time" disabled={disabled} right={<Step2EventTimePicker disabled={disabled} />} />
      <CreateStepControls {...props} />
    </>
  );
}

export default connect(({ categories }) => ({ categories: categories.data }))(
  CreateEventPublicStep2
);
