export default function BecomeAHostRequirements() {
  return (
    <div className="py-24">
      <h2 className="text-center text-5xl lg:text-4xl md:text-3xl font-semibold mb-16 lg:mb-12">
        Becoming a Host Requirements
      </h2>
      <div className="grid grid-cols-2 lg:grid-cols-1 gap-x-6 gap-y-12">
        {items.map(({ label, title, description }, i) => (
          <div
            key={i}
            className={`p-6 rounded-lg ${
              i === 0 ? "bg-primary-50" : "bg-dgray-50"
            }`}
          >
            <div className="text-xl text-white bg-primary-600 h-12 w-12 flex justify-center items-center rounded-lg mb-16">
              {label}
            </div>
            <div className="font-medium text-xl mb-2">{title}</div>
            <div className="text-dgray-500">{description}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

const items = [
  {
    label: "01",
    title: "Complete online application",
    description:
      "Regardless of your professional background, we welcome peoplewho have passion and patience in helping ESL (English as Second Language) learners to improve their interpersonal skills.",
  },

  {
    label: "02",
    title: "Meet advanced English proficiency",
    description:
      "We are not just looking for native English speakers, but also welcoming ESL speakers to join us. Your experience on how to improve spoken English is a great asset to our community.",
  },
  {
    label: "03",
    title: "Prepare for your events",
    description:
      "Based on DDN program guideline, we rely on your creativity and expertise to design various events to help users grow confidence and improve speaking.",
  },
  {
    label: "04",
    title: "Be professional and respectful",
    description:
      "The DDN community has a variety of users from all over the world. Please respect users’ different background and culture.",
  },
];
