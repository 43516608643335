import axios from "utils/axios";
import {
  SETTING_ERROR,
  REMOVE_ERRORS,
  SETTING_UPDATED,
  GET_SETTING,
  LOADING_ON_SETTINGS_SUBMIT,
} from "actions/types";

// Edit Setting
export const edit = (formData, history) => async (dispatch) => {
  // dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnSettingsSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(`/api/admin/setting`, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: SETTING_UPDATED,
        payload: {
          message: res.data.message,
          alertType: "success",
        },
      });
      history.push("/admin/settings");
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: SETTING_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: SETTING_ERROR,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

// Get Setting
export const getSetting = (history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/admin/setting`, config);
    //const { metadata, data } = res.data.response[0];
    await dispatch({
      type: GET_SETTING,
      payload: {
        message: res.data.message,
        alertType: "success",
        data: res.data.response,
      },
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: SETTING_ERROR,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

export const getRecommendedTimes = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/admin/setting/recommended-times`, config);
    //const { metadata, data } = res.data.response[0];

    return res;
  } catch (err) {
    console.log(err);
  }
};

export const editRecommendedTimes = async (data) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(`/api/admin/setting/recommended-times`, data, config);
    //const { metadata, data } = res.data.response[0];

    return res;
  } catch (err) {
    console.log(err);
  }
};


export const getExpertiseList = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/admin/setting/expertise-list`, config);
    //const { metadata, data } = res.data.response[0];

    return res;
  } catch (err) {
    console.log(err);
  }
};

export const editExpertiseList = async (data) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(`/api/admin/setting/expertise-list`, data, config);
    //const { metadata, data } = res.data.response[0];

    return res;
  } catch (err) {
    console.log(err);
  }
};


// Dispatch Loading
export const loadingOnSettingsSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_SETTINGS_SUBMIT });
};
