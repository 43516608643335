import { connect } from "react-redux";
import Footer from "../Layout/Footer";
import HomepageNotSignedIn from "./NotSignedIn";
import HomePageSignedIn from "./SignedIn";

function HomePageNew({ user }) {
  return (
    <>
      {user ? <HomePageSignedIn user={user} /> : <HomepageNotSignedIn />}
      <Footer />
    </>
  );
}

export default connect((state) => ({
  user: state.auth.user,
}))(HomePageNew);
