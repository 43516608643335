// eslint-disable-next-line import/no-anonymous-default-export
export default {
  items: [
    {
      name: "Dashboard",
      url: "/admin",
      icon: "icon-speedometer",
      badge: {
        variant: "info",
      },
    },
    {
      name: "Programs",
      url: "/admin/course-program",
      icon: "icon-energy",
      badge: {
        variant: "info",
      },
    },
    {
      name: "Hosts",
      url: "/admin/hosts",
      icon: "icon-user",
      badge: {
        variant: "info",
      },
    },
    {
      name: "Speakers & Listeners",
      url: "/admin/speakers-and-listeners",
      icon: "icon-user",
      badge: {
        variant: "info",
      },
    },
    // {
    //   name: "Speakers",
    //   url: "/admin/speakers",
    //   icon: "icon-user",
    //   badge: {
    //     variant: "info",
    //   },
    // },

    {
      name: "Courses",
      url: "/admin/courses",
      icon: "icon-user",
      badge: {
        variant: "info",
      },
    },
    {
      name: "Host Applications",
      url: "/admin/host-applications",
      icon: "icon-people",
      badge: {
        variant: "info",
      },
    },
    {
      name: "Recommended Times",
      url: "/admin/recommended-times",
      icon: "icon-settings",
      badge: {
        variant: "info",
      },
    },
    {
      name: "Expertise List",
      url: "/admin/expertise-list",
      icon: "icon-settings",
      badge: {
        variant: "info",
      },
    },

    {
      name: "Settings",
      url: "/admin/settings",
      icon: "icon-settings",
      badge: {
        variant: "info",
      },
    },

    {
      name: "Payouts",
      url: "/admin/payouts",
      exact: false,
      icon: "icon-user",
      badge: {
        variant: "info",
      },
      children: [
        {
          name: "One on One",
          url: "/admin/payouts/one-on-one",
          badge: {
            variant: "info",
          },
        },
        {
          name: "Public Events",
          url: "/admin/payouts/public-events",
          badge: {
            variant: "info",
          },
        },
        {
          name: "Pay To Mentor",
          url: "/admin/payouts",
          badge: {
            variant: "info",
          },
        },
        {
          name: "Refund List",
          url: "/admin/payouts/refund",
          badge: {
            variant: "info",
          },
        },
        {
          name: "Paid Users",
          url:"/admin/payouts/paid-users",
          badge:{
            variant:"info"
          }
        }
      ],
    },
    /* {
      name: "Logs",
      url: "/admin/logs",
      icon: "icon-speedometer",
      badge: {
        variant: "info",
      },
    },
    {
      name: "Email Templates",
      url: "/admin/email-templates",
      icon: "icon-envelope-letter",
      badge: {
        variant: "info"
      }
    },
    {
      name: "Pages",
      url: "/admin/cms",
      icon: "icon-list",
      badge: {
        variant: "info"
      }
    },
    {
      name: "Inquiries",
      url: "/admin/inquiries",
      icon: "icon-question",
      badge: {
        variant: "info"
      }
    },
    {
      name: "Website Settings",
      url: "/admin/settings",
      icon: "icon-settings",
      badge: {
        variant: "info"
      }
    }*/
  ],
};
