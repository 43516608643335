import React, { Fragment } from 'react';
import Moment from 'react-moment';

const DefaultFooter = () => (
  <Fragment>
    <span>
<a href='http://localhost:3000'>{process.env.REACT_APP_APP_NAME}</a> &copy;{' '}
      <Moment format='YYYY' />
    </span>
    {/* <span className='ml-auto'>
      Powered by <a href='https://coreui.io/react'>CoreUI for React</a>
    </span> */}
  </Fragment>
);

export default DefaultFooter;
