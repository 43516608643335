import AccordionList from "components/common/AccordionList";
import CenterContainer from "components/common/Container/Center";

export default function BecomeAHostFAQ() {
  return (
    <CenterContainer className="py-24">
      <h2 className="text-center text-4xl lg:text-3xl font-semibold mb-11">
        Frequently Asked Questions
      </h2>
      <div className="max-w-3xl mx-auto">
        <AccordionList items={items} />
      </div>
    </CenterContainer>
  );
}

const items = [
  {
    title: "How much does a host get paid for organizing an event?",
    content: (
      <div className="space-y-4">
        <p>
          DDN pays our host based on proficiency level. We have three different
          tiers for hosts:
        </p>
        <p>
          <span className="font-medium">Tier 1:</span> Good English
          communication skill, and host is capable to drive a conversion in a
          group.
        </p>
        <p>
          <span className="font-medium">Tier 2 (Advanced):</span> Excellent
          communication skill, and host is able to teach professional jargons
          and native expressions.
        </p>
        <p>
          <span className="font-medium">Tier 3:</span> English Native Speaker
          who is passion about helping ESL learners to master the skill.
        </p>
        <p className="font-medium">
          Tier 1 will be compensated at $5/event; Tier is $8/event; Tier 3 is
          $10/event.
        </p>
      </div>
    ),
  },
  {
    title: "When does a host get paid?",
    content: (
      <p>DDN Host Payout schedule is biweekly on Monday before 5pm US EST</p>
    ),
  },
  {
    title: "How does DDN evaluate a host?",
    content: (
      <p>
        We value more on a host’s personality and passion. A good host knows how
        to train our users to gain confidence in speaking English, and know how
        to help them to achieve their goals, whether the goal is in career
        progression or passing academic exams.
      </p>
    ),
  },
  {
    title: "Does a host need to prepare any materials?",
    content: (
      <p>
        It is highly recommended for a host to prepare for event topics,
        vocabulary/phrases or questions related to the topic.
      </p>
    ),
  },
  {
    title: "How many events can a host organize every week?",
    content: (
      <p>
        Numbers of events are set by DDN based on the demand of subscribers. At
        the initial stage, we set 3 events per day on weekday, and 2 events
        during the weekend. We will slowly increase the number of events based
        on market traction.
      </p>
    ),
  },
  {
    title: "Can a host decide the event time?",
    content: (
      <p>
        There are 3 event slots every day, one of them is set at a fixed time at
        8am – 9am EST (8pm – 9pm Beijing Time). The other two event slots are
        flexible and based on a host’s availability. However, we highly
        recommend a host to keep your event schedule consistent.
      </p>
    ),
  },
];
