/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import * as router from "react-router-dom";
import { Container } from "reactstrap";
// import Alert from "../../Notifications/Alert";
// import "react-notifications/lib/notifications.css";
/*import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";*/

// import "bootstrap/dist/css/bootstrap.min.css";
// import '@coreui/coreui/dist/css/coreui.min.css'

import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppBreadcrumb2 as AppBreadcrumb,
  AppSidebarNav2 as AppSidebarNav,
} from "@coreui/react";
// sidebar nav config
import navigation from "./_nav";
// routes config
import AdminRoutes from "views/Routing/AdminRoutes";
import PrivateRoute from "views/Routing/PrivateRoute";

import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import { removeScripts } from "utils/sFold";
import "../../../scss/admin.scss";
// import "../../../scss/style.scss";

const index = ({
  auth: { isAuthenticated, loading },
  alerts,
  props,
  history,
}) => {
  useMemo(() => {
    removeScripts("front_panel_css", "style");
    removeScripts("front_panel_js", "script");
  }, [history.location.path]);

  const [loadingCSS, setLoadingCSS] = useState(true);
  const [loadingJS, setLoadingJS] = useState(true);
  const [onlyOnce, setOnce] = useState(true);
  let jsNeedToBeLoaded = 0;
  let jsLoaded = 0;
  let stylesNeedToBeLoaded = 0;
  let stylesLoaded = 0;

  const InjectCSS = async () => {
    try {
      const cssArray = [];
      stylesNeedToBeLoaded = cssArray.length;
      if (!checkScripts("front_panel_css"))
        cssArray.forEach((url) => {
          addStyleScript(url, "css", "front_panel_css");
        });
      else setLoadingCSS(false);
    } catch (err) {
      console.log(err);
    }
  };

  const InjectJS = async () => {
    try {
      const jsArray = [
        "https://code.jquery.com/jquery-3.5.1.slim.min.js",
        "https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js",
        "https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js",
        "/assets/js/custom.js",
      ];
      jsNeedToBeLoaded = jsArray.length;
      if (!checkScripts("front_panel_js"))
        jsArray.forEach((url) => {
          addStyleScript(url, "js", "front_panel_js");
        });
      else setLoadingJS(false);
    } catch (err) {
      console.log(err);
    }
  };

  const styleLoaded = (id) => {
    stylesLoaded++;
    if (stylesNeedToBeLoaded === stylesLoaded) {
      setLoadingCSS(false);
    }
  };

  const jsesLoaded = (id) => {
    jsLoaded++;
    if (jsNeedToBeLoaded === jsLoaded) {
      setLoadingJS(false);
    }
  };

  const addStyleScript = (url, type, package_name) => {
    // console.log(url);
    let tag,
      tag_id = sfold(package_name);
    switch (type) {
      case "css":
        tag = document.createElement("link");
        tag.rel = "stylesheet";
        tag.href = url;
        tag.async = true;
        tag.dataset.id = tag_id;
        tag.onload = () => styleLoaded(tag_id);
        break;

      case "js":
        tag = document.createElement("script");
        tag.type = "text/javascript";
        tag.src = url;
        tag.async = true;
        tag.dataset.id = tag_id;
        tag.onload = () => jsesLoaded(tag_id);
        break;
    }
    document.head.appendChild(tag);
  };

  useEffect(() => {
    if (onlyOnce) {
      InjectCSS();
      InjectJS();
      setOnce(false);
    }
  }, [onlyOnce]);

  const createNotification = (type, message) => {
    switch (type) {
      case "info":
        return NotificationManager.info(message, "", 10000);
      case "success":
        return NotificationManager.success(message, "", 10000);
      case "warning":
        return NotificationManager.warning(message, "", 10000);
      case "danger":
        return NotificationManager.error(message, "", 10000);
    }
  };
  /*useEffect(() => {
    if (alerts.isAlertToDisplay) {
      createNotification(alerts.alertType, alerts.message);
    }
  }, [alerts]);*/

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("coreui-admin");
    return () => body.classList.remove("coreui-admin");
  }, []);

  return (
    <div className="app" id="ddn_admin">
      <AppHeader fixed>
        <DefaultHeader />
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          <AppSidebarHeader />
          <AppSidebarForm />
          <AppSidebarNav
            navConfig={navigation}
            {...props}
            router={router}
            location={location}
          />
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main">
          <AppBreadcrumb appRoutes={AdminRoutes} router={router} />
          <Container fluid>
            {/*<NotificationContainer />*/}
            <Switch>
              {AdminRoutes.map((route, idx) => {
                return route.component ? (
                  <PrivateRoute
                    key={idx}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                    name={route.name}
                    forAdmin={true}
                  />
                ) : null;
              })}
              {/* <Redirect from="" to="/admin/page-not-found" /> */}
              <Redirect from="/" to="/admin" />
            </Switch>
          </Container>
        </main>
      </div>
      <AppFooter>
        <DefaultFooter />
      </AppFooter>
    </div>
  );
};

index.prototype = {
  auth: PropTypes.object.isRequired,
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  alerts: state.alert,
});

export default connect(mapStateToProps)(withRouter(index));
