import { PAYOUT_METHODS } from "constants";
import * as Yup from "yup";

const becomeAHostSchema = Yup.object({
  firstName: Yup.string().required("Please enter your first name"),
  lastName: Yup.string().required("Please enter your last name"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
  country: Yup.string("Please select a country."),
  city: Yup.string(),
  languages: Yup.array().of(Yup.object()),
  about: Yup.string()
    .optional()
    .min(20, "Please enter at least 20 characters"),
  profilePicture: Yup.mixed(),

  hostTier: Yup.string().required("Please select a tier"),
  expertise: Yup.array().of(Yup.object()),
  purpose: Yup.string().max(500),
  experience: Yup.string().max(500),
  documents: Yup.array().of(Yup.mixed()),

  payoutMethod: Yup.string(),
  paypalEmail: Yup.string().when("payoutMethod", {
    is: (val) => val === PAYOUT_METHODS.PayPal,
    then: Yup.string()
      .email("Please enter a valid email address")
  }),
  weChatPayFile: Yup.mixed().when("payoutMethod", {
    is: (val) => val === PAYOUT_METHODS.WeChat,
    then: Yup.mixed(),
  }),
});

export default becomeAHostSchema;
