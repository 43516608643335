import { ReactComponent as CheckCircle } from "assets/images/icons/check-circle-primary.svg";

export default function StepCircle({ disabled, completed, className = "" }) {
  if (completed)
    return (
      <div className="my-1.5 md:mx-1.5 mx-auto relative z-10">
        <CheckCircle className={`${className}`} />
      </div>
    );
  return (
    <div
      className={`p-1 h-9 w-9 rounded-full relative z-10
        ${disabled ? "bg-transparent" : "bg-primary-100"} ${className}`}
    >
      <div
        className={`p-0.5 h-7 w-7 rounded-full
          ${disabled ? "bg-dgray-200" : "bg-primary-600"}`}
      >
        <div
          className={`p-2 h-6 w-6 rounded-full
            ${disabled ? "bg-white" : "bg-primary-50"}`}
        >
          <div
            className={`h-2 w-2 rounded-full
              ${disabled ? "bg-dgray-200" : "bg-primary-600"}`}
          />
        </div>
      </div>
    </div>
  );
}
