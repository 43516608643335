import { useEffect, useState } from "react";
import { SelectableButton } from "./Button/Selectable";
import SelectInput from "./form/SelectInput";

export default function TabPicker({
  initialFilters = defaultInitialFilters,
  className = "",

  onChange,
  setPage,
  setFilters,

  items, // [{ value, label }]
}) {
  const [selectedItem, setSelectedItem] = useState(false);

  const onSelect = (item) => {
    setSelectedItem(item);
    onChange?.(item.value);
    setFilters?.(initialFilters);
    setPage?.(1);
  };

  useEffect(() => {
    setSelectedItem(items[0]);
  }, []);

  return (
    <>
      <ul className={`flex md:hidden gap-2 flex-wrap ${className}`}>
        {items.map((item, i) => (
          <li key={i}>
            <SelectableButton
              onClick={() => onSelect(item)}
              isSelected={item.value === selectedItem?.value}
            >
              {item.label}
            </SelectableButton>
          </li>
        ))}
      </ul>
      <SelectInput
        className={`hidden md:block ${className}`}
        options={items}
        value={selectedItem}
        onChange={onSelect}
      />
    </>
  );
}

const defaultInitialFilters = {
  limit: 7,
  page: 1,
  orderBy: "start_at",
  ascending: "desc",
  query: "",
  custom_filters: "",
};
