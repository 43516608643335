import {
  REMOVE_ALERT,
  LOGOUT,
  LOGIN_ERROR,
  AUTH_LOADING,
  AUTH_ERROR,
  LOGIN_FAIL,
  RESET_LINK_FAIL,
  RESET_LINK_SUCCESS,
  REMOVE_ERRORS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  // RESET_PASSWORD_SUCCESS,
  // GOOGLE_SIGN_IN_FAIL,
  CHECK_LINK_ERROR,
  CHECK_LINK_SUCCESS,
  MYACCOUNT_LOADING, //my account
  USER_MYACCOUNT_UPDATED,
  USER_MYACCOUNT_UPDATED_ERROR,
  USER_MYACCOUNT_FAIL,
  // USER_MYACCOUNT,
  USER_MYACCOUNT_ERROR,
  //educator
  EDUCATOR_COURSE_LOADING,
  EDUCATOR_COURSE_CREATED,
  EDUCATOR_COURSE_CREATE_ERROR,
  EDUCATOR_COURSE_FAIL,
  EDUCATOR_COURSE_EDITED,
  EDUCATOR_COURSE_EDIT_ERROR,
  BLOG_CREATED_ERROR,
} from "actions/types";

const initialState = [];

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGOUT:
    case REMOVE_ERRORS:
    case REMOVE_ALERT:
      return initialState;
    //Auth cases
    case AUTH_LOADING:
      return [];
    case RESET_LINK_SUCCESS:
    case REGISTER_SUCCESS:
    case CHECK_LINK_SUCCESS:
      return [payload];
    case LOGIN_ERROR:
    case AUTH_ERROR:
    case CHECK_LINK_ERROR:
      return [payload];
    case LOGIN_FAIL:
    case RESET_LINK_FAIL:
    // case GOOGLE_SIGN_IN_FAIL:
    case REGISTER_FAIL:
      return [payload];
    //other cases
    case MYACCOUNT_LOADING:
    case EDUCATOR_COURSE_LOADING:
      return initialState;
    case USER_MYACCOUNT_UPDATED:
    case USER_MYACCOUNT_UPDATED_ERROR:
    case USER_MYACCOUNT_FAIL:
    case EDUCATOR_COURSE_CREATED:
    // case EDUCATOR_COURSE_CREATE_ERROR:
    // case EDUCATOR_COURSE_FAIL:
    case EDUCATOR_COURSE_EDITED:
    // case EDUCATOR_COURSE_EDIT_ERROR:
    // case USER_MYACCOUNT:
    case USER_MYACCOUNT_ERROR:
      return [payload];
    default:
      return state;
  }
}
