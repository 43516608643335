import JoinCTA from "components/common/JoinCTA";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import HostListLarge from "./HostListLarge";
import HostsHero from "./HostsHero";

export default function Hosts() {
  return (
    <>
      <Header />
      <HostsHero />
      <HostListLarge />
      <JoinCTA />
      <Footer />
    </>
  );
}
