import {
  EDUCATOR_LOADING,
  EDUCATOR_LIST_UPDATED,
  EDUCATOR_FAIL,
  EDUCATOR_CHANGE_STATUS,
  EDUCATOR_CHANGE_STATUS_ERROR,
} from "actions/types";

const initalState = {
  educatorsList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentUser: null,
  loading: false,
  isSubLoading: false,
  error: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case EDUCATOR_LOADING:
      return {
        ...state,
        educatorsList: payload.is_sub_element
          ? state.educatorsList
          : initalState.educatorsList,
        currentUser: payload.is_sub_element
          ? state.currentUser
          : initalState.currentUser,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case EDUCATOR_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case EDUCATOR_LIST_UPDATED:
      return {
        ...state,
        educatorsList: {
          data: payload.data.data,
          page: payload.data.metadata[0].current_page,
          count: payload.data.metadata[0].totalRecord,
        },
        loading: false,
        isSubLoading: false,
      };

    case EDUCATOR_CHANGE_STATUS:
      return {
        ...state,
        educatorsList: {
          ...state.educatorsList,
          data: state.educatorsList.data.map((educator) =>
            educator._id === payload.data._id
              ? { ...educator, status: payload.data.status }
              : educator
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case EDUCATOR_CHANGE_STATUS_ERROR:
      return {
        ...state,
        educatorsList: {
          ...state.educatorsList,
          data: state.educatorsList.data.map((educator) =>
            educator._id === payload.data
              ? { ...educator, is_failed: true }
              : { ...educator, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    default:
      return state;
  }
}
