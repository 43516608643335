import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { create, cancelSave } from "actions/admin/user";
import Errors from "views/Notifications/Errors";
import Spinner from "views/Spinner"

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";

const CreateUser = ({ create, cancelSave, history, errorList, loading }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: ""
  });

  const { first_name, last_name, email } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    create(formData, history);
  };

  const onClickHandel = e => {
    e.preventDefault();
    cancelSave(history);
  };
  return (
    <div className="animated fadeIn">
    {loading ? <Spinner/> :
      <Row>
        <Col xs="12" sm="6">
          <Card>
            <Form className="form-horizontal" onSubmit={e => onSubmit(e)}>
              <CardBody>
                <FormGroup>
                  <Label htmlFor="first_name">
                    First Name <span>*</span>
                  </Label>
                  <Input
                    type="text"
                    id="first_name"
                    name="first_name"
                    maxLength="25"
                    value={first_name}
                    required
                    onChange={e => onChange(e)}
                    invalid={errorList.first_name ? true : false}
                  />
                  <Errors current_key="first_name" key="first_name" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="last_name">Last Name</Label>
                  <Input
                    type="text"
                    id="last_name"
                    name="last_name"
                    maxLength="25"
                    value={last_name}
                    onChange={e => onChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="email">
                    Email <span>*</span>
                  </Label>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    maxLength="50"
                    value={email}
                    required
                    onChange={e => onChange(e)}
                    invalid={errorList.email ? true : false}
                  />
                  <Errors current_key="email" key="email" />
                </FormGroup>
              </CardBody>
              <CardFooter>
                <Button type="submit" className="m-1" size="sm" color="primary">
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <a onClick={onClickHandel} href="#!">
                  <Button type="reset" className="m-1" size="sm" color="danger">
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </a>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    }</div>
  );
};

CreateUser.propTypes = {
  create: PropTypes.func.isRequired,
  cancelSave: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  user: state.user,
  loading: state.user.loading,
  errorList: state.errors
});

export default connect(mapStateToProps, { create, cancelSave })(CreateUser);
