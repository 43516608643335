import { ReactComponent as CalendarIcon } from "assets/images/icons/calendar.svg";
import { ReactComponent as FilterLines } from "assets/images/icons/filter-lines.svg";
import Button from "components/common/Button";
import SelectInput from "components/common/form/SelectInput";
import { useState } from "react";
import { connect } from "react-redux";
import { isUserHost } from "utils/isUserHost";

function DashboardCalendarToolbar({ user }) {
  const [calendarView, setCalendarView] = useState(dateOptions[0]);
  const [selectedDateRange, setSelectedDateRange] = useState(new Date());

  return (
    <div className="flex lg:flex-col lg:gap-4 justify-between py-5 px-6">
      <div>
        <div className="text-lg font-medium">Weekly Calendar</div>
        <div className="text-sm text-dgray-500">
          Keep track of your upcoming events.
        </div>
      </div>
    
    </div>
  );
}

export default connect(({ auth }) => ({ user: auth.user }))(
  DashboardCalendarToolbar
);

function CalendarLegend() {
  return (
    <div>
      <div className="flex flex-row items-center">
        <div className="w-2 h-2 rounded-full bg-cyan-500 mr-1 shrink-0" />
        <p className="text-cyan-500 text-2xs font-medium">
          Events You're Attending
        </p>
      </div>
      <div className="flex flex-row items-center">
        <div className="w-2 h-2 rounded-full bg-primary-500 mr-1 shrink-0" />
        <p className="text-primary-500 text-2xs font-medium">
          Events You're Hosting
        </p>
      </div>
    </div>
  );
}

const dateOptions = [
  { label: "EST +2", value: "EST +2" },
  { label: "EST +3", value: "EST +3" },
];
