import { Fragment, useEffect, useState } from "react";
import { RadioGroup as HUIRadioGroup } from "@headlessui/react";
import RadioButton from "./RadioButton";
import { Field } from "formik";

export default function RadioGroup({
  name,
  onChange,
  disabled,
  items = [],
  defaultSelectedIndex = 0,
}) {
  if (name)
    return (
      <Field name={name}>
        {({ field, form }) => (
          <RadioGroupEl
            items={items}
            onChange={(item) => {
              form.setFieldValue(field.name, item.value);
              onChange?.(item);
            }}
            disabled={form.isSubmitting || disabled}
            defaultSelectedIndex={defaultSelectedIndex}
          />
        )}
      </Field>
    );
  return (
    <RadioGroupEl
      disabled={disabled}
      items={items}
      onChange={onChange}
      defaultSelectedIndex={defaultSelectedIndex}
    />
  );
}

function RadioGroupEl({ items, onChange, defaultSelectedIndex, disabled }) {
  const [selected, setSelected] = useState(items[defaultSelectedIndex]);

  useEffect(() => {
    onChange?.(items[0]);
  }, []);

  return (
    <HUIRadioGroup
      disabled={disabled}
      value={selected}
      onChange={(item) => {
        setSelected(item);
        onChange?.(item);
      }}
    >
      <div className="space-y-3 md:space-y-4">
        {items.map((item, i) => (
          <Fragment key={i}>
            <HUIRadioGroup.Option
              disabled={disabled}
              key={item.label}
              value={item}
              className={({ active, checked }) =>
                `${active ? "" : ""} ${checked ? "" : ""}`
              }
            >
              {({ checked }) => (
                <RadioButton
                  {...item}
                  disabled={disabled}
                  selected={checked || selected.label === item.label}
                  select={() => {
                    if (!disabled) {
                      setSelected(item);
                      if (item && item.onSelect) {
                        item?.onSelect(item);
                      }
                    }
                  }}
                />
              )}
            </HUIRadioGroup.Option>
            {selected.label === item.label && item.expansion && (
              <div className="ml-6">{item.expansion}</div>
            )}
          </Fragment>
        ))}
      </div>
    </HUIRadioGroup>
  );
}
