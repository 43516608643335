import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import Footer from "./Layout/Footer";
import { Route, Switch } from "react-router-dom";

// import Login from "../Auth/Login";
// import Register from "../Auth/Register";
// import ForgotPassword from "views/Auth/ForgotPassword/ForgotPassword";
// import ResetPassword from "views/Auth/ForgotPassword/ResetPassword";
// import ConfirmUser from "views/Auth/ConfirmUser";

import FrontRoutes from "views/Routing/FrontRoutes";
import PublicRoute from "views/Routing/PublicRoute";
import PrivateRoute from "views/Routing/PrivateRoute";

import { sfold, checkScripts } from "utils/sFold";

//import "App.scss";

const Home = ({ alerts, history, location }) => {
  /********************Scroll to top*****************/
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  //require("../../scss/style.scss");

  return (
    <div className="bg-white text-dgray-900">
      <Fragment>
        <section className="">
          <div className="">
            <Switch>
              {FrontRoutes.map((route, idx) => {
                return route.component ? (
                  route.ispublic ? (
                    <PublicRoute
                      key={idx}
                      exact={route.exact}
                      path={route.path}
                      restricted={true}
                      component={route.component}
                      name={route.name}
                      isforall={route.isforall}
                    />
                  ) : route.isprivate ? (
                    <PrivateRoute
                      key={idx}
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                      name={route.name}
                      isforall={route.isforall}
                      isForEducator={route.isForEducator}
                      isForLearner={route.isForLearner}
                    />
                  ) : (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => (
                        <route.component {...props} name={route.name} />
                      )}
                    />
                  )
                ) : null;
              })}
            </Switch>
          </div>
        </section>
      </Fragment>
    </div>
  );
};

export default connect()(Home);
