import React from "react";
// import spinner from '../assets/spinner.gif';

const Spinner1 = () => {
  return (
    <div className="overlay-bg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <span>
              <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default Spinner1;
