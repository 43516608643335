import axios from "utils/axios";
import {
  FRONT_COURSE_RATING_FAIL,
  FRONT_COURSE_RATING_CREATED,
  FRONT_COURSE_RATING_CREATE_ERROR,
  FRONT_COURSE_RATING_LOADER,
} from "actions/types";

//get all the sessions of learner
export const getSessions = () => async (dispatch) => {
  const res = await axios.get("/api/learner/courses/calendar");
  return res.data;
};

// get Statistics for learner dashboard
export const getReviewDetails = (course_id, reviewer_id = "") => async (
  dispatch
) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/front/courses/${course_id}/review?reviewid=${reviewer_id}`,
      config
    );
    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      return { status: false };
    }
  } catch (error) {
    return { status: false };
  }
};

//rate the product
export const rateCourse = (formdata, course_id) => async (dispatch) => {
  dispatch({
    type: FRONT_COURSE_RATING_LOADER,
    payload: true,
  });
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await axios.post(
      `/api/front/courses/add-reviews/${course_id}`,
      formdata,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FRONT_COURSE_RATING_CREATED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
      // dispatch(getProductRating(product_id));
      return { status: true, data: res.data.response };
    } else {
      const errors = res.data.errors;
      let firstError = "";
      if (errors) {
        firstError = errors[0].msg;
      }
      dispatch({
        type: FRONT_COURSE_RATING_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
        },
      });
      return { status: false, msg: firstError };
    }
  } catch (err) {
    //console.log(err)
    dispatch({
      type: FRONT_COURSE_RATING_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
    return {
      status: false,
      msg: "Something went wrong, please try again later.",
    };
  }
};
