import Pill from "./Pill";

export default function NestedPill({ innerText, outerText }) {
  const color = innerText === "Private event" ? "purple" : "primary";

  return (
    <div
      className={`inline rounded-2xl p-1 pt-0 whitespace-nowrap
        ${color === "primary" ? "bg-primary-50" : "bg-purple-50"}
      `}
    >
      <Pill
        text={innerText}
        color={color}
        className="text-2xs font-normal py-1"
      />
      {outerText && (
        <span className="ml-2 text-2xs text-primary-700 pr-2.5">
          {outerText}
        </span>
      )}
    </div>
  );
}
