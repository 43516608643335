// import React from 'react';
import Dashboard from "views/Admin/Dashboard";
import CreateUser from "views/Admin/Users/CreateUser";
import UsersList from "views/Admin/Users/UsersList";

//course category routes
import CreateCourseCategory from "views/Admin/CourseCategory/CreateCourseCategory";
import CourseCategoryList from "views/Admin/CourseCategory/CourseCategoryList";
import EditCourseCategory from "views/Admin/CourseCategory/EditCourseCategory";

//course subcategory routes
import CreateCourseSubCategory from "views/Admin/CourseSubCategory/CreateCourseSubCategory";
import CourseSubCategoryList from "views/Admin/CourseSubCategory/CourseSubCategoryList";
import EditCourseSubCategory from "views/Admin/CourseSubCategory/EditCourseSubCategory";

// course list routes
import CoursesList from "views/Admin/Courses/CoursesList";
import CourseDetail from "views/Admin/Courses/CourseDetail";

import EditUser from "views/Admin/Users/EditUser";
import CreateEmailTemplate from "views/Admin/EmailTemplate/CreateEmailTemplate";
import EmailTemplateList from "views/Admin/EmailTemplate/EmailTemplateList";
import EditEmailTemplate from "views/Admin/EmailTemplate/EditEmailTemplate";
import HostApplicationList from "views/Admin/HostApplications/ApplicationList";

import CreateCMS from "views/Admin/CMS/CreateCMS";
import CMSList from "views/Admin/CMS/CMSList";
import EditCMS from "views/Admin/CMS/EditCMS";
import AddInquiry from "views/Admin/Inquiries/AddInquiry";
import InquiriesList from "views/Admin/Inquiries/InquiriesList";
import EditInquiry from "views/Admin/Inquiries/EditInquiry";
import EditProfile from "views/Admin/Profile/EditProfile";
import EditSettings from "views/Admin/WebsiteSettings/EditSettings";
import OneOnOnePaymentList from "views/Admin/EducatorPayments/OneOnOnePaymentList";
import NotFoundPage from "views/NotFound";
import PayToEducators from "views/Admin/EducatorPayments/PayToEducators";
import RefundList from "views/Admin/EducatorPayments/RefundList";
import AdminLogs from "views/Admin/Logs/AdminLogs";
import RecommendedTimes from "views/Admin/RecommendedTime/RecommendedTimes";
import ExpertiseList from "views/Admin/ExpertiseList/ExpertiseList";
import ApplicationDetail from "views/Admin/HostApplications/ApplicationDetail";
import HostList from "views/Admin/Users/HostList";
import HostDetail from "views/Admin/Users/HostDetail";

import AudienceList from "views/Admin/Users/AudienceList";
import LearnerDetails from "views/Admin/Users/LearnerDetails";
import SpeakerAndAudienceList from "views/Admin/Users/SpeakerAndAudienceList";
import SessionList from "views/Admin/Courses/SessionList";
import PublicEventEventPaymentList from "views/Admin/EducatorPayments/PublicEventEventPaymentList";
import PaidUsers from "views/Admin/EducatorPayments/PaidUsers";

const AdminRoutes = [
  { path: "/admin", exact: true, name: "Dashboard", component: Dashboard },
  {
    path: "/admin/profile",
    exact: true,
    name: "Admin Profile",
    component: EditProfile,
  },
  {
    path: "/admin/course-program",
    exact: true,
    name: "Course Program List",
    component: CourseCategoryList,
  },
  {
    path: "/admin/course-program/create",
    exact: true,
    name: "Create Course Program",
    component: CreateCourseCategory,
  },
  {
    path: "/admin/course-program/:coursecategory_id",
    name: "Edit Course Program",
    component: EditCourseCategory,
  },
  {
    path: "/admin/course-subcategory",
    exact: true,
    name: "Course SubCategory List",
    component: CourseSubCategoryList,
  },
  {
    path: "/admin/course-subcategory/create",
    exact: true,
    name: "Create Course SubCategory",
    component: CreateCourseSubCategory,
  },
  {
    path: "/admin/course-subcategory/:coursesubcategory_id",
    name: "Edit Course SubCategory",
    component: EditCourseSubCategory,
  },
  {
    path: "/admin/hosts",
    exact: true,
    name: "Host List",
    component: HostList,
  },
  {
    path: "/admin/hosts/:id",
    exact: true,
    name: "Host Detail",
    component: HostDetail,
  },

  // {
  //   path: "/admin/listener",
  //   exact: true,
  //   name: "Audience List",
  //   component: AudienceList,
  // },
  {
    path: "/admin/speakers-and-listeners/:id",
    exact: true,
    name: "Details",
    component: LearnerDetails,
  },
  {
    path: "/admin/courses",
    exact: true,
    name: "Courses List",
    component: CoursesList,
  },
  {
    path: "/admin/courses/:course_id",
    exact: true,
    name: "Courses Detail",
    component: CourseDetail,
  },
  {
    path: "/admin/settings",
    exact: true,
    name: "Admin Settings",
    component: EditSettings,
  },
  {
    path: "/admin/recommended-times",
    exact: true,
    name: "Admin Recommended Times",
    component: RecommendedTimes,
  },
  {
    path: "/admin/expertise-list",
    exact: true,
    name: "Admin Expertise List",
    component: ExpertiseList,
  },

  {
    path: "/admin/host-applications",
    exact: true,
    name: "Host Applications",
    component: HostApplicationList,
  },
  {
    path: "/admin/host-applications/:id",
    exact: true,
    name: "Host Application Detail",
    component: ApplicationDetail,
  },
  {
    path: "/admin/users",
    exact: true,
    name: "Users List",
    component: UsersList,
  },
  {
    path: "/admin/hosts",
    exact: true,
    name: "Host List",
    component: UsersList,
  },
  {
    path: "/admin/speakers-and-listeners",
    exact: true,
    name: "Speaker & Listener List",
    component: SpeakerAndAudienceList,
  },
  {
    path: "/admin/listener",
    exact: true,
    name: "Listener List",
    component: AudienceList,
  },
  {
    path: "/admin/sessions",
    exact: true,
    name: "Session List",
    component: SessionList,
  },
  {
    path: "/admin/users/create",
    exact: true,
    name: "Create User",
    component: CreateUser,
  },
  {
    path: "/admin/users/:user_id",
    name: "Edit User",
    component: EditUser,
  },
  {
    path: "/admin/email-templates",
    exact: true,
    name: "Email Template List",
    component: EmailTemplateList,
  },
  {
    path: "/admin/email-templates/create",
    exact: true,
    name: "Create Email Template",
    component: CreateEmailTemplate,
  },
  {
    path: "/admin/email-templates/:emailTemplate_id",
    name: "Edit Email Template",
    component: EditEmailTemplate,
  },
  {
    path: "/admin/cms",
    exact: true,
    name: "CMS List",
    component: CMSList,
  },
  {
    path: "/admin/cms/create",
    exact: true,
    name: "Create CMS Page",
    component: CreateCMS,
  },
  {
    path: "/admin/cms/:cms_id",
    name: "Edit CMS Page",
    component: EditCMS,
  },
  {
    path: "/admin/inquiries",
    exact: true,
    name: "Inquiries List",
    component: InquiriesList,
  },
  {
    path: "/admin/inquiries/add",
    exact: true,
    name: "Add Inquiry",
    component: AddInquiry,
  },
  {
    path: "/admin/inquiries/:inquiry_id",
    name: "Edit Inquiry",
    component: EditInquiry,
  },
  {
    path: "/admin/settings",
    exact: true,
    name: "Website Settings",
    component: EditSettings,
  },
  {
    path: "/admin/page-not-found",
    exact: true,
    name: "404 Not Found",
    component: NotFoundPage,
  },
  {
    path: "/admin/payouts/one-on-one",
    exact: true,
    name: "Payouts",
    component: OneOnOnePaymentList,
  },
  {
    path: "/admin/payouts/public-events",
    exact: true,
    name: "Public Events",
    component: PublicEventEventPaymentList,
  },
  {
    path:"/admin/payouts/paid-users",
    exact:true,
    name: "Paid Users",
    component: PaidUsers
  },
  {
    path: "/admin/payouts",
    exact: true,
    name: "Pay To Mentor",
    component: PayToEducators,
  },
  {
    path: "/admin/payouts/refund",
    exact: true,
    name: "Refund List",
    component: RefundList,
  },
  {
    path: "/admin/logs",
    exact: true,
    name: "Logs",
    component: AdminLogs,
  },
];

export default AdminRoutes;
