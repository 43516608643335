import React from 'react'

const ClassCancled = ({ details: { course_name }}) => {
    return (
        <div className="col-md-12">
        <div className="notification-box box">
          <div className="space-between">
            <a href="#" className="d-flex">
              <i className="fa fa-close"></i>
              <strong>Class Cancelled</strong>
            </a>
          </div>
          <p>Your mentor has cancelled the class for {course_name} </p>
        </div>
      </div>
    )
};

export default ClassCancled;
