import axios from "utils/axios";

export const getEducatorFullPaymentList = async (count) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/admin/payment?limit=${count}`,
      config
    );

    if (res.data.status) {
      return {
        status: true,
        data: res.data.response.data,
        count: res.data.response.metadata[0].totalRecord,
      };
    } else {
      return { status: false, data: [], count: 0 };
    }
  } catch (err) {
    console.log(err);
  }
};

// get getEducators Payments
export const getEducatorOneOnOnePayments = (userParams) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const query = userParams.query ? userParams.query : "&query=";
    const res = await axios.get(
      `/api/admin/payment/one-on-one?limit=${userParams.limit}&page=${userParams.page}&orderBy=${userParams.orderBy}&ascending=${userParams.ascending}${query}`,
      config
    );

    if (res.data.status) {
      return {
        status: true,
        data: res.data.response.data,
        count: res.data.response.metadata[0].totalRecord,
      };
    } else {
      return { status: false, data: [], count: 0 };
    }
  } catch (err) {
    console.log(err);
  }
};

export const getEducatorEventPayments = (userParams) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const query = userParams.query ? userParams.query : "&query=";
    const res = await axios.get(
      `/api/admin/payment/events?limit=${userParams.limit}&page=${userParams.page}&orderBy=${userParams.orderBy}&ascending=${userParams.ascending}${query}`,
      config
    );

    if (res.data.status) {
      return {
        status: true,
        data: res.data.response.data,
        count: res.data.response.metadata[0].totalRecord,
      };
    } else {
      return { status: false, data: [], count: 0 };
    }
  } catch (err) {
    console.log(err);
  }
};


// get eductors Payment details
export const getPaymentDetails = (paymentParams) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const query = paymentParams.query
      ? paymentParams.query
      : "&query=&dateFrom=&dateTo=";
    const res = await axios.get(
      `/api/admin/payment/details?orderBy=${paymentParams.orderBy}&ascending=${paymentParams.ascending}${query}`,
      config
    );

    if (res.data.status) {
      return {
        status: true,
        data: res.data.response.data,
        // count: res.data.response.metadata[0].totalRecord,
      };
    } else {
      return { status: false, data: [], count: 0 };
    }
  } catch (err) {
    console.log(err);
    return { status: false, data: [], count: 0 };
  }
};
// Pay to educator
export const payToMentorNow = (singleDetail) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // const body = { singleDetail };
    const res = await axios.post(`/api/admin/payment`, singleDetail, config);

    if (res.data.status) {
      return { status: true, msg: res.data.message };
    } else {
      return { status: false };
    }
    // return { status: true };
  } catch (err) {
    console.log(err);
    return { status: false };
  }
};


export const getPaidUserList = (paidUserParams) => async (dispatch) => {
  console.log("=================getPaidUserList");
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const query = paidUserParams.query ? paidUserParams.query : "&query=";
    const res = await axios.get(
      `/api/admin/payment/paid-users?limit=${paidUserParams.limit}&page=${paidUserParams.page}&orderBy=${paidUserParams.orderBy}&ascending=${paidUserParams.ascending}${query}`,
      config
    );

    if (res.data.status) {
      return {
        status: true,
        data: res.data.response[0].data,
        count: res.data.response[0].metadata[0].totalRecord,
      };
    } else {
      return { status: false, data: [], count: 0 };
    }
  } catch (err) {
    console.log(err);
    return { status: false, data: [], count: 0 };
  }
};

export const getFullPaidUserList = (paidUserParams) => async (dispatch) => {
  console.log("=================getFullPaidUserList",paidUserParams);
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const query = paidUserParams.query ? paidUserParams.query : "&query=";
    const res = await axios.get(
      `/api/admin/payment/paid-users?limit=${paidUserParams}`,
      config
    );

    if (res.data.status) {
      return {
        status: true,
        data: res.data.response[0].data,
        count: res.data.response[0].metadata[0].totalRecord,
      };
    } else {
      return { status: false, data: [], count: 0 };
    }
  } catch (err) {
    console.log(err);
    return { status: false, data: [], count: 0 };
  }
};

// get refund list
export const getRefundList = (refundParams) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const query = refundParams.query ? refundParams.query : "&query=";
    const res = await axios.get(
      `/api/admin/payment/refund?limit=${refundParams.limit}&page=${refundParams.page}&orderBy=${refundParams.orderBy}&ascending=${refundParams.ascending}${query}`,
      config
    );

    if (res.data.status) {
      return {
        status: true,
        data: res.data.response[0].data,
        count: res.data.response[0].metadata[0].totalRecord,
      };
    } else {
      return { status: false, data: [], count: 0 };
    }
  } catch (err) {
    console.log(err);
    return { status: false, data: [], count: 0 };
  }
};


export const getFullRefundList = (count) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // const query = refundParams.query ? refundParams.query : "&query=";
    const res = await axios.get(
      `/api/admin/payment/refund?limit=${count}`,
      config
    );

    if (res.data.status) {
      return {
        status: true,
        data: res.data.response[0].data,
        count: res.data.response[0].metadata[0].totalRecord,
      };
    } else {
      return { status: false, data: [], count: 0 };
    }
  } catch (err) {
    console.log(err);
    return { status: false, data: [], count: 0 };
  }
};
