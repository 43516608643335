export default function Separator({ label, className = "" }) {
  return (
    <div className={`flex items-center gap-8 text-dgray-500 ${className}`}>
      <div className="h-px bg-dgray-200 flex-1" />
      <div className="uppercase text-inherit text-xs font-semibold">
        {label}
      </div>
      <div className="h-px bg-dgray-200 flex-1" />
    </div>
  );
}
