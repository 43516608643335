import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import * as moment from 'moment-timezone';
import {
  getEvents,
  educatorCancelCourseScheduled,
} from "actions/front/mentorDashboard";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import { SESSION_TYPE } from "constants/index";
import Spinner1 from "views/Spinner1";
import { getCurrentTimeZone } from "utils/time";

const MentorCalendar = ({ getEvents, user, educatorCancelCourseScheduled }) => {
  const [events, setEvents] = useState([]);

  let timeZone = getCurrentTimeZone(user);

  const localizer = momentLocalizer(moment);
  let unixTimeStamp = moment.utc().unix();

  // let currentDate = moment().tz(timeZone).format("YYYY-MM-DD HH:mm");
  let currentDate = moment().tz(timeZone);
  const [calanderLoader, setCalanderLoader] = useState(false);
  const [cancelLoader, setCancelLoader] = useState(false);
  const [maxCancelHr, setMaxCancelHr] = useState(false);

  const getCalanderData = () => {
    getEvents().then(async (data) => {
      if (data.status) {
        var eventlists = [];
        setMaxCancelHr(data.response.settings.cancellation_maxhour);
        let sessionPromise = await data.response.events.map(
          async (singleSession) => {
            if (
              singleSession.session_type != SESSION_TYPE.GENERIC ||
              (singleSession.session_type == SESSION_TYPE.GENERIC &&
                singleSession.session_booking_count == 0)
            ) {
              let start_time = moment
                .unix(singleSession.start_at)
                .tz(timeZone)
                .format("YYYY-MM-DDTHH:mm:ssZ");
              let end_time = moment
                .unix(singleSession.end_at)
                .tz(timeZone)
                .format("YYYY-MM-DDTHH:mm:ssZ");

              eventlists.push({
                start: new Date(start_time),
                end: new Date(end_time),
                title: singleSession.course_name,
                course_id: singleSession.course_id,
                is_booked:
                  singleSession.session_booking_count > 0 ? true : false,
                all_session_with_recurring_id:
                  singleSession.session_type != SESSION_TYPE.GENERIC
                    ? singleSession.all_session_with_recurring_id
                    : "",
                session_group_id: singleSession.session_group_id,
                start_at: singleSession.start_at,
                end_at: singleSession.end_at,
                detail_for: singleSession.session_type,
                check_session_cancelation_start_at: singleSession.check_session_cancelation_start_at,
                is_cancelled:
                  singleSession.session_type != SESSION_TYPE.GENERIC
                    ? singleSession.is_cancelled
                      ? singleSession.is_cancelled
                      : false
                    : false,
              });
            } else {
              let sortedSession = await Promise.all(singleSession.booked_sessions.sort(
                (a, b) => a.start_at - b.start_at
              ));

              if (sortedSession.length !== 0) {
                if (sortedSession[0].start_at != singleSession.start_at) {
                  let start_time = moment
                    .unix(singleSession.start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
                  let end_time = moment
                    .unix(sortedSession[0].start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
  
                  eventlists.push({
                    start: new Date(start_time),
                    end: new Date(end_time),
                    title: singleSession.course_name,
                    course_id: singleSession.course_id,
                    is_booked: false,
                    all_session_with_recurring_id: "",
                    session_group_id: singleSession.session_group_id,
                    start_at: singleSession.start_at,
                    end_at: sortedSession[0].start_at,
                    detail_for: singleSession.session_type,
                    check_session_cancelation_start_at: singleSession.check_session_cancelation_start_at,
                    is_cancelled: false,
                  });
                }
                let sessionIndex = 0;
                while (sessionIndex < sortedSession.length) {
                  let start_time = moment
                    .unix(sortedSession[sessionIndex].start_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
                  let end_time = moment
                    .unix(sortedSession[sessionIndex].end_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
  
                  eventlists.push({
                    start: new Date(start_time),
                    end: new Date(end_time),
                    title: singleSession.course_name,
                    course_id: singleSession.course_id,
                    is_booked: true,
                    all_session_with_recurring_id: "",
                    session_group_id: singleSession.session_group_id,
                    start_at: sortedSession[sessionIndex].start_at,
                    end_at: sortedSession[sessionIndex].end_at,
                    detail_for: singleSession.session_type,
                    check_session_cancelation_start_at: singleSession.check_session_cancelation_start_at,
                    is_cancelled:
                      sortedSession[sessionIndex].is_cancelled == 2
                        ? true
                        : false,
                  });
  
                  if (
                    sessionIndex + 1 != sortedSession.length &&
                    sortedSession[sessionIndex].end_at !=
                    sortedSession[sessionIndex + 1].start_at
                  ) {
                    let start_time = moment
                      .unix(sortedSession[sessionIndex].end_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
                    let end_time = moment
                      .unix(sortedSession[sessionIndex + 1].start_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
  
                    eventlists.push({
                      start: new Date(start_time),
                      end: new Date(end_time),
                      title: singleSession.course_name,
                      course_id: singleSession.course_id,
                      is_booked: true,
                      all_session_with_recurring_id: "",
                      session_group_id: singleSession.session_group_id,
                      start_at: sortedSession[sessionIndex].end_at,
                      end_at: sortedSession[sessionIndex + 1].start_at,
                      detail_for: singleSession.session_type,
                      is_cancelled: false,
                      check_session_cancelation_start_at: singleSession.check_session_cancelation_start_at,
                    });
                  }
  
                  sessionIndex++;
                }
                
                  if (
                    sortedSession[sortedSession.length - 1].end_at !=
                    singleSession.end_at
                  ) {
                    let start_time = moment
                      .unix(sortedSession[sortedSession.length - 1].end_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
                    let end_time = moment
                      .unix(singleSession.end_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");

                    eventlists.push({
                      start: new Date(start_time),
                      end: new Date(end_time),
                      title: singleSession.course_name,
                      course_id: singleSession.course_id,
                      is_booked: false,
                      all_session_with_recurring_id: "",
                      session_group_id: singleSession.session_group_id,
                      start_at: sortedSession[sortedSession.length - 1].end_at,
                      end_at: singleSession.end_at,
                      detail_for: singleSession.session_type,
                      is_cancelled: false,
                      check_session_cancelation_start_at:
                        singleSession.check_session_cancelation_start_at,
                    });
                  }
                } else {
                  let start_time = moment
                      .unix(singleSession.start_at)
                      .tz(timeZone)
                      .format("YYYY-MM-DDTHH:mm:ssZ");
                  let end_time = moment
                    .unix(singleSession.end_at)
                    .tz(timeZone)
                    .format("YYYY-MM-DDTHH:mm:ssZ");
  
                  eventlists.push({
                    start: new Date(start_time),
                    end: new Date(end_time),
                    title: singleSession.course_name,
                    course_id: singleSession.course_id,
                    is_booked: singleSession.session_booking_count > 0 ? true : false,
                    all_session_with_recurring_id: "",
                    session_group_id: singleSession.session_group_id,
                    start_at: singleSession.start_at,
                    end_at: singleSession.end_at,
                    detail_for: singleSession.session_type,
                    check_session_cancelation_start_at: singleSession.check_session_cancelation_start_at,
                    is_cancelled:
                      singleSession.is_cancelled == 2
                        ? true
                        : false,
                  });
                }
              }
          }
        );
        await Promise.all(sessionPromise);
        setEvents(eventlists);
        setCalanderLoader(false);
        setCancelLoader(false);
      }
    });
  };

  useMemo(() => {
    setCalanderLoader(true);
    getCalanderData();
  }, []);

  const [modal, setModal] = useState(false);
  const [modeldata, setModalData] = useState(false);
  const toggle = () => setModal(!modal);
  const onEventClick = (e) => {
    setModal(!modal);
    let modalSetData = { ...e };
    const start_at = moment(modalSetData.start);
    let check_start_date = moment.unix(modalSetData.start_at).tz(timeZone);
    let check_Date = moment(
      moment(currentDate).add(maxCancelHr, "hours").format("YYYY-MM-DD HH:mm")
    );
    if (
      modalSetData.is_booked &&
      start_at.unix() > currentDate.unix() &&
      !modalSetData.is_cancelled &&
      check_start_date.unix() > check_Date.unix()
    ) {
      modalSetData.can_be_cancelled = true;
    } else {
      modalSetData.can_be_cancelled = false;
    }
    setModalData(modalSetData);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    //var backgroundColor = '#' + event.hexColor;
    // const start_at = moment(start).tz(timeZone).format("YYYY-MM-DD HH:mm");
    const start_at = moment(start);
    // let backgroundColor = start_at > currentDate ? '#3174ad' : 'lightblue';
    let backgroundColor = "";
    if (event.is_booked && !event.is_cancelled) {
      backgroundColor = "#28a745"; // for booked and not cancelled
    } else if (start_at.unix() > currentDate.unix()) {
      backgroundColor = "#f39263"; // for upcoming and other
    }

     if (start_at.unix() < currentDate.unix()) {
      backgroundColor = "red"; // for past
    }

     if (event.is_cancelled) {
      backgroundColor = "black"; // for cancelled
    }
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "#FFFFFF",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  const onCancelClick = () => {
    toggle();
    confirmAlert({
      title: <h6>Are you sure you want to cancel this session ?</h6>,
      message: (
        <div>
          <span>Your learners will be notified and 100% refunded</span>
        </div>
      ),
      buttons: [
        {
          label: "Ok",
          onClick: () => confirmCancel(),
        },
        {
          label: "Back",
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const confirmCancel = async () => {
    setCancelLoader(true);
    educatorCancelCourseScheduled(modeldata).then(async (res) => {
      setModal(false);
      setModalData(false);
      if (res.status) {
        getCalanderData();
      } else {
        alert(res.msg);
        setCancelLoader(false);
      }
    });
  };

  return calanderLoader ? (
    <Spinner />
  ) : (
      <section className="card-body">
        <ul className="colorList">
          <li>
            <span className="orange"></span> Availability for sessions
          </li>
          <li>
            <span className="green"></span> Booked
         </li>
          <li>
            <span className="Red"></span> Completed/Expired
          </li>
          <li>
            <span className="black"></span> Cancelled
          </li>
        </ul>
        {cancelLoader && <Spinner1 />}
        <Calendar
          localizer={localizer}
          defaultView="week"
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          onSelectEvent={(event) => onEventClick(event)}
          eventPropGetter={eventStyleGetter}
        />

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>My Detail</ModalHeader>
          <ModalBody>
          <strong><Link to="/dashboard/session-management">Click here to go Session Management for session detail</Link></strong>
            <p className="mydetail-session-name"> Session Title :  {modeldata.title} </p>
          Start Date : {moment(modeldata.start).format(
              "YYYY-MM-DD hh:mm A"
            )}{" "}
            <br />
          End Date : {moment(modeldata.end).format("YYYY-MM-DD hh:mm A")}
            <br />
          Timezone: {moment.tz(timeZone).format("z")}
          </ModalBody>
          <ModalFooter>
            {modeldata.is_booked === true && !modeldata.is_cancelled && unixTimeStamp < modeldata.check_session_cancelation_start_at && (
              <button
                className="btn bg-primary btn-sm"
                style={{ marginBottom: 1 }}
                onClick={(e) => onCancelClick()}
              >
                Cancel Booking
              </button>
            )}
            <Button color="secondary" className="m-1" onClick={toggle}>
              Close
          </Button>
          </ModalFooter>
        </Modal>
      </section>
    );
};

// const mapStateToProps = (state) => ({});
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getEvents,
  educatorCancelCourseScheduled,
})(MentorCalendar);
