import { getMentorsOrders } from "actions/front/myaccount";
import { ReactComponent as GetPaid } from "assets/images/get-paid.svg";
import Button from "components/common/Button";
import DashboardHeader from "components/common/dashboard/Header";
import NotFoundText from "components/common/NotFoundText";
import Pill from "components/common/Pill";
import Table from "components/common/Table";
import DashboardUserTier from "components/dashboard/DashboardUserTier";
import { BOOKED_SESSION_STATUS, SESSION_TYPE_REVERSE } from "constants";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Spinner from "views/Spinner";
import GetPaidPaymentMethodPicker from "./PaymentMethodPicker";

function DashboardGetPaid({ getMentorsOrders }) {
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(initialFilters);
  const [items, setItems] = useState();
  const [totalRecords, setTotalRecords] = useState();

  const fetchData = () => {
    setLoading(true);
    getMentorsOrders({ ...filters, page }).then((res) => {
      if (res.status) {
        setItems(res?.data.data);
        setTotalRecords(res.data.metadata[0].totalRecord);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <div className="py-8 min-h-screen">
      <DashboardHeader
        title="Get Paid"
        description="Track your earnings through your paid events, withdraw your balance."
      />
      <DashboardUserTier />
      <div className="mt-8 text-2xl font-medium mb-6">My Payment Details</div>
      <GetPaidPaymentMethodPicker />

      <div>
        <div className="text-2xl font-medium mb-6">Earning History</div>
        {isLoading ? (
          <Spinner />
        ) : items?.length > 0 ? (
          <Table
            page={page}
            setPage={setPage}
            pageSize={filters.limit}
            count={totalRecords}
            top={
              <>
                Paid Events
                <Pill
                  className="ml-2"
                  text={`${totalRecords} events & sessions`}
                  color="orange"
                />
              </>
            }
            columns={[
              {
                label: "Date",
                Content: ({ data }) => (
                  <div>
                    {moment.unix(data.booked_on).format("MMM DD, YYYY")}
                  </div>
                ),
              },
              { label: "Event Name", key: "course_name" },
              {
                label: "Event Type",
                Content: ({ data }) => (
                  <div>{SESSION_TYPE_REVERSE[data.session_type]}</div>
                ),
              },
              {
                label: "Order Status",
                Content: ({ data }) => (
                  <div className="text-dgray-900 font-medium">
                    {data.order_status === BOOKED_SESSION_STATUS.BOOKED ? (
                      <Pill text="Booked" color="green" />
                    ) : data.order_status ===
                      BOOKED_SESSION_STATUS.CANCLLED_BY_MENTOR ? (
                      <Pill text="Cancelled by Host" color="pink" />
                    ) : data.order_status ===
                      BOOKED_SESSION_STATUS.CANCLLED_BY_LEARNER ? (
                      <Pill text="Cancelled by Student" color="red" />
                    ) : (
                      ""
                    )}
                  </div>
                ),
              },
              {
                label: "Price",
                Content: ({ data }) => (
                  <div className="text-dgray-900 font-medium">
                    $
                    {data.net_amount
                      ? `${data.net_amount}`.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : 0}
                  </div>
                ),
              },
            ]}
            rows={items}
          />
        ) : (
          <NotFoundText>No events found</NotFoundText>
        )}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  {
    getMentorsOrders,
  }
)(DashboardGetPaid);

const initialFilters = {
  limit: 7,
  page: 1,
  orderBy: "booked_on",
  ascending: "desc",
  query: "",
  custom_filters: "",
};
