import React, { useState, useMemo, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import moment from "moment";

import CourseInformation from "./CourseInformation";
import SessionInformation from "./Session/SessionInformation";
import CalendarInformation from "./Session/CalendarInformation";
import TabsHeader from "./TabsHeader";

import { RECURRING_TYPE_REVERSE, TABS, SESSION_TYPE } from "constants/index";
import { EDUCATOR_COURSE_CREATE_ERROR } from "actions/types";

import { createCourse , createDraftCourse } from "actions/front/educator/courses";
import { getTimeZone } from "utils/time";

const AddCourse = ({ history, createCourse, createDraftCourse, auth: { user } }) => {
  const dispatch = useDispatch();
  const wizardRef = useRef(null);

  /*********************current tab state************************/

  const [activeTab, setActiveTab] = useState(TABS.BASIC);
  /*********************error msg ************************/
  const [basicErrorMsg, setBasicErrorMsg] = useState("");
  const [sessionErrorMsg, setSessionErrorMsg] = useState("");

  /*********************scroll to top when the tab is changed************************/
  useMemo(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);

  /*********************enable disable session tab************************/

  const [sessionEnable, setSessionEnable] = useState(false);
  /*********************enable disable calander and confirmation tab************************/

  const [
    calendarConfirmationTabEnable,
    setCalendarConfirmationTabEnable,
  ] = useState(false);

  useMemo(() => {
    if (!sessionEnable) {
      setCalendarConfirmationTabEnable(false);
    }
  }, [sessionEnable]);

  /*********************Information Tab Start Here************************/

  /*********************course basic information form fields ************************/
  const [courseInformationFormData, setcourseInformationFormData] = useState({
    category_id: "",
    sub_category_id: "",
    name: "",
    image: "",
    overview: "",
    ideal_for: "",
    course_information: "",
    category_type: "",
    is_individual: false,
    is_group: false,
  });

  /*********************Information Tab End Here************************/
  /*********************Session Tab Start Here************************/

  /*********************Type of session************************/

  const [typeOfSession, setTypeOfSession] = useState(false); //1: generic 1-1, 2: specific 1-1, 3: specific group, 4 specific both

  /*********************currnent date for default selection************************/
  let timeZone = getTimeZone(user);
  var current_date = moment.tz(timeZone).format("YYYY-MM-DD");

  /*********************default object of date and time for specific and generic************************/
  const defaultSpecificIndividualDateTimingInput = {
    start_date: "",
    start_time: "00:00",
  };
  const defaultSpecificIndividualSessionGroup = {
    sessions: [{ ...defaultSpecificIndividualDateTimingInput }],
    recurring_type: RECURRING_TYPE_REVERSE.NONE,
    recurring_end_date: current_date,
    recurring_min_date: current_date,
    recurring_type_min: RECURRING_TYPE_REVERSE.NONE,
    is_new: true,
    all_session_with_recurring: [],
  };

  const defaultSpecificGroupDateTimingInput = {
    start_date: "",
    start_time: "00:00",
  };
  const defaultSpecificGroupSessionGroup = {
    sessions: [{ ...defaultSpecificGroupDateTimingInput }],
    recurring_type: RECURRING_TYPE_REVERSE.NONE,
    recurring_end_date: current_date,
    recurring_min_date: current_date,
    recurring_type_min: RECURRING_TYPE_REVERSE.NONE,
    is_new: true,
    all_session_with_recurring: [],
  };

  const defaultGenericDateTimingInput = {
    start_date: "",
    start_time: "00:00",
    min_end_date: current_date,
    max_end_date: "",
    max_end_time: "",
    min_end_time: "",
    end_date: "",
    end_time: "00:15",
  };
  const defaultGenericSessionGroup = {
    sessions: [{ ...defaultGenericDateTimingInput }],
    recurring_type: RECURRING_TYPE_REVERSE.NONE,
    recurring_end_date: current_date,
    recurring_min_date: current_date,
    recurring_type_min: RECURRING_TYPE_REVERSE.NONE,
    is_new: true,
    all_session_with_recurring: [],
  };

  /*********************initiall and usestate for session form data************************/
  const initiallSessionFormData = {
    specific_individual: {
      duration_per_session: "",
      price_per_session: "",
      number_of_session: 1,
      can_add_new_bundle: true,
      session_group: [{ ...defaultSpecificIndividualSessionGroup }],
      all_session_with_recurring: [],
    },
    specific_group: {
      duration_per_session: "",
      price_per_session: "",
      max_students: "",
      number_of_session: 1,
      can_add_new_bundle: true,
      session_group: [{ ...defaultSpecificGroupSessionGroup }],
      all_session_with_recurring: [],
    },
    generic: {
      duration_info: [{ duration_per_session: "", price_per_session: "" }],
      session_group: [{ ...defaultGenericSessionGroup }],
      all_session_with_recurring: [],
      // recurring_type: RECURRING_TYPE_REVERSE.NONE,
      // recurring_end_date: current_date,
    },
  };
  const [sessionFormData, setSessionFormData] = useState({
    ...initiallSessionFormData,
  });

  /*********************reset session form data************************/
  const resetSessionFormData = () => {
    setSessionFormData({ ...initiallSessionFormData });
  };

  /*********************Session Tab End Here************************/
  /*********************on click of back button************************/

  const onClickBackNextButton = (e, onTab) => {
    setActiveTab(onTab);
  };

  /*********************submit loader ************************/
  const [submitLoading, setSubmitLoading] = useState(false);

  /*********************on submit of  course form************************/
  const onSubmitForm = (e) => {
    setSubmitLoading(true);
    createCourse(
      courseInformationFormData,
      sessionFormData,
      typeOfSession,
      history
    ).then((res) => {
      if (!res.status) {
        if (res.isErrorInBasic) {
          setBasicErrorMsg(res.msg);
          setActiveTab(TABS.BASIC);
        } else {
          setActiveTab(TABS.SESSION);
          setSessionErrorMsg(res.msg);
        }
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    });
  };
  const onSubmitDraftForm = (e) => {
    setSubmitLoading(true);
    createDraftCourse(
      courseInformationFormData,
      sessionFormData,
      typeOfSession,
      history,
      activeTab
    ).then((res) => {
      if (!res.status) {
        if (res.isErrorInBasic) {
          setBasicErrorMsg(res.msg);
          setActiveTab(TABS.BASIC);
        } else {
          setActiveTab(TABS.SESSION);
          setSessionErrorMsg(res.msg);
        }
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    });
  };

  /*****check for session validation while click on next button of session tab or calander tab click*******/
  const checkSessionValidation = async () => {
    let errorsList = [];
    let isError = false;
    let allSession = [];
    let hasRecurringError = false;

    if (
      typeOfSession === SESSION_TYPE.SPECIFIC_INDIVIDUAL ||
      typeOfSession === SESSION_TYPE.SPECIFIC_BOTH
    ) {
      let allDataPromise = getSpecificIndividualAllSessionDataAndErrorIfAny();
      await Promise.resolve(allDataPromise).then((res) => {
        let allData = res;
        allSession.push(...allData.allSession);
        errorsList = allData.errorsList;
        hasRecurringError = allData.hasRecurringError;
        setSessionFormData({
          ...sessionFormData,
          specific_individual: {
            ...sessionFormData.specific_individual,
            session_group: allData.recurrenceSessionGroup,
            all_session_with_recurring: [...allSession],
          },
        });
      });
    }
    if (
      typeOfSession === SESSION_TYPE.SPECIFIC_GROUP ||
      typeOfSession === SESSION_TYPE.SPECIFIC_BOTH
    ) {
      let allDataPromise = getSpecificGroupAllSessionDataAndErrorIfAny();
      await Promise.resolve(allDataPromise).then((res) => {
        let allData = res;
        allSession.push(...allData.allSession);
        errorsList = allData.errorsList;
        hasRecurringError = allData.hasRecurringError;
        setSessionFormData({
          ...sessionFormData,
          specific_group: {
            ...sessionFormData.specific_group,
            session_group: allData.recurrenceSessionGroup,
            all_session_with_recurring: [...allSession],
          },
        });
      });
    }
    if (typeOfSession === SESSION_TYPE.GENERIC) {
      let allDataPromise = getGenericAllSessionDataAndErrorIfAny();
      await Promise.resolve(allDataPromise).then((res) => {
        let allData = res;
        allSession.push(...allData.allSession);
        errorsList = allData.errorsList;
        hasRecurringError = allData.hasRecurringError;
        setSessionFormData({
          ...sessionFormData,
          generic: {
            ...sessionFormData.generic,
            session_group: allData.recurrenceSessionGroup,
            all_session_with_recurring: [...allSession],
          },
        });
      });
    }

    if (allSession.length > 1 && !hasRecurringError) {
      let sortedSession = await allSession.sort(
        (a, b) => a.start_at - b.start_at
      );

      let checkValidationPromise = await sortedSession.map(
        async (session, idx) => {
          var remainingSortedSession = sortedSession.slice(
            idx + 1,
            sortedSession.length + 1
          );
          let remainingSessionPromise = await remainingSortedSession.map(
            async (remainingSession) => {
              if (
                (session.type == remainingSession.type &&
                  session.type != SESSION_TYPE.GENERIC &&
                  ((session.bundleIndex == remainingSession.bundleIndex &&
                    session.index != remainingSession.index) ||
                    session.bundleIndex != remainingSession.bundleIndex)) ||
                (session.type == SESSION_TYPE.GENERIC &&
                  session.index != remainingSession.index) ||
                session.type != remainingSession.type
              ) {
                let conflictStatus = {};
                let conflictPromise = await checkForConflict(
                  session,
                  remainingSession
                );

                await Promise.resolve(conflictPromise).then((value) => {
                  conflictStatus = value;
                });
                if (conflictStatus.hasError && !conflictStatus.hasException) {
                  isError = true;
                  if (
                    !errorsList[
                      `${
                        conflictStatus.type == SESSION_TYPE.SPECIFIC_INDIVIDUAL
                          ? "individual_start_date_" +
                            conflictStatus.bundleIndex +
                            "_"
                          : conflictStatus.type == SESSION_TYPE.SPECIFIC_GROUP
                          ? "group_start_date_" +
                            conflictStatus.bundleIndex +
                            "_"
                          : "generic_start_date_"
                      }${conflictStatus.index}`
                    ]
                  ) {
                    errorsList[
                      `${
                        conflictStatus.type == SESSION_TYPE.SPECIFIC_INDIVIDUAL
                          ? "individual_start_date_" +
                            conflictStatus.bundleIndex +
                            "_"
                          : conflictStatus.type == SESSION_TYPE.SPECIFIC_GROUP
                          ? "group_start_date_" +
                            conflictStatus.bundleIndex +
                            "_"
                          : "generic_start_date_"
                      }${conflictStatus.index}`
                    ] = `Session date and time conflict at ${moment(
                      conflictStatus.start_at
                    ).format("MMMM Do YYYY, h:mm A")} please check.`;
                  }
                }
              }
            }
          );

          await Promise.all(remainingSessionPromise);
        }
      );

      await Promise.all(checkValidationPromise);
    }
    if (isError || hasRecurringError) {
      dispatch({
        type: EDUCATOR_COURSE_CREATE_ERROR,
        payload: {
          message: "Please correct the errors",
          alertType: "danger",
          errorsList: errorsList,
        },
      });

      return false;
    } else {
      return true;
    }
  };
  const getSpecificIndividualAllSessionDataAndErrorIfAny = async () => {
    let errorsList = [];
    let allSession = [];
    let hasRecurringError = false;
    let recurrenceSessionGroup = [
      ...sessionFormData.specific_individual.session_group,
    ];
    let sessionDuration =
      sessionFormData.specific_individual.duration_per_session;

    let sessionBundlePromise = await recurrenceSessionGroup.map(
      async (singleBundle, bundleIndex) => {
        let new_all_session_with_recurring = [];
        let sessionPromise = await singleBundle.sessions.map(
          async (singleSession, index) => {
            let recurringEndDate = moment(
              moment(
                singleBundle.recurring_end_date + " " + singleSession.start_time
              )
                .add(sessionDuration, "minutes")
                .format("YYYY-MM-DD HH:mm")
            );

            let selected_start_date = moment(
              singleSession.start_date + " " + singleSession.start_time
            );
            let selected_end_date = moment(
              selected_start_date
                .clone()
                .add(sessionDuration, "minutes")
                .format("YYYY-MM-DD HH:mm")
            );

            if (
              singleBundle.recurring_type != RECURRING_TYPE_REVERSE.NONE &&
              recurringEndDate <= selected_start_date
            ) {
              hasRecurringError = true;
              errorsList[`individual_recurring_end_date_${bundleIndex}`] =
                "Recurring Date must be greater than above session date(s).";
            }

            let current_date_time = moment.tz(timeZone);
            if (current_date_time.unix() > selected_start_date.unix()) {
              hasRecurringError = true;
              errorsList[`individual_start_date_${bundleIndex}_${index}`] =
                "Session date and time must be greater then current date and time.";
            } else {
              let newData = {
                start_at: selected_start_date,
                start_time: singleSession.start_time,
                end_at: selected_end_date,
                start_date_time: moment(selected_start_date).format(
                  "YYYY-MM-DD HH:mm"
                ),
                end_date_time: moment(selected_end_date).format(
                  "YYYY-MM-DD HH:mm"
                ),
                bundleIndex: bundleIndex,
                index: index,
                type: SESSION_TYPE.SPECIFIC_INDIVIDUAL,
              };
              if (singleBundle.recurring_type != RECURRING_TYPE_REVERSE.NONE) {
                let recurringDataPromise = getRecurringAllSessionData(
                  newData,
                  singleBundle.recurring_type,
                  recurringEndDate,
                  allSession
                );

                await Promise.resolve(recurringDataPromise).then((res) => {
                  let recurringData = res;
                  new_all_session_with_recurring.push(...recurringData);
                  allSession.push(...recurringData);
                });
              } else {
                allSession.push(newData);
                new_all_session_with_recurring.push({ ...newData });
              }
            }
          }
        );
        await Promise.all(sessionPromise);
        recurrenceSessionGroup[
          bundleIndex
        ].all_session_with_recurring = new_all_session_with_recurring;
      }
    );
    await Promise.all(sessionBundlePromise);
    return {
      errorsList,
      allSession,
      hasRecurringError,
      recurrenceSessionGroup,
    };
  };

  const getSpecificGroupAllSessionDataAndErrorIfAny = async () => {
    let errorsList = [];
    let allSession = [];
    let hasRecurringError = false;
    let recurrenceSessionGroup = [
      ...sessionFormData.specific_group.session_group,
    ];
    let sessionDuration = sessionFormData.specific_group.duration_per_session;

    let sessionBundlePromise = await recurrenceSessionGroup.map(
      async (singleBundle, bundleIndex) => {
        let new_all_session_with_recurring = [];
        let sessionPromise = await singleBundle.sessions.map(
          async (singleSession, index) => {
            let recurringEndDate = moment(
              moment(
                singleBundle.recurring_end_date + " " + singleSession.start_time
              )
                .add(sessionDuration, "minutes")
                .format("YYYY-MM-DD HH:mm")
            );

            let selected_start_date = moment(
              singleSession.start_date + " " + singleSession.start_time
            );
            let selected_end_date = moment(
              selected_start_date
                .clone()
                .add(sessionDuration, "minutes")
                .format("YYYY-MM-DD HH:mm")
            );
            if (
              singleBundle.recurring_type != RECURRING_TYPE_REVERSE.NONE &&
              recurringEndDate <= selected_start_date
            ) {
              hasRecurringError = true;
              errorsList[`group_recurring_end_date_${bundleIndex}`] =
                "Recurring Date must be greater than above session date(s).";
            }

            let current_date_time = moment.tz(timeZone);
            if (current_date_time.unix() > selected_start_date.unix()) {
              hasRecurringError = true;
              errorsList[`group_start_date_${bundleIndex}_${index}`] =
                "Session date and time must be greater then current date and time.";
            } else {
              let newData = {
                start_at: selected_start_date,
                start_date_time: moment(selected_start_date).format(
                  "YYYY-MM-DD HH:mm"
                ),
                end_date_time: moment(selected_end_date).format(
                  "YYYY-MM-DD HH:mm"
                ),
                start_time: singleSession.start_time,
                end_at: selected_end_date,
                bundleIndex: bundleIndex,
                index: index,
                type: SESSION_TYPE.SPECIFIC_GROUP,
              };
              if (singleBundle.recurring_type != RECURRING_TYPE_REVERSE.NONE) {
                let recurringDataPromise = getRecurringAllSessionData(
                  newData,
                  singleBundle.recurring_type,
                  recurringEndDate,
                  allSession
                );

                await Promise.resolve(recurringDataPromise).then((res) => {
                  let recurringData = res;
                  new_all_session_with_recurring.push(...recurringData);
                  allSession.push(...recurringData);
                });
              } else {
                allSession.push(newData);
                new_all_session_with_recurring.push({ ...newData });
              }
            }
          }
        );
        await Promise.all(sessionPromise);
        recurrenceSessionGroup[
          bundleIndex
        ].all_session_with_recurring = new_all_session_with_recurring;
      }
    );
    await Promise.all(sessionBundlePromise);
    return {
      errorsList,
      allSession,
      hasRecurringError,
      recurrenceSessionGroup,
    };
  };

  const getGenericAllSessionDataAndErrorIfAny = async () => {
    let errorsList = [];
    let allSession = [];
    let hasRecurringError = false;
    let recurrenceSessionGroup = [...sessionFormData.generic.session_group];

    let sessionPromise = recurrenceSessionGroup.map(
      async (singleSession, index) => {
        let new_all_session_with_recurring = [];
        let recurringEndDate = moment(
          singleSession.sessions[0].end_time == "00:00"
            ? moment(singleSession.recurring_end_date)
                .add(1, "days")
                .format("YYYY-MM-DD")
            : singleSession.recurring_end_date +
                " " +
                singleSession.sessions[0].end_time
        );

        let selected_start_date = moment(
          singleSession.sessions[0].start_date +
            " " +
            singleSession.sessions[0].start_time
        );
        let selected_end_date = moment(
          singleSession.sessions[0].end_time == "00:00"
            ? moment(singleSession.sessions[0].start_date)
                .add(1, "days")
                .format("YYYY-MM-DD")
            : singleSession.sessions[0].start_date +
                " " +
                singleSession.sessions[0].end_time
        );
        let end_date = moment(
          singleSession.sessions[0].end_time == "00:00"
            ? moment(singleSession.sessions[0].end_date)
                .add(1, "days")
                .format("YYYY-MM-DD")
            : singleSession.sessions[0].end_date +
                " " +
                singleSession.sessions[0].end_time
        );

        if (
          singleSession.recurring_type != RECURRING_TYPE_REVERSE.NONE &&
          recurringEndDate < selected_start_date
        ) {
          hasRecurringError = true;
          errorsList[`generic_recurring_end_date_${index}`] =
            "Recurring Date must be greater than session date.";
        }

        let current_date_time = moment.tz(timeZone);
        if (current_date_time.unix() > selected_start_date.unix()) {
          hasRecurringError = true;
          errorsList[`generic_start_date_${index}`] =
            "Session date and time must be greater then current date and time.";
        } else {
          if (selected_end_date.unix() <= selected_start_date.unix()) {
            hasRecurringError = true;
            errorsList[`generic_start_date_${index}`] =
              "Session end date and time must be greater than start date and time.";
          } else {
            while (end_date.unix() > selected_start_date.unix()) {
              let newData = {
                start_at: selected_start_date,
                start_date_time: moment(selected_start_date).format(
                  "YYYY-MM-DD HH:mm"
                ),
                start_time: singleSession.sessions[0].start_time,
                end_time: singleSession.sessions[0].end_time,
                end_at: selected_end_date,
                end_date_time: moment(selected_end_date).format(
                  "YYYY-MM-DD HH:mm"
                ),
                index: index,
                type: SESSION_TYPE.GENERIC,
                // recurring_type: singleSession.recurring_type,
                // recurring_end_date: recurringEndDate,
              };
              if (singleSession.recurring_type != RECURRING_TYPE_REVERSE.NONE) {
                let recurringDataPromise = getRecurringAllSessionData(
                  newData,
                  singleSession.recurring_type,
                  recurringEndDate,
                  allSession
                );

                await Promise.resolve(recurringDataPromise).then((res) => {
                  let recurringData = res;
                  new_all_session_with_recurring.push(...recurringData);
                  allSession.push(...recurringData);
                });
              } else {
                allSession.push(newData);
                new_all_session_with_recurring.push({ ...newData });
              }

              // recurringEndDate = moment(
              //   recurringEndDate.clone().add(1, "days").format("YYYY-MM-DD HH:mm")
              // );
              selected_start_date = moment(
                selected_start_date
                  .clone()
                  .add(1, "days")
                  .format("YYYY-MM-DD HH:mm")
              );

              selected_end_date = moment(
                selected_end_date
                  .clone()
                  .add(1, "days")
                  .format("YYYY-MM-DD HH:mm")
              );
            }

            let sessionChuncksPromise = getSessionChunks(
              new_all_session_with_recurring
            );

            await Promise.resolve(sessionChuncksPromise).then(
              (sessionChunks) => {
                recurrenceSessionGroup[index].all_session_with_recurring = [
                  ...sessionChunks,
                ];
              }
            );
            recurrenceSessionGroup[
              index
            ].all_session_with_recurring_without_chuks = new_all_session_with_recurring;
          }
        }
      }
    );
    await Promise.all(sessionPromise);
    return {
      errorsList,
      allSession,
      hasRecurringError,
      recurrenceSessionGroup,
    };
  };

  const getSessionChunks = async (new_all_session_with_recurring) => {
    let sessionChunks = [];
    let sessionChunksPromise = await new_all_session_with_recurring.map(
      async (singleSession) => {
        let selected_start_date = moment(
          singleSession.start_at.clone().format("YYYY-MM-DD HH:mm")
        );
        let selected_end_date = moment(
          singleSession.start_at
            .clone()
            .add(15, "minutes")
            .format("YYYY-MM-DD HH:mm")
        );
        let end_date = moment(
          singleSession.end_at.clone().format("YYYY-MM-DD HH:mm")
        );

        while (selected_end_date.unix() <= end_date.unix()) {
          // if (selected_start_date != currentSession.start_at)
          sessionChunks.push({
            ...singleSession,
            type: singleSession.type,
            start_date_time: moment(selected_start_date).format(
              "YYYY-MM-DD HH:mm"
            ),
            end_date_time: moment(selected_end_date).format("YYYY-MM-DD HH:mm"),
            start_at: selected_start_date,
            end_at: selected_end_date,
          });
          selected_start_date = moment(
            selected_start_date
              .clone()
              .add(15, "minutes")
              .format("YYYY-MM-DD HH:mm")
          );
          selected_end_date = moment(
            selected_end_date
              .clone()
              .add(15, "minutes")
              .format("YYYY-MM-DD HH:mm")
          );
        }
      }
    );

    await Promise.all(sessionChunksPromise);
    return sessionChunks;
  };

  const getRecurringAllSessionData = async (
    currentSession,
    recurringType,
    recurringEndDate,
    allSession
  ) => {
    let sessionRecurringData = [];
    let selected_start_date = moment(
      currentSession.start_at.clone().format("YYYY-MM-DD HH:mm")
    );
    let selected_end_date = moment(
      currentSession.end_at.clone().format("YYYY-MM-DD HH:mm")
    );
    switch (Number(recurringType)) {
      case RECURRING_TYPE_REVERSE.DAILY:
        while (selected_end_date.unix() <= recurringEndDate.unix()) {
          // if (selected_start_date != currentSession.start_at)
          sessionRecurringData.push({
            ...currentSession,
            start_date_time: moment(selected_start_date).format(
              "YYYY-MM-DD HH:mm"
            ),
            end_date_time: moment(selected_end_date).format("YYYY-MM-DD HH:mm"),

            start_at: selected_start_date,
            end_at: selected_end_date,
          });
          selected_start_date = moment(
            selected_start_date
              .clone()
              .add(1, "days")
              .format("YYYY-MM-DD HH:mm")
          );
          selected_end_date = moment(
            selected_end_date.clone().add(1, "days").format("YYYY-MM-DD HH:mm")
          );
        }
        break;
      case RECURRING_TYPE_REVERSE.WEEKLY:
        while (selected_end_date.unix() <= recurringEndDate.unix()) {
          // if (selected_start_date != currentSession.start_at)
          sessionRecurringData.push({
            ...currentSession,
            start_date_time: moment(selected_start_date).format(
              "YYYY-MM-DD HH:mm"
            ),
            end_date_time: moment(selected_end_date).format("YYYY-MM-DD HH:mm"),
            start_at: selected_start_date,
            end_at: selected_end_date,
          });
          selected_start_date = moment(
            selected_start_date
              .clone()
              .add(7, "days")
              .format("YYYY-MM-DD HH:mm")
          );
          selected_end_date = moment(
            selected_end_date.clone().add(7, "days").format("YYYY-MM-DD HH:mm")
          );
        }

        break;
      case RECURRING_TYPE_REVERSE.MONTHLY:
        let start_at = selected_start_date.clone();
        let end_at = selected_end_date.clone();
        let addMonth = 1;
        while (end_at.unix() <= recurringEndDate.unix()) {
          // if (selected_start_date != currentSession.start_at)
          let isExist = allSession.filter((session) => {
            return (
              session.start_at.unix() == start_at.unix() &&
              session.end_at.unix() == end_at.unix()
            );
          });
          if (isExist.length == 0) {
            sessionRecurringData.push({
              ...currentSession,
              start_date_time: moment(start_at).format("YYYY-MM-DD HH:mm"),
              end_date_time: moment(end_at).format("YYYY-MM-DD HH:mm"),
              start_at: start_at,
              end_at: end_at,
            });
          }
          start_at = moment(
            selected_start_date
              .clone()
              .add(addMonth, "months")
              .format("YYYY-MM-DD HH:mm")
          );
          end_at = moment(
            selected_end_date
              .clone()
              .add(addMonth, "months")
              .format("YYYY-MM-DD HH:mm")
          );
          addMonth += 1;
        }

        break;

      default:
        break;
    }
    return sessionRecurringData;
  };

  const checkForConflict = async (firstDate, secondDate) => {
    try {
      if (
        (firstDate.start_at.unix() < secondDate.start_at.unix() &&
          ((secondDate.start_at.unix() < firstDate.end_at.unix() &&
            firstDate.end_at.unix() <= secondDate.end_at.unix()) ||
            firstDate.end_at.unix() > secondDate.end_at.unix() ||
            secondDate.end_at.unix() <= firstDate.end_at.unix())) ||
        (firstDate.start_at.unix() == secondDate.start_at.unix() &&
          ((secondDate.start_at.unix() < firstDate.end_at.unix() &&
            firstDate.end_at.unix() < secondDate.end_at.unix()) ||
            secondDate.end_at.unix() <= firstDate.end_at.unix()))
      ) {
        return {
          hasError: true,
          index: secondDate.index,
          bundleIndex: secondDate.bundleIndex ? secondDate.bundleIndex : 0,
          start_at: secondDate.start_at,
          type: secondDate.type,
          hasException: false,
        };
      } else {
        return { hasError: false, hasException: false };
      }
    } catch (error) {
      // console.log(error);
      return { hasError: true, hasException: true };
    }
  };

  return (
    <div className="row p-60 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-head space-between mb-3">
              <h2>Add New Session</h2>
              <Link to="/dashboard">Back to Dashboard</Link>
            </div>
            <div id="login" className="bg-white">
              <div className="wrapper">
                <div className="inner">
                  <div id="wizard" ref={wizardRef}>
                    <TabsHeader
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      TABS={TABS}
                      sessionEnable={sessionEnable}
                      calendarConfirmationTabEnable={
                        calendarConfirmationTabEnable
                      }
                      checkSessionValidation={checkSessionValidation}
                      submitLoading={submitLoading}
                    />
                    {/* SECTION 1 */}
                    {activeTab == TABS.BASIC && (
                      <CourseInformation
                        isDraft={true}
                        courseInformationFormData={courseInformationFormData}
                        setcourseInformationFormData={
                          setcourseInformationFormData
                        }
                        sessionEnable={sessionEnable}
                        setSessionEnable={setSessionEnable}
                        setTypeOfSession={setTypeOfSession}
                        setActiveTab={setActiveTab}
                        resetSessionFormData={resetSessionFormData}
                        basicErrorMsg={basicErrorMsg}
                        setBasicErrorMsg={setBasicErrorMsg}
                        onSubmitDraftForm={onSubmitDraftForm}
                      />
                    )}

                    {/* SECTION 2 */}
                    {activeTab == TABS.SESSION && (
                      <SessionInformation
                        typeOfSession={typeOfSession}
                        sessionFormData={sessionFormData}
                        setSessionFormData={setSessionFormData}
                        setActiveTab={setActiveTab}
                        setCalendarConfirmationTabEnable={
                          setCalendarConfirmationTabEnable
                        }
                        calendarConfirmationTabEnable={
                          calendarConfirmationTabEnable
                        }
                        checkSessionValidation={checkSessionValidation}
                        defaultGenericSessionGroup={defaultGenericSessionGroup}
                        defaultGenericDateTimingInput={
                          defaultGenericDateTimingInput
                        }
                        defaultSpecificIndividualSessionGroup={
                          defaultSpecificIndividualSessionGroup
                        }
                        defaultSpecificIndividualDateTimingInput={
                          defaultSpecificIndividualDateTimingInput
                        }
                        defaultSpecificGroupDateTimingInput={
                          defaultSpecificGroupDateTimingInput
                        }
                        defaultSpecificGroupSessionGroup={
                          defaultSpecificGroupSessionGroup
                        }
                        current_date={current_date}
                        sessionErrorMsg={sessionErrorMsg}
                        setSessionErrorMsg={setSessionErrorMsg}
                        onSubmitDraftForm={onSubmitDraftForm}
                      />
                    )}

                    {/* SECTION 3 */}
                    {activeTab == TABS.CALENDAR && (
                      <CalendarInformation
                        courseInformationFormData={courseInformationFormData}
                        typeOfSession={typeOfSession}
                        sessionFormData={sessionFormData}
                        setActiveTab={setActiveTab}
                        calendarConfirmationTabEnable={
                          calendarConfirmationTabEnable
                        }
                        onSubmitDraftForm={onSubmitDraftForm}
                      />
                    )}

                    {/* SECTION 4 */}
                    {activeTab == TABS.CONFIRMATION && (
                      <>
                        <CourseInformation
                          courseInformationFormData={courseInformationFormData}
                          setcourseInformationFormData={
                            setcourseInformationFormData
                          }
                          sessionEnable={sessionEnable}
                          setSessionEnable={setSessionEnable}
                          setTypeOfSession={setTypeOfSession}
                          setActiveTab={setActiveTab}
                          resetSessionFormData={resetSessionFormData}
                          isConfirmation={true}
                          basicErrorMsg={basicErrorMsg}
                          setBasicErrorMsg={setBasicErrorMsg}
                          onSubmitDraftForm={onSubmitDraftForm}
                        />
                        <SessionInformation
                          typeOfSession={typeOfSession}
                          sessionFormData={sessionFormData}
                          setSessionFormData={setSessionFormData}
                          setActiveTab={setActiveTab}
                          setCalendarConfirmationTabEnable={
                            setCalendarConfirmationTabEnable
                          }
                          calendarConfirmationTabEnable={
                            calendarConfirmationTabEnable
                          }
                          isConfirmation={true}
                          defaultGenericSessionGroup={
                            defaultGenericSessionGroup
                          }
                          defaultGenericDateTimingInput={
                            defaultGenericDateTimingInput
                          }
                          sessionErrorMsg={sessionErrorMsg}
                          setSessionErrorMsg={setSessionErrorMsg}
                          onSubmitDraftForm={onSubmitDraftForm}
                        />
                        <div className="col-md-12">
                          <hr />
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              {!submitLoading && (
                                <button
                                  className="btn btn-primary"
                                  onClick={(e) => {
                                    onClickBackNextButton(e, TABS.CALENDAR);
                                  }}
                                >
                                  Back
                                </button>
                              )}
                            </div>
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                              <div className="form-group text-right mb-0">
                                {submitLoading ? (
                                  <Spinner />
                                ) : (
                                  <button
                                    className="btn btn-secondary mb-0"
                                    onClick={(e) => onSubmitForm(e)}
                                  >
                                    Publish Session
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { createCourse , createDraftCourse})(
  withRouter(AddCourse)
);
