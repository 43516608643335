import * as Yup from "yup";

const createBlogSchema = Yup.object({
  //header_image: Yup.string(),
  title: Yup.string().required("Please enter a title"),
  meta_description: Yup.string()
    .required()
    .min(50, "Please enter at least 50 characters")
    .max(200, "Please enter maximum 200 characters"),
  meta_keywords: Yup.array(),
  category: Yup.string().required(),
  content: Yup.string()
});

export default createBlogSchema;
