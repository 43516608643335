import axios from "utils/axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  CMS_CREATED,
  CMS_ERROR,
  DELETE_CMS,
  CMS_UPDATED,
  CMS_LIST_UPDATED,
  GET_CMS_BY_ID,
  CMS_SEARCH_PARAMATERS_UPDATE,
  REMOVE_ERRORS,
  CHANGE_CMS_STATUS,
  LOADING_ON_CMS_SUBMIT
} from "actions/types";

// Create CMS
export const create = (formData, history) => async dispatch => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnCMSSubmit());
  const config = {
    headers: {
      "content-type": "application/json; multipart/form-data;"
    }
  };
  try {
    let param = new window.FormData();
    param.append("file", formData.selectedFile);
    param.append("slug", formData.slug);
    param.append("page_title", formData.page_title);
    param.append("page_header", formData.page_header);
    param.append("meta_description", formData.meta_description);
    param.append("description", formData.description);
    param.append("image_name", formData.image_name);

    // console.log("get ", param.get("file"));
    const res = await axios.post(`/api/admin/cms/create`, param, config);
    if (res.data.status === true) {
      dispatch({
        type: CMS_CREATED,
        payload: res.data.response
      });
      // dispatch({ type: REMOVE_ALERT });
      dispatch(setAlert("CMS Page Created.", "success"));
      history.push("/admin/cms");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: CMS_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach(error => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Edit CMS
export const edit = (formData, history, cms_id) => async dispatch => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnCMSSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    let param = new window.FormData();
    param.append("file", formData.selectedFile);
    param.append("slug", formData.slug);
    param.append("page_title", formData.page_title);
    param.append("page_header", formData.page_header);
    param.append("meta_description", formData.meta_description);
    param.append("description", formData.description);
    param.append("thumbnail", formData.thumbnail);
    param.append("image", formData.image);
    const res = await axios.post(`/api/admin/cms/${cms_id}`, param, config);
    if (res.data.status === true) {
      dispatch({
        type: CMS_UPDATED,
        payload: res.data.response
      });
      // dispatch({ type: REMOVE_ALERT });
      dispatch(setAlert("CMS Page Updated.", "success"));
      history.push("/admin/cms");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: CMS_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach(error => {
          // if(error.param==="description")
          // dispatch(setAlert(error.msg, "danger"));
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete CMS Page
export const deleteCMS = cms_id => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    await axios.delete(`/api/admin/cms/${cms_id}`, config);
    // dispatch({ type: REMOVE_ALERT });

    dispatch({
      type: DELETE_CMS,
      payload: cms_id
    });
    dispatch(setAlert("CMS Page deleted.", "success"));
  } catch (err) {
    // console.log(err);
    dispatch({
      type: CMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//get CMS list

export const getCMSList = cmsParams => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const query = cmsParams.query ? cmsParams.query : "";
    const res = await axios.get(
      `/api/admin/cms?limit=${cmsParams.limit}&page=${cmsParams.page}&query=${query}&orderBy=${cmsParams.orderBy}&ascending=${cmsParams.ascending}`,
      config
    );
    // dispatch({ type: REMOVE_ALERT });
    dispatch({
      type: CMS_SEARCH_PARAMATERS_UPDATE,
      payload: cmsParams
    });
    dispatch({
      type: CMS_LIST_UPDATED,
      payload: res.data.response[0]
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: CMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get CMS by id
export const getCMSById = cms_id => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.get(`/api/admin/cms/${cms_id}`, config);
    // dispatch({ type: REMOVE_ALERT });
    //if(res.data.status){
    await dispatch({
      type: GET_CMS_BY_ID,
      payload: res.data.response
    });
    return res.data.response;
    //}//else history.push("/admin");
  } catch (err) {
    dispatch({
      type: CMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete User
export const cancelSave = history => async dispatch => {
  // dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("/admin/cms");
};

// page not found
export const notFound = history => async dispatch => {
  history.push("/admin/page-not-found");
};

//change status
export const changeStatus = (cms_id, status) => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(
      `/api/admin/cms/change-status/${cms_id}`,
      { status },
      config
    );
    // dispatch({ type: REMOVE_ALERT });

    await dispatch({
      type: CHANGE_CMS_STATUS,
      payload: res.data.response
    });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch({
      type: CMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Dispatch Loading
export const loadingOnCMSSubmit = () => async dispatch => {
  await dispatch({ type: LOADING_ON_CMS_SUBMIT });
};
