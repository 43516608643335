import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Modal from "components/common/Modal";
import Button from "components/common/form/Button";
import { useRef, useState } from "react";

function PaymentForm({ handleForClientSecret,clientSecret,closeModal, payText = "Purchase",select,course_id}) {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);

  const formRef = useRef();

  const stripe = useStripe();
  const elements = useElements();

  const onPay = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    console.log("====course_id",course_id);
    setSubmitting(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/payment-complete?course_id=${course_id}`,
      }
    });

    if (error) {
      console.error(error);
      setSubmitting(false);
    } else {
    }
  };
  return (
    <>
      <form onSubmit={onPay}>
        <PaymentElement />
        <div className="flex flex-row gap-2.5 mt-8">
          <Button
            type="button"
            className="w-1/2 py-2 mr-3"
            onClick={closeModal}
          >
            <label className="text-base text-dgray-700">Cancel</label>
          </Button>

          <Button
            type="submit"
            disabled={!stripe}
            loading={isSubmitting}
            className="bg-primary-600 w-1/2 py-2 text-base text-white"
            loadingText="Please wait..."
          >
            {payText}
          </Button>
        </div>
      </form>
      <Modal
        isOpen={isInfoModalOpen}
        modalContent={{
          title: "Error",
          content: "There was an error while creating your subscription. Please try again.",
        }}
        confirmCancel={() => setInfoModalOpen(false)}
      />
    </>
  );
}

export default PaymentForm;
