import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as moment from 'moment-timezone';
import { SESSION_TYPE } from "constants/index";
import Pagination from "views/Front/Pagination";
import { RECORD_PER_PAGE } from "constants/index";
import { getCurrentTimeZone } from "utils/time";

const MentorPaymentHistory = ({ mentorOrderList, user, paginate, totalRecords, filters }) => {

  let timeZone = getCurrentTimeZone(user);

  return (
    <>
    <div className="col-md-12">
      <h3>Earning History</h3>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Session</th>
              <th>Interaction Information</th>
              <th>Learners Name</th>
              <th>Total Amount</th>
              <th>Refund Amount</th>
              <th>Commission Deducted</th>
              <th>Net Amount Earned</th>
              <th>Booked On</th>
              <th>Payment Status</th>
            </tr>
          </thead>
          <thead>
            {mentorOrderList && mentorOrderList.map((item, index) => {
              return (
                  <tr key={index}>
                    <td>{++index}</td>
                    <td>{item.course_name}</td>
                    <td>
                      <p className="mb-1">
                        <small>Sessions:</small> <br />
                        {item.session_type == SESSION_TYPE.GENERIC ? (
                          <>
                            <span>
                              {moment
                                .unix(item.session_data[0].start_at)
                                .tz(timeZone)
                                .format("DD MMM, YYYY")}{" "}
                              |{" "}
                              {moment
                                .unix(item.session_data[0].start_at)
                                .tz(timeZone)
                                .format("hh:mm A")}{" "}
                              -{" "}
                              {moment
                                .unix(
                                  item.session_data[
                                    item.session_data.length - 1
                                  ].end_at
                                )
                                .tz(timeZone)
                                .format("hh:mm A")}
                            </span>
                            <br />
                          </>
                        ) : (
                          item.session_data.map((singleSession) => {
                            return (
                              <Fragment key={singleSession.id}>
                                <span>
                                  {moment
                                    .unix(singleSession.start_at)
                                    .tz(timeZone)
                                    .format("DD MMM, YYYY hh:mm A")}{" "}
                                </span>
                                <br />{" "}
                              </Fragment>
                            );
                          })
                        )}
                      </p>
                      <p className="mb-1">
                        <small>No. of session</small>
                        <br />
                        <span>
                          {item.session_type == SESSION_TYPE.GENERIC
                            ? "N/A"
                            : item.session_data.length}
                        </span>
                      </p>
                    </td>
                    <td>{item.student_username}</td>
                    <td>${item.order_total.toFixed(2)}</td>
                    <td>{item.paid_refund_amount ? `$${item.paid_refund_amount.toFixed(2)}` : 'N/A'}</td>
                    <td>${item.net_amount <= 0 ? 0 : item.commission.toFixed(2)}</td>
                    <td>{ item.net_amount ? <>${item.net_amount.toFixed(2)}</> : "$0"}</td>
                    <td>{moment.unix(item.booked_on).tz(timeZone).format('DD MMM, YYYY')}</td>
                    <td>{item.order_status === 1 ? "Not Paid" : item.order_status === 2 ? "Paid" : "Refunded" }</td>
                  </tr>
              );
            })}
          </thead>
        </table>
      </div>
    </div>
    <div className="row paginate-pos">
        <div className="col-md-12">
          <Pagination
            totalRecords={totalRecords}
            recordPerPage={RECORD_PER_PAGE}
            paginate={paginate}
            currentPage={filters.page}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  
})(withRouter(MentorPaymentHistory));
