import { getCourseDetailById } from "actions/front/common/course";
import {
  createCourse,
  createDraftCourse,
  editCourse,
} from "actions/front/educator/courses";
import { getEventDetail } from "actions/front/mentorDashboard";
import CenterContainer from "components/common/Container/Center";
import FormHero from "components/create-form/Hero";
import { SESSION_TYPE } from "constants";
import { Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import StepWizard from "react-step-wizard";
import prepareLiveEventData from "utils/createEvent/prepareLiveEventData";
import createPublicEventSchema from "utils/schemas/createPublicEvent";
import { getClosestQuarterTime } from "utils/time";
import Footer from "views/Front/Layout/Footer";
import Header from "views/Front/Layout/Header";
import Spinner from "views/Spinner";
import CreateEventPublicStep1 from "./Step1";
import CreateEventPublicStep2 from "./Step2";
import CreateEventPublicStep3 from "./Step3";
import CreateEventPublicStep4 from "./Step4";

function CreateEventPublic({
  createCourse,
  createDraftCourse,
  getCourseDetailById,
  editCourse,
  getEventDetail,
}) {
  const [preparedData, setPreparedData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [eventFetchingError, setEventFetchingError] = useState(null);
  const location = useLocation();
  const editId = new URLSearchParams(location.search).get("editId");
  const [editableEvent, setEditableEvent] = useState();
  const [attendeeCount, setAttendeeCount] = useState(0);

  const getEditCourseDetails = () => {
    Promise.all([getCourseDetailById(editId), getEventDetail(editId)]).then(
      ([courseResponse, eventDetailResponse]) => {
        if (courseResponse.status) {
          setEditableEvent(courseResponse.data);
        }
        if (eventDetailResponse.status) {
          const attendees = eventDetailResponse.response.map(
            (item) => item.learner
          );
          setAttendeeCount(attendees.length);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (editId) {
      getEditCourseDetails(editId);
    } else {
      setLoading(false);
    }
  }, []);

  const saveDraft = async (values) => {
    setError(null);
    const prepData = prepareLiveEventData(values);
    const res = await createDraftCourse(
      prepData.courseInformation,
      prepData.sessionData,
      prepData.typeOfSession
    );

    if (!res.status) {
      // error
      setError(res.msg);
      // setSubmitting(false);
      return false;
    } else {
      // success
      return true;
    }
  };

  return (
    <>
      <Header />
      <CenterContainer className="pb-24 md:pb-16">
        {isLoading ? (
          <Spinner />
        ) : eventFetchingError ? (
          <div className="text-center text-red-500">{eventFetchingError}</div>
        ) : (
          <Formik
            initialValues={{
              editId,
              maxSpeakers:
                editableEvent?.session_detail[0].max_speakers || undefined,
              allowAudience:
                editableEvent?.session_detail[0].max_students > 0 || true,
              maxAudience:
                editableEvent?.session_detail[0].max_students || undefined,
              inviteCoHost:
                (editableEvent?.cohosts.length > 0 &&
                  editableEvent?.cohosts[0] !== "") ||
                false,
              coHosts:
                editableEvent?.cohosts && editableEvent?.cohosts.length > 0
                  ? editableEvent?.cohosts
                  : [""],

              image:
                editableEvent?.image && editableEvent?.image !== "undefined"
                  ? editableEvent.image
                  : undefined,
              associatedProgram: editableEvent?.category_id || undefined,
              proficiencyLevel: editableEvent?.proficiency_level || undefined,
              title: editableEvent?.name || "",
              description: editableEvent?.overview || "",
              date: editableEvent
                ? new Date(
                    editableEvent?.session_detail[0].session_group
                      .all_session_with_recurring[0].start_at * 1000
                  )
                : getClosestQuarterTime(),
              time: editableEvent
                ? moment(
                    editableEvent?.session_detail[0].session_group
                      .all_session_with_recurring[0].start_at * 1000
                  ).format("HH:mm")
                : moment(getClosestQuarterTime()).format("HH:mm"),
              duration: editableEvent
                ? `${editableEvent.session_detail[0].duration_info[0].duration_per_session}`
                : undefined,
            }}
            validationSchema={createPublicEventSchema}
            onSubmit={async (values, { setSubmitting }) => {
              if (editId) {
                setError(null);

                const res = await editCourse(
                  editId,
                  {
                    ...preparedData.courseInformation,
                    isNewFileSelected:
                      preparedData.courseInformation.image &&
                      typeof preparedData.courseInformation.image === "object"
                        ? 1
                        : 0,
                  },
                  preparedData.sessionData,
                  preparedData.typeOfSession
                );

                if (!res.status) {
                  // error
                  setError(res.errors ? res.errors[0].msg : res.msg);
                  setSubmitting(false);
                  return false;
                } else {
                  // success
                  return true;
                }
              } else {
                setError(null);
                const res = await createCourse(
                  preparedData.courseInformation,
                  preparedData.sessionData,
                  preparedData.typeOfSession
                );

                if (!res.status) {
                  // error
                  setError(res.errors ? res.errors[0].msg : res.msg);
                  setSubmitting(false);
                  return false;
                } else {
                  // success
                  return true;
                }
              }
            }}
          >
            {({ values, errors }) => {
              return (
                <Form>
                  <StepWizard
                    transitions
                    nav={
                      <FormHero
                        title="Create a public event"
                        items={[
                          {
                            title: "Event Settings",
                            subtitle: "Event Interaction Details",
                          },
                          {
                            title: "Event Details",
                            subtitle: "Event Description & Time",
                          },
                          {
                            title: "Confirm",
                            subtitle: "Review event information and publish",
                          },
                        ]}
                      />
                    }
                  >
                    <CreateEventPublicStep1 saveDraft={saveDraft} />
                    <CreateEventPublicStep2
                      disabled={attendeeCount > 0}
                      saveDraft={saveDraft}
                      onClickNext={() => {
                        if (Object.keys(errors).length === 0) {
                          const eventData = prepareLiveEventData(
                            {
                              ...values,
                              sessionDetail:
                                editableEvent && editableEvent.session_detail
                                  ? editableEvent.session_detail
                                  : null,
                              events: [
                                {
                                  date: values.date,
                                  time: values.time,
                                  isRecurring: values.isRecurring,
                                  recurringInterval: values.recurringInterval,
                                  recurringEndDate: values.recurringEndDate,
                                },
                              ],
                            },
                            SESSION_TYPE.LIVE_EVENT
                          );
                          setPreparedData(eventData);
                        } else {
                          setPreparedData(null);
                        }
                      }}
                    />
                    <CreateEventPublicStep3 saveDraft={saveDraft} />
                    <CreateEventPublicStep4 />
                  </StepWizard>
                  {error && (
                    <div className="text-center text-red-500 mt-4">{error}</div>
                  )}
                </Form>
              );
            }}
          </Formik>
        )}
      </CenterContainer>
      <Footer />
    </>
  );
}

export default connect(() => ({}), {
  createCourse,
  createDraftCourse,
  getCourseDetailById,
  editCourse,
  getEventDetail,
})(CreateEventPublic);
