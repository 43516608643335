import { getEventDetail } from "actions/front/mentorDashboard";
import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow-right.svg";
import { ReactComponent as GlobeIcon } from "assets/images/icons/globe.svg";
import NestedPill from "components/common/NestedPill";
import Pill from "components/common/Pill";
import { getClosestSession } from "utils/course";
import { formatDateShort } from "utils/formatDate";
import { SESSION_TYPE } from "constants";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import getUserAvatar from "utils/getUserAvatar";
import PinPill from "components/common/PinPill";
import getEventImage, { getCategoryImage } from "utils/getEventImage";
import Attendees from "components/common/Attendees";
import { CATEGORIES } from "constants";

function EventCardLarge({ data, isPreview, categories }) {
  const [closestSession, setClosestSession] = useState();

  useEffect(() => {
    setClosestSession(getClosestSession(data));
  }, []);

  return (
    <div className="relative text-left rounded-2xl shadow-lg flex-1 lg:mx-auto bg-white flex">
      <PinPill text="Featured" className="top-6 left-6" />
      <div className="w-1/2">
        <img
          src={
            isPreview
              ? data.course_image ||
                getCategoryImage(data.category_id, categories)
              : getEventImage(data)
          }
          alt=""
          className="object-cover h-full w-full rounded-l-lg"
        />
      </div>

      <div className="w-1/2 px-8 pt-9 pb-4">
        <div className="flex justify-between items-center mb-4">
          {data.category_name ? (
            <Pill text={data.category_name} color={CATEGORIES[data.category_name]?.color} />
          ) : (
            <div />
          )}
          <NestedPill
            innerText={
              data.course_type === SESSION_TYPE.LIVE_EVENT
                ? "Public event"
                : "Private event"
            }
            // outerText={`${data?.speakerCount} Speaker Seats`}
          />
        </div>
        <div className="text-xl md:text-lg font-semibold mb-2">
          {data.course_name}
        </div>

        <div
          className="text-dgray-500 line-clamp-3 mb-6 whitespace-pre-line"
          dangerouslySetInnerHTML={{ __html: data.course_overview }}
        ></div>

        <div className="flex justify-between mb-4">
          <div className="flex gap-2.5">
            <img
              src={getUserAvatar(data.mentor_avatar)}
              alt="host avatar"
              className="rounded-full w-10 h-10"
            />
            <div>
              <div className="text-sm font-medium">{data.mentor_name}</div>
              <div className="text-xs text-dgray-500">
                <GlobeIcon className="inline mr-1" />
                {data.host ? data.host?.country : data.country}
              </div>
            </div>
          </div>
          <div>
            {isPreview ||
              (data.course_type === SESSION_TYPE.LIVE_EVENT && (
                <Attendees attendees={data.attendees} />
              ))}
          </div>
        </div>

        <div className="text-sm border-t pt-4 mt-4">
          <div className="flex justify-between">
            {isPreview ? (
              <div className="font-semibold">{formatDateShort(data.date)}</div>
            ) : (
              closestSession && (
                <div className="font-semibold">
                  {formatDateShort(moment.unix(closestSession?.start_at))}
                </div>
              )
            )}
            {isPreview || (
              <Link
                to={`/session/${data.course_id}`}
                className="text-primary-700 font-medium"
              >
                See Event Details <ArrowIcon className="inline" />
              </Link>
            )}
          </div>
          <div className="text-dgray-500">{data.timezone}</div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    user: state.auth.user,
    categories: state.categories,
  }),
  { getEventDetail }
)(EventCardLarge);
