import RadioGroup from "components/common/form/RadioGroup";
import SelectInput from "components/common/form/SelectInput";
import TextEditor from "components/common/form/TextEditor";
import TextInput from "components/common/form/TextInput";
import { CreateFormRow } from "components/create-form/CreateFormRow";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import CreateStepControls from "../../StepControls";
import Step2ProgramImagery from "../Public/Step2/ProgramImagery";

function CreateEventOneOnOneStep1({ categories, ...props }) {
  const location = useLocation();
  const editId = new URLSearchParams(location.search).get("editId");
  return (
    <>
      <h2 className="text-lg font-medium border-b border-dgray-200 pb-5 mb-5">
        Event Information
      </h2>
      <CreateFormRow
        title="Event Category"
        right={
          <SelectInput
            name="category"
            label="Category"
            options={
              categories
                ? categories.map((cat) => ({
                    label: cat.name,
                    value: cat._id,
                  }))
                : []
            }
          />
        }
      />
      <CreateFormRow
        title="Event Title"
        description="Max. xx characters"
        placegholder="Event title"
        right={
          <TextInput name="title" placeholder="Event title" />
        }
      />
      <CreateFormRow
        title="Event Description"
        description="A quick introduction of event agenda, details and highlighted topics. (50 ch min.)"
        right={<TextEditor name="description" />}
      />
      <CreateFormRow
        title="Ideal for"
        right={<TextEditor name="idealFor" />}
      />
      <div className="border-b border-dgray-200 pb-5 mb-5">
        <Step2ProgramImagery />
      </div>

      <CreateFormRow
        title="Event Interaction"
        description="Select the listener you want to organize the event for."
        right={
          <RadioGroup
            name="interaction"
            items={[
              // { label: "Group Event", value: "group" },
              { label: "One-on-One Session", value: "one_on_one" },
            ]}
          />
        }
      />

      <CreateStepControls {...props} />
    </>
  );
}

export default connect(({ categories }) => ({ categories: categories.data }))(
  CreateEventOneOnOneStep1
);
