import React from "react";
import Errors from "views/Notifications/Errors";
import { SESSION_DURATION } from "constants/index";

const DurationInformation = ({
  duration_per_session,
  price_per_session,
  max_students,
  number_of_session,
  onGroupChange,
  isConfirmation,
  number_of_session_editable = true,
}) => {
  return (
    <>
      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="duration_per_session">
            Duration per interaction <span className="text-danger">*</span>
          </label>
          <select
            name="duration_per_session"
            id="group_duration_per_session"
            className="form-control custom-select"
            value={duration_per_session}
            onChange={(e) => onGroupChange(e)}
            disabled={isConfirmation || !number_of_session_editable}
          >
            <option value=""> -- Select Duration -- </option>
            {Object.keys(SESSION_DURATION).map((key) => (
              <option value={key}>{SESSION_DURATION[key]}</option>
            ))}
          </select>
          {!isConfirmation && (
            <Errors
              current_key="group_duration_per_session"
              key="group_duration_per_session"
            />
          )}
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="price_per_session">
            Price per Interaction ($) <span className="text-danger">*</span>
          </label>
          <input
            type="number"
            className="form-control"
            id="group_price_per_session"
            min="0"
            name="price_per_session"
            value={price_per_session}
            onChange={(e) => onGroupChange(e)}
            disabled={isConfirmation}
          />
          {!isConfirmation && (
            <Errors
              current_key="group_price_per_session"
              key="group_price_per_session"
            />
          )}
          <small className="text-muted">Enter a price per Interaction.  Enter 0 if you would like this session to be free</small>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="max_students">
            Max of Learners <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            pattern="[0-9]*"
            className="form-control"
            id="group_max_students"
            name="max_students"
            value={max_students}
            onChange={(e) => onGroupChange(e)}
            disabled={isConfirmation}
          />
          {!isConfirmation && (
            <Errors current_key="group_max_students" key="group_max_students" />
          )}
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="number_of_session" style={{marginTop: -20 }}>
            Enter the number of interactions in this group session <span className="text-danger">*</span>
          </label>
          <input
            type="number"
            className="form-control"
            id="group_number_of_session"
            name="number_of_session"
            value={number_of_session}
            onChange={(e) => onGroupChange(e)}
            disabled={isConfirmation || !number_of_session_editable}
            min={1}
          />
          <small className="text-muted">Note: please only add more than one interaction if each interaction builds on the previous interaction</small>
          {!isConfirmation && (
            <Errors
              current_key="group_number_of_session"
              key="group_number_of_session"
            />
          )}
        </div>
      </div>

      <div className="col-md-12">
        <hr />
      </div>
    </>
  );
};

export default DurationInformation;
