import React, { useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { REMOVE_ERRORS } from "actions/types";
import DurationInformation from "./DurationInformation";
import SessionGroupInformation from "./SessionGroupInformation";

import { RECURRING_TYPE_REVERSE, TABS } from "constants/index";

const Generic = ({
  errorList,
  sessionFormData,
  setSessionFormData,
  setIsGenericValid,
  isConfirmation,
  setActiveTab,
  defaultGenericDateTimingInput,
  defaultGenericSessionGroup,
  isForEdit,
  current_date,
  setGenericErrors,
  setSessionErrorMsg,
}) => {
  /*********************Use Dispatch to set state ************************/
  const dispatch = useDispatch();

  // /*******************get current date ************************/
  // var curr = new Date();
  // var current_date = curr.toISOString().substr(0, 10);

  /*********************useState for generic form data ************************/
  const [sessionGenericFormData, setSessionGenericFormData] = useState({
    ...sessionFormData.generic,
  });
  /*********************explode form data ************************/
  const { duration_info, session_group } = sessionGenericFormData;

  /*********************INPUT type for change check ************************/
  const INPUT_TYPE = {
    SESSION: 1,
    DURATION: 2,
    NONE: 0,
  };

  /*********************onChange event ************************/
  const onGenericChange = (
    e,
    isSessionOrDurationOrNone = INPUT_TYPE.NONE,
    index = 0, //session index
    name = "" //name will have the value only for start_time and end_time
  ) => {
    if (isSessionOrDurationOrNone === INPUT_TYPE.SESSION) {
      let sessionData = [...session_group];
      let targetName = name != "" ? name : e.target.name;
      let targetValue = name != "" ? e.hour + ":" + e.minute : e.target.value;
      if (
        targetName == "start_date" ||
        targetName == "end_date" ||
        targetName == "start_time" ||
        targetName == "end_time"
      )
        sessionData[index].sessions[0][targetName] = targetValue;
      else sessionData[index][targetName] = targetValue;
      if (targetName == "start_date" || targetName == "end_date") {
        if (targetName == "start_date") {
          sessionData[index].sessions[0]["end_date"] = targetValue;
          let temp_end_date = new Date(targetValue);

          if (
            Object.prototype.toString.call(temp_end_date) === "[object Date]" &&
            !isNaN(temp_end_date.getTime())
          ) {
            sessionData[index].sessions[0]["min_end_date"] = targetValue;

            temp_end_date.setMonth(temp_end_date.getMonth() + 1);
            temp_end_date.setDate(temp_end_date.getDate() - 1);

            let max_end_date = temp_end_date.toISOString().substr(0, 10);
            sessionData[index].sessions[0]["max_end_date"] = max_end_date;
          }
        } else if (targetName == "end_date") {
        }

        // set the recurring type and recurring min date
        let start_date_value = new Date(
          sessionData[index].sessions[0].start_date
        );
        let end_date_value = new Date(sessionData[index].sessions[0].end_date);
        if (
          Object.prototype.toString.call(start_date_value) ===
            "[object Date]" &&
          !isNaN(start_date_value.getTime()) &&
          Object.prototype.toString.call(end_date_value) === "[object Date]" &&
          !isNaN(end_date_value.getTime())
        ) {
          // 1)if start date and end date are same then recurring daily is available
          // 2) if start date and end date difference is less then a week then recurring daily will not be avaiilable
          // 3) if start and end date difference is more than a week then only Monthly recurring is available
          let calculated_end_date_for_week = new Date(
            sessionData[index].sessions[0].start_date
          );
          calculated_end_date_for_week.setDate(
            calculated_end_date_for_week.getDate() + 7
          );
          if (start_date_value.getTime() == end_date_value.getTime())
            sessionData[index].recurring_type_min =
              RECURRING_TYPE_REVERSE.DAILY;
          else if (
            calculated_end_date_for_week.getTime() > end_date_value.getTime()
          )
            sessionData[index].recurring_type_min =
              RECURRING_TYPE_REVERSE.WEEKLY;
          else
            sessionData[index].recurring_type_min =
              RECURRING_TYPE_REVERSE.MONTHLY;

          if (
            sessionData[index].recurring_type != RECURRING_TYPE_REVERSE.NONE &&
            sessionData[index].recurring_type <
              sessionData[index].recurring_type_min
          ) {
            sessionData[index].recurring_type = RECURRING_TYPE_REVERSE.NONE;
            sessionData[index].recurring_end_date = "";
          }
        }
      } else if (targetName == "start_time" || targetName == "end_time") {
        if (
          sessionData[index].sessions[0]["end_time"] != "" &&
          sessionData[index].sessions[0]["end_time"] != "00:00" &&
          sessionData[index].sessions[0]["end_time"] <
            sessionData[index].sessions[0]["start_time"]
        )
          sessionData[index].sessions[0]["end_time"] =
            sessionData[index].sessions[0]["start_time"];
      }

      if (
        targetName == "start_date" ||
        targetName == "end_date" ||
        targetName == "recurring_type"
      ) {
        //change the recurring end min date to one day extra from end date
        let recurring_min_date = new Date(targetValue);
        if (
          Object.prototype.toString.call(recurring_min_date) ===
            "[object Date]" &&
          !isNaN(recurring_min_date.getTime())
        ) {
          recurring_min_date.setDate(recurring_min_date.getDate() + 1);

          sessionData[
            index
          ].recurring_min_date = recurring_min_date.toISOString().substr(0, 10);
          //compair if the recurring end date is less then end date if it is then make the recurring end date one greater then end date
          let compaire_recurrning_end_date = new Date(
            sessionData[index].recurring_end_date
          );
          let compaire_end_date = new Date(
            sessionData[index].sessions[0].end_date
          );
          if (
            Object.prototype.toString.call(compaire_recurrning_end_date) ===
              "[object Date]" &&
            !isNaN(compaire_recurrning_end_date.getTime()) &&
            compaire_recurrning_end_date.getTime() <=
              compaire_end_date.getTime()
          ) {
            sessionData[index].recurring_end_date =
              sessionData[index].recurring_min_date;
          }
        }
      }

      setSessionGenericFormData({
        ...sessionGenericFormData,
        session_group: sessionData,
      });
    } else if (isSessionOrDurationOrNone === INPUT_TYPE.DURATION) {
      let durationData = [...duration_info];
      durationData[index][e.target.name] = e.target.value;
      setSessionGenericFormData({
        ...sessionGenericFormData,
        duration_info: durationData,
      });
    } else {
      setSessionGenericFormData({
        ...sessionGenericFormData,
        [e.target.name]: e.target.value,
      });
    }
    dispatch({ type: REMOVE_ERRORS });
    setSessionErrorMsg("");
  };

  /*******************change the main form data when change in generic form data ************************/
  useMemo(() => {
    setSessionFormData({
      ...sessionFormData,
      generic: {
        ...sessionGenericFormData,
      },
    });
  }, [sessionGenericFormData]);

  //   var curr = new Date();
  // curr.setDate(curr.getDate() + 3);
  // var date = curr.toISOString().substr(0,10);

  /************useMemo to enable the calander and final tab and check form validation**************/
  useMemo(() => {
    let isValid = true;
    let errorsList = [];
    let highestDuration = 0;

    sessionGenericFormData.duration_info.map((singleDuration, index) => {
      if (!singleDuration.duration_per_session || singleDuration.duration_per_session === "") {
        isValid = false;
        errorsList[`generic_duration_per_session_${index}`] =
          "Please select duration.";
      }
      if (singleDuration.price_per_session === undefined || singleDuration.price_per_session === null || singleDuration.price_per_session === "") {
        isValid = false;
        errorsList[`generic_price_per_session_${index}`] =
          "Please select price per session.";
      }
      if (isValid) {
        if (highestDuration < singleDuration.duration_per_session) {
          highestDuration = singleDuration.duration_per_session;
        }
      }
      return isValid;
    });
    sessionGenericFormData.session_group.map((singleSession, index) => {
      if (singleSession.sessions[0].start_date === "") {
        isValid = false;
        errorsList[`generic_start_date_${index}`] = "Please select start date";
      }
      if (singleSession.sessions[0].start_time === "") {
        isValid = false;
        errorsList[`generic_start_time_${index}`] = "Please select start time";
      }
      if (singleSession.sessions[0].end_date === "") {
        isValid = false;
        errorsList[`generic_end_date_${index}`] = "Please select end date";
      }
      if (singleSession.sessions[0].end_time === "") {
        isValid = false;
        errorsList[`generic_end_time_${index}`] = "Please select end time";
      }
      if (isValid) {
        let start_date_time = moment(
          singleSession.sessions[0].start_date +
            " " +
            singleSession.sessions[0].start_time
        );

        let end_date_time = moment(
          singleSession.sessions[0].end_time == "00:00"
            ? moment(singleSession.sessions[0].start_date)
                .add(1, "days")
                .format("YYYY-MM-DD")
            : singleSession.sessions[0].start_date +
                " " +
                singleSession.sessions[0].end_time
        );

        let diff = end_date_time.diff(start_date_time, "minutes");
        if (highestDuration > diff) {
          isValid = false;
          errorsList[
            `generic_end_time_${index}`
          ] = `Minimum time duration must be ${highestDuration} minutes.`;
        }
      }
      if (
        singleSession.recurring_type != RECURRING_TYPE_REVERSE.NONE &&
        singleSession.recurring_end_date === ""
      ) {
        isValid = false;
        errorsList[`generic_recurring_type_${index}`] =
          "Please select recurring date";
      }
      return isValid;
    });
    if (isValid) {
      setGenericErrors(null);
    } else {
      setGenericErrors(errorsList);
    }
    setIsGenericValid(isValid);
  }, [sessionGenericFormData]);

  const genericHtml = (
    <div className="row mb-3" id="ask-anything">
      <div className="col-md-12">
        <div className="form-group space-between">
          <b>Ask Me Anything</b>
        </div>
      </div>
      <DurationInformation
        onGenericChange={onGenericChange}
        duration_info={duration_info}
        isConfirmation={isConfirmation}
        setSessionGenericFormData={setSessionGenericFormData}
        sessionGenericFormData={sessionGenericFormData}
        INPUT_TYPE={INPUT_TYPE}
        setSessionErrorMsg={setSessionErrorMsg}
      />
      <SessionGroupInformation
        session_group={session_group}
        INPUT_TYPE={INPUT_TYPE}
        sessionGenericFormData={sessionGenericFormData}
        setSessionGenericFormData={setSessionGenericFormData}
        isConfirmation={isConfirmation}
        onGenericChange={onGenericChange}
        current_date={current_date}
        defaultGenericDateTimingInput={defaultGenericDateTimingInput}
        defaultGenericSessionGroup={defaultGenericSessionGroup}
        isForEdit={isForEdit}
        setSessionErrorMsg={setSessionErrorMsg}
      />
    </div>
  );

  return isConfirmation ? (
    <fieldset className="box p-3 col-md-12 mb-0 mt-3">
      <legend>Manage Availability</legend>{" "}
      <button
        className="action editIcon"
        onClick={(e) => setActiveTab(TABS.SESSION)}
      >
        <i className="fa fa-pencil"></i> &nbsp;Edit
      </button>
      {genericHtml}
    </fieldset>
  ) : (
    genericHtml
  );
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
});

export default connect(mapStateToProps)(withRouter(Generic));
