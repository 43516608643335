import ImageDropzone from "components/common/form/ImageDropzone";
import ImageCropper from "components/common/ImageCropper";
import { useFormikContext } from "formik";
import useImageCropper from "hooks/useImageCropper";
import { useEffect, useState } from "react";

export default function Step2ImageDropzone() {
  const formikCtx = useFormikContext();
  const [file, setFile] = useState(null);
  const [acceptedFile, setAcceptedFile] = useState(null);

  const { cropperProps, show } = useImageCropper({
    src: file && URL.createObjectURL(file),
    onComplete: setFile,
    aspect: 2 / 1,
  });

  useEffect(() => {
    if (acceptedFile) {
      const renamedFile = new File([file], acceptedFile.name, {
        type: acceptedFile.type,
      });
      formikCtx?.setFieldValue("image", renamedFile);
    }
  }, [file]);

  const onDrop = (acceptedFiles) => {
    const uploadedFile = acceptedFiles[0];
    setAcceptedFile(uploadedFile);
    setFile(uploadedFile);
    show();
  };

  return (
    <>
      {file ? (
        <div className="text-sm font-medium">
          <img
            className="mb-3 rounded-lg h-60 object-contain"
            src={URL.createObjectURL(file)}
            alt=""
          />
          <button
            type="button"
            className="text-primary-700 mr-6"
            onClick={show}
          >
            Edit
          </button>
          <button
            type="button"
            className="text-dgray-500"
            onClick={() => setFile(null)}
          >
            Delete
          </button>
          <ImageCropper {...cropperProps} />
        </div>
      ) : (
        <ImageDropzone onDrop={onDrop} />
      )}
    </>
  );
}
