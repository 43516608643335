import React, { useState, useMemo, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { USER_TYPE } from "constants";

import UserDashboard from "./UserDashboard";
import HostDashboard from "./HostDashboard";

const Dashboard = ({ user, isHosting }) => {
  const [onlyId, setOnlyId] = useState(null);
  /********************set user data to local state*****************/
  return isHosting ? (
    <HostDashboard user={user} setOnlyId={setOnlyId} onlyId={onlyId} />
  ) : (
    <UserDashboard user={user} setOnlyId={setOnlyId} onlyId={onlyId} />
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  isHosting: state.auth.user?.role[0].user_type === USER_TYPE.HOST,
});

export default connect(mapStateToProps)(withRouter(Dashboard));
