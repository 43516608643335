import Cards from "components/common/Cards";
import { Fragment, useState,useEffect} from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as Close } from "assets/images/icons/close-icon.svg";
import PaymentModal from "views/Front/Order/Cart/PaymentModal";
import { initializeMemberPayment } from "actions/menber"
function MenberPicker({ isOpen, setOpen, getMenberPickInfo,initializeMemberPayment,setClientSecret,state}) {
  const closeModal = () => {
    setOpen(false);
  };
  const FetchMenberClientSecrert = (menber_id) =>{
    console.log("========FetchMenberClientSecrert",menber_id)
    initializeMemberPayment(menber_id).then((reqonse)=>{
      setClientSecret(reqonse.data)
    }).catch(()=>{
      setClientSecret(null)
    })
  }
  useEffect(()=>{
  })
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-10"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-10"
        >
          <div className="fixed inset-0 bg-black bg-dgray-700/70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative mx-auto w-[750px] lg:w-[600px] rounded-xl bg-white p-8 h-[85%] max-h-[90%] overflow-hidden overflow-y-auto">
                <button
                  className="focus:outline-none absolute"
                  onClick={closeModal}
                  style={{ top: 16, right: 16 }}
                >
                  <Close style={{ width: "15px" }} className="text-dgray-500" />
                </button>
                <Dialog.Title className="text-center text-2xl font-semibold">
                  Purchase a Plan
                </Dialog.Title>
                  <div>
                    {getMenberPickInfo &&
                      getMenberPickInfo.map((item, idx) => {
                        return (
                          <Cards
                            key={idx}
                            {...item}
                            handleMenberClientSecrert={FetchMenberClientSecrert}
                          />
                        );
                      })}
                  </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default connect((state) => ({ user: state.auth.user }),{initializeMemberPayment})(MenberPicker);
