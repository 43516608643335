import { ReactComponent as ArrowLeft } from "assets/images/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";
import Button from "components/common/Button";
import { useFormikContext } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export default function CreateStepControls({
  currentStep,
  totalSteps,
  previousStep,
  nextStep,
  nextButton,
  onClickNext,
  onSaveDraft,
}) {
  const { values } = useFormikContext();
  const [error, setError] = useState(null);
  const [isLoadingDraft, setLoadingDraft] = useState(null);
  const history = useHistory();

  const saveDraft = async (values) => {
    setError(null);
    setLoadingDraft(true);
    const res = await onSaveDraft(values);
    if (!res.status) {
      setError(res.msg);
      setLoadingDraft(false);
      return false;
    } else {
      history.push("/dashboard");
      setLoadingDraft(false);
      return true;
    }
  };

  return (
    <>
      <div className="flex justify-end gap-3">
        <div className="flex gap-3 md:flex-row-reverse md:justify-between md:flex-1">
          {onSaveDraft && (
            <Button
              type="button"
              className="!bg-white"
              onClick={() => saveDraft?.(values)}
              disabled={isLoadingDraft}
              loading={isLoadingDraft}
              small
              secondary
            >
              Save as Draft
            </Button>
          )}
          {currentStep > 1 && (
            <Button
              type="button"
              onClick={() => {
                previousStep();
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              small
              flat
            >
              <ArrowLeft className="inline mr-2" />
              <span className="hidden md:inline">Back</span>
              <span className="md:hidden">Previous Step</span>
            </Button>
          )}
        </div>
        {currentStep !== totalSteps &&
          (nextButton || (
            <Button
              type="button"
              onClick={() => {
                onClickNext?.();
                nextStep();
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              small
            >
              Next <span className="md:hidden">Step</span>
              <ArrowRight className="inline ml-2" />
            </Button>
          ))}
      </div>
      {error && <div className="text-center text-red-500">{error}</div>}
    </>
  );
}
