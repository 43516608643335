import CenterContainer from "../Container/Center";

export default function DarkHero({
  supertitle,
  title,
  subtitle,
  bottom = "",
  className = "",
}) {
  return (
    <div
      className={`bg-dgray-800 text-center py-24 md:py-16 ${className}`}
      tight
    >
      <CenterContainer tight>
        {supertitle && <div className="text-dgray-100 mb-6">{supertitle}</div>}
        <h1 className="text-5xl md:text-4xl text-white font-semibold mb-6 md:mb-4">
          {title}
        </h1>
        <p className={`text-white text-xl md:text-lg ${bottom && "mb-10"}`}>
          {subtitle}
        </p>
        {bottom}
      </CenterContainer>
    </div>
  );
}
