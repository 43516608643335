import BlogCard from "components/blog/BlogCard";
import DarkHero from "components/common/Hero/Dark";
import CenterContainer from "components/common/Container/Center";
import Header from "../Layout/Header";
import { useEffect, useState } from "react";
import BlogList from "./BlogList";
import JoinCTA from "components/common/JoinCTA";
import Footer from "../Layout/Footer";
import { connect } from "react-redux";
import { getAllBlogs } from "actions/front/blog";
import Spinner from "views/Spinner";

function Blogs({ getAllBlogs }) {
  const [latestBlog, setLatestBlog] = useState();

  const [isLoading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [blogs, setBlogs] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(initialFilters);
  const [limit, setLimit] = useState(7);

  useEffect(() => {
    fetchData();
  }, [filters, page, selectedCategory]);

  const fetchData = () => {
    setLoading(true);
    getAllBlogs({ ...filters, limit, page }).then((res) => {
      if (res.status) {
        if (limit === 7) {
          setLatestBlog(res.data.data[0]);
          setBlogs(res.data.data.slice(1));
          setLimit(6);
        } else {
          setBlogs(res.data.data);
        }
        setTotalRecords(res.data.metadata[0].totalRecord);
      }
      setLoading(false);
    });
  };

  return (
    <>
      <Header className="bg-dgray-800" light />
      <DarkHero
        title="DDN Blog"
        subtitle="Read how to practice and improve your speaking, written by our hosts."
        className="pb-48"
      />
      <CenterContainer className="-mt-20 md:-mt-10">
        {isLoading ? (
          <Spinner />
        ) : (
          latestBlog && (
            <div className="mb-16">
              <BlogCard blog={latestBlog} pinText="Latest" large />
            </div>
          )
        )}
        <BlogList
          isLoading={isLoading}
          setSelectedCategory={setSelectedCategory}
          blogs={blogs}
          totalRecords={totalRecords}
          page={page}
          filters={filters}
          setFilters={setFilters}
          setPage={setPage}
        />
      </CenterContainer>
      <JoinCTA />
      <Footer />
    </>
  );
}

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  { getAllBlogs }
)(Blogs);

const initialFilters = {
  limit: 7,
  page: 1,

  orderBy: "created_at",
  ascending: "desc",
  query: "",
  custom_filters: "",
};
