import {
  generateMeetingLink,
  getCourseById,
} from "actions/front/educator/courses";
import { educatorCancelCourseScheduled } from "actions/front/mentorDashboard";
import { cancelCourseScheduled } from "actions/front/learner/courses";
import { ReactComponent as CalendarIcon } from "assets/images/icons/calendar.svg";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import NestedPill from "components/common/NestedPill";
import Pill from "components/common/Pill";
import { SESSION_TYPE } from "constants";
import { ATTENDEE_ROLE, CHIME_CLIENT_URL, USER_TYPE } from "constants";
import inRange from "lodash.inrange";
import moment from "moment";
import { useState } from "react";
import { connect } from "react-redux";
import getEventImage from "utils/getEventImage";
import IconCircle from "views/Front/HomePage/IconCircle";
import AttendeeListModal from "./AttendeeListModal";
import { useDispatch } from "react-redux"
const modalContentForHost = {
  title: "Event cancelation",
  content:
    "Are you sure you want to cancel your event? All registered attendees will be notified.",
};

const modalContentForStudent = {
  title: "Seat cancelation",
  content:
    "Are you sure you want to cancel your seat? Event host will be notified.",
};

function UpcomingEventCardLarge({
  featuredEvent,
  gmt,
  timeZone,
  token,
  getCourseById,
  generateMeetingLink,
  user,
  refetch,
  setOnlyId
}) {
  let dispatch = useDispatch()
  let now = moment(
    moment.unix(moment.utc().unix()).tz(timeZone).format("YYYY-MM-DD HH:mm:ss")
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isEventActivating, setEventActivating] = useState(false);
  const [generatedMeetingId, setGeneratedMeetingId] = useState();
  const [isAttendeesModalOpen, setIsAttendeesModalOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  const confirmCancel = () => {
    if (featuredEvent?.type === "hosting") {
      educatorCancelCourseScheduled(featuredEvent).then((res) => {
        setIsOpen(false);
        setOnlyId(Date.now())

        refetch().catch((err) => console.error(err)).finally(()=>{
          setGeneratedMeetingId(false)
        });
      });
    } else {
      cancelCourseScheduled(featuredEvent.course_detail_id).then((res) => {
        setIsOpen(false);
        setOnlyId(Date.now())
        dispatch({
          type:"UPDATE_CANCLE_PASS",
          payload:{
            pass:res?.data?.activeSubscription?.pass,
            status:res?.data?.activeSubscription?.status,
            is_premium:res?.data?.is_premium
          }
        })
        refetch().catch((err) => console.error(err)).finally(()=>{
          setGeneratedMeetingId(false)
        });
      });
    }
  };

  const activateEvent = () => {
    setEventActivating(true);
    getCourseById(featuredEvent.course_id).then((res) => {
      const recurringId =
        res.data.session_detail[0].session_group[0]
          .all_session_with_recurring[0]._id;
      generateMeetingLink(recurringId,featuredEvent.course_id,timeZone,featuredEvent.session_data,featuredEvent.course_type).then((res) => {
        setGeneratedMeetingId(res.meetingId);
        setEventActivating(false);
      });
    });
  };

  return (
    <>
      {user.id === featuredEvent.mentor_id && featuredEvent && (
        <AttendeeListModal
          isOpen={isAttendeesModalOpen}
          setIsOpen={setIsAttendeesModalOpen}
          eventId={featuredEvent?.course_id}
          eventType={featuredEvent?.course_type}
          end_at={featuredEvent?.end_at}
          start_at={featuredEvent?.start_at}
          all={false}
        />
      )}
      <div className="bg-white rounded-lg shadow border border-dgray-200 w-full md:w-auto overflow-y-hidden">
        <div className="flex items-center gap-3 py-4 px-5 border-b border-inherit">
          <IconCircle>
            <CalendarIcon />
          </IconCircle>
          <div>
            <div className="text-lg font-medium">Upcoming Event</div>
            <div className="text-sm text-dgray-500">
              You can attend the event when it’s activated prior to starting
              time.
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="flex md:flex-col gap-4">
            <div className="w-60 md:h-full shrink-0 md:w-full">
              <img
                src={getEventImage(featuredEvent)}
                alt="course"
                className="object-cover w-full h-full rounded-l-lg"
              />
            </div>
            <div className="w-full">
              <div className="flex md:flex-col justify-between items-center md:items-start mb-3">
                <Pill
                  text={
                    featuredEvent?.category
                      ? featuredEvent?.category.name
                      : featuredEvent?.category_name
                  }
                  color="blue"
                  className="md:mb-2"
                />
                <NestedPill
                  innerText={
                    featuredEvent.course_type === SESSION_TYPE.LIVE_EVENT
                      ? "Public event"
                      : "Private event"
                  }
                  // outerText={"You are a speaker"}
                />
              </div>
              <div className="text-xl md:text-lg font-semibold mb-2">
                {featuredEvent?.course_name}
              </div>
              <div className="">
                <Description
                  data={featuredEvent?.course_overview}
                  startAt={featuredEvent?.start_at}
                  endAt={featuredEvent?.end_at}
                  type={featuredEvent?.course_type}
                  gmt={gmt}
                />
              </div>
              {user.id === featuredEvent.mentor_id && (
                <button
                  className="text-sm text-primary-700 font-medium"
                  onClick={() => setIsAttendeesModalOpen(true)}
                >
                  View attendees
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="py-4 px-6 flex justify-between items-center border-t border-inherit">
          <div className="text-red-700 text-sm">
            <button type="button" onClick={openModal} className="">
              {featuredEvent?.type === "hosting"
                ? "Cancel the event"
                : "Cancel your seat"}
            </button>
          </div>
          <div className="flex md:flex-col-reverse items-center md:items-start gap-3">
            {featuredEvent?.type === "hosting" ? (
              generatedMeetingId ? (
                <Button
                  small
                  target="_blank"
                  external={true}
                  href={`${CHIME_CLIENT_URL}join/${generatedMeetingId}?token=${token}`}
                >
                  Join the event
                </Button>
              ) : (
                <>
                  {featuredEvent?.meeting_id ? (
                    <Button
                      small
                      target="_blank"
                      external={true}
                      href={`${CHIME_CLIENT_URL}join/${featuredEvent?.meeting_id}?token=${token}`}
                    >
                      Join the event
                    </Button>
                  ) : (
                    <>
                      {inRange(
                        moment
                          .duration(
                            now.diff(
                              moment
                                .unix(featuredEvent?.start_at)
                                .tz(timeZone)
                                .format("YYYY-MM-DD HH:mm:ss")
                            )
                          )
                          .asMinutes(),
                        -5,
                        10
                      ) ? (
                        <>
                          <YourRole
                            cohosts={featuredEvent?.cohosts}
                            user={user}
                            isHosting={featuredEvent?.type === "hosting"}
                            learnerRole={featuredEvent?.learner_role}
                          />
                          <Button
                            small
                            onClick={activateEvent}
                            loading={isEventActivating}
                          >
                            Activate the event
                          </Button>
                        </>
                      ) : (
                        <>
                          <label className="text-dgray-500 text-sm">
                            Event will be activated prior to starting time
                          </label>
                          <Button
                            small
                            disabled
                            className="disabled:bg-dgray-200"
                          >
                            Activate the event
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </>
              )
            ) : (
              <>
                {featuredEvent?.meeting_id ? (
                  <>
                    <label className="text-dgray-500 text-sm">
                      Event will be activated prior to starting time
                    </label>
                    <Button
                      small
                      external={true}
                      href={`${CHIME_CLIENT_URL}join/${featuredEvent?.meeting_id}?token=${token}`}
                    >
                      Attend the event
                    </Button>
                  </>
                ) : (
                  <>
                    <label className="text-dgray-500 text-sm">
                      Event will be activated prior to starting time
                    </label>
                    <Button small disabled className="disabled:bg-dgray-200">
                      Attend the event
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setOnlyId={setOnlyId}
            modalContent={
              featuredEvent?.type === "hosting"
                ? modalContentForHost
                : modalContentForStudent
            }
            confirmCancel={confirmCancel}
          />
        </div>
      </div>
    </>
  );
}

function Description({ data, startAt,endAt,gmt,type }) {
  return (
    <>
      <p
        className="text-sm text-gray-500 mb-3 line-clamp-3 whitespace-pre-line"
        dangerouslySetInnerHTML={{ __html: data }}
      />

      <time className="text-sm font-semibold mt-3">
        {type == 1 ? moment.unix(startAt).format("MMM D, h:mm A") + " - " +  moment.unix(endAt).format("MMM D, h:mm A") : moment.unix(startAt).format("MMM D, h:mm A")}
      </time>
    </>
  );
}

export function YourRole({ isHosting, learnerRole, cohosts, user }) {
  return (
    <p className="text-sm font-semibold text-primary-600">
      Your role:{" "}
      {isHosting
        ? cohosts && cohosts.includes(user.email)
          ? "Cohost"
          : "Host"
        : learnerRole === ATTENDEE_ROLE.SPEAKER
        ? "Speaker"
        : "Listener"}
    </p>
  );
}

export default connect(
  (state) => ({
    token: state.auth.token,
    user: state.auth.user,
  }),
  {
    getCourseById,
    generateMeetingLink,
  }
)(UpcomingEventCardLarge);
