import React, { useState, useMemo, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AllSession from "./AllSession";
import UpcomingSession from "./UpcomingSession";
import CompletedSession from "./CompletedSession";
import { getSessionStatistics } from "actions/front/learner/courses";

const MyCourses = ({ getSessionStatistics }) => {
  const COURSE_LIST = {
    UPCOMING: 1,
    COMPLETED: 2,
    ALL: 3,
  };

  const [activeResults, setActiveResults] = useState(COURSE_LIST.UPCOMING);

  const [loading, setloading] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [sessionStatistics, setSessionStatistics] = useState({
    upcoming: 0,
    completed: 0,
    all: 0,
  });

  const { upcoming, completed, all } = sessionStatistics;

  useEffect(() => {
    if (refresh) {
      setActiveResults(null);
      setloading(true);
      setTimeout(() => {
        setActiveResults(COURSE_LIST.UPCOMING);
        setrefresh(false);
        setloading(false);
      }, 500);
    }
  }, [refresh]);

  useMemo(() => {
    setloading(true);
    getSessionStatistics().then((res) => {
      if (res.status && res.data) {
        let newData = {
          upcoming: res.data.count_upcoming_sessions,
          completed: res.data.count_past_sessions,
          all: res.data.count_all_sessions,
        };
        setSessionStatistics({ ...newData });
      }
      setloading(false);
    });
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <h4>Attending Sessions/Events</h4>
        <div className="section-head  mb-4">
          {/* <h4>My Courses</h4> */}
          {/* <a href="#" class="btn btn-primary">Add New</a> */}
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeResults == COURSE_LIST.UPCOMING && "active"
                }`}
                id="upcoming-tab"
                onClick={(e) => setActiveResults(COURSE_LIST.UPCOMING)}
              >
                Upcoming
                {!loading && upcoming > 0 && <strong>({upcoming})</strong>}
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeResults == COURSE_LIST.COMPLETED && "active"
                }`}
                id="attended-tab"
                onClick={(e) => setActiveResults(COURSE_LIST.COMPLETED)}
              >
                Completed{" "}
                {!loading && completed > 0 && <strong>({completed})</strong>}
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeResults == COURSE_LIST.ALL && "active"
                }`}
                id="enrolled-tab"
                onClick={(e) => setActiveResults(COURSE_LIST.ALL)}
              >
                All {!loading && all > 0 && <strong>({all})</strong>}
              </button>
            </li>
          </ul>
          <button
            to="#"
            className="btn btn-secondary ml-3"
            onClick={(e) => setrefresh(true)}
          >
            Refresh
          </button>
        </div>
        <div className="tab-content" id="myTabContent">
          {activeResults == COURSE_LIST.ALL && (
            <AllSession COURSE_LIST={COURSE_LIST} />
          )}
          {activeResults == COURSE_LIST.UPCOMING && (
            <UpcomingSession COURSE_LIST={COURSE_LIST} />
          )}
          {activeResults == COURSE_LIST.COMPLETED && (
            <CompletedSession COURSE_LIST={COURSE_LIST} />
          )}
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = (state) => ({
//   user: state.auth.user,
// });

export default connect(null, { getSessionStatistics })(withRouter(MyCourses));
