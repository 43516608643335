import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import StarRatings from "react-star-ratings";
import BackgroundImage from "assets/images/bg/bg5.jpg";
import CourseImage from "assets/images/mock-event.jpg";
import NoRecord from "views/NoRecord";

import {
  getReviewDetails,
  rateCourse,
} from "actions/front/common/courseRating";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

import { Spinner } from "reactstrap";
import Spinner1 from "views/Spinner1";

import { filterString } from "utils/sFold";
import { REMOVE_ERRORS } from "actions/types";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const Rating = (props) => {
  /******************************** set the initial form data ********************/
  const initialState = {
    rate: 0,
    review: "",
    mentor_id: "",
  };
  /********************************usestate form data ********************/
  const [formData, setFormData] = useState({
    rate: 0,
    review: "",
    mentor_id: "",
  });

  const params = useQuery();

  /********************************get details ********************/

  const [loading, setLoading] = useState(false);
  const [courseDetail, setCourseDetail] = useState({});
  const [reviewList, setReviewList] = useState([]);
  const [mentorRating, setMentorRating] = useState({
    avg_rating: 0,
    rated_by_users: 0,
  });
  const [courseRating, setCourseRating] = useState({
    avg_rating: 0,
    rated_by_users: 0,
  });
  const [isForWriteReview, setIsForWriteReview] = useState(false);
  const [isCourseFound, setIsCourseFound] = useState(false);
  const [modal, setModal] = useState(false);
  const [modelMessage, setModalMessage] = useState("");
  useMemo(() => {
    setLoading(true);
    let reviewer_id = params.get("user");
    if (reviewer_id != "") {
      if (reviewer_id == props.user._id) {
        props
          .getReviewDetails(props.match.params.course_id, reviewer_id)
          .then((res) => {
            if (res.status) {
              if (res.data.isCourseExist) {
                setIsCourseFound(true);
                if (
                  res.data.is_invalid_reviewer ||
                  !res.data.is_user_enrolled
                ) {
                  setModalMessage("Invalid URL or URL expired.");
                  setModal(true);
                }
                if (
                  !res.data.is_invalid_reviewer &&
                  res.data.is_user_enrolled &&
                  res.data.is_alreaded_rated
                ) {
                  setModalMessage("You have already rated this course.");
                  setModal(true);
                  setCourseDetail(res.data.course_detail[0]);
                  setFormData({
                    ...formData,
                    mentor_id: res.data.course_detail[0].mentor_id,
                  });
                  setReviewList(res.data.course_review.rating_list);
                  setCourseRating({
                    avg_rating: res.data.course_review.avg_rating,
                    rated_by_users: res.data.course_review.total_user_rated,
                  });
                  setMentorRating({
                    avg_rating: res.data.mentor_rating.avg_rating,
                    rated_by_users: res.data.mentor_rating.total_user_rated,
                  });
                  setIsForWriteReview(false);
                } else {
                  setCourseDetail(res.data.course_detail[0]);
                  setFormData({
                    ...formData,
                    mentor_id: res.data.course_detail[0].mentor_id,
                  });
                  setReviewList(res.data.course_review.rating_list);
                  setCourseRating({
                    avg_rating: res.data.course_review.avg_rating,
                    rated_by_users: res.data.course_review.total_user_rated,
                  });
                  setMentorRating({
                    avg_rating: res.data.mentor_rating.avg_rating,
                    rated_by_users: res.data.mentor_rating.total_user_rated,
                  });
                  setIsForWriteReview(true);
                }
              }
            }
            setLoading(false);
          });
      } else {
        setModalMessage("You are not allowed to access this URL.");
        setModal(true);
        setLoading(false);
      }
    } else {
      props
        .getReviewDetails(props.match.params.course_id, reviewer_id)
        .then((res) => {
          if (res.status) {
            if (res.data.isCourseExist) {
              setIsCourseFound(true);
              setCourseDetail(res.data.course_detail[0]);
              setFormData({
                ...formData,
                mentor_id: res.data.course_detail[0].mentor_id,
              });

              setReviewList(res.data.course_review.rating_list);
              setCourseRating({
                avg_rating: res.data.course_review.avg_rating,
                rated_by_users: res.data.course_review.total_user_rated,
              });
              setMentorRating({
                avg_rating: res.data.mentor_rating.avg_rating,
                rated_by_users: res.data.mentor_rating.total_user_rated,
              });
            }
          }
          setLoading(false);
        });
    }
  }, []);

  const closePopup = () => {
    setModal(false);
    setModalMessage("");
  };

  const toggle = () => setModal(!modal);

  /************************explode form data ********************/
  const { rate, review } = formData;

  /***********************use dispatch ********************/
  const dispatch = useDispatch();

  /******************************* reset form data ***************/
  const reset = () => {
    dispatch({ type: REMOVE_ERRORS });
    setFormData(initialState);
  };

  /***********************on change event ********************/
  const onChange = (e, isRating = false) => {
    if (isRating) {
      setFormData({ ...formData, rate: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    dispatch({ type: REMOVE_ERRORS });
  };

  /***********************on submit event ********************/
  const onSubmit = (e) => {
    e.preventDefault();
    if (rate != 0) {
      props.rateCourse(formData, props.match.params.course_id).then((res) => {
        if (res.status) {
          setModalMessage("Your review submitted successfully");
          setModal(true);
          setCourseDetail(res.data.course_detail[0]);
          setReviewList(res.data.course_review.rating_list);
          setCourseRating({
            avg_rating: res.data.course_review.avg_rating,
            rated_by_users: res.data.course_review.total_user_rated,
          });
          setMentorRating({
            avg_rating: res.data.mentor_rating.avg_rating,
            rated_by_users: res.data.mentor_rating.total_user_rated,
          });
          setIsForWriteReview(false);
          reset();
        } else {
          alert(res.msg);
        }
      });
    } else {
      alert("Please Rate.");
    }
  };

  return (
    <>
      <div className="row" id="banner">
        <div id="inner-banner" className="col-md-12 p-0">
          <img src={BackgroundImage} alt className="img-fluid" />
          <div className="inner-caption d-md-block">
            <h2 className="mb-3">Reviews & Ratings</h2>
          </div>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="row course-section p-60" id="course-details">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mb-3">
                {/* Attended Card Start   */}
                <div className="card">
                  <img
                    src={
                      courseDetail.course_image
                        ? process.env.REACT_APP_MEDIA_URL  + courseDetail.course_image
                        : CourseImage
                    }
                    alt
                    className="img-fluid card-thumb"
                  />
                  <div className="card-body pb-0">
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <Link to={`/course/${courseDetail.course_id}`}>
                          <h4 className="title">{courseDetail.course_name}</h4>
                        </Link>
                        <div className="info-row">
                          <div className="rating">
                            <StarRatings
                              rating={courseRating.avg_rating}
                              numberOfStars={5}
                              name="rating"
                              starDimension="16px"
                              starSpacing="0px"
                              starRatedColor="orange"
                              starEmptyColor="#a9a9a9"
                            />
                            <span className="count text-muted">
                              ({courseRating.rated_by_users})
                            </span>
                          </div>
                          <span>
                            {courseDetail.overview
                              ? filterString(courseDetail.overview).substring(
                                  0,
                                  97
                                ) + " ..."
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="info-row pb-2">
                      <span>Host Name</span>
                      <strong>{courseDetail.mentor_name?.split(' ')[0] + ' ' + courseDetail.mentor_name?.split(' ')[1].substring(0, 1)}</strong>
                      <span>Attendee User Name</span>
                      <strong>{props.user.username}</strong>
                      <div className="rating">
                        <StarRatings
                          rating={mentorRating.avg_rating}
                          numberOfStars={5}
                          name="rating"
                          starDimension="16px"
                          starSpacing="0px"
                          starRatedColor="orange"
                          starEmptyColor="#a9a9a9"
                        />
                        <span className="count text-muted">
                          ({mentorRating.rated_by_users})
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Attended Card End  /*/}
              </div>

              <div className="col-md-8">
                <div className="review-rating">
                  {isForWriteReview && (
                    <>
                      <form
                        onSubmit={(e) => {
                          onSubmit(e);
                        }}
                      >
                        <h4 className="rating-head mb-3 border-bottom pb-3">
                          Reviews
                        </h4>
                        <div className="add-review mb-5">
                          <div className="rating">
                            <StarRatings
                              rating={rate}
                              starRatedColor="orange"
                              starEmptyColor="#212529"
                              starHoverColor="orange"
                              starDimension="16px"
                              starSpacing="0px"
                              changeRating={(e) => onChange(e, true)}
                              numberOfStars={5}
                              name="rating"
                              required
                            />
                          </div>
                          <div className="form-group mt-3">
                            <textarea
                              name="review"
                              id="review"
                              cols={30}
                              rows={2}
                              className="form-control"
                              defaultValue={review}
                              onChange={(e) => onChange(e, false)}
                            />
                          </div>
                          <div className="form-group">
                            {props.ratingLoading ? (
                              <Spinner1 />
                            ) : (
                              <input
                                type="submit"
                                className="btn btn-primary"
                                defaultValue="Submit"
                              />
                            )}
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                  {reviewList.length == 0 ? (
                    <NoRecord msg="No Rating" />
                  ) : (
                    reviewList.map((review) => (
                      <div className="review-group">
                        <div className="review-details">
                          <strong className="text-dark">
                            {review.rated_by}
                          </strong>
                          <label htmlFor>Learner</label>
                          <div className="rating">
                            <StarRatings
                              rating={review.rating}
                              numberOfStars={5}
                              name="rating"
                              starDimension="16px"
                              starSpacing="0px"
                              starRatedColor="orange"
                              starEmptyColor="#a9a9a9"
                            />
                          </div>
                          <p className="text-muted">{review.comment}</p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal isOpen={modal} toggle={toggle} onClosed={closePopup}>
        <ModalHeader>Thankyou</ModalHeader>
        <ModalBody>
          <h5 className="error text-success">{modelMessage}</h5>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            className="m-1"
            onClick={(e) => closePopup(e)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

// Checkout.propTypes = {
//   getClientToken: PropTypes.func.isRequired,
//   getCartDetail: PropTypes.func.isRequired,
//   payNow: PropTypes.func.isRequired,
// };

const mapStateToProps = (state) => ({
  user: state.auth.user,
  ratingLoading: state.frontCourseRating.ratingloading,
});

export default connect(mapStateToProps, { getReviewDetails, rateCourse })(
  withRouter(Rating)
);
