import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Pagination from "views/Front/Pagination";
import { RECORD_PER_PAGE } from "constants/index";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { getAllSessions } from "actions/front/educator/courses";
import Sorting from "./Sorting";
import Courses from "./Courses";

const CompletedSession = ({ getAllSessions, user, RESULTS }) => {
  const [coursesList, setCoursesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const activeResults = RESULTS.COMPLETED_SESSION;

  const [filters, setFilters] = useState({
    limit: RECORD_PER_PAGE,
    page: 1,
    orderBy: "start_at",
    ascending: "desc",
    query: "",
    custom_filters: "", //start_date //end_date
  });

  const paginate = (page_no) => {
    setFilters({ ...filters, page: page_no });
  };

  const onClickSearchText = (e) => {
    e.preventDefault();
    setFilters({ ...filters, query: searchText });
  };

  useEffect(() => {
    setLoading(true);
    getAllSessions(filters, activeResults).then((res) => {
      if (res.status) {
        setCoursesList(res.data.data);
        setTotalRecords(res.data.metadata[0].totalRecord);
      }
      setLoading(false);
    });
  }, [filters, activeResults]);

  const showLearnerList = (learnerList) => {
    setShowModal(true);
    setModalData(learnerList);
  };

  const closePopup = (e) => {
    setShowModal(false);
    setModalData([]);
  };

  const modalActions = {
    showLearnerList
  }

  const toggle = () => setShowModal(!showModal);
  return (
    <>
    <div
      className="tab-pane fade show active"
      id="completed"
      role="tabpanel"
      aria-labelledby="completed-tab"
    >
      <div className="row">
        <div className="col-md-4">
          <form
            className="form-horizontal"
            onSubmit={(e) => onClickSearchText(e)}
          >
            <div className="input-group">
              <input
                type="text"
                placeholder="Search Sessions..."
                className="form-control"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-secondary"
                  type="submit"
                  onClick={(e) => onClickSearchText(e)}
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </div>
          </form>
        </div>
        <Sorting filters={filters} setFilters={setFilters} />
      </div>
      <Courses activeResult={activeResults} page={RESULTS} courseList={coursesList} loading={loading} modal={modalActions} />
      <div className="row">
        <div className="col-md-12 mt-5">
          <Pagination
            totalRecords={totalRecords}
            recordPerPage={RECORD_PER_PAGE}
            paginate={paginate}
            currentPage={filters.page}
          />
        </div>
      </div>
    </div>
    <Modal isOpen={showModal} toggle={toggle} onClosed={closePopup}>
        <ModalHeader className="modal-title">
          Enrolled Learners
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
              </thead>
              <tbody>
              {modalData.map((learner, idx) => (
                <tr>
                  <td>{idx + 1}</td>
                  <td><Link to={`/profile/${learner.learner_username}`}>{learner.learner_name}</Link></td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            className="m-1"
            onClick={(e) => closePopup(e)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getAllSessions,
})(withRouter(CompletedSession));
