import { ReactComponent as CheckIcon } from "assets/images/icons/check.svg";
import { Field } from "formik";
import { useEffect, useState } from "react";

export default function Checkbox({ name, disabled, ...props }) {
  if (name)
    return (
      <Field name={name} type="checkbox">
        {({ field, form }) => (
          <CheckboxEl
            {...field}
            {...props}
            onChange={(val) => {
              form?.setFieldValue(field.name, val);
              props.onChange?.(val);
            }}
            onBlur={() => {
              field?.onBlur();
              props.onBlur?.();
            }}
            disabled={disabled || form.isSubmitting}
          />
        )}
      </Field>
    );

  return <CheckboxEl {...props} disabled={disabled} />;
}

function CheckboxEl({
  label,
  checked,
  onChange,
  className = "",
  disabled,
  ...props
}) {
  const [isChecked, setChecked] = useState(checked || false);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const toggle = () => {
    onChange?.(!isChecked);
    setChecked((c) => !c);
  };

  return (
    <div
      className={`flex items-center
      ${disabled ? "opacity-60" : ""} ${className}`}
    >
      <button
        {...props}
        disabled={disabled}
        type="button"
        id={`${label}${props.name}`}
        onClick={toggle}
        className={`shrink-0 h-4 w-4 rounded border flex justify-center items-center
        ${isChecked ? "border-primary-600 bg-primary-50" : "bg-white"}`}
      >
        {isChecked && <CheckIcon className="text-primary-600" />}
      </button>
      {label && (
        <label
          htmlFor={`${label}${props.name}`}
          className="text-sm text-dgray-700 font-medium inline-block w-full pl-2"
        >
          {label}
        </label>
      )}
    </div>
  );
}
