import { Link } from "react-router-dom";
import UserImage from "assets/images/user.jpg";
import StarRatings from "react-star-ratings";
import DingDongUser from "./DingDong/DingDongUser";
import * as moment from "moment";
import { getCurrentTimeZone, getDuration } from "utils/time";
import { connect } from "react-redux";
import { filterString } from 'utils/sFold';

const Mentor = ({ user, page, currentUser }) => {
  let hasCourse = user.closest_date.length > 0;
  let closestDate = null;
  let duration = null;
  let durationType = null;
  if (hasCourse) {
    const nowUnix = moment().utc().unix();
    const closestDates = user.closest_date.filter(t => t > nowUnix).sort((a, b) => a === b ? 0 : a > b ? 1 : -1);
    hasCourse = closestDates.length > 0;
    if (closestDates.length > 0) {
      closestDate = closestDates[0];
      const closestTz = moment.unix(closestDate).tz(getCurrentTimeZone(currentUser));
      const now = moment(new Date()).tz(getCurrentTimeZone(currentUser));
      const result = moment.duration(closestTz.diff(now));
      const { duration: dur, unit } = getDuration(result);
      duration = dur;
      durationType = unit;
    }
  }
  return (
    <div className="card mentor">
      <div className="card child relative">
        <div className="row absolute dingdong-top-right">
          <div className="flex-center">
            <DingDongUser followId={user._id} followName={user.first_name} />
          </div>
        </div>
        <div className="mentor-thumb">
          <img
            src={
              user.avatar
                ? user.avatar.startsWith("https://")
                  ? user.avatar
                  : process.env.REACT_APP_MEDIA_URL + user.avatar
                : UserImage
            }
            alt=""
            className="img-fluid card-thumb"
          />
          <div className="flex-1">
            <div className="row">
              <div className="col-md-12">
                <div className="mentor-name">
                  <Link to={`/profile/${user.username}`}>
                    <h4 className="large">{user.name}</h4>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              {user.city ?
              <div className="col">
                <small className="text-muted">
                  <b>Lives in </b>
                  {" " + user.location}
                </small>
              </div>
              : null }
              {user.languages && user.languages.length > 0 ? (
                <div className="col">
                  <small className="text-muted">
                    <b>Speaks: </b>
                    {" " + user.languages}
                  </small>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="info-row text-muted bg-light">
            <b>About me: </b>
            <strong className="card-about"
              dangerouslySetInnerHTML={{
                __html: user.description
                  ? filterString(user.description).length > 90
                    ? filterString(user.description).substring(0, 90) + "..."
                    : filterString(user.description)
                  : "N/A",
              }}
            ></strong>
          </div>

          <div className="info-row text-muted bg-light">
            <b>Expertise: </b>
            <strong
              className="card-about"
              dangerouslySetInnerHTML={{
                __html: user.expertise
                  ? filterString(user.expertise).length > 90
                    ? filterString(user.expertise).substring(0, 90) + "..."
                    : filterString(user.expertise)
                  : "N/A",
              }}
            ></strong>
          </div>
          <div className="row">
            <div className="col-md-7">
              <div className="info-row">
                {hasCourse ?
                <strong className="ml-2 mt-3">
                  Next event in{" "}
                  <a style={{ color: "red" }}>{duration}</a> {durationType}
                </strong> : <strong className="ml-2 mt-3">
                  No upcoming sessions or events
                </strong>}
              </div>
            </div>
            <div className="col-md-5 text-right">
              <Link
                to={`/profile/${user.username}`}
                className="btn bg-secondary btn-sm mr-3"
              >
                View More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.isAuthenticated ? state.auth.user : null
});

export default connect(mapStateToProps, null)(Mentor);
