import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Pagination from "views/Front/Pagination";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  RECORD_PER_PAGE,
  SESSION_TYPE_REVERSE,
  SESSION_TYPE,
  CHIME_CLIENT_URL,
  BOOKED_SESSION_STATUS,
} from "constants/index";
import { getUpcomingSessions, generateMeetingLink } from "actions/front/educator/courses";
import { getCourseDetailById } from "actions/front/common/course";
import { rescheduleSession } from "actions/front/learner/order";
import { cancelCourseScheduled } from "actions/front/learner/courses";
import Spinner from "views/Spinner";
import NoRecord from "views/NoRecord";
import CourseImage from "assets/images/mock-event.jpg";
import EditImage from "assets/images/edit.svg";
import {
  getEvents,
  educatorCancelCourseScheduled,
} from "actions/front/mentorDashboard";
import * as moment from 'moment-timezone';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormText } from "reactstrap";
import Swal from 'sweetalert2';
import { getCurrentTimeZone } from "utils/time";
import AskMeAnythingDetailPage from "views/Front/ProfileCourseMgmt/MyCourses/RescheduleSession/AskMeAnythingDetailPage";
import SpecificDetailPage from "views/Front/ProfileCourseMgmt/MyCourses/RescheduleSession/SpecificDetailPage";
import inRange from "lodash.inrange";

const UpcomingSession = ({ getUpcomingSessions, user, token, RESULTS, generateMeetingLink, educatorCancelCourseScheduled, getEvents, getCourseDetailById, rescheduleSession, cancelCourseScheduled }) => {
  let timeZone = getCurrentTimeZone(user);

  let unixTimeStamp = moment.utc().unix();
  let now  = moment(moment.unix(unixTimeStamp).tz(timeZone).format("YYYY-MM-DD HH:mm:ss"));

  const [coursesList, setCoursesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generateMeetId, setGenerateMeetingId] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchText, setSearchText] = useState("");
  const activeResults = RESULTS.UPCOMING_SESSION;

  const initialFilters = {
    limit: RECORD_PER_PAGE,
    page: 1,
    orderBy: "start_at",
    ascending: "desc",
    query: "",
    custom_filters: "", //start_date //end_date
  };
  const [filters, setFilters] = useState({ ...initialFilters });
  const [meetingIdGenerated ,gettingMeetingLink] = useState(false);
  const [events, setEvents] = useState([]);

  const paginate = (page_no) => {
    setFilters({ ...filters, page: page_no });
  };

  const onClickSearchText = (e) => {
    e.preventDefault();
    setFilters({ ...filters, query: searchText });
  };

  useEffect(() => {
    setLoading(true);
    getEvents().then(async (data) => {
      if (data.status) {
        setEvents(data.response.events);
      }
    })
    console.log("================getUpcomingSessions",filters,activeResults);
    getUpcomingSessions(filters, activeResults).then((res) => {
      if (res.status) {
        res.data.data.sort(
          (a, b) => a.start_at - b.start_at
        );
        setCoursesList(res.data.data.filter((courseStatus) => courseStatus.course_status === 1));
        setTotalRecords(res.data.metadata[0].totalRecord);
      }
      setLoading(false);
    });
  }, [filters, activeResults, meetingIdGenerated]);

  const onCancelHostingClick = async (course) => {
    let sessionForCancellation = {};
    if (course.session_type !== 1) {
      sessionForCancellation = await coursesList.filter((iter) => iter.all_session_with_recurring_id === course._id)[0];
    } else {
      await coursesList.filter((iter) => {
        if (iter.booked_sessions) {
          let a = iter.booked_sessions.filter((session) => session.all_session_with_recurring_id === course._id);
          if (a.length > 0) {
          sessionForCancellation['all_session_with_recurring_id']= a[0].all_session_with_recurring_id;
          sessionForCancellation['course_id']= iter.course_id;
          sessionForCancellation['session_group_id']= iter.session_group_id;
          sessionForCancellation['start_at']= a[0].start_at;
          sessionForCancellation['end_at']= a[0].end_at;
          sessionForCancellation['session_type']= iter.session_type;
          }
        }
      });
    }
    confirmAlert({
      title: <h6>Are you sure you want to cancel this session ?</h6>,
      message: (
        <div>
          <span>Your learners will be notified and 100% refunded</span>
        </div>
      ),
      buttons: [
        {
          label: "Ok",
          onClick: () => confirmHostingCancel(sessionForCancellation),
        },
        {
          label: "Back",
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const confirmHostingCancel = async (sessionForCancellation) => {

    let obj = {
      all_session_with_recurring_id: sessionForCancellation.all_session_with_recurring_id,
        course_id: sessionForCancellation.course_id,
        session_group_id: sessionForCancellation.session_group_id,
        start_at: sessionForCancellation.start_at,
        end_at: sessionForCancellation.end_at,
        detail_for: sessionForCancellation.session_type
    };

    educatorCancelCourseScheduled(obj).then(async (res) => {
      if (res.status) {
        Swal.fire(res.msg, "", 'success')
        setFilters({ ...filters });
      }
    });
  };

  const onCancelAttendingClick = async (course_detail_id) => {
    confirmAlert({
      title: <h6>Please be aware of our cancellation policy:</h6>,
      message: (
        <div>
          <span>1- Cancelling &gt; 24 hours is free</span>
          <br />
          <span>2- Only 50% will be refunded if Cancelling &lt; 24 hours</span>
        </div>
      ),
      buttons: [
        {
          label: "Ok",
          onClick: () => confirmAttendingCancel(course_detail_id),
        },
        {
          label: "Back",
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const confirmAttendingCancel = async (course_detail_id) => {
    // setCancelLoader(true);
    cancelCourseScheduled(course_detail_id).then(async (res) => {
      if (res.status) {
        let newSessionData = [...coursesList];
        await newSessionData.map((singleSession) => {
          if (singleSession.course_detail_id === course_detail_id) {
            singleSession.course_status =
              BOOKED_SESSION_STATUS.CANCLLED_BY_LEARNER;
          }
        });
        Swal.fire(res.msg, "", "success");
        setCoursesList([...newSessionData]);
        // setCancelLoader(false);
      } else {
        Swal.fire("Oops...", res.msg, "error");
        // setCancelLoader(false);
      }
    });
  };

  /****************************Reschedule************************************/
  const [isIndividualExist, setIsIndividualExist] = useState(false);
  const [isGroupExist, setIsGroupExist] = useState(false);
  const [isGenericExist, setIsGenericExist] = useState(false);

  const [sendSessionData, setSendSessionData] = useState(null);
  const [errorSuccess, setErrorSuccess] = useState({ isError: null, msg: "" });
  const [scheduleLoader, setScheduleLoader] = useState(false);
  const [resetData, setResetData] = useState(null);

  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [courseDetail, setCourseDetail] = useState(null);
  const [isCourseFull, setIsCourseFull] = useState(false);
  const [sessionType, setSessionType] = useState(null);
  const [genericSessionTiming, setGenericSessionTiming] = useState("");
  const [alreadyScheduledId, setAlreadyScheduledId] = useState(null);
  const onRescheduleClick = (
    e,
    already_scheduled_course_detail_id,
    course_id,
    session_type,
    session_timing
  ) => {
    getCourseDetailById(course_id).then((res) => {
      if (res.status) {
        setIsCourseFull(false);
        setGenericSessionTiming(session_timing);
        setSessionType(session_type);
        setAlreadyScheduledId(already_scheduled_course_detail_id);
        setCourseDetail(res.data);
        setRescheduleModal(!rescheduleModal);
      } else {
        if (res.is_full) {
          setIsCourseFull(true);
          setRescheduleModal(!rescheduleModal);
        }
      }
    });
  };

  const onScheduleClick = (e) => {
    e.preventDefault();
    if (sendSessionData === null) {
      setErrorSuccess({
        isError: true,
        msg: "Please select at least one session.",
        cartBtn: false,
      });
      setTimeout(() => setErrorSuccess({ isError: null, msg: "" }), 10000);
    } else {
      setScheduleLoader(true);
      rescheduleSession(
        courseDetail._id,
        sendSessionData,
        alreadyScheduledId
      ).then((res) => {
        if (!res.status) {
          setErrorSuccess({
            isError: true,
            msg: res.msg,
          });
          setScheduleLoader(false);
          setTimeout(() => setErrorSuccess({ isError: null, msg: "" }), 10000);
        } else {
          setResetData(true);
          alert(res.msg);
          getUpcomingSessions();
          setRescheduleModal(!rescheduleModal);
          setScheduleLoader(false);
        }
      });
    }
  };



  /**********************filters *********************************/

  const initialCustomerFilter = {
    from_date: "",
    to_date: "",
  };
  const [custonFilter, setCustomFilter] = useState({
    ...initialCustomerFilter,
  });

  const { to_date, from_date } = custonFilter;

  const handleCustomFilter = (e) => {
    setCustomFilter({ ...custonFilter, [e.target.name]: e.target.value });
  };

  // useEffect(() => {
  //   let final_query = "";
  //   Object.keys(custonFilter).map(function (key) {
  //     final_query = `${final_query}&${key}=${custonFilter[key]}`;
  //   });
  //   setFilters({ ...filters, page: 1, custom_filters: final_query });
  // }, [custonFilter]);

  const onClickFilterSubmit = (e) => {
    e.preventDefault();
    let final_query = "";
    Object.keys(custonFilter).map(function (key) {
      final_query = `${final_query}&${key}=${custonFilter[key]}`;
    });
    setFilters({
      ...filters,
      page: 1,
      custom_filters: final_query,
      query: searchText,
    });
  };

  const onClearFilters = (e) => {
    setFilters({ ...initialFilters });
    setCustomFilter({ ...initialCustomerFilter });
    setSearchText("");
  };

  const generateLink = (recurring_id, course_id) => {
    gettingMeetingLink(true);
    setGenerateMeetingId(course_id)
    generateMeetingLink(recurring_id, course_id).then(res => {
      if (res.status) {
        gettingMeetingLink(false);
        setGenerateMeetingId("")
      }
    })
  };

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  const showLearnerList = (learnerList) => {
    setShowModal(true);
    setModalData(learnerList);
  };

  const closePopup = (e) => {
    setShowModal(false);
    setModalData([]);
  };

  const toggle = () => setShowModal(!showModal);
  const toggleReschedule = () => setRescheduleModal(!rescheduleModal);

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="upcoming"
        role="tabpanel"
        aria-labelledby="upcoming-tab"
      >
        {/*
        <div className="row">
          <div className="col-md-4">
            <form
              className="form-horizontal"
              onSubmit={(e) => onClickSearchText(e)}
            >

              <div className="input-group form-group">
                <input
                  type="text"
                  placeholder="Search Sessions..."
                  className="form-control"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-secondary"
                    type="submit"
                    onClick={(e) => onClickSearchText(e)}
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>

            </form>
          </div>
          <div className="col-md-12 mb-2">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="hidden-xl">Enter start date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="from_date"
                    value={from_date}
                    onChange={(e) => handleCustomFilter(e)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                <label className="hidden-xl">Enter end date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="to_date"
                    value={to_date}
                    onChange={(e) => handleCustomFilter(e)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <button
                  className="mr-2 show-btn"
                  onClick={(e) => onClearFilters(e)}
                >
                  Clear All
                </button>
                <input
                  type="submit"
                  className="btn btn-primary mb-0"
                  defaultValue="Apply"
                  onClick={(e) => onClickFilterSubmit(e)}
                />
              </div>
            </div>
          </div>
        </div>
        */}
        {coursesList.length > 0 ? (
          <div className="row">
            <div className="col-md-12">
              <ul className="session-list">
                {loading ? (
                  <Spinner />
                ) : (
                  coursesList.map((course, index) => {
                    return (
                      <li className="box" key={`course_${index}`}>
                        { generateMeetId === course.course_id ? (
                          <Spinner />
                        ) : (
                        <>
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <img
                            src={
                              course.course_image
                                ? process.env.REACT_APP_MEDIA_URL  +  course.course_image
                                : CourseImage
                            }
                            alt=""
                            className="list-thumb"
                          />
                          {course.session_type === SESSION_TYPE.GENERIC && (
                            <div className="ribbon ribbon-top-right">
                              <span>DD me for anything</span>
                            </div>
                          )}
                          {course.session_type === SESSION_TYPE.LIVE_EVENT && (
                            <div className="ribbon ribbon-top-right">
                              <span>Live Event</span>
                            </div>
                          )}
                        </div>

                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12 mb-1 space-between relative">
                              <span className="upcoming-tag">{course.type === "attending" ? "Attending" : "Hosting"}</span>
                              <Link to={`/session/${course.course_id}`}>
                                <h4 className="title upcoming-title">
                                  <strong
                                    className="text-orange"
                                    style={{ fontSize: 16 }}
                                  >
                                    {course.course_name.length > 20
                                      ? course.course_name.substring(0, 20)
                                      : course.course_name}
                                  </strong>
                                </h4>
                              </Link>
                            </div>
                            <div className="col-md-5">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="info-row">
                                    <span>Session Date</span>
                                    <strong>
                                      {moment
                                        .unix(course.start_at)
                                        .tz(timeZone)
                                        .format("MMM Do, YYYY")}
                                    </strong>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="info-row">
                                    <span>Start Time</span>
                                    <strong>
                                      {moment
                                        .unix(course.start_at)
                                        .tz(timeZone)
                                        .format("hh:mm A")}
                                    </strong>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="info-row m-0">
                                    <span>End Time</span>
                                    <strong>
                                      {course.end_at ? moment
                                        .unix(course.end_at)
                                        .tz(timeZone)
                                        .format("hh:mm A") : "-"}
                                    </strong>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="info-row m-0">
                                    <span>Timezone</span>
                                    <strong>
                                      {moment.tz(timeZone).format("z")}
                                    </strong>
                                  </div>
                                </div>

                                {course.type === 'attending' ? (
                                  <div className="col-md-12">
                                    <div className="info-row m-0">
                                      <span>Schedule</span>
                                      <strong>
                                        <button
                                          className="show-btn"
                                          onClick={(e) => onRescheduleClick(
                                            e,
                                            course.course_detail_id,
                                            course.course_id,
                                            course.session_type,
                                            course.session_type ==
                                              SESSION_TYPE.GENERIC
                                              ? course.session_timing
                                              : ""
                                          )}
                                        >
                                          Reschedule →
                                        </button>
                                      </strong>
                                    </div>
                                    <div className="info-row m-0">
                                    {course.start_at >
                                      unixTimeStamp ? (
                                        <strong>
                                          <button
                                            className="show-btn"
                                            onClick={() =>
                                              onCancelAttendingClick(course.course_detail_id)
                                            }
                                          >
                                            Cancel
                                          </button>
                                        </strong>
                                      ) : (
                                        <span className="show-btn disabled-reschedule-btn">
                                          Cancel
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                ) : (
                                  unixTimeStamp <
                                    course.start_at && (
                                    <button
                                      className="btn bg-primary btn-sm"
                                      style={{ marginBottom: 1 }}
                                      onClick={(e) => onCancelHostingClick(course)}
                                    >
                                      Cancel Booking
                                    </button>
                                  )
                                )}

                              </div>
                            </div>
                            <div className="col-md-7">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="info-row">
                                    <span>Session Type</span>
                                    <strong>
                                      <span
                                        className="text-orange"
                                        style={{ fontWeight: 700 }}
                                      >
                                        {course.session_type === 1
                                          ? "DD Me for Anything"
                                          : null}
                                        {course.session_type === 2
                                          ? "1-1 Session Offered"
                                          : null}
                                        {course.session_type === 3
                                          ? "Group Session Offered"
                                          : null}
                                        {course.session_type === 4
                                          ? "1-1 and Group Session Offered"
                                          : null}
                                        {course.session_type === 5
                                          ? "Live Event"
                                          : null}
                                      </span>
                                    </strong>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="info-row">
                                    <span>Link</span>
                                    {course.type === 'hosting' ? (course.meeting_id ? (
                                      <a
                                        rel="noreferrer noopener"
                                        href={`${CHIME_CLIENT_URL}join/${
                                          course.meeting_id
                                        }?token=${token}`}
                                        target="_blank"
                                      >
                                        <strong className="btn btn-primary">
                                          {course.session_type === 5 ? "Join Event" : "Join Session"}
                                        </strong>
                                      </a>
                                    ) : // Defines the Range to allow generate link 5 min before the session and 10 mins after the session time
                                    inRange(
                                        moment
                                          .duration(
                                            now.diff(
                                              moment
                                                .unix(course.start_at)
                                                .tz(timeZone)
                                                .format("YYYY-MM-DD HH:mm:ss")
                                            )
                                          )
                                          .asMinutes(),
                                        -5,
                                        10
                                      ) ? <>
                                      <Button
                                        className="btn btn-primary"
                                        disabled={ generateMeetId === course.course_id ? true : false }
                                        onClick={() =>
                                          generateLink(
                                            course._id,
                                            course.course_id,
                                            user.token
                                          )
                                        }
                                      >
                                        Generate Link
                                      </Button>
                                      </> : <>
                                            {moment
                                              .duration(
                                                now.diff(
                                                  moment
                                                    .unix(course.start_at)
                                                    .tz(timeZone)
                                                    .format("YYYY-MM-DD HH:mm:ss")
                                                )
                                              )
                                              .asMinutes() > 5 ? (
                                              <>
                                                <strong>
                                                  <span className="orange-text">
                                                    You've missed the window to generate link
                                                  </span>
                                                </strong>
                                              </>
                                            ) : (
                                              <span className="orange-text">
                                                Please check back at least 5 min before the start
                                              </span>
                                            )}
                                          </>) : course.meeting_id ? (
                                      <a
                                        rel="noreferrer noopener"
                                        href={`${CHIME_CLIENT_URL}join/${course.meeting_id}?token=${token}`}
                                        target="_blank"
                                      >
                                        <strong className="btn btn-primary">
                                          {course.session_type === 5 ? "Attend Event" : "Attend Session"}
                                        </strong>
                                      </a>
                                    ) : <span className="orange-text">
                                          Please check back at least 5 min before the start
                                        </span>}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="info-row m-0">
                                    {course.type === 'hosting' ? (
                                      <>
                                        <span>Enrolled Learners</span>
                                        <strong>
                                          {course.session_booking_count > 0 ? (
                                            <button
                                              className="show-btn"
                                              onClick={(e) =>
                                                showLearnerList(
                                                  course.enrolled_students
                                                )
                                              }
                                            >
                                              {course.session_booking_count}
                                            </button>
                                          ) : (
                                            "None"
                                          )}
                                        </strong>
                                      </>
                                    ) : (
                                      <>
                                        <span>Mentor</span>
                                        <strong>
                                          <Link to={`/profile/${course.mentor_username}`}>
                                            {course.mentor_name}
                                          </Link>
                                        </strong>
                                      </>
                                    )}
                                  </div>
                                  <div className="info-row m-0">
                                    <span>Interaction</span>
                                    <strong>
                                      &nbsp;&nbsp;
                                      {course?.interaction_number &&
                                      course.session_type !== 1
                                        ? course.interaction_number
                                        : "N/A"}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </>
                        )}
                      </li>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
        ) : (
          <NoRecord className="low" />
        )}
        {totalRecords > 0 ?
        <div className="row">
          <div className="col-md-12 mt-5">
            <Pagination
              totalRecords={totalRecords}
              recordPerPage={RECORD_PER_PAGE}
              paginate={paginate}
              currentPage={filters.page}
            />
          </div>
        </div> : null}
      </div>
      <Modal isOpen={showModal} toggle={toggle} onClosed={closePopup}>
        <ModalHeader>
          <h5 className="modal-title">Enrolled Learners</h5>
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <table className="table table-bordered">
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
              {modalData.map((learner, idx) => (
                <tr key={`learner_${idx}`}>
                  <td>{idx + 1}</td>
                  <td>
                    <Link to={`/profile/${learner.learner_username}`}>
                      {learner.learner_name}
                    </Link>
                    {learner.learner_message && (
                      <p>{learner.learner_message}</p>
                    )}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            className="m-1"
            onClick={(e) => closePopup(e)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={rescheduleModal} toggle={toggleReschedule}>
        <ModalHeader toggle={toggleReschedule}>Reschedule</ModalHeader>
        <ModalBody>
          {rescheduleModal && (<form id="indiviual-form" onSubmit={(e) => onScheduleClick(e)}>
            {isCourseFull ? (
              <div> No Sessions Available </div>
            ) : (
              <>
                {/* Specific course page */}
                {sessionType != SESSION_TYPE.GENERIC && (
                  <SpecificDetailPage
                    courseDetail={courseDetail}
                    user={user}
                    setSendSessionData={setSendSessionData}
                    setResetData={setResetData}
                    resetData={resetData}
                    setIsIndividualExist={setIsIndividualExist}
                    setIsGroupExist={setIsGroupExist}
                    isIndividualExist={isIndividualExist}
                    isGroupExist={isGroupExist}
                    setErrorSuccess={setErrorSuccess}
                    sessionType={sessionType}
                  />
                )}
                {/* One to One page */}
                {sessionType === SESSION_TYPE.GENERIC && (
                  <AskMeAnythingDetailPage
                    courseDetail={courseDetail}
                    user={user}
                    // history={history}
                    genericSessionTiming={genericSessionTiming}
                    setResetData={setResetData}
                    resetData={resetData}
                    setSendSessionData={setSendSessionData}
                    setIsGenericExist={setIsGenericExist}
                    isGenericExist={isGenericExist}
                  />
                )}
                {((sessionType === SESSION_TYPE.SPECIFIC_INDIVIDUAL &&
                  isIndividualExist) ||
                  (sessionType === SESSION_TYPE.SPECIFIC_GROUP &&
                    isGroupExist) ||
                  (sessionType === SESSION_TYPE.GENERIC &&
                    isGenericExist)) && (
                  <>
                    {errorSuccess.isError === true && (
                      <FormText
                        className={`invalid-feedback-text error ${
                          errorSuccess.isError && "error text-danger"
                        }`}
                      >
                        {errorSuccess.msg}
                      </FormText>
                    )}

                    <br />
                    {scheduleLoader === true ? (
                      <Spinner />
                    ) : (
                      <button
                        type="submit"
                        className="btn bg-secondary d-block btn-lg"
                      >
                        Re-Schedule →
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </form>)}
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token
});

export default connect(mapStateToProps, {
  getUpcomingSessions,
  generateMeetingLink,
  educatorCancelCourseScheduled,
  cancelCourseScheduled,
  getEvents,
  getCourseDetailById,
  rescheduleSession,
})(withRouter(UpcomingSession));
