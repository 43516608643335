import axios from "utils/axios";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  // REMOVE_ERRORS,
  GOOGLE_SIGN_IN_SUCCESS,
  GOOGLE_SIGN_IN_FAIL,
  USER_LOADED,
  NEW_USER_LOADED,
  AUTH_LOADING,
  AUTH_FAIL,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_ERROR,
  RESET_LINK_SUCCESS,
  RESET_LINK_FAIL,
  RESET_LINK_GET_DETAIL_SUCCESS,
  AUTH_ERROR,
  RESET_PASSWORD_SUCCESS,
  CHECK_LINK_SUCCESS,
  CHECK_LINK_ERROR,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import Swal from "sweetalert2";
import * as Sentry from "@sentry/react";
import {
  getStatusCardLocalKey,
  LOCAL_DISMISSED_TYPE,
} from "views/Front/Snippets/MyaccountSidebar";

//switch profile

export const changeProfile = (usertype) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      login_role: usertype,
    });

    const res = await axios.post(`/api/auth/changerole`, body, config);
    if (res.data.status === true) {
      dispatch(loadUser());
    }
  } catch (err) {}
};

//Resend Activation
export const resendActivation = (email) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      email,
    });

    const res = await axios.post(
      `/api/register/resendactivation`,
      body,
      config
    );
    if (res.data.status === true) {
      return {
        status: true,
        message: res.data.message,
      };
      // dispatch({
      //   type: REGISTER_SUCCESS,
      //   payload: {
      //     message: res.data.message,
      //     alertType: "success",
      //     data: res.data.response,
      //   },
      // });
    }
  } catch (err) {
    dispatch({
      type: REGISTER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
      },
    });
    return false;
  }
};

// Load User    判断用户当前状态
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({
      type: AUTH_LOADING,
      payload: true,
    });
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const res = await axios.get(`/api/auth`);
    if (res.data.status === true) {
      dispatch({
        type: USER_LOADED,
        payload: res.data.response,
      });
    }
  } catch (err) {
    setAuthToken("");
    dispatch({
      type: AUTH_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

// Load User 获取用户最新数据
export const loadUserLastInfo = () => async (dispatch) => {
  try {
    // dispatch({
    //   type: AUTH_LOADING,
    //   payload: true,
    // });
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const res = await axios.get(`/api/auth`);
    if (res.data.status === true) {
      dispatch({
        type: NEW_USER_LOADED,
        payload: res.data.response,
      });
    }
  } catch (err) {
    setAuthToken("");
    dispatch({
      type: AUTH_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

//Register User
export const register = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: AUTH_LOADING,
      payload: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(`/api/register`, formData, config);
    if (res.data.status === true) {
      // dispatch({
      //   type: REGISTER_SUCCESS,
      //   payload: {
      //     message: res.data.message,
      //     alertType: "success",
      //     data: res.data.response,
      //   },
      // });
      return {
        status: true,
        message: res.data.message,
      };
    } else {
      dispatch({
        type: AUTH_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList: res.data.errors?.map((err) => err.msg),
        },
      });

      return false;
    }
  } catch (err) {
    dispatch({
      type: REGISTER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
      },
    });
    return false;
  }
};

//Logout / Clear Profile
export const logout = () => (dispatch, getState) => {
  const user = getState()?.auth?.user;
  setAuthToken("");
  Sentry.setUser(null);

  if (user) {
    const localSidebarStatusKey = getStatusCardLocalKey(user);
    const sidebarDismissType = localStorage.getItem(localSidebarStatusKey);
    if (
      sidebarDismissType &&
      sidebarDismissType === LOCAL_DISMISSED_TYPE.ReappearAfterLogin
    ) {
      localStorage.removeItem(localSidebarStatusKey);
    }
  }

  dispatch({
    type: LOGOUT,
    payload: {
      message: "Logout",
      alertType: "success",
      data: {},
    },
  });
};

//Check logged user role
// export const checkandupdateuser = (id) => async (dispatch) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };

//   const body = JSON.stringify({ id });
// };

//Login User
export const login =
  (email, password, history, props, redirectTo = null) =>
  async (dispatch) => {
    try {
      dispatch({
        type: AUTH_LOADING,
        payload: true,
      });
      const body = { email, password };

      const res = await axios.post(`/api/auth`, body);
      if (res.data.status === true) {
        await localStorage.setItem("token", res.data.response.token);
        await setAuthToken(localStorage.token);
        Sentry.setUser(res.data.response.user);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data.response,
        });

        // if (res.data.response.user.last_login_role === null) {
        //   const body = JSON.stringify({ id: res.data.response.user.id });
        // }

        // If user tries to purchase course without login
        if (
          props.location.state?.from &&
          props.location.state?.from === "Purchase Course"
        ) {
          history.goBack();
        }

        //redirecting after login
        if (redirectTo) {
          history.push(redirectTo);
        } else if (props.location.state === undefined) {
          history.push("/dashboard");
        }
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }

        let isinactive = res.data.response.isinactive ?? false;
        let activateEmail=""
        if(isinactive) activateEmail=res.data.response.email
        dispatch({
          type: LOGIN_ERROR,
          payload: {
            message: res.data.message,
            alertType: "danger",
            errorsList,
            isinactive,
            email,
            activateEmail
          },
        });
        // dispatch(setAlert(res.data.message, "danger"));
      }
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : "Something went wrong.",
          alertType: "danger",
        },
      });
    }
  };

// Generate Reset Link
export const resetLink =
  ({ email }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: AUTH_LOADING,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({ email });

      const res = await axios.post(`/api/forgotPassword`, body, config);

      if (res.data.status === true) {
        Swal.fire("", "We have e-mailed your password reset link!", "success");
        dispatch({
          type: RESET_LINK_SUCCESS,
          payload: {
            message: "We have e-mailed your password reset link!",
            alertType: "success",
            data: res.data.response,
          },
        });
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }
        Swal.fire("", errors[0].msg, "warning");
        dispatch({
          type: AUTH_ERROR,
          payload: {
            message: errors[0].msg,
            alertType: "danger",
            errorsList,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: RESET_LINK_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : "Something went wrong.",
          alertType: "danger",
        },
      });
    }
  };

// Get email by id
export const getEmailById = (id, token) => async (dispatch) => {
  try {
    dispatch({
      type: AUTH_LOADING,
      payload: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/forgotPassword/${id}` + `/` + `${token}`,
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: RESET_LINK_GET_DETAIL_SUCCESS,
        payload: res.data.response,
      });
      return res.data;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: AUTH_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: RESET_LINK_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
      },
    });
  }
};

export const checklink = (confirmation_token) => async (dispatch) => {
  try {
    dispatch({
      type: AUTH_LOADING,
      payload: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/confirmUser/${confirmation_token}`,
      config
    );
    if (res.data.status === true) {
      // await dispatch({
      //   type: CHECK_LINK_SUCCESS,
      //   payload: {
      //     message: res.data.message,
      //     alertType: "success",
      //     data: "",
      //   },
      // });
      return res.data;
    } else {
      let errorsList = [];
      // dispatch({
      //   type: CHECK_LINK_ERROR,
      //   payload: {
      //     message: res.data.message,
      //     alertType: "danger",
      //     errorsList,
      //   },
      // });
      return res.data;
    }
  } catch (err) {
    dispatch({
      type: RESET_LINK_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
      },
    });
  }
};

// Token invalid
export const tokenInvalid = (history) => async (dispatch) => {
  history.push("/forgotPassword");
};

//Dispatch Confirm password error
export const setPasswordError = (msg, alertType) => async (dispatch) => {
  dispatch({
    type: AUTH_ERROR,
    payload: {
      message: msg,
      alertType: alertType,
    },
  });
};

// Reset Password
export const resetPassword =
  (password, email, id, history) => async (dispatch) => {
    try {
      dispatch({
        type: AUTH_LOADING,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({ password, email });

      const res = await axios.post(`/api/forgotPassword/${id}`, body, config);
      if (res.data.status === true) {
        // await localStorage.setItem("token", res.data.response.token);
        // await setAuthToken(localStorage.token);
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: res.data.response,
        });

        setTimeout(() => {
          history.push(`/login`);
        }, 10000);

        // dispatch(login(email, password));
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }
        dispatch({
          type: AUTH_ERROR,
          payload: {
            message: res.data.message,
            alertType: "danger",
            errorsList,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: RESET_LINK_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : "Something went wrong.",
          alertType: "danger",
        },
      });
    }
  };

// Google Register
export const googleSignIn =
  (formValues, user_type, timezone) => async (dispatch) => {
    try {
      dispatch({
        type: AUTH_LOADING,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `/api/googlelogin/google/register`,
        Object.assign(formValues, {
          google_user_type: user_type,
          timezone: timezone,
        }),
        config
      );
      if (res.data.status === true) {
        await localStorage.setItem("token", res.data.response.token);
        await setAuthToken(localStorage.token);
        dispatch({
          type: GOOGLE_SIGN_IN_SUCCESS,
          payload: res.data.response,
        });
        if (res.data.response.user.last_login_role === null) {
          const body = JSON.stringify({ id: res.data.response.user.id });
          // console.log(body);
          // const res2 = await axios.post(`/api/auth/coursecategory/create`, body, config);
        }
        // dispatch(loadUser());
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }
        dispatch({
          type: AUTH_ERROR,
          payload: {
            message: res.data.message,
            alertType: "danger",
            errorsList,
          },
        });
      }
    } catch (err) {
      // console.log(err);
      dispatch({
        type: GOOGLE_SIGN_IN_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : "Something went wrong.",
          alertType: "danger",
        },
      });
    }
  };

// Google Login
export const googleLogin =
  (formValues, user_type, timezone, history, redirectTo = null) =>
  async (dispatch) => {
    try {
      dispatch({
        type: AUTH_LOADING,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `/api/googlelogin/google`,
        Object.assign(formValues, {
          google_user_type: user_type,
          timezone: timezone,
        }),
        config
      );
      if (res.data.status === true) {
        await localStorage.setItem("token", res.data.response.token);
        await setAuthToken(localStorage.token);
        dispatch({
          type: GOOGLE_SIGN_IN_SUCCESS,
          payload: res.data.response,
        });
        if (res.data.response.user.last_login_role === null) {
          const body = JSON.stringify({ id: res.data.response.user.id });
        }
        if (redirectTo) {
          history.push(redirectTo);
        }
      } else {
        dispatch({
          type: AUTH_ERROR,
          payload: {
            message: res.data.message,
            alertType: "danger",
          },
        });
      }
    } catch (err) {
      // console.log(err);
      dispatch({
        type: GOOGLE_SIGN_IN_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : "Something went wrong.",
          alertType: "danger",
        },
      });
    }
  };
