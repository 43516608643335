import { ReactComponent as PlusCircle } from "assets/images/icons/plus-circle.svg";
import { ReactComponent as MinusCircle } from "assets/images/icons/minus-circle.svg";
import { useState } from "react";

export default function Accordion({ title, content, defaultIsOpen = false }) {
  const [isOpen, setOpen] = useState(defaultIsOpen);

  return (
    <li className={`p-8 rounded-2xl ${isOpen ? "bg-dgray-50" : ""}`}>
      <div
        className={`font-medium flex justify-between ${isOpen ? "mb-2" : ""}`}
      >
        {title}
        <button onClick={() => setOpen((o) => !o)} className="text-dgray-500">
          {isOpen ? <MinusCircle /> : <PlusCircle />}
        </button>
      </div>
      {isOpen && <div className="text-dgray-500">{content}</div>}
    </li>
  );
}
