import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BackgroundImage from "assets/images/bg/bg51.jpg";

import { Spinner } from "reactstrap";
import Spinner1 from "views/Spinner1";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

import {
  getClientToken,
  getCartDetail,
  payNow,
} from "actions/front/learner/order";

const Checkout = ({ getClientToken, getCartDetail, payNow, history }) => {
  const [clientToken, setClientToken] = useState(null);
  const [instance, setInstance] = useState(null);

  useMemo(() => {
    getClientToken().then((res) => {
      if (res.status) {
        setClientToken(res.data);
      }
    });
  }, []);

  const [loader, setLoader] = useState(false);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderDetail, setOrderDetail] = useState(0);
  useMemo(() => {
    setLoader(true);
    getCartDetail().then((res) => {
      if (res.status) {
        if (!res.data.items || (res.data.items && res.data.items.length == 0)) {
          history.push("/all-courses");
        } else if (
          res.data.items &&
          res.data.items.length > 0 &&
          res.data.total == 0
        ) {
          history.push("/cart");
        } else {
          setOrderDetail(res.data);
          setOrderTotal(res.data.total);
        }
      }
      setLoader(false);
    });
  }, []);

  const [modal, setModal] = useState(false);
  const [modeldata, setModalData] = useState(false);

  const [paymentLoader, setPaymentLoader] = useState(false);
  const onClickPayNow = async (e) => {
    e.preventDefault();

    const { nonce } = await instance.requestPaymentMethod();
    setPaymentLoader(true);
    await instance.clearSelectedPaymentMethod();

    payNow(orderDetail, nonce).then((res) => {
      if (res.status) {
        setPaymentLoader(false);
        setModal(!modal);
        setModalData(res);
      }
    });
  };

  const closePopup = () => {
    history.push("/dashboard");
  };

  return clientToken == null || loader ? (
    <Spinner />
  ) : (
    <>
      <div className="row" id="banner">
        <div id="inner-banner" className="col-md-12 p-0">
          <img src={BackgroundImage} alt="" className="img-fluid" />
          <div className="inner-caption d-md-block">
            <h2 className="mb-3">Checkout</h2>
          </div>
        </div>
      </div>
      <div className="row course-section p-60 bg-light" id="cart-details">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <Link to="/cart" className="text-orange bold p-0 mb-3">
                  {" "}
                  Back to Cart
                </Link>
              </div>
            </div>
            <div className="col-md-4 order-md-2 mb-4">
              {/*  <h5>Cart Amount</h5>
                        <hr class="mb-4"> */}
              <div className="card">
                <div className="card-header">
                  <h5>Summary</h5>
                </div>
                <div className="card-body">
                  <div className="cart-total">
                    <div className="inline-flex">
                      <label htmlFor>Sub Total</label>
                      <strong>${orderTotal}</strong>
                    </div>
                    {/* <div class="inline-flex">
                                        <label for="">Tax</label>
                                        <strong>$100.00</strong>
                                    </div> */}
                  </div>
                </div>
                <div className="card-footer bg-white">
                  <div className="inline-flex">
                    <label htmlFor>Total Amount</label>
                    <strong>${orderTotal}</strong>
                  </div>
                </div>
              </div>
              <div className="mt-4 space-between w-100">
                {paymentLoader ? (
                  <Spinner1 />
                ) : (
                  <button
                    className="btn bg-secondary btn-lg"
                    onClick={(e) => onClickPayNow(e)}
                    disabled={instance === null ? true : false}
                  >
                    Pay Now and Schedule Session →
                  </button>
                )}
              </div>
            </div>
            <div className="col-md-8 order-md-1 box bg-white pt-4">
              <form className="needs-validation" noValidate>
                <div className="row">
                  {/* <div className="col-md-6">
                    <h5 className="mb-3">Payment</h5>
                    <div className="d-block my-3">
                      <div className="custom-control custom-radio">
                        <input
                          id="credit"
                          name="paymentMethod"
                          type="radio"
                          className="custom-control-input"
                          defaultChecked
                          required
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="credit"
                        >
                          Credit card
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          id="debit"
                          name="paymentMethod"
                          type="radio"
                          className="custom-control-input"
                          required
                        />
                        <label className="custom-control-label" htmlFor="debit">
                          Debit card
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          id="paypal"
                          name="paymentMethod"
                          type="radio"
                          className="custom-control-input"
                          required
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="paypal"
                        >
                          Paypal
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="mb-3">Saved Card</h5>
                        <div className="d-block my-3" id="savedCard">
                          <div className="custom-control custom-radio">
                            <input
                              name="savedCard"
                              type="radio"
                              className="custom-control-input"
                              defaultChecked
                              required
                            />
                            <label
                              htmlFor="cc-name"
                              className="custom-control-label"
                            >
                              <strong>1234 #### #### 1253</strong>
                              <small className="text-muted">
                                ICICI Debit Card
                              </small>
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              name="savedCard"
                              type="radio"
                              className="custom-control-input"
                              required
                            />
                            <label
                              htmlFor="cc-name"
                              className="custom-control-label"
                            >
                              <strong>1234 #### #### 1253</strong>
                              <small className="text-muted">
                                ICICI Debit Card
                              </small>
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              name="savedCard"
                              type="radio"
                              className="custom-control-input"
                              required
                            />
                            <label
                              htmlFor="cc-name"
                              className="custom-control-label"
                            >
                              <strong>1234 #### #### 1253</strong>
                              <small className="text-muted">
                                ICICI Debit Card
                              </small>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                   */}
                  {/* <div className="col-md-12">
                    <DropIn
                      options={{
                        authorization: clientToken,
                        vaultManager: true,
                        // preselectVaultedPaymentMethod: false,
                        // paypal: {
                        //   flow: "checkout",
                        //   // buttonStyle: {
                        //   //   color: "blue",
                        //   //   shape: "rect",
                        //   //   size: "medium",
                        //   // },
                        // },

                        card: { cardholderName: { required: true } },
                      }}
                      onInstance={(instance) => setInstance(instance)}
                    />
                  </div> */}

                  {/* <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="mb-3">Add New card</h5>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label htmlFor="cc-name">
                          Name on card
                          <small className="text-muted">
                            (Full name as displayed on card)
                          </small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-name"
                          placeholder
                          required
                        />
                        <div className="invalid-feedback">
                          Name on card is required
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label htmlFor="cc-number">Credit card number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-number"
                          placeholder
                          required
                        />
                        <div className="invalid-feedback">
                          Credit card number is required
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="cc-expiration">Expiration</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-expiration"
                          placeholder
                          required
                        />
                        <div className="invalid-feedback">
                          Expiration date required
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="cc-expiration">CVV</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-cvv"
                          placeholder
                          required
                        />
                        <div className="invalid-feedback">
                          Security code required
                        </div>
                      </div>
                      <div className="col-md-12 mb-3 text-right">
                        <input
                          type="submit"
                          defaultValue="Add"
                          className="btn btn-primary"
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modal}>
        <ModalHeader>Thankyou</ModalHeader>
        <ModalBody>
          <h5 className="error text-success">{modeldata.msg}</h5>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            className="m-1"
            onClick={(e) => closePopup(e)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

Checkout.propTypes = {
  getClientToken: PropTypes.func.isRequired,
  getCartDetail: PropTypes.func.isRequired,
  payNow: PropTypes.func.isRequired,
};

export default connect(null, { getClientToken, getCartDetail, payNow })(
  withRouter(Checkout)
);
