import { useEffect, useState } from "react";
import { connect } from "react-redux";
import TabPicker from "./TabPicker";

function CategoryPicker({
  viewAllLabel = "View All",
  initialFilters = defaultInitialFilters,
  // className = "",
  // onChange,
  // setPage,
  // setFilters,
  categories = [],
  ...props
}) {
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    if (categories) {
      setCategoryList([
        { value: false, label: viewAllLabel },
        ...categories.map((cat) => ({
          label: cat.name,
          value: cat._id,
        })),
      ]);
    }
  }, [categories]);

  return (
    <TabPicker
      items={categoryList}
      initialFilters={initialFilters}
      {...props}
    />
  );
}

export default connect(({ categories }) => ({
  categories: categories.data,
}))(CategoryPicker);

const defaultInitialFilters = {
  limit: 7,
  page: 1,
  orderBy: "start_at",
  ascending: "desc",
  query: "",
  custom_filters: "",
};
