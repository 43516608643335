import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
import Spinner from "views/Spinner";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import {
  getCourseSubCategoryList,
  deleteCourseSubCategory,
  changeStatus,
} from "actions/admin/coursesubcategory";
import * as Constants from "constants/index";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import overlayFactory from "react-bootstrap-table2-overlay";
import CustomFilters from "./CustomFilters";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Input,
} from "reactstrap";

const actions = (
  <div className="add-button-div">
    <Link to="/admin/course-subcategory/create" className="addNewElementClass">
      <Button color="primary" size="sm">
        <i className="fa fa-plus"></i> Create Course SubCategory
      </Button>
    </Link>
  </div>
);

const CourseSubCategoryList = ({
  getCourseSubCategoryList,
  deleteCourseSubCategory,
  changeStatus,
  history,
  courseSubCategoryList: { data, count, page },
  loading,
  subLoading,
}) => {
  //################## Initillise sorting searching parameters by default values ###################
  const [courseSubCategoryParams, setCourseSubCategoryParams] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
  });

  //################## to get updated list ###################

  useEffect(() => {
    getCourseSubCategoryList(courseSubCategoryParams);
  }, [courseSubCategoryParams]);

  //################## Handel table change(sorting, searching, status change) ###################
  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    setCourseSubCategoryParams({
      ...courseSubCategoryParams,
      page: type === "search" ? 1 : page,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      query: searchText,
    });
  };

  //#########################Colums defination start ###################
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "parent_category_name",
      text: "Category Name",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      // headerSortingStyle
    },
    {
      dataField: "created_at",
      text: "Date Created",
      sort: true,
      // headerSortingStyle,
      formatter: (cellContent, row) => (
        <Moment format="YYYY/MM/DD">{Number(row.created_at)}</Moment>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },

      formatter: (cell, row) => {
        return (
          <div>
            <Input
              type="select"
              name="status"
              id={row._id}
              defaultValue={cell}
              onChange={(e, a) => {
                changeStatus(row._id, e.target.value);
              }}
            >
              <option value="0">Inactive</option>
              <option value="1">Active</option>
            </Input>
          </div>
        );
      },
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: (cellContent, row) => (
        <div>
          <Link to={`/admin/course-subcategory/${row._id}`}>
            <Button type="button" size="sm" color="success">
              <i className="fa fa-pencil"></i>
            </Button>
          </Link>
          <Button
            type="button"
            size="sm"
            color="danger"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure you want to delete :\r ${row.name}?`
                )
              ) {
                deleteCourseSubCategory(row._id, history).then((res) => {
                  if (res) getCourseSubCategoryList(courseSubCategoryParams);
                });
              }
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
  ];
  //#########################Colums defination end ###################

  //################## START Set properties(options) of datatables ###################

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li
      key={text}
      role="presentation"
      className="dropdown-item"
      onMouseDown={(e) => {
        e.preventDefault();
        onSizePerPageChange(page);
      }}
    >
      <Button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        className="sizePerPageaTag"
      >
        {text}
      </Button>
    </li>
  );

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <>
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    </>
  );

  const options = {
    page: parseInt(courseSubCategoryParams.page),
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: count,
    // sizePerPageRenderer,
    sizePerPageOptionRenderer,
    sizePerPageList: [
      {
        text: Constants.DEFAULT_PAGE_SIZE,
        value: Constants.DEFAULT_PAGE_SIZE,
      },
      {
        text: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
        value: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
      },
      {
        text: "All",
        value: count,
      },
    ],
  };

  return (
    <div className="animated fadeIn displayTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              {actions}
              <PaginationProvider
                pagination={paginationFactory(options)}
                keyField="_id"
                columns={columns}
                data={data}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={data}
                    columns={columns}
                    search
                  >
                    {(toolkitprops) => (
                      <>
                        <>
                          <div className="per-page-div">
                            Record per page:
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <CustomFilters {...toolkitprops.searchProps} />
                        </>

                        <BootstrapTable
                          {...toolkitprops.baseProps}
                          bootstrap4
                          remote={{
                            pagination: true,
                            filter: true,
                            sort: true,
                          }}
                          keyField="_id"
                          data={data}
                          columns={columns}
                          {...paginationTableProps}
                          onTableChange={handleTableChange}
                          defaultSorted={defaultSorted}
                          noDataIndication={Constants.NO_RECORD}
                          bordered={false}
                          hover
                          loading={loading && !subLoading}
                          overlay={overlayFactory({
                            spinner: <Spinner />,
                            styles: {
                              overlay: (base) => ({
                                ...base,
                                background: "#E8E8E8",
                              }),
                            },
                          })}
                        />
                      </>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

CourseSubCategoryList.propTypes = {
  getCourseSubCategoryList: PropTypes.func.isRequired,
  courseSubCategoryList: PropTypes.object.isRequired,
  deleteCourseSubCategory: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  courseSubCategoryList: state.coursesubcategory.courseSubCategoriesList,
  loading: state.coursesubcategory.loading,
  subLoading: state.coursesubcategory.isSubLoading,
});

export default connect(mapStateToProps, {
  getCourseSubCategoryList,
  deleteCourseSubCategory,
  changeStatus,
})(withRouter(CourseSubCategoryList));
