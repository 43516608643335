import axios from "utils/axios";

import {
  COURSE_SUBCATEGORY_LOADING,
  COURSE_SUBCATEGORY_LIST_UPDATED,
  COURSE_SUBCATEGORY_FAIL,
  COURSE_SUBCATEGORY_CREATED,
  COURSE_SUBCATEGORY_CREATE_ERROR,
  COURSE_SUBCATEGORY_UPDATED,
  COURSE_SUBCATEGORY_UPDATE_ERROR,
  COURSE_SUBCATEGORY_DELETED,
  COURSE_SUBCATEGORY_DELETE_ERROR,
  COURSE_SUBCATEGORY_CHANGE_STATUS,
  COURSE_SUBCATEGORY_CHANGE_STATUS_ERROR,
  GET_COURSE_SUBCATEGORY_BY_ID,
} from "actions/types";

//get CourseSubCategory list
export const getCourseSubCategoryList = (courseSubCategoryParams) => async (
  dispatch
) => {
  try {
    dispatch({
      type: COURSE_SUBCATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const query = courseSubCategoryParams.query
      ? courseSubCategoryParams.query
      : "&query=";
    const res = await axios.get(
      `/api/admin/coursesubcategory?limit=${courseSubCategoryParams.limit}&page=${courseSubCategoryParams.page}&orderBy=${courseSubCategoryParams.orderBy}&ascending=${courseSubCategoryParams.ascending}${query}`,
      config
    );

    dispatch({
      type: COURSE_SUBCATEGORY_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: "success",
        data: res.data.response[0],
      },
    });
  } catch (err) {
    dispatch({
      type: COURSE_SUBCATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

// Create Course Category
export const create = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_SUBCATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/admin/coursesubcategory/create`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: COURSE_SUBCATEGORY_CREATED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
      history.push("/admin/course-subcategory");
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: COURSE_SUBCATEGORY_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: COURSE_SUBCATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

// Update Course Category
export const edit = (formData, history, coursesubcategory_id) => async (
  dispatch
) => {
  try {
    dispatch({
      type: COURSE_SUBCATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/admin/coursesubcategory/${coursesubcategory_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: COURSE_SUBCATEGORY_UPDATED,
        payload: {
          message: res.data.message,
          alertType: "success",
        },
      });
      history.push("/admin/course-subcategory");
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: COURSE_SUBCATEGORY_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: COURSE_SUBCATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

// Delete Course Category
export const deleteCourseSubCategory = (
  coursesubcategory_id,
  history
) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_SUBCATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let res = await axios.delete(
      `/api/admin/coursesubcategory/${coursesubcategory_id}`,
      config
    );
    // dispatch({ type: REMOVE_ALERT });

    if (res.data.status === true) {
      await dispatch({
        type: COURSE_SUBCATEGORY_DELETED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: COURSE_SUBCATEGORY_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: COURSE_SUBCATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (coursesubcategory_id, status) => async (
  dispatch
) => {
  try {
    dispatch({
      type: COURSE_SUBCATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/admin/coursesubcategory/change-status/${coursesubcategory_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: COURSE_SUBCATEGORY_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: COURSE_SUBCATEGORY_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          data: coursesubcategory_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: COURSE_SUBCATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

// get Course SubCategory by id
export const getCourseSubCategoryById = (coursesubcategory_id) => async (
  dispatch
) => {
  try {
    dispatch({
      type: COURSE_SUBCATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/admin/coursesubcategory/${coursesubcategory_id}`,
      config
    );
    await dispatch({
      type: GET_COURSE_SUBCATEGORY_BY_ID,
      payload: {
        message: res.data.message,
        alertType: "success",
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: COURSE_SUBCATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

export const getCourseCategories = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/admin/coursecategory/list/all`, config);

    return res.data.response;
  } catch (err) {
    dispatch({
      type: COURSE_SUBCATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};
