import axios from "utils/axios";
import {
  LEARNER_LOADING,
  LEARNER_LIST_UPDATED,
  LEARNER_FAIL,
  LEARNER_CHANGE_STATUS,
  LEARNER_CHANGE_STATUS_ERROR,
  DELETE_USER,
  LEARNER_DETAILS,
} from "actions/types";

export const getFullLearnersList = async (count) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(
      `/api/admin/learner?limit=${count}`,
      config
    );
    return res.data.response[0];
  } catch (err) {
    console.log(err);
  }
}

//get learners list

export const getLearnersList = (userParams) => async (dispatch) => {
  try {
    dispatch({
      type: LEARNER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const query = userParams.query ? userParams.query : "&query=";
    const res = await axios.get(
      `/api/admin/learner?limit=${userParams.limit}&page=${userParams.page}&orderBy=${userParams.orderBy}&ascending=${userParams.ascending}${query}`,
      config
    );
    dispatch({
      type: LEARNER_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: "success",
        data: res.data.response[0],
      },
    });
    return res.data.response[0];
  } catch (err) {
    // console.log(err);
    dispatch({
      type: LEARNER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

export const getLearnerDetail = (userParams) => async (dispatch) => {
  try {
    dispatch({
      type: LEARNER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/admin/learner/details/${userParams}`, config
    );

    console.log(res, '============')
if (res.data.status) {
  dispatch({
    type: LEARNER_DETAILS,
    payload: res.data.response,
  });
  return res;
}
  } catch (err) {
    // console.log(err);
    // dispatch({
    //   type: LEARNER_FAIL,
    //   payload: {
    //     message:
    //       err.response && err.response.statusText
    //         ? err.response.statusText
    //         : "Something went wrong.",
    //     alertType: "danger",
    //     error: err,
    //   },
    // });
  }
};

//change status
export const changeStatus = (user_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: LEARNER_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/admin/learner/change-status/${user_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: LEARNER_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: LEARNER_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          data: user_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: LEARNER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};

//delete learner
export const deleteUser = (user_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.delete(`/api/admin/learner/${user_id}`, config);
    if (res.data.status === true) {
      await dispatch({
        type: DELETE_USER,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: LEARNER_FAIL,
        payload: {
          message: res.data.message,
          alertType: "danger",
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: LEARNER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};
