import CenterContainer from "components/common/Container/Center";
import Separator from "components/common/Separator";
import NotificationEventItem from "components/notifications/NotificationEventItem";
import { NOTIFICATION_EVENT } from "constants";
import { useEffect, useState } from "react";
import Spinner from "views/Spinner";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

export default function Notifications() {
  const [latestEvents, setLatestEvents] = useState();
  const [olderEvents, setOlderEvents] = useState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // send request
    setLatestEvents(fakeLatestEvents);
    setOlderEvents(fakeOlderEvents);
    setLoading(false);
  }, []);

  return (
    <>
      <Header />
      <CenterContainer className="pb-24 md:pb-16">
        <div className="py-24 md:py-16">
          <h1 className="text-dgray-900 text-5xl md:text-4xl font-semibold text-center mb-16">
            Notifications
          </h1>
        </div>
        <div className="flex-1">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Separator label="Latest" className="mb-12 text-primary-600" />
              <div className="divide-y">
                {latestEvents?.map((event) => (
                  <NotificationEventItem event={event} />
                ))}
              </div>
              <Separator label="Older" className="my-12" />
              <div className="divide-y">
                {olderEvents?.map((event) => (
                  <NotificationEventItem event={event} />
                ))}
              </div>
            </>
          )}
        </div>
      </CenterContainer>
      <Footer />
    </>
  );
}

const fakeEventCreate = {
  mentor_name: "Olivia Rhye",
  mentor_avatar: "",
  created_at: new Date().toISOString(),
  type: NOTIFICATION_EVENT.EVENT_CREATE,
  data: {
    name: "Example Event Name",
    date: new Date().toISOString(),
    category: "5fff08662fc7be00539299a9",
    timezone: "GMT +3",
  },
};

const fakeBlogCreate = {
  mentor_name: "Olivia Rhye",
  mentor_avatar: "",
  created_at: new Date().toISOString(),
  type: NOTIFICATION_EVENT.BLOG_CREATE,
  data: {
    name: "Example Blog Name",
    category: "5fff08662fc7be00539299a9",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id.",
  },
};

const fakeLatestEvents = [
  fakeEventCreate,
  fakeBlogCreate,
  { type: NOTIFICATION_EVENT.WELCOME },
  { type: NOTIFICATION_EVENT.HOST_APPLICATION_RECEIVED },
  { type: NOTIFICATION_EVENT.HOST_APPLICATION_APPROVED },
  { type: NOTIFICATION_EVENT.HOST_APPLICATION_REJECTED },
  {
    type: NOTIFICATION_EVENT.UPCOMING_EVENT_HOST,
    data: {
      name: "Example Event Name",
      date: new Date().toISOString(),
      category: "5fff08662fc7be00539299a9",
      timezone: "GMT +3",
      signedUpCount: 23,
    },
  },
  {
    type: NOTIFICATION_EVENT.UPCOMING_EVENT_STUDENT,
    data: {
      id: "123",
      name: "Example Event Name",
      date: new Date().toISOString(),
      category: "5fff08662fc7be00539299a9",
      timezone: "GMT +3",
    },
  },
];
const fakeOlderEvents = [fakeEventCreate, fakeBlogCreate];
