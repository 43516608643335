// import React from 'react';
import Homepage from "views/Front/HomePage";
import HomePageBecomeAHost from "views/Front/HomePage/BecomeAHost";
// import Myaccount from "views/Front/Myaccount";
// import Dashboard from "views/Front/Dashboard";
// import ChangePassword from "views/Front/ChangePassword";
import AddCourse from "views/Front/Courses/AddCourse/AddCourse";
import EditCourse from "views/Front/Courses/AddCourse/EditCourse";
import EditDraftCourse from "views/Front/Courses/AddCourse/EditDraftCourse";

import Login from "views/Auth/Login";
import Register from "views/Auth/Register";
import ForgotPassword from "views/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "views/Auth/ForgotPassword/ResetPassword";
import ConfirmUser from "views/Auth/ConfirmUser";
import VerificationEmail from "views/Auth/VerificationEmail";
import ForLearner from "views/Front/ForLearner";
import ForMentor from "views/Front/ForMentor";
import Events from "views/Front/Courses/CoursesList/List";
import ExploreEvents from "views/Front/Courses/CoursesList/Explore";
import AllMentors from "views/Front/Mentor/MentorsList/List";
import ViewMentorProfile from "views/Front/Mentor/ViewMentorProfile";
import ViewCourseDetail from "views/Front/Courses/CourseDetail/Detail";
import Index from "views/Front/ProfileCourseMgmt/Index";
import Cart from "views/Front/Order/Cart/Cart";
import PaymentComplete from "views/Front/Order/Cart/PaymentComplete";
import Checkout from "views/Front/Order/Checkout/Checkout";
import Notification from "views/Front/Notification/Notification";
import OrderDetail from "views/Front/Order/OrderDetail";
import WriteAReview from "views/Front/CourseRating/Rating";
import MeetingEnd from "views/MeetingEnd";
import AddBlog from "views/Front/Blogs/AddBlog";
import EditBlog from "views/Front/Blogs/EditBlog";
import TermsOfService from "views/Front/FooterLinks/TermsOfService";
import HowItWorks from "views/Front/FooterLinks/HowItWorks";
import Help from "views/Front/FooterLinks/Help";
import AboutUs from "views/Front/FooterLinks/AboutUs";
import Contact from "views/Front/FooterLinks/Contact";
import Hosts from "views/Front/Hosts";
import HostDetails from "views/Front/Hosts/Details";
import Blogs from "views/Front/Blogs";
import Blog from "views/Front/Blogs/Blog";
import Privacy from "views/Front/FooterLinks/Privacy";
import Cookies from "views/Front/FooterLinks/Cookies";
import CreateEvent from "views/Front/Create/Event";
import CreateEventPublic from "views/Front/Create/Event/Public";
import CreateEventOneOnOne from "views/Front/Create/Event/OneOnOne";
import CreateBlog from "views/Front/Create/Blog";
import Myaccount from "views/Front/ProfileCourseMgmt/Myaccount";
import MyAccountSettings from "views/Front/ProfileCourseMgmt/MyAccountSettings";
import BecomeAHostForm from "views/Front/BecomeAHost";
import BecomeAHost from "views/Front/ProfileCourseMgmt/BecomeAHost";
import Feed from "views/Front/Feed";
import Notifications from "views/Front/Notifications";

const FrontRoutes = [
  {
    path: "/",
    exact: true,
    name: "Homepage",
    component: Homepage,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/become-a-host",
    exact: true,
    name: "Become a Host",
    component: HomePageBecomeAHost,
    isprivate: true,
    isforall: false,
  },
  {
    path: "/become-a-host/apply",
    exact: true,
    name: "Become a Host",
    component: BecomeAHostForm,
    isprivate: true,
    isforall: false,
  },
  {
    path: "/login",
    exact: true,
    name: "Login Page",
    component: Login,
    ispublic: true,
    isforall: false,
    isPreviousPath: false,
  },
  {
    path: "/register/:user_role?",
    exact: true,
    name: "Register",
    component: Register,
    ispublic: true,
    isforall: false,
  },
  {
    path: "/forgot-password",
    exact: true,
    name: "Forgot Password",
    component: ForgotPassword,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/resetPassword/:id/:token",
    exact: false,
    name: "Reset Password",
    component: ResetPassword,
    ispublic: true,
    isforall: false,
  },
  {
    path: "/confirmUser/:confirmation_token",
    exact: false,
    name: "Confirm User",
    component: ConfirmUser,
    ispublic: true,
    isforall: false,
  },
  {
    path: "/verificationEmail",
    exact: false,
    name: "Verification Email",
    component: VerificationEmail,
    ispublic: true,
    isforall: false,
  },
  // {
  //   path: "/profile",
  //   exact: true,
  //   name: "My Account",
  //   component: Myaccount,
  //   isprivate: true,
  //   ispublic: false,
  //   isforall: false,
  // },
  // {
  //   path: "/changepassword",
  //   exact: true,
  //   name: "Change Password",
  //   component: ChangePassword,
  //   isprivate: true,
  //   ispublic: false,
  //   isforall: false,
  // },
  // {
  //   path: "/dashboard",
  //   exact: true,
  //   name: "Dashboard",
  //   component: Dashboard,
  //   isprivate: true,
  //   ispublic: false,
  //   isforall: false,
  // },
  {
    path: "/add-session",
    exact: true,
    name: "Create New Course",
    component: AddCourse,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/edit-draft-session/:course_id",
    exact: true,
    name: "Edit Draft Course",
    component: EditDraftCourse,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/edit-session/:course_id",
    exact: true,
    name: "Edit Course",
    component: EditCourse,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/how-it-works",
    exact: true,
    name: "How It Works",
    component: HowItWorks,
    ispublic: true,
    isforall: true,
  },

  {
    path: "/events",
    exact: true,
    name: "Events",
    component: Events,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/explore",
    exact: true,
    name: "Explore Events",
    component: ExploreEvents,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/find-connections",
    exact: true,
    name: "Find Connections",
    component: AllMentors,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/hosts",
    exact: true,
    name: "Hosts",
    component: Hosts,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/hosts/:hostUsername",
    exact: true,
    name: "Host Details",
    component: HostDetails,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/profile/:mentor_id",
    exact: true,
    name: "Mentor Profile",
    component: ViewMentorProfile,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/session/:course_id/review",
    exact: false,
    name: "Write a Review",
    component: WriteAReview,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/session/:course_id",
    exact: true,
    name: "Course Detail",
    component: ViewCourseDetail,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/blogs",
    exact: true,
    name: "Blog Detail",
    component: Blogs,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/blogs/:slug",
    exact: true,
    name: "Blog Detail",
    component: Blog,
    ispublic: true,
    isforall: true,
  },

  {
    path: "/help",
    exact: true,
    name: "DDN Help",
    component: Help,
    ispublic: true,
    isforall: true,
  },

  {
    path: "/dashboard*",
    exact: true,
    name: "Course Management",
    component: Index,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/contact",
    exact: true,
    name: "Contact",
    component: Contact,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/cart",
    exact: true,
    name: "Cart",
    component: Cart,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/payment-complete",
    exact: true,
    name: "PaymentComplete",
    component: PaymentComplete,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  /*{
    path: "/schedule-live-event",
    exact: true,
    name: "Live Event",
    component: LiveEvent,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },*/
  {
    path: "/checkout",
    exact: true,
    name: "Checkout",
    component: Checkout,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/order-detail",
    exact: true,
    name: "Checkout",
    component: OrderDetail,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/notification",
    exact: true,
    name: "Notification",
    component: Notification,
    isprivate: true,
    ispublic: false,
    isforall: true,
  },
  {
    path: "/terms",
    exact: true,
    name: "TERMS OF SERVICE",
    component: TermsOfService,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/privacy-policy",
    exact: true,
    name: "PRIVACY POLICY",
    component: Privacy,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/cookies",
    exact: true,
    name: "COOKIES",
    component: Cookies,
    ispublic: true,
    isforall: true,
  },

  {
    path: "/about-us",
    exact: true,
    name: "About Us",
    component: AboutUs,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/meeting-end",
    exact: true,
    name: "Meeting Ended",
    component: MeetingEnd,
    ispublic: true,
    isforall: true,
  },
  {
    path: "/add-blog",
    exact: true,
    name: "Create Add Blog",
    component: AddBlog,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/edit-blog/:blog_id",
    exact: true,
    name: "Edit Blog",
    component: EditBlog,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },

  {
    path: "/create",
    exact: true,
    name: "Create Event",
    component: CreateEvent,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/create/event",
    exact: true,
    name: "Create Public Event",
    component: CreateEventPublic,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/create/one-on-one",
    exact: true,
    name: "Create One on One Event",
    component: CreateEventOneOnOne,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/create/blog",
    exact: true,
    name: "Create Blog",
    component: CreateBlog,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/profile",
    exact: true,
    name: "My Account",
    component: Myaccount,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/settings",
    exact: true,
    name: "My Account Settings",
    component: MyAccountSettings,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/account/become-a-host",
    exact: true,
    name: "Become a Host",
    component: BecomeAHost,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/feed",
    exact: true,
    name: "Feed",
    component: Feed,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
  {
    path: "/notifications",
    exact: true,
    name: "Notifications",
    component: Notifications,
    isprivate: true,
    ispublic: false,
    isforall: false,
  },
];

export default FrontRoutes;
