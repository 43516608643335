import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { editPassword } from "actions/admin/profile";
import Errors from "views/Admin/Layout/Errors";
import Spinner from "views/Spinner";

import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label,
} from "reactstrap";

import { REMOVE_ERRORS } from "actions/types";

const ChangePassword = ({
  email,
  onClosed,
  editPassword,
  errorList,
  doOpen,
  loadingChangePassword,
}) => {
  /**************** dispatch to remove error *********************/
  const dispatch = useDispatch();

  const initialData = {
    email,
    password: "",
    current_password: "",
    confirm_password: "",
  };

  const [formData, setFormData] = useState(initialData);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const reset = () => {
    setModal(false);
    setFormData(initialData);
    dispatch({ type: REMOVE_ERRORS });
    onClosed();
  };

  useEffect(() => {
    setModal(doOpen);
  }, [doOpen]);

  const { password, current_password, confirm_password } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };

  const savePassword = (e) => {
    e.preventDefault();
    editPassword(formData).then((res) => {
      //reset();
      if (res) setModal(false);
    });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} onClosed={reset}>
      <ModalHeader toggle={toggle}>Change Password</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label htmlFor="current_password">
            Current Password <span>*</span>
          </Label>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-lock"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="password"
              placeholder="Current Password"
              autoComplete="current_password"
              name="current_password"
              id="current_password"
              minLength="6"
              value={current_password}
              onChange={(e) => onChange(e)}
              invalid={errorList.current_password ? true : false}
            />
          </InputGroup>
          <Errors current_key="current_password" key="current_password" />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="password">
            New Password <span>*</span>
          </Label>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-lock"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="password"
              placeholder="Password"
              autoComplete="password"
              name="password"
              id="password"
              minLength="6"
              value={password}
              onChange={(e) => onChange(e)}
              invalid={errorList.password ? true : false}
            />
          </InputGroup>
          <Errors current_key="password" key="password" />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="confirm_password">
            Confirm Password <span>*</span>
          </Label>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-lock"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="password"
              placeholder="Confirm Password"
              autoComplete="confirm_password"
              name="confirm_password"
              id="confirm_password"
              minLength="6"
              value={confirm_password}
              onChange={(e) => onChange(e)}
              invalid={errorList.confirm_password ? true : false}
            />
          </InputGroup>
          <Errors current_key="confirm_password" key="confirm_password" />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {loadingChangePassword ? (
          <Spinner />
        ) : (
          <>
            <a onClick={savePassword} className="m-1" href="#!">
              <Button color="primary">Save</Button>
            </a>
            <Button color="secondary" className="m-1" onClick={toggle}>
              Close
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

ChangePassword.propTypes = {
  editPassword: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loadingChangePassword: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  loadingChangePassword: state.profile.loadingChangePassword,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  editPassword,
})(ChangePassword);
