import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import { follow, unFollow } from "actions/front/dingdong";
import DingDongUser from "../DingDong/DingDongUser";
import { Tooltip } from "reactstrap";

import img1 from "assets/images/user.jpg";
import dingdongnow from "assets/images/icons/dingdongnow.svg";
import Courses from "views/Front/Courses/CoursesList/Courses";
import Spinner from "views/Spinner";

import { getMentorById } from "actions/front/myaccount";
import { getAllCourses } from "actions/front/common/course";
import { SORTING, SESSION_TYPE } from "constants/index";
import NoRecord from "views/NoRecord";
import { filterString } from "utils/sFold";
import { getAllUserBlogs } from "actions/front/blog";
import Blogs from "views/Front/Blogs/MentorBlogList";

const ViewMentorProfile = ({
  getMentorById,
  match,
  getAllCourses,
  user,
  getAllUserBlogs,
  authLoading,
  follow,
  unFollow,
}) => {
  const [mentors, setMentorData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [courseLoading, setCourseLoading] = useState(false);
  const [coursesList, setCoursesList] = useState([]);
  const [mentorsId, setMentorId] = useState(null);
  const [blogLoading, setBlogLoading] = useState(false);
  const [blogsList, setBlogsList] = useState([]);

  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [filter, setFilter] = useState({
    limit: 0,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    custom_filters: "",
  });

  useEffect(() => {
    setLoading(true);
    if (match.params.mentor_id) {
      getMentorById(match.params.mentor_id).then((data) => {
        if (data.status) {
          setMentorData(data.response[0]);
          setMentorId(data.response[0]._id);
          setLoading(false);
        } else {
          setMentorData(null);
          setLoading(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    setCourseLoading(true);
    if (mentorsId != null) {
      getAllCourses(filter, mentorsId, "all").then((res) => {
        if (res.status) {
          setCoursesList(res.data.data);
        }
        setCourseLoading(false);
      });
    }
  }, [filter, mentorsId]);

  useEffect(() => {
    setBlogLoading(true);
    if (mentorsId != null) {
      getAllUserBlogs(filter, mentorsId, "all").then((res) => {
        if (res.status) {
          setBlogsList(res.data.data);
        }
        setBlogLoading(false);
      });
    }
  }, [filter, mentorsId]);

  const [sortingFilter, setSortingFilter] = useState("");

  const onChangeSorting = (e) => {
    setSortingFilter(e.target.value);
  };

  const toggle = () => setTooltipOpen(!isTooltipOpen);

  useEffect(() => {
    let sortBy = "created_at";
    let orderDirection = "desc";
    if (sortingFilter == SORTING.ALPHABET_ASC) {
      sortBy = "name";
      orderDirection = "asc";
    } else if (sortingFilter == SORTING.ALPHABET_DESC) {
      sortBy = "name";
      orderDirection = "desc";
    } else if (sortingFilter == SORTING.DATE_DESC) {
      sortBy = "created_at";
      orderDirection = "desc";
    } else if (sortingFilter == SORTING.DATE_ASC) {
      sortBy = "created_at";
      orderDirection = "asc";
    } else if (sortingFilter == SORTING.RATING_LOW_HIGH) {
      sortBy = "avg_rating";
      orderDirection = "asc";
    } else if (sortingFilter == SORTING.RATING_HIGH_LOW) {
      sortBy = "avg_rating";
      orderDirection = "desc";
    }
    setFilter({ ...filter, orderBy: sortBy, ascending: orderDirection });
  }, [sortingFilter]);

  const [durationPrice, setDurationPrice] = useState([]);
  // const [minPriceCalculationLoading, setMinPriceCalculationLoading] = useState(false);
  var minPriceCalculationLoading = false;
  useMemo(async () => {
    minPriceCalculationLoading = true;
    let tempDurationPrice = [...durationPrice];
    await coursesList.map(async (course) => {
      await course.session.map(async (singleSession) => {
        await singleSession.duration_info.map((singleDuration) => {
          if (
            (tempDurationPrice[course._id] &&
              tempDurationPrice[course._id] >
                singleDuration.price_per_session) ||
            tempDurationPrice[course._id] == undefined
          ) {
            tempDurationPrice[course._id] = singleDuration.price_per_session;
          }
        });
      });
    });
    setDurationPrice(tempDurationPrice);
    minPriceCalculationLoading = false;
  }, [coursesList]);

  return loading || minPriceCalculationLoading ? (
    <div style={{ marginTop: 100, marginBottom: 100 }}>
      {" "}
      <Spinner />{" "}
    </div>
  ) : mentors !== null ? (
    <div className="row course-section" id="courses">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="profile-header">
              <div className="row flex-1">
                <div className="col-md-3 profile-details">
                  <div className="mentor-profile">
                    <img
                      src={
                        mentors && mentors.avatar
                          ? mentors.avatar.startsWith("https://")
                            ? mentors.avatar
                            : process.env.REACT_APP_MEDIA_URL + mentors.avatar
                          : img1
                      }
                      alt=""
                      className="img-fluid card-thumb rounded"
                    />
                  </div>
                  <div className="info-row w-100 text-center">
                    <div className="mentor-name mt-3">
                      <h3 className="title large">{mentors.name}</h3>
                    </div>
                    <div className="text-muted">
                      <span>
                        Member since{" "}
                        <Moment format="MMM YYYY">
                          {Number(mentors.created_at)}
                        </Moment>{" "}
                      </span>
                    </div>
                  </div>
                  {(user && mentors._id !== user.id) || !user ? (
                    <div className="info-row text-center">
                      <div className="flex-center">
                        <span className="text-capitalize">
                          Dingding {mentors.first_name}
                        </span>
                        <DingDongUser
                          followId={mentors._id}
                          followName={mentors.first_name}
                        />
                      </div>
                    </div>
                  ) : null}
                  {mentors.city ? 
                  <div className="info-row pl-4">
                    <strong>
                      <img
                        style={{ width: 24 }}
                        className="mr-1"
                        src="/assets/images/location2.svg"
                      />
                      <span>{mentors.location}</span>
                    </strong>
                  </div> : null }
                  {/*
                  <Tooltip
                    placement="top"
                    isOpen={isTooltipOpen}
                    target={`dingdong-status${mentorsId}`}
                    toggle={toggle}
                    dangerouslySetInnerHTML={{
                      __html: "Number of interactions",
                    }}
                  ></Tooltip>
                  <div className="info-row pl-4">
                    <strong className="flex align-center">
                      <img
                        style={{ width: 21 }}
                        id={`dingdong-status${mentorsId}`}
                        className="mr-1"
                        src={dingdongnow}
                      />
                      <span style={{ fontSize: 18 }}>{mentors.followers}</span>
                    </strong>
                  </div>
                  */}
                  {mentors.twitter ? (
                    <div className="info-row pl-4">
                      <strong className="flex align-center">
                        <i
                          className="fa fa-twitter mr-1"
                          style={{ fontSize: 24 }}
                        ></i>
                        <a
                          className="text-muted"
                          href={`https://twitter.com/${mentors.twitter}`}
                          target="_blank"
                        >
                          @{mentors.twitter}
                        </a>
                      </strong>
                    </div>
                  ) : null}
                  {mentors.linkedin ? (
                    <div className="info-row pl-4">
                      <strong className="flex align-center">
                        <i
                          className="fa fa-linkedin mr-1"
                          style={{ fontSize: 24 }}
                        ></i>
                        <a
                          className="text-muted ml-1"
                          style={{ display: "block", marginBottom: -3 }}
                          href={`https://linkedin.com/in/${mentors.linkedin}`}
                          target="_blank"
                        >
                          {mentors.linkedin}
                        </a>
                      </strong>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mentor-info">
                        <div className="info-row w-100 bg-light p-18">
                          <div className="text-orange">
                            <h5>About Me</h5>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: mentors.description
                                ? mentors.description
                                : "NA",
                            }}
                          ></div>
                        </div>
                        <div className="row w-100">
                          <div className="col-md-4">
                            <div className="info-row">
                              <div className="text-orange">
                                <b>Experience</b>
                              </div>
                              <strong>
                                {mentors.experience ? mentors.experience : "NA"}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="info-row">
                              <div className="text-orange">
                                <b>Education</b>
                              </div>
                              <strong>
                                {mentors.education ? mentors.education : "NA"}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="info-row">
                              <div className="text-orange">
                                <b>Languages</b>
                              </div>
                              <strong>
                                {mentors.languages &&
                                mentors.languages.length > 0
                                  ? mentors.languages.join(", ")
                                  : "NA"}
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="row w-100 mt-4">
                          <div className="col-md-12">
                            <div className="info-row">
                              <div className="text-orange">
                                <b>Area of Interest</b>
                              </div>
                              <strong>
                                {mentors.interests &&
                                mentors.interests.length > 0
                                  ? mentors.interests.join(", ")
                                  : "NA"}
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="info-row w-100 mt-4">
                          <div className="text-orange">
                            <b>Expertise</b>
                          </div>
                          <strong
                            dangerouslySetInnerHTML={{
                              __html: mentors.expertise
                                ? mentors.expertise
                                : "NA",
                            }}
                          ></strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-4 mb-4" />
        <div className="col-md-12 mb-3 mt-4">
          <div className="section-head">
            <h4 className="text-orange">Upcoming Public Events or Sessions</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group selectGroup">
              <select
                name="select-profession"
                className="form-control custom-select"
                value={sortingFilter}
                onChange={(e) => onChangeSorting(e)}
              >
                <option value="">Sort By</option>
                <option value={SORTING.ALPHABET_ASC}>A-Z</option>
                <option value={SORTING.ALPHABET_DESC}>Z-A</option>
                <option value={SORTING.DATE_DESC}>Date : Newest First</option>
                <option value={SORTING.DATE_ASC}>Date : Oldest First</option>
                <option value={SORTING.RATING_LOW_HIGH}>
                  Rating : Low to High
                </option>
                <option value={SORTING.RATING_HIGH_LOW}>
                  Rating : High to Low{" "}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="all-courses row">
              {courseLoading ? (
                <Spinner />
              ) : (
                <div className="col-md-12 col-lg-12 mb-3">
                  <Courses coursesList={coursesList} loading={loading} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <div className="section-head">
            <h4 className="text-orange">Blogs</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="all-courses row">
              {blogLoading ? (
                <Spinner />
              ) : (
                <div className="col-md-12 col-lg-12 mb-3">
                  <Blogs blogsList={blogsList} loading={loading} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <NoRecord msg="User Not Found" />
  );
};

ViewMentorProfile.propTypes = {
  getAllCourses: PropTypes.func.isRequired,
  getAllUserBlogs: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  authLoading: state.auth.loading,
});

export default connect(mapStateToProps, {
  getMentorById,
  getAllCourses,
  follow,
  unFollow,
  getAllUserBlogs,
})(withRouter(ViewMentorProfile));
