import { Link } from "react-router-dom";
import { ReactComponent as Rolling } from "assets/images/icons/rolling.svg";

export default function Button({
  children,
  onClick,
  className = "",
  href,
  to,
  external = false,
  small,
  secondary,
  flat,
  loading = false,
  nonClicking = false,
  ...props
}) {
  const classNames = `whitespace-nowrap rounded-lg text-center inline-block disabled:opacity-75
  ${
    secondary
      ? "bg-primary-50 text-primary-700"
      : flat
      ? "bg-white text-dgray-500 font-semibold border border-dgray-300"
      : "bg-primary-600 text-white"
  }
  ${small ? "text-sm py-2.5 px-[1.125rem]" : "py-4 px-7"} ${className}
  ${nonClicking ? "opacity-75" : ""}`;

  if (href || to) {
    if (external) {
      return (
        <a
          className={classNames}
          onClick={onClick}
          href={href || to}
          {...props}
          target="_blank"
        >
          {children}
        </a>
      );
    }
    return (
      <Link className={classNames} onClick={onClick} to={href || to} {...props}>
        {children}
      </Link>
    );
  }
  return (
    <button
      className={classNames}
      onClick={onClick}
      disabled={loading}
      {...props}
    >
      {loading ? <Rolling /> : children}
    </button>
  );
}
