import CenterContainer from "components/common/Container/Center";
import SectionTriangle from "components/common/SectionTriangle";
import HostList from "../HostList";

export default function HomePageSignedInHosts({ getAllMentors }) {
  return (
    <div className="relative bg-primary-50 pt-32 pb-40 md:pb-10">
      <SectionTriangle />
      <CenterContainer>
        <div className="text-center">
          <h2 className="text-4xl md:text-3xl font-semibold mb-5">
            Best Hosts for You
          </h2>
          <p className="text-dgray-500 mb-16 md:mb-10 text-xl md:text-lg">
            See specialized hosts within your areas of interest.
          </p>
        </div>
        <HostList />
      </CenterContainer>
    </div>
  );
}
