import { ReactComponent as Check } from "assets/images/icons/check-orange.svg";

export default function CheckCircle({ small }) {
  return (
    <div
      className={`bg-primary-100 rounded-full flex justify-center items-center shrink-0
        ${small ? "h-6 w-6" : "h-7 w-7"}
      `}
    >
      <Check />
    </div>
  );
}
