import React, { useState, useMemo, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import logo from "assets/images/logo.svg";
import { withRouter, Link } from "react-router-dom";
import * as moment from 'moment-timezone';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { SESSION_TYPE } from "constants/index";
import { getCurrentTimeZone } from "utils/time";

const OrderDetail = ({ user, orderDetails, history }) => {
  const [sessions_details, setSessionDetails] = useState("");

  const timeZone = getCurrentTimeZone(user);

  useEffect(() => {
    if (Object.keys(orderDetails).length <= 0)
      history.push("/dashboard/payment-and-metrics");
  }, []);

  useEffect(() => {
    if (orderDetails.session_type === SESSION_TYPE.GENERIC) {
      let sessionsDetails =
        moment
          .unix(orderDetails.session_data[0].start_at)
          .tz(timeZone)
          .format("DD MMM, YYYY HH:mm") +
        "-" +
        moment
          .unix(
            orderDetails.session_data[orderDetails.session_data.length - 1]
              .end_at
          )
          .tz(timeZone)
          .format("DD MMM, YYYY HH:mm");

      setSessionDetails(sessionsDetails);
    }
  }, [orderDetails]);

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProps= pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`Invoice-${user.first_name}.pdf`);
    });
  };

  return (
    <>
      <div className="order-body">
        <div className="order-detail-clearfix header">
          <div className="mb-3 mt-3">
            <Link to="/dashboard" className="text-orange bold p-0">
              Back To Dashboard
            </Link>
            <Link
              to="#"
              onClick={printDocument}
              style={{ paddingLeft: "78%" }}
              className="text-orange bold"
            >
              Download
            </Link>
          </div>
        </div>
      </div>
      <div className="order-body" id="divToPrint">
        <div className="order-detail-clearfix header">
          <h1 className="Invoice-header">ORDER ID: {orderDetails.order_id}</h1>
          <div id="logo">
            <img src={logo} alt="" className="img-fluid" />
          </div>
          <div id="company" className="order-detail-clearfix">
            <strong>
              Mentor Name <br />
              {orderDetails.mentor_name}
            </strong>
          </div>
          <div id="project">
            <strong>
              <span>Invoice To</span>
            </strong>
            <strong>
              {user.first_name} {user.last_name}
            </strong>
            <div>{user.email}</div>
            {/* <div>+91 - 988746###5</div>
          <div>Nov 02, 2020</div> */}
          </div>
        </div>
        <main>
          <table>
            <thead>
              <tr>
                <th className="sr">#</th>
                <th className="order-course">Course</th>
                <th className="session">Sessions</th>
                <th>Fees</th>
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="sr">1</td>
                <td className="order-course">{orderDetails.course_name}</td>
                <td className="session">
                  {
                    <p className="mb-1">
                      <small>Date/Time:</small> <br />
                      {sessions_details === ""
                        ? orderDetails.session_data &&
                          orderDetails.session_data.map((session) => (
                            <>
                              <span>
                                {moment
                                  .unix(session.start_at)
                                  .tz(timeZone)
                                  .format("DD MMM, YYYY HH:mm")}{" "}
                                -{" "}
                                {moment
                                  .unix(session.end_at)
                                  .tz(timeZone)
                                  .format("DD MMM, YYYY HH:mm")}
                              </span>
                              <br />
                            </>
                          ))
                        : sessions_details}
                    </p>
                  }
                </td>
                <td className="fee">${orderDetails.course_price}</td>
                <td className="total">${orderDetails.course_price}</td>
              </tr>
              <tr>
              <td colspan="4" className="grand total text-right">
                  Refund Amount
                </td>
                <td className="grand total">${orderDetails.refund_amount}</td>
              </tr>
              <tr>
                <td colspan="4" className="grand total text-right">
                  GRAND TOTAL
                </td>
                <td className="grand total">${orderDetails.course_price - orderDetails.refund_amount}</td>
              </tr>
            </tbody>
          </table>
        </main>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  orderDetails: state.myaccount.orderDetails,
});

export default connect(mapStateToProps)(withRouter(OrderDetail));
