import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { Alert as ShowAlert, Button } from "reactstrap";
import { REMOVE_ALERT } from "actions/types";
import { resendActivation } from "actions/auth";

const Alert = ({ alerts,resendActivation }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      alerts !== null &&
      alerts.length > 0 &&
      ((typeof alerts[0].isinactive !== "undefined" &&
        alerts[0].isinactive == false) ||
        typeof alerts[0].isinactive === "undefined")
    ) {
      setTimeout(() => dispatch({ type: REMOVE_ALERT }), 10000);
    }
  }, [alerts]);


const resendActivationLink = (e,email) =>{
  dispatch({ type: REMOVE_ALERT })

 resendActivation(email).then((res) => {});
};


  return (
    alerts !== null &&
    alerts.length > 0 &&
    alerts.map((alert, idx) => (
      <ShowAlert key={idx} color={`${alert.alertType}`}>
        {alert.message} <br/><br/>
        { (typeof alert.isinactive !== "undefined" &&
        alert.isinactive)&& <Button  onClick={e => resendActivationLink(e,alert.email ) }> Resend Verification Link</Button> }
      </ShowAlert>
    ))
  );
};
Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.frontAlert,
});

export default connect(mapStateToProps, {
  resendActivation,
})(Alert);
