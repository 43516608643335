import React, { useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { REMOVE_ERRORS, EDUCATOR_COURSE_CREATE_ERROR } from "actions/types";
import { RECURRING_TYPE_REVERSE, TABS } from "constants/index";
import DurationInformation from "./DurationInformation";
import SessionGroupInformation from "./SessionGroupInformation";
import moment from "moment";

const SpecificGroup = ({
  sessionFormData,
  setSessionFormData,
  setIsSpecificGroupValid,
  isConfirmation,
  setActiveTab,
  defaultSpecificGroupDateTimingInput,
  defaultSpecificGroupSessionGroup,
  isForEdit,
  current_date,
  setSpecificGroupErrors,
  setSessionErrorMsg,
}) => {
  /*********************Use Dispatch to set state ************************/
  const dispatch = useDispatch();

  /*********************useState for group form data ************************/
  const [sessionGroupFormData, setSessionGroupFormData] = useState({
    ...sessionFormData.specific_group,
  });

  /*********************explode form data ************************/
  const {
    duration_per_session,
    price_per_session,
    session_group,
    number_of_session,
    number_of_session_editable,
    can_add_new_bundle,
    max_students,
  } = sessionGroupFormData;
  /*********************INPUT type for change check ************************/
  const INPUT_TYPE = {
    SESSION: 1,
    RECURRING: 2,
    OTHER: 3,
  };

  /*********************onChange event ************************/
  const [sessionBundle, setSessionBundle] = useState(null);

  const getMinMaxDateInBundle = async (newSessionGroupData, bundelIndex) => {
    let minDate = "";
    let maxDate = "";
    await newSessionGroupData[bundelIndex].sessions.map((singleSession) => {
      let tempStartDate = moment(singleSession.start_date);
      if (minDate == "" || minDate.unix() > tempStartDate.unix()) {
        minDate = tempStartDate.clone();
      }
      if (maxDate == "" || maxDate.unix() < tempStartDate.unix()) {
        maxDate = tempStartDate.clone();
      }
    });
    return { minDate, maxDate };
  };

  const checkRecurring = async (newSessionGroupData, bundelIndex) => {
    //for session recurring type minimum
    //get min and max date
    let minDate = "";
    let maxDate = "";
    let getMinMaxDatePromise = await getMinMaxDateInBundle(
      newSessionGroupData,
      bundelIndex
    );

    await Promise.resolve(getMinMaxDatePromise).then((res) => {
      minDate = res.minDate;
      maxDate = res.maxDate;
    });
    //end----get min and max date
    // let minDate = "";
    // let maxDate = "";
    // await newSessionGroupData[bundelIndex].sessions.map((singleSession) => {
    //   let tempStartDate = moment(singleSession.start_date);
    //   if (minDate == "" || minDate.unix() > tempStartDate.unix()) {
    //     minDate = tempStartDate.clone();
    //   }
    //   if (maxDate == "" || maxDate.unix() < tempStartDate.unix()) {
    //     maxDate = tempStartDate.clone();
    //   }
    // });

    let calculated_end_date_for_week = moment(
      minDate.clone().add(7, "days").format("YYYY-MM-DD")
    );

    if (minDate.unix() == maxDate.unix())
      newSessionGroupData[bundelIndex].recurring_type_min =
        RECURRING_TYPE_REVERSE.DAILY;
    else if (maxDate.unix() < calculated_end_date_for_week.unix())
      newSessionGroupData[bundelIndex].recurring_type_min =
        RECURRING_TYPE_REVERSE.WEEKLY;
    else
      newSessionGroupData[bundelIndex].recurring_type_min =
        RECURRING_TYPE_REVERSE.MONTHLY;

    if (
      newSessionGroupData[bundelIndex].recurring_type !=
        RECURRING_TYPE_REVERSE.NONE &&
      newSessionGroupData[bundelIndex].recurring_type <
        newSessionGroupData[bundelIndex].recurring_type_min
    ) {
      newSessionGroupData[bundelIndex].recurring_type =
        RECURRING_TYPE_REVERSE.NONE;
      newSessionGroupData[bundelIndex].recurring_end_date = "";
    }
    //end-------for session recurring type minimum

    //check if recurring type is not none and set min date of recurring
    if (
      newSessionGroupData[bundelIndex].recurring_type !=
      RECURRING_TYPE_REVERSE.NONE
    ) {
      let recurringMinDate = maxDate.clone().add(1, "days");

      newSessionGroupData[
        bundelIndex
      ].recurring_min_date = recurringMinDate.clone().format("YYYY-MM-DD");
      //if recurring end date is less then recurring min date then chenge the recurring enddate accordingly
      if (
        moment(newSessionGroupData[bundelIndex].recurring_end_date).unix() <
        recurringMinDate.unix()
      ) {
        newSessionGroupData[
          bundelIndex
        ].recurring_end_date = recurringMinDate.clone().format("YYYY-MM-DD");
      }
    }
    //end----check if recurring type is not none and set min date of recurring

    return newSessionGroupData;
  };

  const onGroupChange = async (
    e,
    isSessionOrDurationOrRecurring = INPUT_TYPE.OTHER,
    index = 0,
    bundelIndex = 0,
    name = ""
  ) => {
    let error = false;
    if (isSessionOrDurationOrRecurring == INPUT_TYPE.SESSION) {
      //if the input is date,
      //check is it lowest in all selected date and it is not less than one month from the biggest selected date in the bundle
      //if true then set the max selected date for all sessions in the bundle by e.target.vlaue +1 month and set all that happen below
      //else set the error that dates can be in one month cretira it broke the rule becoz of maxselected date

      let newSessionGroupData = [...session_group];
      // let sessionData = [...session];
      let targetName = name != "" ? name : e.target.name;
      let targetValue = name != "" ? e.hour + ":" + e.minute : e.target.value;

      //check if the entered date is in the range if the entered is date
      if (targetName == "start_date") {
        let targetValueDate = new Date(targetValue);
        if (
          Object.prototype.toString.call(targetValueDate) === "[object Date]" &&
          !isNaN(targetValueDate.getTime())
        ) {
          let recentEnteredDate = moment(targetValue);
          let currentDate = moment(current_date);
          if (recentEnteredDate.unix() >= currentDate.unix()) {
            //get min  date
            let minDate = "";
            let tempNewSessionGroupData = [...newSessionGroupData];
            tempNewSessionGroupData[bundelIndex].sessions[index][
              targetName
            ] = targetValue;
            let getMinMaxDatePromise = await getMinMaxDateInBundle(
              tempNewSessionGroupData,
              bundelIndex
            );

            await Promise.resolve(getMinMaxDatePromise).then((res) => {
              minDate = res.minDate;
            });
            //end----get min date
            let maxDateCanBeEntered = moment(
              minDate.clone().add(1, "months").format("YYYY-MM-DD")
            );

            if (recentEnteredDate.unix() > maxDateCanBeEntered.unix()) {
              error = true;
              let errorsList = [];
              errorsList[
                `group_start_date_${bundelIndex}_${index}`
              ] = `Session date must be less than ${maxDateCanBeEntered.format(
                "MM/DD/YYYY"
              )}, as it voilates the max one month bracket.`;
              dispatch({
                type: EDUCATOR_COURSE_CREATE_ERROR,
                payload: {
                  message: "Please correct the errors",
                  alertType: "danger",
                  errorsList,
                },
              });
            } else {
              setSessionBundle(bundelIndex);
            }
          }
        }
      }

      //end------check if the entered date is in the range
      if (!error) {
        newSessionGroupData[bundelIndex].sessions[index][
          targetName
        ] = targetValue;

        setSessionGroupFormData({
          ...sessionGroupFormData,
          session_group: newSessionGroupData,
        });
      }
    } else if (
      isSessionOrDurationOrRecurring == INPUT_TYPE.OTHER &&
      e.target.name == "number_of_session"
    ) {
      let sessionCount = e.target.value;
      if (!isNaN(sessionCount) && Number(sessionCount) > 0) {
        if (Number(sessionCount) < Number(number_of_session)) {
          if (
            window.confirm(
              `Are you sure you want to decrease the number of session?`
            )
          ) {
            let newSessionGroupData = [...session_group];
            let promise = await newSessionGroupData.map(
              async (singleBundle, innerBundleIndex) => {
                if (
                  (singleBundle.is_new || singleBundle.is_edictable) &&
                  singleBundle.sessions.length > Number(sessionCount)
                ) {
                  newSessionGroupData[innerBundleIndex].sessions.splice(
                    Number(sessionCount) - 1,
                    singleBundle.sessions.length - Number(sessionCount)
                  );
                  //check for recurrence type and date
                  let checkRecurringPromise = await checkRecurring(
                    newSessionGroupData,
                    innerBundleIndex
                  );

                  await Promise.resolve(checkRecurringPromise).then((res) => {
                    newSessionGroupData = res;
                  });
                  //end----check for recurrence type and date
                }
                return true;
              }
            );
            await Promise.all(promise);

            setSessionGroupFormData({
              ...sessionGroupFormData,
              number_of_session: sessionCount,
              session_group: [...newSessionGroupData],
            });
          }
        } else {
          setSessionGroupFormData({
            ...sessionGroupFormData,
            [e.target.name]: sessionCount,
          });
        }
      }
    } else if (isSessionOrDurationOrRecurring == INPUT_TYPE.RECURRING) {
      let newSessionGroupData = [...session_group];
      newSessionGroupData[bundelIndex][e.target.name] = e.target.value;
      //check for recurrence type and date
      let checkRecurringPromise = await checkRecurring(
        newSessionGroupData,
        bundelIndex
      );

      await Promise.resolve(checkRecurringPromise).then((res) => {
        newSessionGroupData = res;
      });
      //end----check for recurrence type and date
      setSessionGroupFormData({
        ...sessionGroupFormData,
        session_group: newSessionGroupData,
      });
    } else {
      setSessionGroupFormData({
        ...sessionGroupFormData,
        [e.target.name]: e.target.value,
      });
    }
    if (!error) {
      dispatch({ type: REMOVE_ERRORS });
      setSessionErrorMsg("");
    }
  };

  useMemo(() => {
    if (sessionBundle != null) {
      let newSessionGroupData = [...session_group];

      let checkRecurringPromise = checkRecurring(
        newSessionGroupData,
        sessionBundle
      );
      Promise.resolve(checkRecurringPromise).then((res) => {
        newSessionGroupData = res;

        setSessionGroupFormData({
          ...sessionGroupFormData,
          session_group: newSessionGroupData,
        });
        setSessionBundle(null);
      });
    }
  });
  /*******************change the main form data when change in group form data ************************/
  useMemo(() => {
    setSessionFormData({
      ...sessionFormData,
      specific_group: {
        ...sessionGroupFormData,
      },
    });
  }, [sessionGroupFormData]);

  /************useMemo to enable the calander and final tab and check form validation**************/
  useMemo(() => {
    let isValid = true;
    let errorsList = [];
    if (!sessionGroupFormData.duration_per_session || sessionGroupFormData.duration_per_session === "") {
      isValid = false;
      errorsList[`group_duration_per_session`] = "Please select duration.";
    }
    if (sessionGroupFormData.price_per_session === undefined || sessionGroupFormData.price_per_session === null || sessionGroupFormData.price_per_session === "") {
      isValid = false;
      errorsList[`group_price_per_session`] =
        "Please select price per session.";
    }
    if (!sessionGroupFormData.number_of_session  || sessionGroupFormData.number_of_session === "") {
      isValid = false;
      errorsList[`group_number_of_session`] =
        "Please select number of sessions.";
    }
    if (!sessionGroupFormData.max_students || sessionGroupFormData.max_students === "") {
      isValid = false;
      errorsList[`group_max_students`] = "Please select max students.";
    }
    // if (
    //   sessionIndividualFormData.duration_per_session === "" ||
    //   sessionIndividualFormData.price_per_session === "" ||
    //   sessionIndividualFormData.number_of_session === ""
    // ) {
    //   isValid = false;
    // }
    sessionGroupFormData.session_group.map((singleBundle, bundelIndex) => {
      if (
        singleBundle.sessions.length !=
        Number(sessionGroupFormData.number_of_session)
      ) {
        isValid = false;
        errorsList[
          `group_start_date_${bundelIndex}`
        ] = `Please enter ${sessionGroupFormData.number_of_session} sessions.`;
      }
      if (
        singleBundle.recurring_type != RECURRING_TYPE_REVERSE.NONE &&
        singleBundle.recurring_end_date === ""
      ) {
        isValid = false;
        errorsList[`group_recurring_end_date_${bundelIndex}`] =
          "Please select recurring date.";
      }

      singleBundle.sessions.map((singleSession, sessionIndex) => {
        if (singleSession.start_date === "") {
          isValid = false;
          errorsList[`group_start_date_${bundelIndex}_${sessionIndex}`] =
            "Please select start date.";
        }
        if (singleSession.start_time === "") {
          isValid = false;
          errorsList[`group_start_time_${bundelIndex}_${sessionIndex}`] =
            "Please select start time.";
        }

        return isValid;
      });
      return isValid;
    });
    if (isValid) {
      setSpecificGroupErrors(null);
    } else {
      setSpecificGroupErrors(errorsList);
    }
    setIsSpecificGroupValid(isValid);
  }, [sessionGroupFormData]);

  const groupSessionHtml = (
    <div className="row mb-3" id="indiviual-form">
      <div className="col-md-12">
        <div className="form-group space-between">
          <b>Group Session</b>
        </div>
      </div>

      <DurationInformation
        onGroupChange={onGroupChange}
        isConfirmation={isConfirmation}
        duration_per_session={duration_per_session}
        price_per_session={price_per_session}
        number_of_session={number_of_session}
        number_of_session_editable={number_of_session_editable}
        max_students={max_students}
      />
      <SessionGroupInformation
        session_group={session_group}
        can_add_new_bundle={can_add_new_bundle}
        sessionGroupFormData={sessionGroupFormData}
        setSessionGroupFormData={setSessionGroupFormData}
        isConfirmation={isConfirmation}
        onGroupChange={onGroupChange}
        current_date={current_date}
        defaultSpecificGroupDateTimingInput={
          defaultSpecificGroupDateTimingInput
        }
        defaultSpecificGroupSessionGroup={defaultSpecificGroupSessionGroup}
        isForEdit={isForEdit}
        INPUT_TYPE={INPUT_TYPE}
        number_of_session={number_of_session}
        checkRecurring={checkRecurring}
        setSessionErrorMsg={setSessionErrorMsg}
      />
    </div>
  );

  return isConfirmation ? (
    <fieldset className="box p-3 col-md-12 mb-0 mt-3">
      <legend>Manage Availability</legend>
      <button
        className="action editIcon"
        onClick={(e) => setActiveTab(TABS.SESSION)}
      >
        <i className="fa fa-pencil"></i> &nbsp;Edit
      </button>
      {groupSessionHtml}
    </fieldset>
  ) : (
    groupSessionHtml
  );
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
});

export default connect(mapStateToProps)(withRouter(SpecificGroup));
