// -- TODO: INITIAL LOADING AND LOADING BOTH ARE DOING THE SAME WORK; FIX IT
import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { edit } from "actions/admin/profile";
import Errors from "views/Admin/Layout/Errors";
import Spinner from "views/Spinner";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import ChangePassword from "./ChangePassword";

import { REMOVE_ERRORS } from "actions/types";

const EditProfile = ({
  edit,
  errorList,
  loadingProfile,
  auth: { loading, user },
}) => {
  /**************** dispatch to remove error *********************/
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: REMOVE_ERRORS });
  }, []);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  useMemo(() => {
    setFormData({
      first_name: loading ? "" : user.first_name,
      last_name: loading ? "" : user.last_name,
      email: loading ? "" : user.email,
    });
  }, [loading, user]);

  const { first_name, last_name, email } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    edit(formData);
  };

  const [modal, setModal] = useState(false);

  const resetModal = () => {
    setModal(false);
  };

  const openModal = () => setModal(true);

  return (
    <div className="animated fadeIn">
      {loadingProfile ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card>
              <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                <CardBody>
                  {" "}
                  <h4> Profile </h4>
                  <br></br>
                  <Row>
                    <Col xl="12">
                      <Col xl="12" className="text-right">
                        <Button size="sm" color="primary" onClick={openModal}>
                          <i className="fa fa-dot-circle-o"></i> Change Password
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                  <ChangePassword
                    doOpen={modal}
                    email={email}
                    onClosed={resetModal}
                  />
                  <FormGroup>
                    <Label htmlFor="first_name">
                      First Name <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="first_name"
                      name="first_name"
                      maxLength="25"
                      value={first_name}
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.first_name ? true : false}
                    />
                    <Errors current_key="first_name" key="first_name" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="last_name">Last Name</Label>
                    <Input
                      type="text"
                      id="last_name"
                      name="last_name"
                      maxLength="25"
                      value={last_name}
                      onChange={(e) => onChange(e)}
                    />
                    <Errors current_key="last_name" key="last_name" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="email">
                      Email <span>*</span>
                    </Label>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      maxLength="50"
                      value={email}
                      required
                      readOnly
                      disabled
                      onChange={(e) => onChange(e)}
                      invalid={errorList.email ? true : false}
                    />
                    <Errors current_key="email" key="email" />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                  >
                    <i className="fa fa-dot-circle-o"></i> Update
                  </Button>
                  <Link to="/admin">
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

EditProfile.propTypes = {
  edit: PropTypes.func.isRequired,
  errorList: PropTypes.array.isRequired,
  loadingProfile: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  loadingProfile: state.profile.loading,
  errorList: state.errors.errorList,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  edit,
})(EditProfile);
