import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import {
  editRecommendedTimes,
  getRecommendedTimes,
} from "actions/admin/setting";
import Spinner from "views/Spinner";
import Errors from "views/Admin/Layout/Errors";
import { REMOVE_ERRORS } from "actions/types";
import cronParser from "cron-parser";
import cronstrue from "cronstrue";
import Cron, { HEADER } from "react-cron-generator";
import timezones from "timezones-list";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import SelectInput from "components/common/form/SelectInput";
import { getTimeZone } from "utils/time";

const RecommendedTimes = ({ user }) => {
  const timezone = getTimeZone(user);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [recommended, setRecommended] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRecommendedTimes().then((res) => {
      const items = res.data.response.map((rec) => ({
        isEditing: false,
        cron: rec.cron,
        timezone: rec.timezone,
        expression: cronstrue.toString(rec.cron, { verbose: true }),
      }));
      setRecommended(items);
      setLoading(false);
    });
  }, []);

  useEffect(() => {}, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };
  const onSubmit = (e) => {
    e.preventDefault();
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="6">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <h4>DDN Recommended Times for Events</h4>
                <br></br>
                <ul>
                  {recommended.map((rec, index) => (
                    <li key={rec.cron}>
                      {rec.isEditing ? (
                        <div className="mt-4">
                          <Cron
                            options={{
                              headers: [HEADER.WEEKLY],
                            }}
                            onChange={(cron) => {
                              const item = {
                                isEditing: true,
                                cron: cron,
                                expression: cronstrue.toString(cron, {
                                  verbose: true,
                                }),
                              };

                              const recs = [...recommended];
                              recs[index] = item;
                              setRecommended([...recs]);
                            }}
                            value={rec.cron}
                            showResultText={true}
                          />
                          <div className="my-2">
                            <SelectInput
                              placeholder="Select timezone"
                              value={recommended[index].timezone}
                              options={timezones.map((tz) => ({
                                label: tz.label,
                                value: tz.tzCode,
                              }))}
                              onChange={({ value }) => {
                                const recs = [...recommended];
                                recs[index].timezone = value;
                                setRecommended([...recs]);
                              }}
                            />
                          </div>
                          <Button
                            size="sm"
                            color="info"
                            type="button"
                            onClick={() => {
                              const item = { ...recommended[index] };
                              item.isEditing = false;
                              const recs = [...recommended];
                              recs[index] = item;
                              setRecommended([...recs]);
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      ) : (
                        <div className="flex flex-row justify-between">
                          <span>{rec.expression}</span>
                          <div>
                            <Button
                              size="sm"
                              color="success"
                              type="button"
                              onClick={(e) => {
                                const item = { ...recommended[index] };
                                item.isEditing = true;
                                const recs = [...recommended];
                                recs[index] = item;
                                setRecommended([...recs]);
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              type="button"
                              onClick={(e) => {
                                setRecommended((prev) => [
                                  ...prev.filter(
                                    (item) => item.cron !== rec.cron
                                  ),
                                ]);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
                <Button
                  onClick={(e) => {
                    setRecommended((prev) => [
                      ...prev,
                      {
                        isEditing: true,
                        cron: "0 0 00 ? * * *",
                        timezone: timezone,
                        expression: cronstrue.toString("0 0 00 ? * * *", {
                          verbose: true,
                        }),
                      },
                    ]);
                  }}
                >
                  Add New
                </Button>
              </CardBody>
              <CardFooter>
                <Button
                  type="submit"
                  size="sm"
                  color="primary"
                  onClick={() => {
                    editRecommendedTimes(
                      recommended.map((item) => ({
                        timezone: item.timezone,
                        cron: item.cron,
                      }))
                    ).then((res) => {
                      window.alert("Updated!");
                    });
                  }}
                >
                  <i className="fa fa-dot-circle-o"></i> Update
                </Button>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.auth.user });

export default connect(mapStateToProps, {})(RecommendedTimes);
