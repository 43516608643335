import { SESSION_TYPE } from "constants";
import { RECURRING_TYPE_REVERSE } from "constants";
import moment from "moment";
import { getTimeZone } from "utils/time";

const typeOfSession = SESSION_TYPE.GENERIC;
const SINGLE_SESSION_DURATION = 15;

export default function prepareOneOnOneEventData(
  {
    title,
    description,
    idealFor,
    image,

    category,
    interaction, //
    durations,
    events,
    proficiencyLevel,
  },
  extras
) {
  var currentDate = moment.tz(getTimeZone()).format("YYYY-MM-DD");
  // each key is a parameter for the "createCourse" action
  return {
    courseInformation: {
      ...extras,
      name: title,
      image: image || "",
      overview: description,
      ideal_for: idealFor,
      course_information: description,
      is_individual: false,
      is_group: false,
      category,
      category_type: "generic",
      category_id: category,
      proficiency_level: proficiencyLevel,
    },
    sessionData: getSessionFormData({
      duration: SINGLE_SESSION_DURATION,
      durations,
      events,
      currentDate,
    }),
    typeOfSession,
  };
}

const getSessionFormData = ({
  duration,
  price,
  durations,
  events,
  currentDate,
}) => {
  const topAllSessions = [];
  const sessionGroup = getGenericSessionGroup(
    { events, duration },
    topAllSessions
  );
  return {
    specific_individual: {},
    specific_group: {},
    generic: {
      duration_info: durations.map((dur) => ({
        duration_per_session: dur.value,
        price_per_session: dur.price,
      })),
      session_group: sessionGroup,
      number_of_session: topAllSessions.length,
      all_session_with_recurring: topAllSessions,
    },
    live_event: {},
  };
};

function getGenericSessionGroup({ events, duration }, topAllSessions) {
  const readyEvents = events.map((event, index) => ({
    sessions: [
      {
        start_date: moment(event.startDate)
          .set({
            seconds: 0,
          })
          .format("YYYY-MM-DD"),
        start_time: moment(event.startTime)
          .set({
            seconds: 0,
          })
          .format("kk:mm"),
        min_end_date: moment(event.startDate)
          .set({
            seconds: 0,
          })
          .format("YYYY-MM-DD"),
        max_end_date: moment(event.endDate)
          .set({
            seconds: 0,
          })
          .format("YYYY-MM-DD"), // same as min
        max_end_time: "",
        min_end_time: "",
        end_date: moment(event.endDate)
          .set({
            seconds: 0,
          })
          .format("YYYY-MM-DD"), // ?
        end_time: moment(event.endTime)
          .set({
            seconds: 0,
          })
          .format("kk:mm"),
      },
    ],
    recurring_type: event.isRecurring
      ? event.recurringInterval
      : RECURRING_TYPE_REVERSE.NONE,
    recurring_end_date: event.recurringEndDate.toISOString(),
    recurring_min_date: event.startDate.toISOString(), // ?
    recurring_type_min: event.isRecurring
      ? event.recurringInterval
      : RECURRING_TYPE_REVERSE.NONE, // ?
    is_new: true,
    all_session_with_recurring: event.isRecurring
      ? // get all the session till the end date using the recur type
        getRecurringSessions(
          {
            date: event.startDate,
            endDate: event.endDate,
            recurType: event.recurringInterval,
            recurringEndDate: event.recurringEndDate,
            time: event.startTime,
            endTime: event.endTime,
            duration,
            bundleIndex: index,
          },
          topAllSessions
        )
      : getNonRecurringSingleSessions(
          {
            date: event.startDate,
            endDate: event.endDate,
            time: event.startTime,
            endTime: event.endTime,
            duration,
            bundleIndex: index,
          },
          topAllSessions
        ),
  }));

  readyEvents.map((item, idx) => {
    let new_without_chunks = [...item.all_session_with_recurring];
    let sessionChunks = getSessionChunks(item.all_session_with_recurring);

    readyEvents[idx].all_session_with_recurring = [...sessionChunks];

    // filtering sessions to not have the same day more than once (for without_chuks only)
    const sessionsWithoutChunks = [];
    const sessionByDateMap = {};
    new_without_chunks.forEach((sess) => {
      const sessDate = moment(sess.start_date_time).format("YYYY-MM-DD");
      if (!sessionByDateMap[sessDate]) {
        sessionByDateMap[sessDate] = true;
        sessionsWithoutChunks.push({
          ...sess,
          end_date_time: getDateTimeStr(
            moment(sess.end_date_time).format("YYYY-MM-DD") +
              " " +
              sess.end_time
          ),
        });
      }
    });

    readyEvents[idx].all_session_with_recurring_without_chuks =
      sessionsWithoutChunks;
  });

  return readyEvents;
}

const getRecurringSessions = (
  {
    date,
    endDate,
    recurringEndDate,
    recurType,
    time,
    endTime,
    duration,
    bundleIndex,
  },
  topAllSessions
) => {
  const recurDates = [];
  let currDate = moment(date).set({
    seconds: 0,
  });
  let currEndDate = moment(endDate).set({
    seconds: 0,
  });
  const recEndDate = moment(
    moment(recurringEndDate).format("YYYY-MM-DD") + " " + moment(endTime).format("HH:mm")
  ).set({
    seconds: 0,
  });

  let dateUnitMultiplier = 1;
  // first go through date -> endDate, daily
  while (currDate.isSameOrBefore(recEndDate)) {
    recurDates.push(currDate.clone());
    currDate = currDate.clone().add(1, "day");

    // if reached endDate, add recurring types (day/week/month)
    if (currDate.isAfter(currEndDate)) {
      const dateUnitToAdd =
        recurType === RECURRING_TYPE_REVERSE.DAILY
          ? "days"
          : recurType === RECURRING_TYPE_REVERSE.WEEKLY
          ? "week"
          : recurType === RECURRING_TYPE_REVERSE.MONTHLY
          ? "month"
          : "";

      currDate = moment(date)
        .set({
          seconds: 0,
        })
        .add(dateUnitMultiplier, dateUnitToAdd);
      currEndDate = moment(endDate)
        .set({
          seconds: 0,
        })
        .add(dateUnitMultiplier, dateUnitToAdd);
      if (currEndDate.isAfter(recEndDate)) {
        currEndDate = recEndDate.clone();
      }
      dateUnitMultiplier++;
    }
  }

  const sessions = recurDates.map((recDate, i) =>
    getRecurringSingleSessions(
      { date: recDate, time, duration, endTime, bundleIndex },
      topAllSessions
    )
  );

  return sessions.flat();
};

// start_time to end_time for the same session
const getRecurringSingleSessions = (
  { date, time, duration, endTime, bundleIndex },
  topAllSessions
) => {
  const recurDates = [];

  let currDateTime = moment(
    moment(date).format("YYYY-MM-DD") + " " + moment(time).format("HH:mm")
  );
  const endDateTime = moment(
    moment(date).format("YYYY-MM-DD") + " " + moment(endTime).format("HH:mm")
  );

  while (currDateTime.isBefore(endDateTime)) {
    recurDates.push(currDateTime.clone());
    currDateTime = currDateTime.add(duration, "minutes");
  }

  const sessions = recurDates.map((recDate, i) =>
    getSingleSession(
      { date: recDate, start_time: time, end_time: endTime, duration, bundleIndex },
      topAllSessions
    )
  );

  return sessions;
};

const getNonRecurringSingleSessions = (
  { date, endDate, time, duration, endTime, bundleIndex },
  topAllSessions
) => {
  const recurDates = [];
  let addDayMultiplier = 1;
  let currDateTime = moment(
    moment(date).format("YYYY-MM-DD") + " " + moment(time).format("HH:mm")
  );
  let currDayEndDateTime = moment(
    moment(date).format("YYYY-MM-DD") + " " + moment(endTime).format("HH:mm")
  );
  const endDateTime = moment(
    moment(endDate).format("YYYY-MM-DD") + " " + moment(endTime).format("HH:mm")
  );

  while (currDateTime.isSameOrBefore(endDateTime)) {
    recurDates.push(currDateTime.clone());
    currDateTime = currDateTime.add(duration, "minutes");

    if (currDateTime.isSameOrAfter(currDayEndDateTime)) {
      currDateTime = moment(
        moment(date).format("YYYY-MM-DD") + " " + moment(time).format("HH:mm")
      ).add(addDayMultiplier, "day");
      currDayEndDateTime = moment(
        moment(date).format("YYYY-MM-DD") +
          " " +
          moment(endTime).format("HH:mm")
      ).add(addDayMultiplier, "day");
      addDayMultiplier++;
    }
  }

  const sessions = recurDates.map((recDateTime, i) =>
    getSingleSession(
      {
        date: recDateTime,
        time: recDateTime,
        start_time: time,
        end_time: endTime,
        duration,
        bundleIndex,
      },
      topAllSessions
    )
  );

  return sessions;
};

const getSingleSession = (
  { date, time, start_time, end_time, duration, bundleIndex = 0 },
  topAllSessions
) => {
  const session = {
    start_at: new Date(date).toISOString(),
    start_time: moment(start_time).format("kk:mm"),
    end_time: moment(end_time).format("kk:mm"),
    end_at: moment(date).add(duration, "minutes").toISOString(),
    start_date_time: getDateTimeStr(date, time),
    end_date_time: getDateTimeStr(
      moment(getDateTimeStr(date, time)).add(duration, "minutes")
    ), // .toISOString()
    bundleIndex: bundleIndex,
    index: bundleIndex,
    type: 1, // 2 for one-on-one
  };

  topAllSessions.push(session);
  return session;
};

const getDateTimeStr = (date, time) =>
  `${moment(date).format("YYYY-MM-DD")} ${moment(time || date).format(
    "kk:mm"
  )}`;

const getSessionChunks = (new_all_session_with_recurring) => {
  let sessionChunks = [];
  new_all_session_with_recurring.forEach((singleSession) => {
    let selected_start_date = moment(
      moment(singleSession.start_at).format("YYYY-MM-DD HH:mm")
    );
    let selected_end_date = moment(
      moment(singleSession.start_at)
        .clone()
        .add(SINGLE_SESSION_DURATION, "minutes")
        .format("YYYY-MM-DD HH:mm")
    );
    let end_date = moment(
      moment(singleSession.end_at).format("YYYY-MM-DD HH:mm")
    );

    while (selected_end_date.unix() <= end_date.unix()) {
      // if (selected_start_date != currentSession.start_at)
      sessionChunks.push({
        ...singleSession,
        type: singleSession.type,
        start_date_time: moment(selected_start_date).format("YYYY-MM-DD HH:mm"),
        end_date_time: moment(selected_end_date).format("YYYY-MM-DD HH:mm"),
        start_at: selected_start_date.toISOString(),
        end_at: selected_end_date.toISOString(),
      });
      selected_start_date = moment(
        selected_start_date
          .clone()
          .add(SINGLE_SESSION_DURATION, "minutes")
          .format("YYYY-MM-DD HH:mm")
      );
      selected_end_date = moment(
        selected_end_date
          .clone()
          .add(SINGLE_SESSION_DURATION, "minutes")
          .format("YYYY-MM-DD HH:mm")
      );
    }
  });

  return sessionChunks;
};
