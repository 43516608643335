import axios from "utils/axios";
import {
  FETCH_CATEGORIES_LOADING, FETCH_CATEGORIES_COMPLETED
} from "actions/types";


export const getCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_CATEGORIES_LOADING,
      payload: {is_loading: true, is_sub_element: false},
    });

    const res = await axios.get("/api/front/category");
    if (res.status) {
      dispatch({
        type: FETCH_CATEGORIES_COMPLETED,
        payload: res.data.response
      })
    }
    return { status: true, data: res.data };
  } catch (error) {
    return { status: false };
  }
};

export const getSubCategories = () => async (dispatch) => {
  const res = await axios.get("/api/front/category/subCategory");
  return res.data;
};