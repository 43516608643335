import React, { useState, useMemo, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Pagination from "views/Front/Pagination";
import { RECORD_PER_PAGE, COURSE_VIEW } from "constants/index";
import { getAllCourses } from "actions/front/common/course";
import { changeStatus, deleteCourse } from "actions/front/educator/courses";
import Courses from "views/Front/ProfileCourseMgmt/CourseManagement/Courses";

const MyListedCourse = ({
  getAllCourses,
  user_id,
  RESULTS,
}) => {
  const [coursesList, setCoursesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchText, setSearchText] = useState("");
  const activeResults = RESULTS.MY_LISTED_COURSES;

  const [filters, setFilters] = useState({
    limit: RECORD_PER_PAGE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    custom_filters: "",
  });

  const onUpdate = () => {
    setLoading(true);
    getAllCourses(filters, user_id, "", activeResults, 1).then((res) => {
      if (res.status) {
        setCoursesList(res.data.data);
        setTotalRecords(res.data.metadata[0].totalRecord);
      }
      setLoading(false);
    });
  }

  const paginate = (page_no) => {
    setFilters({ ...filters, page: page_no });
  };

  const onClickSearchText = (e) => {
    e.preventDefault();
    setFilters({ ...filters, query: searchText });
  };

  useEffect(() => {
    setLoading(true);
    getAllCourses(filters, user_id, "", activeResults, 1).then((res) => {
      if (res.status) {
        setCoursesList(res.data.data);
        setTotalRecords(res.data.metadata[0].totalRecord);
      }
      setLoading(false);
    });
  }, [filters, activeResults]);


  const [durationPrice, setDurationPrice] = useState([]);
  // const [minPriceCalculationLoading, setMinPriceCalculationLoading] = useState(false);
  var minPriceCalculationLoading = false;
  useMemo(async () => {
    minPriceCalculationLoading = true;
    let tempDurationPrice = [...durationPrice];
    await coursesList.map(async (course) => {
      await course.session.map(async (singleSession) => {
        await singleSession.duration_info.map((singleDuration) => {
          if (
            (tempDurationPrice[course.course_id] &&
              tempDurationPrice[course.course_id] >
                singleDuration.price_per_session) ||
            tempDurationPrice[course.course_id] == undefined
          ) {
            tempDurationPrice[course.course_id] = singleDuration.price_per_session;
          }
        });
      });
    });
    setDurationPrice(tempDurationPrice);
    minPriceCalculationLoading = false;
  }, [coursesList]);
  
  return (
    <div
      className="tab-pane fade show active"
      id="listed"
      role="tabpanel"
      aria-labelledby="listed-tab"
    >
      <div className="row">
        <div className="col-md-4 class-for-padding">
          <form
            className="form-horizontal"
            onSubmit={(e) => onClickSearchText(e)}
          >
            <div className="input-group">
              <input
                type="text"
                placeholder="Search Sessions..."
                className="form-control"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-secondary"
                  type="submit"
                  onClick={(e) => onClickSearchText(e)}
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* <div className="col-md-8 mb-2">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <input type="date" className="form-control" />
            </div>
          </div>
          <div className="col-md-4">
            <a href="#" className="mr-2">
              Clear All
            </a>
            <input
              type="submit"
              className="btn btn-primary mb-0"
              defaultValue="Apply"
            />
          </div>
        </div>
      </div>
     */}
      </div>
      <Courses activeResult={activeResults} page={RESULTS} courseList={coursesList} courseView={COURSE_VIEW.EDIT} loading={loading} minPriceCalculationLoading={minPriceCalculationLoading} durationPrice={durationPrice} onUpdate={onUpdate}/>
      {totalRecords > 0 ?
      <div className="row">
        <div className="col-md-12 mt-5">
          <Pagination
            totalRecords={totalRecords}
            recordPerPage={RECORD_PER_PAGE}
            paginate={paginate}
            currentPage={filters.page}
          />
        </div>
      </div> : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_id: state.auth.user._id,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getAllCourses,
  changeStatus,
  deleteCourse,
})(withRouter(MyListedCourse));
