import { ErrorMessage, Field } from "formik";
import { forwardRef } from "react";
import InputError from "./InputError";

const TextInput = forwardRef(
  (
    {
      name,
      noFormik, // this might be temporary, added it cuz there are forms that use 'name' without formik
      onChange,
      onBlur,
      hideError,
      disabled,
      className = "",
      multiline = false,
      ...props
    },
    ref
  ) => {
    if (name && !noFormik) {
      return (
        <Field name={name}>
          {({ field, meta, form }) => (
            <div className={className}>
              <Input
                {...props}
                {...field}
                onChange={(e) => {
                  field?.onChange(e);
                  onChange?.(e);
                }}
                onBlur={(e) => {
                  field?.onBlur(e);
                  onBlur?.(e);
                }}
                error={meta.error}
                touched={meta.touched}
                disabled={form.isSubmitting || disabled}
                ref={ref}
                multiline={multiline}
              />
              {!hideError && <InputError name={name} />}
            </div>
          )}
        </Field>
      );
    }
    return (
      <Input
        {...props}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        className={className}
        name={name}
      />
    );
  }
);

export default TextInput;

const Input = forwardRef(
  (
    {
      wFull,
      placeholder,
      onChange,
      label,
      className,
      error,
      touched,
      disabled,
      multiline = false,

      ...rest
    },
    ref
  ) => {
    return (
      <div className={className}>
        {label && (
          <label
            htmlFor={label}
            className="text-sm text-dgray-700 font-medium inline-block mb-1.5 w-full"
          >
            {label}
          </label>
        )}
        {multiline ? (
          <textarea
            {...rest}
            id={label}
            className={`leading-snug text-dgray-500 focus:outline-none border border-dgray-300 rounded-lg w-full py-2.5 px-3.5 
          ${disabled ? "opacity-80" : ""}
          ${className}
          `}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            ref={ref}
          />
        ) : (
          <input
            {...rest}
            id={label}
            className={`leading-none text-dgray-500 focus:outline-none border border-dgray-300 rounded-lg w-full py-2.5 px-3.5 
          ${disabled ? "opacity-80" : ""}
          ${className}
          `}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            ref={ref}
          />
        )}
      </div>
    );
  }
);
