import { ReactComponent as PlusIcon } from "assets/images/icons/plus.svg";
import { ReactComponent as MinusIcon } from "assets/images/icons/minus.svg";
import SelectInput from "components/common/form/SelectInput";
import TextInput from "components/common/form/TextInput";
import { CreateFormRow } from "components/create-form/CreateFormRow";
import { SESSION_DURATION } from "constants";
import { FieldArray, useFormikContext } from "formik";
import CreateStepControls from "../../../StepControls";
import OneOnOneNewEventRows from "./EventRows";

export default function CreateEventOneOnOneStep2({ disabled, ...props }) {
  const { values } = useFormikContext();

  return (
    <>
    <div className="border-b border-dgray-200 mb-5 pb-5">
      <h2 className="text-lg font-medium">
        Manage Availability 
      </h2>
      {disabled && <span className="text-primary-600">Since students already attended to your sessions, you can not change date and time.</span>}
      </div>
      <CreateFormRow
        title="Event Interaction"
        right={
          <FieldArray
            name="durations"
            render={(arrayHelpers) =>
              values.durations.map((duration, i) => (
                <>
                  <div className="flex gap-4 items-center mb-4">
                    <SelectInput
                      disabled={disabled}
                      className="flex-1"
                      label="Event Duration"
                      name={`durations.${i}.value`}
                      options={Object.entries(SESSION_DURATION).filter(d => values.durations.map(i => i.value).includes(d[0]) === false).map(
                        ([value, label]) => ({
                          label,
                          value: +value,
                        })
                      )}
                    />
                    <TextInput
                      type="number"
                      className="flex-1"
                      name={`durations.${i}.price`}
                      label="Price $"
                      disabled={disabled}
                    />
                  </div>
                  {i === values.durations.length - 1 && (
                    <div className="flex gap-1 justify-between">
                      <button
                        type="button"
                        className="flex items-center gap-2 text-primary-600 disabled:opacity-70"
                        disabled={disabled}
                        onClick={() => arrayHelpers.push(initialNewDuration)}
                      >
                        <PlusIcon /> Add duration
                      </button>
                      {i > 0 && (
                        <button
                          type="button"
                          className="flex items-center gap-2 text-primary-600 disabled:opacity-70"
                          onClick={() => arrayHelpers.pop()}
                          disabled={disabled}

                        >
                          <MinusIcon />
                        </button>
                      )}
                    </div>
                  )}
                </>
              ))
            }
          />
        }
      />

      <OneOnOneNewEventRows disabled={disabled} />
      <CreateStepControls {...props} />
    </>
  );
}

export const initialNewDuration = {
  value: "",
  price: 0,
};
