import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { create } from "actions/admin/coursecategory";
// import Errors from "views/Notifications/Errors";
import Spinner from "views/Spinner";
import Errors from "views/Admin/Layout/Errors";

import { REMOVE_ERRORS } from "actions/types";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const CreateCourseCategory = ({ create, history, errorList, loading }) => {
  /**************** dispatch to remove error *********************/
  const dispatch = useDispatch();

  /********************Form data*****************/
  const [formData, setFormData] = useState({
    category_type: "specific",
    category_name: "",
    category_description: "",
    category_image: ""
  });
  
  const { category_type, category_name, category_description, category_image } = formData;

  const onChange = e => {
    if (e.target.name === "category_image") {
      let image_file = e.target.files[0];
      let image_url = URL.createObjectURL(image_file);

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        file: image_file,
        image: image_url
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    dispatch({ type: REMOVE_ERRORS });
  };

  /********************On Submit event*****************/
  const onSubmit = (e) => {
    e.preventDefault();
    create(formData, history);
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card>
              <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="category_name">
                      Name<span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="category_name"
                      name="category_name"
                      maxLength="25"
                      value={category_name}
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.category_name ? true : false}
                    />
                    <Errors current_key="category_name" key="category_name" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="category_description">Description</Label>
                    <Input
                      type="textarea"
                      id="category_description"
                      name="category_description"
                      value={category_description}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="image">Image</Label>
                    <Input
                      type="file"
                      id="category_image"
                      name="category_image"
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>

                </CardBody>
                <CardFooter>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/course-program">
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

CreateCourseCategory.propTypes = {
  create: PropTypes.func.isRequired,
  coursecategory: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  coursecategory: state.coursecategory,
  loading: state.coursecategory.loading,
  errorList: state.errors.errorList,
});

export default connect(mapStateToProps, { create })(CreateCourseCategory);
