import React from "react";
import Spinner from "views/Spinner";
import NoRecord from "views/NoRecord";
import Course from "views/Front/Course";

const Courses = ({
  courseList,
  loading,
  courseView,
  activeResult,
  page,
  minPriceCalculationLoading,
  durationPrice,
  modal,
  onUpdate
}) => {

  return (
    <div>
      {loading ? (<Spinner />) : <>
        {courseList || minPriceCalculationLoading && courseList.length > 0 ?
          (
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  {
                    courseList.map((course, index) =>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4 mt-2 course-wrapper" key={index}>
                      <Course course={course} loading={loading} page={page} courseView={courseView} activeResult={activeResult} durationPrice={durationPrice} modal={modal} onUpdate={onUpdate} />
                    </div>
                    )
                  }
                </div>
              </div>
            </div>
          )
          : <NoRecord />}
      </>}
    </div>
  )
};

export default (Courses);
