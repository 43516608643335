import axios from "utils/axios";
import { BLOG_CREATED, BLOG_CREATED_ERROR, BLOG_LOADING } from "actions/types";

export const createBlog = async (
  formData,
  history,
  status,
  redirect = true
) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
  };

  try {
    let param = new window.FormData();
    param.append("file", formData.selectedFile);
    param.append("title", formData.title);
    param.append("content", formData.content);
    param.append("category", formData.category);

    //    param.append("header_image", formData.header_image);
    param.append("meta_description", formData.meta_description);
    param.append("meta_keywords", formData.meta_keywords);
    param.append("status", status);

    const res = await axios.post("/api/front/blogs/create-blog", param, config);
    if (res.data.status === true) {
      if (redirect) {
        history.push("/dashboard/blogs");
      }
      return { status: true };
    } else {
      const errors = res.data.errors ? res.data.errors : [];
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      return {
        status: false,
        isErrorInBasic: res.data.errors.isErrorInBasic,
        msg: res.data.message,
      };
    }
  } catch (err) {
    console.log(err);
  }
};

export const getAllBlogs =
  (blogParams, user_id = "") =>
  async (dispatch) => {
    try {
      const custom_filters = blogParams.custom_filters
        ? blogParams.custom_filters
        : "";

      const res = await axios.get(
        `/api/front/blogs/getallblogs?limit=${blogParams.limit}&page=${blogParams.page}&orderBy=${blogParams.orderBy}&ascending=${blogParams.ascending}&query=${blogParams.query}${custom_filters}&userId=${user_id}`
      );
      return { status: true, data: res.data.response };
    } catch (error) {
      return { status: false };
    }
  };

export const getAllUserBlogs =
  (blogParams, user_id = "", isAll = "") =>
  async (dispatch) => {
    try {
      const custom_filters = blogParams.custom_filters
        ? blogParams.custom_filters
        : "";

      const res = await axios.get(
        `/api/front/blogs/getalluserblogs?limit=${blogParams.limit}&page=${blogParams.page}&orderBy=${blogParams.orderBy}&ascending=${blogParams.ascending}&query=${blogParams.query}${custom_filters}&userId=${user_id}&isAll=${isAll}`
      );
      return { status: true, data: res.data.response };
    } catch (error) {
      return { status: false };
    }
  };

export const getAllMyBlogs =
  (blogParams, isAll = "") =>
  async (dispatch) => {
    try {
      const custom_filters = blogParams.custom_filters
        ? blogParams.custom_filters
        : "";

      const res = await axios.get(
        `/api/front/blogs/getallmyblogs?limit=${blogParams.limit}&page=${blogParams.page}&orderBy=${blogParams.orderBy}&ascending=${blogParams.ascending}&query=${blogParams.query}${custom_filters}&isAll=${isAll}`
      );
      return { status: true, data: res.data.response };
    } catch (error) {
      return { status: false };
    }
  };

export const uploadFileContent = async (file, token, userId) => {
  try {
    const data = new window.FormData();

    data.append("token", token);
    data.append("userId", userId);
    data.append("file", file);

    const response = await axios.post("/api/front/blogs/upload-file", data);
    return response.data.response;
  } catch (ex) {}
};

export const editBlog = async (
  formData,
  history,
  blog_id,
  status = 1,
  redirect = true
) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
  };

  try {
    let param = new window.FormData();
    if (formData.selectedFile) param.append("file", formData.selectedFile);
    param.append("title", formData.title);
    param.append("content", formData.content);
    param.append("category", formData.category);
    param.append("header_image", formData.header_image);
    param.append("meta_description", formData.meta_description);
    param.append("meta_keywords", formData.meta_keywords);
    param.append("status", status);

    // console.log("get ", param.get("file"));
    const res = await axios.post(`/api/front/blogs/${blog_id}`, param, config);
    if (res.data.status === true) {
      if (redirect) {
        history.push("/dashboard/blogs");
      }
      return { status: true };
    } else {
      const errors = res.data.errors ? res.data.errors : [];
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }

      return {
        status: false,
        isErrorInBasic: res.data.errors.isErrorInBasic,
        msg: res.data.message,
      };
    }
  } catch (err) {
    console.log(err);
  }
};

export const changeStatus = (blog_id, status) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/front/blogs/change-status/${blog_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      return { status: true, value: status, msg: res.data.message };
    } else {
      return { status: false, msg: res.data.message };
    }
  } catch (err) {
    return {
      status: false,
      msg: "Unable to change the status, please try again later.",
    };
  }
};

export const deleteBlog = (blog_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.delete(`/api/front/blogs/${blog_id}`, config);
    if (res.data.status === true) {
      return { status: true };
    } else {
      return { status: false, msg: res.data.message };
    }
  } catch (err) {
    return {
      status: false,
      msg: "Unable to delete the blog, please try again later.",
    };
  }
};

export const getBlogById = async (blog_id) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/front/blogs/${blog_id}`, config);
    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      return { status: false };
    }
  } catch (err) {
    return {
      status: false,
    };
  }
};

export const getBlog = async (slug) => {
  try {
    const response = await axios.get(`/api/front/blogs/blog-detail/${slug}`);
    if (response.data.status) {
      return response.data.response;
    } else {
    }
  } catch (err) {
    return {
      status: false,
    };
  }
};
