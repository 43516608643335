import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { getMycourseSessions } from "actions/front/learner/courses";
import { DURATION_LIST, COURSE_VIEW } from "constants/index";
import { Spinner } from "reactstrap";
import NoRecord from "views/NoRecord";
import * as moment from 'moment-timezone';
import Pagination from "views/Front/Pagination";
import { RECORD_PER_PAGE } from "constants/index";
import { getCurrentTimeZone } from "utils/time";
import Courses from "views/Front/ProfileCourseMgmt/CourseManagement/Courses";

const CompletedSession = ({ COURSE_LIST, getMycourseSessions, user }) => {
  let timeZone = getCurrentTimeZone(user);

  const [loader, setLoader] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [duration, setDuration] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const activeResults = COURSE_LIST.COMPLETED;
  useMemo(() => {
    setLoader(true);
    getMycourseSessions(COURSE_LIST.COMPLETED, page, duration).then((res) => {
      if (res.status && res.data.data.length > 0) {
        let tempData = [];
        res.data.data.map((singleSession) => {
          let start_at = moment.unix(singleSession.start_at).tz(timeZone);
          let end_at = moment.unix(singleSession.end_at).tz(timeZone);
          tempData.push({
            ...singleSession,
            start_at: start_at,
            end_at: end_at,
          });
        });
        setSessions(tempData);
      }
      setLoader(false);
      setTotalRecords(
        res.data.metadata[0] ? res.data.metadata[0].totalRecord : 0
      );
    });
  }, [duration, page]);

  const paginate = (page_no) => {
    setPage(page_no);
  };

  return (
    <div className="tab-pane fade show active" id="attended">
      <div className="row">
        <div className="col-md-4">
          <div className="form-group selectGroup">
            <select
              className="form-control custom-select"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            >
              {Object.keys(DURATION_LIST).map((idx) => (
                <option value={idx}>{DURATION_LIST[idx]}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {loader ? (
        <Spinner />
      ) : sessions.length > 0 ? (
        <>
        <Courses activeResult={activeResults} page={COURSE_LIST} courseList={sessions} courseView={COURSE_VIEW.REVIEW_COURSE} loading={loader} />
          <div className="row">
            <div className="col-md-12">
              <Pagination
                totalRecords={totalRecords}
                recordPerPage={RECORD_PER_PAGE}
                paginate={paginate}
                currentPage={page}
              />
            </div>
          </div>
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { getMycourseSessions })(
  CompletedSession
);
