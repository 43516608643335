import { deleteDraftCourse } from "actions/front/educator/courses";
import { getEventDetail } from "actions/front/mentorDashboard";
import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow-right.svg";
import { ReactComponent as GlobeIcon } from "assets/images/icons/globe.svg";
import { ReactComponent as TrashIcon } from "assets/images/icons/trash.svg";
import Attendees from "components/common/Attendees";
import Modal from "components/common/Modal";
import NestedPill from "components/common/NestedPill";
import Pill from "components/common/Pill";
import { SESSION_TYPE } from "constants";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getClosestSession } from "utils/course";
import { formatDateShort } from "utils/formatDate";
import getEventImage from "utils/getEventImage";
import getUserAvatar from "utils/getUserAvatar";
import { ReactComponent as EditLineIcon } from "assets/images/icons/edit-line.svg";
import AttendeeListModal from "./AttendeeListModal";
import { CATEGORIES } from "constants";

function EventCard({
  data,
  full,
  pillText,
  isDraft,
  deleteDraftCourse,
  user,
  canViewAttendeeList,
}) {
  const [closestSession, setClosestSession] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isAttendeesModalOpen, setIsAttendeesModalOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setClosestSession(getClosestSession(data));
  }, []);

  return (
    <>
      {isAttendeesModalOpen && (
        <AttendeeListModal
          isOpen={isAttendeesModalOpen}
          setIsOpen={setIsAttendeesModalOpen}
          eventId={data.course_id}
          eventType={data.course_type}
          all={true}
        />
      )}
      {isDraft && (
        <Modal
          isOpen={isModalOpen}
          modalContent={{
            title: "Are you sure you wanna delete this event?",
          }}
          setIsOpen={setModalOpen}
          confirmCancel={() => {
            deleteDraftCourse(data._id);
            setModalOpen(false);
            history.push("/dashboard");
          }}
        />
      )}
      <div
        className={`text-left rounded-2xl shadow-lg p-4 flex-1 flex flex-col justify-between lg:mx-auto bg-white
        ${full ? "" : "max-w-sm"}
        ${pillText ? "relative" : ""}
      `}
      >
        <div>
          {pillText && (
            <Pill
              className="absolute top-8 left-8"
              text={pillText}
              color="primary"
            />
          )}
          <img
            src={getEventImage(data)}
            alt=""
            className="h-56 object-cover mb-4 w-full rounded-lg"
          />
          <div className="flex justify-between items-center mb-4">
            <Pill
              text={data.category_name}
              color={CATEGORIES[data.category_name]?.color}
            />
            <NestedPill
              innerText={
                data.course_type === SESSION_TYPE.LIVE_EVENT
                  ? "Public event"
                  : "Private event"
              }
              // outerText={`${data?.speakerCount} Speaker Seats`}
            />
          </div>
          <div className="text-xl md:text-lg font-semibold mb-2">
            {data.course_name || data.name}
          </div>

          <div className="flex justify-between mb-4">
            <Link
              to={`/hosts/${data.mentor_username}`}
              className="flex gap-2.5"
            >
              <img
                src={getUserAvatar(data.mentor_avatar)}
                alt=""
                className="rounded-full w-10 h-10"
              />
              <div>
                <div className="text-sm font-medium">{data.mentor_name}</div>
                <div className="text-xs text-dgray-500">
                  <GlobeIcon className="inline mr-1" />
                  {data.host ? data.host?.country : data.country}
                </div>
              </div>
            </Link>
            {canViewAttendeeList ? (
              <button onClick={() => setIsAttendeesModalOpen(true)}>
                <Attendees attendees={data.attendees} />
              </button>
            ) : (
              <>
                {data.couse_type === SESSION_TYPE.LIVE_EVENT && (
                  <div>
                    <Attendees
                      attendees={data.attendees}
                      count={data.attendees ? data.attendees.length : 0}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div
            className="text-dgray-500 line-clamp-3 whitespace-pre-line min-h-[72px]"
            dangerouslySetInnerHTML={{ __html: data.course_overview }}
          ></div>
        </div>
        <div>
          <div className="text-sm border-t pt-4 mt-4">
            <div className="flex justify-between gap-2 whitespace-nowrap">
              <div className="flex gap-2">
                {closestSession && (
                  <div className="font-semibold">
                    {formatDateShort(moment.unix(closestSession?.start_at))}
                  </div>
                )}
                {!isDraft &&
                  canViewAttendeeList &&
                  user?.id &&
                  data?.mentor_id &&
                  user?.id === data?.mentor_id && (
                    <>
                      <Link
                        to={`/create/${
                          data?.course_type === SESSION_TYPE.GENERIC
                            ? "one-on-one"
                            : "event"
                        }?editId=${data.course_id}`}
                        className="text-primary-700 font-medium"
                      >
                        <EditLineIcon />
                      </Link>
                    </>
                  )}
              </div>
              {isDraft ? (
                <>
                  <Link
                    to={`/create/${
                      data?.course_type === SESSION_TYPE.GENERIC
                        ? "one-on-one"
                        : "event"
                    }?draftId=${data._id}`}
                    className="text-primary-700 font-medium"
                  >
                    Edit Draft Event <ArrowIcon className="inline" />
                  </Link>
                  <button
                    onClick={() => setModalOpen(true)}
                    className="text-red-500"
                  >
                    <TrashIcon />
                  </button>
                </>
              ) : (
                <Link
                  to={`/session/${data.course_id}`}
                  className="text-primary-700 font-medium"
                >
                  See Event Details <ArrowIcon className="inline" />
                </Link>
              )}
            </div>
            <div className="text-dgray-500">{data.timezone}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  { getEventDetail, deleteDraftCourse }
)(EventCard);
