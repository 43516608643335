import { useState } from "react";
import ReactDatePicker from "react-datepicker";

export default function TimePicker({
  onChange,
  label,
  className = "",
  selected,
  disabled,
}) {
  const [date, setDate] = useState(new Date());
  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={label}
          className="text-sm text-dgray-700 font-medium inline-block mb-1.5 w-full"
        >
          {label}
        </label>
      )}
      <ReactDatePicker
        className="border border-dgray-300 disabled:bg-dgray-25 disabled:text-dgray-500 rounded-lg py-2.5 px-3.5"
        selected={selected || date}
        disabled={disabled}
        onChange={(date) => {
          setDate(date);
          onChange?.(date);
        }}
        showPopperArrow={false}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
      />
    </div>
  );
}
