import CenterContainer from "components/common/Container/Center";
import TextLink from "components/common/TextLink";
import JoinCTA from "components/common/JoinCTA";
import FooterLinksLayout from "../Layout/FooterLinksLayout";

export default function TermsOfService() {
  return (
    <FooterLinksLayout
      title="Terms of Service"
      subtitle="Last updated at August, 2022"
    >
      <div className="py-24 lg:py-16">
        {items.map((item, i) => (
          <div className="space-y-6" key={i}>
            <CenterContainer>
              <h2
                className={`text-3xl md:text-2xl font-semibold
                ${i > 0 ? "mt-12" : ""}
              `}
              >
                {item.articleTitle}
              </h2>
            </CenterContainer>
            {item.items.map(({ title, content, highlight }, j) => (
              <div
                className={`text-lg md:text-base ${
                  highlight ? "!my-12 md:!my-8 bg-dgray-100 py-16 md:py-6" : ""
                }`}
                key={j}
              >
                <CenterContainer>
                  <div
                    className={`space-y-4
                      ${highlight ? "text-dgray-900" : "text-dgray-500"}
                    `}
                  >
                    {title && (
                      <h3 className="font-semibold text-dgray-900">{title}</h3>
                    )}
                    {content}
                  </div>
                </CenterContainer>
              </div>
            ))}
          </div>
        ))}
      </div>
      <JoinCTA />
    </FooterLinksLayout>
  );
}

function TextHighlight({ children }) {
  return <span className="text-primary-700">{children}</span>;
}

function DDNLink() {
  return <TextLink to="/">DingDingNow</TextLink>;
}

const items = [
  {
    articleTitle: "Article 1. Parties; Definitions",
    items: [
      {
        title: "1.1",
        content: (
          <>
            <p>
              Welcome to <TextLink to="/">DingDingNow.com</TextLink> (the
              “Platform”), which is provided by DingDongNow LLC, a Maryland
              limited liability company based in the United States (referred to
              herein as “DingDingNow”, “we”, “us” or “our”). Throughout these
              Terms, any Learner, Host, or any other person accessing the
              Platform or using our Services is referred to as "User", "you" or
              "your". If you are accessing or using the Platform or our Services
              on behalf of a business or entity, then:
            </p>
            <p>
              i. throughout these Terms, “User”, “you” or “your” shall include
              both you and the business or entity you are associated with;
            </p>
            <p>
              ii. you represent and warrant that you are an authorized
              representative of the business or entity with the authority to
              bind the business or entity to these Terms;
            </p>
            <p>
              iii. you agree to these Terms individually and on behalf of the
              business or entity; and
            </p>
            <p>
              iv. such business or entity is legally and financially responsible
              for your access or use of the Platform and our Services, as well
              as for the access or use of your account by others affiliated with
              such business or entity, including any employees, agents or
              contractors.
            </p>
          </>
        ),
      },
      {
        title: "1.2",
        content: (
          <p>
            Please carefully read the following Terms of Service (the “Terms”)
            and our Privacy Policy. These Terms govern your access to and use of
            the Platform and Services (as set forth in Article 2) and constitute
            a binding legal agreement between you and{" "}
            <TextLink to="/">DingDingNow.</TextLink> Any use or access of the
            Platform or our Services by anyone under the age of 18 is
            prohibited. By using and/or accessing the Platform or Services, you
            represent that you are at least 18 years old and above.
          </p>
        ),
      },
      {
        title: "1.3",
        content: (
          <>
            <p>
              The following capitalized terms shall have the meanings specified
              in this Article 1.3. Other terms are defined in the text of these
              Terms and shall have the meanings respectively ascribed to them.
            </p>
            <p>
              i. <TextHighlight>“Event(s)”</TextHighlight> means any skill,
              knowhow, knowledge, information or content that is offered,
              provided or transmitted by a Host in the manner of online video
              Event(s) via the Platform. Event(s) may consist of one or multiple
              Interaction(s).
            </p>
            <p>
              ii. “Learner” means any User who seeks, schedules, or receives
              Event(s) from a Host via the Platform, including individual
              Learners and group Learners.
            </p>
            <p>
              iii. “Host” means any User who creates their profile on the
              Platform and utilizes our Services to offer Events(s) via the
              Platform.
            </p>
            <p>
              iv. “Publisher” means a third party providing a Third Party
              Service.
            </p>
            <p className="text-primary-700">
              v. “Interaction(s)” means a period of time devoted to Session(s)
              as defined by a Mentor on the Platform, including individual
              Interaction(s), one-on-one Interaction(s), and group
              Interaction(s). ???
            </p>
          </>
        ),
      },
      {
        title: "1.4 ARBITRATION NOTICE FOR USERS IN THE UNITED STATES ",
        highlight: true,
        content: (
          <p>
            THESE TERMS CONTAIN AN ARBITRATION CLAUSE AND A WAIVER OF RIGHTS TO
            BRING A CLASS ACTION AGAINST US. EXCEPT AS EXPRESSLY PROVIDED
            OTHERWISE HEREIN, YOU AND <TextHighlight>DINGDINGNOW</TextHighlight>{" "}
            AGREE THAT ALL DISPUTES BETWEEN THE PARTIES ARISING FROM THESE TERMS
            OR YOUR ACCESS AND USE OF THE PLATFORM AND/OR OUR SERVICES WILL BE
            RESOLVED BY MANDATORY BINDING ARBITRATION, AND YOU AND DINGDINGNOW
            WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT OR
            CLASS-WIDE ARBITRATION.
          </p>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 2. Scope of Services",
    items: [
      {
        title: "2.1",
        content: (
          <p>
            The Platform serves as an interface to facilitate connections and
            communications between Learners, Hosts and other Users (our
            “Services”). Our Services allow Learners, Hosts and Users to
            interact via the Platform and/or other interfaces as may be made
            available by <DDNLink /> from time to time.
          </p>
        ),
      },
      {
        title: "2.2",
        content: (
          <p>
            We reserve the right, in our sole and absolute discretion, to
            change, upgrade, modify or transfer the Platform or our Services (or
            any part thereof), including the relevant functions and
            applications, at any time. You agree that we are not required to
            notify you of and will not be liable to you or any third party for
            such change, upgrade, modification or transfer.
          </p>
        ),
      },
      {
        title: "2.3",
        content: (
          <p>
            <TextHighlight>
              {" "}
              The Platform and our Services are intended to be used within the
              United States of America.{" "}
            </TextHighlight>{" "}
            <DDNLink /> makes no representations or warranties that the Platform
            or our Services are appropriate for use or access outside of the
            United States of America. Anyone using or accessing the Platform or
            our Services does so on their own initiative and agrees to comply
            with the laws of the United States (including federal, state and
            local laws) regarding online conduct and acceptable content.
          </p>
        ),
      },
      {
        title: "2.4",
        content: (
          <p>
            The Hosts are not agents, employees or representatives of{" "}
            <TextLink to="/">DingDingNow.</TextLink> You acknowledge that we
            cannot and do not control any Event(s) or Session(s) provided by
            Hosts, or the actions of any Host, Learner or other User of the
            Platform and Services. You further acknowledge that we cannot
            confirm or ensure, for any Host, the accuracy of their profile
            information, competency, expertise, skill, background, knowledge or
            proficiency in the subject matters of their Event(s) and Session(s).
          </p>
        ),
      },
      {
        title: "2.5",
        content: (
          <p>
            DingDongNow does not endorse any User. Unless we expressly state
            otherwise in writing, we are not your agent or the agent of any
            User, and neither you nor any User is our agent. You are responsible
            for determining and confirming the identity and suitability of the
            Users you interact with on the Platform. The Platform and Services
            are provided "as is," and we make no guarantees that they always
            will be safe, secure, or error-free, or that they will function
            without disruptions, delays, or imperfections. We do not control or
            direct what people and others do or say, and we are not responsible
            for their actions or conduct (whether online or offline) or any
            content they share (including offensive, inappropriate, obscene,
            unlawful, and other objectionable content) in connection with the
            Platform or Services. We cannot predict when issues might arise with
            the Platform or Services. Accordingly, by using the Platform or
            Services, you (and any third parties with access to your account)
            agree to the fullest extent permitted by applicable law, that: (i)
            any legal remedy or liability that you or such third party seek to
            obtain for actions or omissions of any Host, Learner or other User
            will be limited to a claim against the particular Host, Learner or
            other User who caused you, or any third party, harm; and (ii) you
            will not to attempt to impose liability on, or seek any legal remedy
            from <TextHighlight to="/">DingDingNow</TextHighlight> with respect
            to such actions or omissions.
          </p>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 3. Third Party Services",
    items: [
      {
        title: "3.1",
        content: (
          <p>
            The Platform and/or Services may allow you to access, use and/or
            install third party applications, services, websites or functions
            (collectively the “Third Party Services”). Such Third Party Services
            may be subject to additional terms and privacy policies as may be
            prescribed by the relevant Publisher, which you should read,
            understand and accept before using Third Party Services. <DDNLink />{" "}
            shall not be responsible for the privacy practices or policies of
            any Publishers.
          </p>
        ),
      },
      {
        title: "3.2",
        content: (
          <p>
            You understand and agree that the Third Party Services are provided
            by the relevant Publisher and not <DDNLink />. <DDNLink /> shall
            assume no liability for your use of Third Party Services.
            <DDNLink /> shall not be responsible for the Third Party Services,
            their content, functionality, availability or support. <DDNLink />
            shall not be responsible for any loss or corruption of data, lost
            communications, or any other loss or damage of any kind arising from
            any Third Party Services.
          </p>
        ),
      },
      {
        title: "3.3",
        content: (
          <p>
            You agree that your use of any Third Party Services may require
            <DDNLink /> to transfer data relating to you and/or your account to
            the relevant Publisher and you hereby consent to such transfer.
          </p>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 4. Accepting the Terms",
    items: [
      {
        title: "4.1",
        content: (
          <p>
            The Platform and Services are available to Users that are at least
            18 years old. By accessing or using the Platform or our Services,
            you certify that you are at least 18 years old and have fully read,
            understand and agree to these Terms. Your access and use of the
            Platform or our Services is also subject to our Privacy Policy and
            other policies.
          </p>
        ),
      },
      {
        title: "4.2",
        content: (
          <p>
            We reserve the right, within our sole and absolute discretion, to
            update, amend or modify these Terms from time to time; provided,
            however, that we will use commercially reasonable efforts, such as a
            notice on the Platform, to notify all Users of any material changes
            to these Terms. You are responsible for periodically checking these
            Terms for any changes. Your continued access or use of the Platform
            or Services after these Terms are updated, amended or modified
            constitutes your acceptance of any changes. If you do not agree to
            any changes to these Terms, you must stop accessing and using the
            Platform and Services.
          </p>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 5. Account Registration & Termination",
    items: [
      {
        title: "5.1",
        content: (
          <p>
            To access or use the Platform and our Services, you must create an
            account <TextLink to="/">on the Platform</TextLink>. When you create
            your account, you must provide accurate and up-to-date information.
            You are required to maintain and promptly update your details and
            any other information you provide to us, to keep your account
            current and complete.
          </p>
        ),
      },
      {
        title: "5.2",
        content: (
          <p>
            You are required to keep your account password confidential. You
            shall not disclose you account password to any third party. We are
            not responsible for any unauthorized access to your account or any
            actions taken by unauthorized users. If you know or suspect that any
            third party has unauthorized access to your account, you must
            promptly notify us at{" "}
            <a href="mailto:info@dingdingnow.com" className="text-primary-700">
              info@dingdingnow.com
            </a>
            .
          </p>
        ),
      },
      {
        title: "5.3",
        content: (
          <p>
            You agree that you are solely responsible (to us and to others) for
            the activity that occurs under your account.
          </p>
        ),
      },
      {
        title: "5.4",
        content: (
          <>
            <p>
              We reserve the right, in our sole and absolute discretion, to
              disable or deactivate your account at any time for any or no
              reason, including, without limitation, failure to comply with any
              provision of these Terms or our Privacy Policy, or the occurrence
              of activities on your account which:
            </p>
            <p>i. could cause damage to or impair the Platform or Services;</p>
            <p>ii. infringe or violate any third party rights; or</p>
            <p>iii. violate any applicable laws or regulations.</p>
          </>
        ),
      },
      {
        title: "5.5",
        content: (
          <p>
            If you no longer want to use the Platform or our Services, you may
            delete your account by contacting us at{" "}
            <a href="mailto:info@dingdingnow.com" className="text-primary-700">
              info@dingdingnow.com
            </a>
            . Once you choose to delete your account, you will not be able to
            reactivate your account or retrieve any of the content or
            information associated with your account.
          </p>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 6. Account Registration & Termination",
    items: [
      {
        title: "6.1",
        content: (
          <p>
            Your access to and use of the Platform and our Services are subject
            to these Terms and all applicable laws and regulations. You shall
            not:
          </p>
        ),
      },
      {
        title: "6.2",
        content: (
          <>
            <p>
              a. Breach or circumvent any applicable laws or regulations,
              agreements with third-parties, third-party rights, these Terms or
              our Privacy Policy;
            </p>
            <p>
              b. Access or use the Platform or our Services if you are not at
              least 18 years old and fully able and legally competent to agree
              to these Terms;
            </p>
            <p>
              c. Conduct yourself in an improper manner, including, without
              limitation, using profanity or other objectionable language or
              taking inappropriate actions towards other Users;
            </p>
            <p>
              d. Market, rent or lease our Services for a fee or charge, or use
              the Platform or our Services to advertise or perform any
              commercial solicitation;
            </p>
            <p>
              e. Use the Platform or our Services, without our explicit written
              consent, for any commercial or unauthorized purpose, including,
              without limitation, communicating or facilitating any commercial
              advertisement or solicitation or spamming;
            </p>
            <p>
              f. Interfere with or attempt to interfere with the proper
              functioning of the Platform or our Services, disrupt our website
              or any networks connected to the Platform or Services, or bypass
              any measures we may use to prevent or restrict access to the
              Platform or Services;
            </p>
            <p>
              g. Impersonate any person or entity, or falsely state or otherwise
              misrepresent yourself or your affiliation with any person or
              entity, including, without limitation, giving the impression that
              any content you share, upload, post, transmit, distribute or
              otherwise make available emanates from the Platform or us;
            </p>
            <p>
              h. Intimidate or harass other Users, or promote sexually explicit
              material, violence or discrimination based on protected classes,
              including race, sex, religion, nationality, disability, sexual
              orientation or age;
            </p>
            <p>
              i. Use the Platform or Services in a manner that may create a
              conflict of interest or undermine the purposes of the Platform or
              Services, such as trading reviews with other users or writing or
              soliciting fake reviews;
            </p>
            <p>j. Use the Services for any illegal or improper actions;</p>
            <div>
              <p className="mb-4">
                k. Transmit, upload, distribute or share information containing:
              </p>
              <div className="space-y-4 pl-8 md:pl-4">
                <p>
                  i. any unsolicited or unauthorized advertising, solicitations,
                  promotional materials, junk mail, spam, chain letters, pyramid
                  schemes, or any other prohibited form of solicitation;
                </p>
                <p>
                  ii. any private information, including addresses, phone
                  numbers, email addresses, credit card or financial
                  information, or any numbers or features in any personal
                  identity documentation (e.g., insurance documents, driver’s
                  licenses, passports and social security cards);
                </p>
                <p>
                  iii. any material which does or may infringe any copyright,
                  trademark or other intellectual property or privacy rights of
                  any other person;
                </p>
                <p>
                  iv. any material which is defamatory of any person, obscene,
                  offensive, pornographic, hateful or inflammatory;
                </p>
                <p>
                  v. any material that would constitute, encourage or provide
                  instructions for a criminal offence, dangerous activities or
                  self-harm;
                </p>
                <p>
                  vi. any material that is deliberately designed to provoke or
                  antagonize people, especially trolling and bullying, or is
                  intended to harass, harm, hurt, scare, distress, embarrass or
                  upset people;
                </p>
                <p>
                  vii. any material that contains a threat of any kind,
                  including threats of physical violence;
                </p>
                <p>
                  viii. any material that is racist or discriminatory, including
                  discrimination on the basis of someone’s race, religion, age,
                  gender, disability or sexuality;
                </p>
                <p>
                  ix. any answers, responses, comments, opinions, analysis or
                  recommendations that you are not properly licensed or
                  otherwise qualified to provide;
                </p>
                <p>
                  x. any legal or medical advice, political campaign materials,
                  religious materials or personal health information; or
                </p>
                <p>
                  xi. material that, in our sole and absolute discretion, is
                  objectionable or which restricts or inhibits any other person
                  from using the Platform or our Services, or which may expose
                  DingDingNow or its any Users to any harm or liability of any
                  type.
                </p>
              </div>
            </div>
          </>
        ),
      },
      {
        title: "6.3",
        content: (
          <p>
            You acknowledge that you are using the Platform and our Services at
            your own risk. You understand that we do not screen Users in any
            way. You are solely responsible for, and will exercise caution,
            discretion, common sense, and judgement while using the Platform and
            our Services. You agree to take reasonable precautions in all
            interactions with Users.
          </p>
        ),
      },
      {
        title: "6.4",
        content: (
          <>
            <p>
              You agree to treat any information received from any Host,
              Learner, or other User as highly sensitive, top secret and
              classified material. Without limiting the generality of the above,
              you specifically agree to:
            </p>
            <p>i. maintain all such information in strict confidence;</p>
            <p>
              ii. not disclose the information to any third parties for
              commercial purposes; and
            </p>
            <p>iii. not to copy or reproduce any of the information.</p>
          </>
        ),
      },
      {
        title: "6.5",
        content: (
          <p>
            If you feel that any User you interact with is acting or has acted
            inappropriately, including, without limitation, anyone who engages
            in offensive, violent, sexually inappropriate or other disturbing
            conduct, you should immediately report such User to us by using the
            “Contact Us” page on the Platform or sending us an email at
            <DDNLink />.
          </p>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 7. Specific Terms for Hosts",
    items: [
      {
        title: "7.1",
        content: (
          <p>
            If you register as a Host and offer Event(s) or Session(s) via the
            Platform, the provisions of this Article 7, in addition to the rest
            of these Terms, shall apply.
          </p>
        ),
      },
      {
        title: "7.2",
        content: (
          <p>
            We reserve the right, within our sole and absolute discretion, to
            delete your account and your Event(s)/Session(s).
          </p>
        ),
      },
      {
        title: "7.3",
        content: (
          <p>
            You must provide accurate information about your expertise,
            experiences and qualifications, and give accurate descriptions about
            your Event(s) and Session(s), including duration, time slots,
            content and plan.
          </p>
        ),
      },
      {
        title: "7.4",
        content: (
          <p>
            When a Learner signs up for your Event(s) or Session(s) via the
            Platform , you agree to provide the Event(s) as specified in your
            Event description and be present on the date and time as scheduled.
            Additionally, you agree to provide reasonable advanced notice to
            Users prior to the cancellation of any scheduled Event(s) or
            Session(s) (See Article 10 for additional terms on cancellations and
            refunds).
          </p>
        ),
      },
      {
        title: "7.5",
        content: (
          <>
            <p>You represent, warrant, and covenant that you:</p>
            <p>
              i. have the requisite knowledge, skills or information to provide
              Event(s) you offer on the Platform;
            </p>
            <p>
              ii. utilize the User-paid time slots solely to deliver
              Interaction(s)
            </p>
            <p>
              iii. will provide Event(s) as specified in your Event description;
            </p>
            <p>
              iv. will not provide any Event(s) or Session(s) to persons younger
              than 18 years old; and
            </p>
            <p>
              v. are fully licensed if a license is required for offering such
              Event(s).
            </p>
          </>
        ),
      },
      {
        title: "7.6",
        content: (
          <p>
            If any payments that are remitted to you in accordance with these
            Terms are determined to be refundable to a User by us, within our
            sole and absolute discretion, you will be liable to us and the User
            for the full amount of such payments and any fees related thereto.
            Accordingly, you are responsible for reimbursing <DDNLink /> in the
            event of a chargeback, reversal of payment or other claim by a User.
          </p>
        ),
      },
      {
        title: "7.7",
        content: (
          <p>
            IRS regulations regarding federal tax reporting requirements
            stipulate that <DDNLink /> may collect an IRS Form W-9 from any
            Mentor. Because tax laws vary significantly by locality, you
            understand and agree that you are solely responsible for determining
            your own tax filing requirements. We cannot and do not offer tax
            advice to any Host or other User.
          </p>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 8. Specific Terms for Learners",
    items: [
      {
        title: "8.1",
        content: (
          <p>
            If you register as a Learner and want to sign up for Event(s)
            available on the Platform, the provisions of this Article 8, in
            addition to the rest of these Terms, shall apply.
          </p>
        ),
      },
      {
        title: <span className="text-primary-700">8.2</span>,
        content: (
          <p className="text-primary-700">
            Feedback reviews, comments and suggestions provided by Learners are
            an essential part of DingDingNow’s rating system. We take those
            feedbacks into account to continuously improve the Platform, and
            take actions if necessary. You can send your reviews through Contact
            Us.
          </p>
        ),
      },
      {
        title: "8.3",
        content: (
          <p>
            In your feedback reviews, you may{" "}
            <TextHighlight>communicate</TextHighlight> reviews, comments,
            suggestions, ideas, questions and other content, so long as your
            posts are not illegal, obscene, threatening, defamatory, invasive of
            privacy, infringing of intellectual property rights (including
            publicity rights), in violating these Terms or otherwise injurious
            to third parties or objectionable. Your{" "}
            <TextHighlight>reviews</TextHighlight> shall not consist of or
            contain software viruses, political campaigning, commercial
            solicitation, mass mailings, or any form of "spam" or unsolicited
            commercial electronic messages.{" "}
          </p>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 9. Fees and Payments",
    items: [
      {
        title: "9.1",
        content: (
          <p>
            Hosts are solely responsible for setting a price (including any
            applicable Taxes and other charges) for all Event(s) and Session(s)
            offered by him/her, including individual Interactions, one-on-one
            sessions and group sessions (the “Host Fee”). Each Host is solely
            responsible for any and all federal, state and local tax obligations
            that arise as a result of their Host Fee, and shall indemnify and
            hold us harmless from said obligations. Each Host acknowledges that
            depending on their locality, different tax obligations may apply
            (including requirements to impose taxes on Learners) and that{" "}
            <DDNLink /> cannot ensure their compliance with said tax
            obligations. Each Host shall fully consider all applicable tax
            obligations when determining their Host Fee.
          </p>
        ),
      },
      {
        title: "9.2",
        content: (
          <p>
            In order to sign up for any Event(s) or Session(s), the Learners
            shall pay the full Host Fee listed for such Event(s) and/or
            Session(s).
          </p>
        ),
      },
      {
        title: "9.3",
        content: (
          <>
            <p>
              DingDongNow collects 10% of all gross Host Fees (plus any
              additional fees) paid by Learners in consideration of the use of
              the Platform and Services by the Hosts (the “Service Fee”). Unless
              otherwise expressly provided, the Service Fee is non-refundable.
              After collection of the Service Fee, we will remit the remaining
              Host Fee to the applicable Host via in US Dollars within 12
              business days after the applicable Host Fee was paid by the
              Learners. DingDingNow may void or withhold Host Fees, in its sole
              and absolute discretion, for:
            </p>
            <p>i. breach of these Terms;</p>
            <p>ii. suspected fraudulent activity;</p>
            <p>
              iii. any report concerning Host’s behavior, performance, Event(s)
              or Session(s); or
            </p>
            <p>iv. any refund request from other Users.</p>
          </>
        ),
      },
    ],
  },
  {
    articleTitle:
      "Article 10. Rescheduling, Cancellations, No-shows and Refunds",
    items: [
      {
        title: "10.1",
        content: (
          <p>
            Learners may cancel any scheduled Event(s)/Session(s) without any
            cost up to 24 hours prior to such Session(s). Learners will be
            entitled to a full refund of all amounts paid if cancellation occurs
            in accordance with this Article 10.1.
          </p>
        ),
      },
      {
        title: "10.2",
        content: (
          <p>
            If a Learner cancels any scheduled Event(s)/Session(s) less than 24
            hours prior to such Session(s), any and all payments made by the
            Learner for such Session(s) are not refundable.
          </p>
        ),
      },
      {
        title: "10.3",
        content: (
          <p>
            If a Learner is absent from or late to scheduled Session(s),
            payments made by the Learner for such Session(s) are not refundable.
          </p>
        ),
      },
      {
        title: "10.4",
        content: (
          <p>
            Notwithstanding the foregoing, Learners may reschedule any scheduled
            Session(s) up to 12 hours prior to such Session(s) by paying an
            additional fee equal to 50% of the Host Fee for such Session(s).
            Learners will not be able to reschedule less than 12 hours before
            the scheduled Session(s).
          </p>
        ),
      },
      {
        title: "10.5",
        content: (
          <p>
            Hosts may not reschedule Session(s) but may cancel scheduled
            Session(s) at any time. If a Host cancels any scheduled Session(s),
            the Learner for such canceled Session(s) will receive a full refund
            of all payments made.
          </p>
        ),
      },
      {
        title: "10.6",
        content: (
          <p>
            If a Host does not attend one of their scheduled Session(s), the
            Learners for such Session(s) will receive a full refund of all
            payments made.
          </p>
        ),
      },
      {
        title: "10.7",
        content: (
          <p>
            If a Host is more than 15 minutes late to one of their scheduled
            Session(s), such Session(s) shall be deemed canceled by the Host.
          </p>
        ),
      },
      {
        title: "10.8",
        content: (
          <>
            <p>
              <DDNLink /> may decide, in its sole and absolute discretion, that
              it is necessary to cancel scheduled Session(s). Cancellation of
              scheduled Session(s) by <DDNLink /> may occur for any or no
              reason, including, without limitation, the following:
            </p>
            <p>
              i. we believe that cancellation is necessary to avoid harm to
              <DDNLink />, other Users, third parties or property;
            </p>
            <p>
              ii. we believe the scheduled Session(s) are or will be in
              violation of these Terms; or
            </p>
            <p>
              iii. we believe the Host is or will be engaged in conduct that is
              in violation of these Terms. If cancellation by <DDNLink /> occurs
              pursuant to this Article 10.8, the Learners for such cancelled
              Session(s) shall be entitled to a full refund of all payments
              made.
            </p>
          </>
        ),
      },
      {
        title: "10.9",
        content: (
          <p>
            In the event that any User disputes any portion of amounts paid or
            remitted to <DDNLink /> or any Host under these Terms, said User
            must provide written notice to <DDNLink /> within 30 days after such
            payment or remittance occurs. Upon receipt of such written notice,{" "}
            <DDNLink /> shall determine, in its sole and absolute discretion, if
            any refund should be issued. If we do not receive written notice of
            a dispute within the foregoing 30-day period, any and all claims to
            a refund shall be deemed waived.
          </p>
        ),
      },
      {
        title: "10.10",
        highlight: true,
        content: (
          <p>
            IN ACCORDANCE WITH THIS ARTICLE 10, YOU HEREBY EXPRESSLY CONSENT TO
            THE PRECEDING WAIVER OF YOUR REFUND AND WITHDRAWAL RIGHTS.
          </p>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 11. User Content License",
    items: [
      {
        title: "11.1",
        content: (
          <p>
            <DDNLink /> may permit the posting and/or publishing by you and
            other Users of notes, questions, comments, ratings, reviews, images,
            videos and other materials and communications (collectively, "User
            Content"). You understand that whether or not such User Content is
            published, <DDNLink /> does not guarantee any confidentiality with
            respect to any submissions. Consistent with applicable law, as
            between <DDNLink /> and you, you retain all ownership rights you
            have in any User Content you post or publish to the Platform, and{" "}
            <DDNLink /> does not claim any ownership rights in or to such User
            Content. You acknowledge that you are solely responsible for your
            User Content and the consequences of posting, creating, or
            publishing such User Content.
          </p>
        ),
      },
      {
        title: "11.2",
        contnet: (
          <p>
            By posting, submitting or distributing User Content on the Platform
            or through our Services, you hereby grant to <DDNLink /> a
            worldwide, non-exclusive, transferable, assignable, fully paid-up,
            royalty-free right and license to host, transfer, display, perform,
            reproduce, distribute, prepare derivative works of, use, make, have
            made, import, and otherwise exploit your User Content, in whole or
            in part, in any media formats and through any media channels (now
            known or hereafter developed, to the extent consistent with
            applicable law and <DDNLink />
            ’s Privacy Policy). Such license is perpetual and irrevocable,
            except to the extent required to comply with applicable privacy law
            relating to ownership and control of your personal information,
            including education records.
          </p>
        ),
      },
      {
        title: "11.3",
        content: (
          <p>
            By posting, submitting or distributing User Content on the Platform
            or through our Services, you hereby grant to each User of the
            Services a non-exclusive license to access and use your User Content
            in any manner permitted or made available by <DDNLink /> on the
            Platform or through our Services.
          </p>
        ),
      },
      {
        title: "11.4",
        content: (
          <p>
            You understand that when using the Platform and/or our Services, you
            will be exposed to User Content from a variety of sources, and that{" "}
            <DDNLink /> is not responsible for the accuracy, usefulness or
            intellectual property rights of or relating to such User Content.
            You further understand and acknowledge that you may be exposed to
            User Content that is inaccurate, offensive, indecent or
            objectionable, and you agree to waive any legal or equitable rights
            or remedies you have or may have against <DDNLink /> with respect
            thereto. <DDNLink /> does not endorse any User Content or any
            opinion, recommendation or advice expressed therein, and you
            expressly release <DDNLink /> from any and all liability in
            connection with User Content.
          </p>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 12. Indemnity",
    items: [
      {
        title: "",
        content: (
          <>
            <p>
              To the fullest extent permitted by applicable law, you agree to
              defend, indemnify and hold harmless <DDNLink /> from and against
              any and all loss, liability, damages, judgments, claims, demands,
              costs, investigations, settlements and expenses (including,
              without limitation, reasonable attorneys' fees) arising out of or
              directly or indirectly relating to:
            </p>
            <p>i. any and all User Content;</p>
            <p>ii. your use of the Platform and/or our Services;</p>
            <p>
              iii. any activities in connection with the Platform or our
              Services (including Third Party Services);
            </p>
            <p>iv. your breach or anticipatory breach of these Terms;</p>
            <p>
              v. your violation of any laws, rules, regulations, codes,
              statutes, ordinances or orders of any governmental and
              quasi-governmental authorities, including, without limitation, all
              regulatory, administrative and legislative authorities;
            </p>
            <p>
              vi. information or material transmitted through your computer,
              even if not submitted by you, that infringes, violates or
              misappropriates any copyright, trademark, trade secret, trade
              dress, patent, publicity, privacy or other right of any person or
              defames any person;
            </p>
            <p>vii. any misrepresentation made by you; or</p>
            <p>
              viii. the use of your information or User Content as permitted
              under these Terms, our Privacy Policy, or any other written
              agreement between you and <DDNLink />. You agree to fully
              cooperate with us in the defense of any claim under this Article
              12. The provisions of this Article 12 shall survive any expiration
              or termination of your relationship with <DDNLink />.
            </p>
          </>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 13. No Warranties",
    items: [
      {
        title: "13.1",
        content: (
          <p>
            NOTHING IN THESE TERMS SHALL AFFECT ANY STATUTORY RIGHTS THAT YOU
            CANNOT CONTRACTUALLY AGREE TO ALTER OR WAIVE AND ARE LEGALLY
            ENTITLED TO AS A CONSUMER.
          </p>
        ),
      },
      {
        title: "13.2",
        content: (
          <>
            <p className="uppercase">
              NOTWITHSTANDING ARTICLE 13.1 ABOVE, THE PLATFORM AND OUR SERVICES
              ARE PROVIDED “AS IS” WITH ALL FAULTS. TO THE MAXIMUM EXTENT
              PERMITTED BY LAW, <DDNLink /> DISCLAIMS ALL EXPRESS AND IMPLIED
              WARRANTIES TO YOU OR FOR THE BENEFIT OF ANY THIRD PARTY. THE
              ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY, AND
              EFFORT OF THE PLATFORM AND OUR SERVICES SHALL BE WITH YOU. FOR THE
              AVOIDANCE OF DOUBT, WE DO NOT REPRESENT OR WARRANT TO YOU THAT;
            </p>
            <p>
              a. YOUR USE OF THE PLATFORM OR OUR SERVICES WILL MEET YOUR NEEDS
              OR EXPECTATIONS;
            </p>
            <p>
              b. YOUR USE OF THE PLATFORM OR OUR SERVICES WILL BE UNINTERRUPTED,
              TIMELY, SECURE OR FREE FROM ERROR;
            </p>
            <p>
              c. ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE
              PLATFORM OR OUR SERVICES WILL BE ACCURATE OR RELIABLE; OR
            </p>
            <p>
              d. ANY DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE
              PROVIDED TO YOU AS PART OF THE PLATFORM OR SERVICES WILL BE
              CORRECTED.
            </p>
          </>
        ),
      },
      {
        title: "13.3",
        content: (
          <p>
            YOU ACKNOWLEDGE AND AGREE THAT WE MAY, WITHIN OUR SOLE AND ABSOLUTE
            DISCRETION, CHANGE, SUSPEND, WITHDRAW OR RESTRICT THE AVAILABILITY
            OF ALL OR ANY PART OF THE PLATFORM OR OUR SERVICES FOR ANY OR NO
            REASON AT ANY TIME WITHOUT NOTICE.
          </p>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 14. Limitation of Liability and Damages",
    items: [
      {
        title: "14.1",
        content: (
          <p>
            YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY
            LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE
            PLATFORM AND SERVICES REMAINS WITH YOU.
          </p>
        ),
      },
      {
        title: "14.2",
        content: (
          <p className="uppercase">
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER <DDNLink /> NOR ANY
            OTHER PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING OR DELIVERING
            THE PLATFORM OR SERVICES SHALL BE LIABLE FOR ANY DAMAGE OR HARM
            ARISING FROM THESE TERMS OR YOUR ACCESS TO OR USE OF THE PLATFORM OR
            OUR SERVICES. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING,
            <DDNLink /> SHALL NOT BE LIABLE FOR ANY LOST PROFITS, REVENUES,
            GOODWILL, BUSINESS OPPORTUNITY, INFORMATION OR DATA, OR ANY
            CONSEQUENTIAL, SPECIAL, INDIRECT, EXEMPLARY, PUNITIVE, OR INCIDENTAL
            DAMAGES ARISING OUT OF OR RELATED TO THESE TERMS, THE PLATFORM OR
            OUR SERVICES, EVEN IF <DDNLink /> HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. IF THE FOREGOING IS RENDERED
            UNENFORCEABLE FOR ANY REASON, OUR AGGREGATE LIABILITY ARISING OUT OF
            OR RELATING TO THESE TERMS, THE PLATFORM OR OUR SERVICES WILL NOT
            EXCEED THE GREATER OF $100 OR THE AMOUNT YOU HAVE PAID US IN THE
            PAST TWELVE MONTHS. YOU SPECIFICALLY ACKNOWLEDGE THAT OUR LIABILITY
            FOR DAMAGES SUFFERED BY YOU ARE LIMITED AND LIQUIDATED AS SET FORTH
            HEREIN. YOU AGREE TO WAIVE ANY AND ALL PROTECTIONS AVAILABLE TO YOU
            UNDER APPLICABLE LAW, INCLUDING, BUT NOT LIMITED TO, GENERAL RELEASE
            PROTECTIONS. THE LIMITATIONS OF LIABILITY SET FORTH IN THIS ARTICLE
            14 ARE A NECESSARY CONDITION OF YOUR ACCESS TO AND USE OF THE
            PLATFORM AND OUR SERVICES. YOU HEREBY ACKNOWLEDGE THAT WE WOULD NOT
            GRANT YOU ACCESS OR USE OF THE PLATFORM OR OUR SERVICES IF NOT FOR
            THE PROVISIONS OF THIS ARTICLE 14.
          </p>
        ),
      },
      {
        title: "14.3",
        content: (
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, ANY DISPUTE YOU HAVE WITH
            ANY THIRD PARTY ARISING OUT OF YOUR USE OF THE PLATFORM OR OUR
            SERVICES, INCLUDING, BY WAY OF EXAMPLE AND NOT LIMITATION, ANY HOST,
            LEARNER OR OTHER USER, IS DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY.
            YOU IRREVOCABLY RELEASE US AND OUR AFFILIATES FROM ANY AND ALL
            CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND
            AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY
            CONNECTED WITH SUCH DISPUTES.
          </p>
        ),
      },
      {
        title: "14.4",
        content: (
          <p>
            EXCEPT WHERE PROHIBITED BY LAW, NO CLAIM OR LEGAL ACTION THAT EITHER
            PARTY MAY HAVE ARISING OUT OF THESE TERMS, THE PLATFORM OR OUR
            SERVICES (WHETHER BASED ON AGREEMENT, NEGLIGENCE OR OTHERWISE) MAY
            BE BROUGHT MORE THAN ONE YEAR AFTER THE DATE THE CAUSE OF ACTION FOR
            SUCH CLAIM OR LEGAL ACTION ACCRUED. IF, UPON YOUR VIOLATION OF ANY
            PROVISION OF THESE TERMS, THIS AGREEMENT IS PLACED IN THE HANDS OF
            AN ATTORNEY FOR LEGAL ACTION, YOU AGREE TO PAY ALL REASONABLE FEES
            AND COSTS (INCLUDING, BUT NOT LIMITED TO, REASONABLE ATTORNEYS’
            FEES).
          </p>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 15. Arbitration and Class Action Waiver",
    items: [
      {
        title: "15.1",
        content: (
          <p>
            THIS ARTICLE 15 CONTAINS AN ARBITRATION AGREEMENT AND CLASS ACTION
            WAIVER. THE PARTIES AGREE THAT, BY ENTERING INTO THIS AGREEMENT, ALL
            PARTIES ARE WAIVING THEIR RESPECTIVE RIGHTS TO A TRIAL BY JURY OR TO
            PARTICIPATE IN A CLASS OR REPRESENTATIVE ACTION.
          </p>
        ),
      },
      {
        title: "",
        highlight: true,
        content: (
          <p className="text-dgray-900 font-semibold">
            THE PARTIES HEREBY ACKNOWLEDGE THAT THEY ARE WAIVING ANY RIGHT TO:
            (i) LITIGATE IN COURT; (ii) HAVE A JUDGE OR JURY DECIDE THEIR CASE;
            OR (iii) BE A PARTY IN A CLASS OR REPRESENTATIVE ACTION. THE PARTIES
            FURTHER ACKNOWLEDGE THAT ANY AND ALL CLAIMS (EXCEPT AS EXPRESSLY
            PROVIDED OTHERWISE HEREIN) MUST BE DECIDED INDIVIDUALLY, THROUGH
            ARBITRATION.
          </p>
        ),
      },
      {
        title: "15.2",
        content: (
          <>
            <p>
              In order to expedite and control the cost of disputes, the parties
              agree that any legal or equitable claim relating to or arising out
              of these Terms, the Platform or our Services (referred to as a
              “Claim”) will be resolved as follows:
            </p>
            <p>
              a. The parties will first try to resolve any Claim informally.
              Accordingly, neither party may start a formal proceeding for at
              least 60 calendar days after notifying the other party of a Claim
              in writing. This Article 15.2.a shall not extend the applicable
              statute of limitations for any Claim, as set forth in Article 14.4
              of these Terms.
            </p>
            <p>
              b. If the parties cannot resolve a Claim informally pursuant to
              Article 15.2.a, said Claim shall be formally resolved by binding
              arbitration in accordance with the then-current Commercial
              Arbitration Rules of the American Arbitration Association (such
              rules can be found online at https://www.adr.org/Rules).
              <DDNLink /> will select the arbitrator, within its sole and
              absolute discretion. The arbitration will take place in Montgomery
              County, Maryland. All documents, materials, and information in the
              possession of each party that are in any way relevant to the
              dispute will be made available to the other party for review and
              copying no later than thirty (30) calendar days after the notice
              of arbitration is served. The arbitrator will not have the
              authority to modify any provision of these Terms or to award
              punitive damages. The arbitrator will have the power to issue
              mandatory orders and restraint orders in connection with the
              arbitration. The decision rendered by the arbitrator will be final
              and binding on the parties, and judgment may be entered in
              conformity with the decision in any court having jurisdiction.
              This agreement to arbitration will be specifically enforceable
              under the prevailing arbitration law. During the continuance of
              any arbitration proceeding, the parties will continue to perform
              their respective obligations under these Terms. The party that
              files for arbitration will pay the initial filing fee. Each party
              shall initially bear the expense of that party's attorneys,
              experts, witnesses and other expenses, but may recover any or all
              expenses from the other party if the arbitrator so determines.
            </p>
            <p>
              c. Any Claim must be brought in the respective party’s individual
              capacity, and not as a plaintiff or class member in any purported
              class, collective, representative, multiple plaintiff or similar
              action/proceeding (such prohibited actions/proceedings are
              referred to as a “Class Action”). The parties expressly waive any
              ability to maintain any Class Action in any forum. The arbitrator
              under Article 15.2.b shall not have authority to combine or
              aggregate similar claims, conduct any Class Action or make an
              award to any person or entity not a party to the arbitration.
              Notwithstanding anything contained herein to the contrary, any
              Claim that seeks to render the provisions of this Article 15.2.c
              unenforceable shall be determined by a court of competent
              jurisdiction and not by an arbitrator. Under no circumstances
              shall a Class Action be decided by an arbitrator. The arbitration
              provisions set forth in Article 15.2.b shall not apply to Class
              Actions if this Article 15.2.c is rendered unenforceable by a
              court of competent jurisdiction. If for any reason a Class Action
              is permitted to proceed, the parties hereby waive any right to a
              jury trial.
            </p>
          </>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 16. Proprietary Right Notices",
    items: [
      {
        title: "16.1",
        content: (
          <p>
            All trademarks, service marks, logos, trade names and any other
            proprietary designations of DingDingNow used herein or on the
            Platform are trademarks or registered trademarks of DingDingNow. Any
            other trademarks, service marks, logos, trade names or other
            proprietary designations are the trademarks or registered trademarks
            of other parties.
          </p>
        ),
      },
      {
        title: "16.2",
        content: (
          <p>
            We respond to notices of alleged copyright infringement under the
            Digital Millennium Copyright Act (“DMCA”). If you believe your
            intellectual property rights have been infringed, please contact us
            at
            <a href="mailto:info@dingdingnow.com">info@dingdingnow.com</a>.
          </p>
        ),
      },
    ],
  },
  {
    articleTitle: "Article 17. Miscellaneous",
    items: [
      {
        title: "17.1",
        content: (
          <p>
            These Terms constitute the entire agreement between you and
            <DDNLink /> with respect to your use of and access to the Platform
            and/or our Services. These Terms govern your use of the Platform and
            our Services and completely replace any prior agreements between you
            and <DDNLink /> with respect to the subject matter contained herein.
          </p>
        ),
      },
      {
        title: "17.2",
        content: (
          <p>
            Our failure to insist upon or enforce any provision of these Terms
            shall not be construed as a waiver of any provision or right.
          </p>
        ),
      },
      {
        title: "17.3",
        content: (
          <p>
            We do not guarantee that the Platform or our Services will be secure
            or free from bugs or viruses. You are responsible for configuring
            your information technology, computer programs and devices to access
            the Platform and our Services. You use and access the Platform and
            our Services at your own risk. <DDNLink /> is not responsible for
            any damage to your computer or other device resulting from your
            access or use of the Platform or our Services. You should obtain
            your own virus protection software.
          </p>
        ),
      },
      {
        title: "17.4",
        content: (
          <p>
            If any of the provisions of these Terms shall be determined to be
            invalid or unenforceable, the remaining provisions shall remain in
            full force and effect. In the event that a provision of these Terms
            is determined to be invalid or unenforceable, said provision shall
            be modified to the minimum extent necessary to render it valid and
            enforceable. Said modified provision shall be in full force and
            effect as if it were originally part of these Terms.
          </p>
        ),
      },
      {
        title: "17.5",
        content: (
          <p>
            These Terms, the Platform and our Services shall be governed by and
            construed in accordance with applicable federal laws and the laws of
            the State of Maryland, without giving effect to any principles of
            conflicts of law that would cause the application of the laws of any
            other jurisdiction. Notwithstanding the foregoing, the arbitration
            provisions under Article 15.2 shall be governed by the Federal
            Arbitration Act.
          </p>
        ),
      },
      {
        title: "17.6",
        content: (
          <p>
            We do not provide any medical advice. The contents of the Platform
            and our Services are for informational purposes only and are not
            intended as a substitute for professional medical advice, diagnosis
            or treatment.
          </p>
        ),
      },
      {
        title: "17.7",
        content: (
          <p>
            The headings in these Terms are for convenience of reference only
            and do not control or affect the contents of any provision contained
            herein.
          </p>
        ),
      },
      {
        title: "17.8",
        content: (
          <p>
            These Terms will remain in effect upon the expiration or termination
            of your relationship with <DDNLink />. The provisions of these Terms
            will inure to the benefit of our affiliates, successors and assigns,
            as well as the officers, directors and employees of each of these
            entities and <DDNLink />.
          </p>
        ),
      },
    ],
  },
  {
    // articleTitle: "Article 18. Contact Us",
    items: [
      {
        title: "",
        content: (
          <div className="bg-primary-50 rounded-2xl p-8">
            <h2 className="text-3xl md:text-2xl font-semibold text-dgray-900 mb-6">
              Article 18. Contact Us
            </h2>
            <p className="text-dgray-900">
              If you have any questions/concerns about these Terms, please
              contact <DDNLink /> at{" "}
              <a
                href="mailto:info@dingdingnow.com"
                className="text-primary-700"
              >
                info@dingdingnow.com
              </a>
              .
            </p>
          </div>
        ),
      },
    ],
  },
];
