import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BackgroundImage from "assets/images/bg/bg51.jpg";
import CartItems from "./CartItems";
import NoRecord from "views/NoRecord";
import Swal from "sweetalert2";
import moment from "moment";

import {
  getCartDetail,
  RemoveFromCart,
  payNow,
  initializePayment,
} from "actions/front/learner/order";
import Spinner1 from "views/Spinner1";
import PaymentModal from "./PaymentModal";
import Header from "views/Front/Layout/Header";
import CenterContainer from "components/common/Container/Center";
import Table from "components/common/Table";
import Pill from "components/common/Pill";
import { SESSION_TYPE } from "constants";
import { getCurrentTimeZone } from "utils/time";
import Button from "components/common/Button";
import Footer from "views/Front/Layout/Footer";

const Cart = ({
  user,
  getCartDetail,
  RemoveFromCart,
  history,
  payNow,
  initializePayment,
}) => {
  const [order, setOrder] = useState({ items: [], total: 0 });
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [initializing, setInitializing] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [deleteCartItem, setCartItem] = useState(false);

  let timeZone = getCurrentTimeZone(user);

  const status = new URLSearchParams(window.location.search).get("status");

  const [isRetryModalOpen, setRetryModalOpen] = useState(Boolean(status));

  useMemo(() => {
    if (!paymentLoader) {
      setLoader(true);
    }
    getCartDetail().then((res) => {
      if (res.status) {
        setOrder(res.data);
      }
      setLoader(false);
      setPaymentLoader(false);
    });
  }, [deleteCartItem]);

  const initialize = () => {
    if (order.total !== 0 && !initializing) {
      setInitializing(true);
      initializePayment()
        .then((res) => {
          setInitializing(false);
          setPaymentIntent(res.data);
          setModalOpen(true);
          if(!res.status){
            Swal.fire({
              title: "",
              text: res.msg,
              icon: "warning",
              confirmButtonText: "Close",
              closeOnConfirm: false,
            }).then((result) => {
              if (result.value) {
                closePopup();
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
          setInitializing(false);
        });
    }
  };

  /**********on checkout click ****************/
  // const [modal, setModal] = useState(false);
  // const [modeldata, setModalData] = useState(false);

  const makePayment = (token) => {
    setPaymentLoader(true);
    const body = {
      token,
      order,
    };
    payNow(body).then((res) => {
      if (res.status) {
        setPaymentLoader(false);
        Swal.fire({
          title: "",
          text: res.msg,
          icon: "success",
          confirmButtonText: "Close",
          closeOnConfirm: false,
        }).then((result) => {
          if (result.value) {
            closePopup();
          }
        });
      }
    });
  };

  const onCheckoutClick = (e) => {
    const body = {
      order,
    };
    const isFree = true;
    setPaymentLoader(true);
    payNow(body, isFree).then((res) => {
      if (res.status) {
        setPaymentLoader(false);
        Swal.fire({
          title: "",
          text: res.msg,
          icon: "success",
          confirmButtonText: "Close",
          closeOnConfirm: false,
        }).then((result) => {
          if (result.value) {
            closePopup();
          }
        });
      }
    });
  };
  const closePopup = () => history.push("/dashboard");
  const removeFromCart = (item) => {
    RemoveFromCart(item).then((res) => {
      if (res.status) {
        setPaymentLoader(true);
        deleteCartItem ? setCartItem(false) : setCartItem(true);
      }
    });
  };

  return (
    <>
      <Header />
      <CenterContainer className="py-24 mx-4 md:py-16">
        <div className="text-2xl font-medium mb-6">Cart</div>
        <div className="col-md-12">
          {loader ? (
            <Spinner1 />
          ) : order.items.length > 0 ? (
            <>
              <Table
                top={
                  <div className="flex justify-between w-full">
                    <span>
                      Courses in Your Cart
                      <Pill
                        className="ml-2"
                        text={`${order.items.length}`}
                        color="orange"
                      />
                    </span>
                    <span>
                      Total Amount: $
                      {order.total.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </div>
                }
                columns={[
                  {
                    label: "Name",
                    key: "course_name",
                  },
                  {
                    label: "Host",
                    key: "educator_name",
                  },
                  {
                    label: "Date/Time",
                    key: "session_data",
                    Content: ({ data }) => (
                      <>
                        {data.order_for === SESSION_TYPE.GENERIC ? (
                          <>
                            <span>
                              {moment
                                .unix(data.session_data[0].start_at)
                                .tz(timeZone)
                                .format("DD MMM, YYYY HH:mm A")}{" "}
                              -{" "}
                              {moment
                                .unix(
                                  data.session_data[
                                    data.session_data.length - 1
                                  ].end_at
                                )
                                .tz(timeZone)
                                .format("HH:mm A")}
                            </span>
                            <br />
                          </>
                        ) : (
                          data.session_data.map((session, index) => (
                            <React.Fragment key={`session_${index}`}>
                              <span>
                                {moment
                                  .unix(session.start_at)
                                  .tz(timeZone)
                                  .format("DD MMM, YYYY HH:mm A")}{" "}
                                {session.end_at ? (
                                  <>
                                    -{" "}
                                    {moment
                                      .unix(session.end_at)
                                      .tz(timeZone)
                                      .format("HH:mm A")}
                                  </>
                                ) : null}
                              </span>
                              <br />
                            </React.Fragment>
                          ))
                        )}
                      </>
                    ),
                  },
                  {
                    label: "Price",
                    key: "totalPricePerSession",
                    Content: ({ data }) => (
                      <>
                        $
                        {data.totalPriceOfSession.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </>
                    ),
                  },
                  {
                    label: "Actions",
                    key: "_id",
                    Content: ({ data }) => (
                      <Button
                        small
                        secondary
                        onClick={() => removeFromCart(data)}
                      >
                        Delete
                      </Button>
                    ),
                  },
                ]}
                rows={order.items}
              />
              <div className="flex justify-end mt-4">
                {paymentLoader ? (
                  <Spinner1 />
                ) : order.total ? (
                  <Button loading={initializing} small onClick={initialize}>
                    Checkout →
                  </Button>
                ) : (
                  <Button onClick={(e) => onCheckoutClick(e)}>
                    Checkout →
                  </Button>
                )}
              </div>
            </>
          ) : (
            <NoRecord msg="No item in cart" />
          )}
        </div>
      </CenterContainer>
      {/*status ? (
        <>
          {status === "retry" ? (
            <Modal
              isOpen={isRetryModalOpen}
              onClosed={() => setModalOpen(true)}
            >
              <ModalBody>
                Payment failed. Please try another payment method.
              </ModalBody>
              <ModalFooter>
                <button type="button" onClick={() => setRetryModalOpen(false)}>
                  OK
                </button>
              </ModalFooter>
            </Modal>
          ) : null}
        </>
          ) : null*/}
      {paymentIntent ? (
        <PaymentModal
          isOpen={isModalOpen}
          setOpen={setModalOpen}
          clientSecret={paymentIntent}
          payText={"Pay Now"}
        />
      ) : null}
      <Footer />
    </>
  );
};

Cart.propTypes = {
  getCartDetail: PropTypes.func.isRequired,
  RemoveFromCart: PropTypes.func.isRequired,
  payNow: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getCartDetail,
  RemoveFromCart,
  payNow,
  initializePayment,
})(withRouter(Cart));
