import DarkHero from "components/common/Hero/Dark";
import Footer from "./Footer";
import Header from "./Header";

export default function FooterLinksLayout({ title, subtitle, children }) {
  return (
    <>
      <Header light className="bg-dgray-800" />
      <DarkHero title={title} subtitle={subtitle} />
      {children}
      <Footer />
    </>
  );
}
