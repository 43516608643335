import {
  SET_ERRORS_LIST,
  REMOVE_ERRORS,
  // ADMIN_LOGIN_SUCCESS, //new admin login
  // ADMIN_LOGIN_ERROR,
  // ADMIN_LOGIN_LOADING,
  // ADMIN_LOGIN_FAIL,
  // USER_LOADING,
  // USER_LIST_UPDATED,
  // USER_FAIL,
  // USER_CHANGE_STATUS,
  // USER_CHANGE_STATUS_ERROR,
  // USER_CREATED,
  // USER_CREATE_ERROR,
  // GET_USER_BY_ID,
  // USER_UPDATED,
  // USER_UPDATE_ERROR,

  // LOGOUT_USER,
  COURSE_CATEGORY_LOADING, //course category
  COURSE_CATEGORY_LIST_UPDATED,
  COURSE_CATEGORY_FAIL,
  COURSE_CATEGORY_CREATED,
  COURSE_CATEGORY_CREATE_ERROR,
  COURSE_CATEGORY_UPDATED,
  COURSE_CATEGORY_UPDATE_ERROR,
  COURSE_CATEGORY_DELETED,
  COURSE_CATEGORY_DELETE_ERROR,
  COURSE_CATEGORY_CHANGE_STATUS,
  COURSE_CATEGORY_CHANGE_STATUS_ERROR,
  GET_COURSE_CATEGORY_BY_ID,
  COURSE_SUBCATEGORY_LOADING, //course sub SUBcategory
  COURSE_SUBCATEGORY_LIST_UPDATED,
  COURSE_SUBCATEGORY_FAIL,
  COURSE_SUBCATEGORY_CREATED,
  COURSE_SUBCATEGORY_CREATE_ERROR,
  COURSE_SUBCATEGORY_UPDATED,
  COURSE_SUBCATEGORY_UPDATE_ERROR,
  COURSE_SUBCATEGORY_DELETED,
  COURSE_SUBCATEGORY_DELETE_ERROR,
  COURSE_SUBCATEGORY_CHANGE_STATUS,
  COURSE_SUBCATEGORY_CHANGE_STATUS_ERROR,
  GET_COURSE_SUBCATEGORY_BY_ID,
  EDUCATOR_LOADING, //educator
  EDUCATOR_LIST_UPDATED,
  EDUCATOR_FAIL,
  EDUCATOR_CHANGE_STATUS,
  EDUCATOR_CHANGE_STATUS_ERROR,
  LEARNER_LOADING, //learner
  LEARNER_LIST_UPDATED,
  LEARNER_FAIL,
  LEARNER_CHANGE_STATUS,
  LEARNER_CHANGE_STATUS_ERROR,
  USER_PROFILE_MODAL_LOADING, //admin profile
  USER_PROFILE_LOADING,
  USER_PROFILE_UPDATED,
  USER_PROFILE_UPDATE_ERROR,
  USER_PROFILE_FAIL,
  USER_PROFILE_PASSWORD_UPDATED,
  USER_PROFILE_PASSWORD_UPDATE_ERROR,
  SETTING_ERROR,
  SETTING_UPDATED,
  COURSE_LOADING, //course
  COURSE_LIST_UPDATED,
  COURSE_FAIL,
  COURSE_CHANGE_STATUS,
  COURSE_CHANGE_STATUS_ERROR,
  GET_COURSE_BY_ID,
} from "actions/types";

const initialState = {
  errorType: "",
  errorList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ERRORS_LIST:
      return {
        ...state,
        ...payload,
      };
    case REMOVE_ERRORS:
      return initialState;

    //new
    //   case ADMIN_LOGIN_SUCCESS:
    //     return initialState;
    //   case ADMIN_LOGIN_ERROR:
    //     return {
    //       ...state,
    //       errorType: payload.alertType,
    //       errorList: payload.errorsList ? payload.errorsList : [],
    //     };
    //   case ADMIN_LOGIN_LOADING:
    //     return payload ? initialState : { ...state };
    //   case ADMIN_LOGIN_FAIL:
    //     return initialState;
    //   case USER_LOADING:
    case COURSE_CATEGORY_LOADING:
    case COURSE_SUBCATEGORY_LOADING:
    case EDUCATOR_LOADING:
    case LEARNER_LOADING:
    case COURSE_LOADING:
    case USER_PROFILE_MODAL_LOADING:
    case USER_PROFILE_LOADING:
      return initialState;
    //   case USER_LIST_UPDATED:
    //   case USER_CHANGE_STATUS:
    //   case USER_CREATED:
    //   case GET_USER_BY_ID:
    //   case USER_UPDATED:
    case COURSE_CATEGORY_LIST_UPDATED:
    case COURSE_CATEGORY_CREATED:
    case COURSE_CATEGORY_UPDATED:
    case COURSE_CATEGORY_DELETED:
    case COURSE_CATEGORY_CHANGE_STATUS:
    case GET_COURSE_CATEGORY_BY_ID:
    case GET_COURSE_BY_ID:
    case COURSE_SUBCATEGORY_LIST_UPDATED:
    case COURSE_SUBCATEGORY_CREATED:
    case COURSE_SUBCATEGORY_UPDATED:
    case COURSE_SUBCATEGORY_DELETED:
    case COURSE_SUBCATEGORY_CHANGE_STATUS:
    case GET_COURSE_SUBCATEGORY_BY_ID:
    case EDUCATOR_LIST_UPDATED:
    case EDUCATOR_CHANGE_STATUS:
    case LEARNER_LIST_UPDATED:
    case COURSE_LIST_UPDATED:
    case LEARNER_CHANGE_STATUS:
    case COURSE_CHANGE_STATUS:
    case USER_PROFILE_UPDATED:
    case USER_PROFILE_PASSWORD_UPDATED:
      return initialState;
    //   case USER_CHANGE_STATUS_ERROR:
    //   case USER_CREATE_ERROR:
    //   case USER_UPDATE_ERROR:
    case COURSE_CATEGORY_CREATE_ERROR:
    case COURSE_CATEGORY_UPDATE_ERROR:
    case COURSE_CATEGORY_DELETE_ERROR:
    case COURSE_CATEGORY_CHANGE_STATUS_ERROR:
    case COURSE_SUBCATEGORY_CREATE_ERROR:
    case COURSE_SUBCATEGORY_UPDATE_ERROR:
    case COURSE_SUBCATEGORY_DELETE_ERROR:
    case COURSE_SUBCATEGORY_CHANGE_STATUS_ERROR:
    case EDUCATOR_CHANGE_STATUS_ERROR:
    case LEARNER_CHANGE_STATUS_ERROR:
    case COURSE_CHANGE_STATUS_ERROR:
    case USER_PROFILE_UPDATE_ERROR:
    case USER_PROFILE_PASSWORD_UPDATE_ERROR:
    case SETTING_ERROR:
    case SETTING_UPDATED:
      return {
        errorType: payload.alertType,
        errorList: payload.errorsList ? payload.errorsList : [],
      };
    //   case USER_FAIL:
    case COURSE_CATEGORY_FAIL:
    case COURSE_SUBCATEGORY_FAIL:
    case EDUCATOR_FAIL:
    case LEARNER_FAIL:
    case COURSE_FAIL:
    case USER_PROFILE_FAIL:
      return initialState;
    //   case LOGOUT_USER:
    //     return initialState;
    default:
      return state;
  }
}
