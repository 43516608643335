import { ReactComponent as InfoCircle } from "assets/images/icons/info-circle.svg";
import Button from "components/common/form/Button";
import { resendActivation } from "actions/auth";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export default function FormError({ children, isinactive,activateEmail }) {
  let dispatch = useDispatch();
  const fecthResendactivation = async () => {
    let response = await dispatch(resendActivation(activateEmail));
    if (!response?.status) return    toast.error(<>{response.message}</>, { autoClose: 1000 });
    toast.success(<>{response.message}</>, { autoClose: 1000})
  };
  return (
    <div className="flex gap-3 items-center border border-primary-300 bg-primary-25 rounded-lg p-4 mb-8">
      <InfoCircle className="text-primary-600" />
      <div className="text-primary-700 text-sm font-medium">
        {children}
        {isinactive && (
          <Button
            type="button"
            className="text-white bg-primary-600 w-full mt-6"
            wFull
            onClick={() => fecthResendactivation()}
            return
            false
          >
            resend
          </Button>
        )}
      </div>
    </div>
  );
}
