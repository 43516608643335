import ImageDropzone from "components/common/form/ImageDropzone";
import InputError from "components/common/form/InputError";
import SelectInput from "components/common/form/SelectInput";
import Textarea from "components/common/form/Textarea";
import TextInput from "components/common/form/TextInput";
import countries from "constants/countries";
import { Field } from "formik";
import { useEffect, useState } from "react";
import CitySearchInput from "utils/CitySearchInput";
import CreateStepControls from "../Create/StepControls";
import { ReactComponent as UserLargeIcon } from "assets/images/icons/user-large.svg";
import MultiSelectInput from "components/common/form/MultiSelectInput";
import languages from "constants/languages";
import ProfilePicInput from "components/common/form/ProfilePicInput";
import getUserAvatar from "utils/getUserAvatar";
import { connect } from "react-redux";

function BecomeAHostStep1({ user, ...props }) {
  return (
    <div>
      <div className="space-y-5 pb-12 mb-12 border-b border-dgray-200">
        <div className="flex gap-5">
          <TextInput
            className="flex-1"
            name="firstName"
            label="First name"
            placeholder="Enter your first name"
          />
          <TextInput
            className="flex-1"
            name="lastName"
            label="Last name"
            placeholder="Enter your last name"
          />
        </div>
        <div className="flex gap-5">
          <TextInput
            className="flex-1"
            name="email"
            label="Email"
            placeholder="Enter your email"
          />
          <div className="flex-1" />
        </div>
        <div className="flex gap-5">
          <SelectInput
            className="flex-1"
            label="Country"
            name="country"
            options={countries.map((country) => ({
              label: country.name,
              value: country.code,
            }))}
          />
          <div className="flex-1">
            <Field name="city">
              {({ field, form }) => (
                <CitySearchInput
                  defaultValue={form.values.city}
                  label="City"
                  country={form.values.country}
                  onChange={(city) => form.setFieldValue(field.name, city.name)}
                />
              )}
            </Field>
            <InputError name="city" />
          </div>
        </div>
      </div>
      <div className="pb-12 mb-12 border-b border-dgray-200">
        <MultiSelectInput
          label="Spoken Languages"
          name="languages"
          options={languages.map(({ name }) => ({ label: name, value: name }))}
        />
      </div>
      <div className="pb-12 mb-12 border-b border-dgray-200">
        <Textarea
          name="about"
          label="About me"
          placeholder="Introduce yourself briefly."
        />
        <ProfilePicInput name="avatar" defaultSrc={getUserAvatar(user.avatar)} />
      </div>
      <CreateStepControls {...props} />
    </div>
  );
}

export default connect(({ auth }) => ({ user: auth.user }))(BecomeAHostStep1)