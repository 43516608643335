import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { create, getCourseCategories } from "actions/admin/coursesubcategory";
// import Errors from "views/Notifications/Errors";
import Errors from "views/Admin/Layout/Errors";
import Spinner from "views/Spinner";

import { REMOVE_ERRORS } from "actions/types";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const CreateCourseSubCategory = ({
  create,
  history,
  errorList,
  loading,
  getCourseCategories,
}) => {
  /**************** dispatch to remove error *********************/
  const dispatch = useDispatch();

  /********************Form data*****************/
  const [formData, setFormData] = useState({
    category_id: "",
    subcategory_name: "",
    subcategory_description: "",
  });

  /********************Expolde form data*****************/
  const { category_id, subcategory_name, subcategory_description } = formData;

  /***********************get all categories ********************/
  const [categoryList, setCategoryList] = useState([]);
  useMemo(() => {
    getCourseCategories().then((res) => {
      setCategoryList(res);
    });
  }, []);

  /********************On change event*****************/
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };

  /********************On Submit event*****************/
  const onSubmit = (e) => {
    e.preventDefault();
    create(formData, history);
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card>
              <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                <CardBody>
                  {/*<FormGroup check inline>
                <Label check>
                  <Input type="radio" name="radio1" /> Some input
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input type="radio" name="radio1"/> Some other input
                </Label>
              </FormGroup>*/}
                  <FormGroup>
                    <Label htmlFor="category_id">
                      Category <span>*</span>
                    </Label>
                    <Input
                      type="select"
                      id="category_id"
                      name="category_id"
                      maxLength="25"
                      value={category_id}
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.category_id ? true : false}
                    >
                      <option value="">Please Select</option>
                      {categoryList &&
                        categoryList.map((fieldData, index) => (
                          <option key={index} value={fieldData._id}>
                            {fieldData.name}
                          </option>
                        ))}
                    </Input>
                    <Errors current_key="category_id" key="category_id" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="subcategory_name">
                      Name<span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="subcategory_name"
                      name="subcategory_name"
                      maxLength="25"
                      value={subcategory_name}
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.subcategory_name ? true : false}
                    />
                    <Errors
                      current_key="subcategory_name"
                      key="subcategory_name"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="subcategory_description">Description</Label>
                    <Input
                      type="textarea"
                      id="subcategory_description"
                      name="subcategory_description"
                      value={subcategory_description}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/course-subcategory">
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

CreateCourseSubCategory.propTypes = {
  create: PropTypes.func.isRequired,
  coursesubcategory: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getCourseCategories: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  coursesubcategory: state.coursesubcategory,
  loading: state.coursesubcategory.loading,
  errorList: state.errors.errorList,
});

export default connect(mapStateToProps, { create, getCourseCategories })(
  CreateCourseSubCategory
);
