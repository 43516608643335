import { ErrorMessage, Field } from "formik";
import Select from "react-select";
import { Combobox } from "@headlessui/react";
import { useState } from "react";
import { object } from "prop-types";

export default function MultiSelectInput({
  name,
  className = "",
  noFormik, // this might be temporary, added cuz there are forms that use 'name' without formik
  ...props
}) {
  if (name && !noFormik) {
    return (
      <Field name={name}>
        {({ field, meta, form }) => (
          <div className={className}>
            <Input
              {...props}
              {...field}
              error={meta.error}
              touched={meta.touched}
              disabled={form.isSubmitting}
              field={field}
              meta={meta}
              form={form}
            />
            <ErrorMessage name={name}>
              {(msg) => (
                <div className="text-xs pt-2 pl-0.5 text-red-500">{msg}</div>
              )}
            </ErrorMessage>
          </div>
        )}
      </Field>
    );
  }
  return <Input {...props} className={className} />;
}

function Input({
  options = [],
  icon,
  label,
  onChange,
  small,

  field,
  meta,
  form,
  error,
  touched,
  disabled,
  className,
  value,

  ...rest
}) {
  const [selectedOpt, setSelectedOpt] = useState();

  return (
    <div className={className}>
      {label && (
        <div
          htmlFor={label}
          className="text-sm text-dgray-700 font-medium inline-block mb-1.5 w-full"
        >
          {label}
        </div>
      )}
      <Select
        value={value ? value : selectedOpt}
        options={options}
        isMulti
        styles={getCustomStyles(small)}
        onChange={(opt) => {
          form?.setFieldValue(field.name, opt);
          onChange?.(opt);
          setSelectedOpt(opt);
          return opt;
        }}
        {...rest}
      />
    </div>
  );
}

const getCustomStyles = (small) => ({
  valueContainer: (styles) => ({
    ...styles,
    padding: "6.5px 14px",
  }),
  control: (styles) => ({
    ...styles,
    border: "1px solid #D0D5DD",
    padding: "0",
    borderRadius: "8px",
    outline: "none",
    fontSize: small ? "14px" : "16px",
    boxShadow: "none",
    ":hover": {
      borderColor: "#D0D5DD",
    },
    ":focus": {
      borderColor: "#FF692E",
    },
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    color: "#101828",
    outline: "none",
    border: "none",
    fontSize: "14px",
    backgroundColor: isSelected ? "#F9FAFB" : isFocused ? "#F9FAFB" : "none",
    ":hover": {
      backgroundColor: "#F9FAFB",
    },
  }),
  input: (styles) => ({
    ...styles,
    padding: "0px",
    margin: "0px",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: small ? "14px" : "16px",
    color: "#98A2B3",
    margin: "0px",
  }),
  indicatorSeparator: (styles) => ({ display: "none" }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    borderRadius: "6px",
    border: "1px solid #D0D5DD",
    padding: "0px 4px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#344054",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#98A2B3",
    ":hover": { color: "#667085" },
  }),
});

function SelectDropdown({ options }) {
  const [selectedOpt, setSelectedOpt] = useState(options[0]);
  const [query, setQuery] = useState("");

  const filteredOpts =
    query === ""
      ? options
      : options.filter((opt) => {
          return opt.label.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox value={selectedOpt} onChange={setSelectedOpt}>
      <Combobox.Input onChange={(event) => setQuery(event.target.value)} />
      <Combobox.Options>
        {filteredOpts.map((opt) => (
          <Combobox.Option key={opt.avalue} value={opt.value}>
            {opt.label}
          </Combobox.Option>
        ))}
      </Combobox.Options>
    </Combobox>
  );
}
