import Header from "views/Front/Layout/Header";
import HomePageSignedInEvents from "./Events";
import HomePageSignedInHero from "./Hero";
import HomePageSignedInHosts from "./Hosts";
import JoinCTA from "components/common/JoinCTA";

export default function HomePageSignedIn() {
  return (
    <>
      <Header className="bg-primary-50" />
      <HomePageSignedInHero />
      <HomePageSignedInEvents />
      <HomePageSignedInHosts />
      <JoinCTA />
    </>
  );
}
