import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ErrorMessage, Field } from "formik";
import { Fragment, useEffect, useState } from "react";
import extractTextContent from "utils/extractTextContent";
import "./text-editor.scss";

export default function TextEditor({
  name,
  noFormik,
  onChange,
  disabled,
  hideError,
  maxLength,
  ...props
}) {
  if (name && !noFormik) {
    return (
      <Field name={name} as={Fragment}>
        {({ field, meta, form }) => (
          <div>
            <TextEditorEl
              {...props}
              {...field}
              name={name}
              id={name}
              onChange={(data) => {
                field?.onChange(data);
                form?.setFieldValue(name, data);
                onChange?.(data);
              }}
              onBlur={(e) => {
                form.setFieldTouched(name);
                field?.onBlur(e);
              }}
              error={meta.error}
              disabled={form.isSubmitting || disabled}
              maxLength={maxLength}
            />
            {!hideError && (
              <ErrorMessage name={name}>
                {(msg) => (
                  <div className="text-xs pt-2 pl-0.5 text-red-500">{msg}</div>
                )}
              </ErrorMessage>
            )}
          </div>
        )}
      </Field>
    );
  }
  return (
    <TextEditorEl
      {...props}
      onChange={onChange}
      disabled={disabled}
      maxLength={maxLength}
      name={name}
    />
  );
}

function TextEditorEl({
  placeholder,
  onChange,
  onBlur,
  label,
  className = "",
  maxLength,
  value,
  ...rest
}) {
  const [data, setData] = useState("");
  const [textContent, setTextContent] = useState("");

  useEffect(() => {
    if (value) {
      setData(value);
    }
  }, [value]);

  return (
    <div>
      <CKEditor
        {...rest}
        data={data}
        config={{
          // fontSize: { options: ["tiny", "default", "big"] },
          toolbar: [
            "heading",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "fontSize",
            "undo",
            "redo",
          ],
        }}
        editor={ClassicEditor}
        onReady={(editor) => {
          editor.editing.view.change((writer) => {
            writer.setStyle(
              "min-height",
              "140px",
              editor.editing.view.document.getRoot()
            );
            writer.setStyle(
              "max-height",
              "400px",
              editor.editing.view.document.getRoot()
            );
          });
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          const textContent = extractTextContent(data);
          if (!maxLength) {
            onChange?.(data);
            setTextContent(textContent);
            setData(data);
          } else if (maxLength - textContent.length >= 0) {
            onChange?.(data);
            setData(data);
            setTextContent(textContent);
          }
        }}
        onBlur={(event, editor) => {
          onBlur?.(event);
        }}
      />
      {maxLength && (
        <div className="text-sm text-dgray-500">
          {maxLength - textContent.length} characters left
        </div>
      )}
    </div>
  );
}
