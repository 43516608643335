import { useFormikContext } from "formik";
import { useState } from "react";
import { connect } from "react-redux";
import { getCategoryImage } from "utils/getEventImage";
import Step2ImageDropzone from "./ImageDropzone";

function Step2ProgramImagery({ name = "image", categories }) {
  const { values, setFieldValue } = useFormikContext();
  const [willUpload, setWillUpload] = useState(false);
  return (
    <div className="grid grid-cols-7 xl:grid-cols-5 gap-16 lg:gap-8 md:gap-5 mt-5">
      <div className="col-span-2 lg:col-span-5 text-sm">
        <div className="text-dgray-700 font-medium">Program Imagery</div>
        <p className="text-dgray-500 mb-3">
          Generated automatically by DDN. You may prefer to upload an image by
          yourself.
        </p>
        <div>
          <button
            type="button"
            className={`font-medium ${
              willUpload ? "text-dgray-300" : "text-primary-700"
            }`}
            onClick={() => {
              console.log(values.editId,"===>")
              setWillUpload((w) => !w);
              setFieldValue(name, null);
            }}
          >
            Upload image
          </button>{" "}
          <span className="italic">(optional)</span>
        </div>
      </div>

      <div className="col-span-3 md:col-span-5">
     
        {willUpload && [null,undefined,""].includes(values.editId)? (
          
          <Step2ImageDropzone />
        ) : (
         
          <div className="h-64">
            <img
              src={
                values.image && typeof values.image === "string"
                  ? `${process.env.REACT_APP_MEDIA_URL}${values.image}`
                  : getCategoryImage(
                      values.associatedProgram || values.category,
                      categories
                    )
              }
              className="w-full h-full object-cover rounded-lg"
              alt=""
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default connect((state) => ({ categories: state.categories }))(
  Step2ProgramImagery
);
