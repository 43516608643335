import { Dialog, Transition } from "@headlessui/react";
import { getExpertiseList } from "actions/front/educator/courses";
import {
  currentUser,
  getConfigurations,
  update,
  uploadProfileImage,
} from "actions/front/myaccount";
import { REMOVE_ERRORS } from "actions/types";
import { ReactComponent as Close } from "assets/images/icons/close-icon.svg";
import { ReactComponent as InfoCircle } from "assets/images/icons/info-circle.svg";
import CenterContainer from "components/common/Container/Center";
import Button from "components/common/form/Button";
import ImageDropzone from "components/common/form/ImageDropzone";
import MultiSelectInput from "components/common/form/MultiSelectInput";
import ProfilePicInput from "components/common/form/ProfilePicInput";
import SelectInput from "components/common/form/SelectInput";
import TextInput from "components/common/form/TextInput";
import ImageCropper from "components/common/ImageCropper";
import Pill from "components/common/Pill";
import countries from "constants/countries";
import { Field, Form, Formik, useFormikContext } from "formik";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Spinner } from "reactstrap";
import Swal from "sweetalert2";
import CitySearchInput from "utils/CitySearchInput";
import getUserAvatar from "utils/getUserAvatar";
import { hostManageMyAccountSchema } from "utils/schemas/manageMyAccount";
import IconCircle from "../HomePage/IconCircle";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { languagesExtended } from "../Utils/LanguageSelector";

const HostMyAccount = ({
  getConfigurations,
  update,
  currentUser,
  uploadProfileImage,
  user,
  location,
  errorList,
  isAuthenticated,
  authLoading,
  onClosed,
}) => {
  /**************** dispatch to remove error *********************/
  const dispatch = useDispatch();
  const history = useHistory();

  /**************** remove errors when first loaded *********************/
  useMemo(() => {
    dispatch({ type: REMOVE_ERRORS });
  }, []);

  /********************Form data*****************/

  const [initialFormData, setInitialFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    description: "",
    languages: [],
    avatar: "",
    expertise: [],
  });
  const [formData, setFormData] = useState(initialFormData);
  const [selectedAreaOfIntrest, setSelectedAreaOfIntrest] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [expertiseSkills, setExpertiseSkills] = useState([]);
  const [file, setFile] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [formError, setFormError] = useState(null);
  const [isOpenTier, setIsOpenTier] = useState(false);
  const {
    email,
    first_name,
    last_name,
    description,
    languages,
    avatar,
    expertise,
  } = formData;

  const [addressFormData, setaddressFormData] = useState({
    city: "",
    country: "",
    state: "",
  });
  const { city, country, state } = addressFormData;

  /********************get logged in user detail and configrations data*****************/
  useEffect(() => {
    currentUser();
    getConfigurations().then((data) => {
      var config_obj = [];
      data.response.map((configuration, i) => {
        config_obj[configuration.config_key] = configuration.config_value;
      });
    });
    getExpertiseList().then((res) => {
      setExpertiseSkills(
        res.data.map((skill) => ({
          label: skill,
          value: skill,
        }))
      );
    });
  }, []);

  /********************set user data to local state*****************/
  useMemo(() => {
    setLoading(true);
    if (user != null) {
      let languageObj = [];
      let expertiseObj = [];
      user.languages?.map((value) =>
        languageObj.push({
          value: value,
          label: value,
        })
      );
      user.expertise_list?.map((value) =>
        expertiseObj.push({
          value: value,
          label: value,
        })
      );

      let updatedData = {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        username: user.username,
        avatar: user.avatar,
        description: typeof user === "undefined" ? "" : user.description ?? "",
        timezone: user.timezone,
        languages: languageObj,
        expertise: expertiseObj,
      };
      setInitialFormData(updatedData);
      setFormData(updatedData);
      setaddressFormData({
        city: user.city,
        state: user.state,
        country: user.country,
      });
      if (user.languages) {
        setSelectedLanguages(user.languages);
      }

      if (typeof user !== "undefined" && user.languages) {
        let tempSelectedLanguage = [];
        user.languages.map((value) =>
          tempSelectedLanguage.push({
            value: value,
            label: value,
          })
        );
        setSelectedLanguages(tempSelectedLanguage);
      }
    }
    setLoading(false);
  }, [user]);

  // useMemo(() => {
  //   setFormData(initialFormData);
  // }, [initialFormData]);

  /********************On change event*****************/
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setIsSubmit(false);
    dispatch({ type: REMOVE_ERRORS });
  };

  const setGMapPlace = (location) => {
    const addresses = location.address_components;
    let city = "";
    let state = "";
    let country = "";

    addresses.forEach((e) => {
      switch (e.types[0]) {
        case "locality":
        case "sublocality_level_1":
        case "postal_town":
          city = e.long_name;
          break;
        case "administrative_area_level_1":
          state = e.long_name;
          break;
        case "country":
          country = e.long_name;
          break;
      }
    });
    setaddressFormData({
      ...addressFormData,
      city,
      state,
      country,
    });
  };

  const pageOptions = [
    { label: "Edit My Profile", value: "edit" },
    { label: "Account Settings", value: "settings" },
  ];

  const formikCtx = useFormikContext();

  const { cropperProps, show } = useImageCropper({
    src: file && URL.createObjectURL(file),
    onComplete: setFile,
    aspect: 2 / 1,
  });

  useEffect(() => {
    formikCtx?.setFieldValue("avatar", file);
  }, [file]);

  const onDrop = (acceptedFiles) => {
    setFormData({ ...formData, avatar: acceptedFiles[0] });
    setFile(acceptedFiles[0]);
    show();
  };

  const languagesList = languagesExtended.map((languageItem) => ({
    label: languageItem.name,
    value: languageItem.name,
  }));

  /********************On Submit event*****************/
  const onProfileSubmit = (values) => {
    setIsSubmit(true);
    var mergedData = {
      ...formData,
      ...addressFormData,
      ...values,
      languages: values.languages.map((item) => item.value),
      expertise: values.expertise?.map((item) => item.value),
    };
    update(mergedData).then((res) => {
      window.scrollTo(0, 0);
      if (res.status) {
        Swal.fire("", res.message, "success");
        setIsSubmit(false);
      }
      setLoading(false);
    });
  };

  const onUpdate = (avatar) => {
    let image_url = URL.createObjectURL(avatar);
    uploadProfileImage({ avatar, image: image_url }).then((res) => {
      if (res.status) {
        Swal.fire("", res.message, "success");
      }
    });
  };

  const selectedOptions = (selected) => {
    if (selected.value == "settings") history.push("/settings");
  };

  const reset = () => {
    setIsOpenTier(false);
    onClosed();
  };

  function openModal() {
    setIsOpenTier(true);
  }

  function closeModal() {
    setIsOpenTier(false);
  }

  return (
    <>
      <Header />
      <CenterContainer>
        {loading ? (
          <Spinner />
        ) : (
          <div className="md:text-left pt-12 pb-12 xl:px-0 md:w-full mx-auto">
            <p className="text-dgray-900 mb-24 md:mb-8 text-5xl md:text-2xl text-center md:text-left">
              Manage my account
            </p>
            <div className="flex flex-row md:flex-col gap-4">
              <div className="flex flex-col">
                <div className="bg-white rounded-lg shadow border border-dgray-200 w-72 md:w-full p-4">
                  <div className="text-dgray-500 text-sm">
                    You are a <>{user?.userType === 3 ? "host" : "student"}</>{" "}
                    since{" "}
                    <Moment format="MMMM, YYYY">
                      {Number(user?.created_at)}
                    </Moment>
                  </div>
                  <div className="flex justify-between items-center mb-4 mt-4">
                    <div className="flex gap-2.5 justify-center items-center">
                      <img
                        src={getUserAvatar(user?.avatar)}
                        alt=""
                        className="rounded-full w-10 h-10"
                      />
                      <div>
                        <div className="text-sm font-medium">
                          {user?.full_name}
                        </div>
                      </div>
                    </div>
                    <button
                      className="justify-center items-center"
                      onClick={openModal}
                    >
                      <Pill text="Tier 3" color="indigo" className="text-sm" />
                    </button>
                  </div>
                </div>
                <SelectInput
                  options={pageOptions}
                  className="hidden md:block mt-4 text-sm"
                  defaultValue={pageOptions[0]}
                  onChange={selectedOptions}
                />
                <ul className="mt-4 space-y-1 md:hidden">
                  <li
                    className={`flex w-full justify-between cursor-pointer items-center rounded
                  ${
                    location.pathname === "/profile"
                      ? "text-primary-700 bg-primary-50"
                      : "text-dgray-700 hover:text-gray-500"
                  }
                `}
                  >
                    <Link
                      to="/profile"
                      className="flex items-center py-2 px-3 w-full"
                    >
                      <div
                        className={
                          location.pathname === "/profile"
                            ? "text-primary-600"
                            : "text-dgray-500"
                        }
                      ></div>
                      <span className="font-medium ml-2 md:ml-0">
                        Edit My Profile
                      </span>
                    </Link>
                  </li>
                  <li
                    className={`flex w-full justify-between cursor-pointer items-center rounded
                  ${
                    location.pathname === "/settings"
                      ? "text-primary-700 bg-primary-50"
                      : "text-dgray-700 hover:text-gray-500"
                  }
                `}
                  >
                    <Link
                      to="/settings"
                      className="flex items-center py-2 px-3 w-full"
                    >
                      <div
                        className={
                          location.pathname === "/settings"
                            ? "text-primary-600"
                            : "text-dgray-500"
                        }
                      ></div>
                      <span className="font-medium ml-2 md:ml-0">
                        Account Settings
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="w-full px-16 md:px-0">
                <Formik
                  initialValues={{
                    first_name: first_name,
                    last_name: last_name,
                    country: country,
                    city: city,
                    description: description,
                    languages: languages,
                    avatar: avatar,
                    expertise: expertise,
                  }}
                  validationSchema={hostManageMyAccountSchema}
                  onSubmit={(e) => onProfileSubmit(e)}
                  enableReinitialize
                >
                  <Form>
                    <div className="grid grid-cols-2 md:grid-cols-1 gap-5 w-full">
                      <TextInput
                        type="text"
                        name="first_name"
                        placeholder="Enter your first name"
                        label="First Name"
                        className="w-full"
                      />
                      <TextInput
                        type="text"
                        name="last_name"
                        placeholder="Enter your last name"
                        label="Last Name"
                        className="w-full"
                      />
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-1 gap-5 w-full pt-4">
                      <SelectInput
                        label="Country"
                        name="country"
                        options={countries.map((country) => ({
                          label: country.name,
                          value: country.code,
                        }))}
                        value={country}
                        className="flex-1 w-full"
                      />
                      <Field name="city">
                        {({ field, form }) => (
                          <CitySearchInput
                            label="City"
                            country={countries.find(country => country.name === form.values.country)?.code}
                            onChange={(city) =>
                              form.setFieldValue(field.name, city.name)
                            }
                            defaultValue={city}
                          />
                        )}
                      </Field>
                    </div>
                    <ProfilePicInput name="avatar" defaultSrc={getUserAvatar(avatar)} onUpdate={onUpdate} />
                    <div className="pt-5 mt-8 border-t">
                      <TextInput
                        type="text"
                        name="description"
                        placeholder="Enter information about yourself"
                        label="About me"
                        className="w-full"
                        multiline={true}
                        rows={8}
                      />
                    </div>
                    <div className="border-t mt-8 w-full pt-5">
                      <label className="text-sm text-dgray-700 font-medium">
                        Spoken Languages
                      </label>
                      <MultiSelectInput
                        value={languages}
                        isMulti
                        name="languages"
                        options={languagesList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                    <div className="border-t mt-8 w-full pt-5">
                      <label className="text-sm text-dgray-700 font-medium">
                        Expertise
                      </label>
                      <MultiSelectInput
                        value={expertise}
                        isMulti
                        name="expertise"
                        options={expertiseSkills}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                    <div className="flex justify-end border-t mt-8 pt-5">
                      <div className="space-x-3">
                        <Button
                          type="button"
                          className="text-base mt-3 bg-white text-dgray-700 px-4 mr-2"
                          disabled={isSubmit}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="text-base text-white mt-3 bg-primary-600 px-4"
                          disabled={isSubmit}
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        )}
      </CenterContainer>
      <Footer />

      <Transition appear show={isOpenTier} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={reset}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-10"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-10"
          >
            <div className="fixed inset-0 bg-black bg-dgray-700/70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <button
                    className="focus:outline-none absolute"
                    onClick={closeModal}
                    style={{ top: 16, right: 16 }}
                  >
                    <Close
                      style={{ width: "15px" }}
                      className="text-dgray-500"
                    />
                  </button>

                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium text-center items-center flex flex-col leading-6 text-dgray-900"
                  >
                    <div className="flex flex-col mt-4 items-center">
                      <IconCircle color="green">
                        <InfoCircle />
                      </IconCircle>
                      <div className="text-dgray-900 text-2xl font-semibold mt-4">
                        Host tiers
                      </div>
                    </div>
                  </Dialog.Title>
                  <div className="flex flex-col space-y-3">
                    <div className="text-lg text-dgray-500 mt-2 p-2">
                      DDN pays our host based on proficiency level. We have
                      three different tiers for hosts:{" "}
                    </div>
                    <div className="flex items-center p-2">
                      <Pill text="Tier 1" color="blue" className="text-sm" />
                      <div className="text-base text-sky-700 ml-4">
                        Compensated at $5/event
                      </div>
                    </div>
                    <div className="text-sm text-dgray-500">
                      Good English communication skill, and host is capable to
                      drive a conversion in a group.
                    </div>

                    <div className="flex items-center p-2">
                      <Pill text="Tier 2" color="pink" className="text-sm" />
                      <div className="text-base text-pink-700 ml-4">
                        Compensated at $8/event
                      </div>
                    </div>
                    <div className="text-sm text-dgray-500">
                      Advanced – Excellent communication skill, and host is able
                      to teach professional jargons and native expressions.
                    </div>

                    <div className="flex items-center p-2">
                      <Pill text="Tier 3" color="green" className="text-sm" />
                      <div className="text-base text-green-700 ml-4">
                        Compensated at $10/event
                      </div>
                    </div>
                    <div className="text-sm text-dgray-500">
                      English Native Speaker who is passion about helping ESL
                      learners to master the skill.
                    </div>
                    <div className="flex gap-3 justify-center">
                      <Button
                        type="button"
                        className="text-base text-white mt-3 bg-primary-600 w-1/2"
                        onClick={closeModal}
                      >
                        Ok
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

function useImageCropper({ src, onComplete, aspect }) {
  const [isOpen, setIsVisible] = useState(false);
  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  return {
    show,
    cropperProps: { hide, isOpen, src, onComplete, aspect },
  };
}

const mapStateToProps = (state) => ({
  errorList: state.errors,
  profile_loading: state.myaccount.loading,
  isAuthenticated: state.auth.isAuthenticated,
  authLoading: state.auth.loading,
});

export default connect(mapStateToProps, {
  update,
  currentUser,
  getConfigurations,
  uploadProfileImage,
})(withRouter(HostMyAccount));
