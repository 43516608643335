import { googleSignIn, register, setPasswordError,resendActivation } from "actions/auth";
import designScreen from "assets/images/design-screen.png";
import { ReactComponent as GoogleLogo } from "assets/images/google.svg";
import ddnMiniLogo from "assets/images/icons/ddn-mini-logo.svg";
import Button from "components/common/form/Button";
import Checkbox from "components/common/form/Checkbox";
import FormError from "components/common/form/FormError";
import NewPasswordInput from "components/common/form/NewPasswordInput";
import SelectInput from "components/common/form/SelectInput";
import TextInput from "components/common/form/TextInput";
import countries from "constants/countries";
import CitySearchInput from "utils/CitySearchInput";
import registerSchema from "utils/schemas/register";
import { getCurrentTimeZone } from "utils/time";
import Header from "views/Front/Layout/Header";
import { Field, Form, Formik } from "formik";
import * as moment from "moment-timezone";
import PropTypes from "prop-types";
import { useState } from "react";
import GoogleLogin from "react-google-login";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SuccessScreen from "./SuccessScreen";
const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

const Register = ({ register, googleSignIn, error, match,resendActivation }) => {
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [readTerms, setReadTerms] = useState("");
  const [email,setEmail] = useState(false);

  const toggleReadTerms = () => setReadTerms(!readTerms);

  const initialFormData = {
    first_name: "",
    last_name: "",
    password: "",
    email: "",
    user_type: 2,
    google_user_type: 2,
    timezone: getCurrentTimeZone(),
  };

  const initialAddressData = {
    city: "",
    country: "",
    state: "",
  };

  const [addressFormData, setaddressFormData] = useState(initialAddressData);
  const { city, country, state } = addressFormData;

  const setGMapPlace = (location, isErrorReset = false) => {
    const addresses = location.address_components;
    let city = "";
    let state = "";
    let country = "";

    addresses.forEach((e) => {
      switch (e.types[0]) {
        case "locality":
        case "sublocality_level_1":
        case "postal_town":
        case "colloquial_area":
          city = e.long_name;
          break;
        case "administrative_area_level_1":
          state = e.long_name;
          break;
        case "country":
          country = e.long_name;
          break;
        default:
          break;
      }
    });
    // if (isErrorReset) dispatch({ type: REMOVE_ERRORS });
    setaddressFormData({
      ...addressFormData,
      city,
      state,
      country,
    });
  };

  /********************google response callback*****************/
  const onGoogleResponse = (res) => {
    googleSignIn(
      res.profileObj,
      initialFormData.google_user_type,
      moment.tz.guess(true)
    );
  };
  const fecthResendactivation = async ()=> {
    let response = await resendActivation(email)
    console.log("================resendactivation",response);
  }
  const onSubmit = async ({
    first_name,
    last_name,
    email,
    country,
    city,
    password,
  }) => {
    setLoading(true);
    register({
      ...initialFormData,
      first_name,
      last_name,
      email,
      password,
      city,
      state,
      country: countries.find(c => c.code === country).name,
    }).then((res) => {
      if (res.status) {
        setEmail(email)
        setSuccess(true);
        setaddressFormData(initialAddressData);
      }
      setLoading(false);
      // window.scrollTo(0, 0);
    });
  };

  return (
    <>
      <Header dark />
      {initialFormData.email}
      {isSuccess ? (
        <SuccessScreen
          title="Check your email"
          description="We sent a verification link to your given email address."
        >
          <div className="text-sm text-center text-dgray-500">
            Didn’t receive the email?{" "}
            <Button className="text-primary-700 font-medium" onClick={() => fecthResendactivation()}>
              Click to resend
            </Button>
          </div>
        </SuccessScreen>
      ) : (
        <div className="flex md:flex-col pb-8">
          <div className="w-1/2 lg:w-full flex flex-col min-h-screen justify-center items-center lg:max-w-lg px-32 xl:px-20 lg:px-16">
            <div className="md:mt-16 mt-0 hidden md:block">
              <img
                src={ddnMiniLogo}
                alt="Design Screen"
                className="object-contain h-full"
              />
            </div>
            <div className="text-center mt-32 md:mt-5 lg:mt-12 mb-8">
              <div className="text-4xl md:text-2xl text-dgray-900">
                Create Your Account
              </div>
              <div className="text-base text-dgray-500">
                Signup to DDN for free.
              </div>
            </div>
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                country: "",
                city: "",
                password: "",
                passwordRepeat: "",
              }}
              validationSchema={registerSchema}
              onSubmit={onSubmit}
            >
              <Form>
                {error &&
                  error.errorsList?.map((err) => <FormError>{err}</FormError>)}
                <div className="space-y-5">
                  <div className="flex md:flex-col justify-between gap-4 md:gap-5">
                    <TextInput
                      type="text"
                      name="first_name"
                      placeholder="Enter your first name"
                      label="First Name*"
                      wFull
                    />
                    <TextInput
                      type="text"
                      name="last_name"
                      placeholder="Enter your last name"
                      label="Last Name*"
                      wFull
                    />
                  </div>
                  <TextInput
                    type="text"
                    name="email"
                    placeholder="Enter your email"
                    label="Email*"
                    wFull
                  />
                  <SelectInput
                    label="Country*"
                    name="country"
                    options={countries.map((country) => ({
                      label: country.name,
                      value: country.code,
                    }))}
                  />
                  <Field name="city">
                    {({ field, form }) => (
                      <CitySearchInput
                        label="City*"
                        country={form.values.country}
                        onChange={(city) =>
                          form.setFieldValue(field.name, city.name)
                        }
                      />
                    )}
                  </Field>
                  <NewPasswordInput />
                </div>
                <div className="flex justify-between mt-5 text-sm">
                  <label>
                    <Checkbox
                      name="remember"
                      label={
                        <>
                          By signing up, you agree to DingDingNow’s{" "}
                          <Link to="/terms" className="text-primary-600">
                            Terms and Conditions
                          </Link>
                        </>
                      }
                      onChange={toggleReadTerms}
                    />
                  </label>
                </div>
                <Button
                  type="submit"
                  className="text-base text-white mt-3 bg-primary-600"
                  wFull
                  disabled={!readTerms}
                  loading={isLoading}
                >
                  Get Started
                </Button>

                {/* <div className="flex items-center mt-3">
                  <div
                    style={{ flex: 1, height: "1px" }}
                    className="bg-dgray-200"
                  />
                  <label className="text-xs text-dgray-500 mx-8">OR</label>
                  <div
                    style={{ flex: 1, height: "1px" }}
                    className="bg-dgray-200"
                  />
                </div> */}
                {/* <GoogleLogin
                  clientId={REACT_APP_GOOGLE_CLIENT_ID}
                  onSuccess={(e) => onGoogleResponse(e)}
                  onFailure={(e) => onGoogleResponse(e)}
                  render={(renderProps) => (
                    <Button
                      onClick={renderProps.onClick}
                      type="button"
                      className="text-base mt-3 !p-2.5 border opacity- w-full"
                    >
                      <div className="flex items-center justify-center">
                        <GoogleLogo className="w-6 h-6" />
                        <span className="ml-3">Sign up with Google</span>
                      </div>
                    </Button>
                  )}
                /> */}

                <div className="text-center mt-3">
                  <label className="text-dgray-500 text-sm">
                    Already have an account?{" "}
                    <Link to="/login" className="text-primary-700">
                      Log in
                    </Link>
                  </label>
                </div>
              </Form>
            </Formik>
          </div>
          <div className="md:hidden w-1/2 flex bg-primary-50 rounded-l-[80px] pl-20">
            <div className="flex items-center w-full justify-end overflow-hidden">
              <div className="border-4 border-r-0 rounded-l-2xl border-dgray-1000 overflow-hidden lg:-mr-20">
                <img
                  src={designScreen}
                  alt="Design Screen"
                  className="object-contain h-full"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Register.propTypes = {
  setPasswordError: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

export default connect(
  (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    error: state.auth.error,
  }),
  { register, setPasswordError, googleSignIn,resendActivation}
)(Register);
