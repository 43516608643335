import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const Sorting = ({ filters, setFilters }) => {
  const SORTING = {
    POPULAR: 0,
    ALPHABET_ASC: 1,
    ALPHABET_DESC: 2,
    DATE_DESC: 3,
    DATE_ASC: 4,
    RATING_LOW_HIGH: 5,
    RATING_HIGH_LOW: 6,
  };
  const [sortingFilter, setSortingFilter] = useState("");

  const onChangeSorting = (e) => {
    setSortingFilter(e.target.value);
  };
  useEffect(() => {
    let sortBy = "popular";
    let orderDirection = "desc";
    if (sortingFilter === SORTING.POPULAR) {
      sortBy = "popular";
      orderDirection = "desc";
    } else if (sortingFilter == SORTING.ALPHABET_ASC) {
      sortBy = "name";
      orderDirection = "asc";
    } else if (sortingFilter == SORTING.ALPHABET_DESC) {
      sortBy = "name";
      orderDirection = "desc";
    } else if (sortingFilter == SORTING.DATE_DESC) {
      sortBy = "created_at";
      orderDirection = "desc";
    } else if (sortingFilter == SORTING.DATE_ASC) {
      sortBy = "created_at";
      orderDirection = "asc";
    } else if (sortingFilter == SORTING.RATING_LOW_HIGH) {
      sortBy = "avg_rating";
      orderDirection = "asc";
    } else if (sortingFilter == SORTING.RATING_HIGH_LOW) {
      sortBy = "avg_rating";
      orderDirection = "desc";
    }
    setFilters({ ...filters, orderBy: sortBy, ascending: orderDirection });
  }, [sortingFilter]);
  return (
    <div className="row">
      <div className="col-md-4"></div>
      <div className="col-md-4 offset-md-4">
        <div className="form-group selectGroup">
          <select
            name="select-profession"
            className="form-control custom-select"
            value={sortingFilter}
            onChange={(e) => onChangeSorting(e)}
          >
            <option value="">Sort By</option>
            <option value={SORTING.POPULAR}>Popularity</option>
            <option value={SORTING.ALPHABET_ASC}>A-Z</option>
            <option value={SORTING.ALPHABET_DESC}>Z-A</option>
            <option value={SORTING.DATE_DESC}>Date : Newest First</option>
            <option value={SORTING.DATE_ASC}>Date : Oldest First</option>
            <option value={SORTING.RATING_LOW_HIGH}>
              Rating : Low to High
            </option>
            <option value={SORTING.RATING_HIGH_LOW}>
              Rating : High to Low{" "}
            </option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Sorting;
