import React from 'react'

const SessionCancelled = ({ details: { course_name } }) => {
    return (
        <>
          <div className="col-md-12">
            <div className="notification-box box">
              <div className="space-between">
                <a href="#" className="d-flex">
                  <i className="fa fa-close"></i>
                  <strong>Session Cancelled</strong>
                </a>
              </div>
              <p>
                Your {course_name} {" "} is Cancelled by one user.
              </p>
            </div>
          </div>
    </>
    )
}

export default SessionCancelled;
