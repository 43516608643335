import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import defaultImage from "assets/images/bg/bg51.jpg";
import SessionSchedule from "./SessionSchedule";
import NewCourseAdded from "./NewCourseAdded";
import ClassCancled from "./ClassCancled";
import { markNotificationRead, getNotification } from "actions/front/common/notification";
import { USER_TYPE } from "constants/index";
import * as moment from 'moment-timezone';
import Pagination from "views/Front/Pagination";
import { RECORD_PER_PAGE } from "constants/index";
import SessionCancelled from "./SessionCancelled";
import SessionReschedule from "./SessionReschedule";
import { getCurrentTimeZone } from "utils/time";

const Notification = ({ markNotificationRead, notificationDetails, getNotification, user }) => {

  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState({
    limit: RECORD_PER_PAGE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    custom_filters: "",
  });

  const paginate = (page_no) => {
    setFilters({ ...filters, page: page_no });
  };

  let timeZone = getCurrentTimeZone(user);

  useEffect(() => {
    if (user) {
      markNotificationRead().then((res => {
        if (res.status) {
          getNotification(filters).then((res) => {
            setTotalRecords(res.data.metaData[0].totalRecord)
          });
        }
      }));
    }
  }, [user, filters]);

  return (
    <>
      <div className="col-lg-9" id="course-details">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            <Link to="/dashboard">Back to Calendar</Link>
              <h2>Notifications</h2>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="row">
                {notificationDetails && notificationDetails.map((item) => {
                  switch (item.category) {
                    case "Session Scheduled":
                        return <SessionSchedule details={item} timeZone={timeZone} />
                    case "Session ReScheduled":
                        return <SessionReschedule details={item} timeZone={timeZone} />
                    case "Session Cancelled":
                        return <SessionCancelled details={item} timeZone={timeZone} />
                    case "New Course Added":
                        return <NewCourseAdded details={item} />
                    case "Class Cancelled":
                        return <ClassCancled details={item} />
                  }
                })}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Pagination
                    totalRecords={totalRecords}
                    recordPerPage={RECORD_PER_PAGE}
                    paginate={paginate}
                    currentPage={filters.page}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  notificationDetails: state.notification.notificationDetails,
});

export default connect(mapStateToProps, {
  markNotificationRead,
  getNotification,
})(withRouter(Notification));
