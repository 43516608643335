import React, { useState, useMemo, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import MyaccountSidebar from "../Snippets/MyaccountSidebar";
import Errors from "views/Notifications/Errors";
import Alert from "views/Notifications/Alert";
import { REMOVE_ERRORS } from "actions/types";

import { updatePassword,currentUser } from "actions/front/myaccount";


const ChangePassword = ({ errorList, user,updatePassword,currentUser }) => {
  /**************** dispatch to remove error *********************/
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const { current_password, new_password, confirm_password } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };

    /********************get logged in user detail and configrations data*****************/
    useEffect(() => {
      currentUser();

    }, []);


  const [isSubmit, setIsSubmit] = useState(false);

  const onChangePasswordSubmit = (e) => {
    setIsSubmit(true);
    e.preventDefault();
    updatePassword(formData).then((res) => {
      window.scrollTo(0, 0);
      setIsSubmit(false);
    });
  };

  return (
    <div className="col-lg-9">
      <div className="row">
        <div className="col-md-12">
          <form className="card" onSubmit={(e) => onChangePasswordSubmit(e)}>
            <div className="card-header profile-head">
              <h3 className="card-title">Change Password</h3>
            </div>
            <div className="card-body">
              {(user != null && !user.google_id) && (
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Current Password <span className="text-danger">*</span>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="current_password"
                        name="current_password"
                        maxLength="30"
                        value={current_password}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.current_password ? true : false}
                      />
                      <Errors
                        current_key="current_password"
                        key="current_password"
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      New Password <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      id="new_password"
                      name="new_password"
                      maxLength="30"
                      value={new_password}
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.new_password ? true : false}
                    />
                    <Errors current_key="new_password" key="new_password" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Confirm Password <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      id="confirm_password"
                      name="confirm_password"
                      maxLength="30"
                      value={confirm_password}
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.confirm_password ? true : false}
                    />
                    <Errors
                      current_key="confirm_password"
                      key="confirm_password"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3 mt-4 text-right">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmit}
                >
                  Update Password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  user: state.myaccount.user,
});

export default connect(mapStateToProps, {
  updatePassword,
  currentUser
})(withRouter(ChangePassword));
