import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CKEditor from "ckeditor4-react";

import Spinner from "views/Spinner";
import slugify from "react-slugify";

import { create, cancelSave } from "actions/admin/cms";
import Errors from "views/Notifications/Errors";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";

const CreateCMS = ({
  create,
  cancelSave,
  history,
  errorList,
  loading
}) => {
  const [formData, setFormData] = useState({
    page_title: "",
    page_header: "",
    slug: "",
    description: "",
    meta_description: "",
    thumbnail: "",
    selectedFile: "",
    image: ""
  });

  const {
    page_title,
    page_header,
    slug,
    description,
    meta_description,
    image
  } = formData;

  const onChange = e => {
    if (e.target.name === "page_title") {
      let new_slug = slugify(e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        slug: new_slug
      });
    } else if (e.target.name === "thumbnail") {
      let image_file = e.target.files[0];

      let image_url = URL.createObjectURL(image_file);
      // console.log(image_url);

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        selectedFile: image_file,
        image: image_url
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handelDescriptionChange = event => {
    setFormData({ ...formData, description: event.editor.getData() });
  };

  const onSubmit = e => {
    e.preventDefault();
    create(formData, history);
  };

  const onClickHandel = e => {
    e.preventDefault();
    cancelSave(history);
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card>
              <Form
                className="form-horizontal"
                onSubmit={e => onSubmit(e)}
                encType="multipart/form-data"
              >
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="slug">
                      CMS Slug <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="slug"
                      name="slug"
                      maxLength="100"
                      value={slug}
                      required
                      readOnly
                      // onChange={e => onChange(e)}
                      invalid={errorList.slug ? true : false}
                    />
                    <Errors current_key="slug" key="slug" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="page_title">
                      Page Title <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="page_title"
                      name="page_title"
                      maxLength="100"
                      value={page_title}
                      required
                      onChange={e => onChange(e)}
                      invalid={errorList.page_title ? true : false}
                    />
                    <Errors current_key="page_title" key="page_title" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="page_header">
                      Page Header <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="page_header"
                      name="page_header"
                      maxLength="100"
                      value={page_header}
                      required
                      onChange={e => onChange(e)}
                      invalid={errorList.page_header ? true : false}
                    />
                    <Errors current_key="page_header" key="page_header" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="meta_description">Meta Description</Label>
                    <Input
                      type="textarea"
                      id="meta_description"
                      name="meta_description"
                      value={meta_description}
                      onChange={e => onChange(e)}
                      invalid={errorList.meta_description ? true : false}
                    />
                    <Errors
                      current_key="meta_description"
                      key="meta_description"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="thumbnail">
                      Thumbnail<span>*</span>
                    </Label>
                    <Input
                      type="file"
                      id="thumbnail"
                      name="thumbnail"
                      onChange={e => onChange(e)}
                      multiple
                      invalid={errorList.thumbnail ? true : false}
                    />
                    <img src={image} className="preview-img" alt="" />
                    {/* {image_preview} */}
                    <Errors current_key="thumbnail" key="thumbnail" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="description">
                      Body <span>*</span>
                    </Label>
                    {
                    /**
                     * CKEditor element conflict bug
                     * Fix: onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }
                     * Issue: https://github.com/ckeditor/ckeditor4-react/issues/57
                     */
                    }
                    <CKEditor
                      data={description}
                      id="description"
                      name="description"
                      config={{
                        height: 100,
                        toolbar: [
                          ["Cut", "Copy", "Paste"],
                          ["Undo", "Redo"],
                          ["SpellChecker"],
                          ["Link", "Unlink", "Anchor"],
                          [
                            "Image",
                            "Table",
                            "Horizontal Line",
                            "Special Character"
                          ],
                          // ["Maximize"],
                          ["Source"],
                          ["Bold", "Italic", "Strike"],
                          ["RemoveFormat"],
                          ["NumberedList", "BulletedList"],
                          ["DecreaseIndent", "IncreaseIndent"],
                          ["BlockQuote"],
                          ["Styles"],
                          ["Format"],
                          ["About"]
                        ]
                      }}
                      onChange={event => handelDescriptionChange(event)}
                      onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }
                      invalid={errorList.description ? true : false}
                    />
                    <Errors current_key="description" key="description" />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" className="m-1" size="sm" color="primary">
                    {/* {loading?<Spinner/>: */}
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <a onClick={onClickHandel} href="#!">
                    <Button type="reset" className="m-1" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </a>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

CreateCMS.propTypes = {
  create: PropTypes.func.isRequired,
  cancelSave: PropTypes.func.isRequired,
  //   cms: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  errorList: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  //   cms: state.cms,
  errorList: state.errors,
  loading: state.cms.loading
});

export default connect(mapStateToProps, { create, cancelSave })(
  CreateCMS
);
