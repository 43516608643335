import { Dialog } from "@headlessui/react";
import { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import Button from "../Button";
import "react-image-crop/dist/ReactCrop.css";

export default function ImageCropper({
  src,
  hide,
  isOpen,
  onComplete,
  aspect,
}) {
  const imageRef = useRef(initialCrop);
  const [crop, setCrop] = useState();

  return (
    <Dialog as="div" className="relative z-50" open={isOpen} onClose={hide}>
      <div className="fixed inset-0 bg-black bg-dgray-700/70" onClick={hide} />

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium text-center items-center flex flex-col leading-6 text-dgray-900"
            >
              Crop Image
            </Dialog.Title>
            <Crop
              src={src}
              crop={{ ...crop, aspect }}
              setCrop={setCrop}
              onLoad={(e) => (imageRef.current = e)}
            />
            <div className="flex gap-3 justify-end">
              <Button
                type="button"
                className=""
                onClick={async () => {
                  const fullImg = await getCroppedImg(imageRef.current, crop);
                  const imgFile = new File([fullImg], "image", {
                    type: fullImg.type,
                  });
                  onComplete?.(imgFile);
                  setCrop(fullCrop);
                  hide();
                }}
                small
                secondary
              >
                Crop
              </Button>
              <Button type="button" className="" onClick={hide} small flat>
                Cancel
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}

function Crop({ src, onLoad, crop, setCrop }) {
  return (
    <div className="flex justify-center items-center py-6">
      <ReactCrop
        className=""
        crop={crop}
        onImageLoaded={onLoad}
        onChange={setCrop}
        src={src}
      />
    </div>
  );
}

function getCroppedImg(image, crop) {
  const canvas = document.createElement("canvas");

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext("2d");
  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve, reject) =>
    canvas.toBlob(
      (blob) => {
        blob.name = `${Date.now()}.jpg`;
        resolve(blob);
      },
      "image/jpeg",
      1
    )
  );
}

const initialCrop = {
  unit: "%",
  x: 25,
  y: 25,
  width: 50,
  height: 50,
};

const fullCrop = {
  unit: "%",
  x: 0,
  y: 0,
  width: 100,
  height: 100,
};
