import React, { useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Generic from "./Generic/Generic";
import SpecificIndividual from "./SpecificIndividual/SpecificIndividual";
import SpecificGroup from "./SpecificGroup/SpecificGroup";
import MentorCalendar from "../../../Mentor/MentorCalendar";

import { SESSION_TYPE, TABS } from "constants/index";
import { EDUCATOR_COURSE_CREATE_ERROR } from "actions/types";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const SessionInformation = ({
  typeOfSession,
  sessionFormData,
  setSessionFormData,
  setCalendarConfirmationTabEnable,
  calendarConfirmationTabEnable,
  setActiveTab,
  isConfirmation = false,
  checkSessionValidation,
  defaultGenericDateTimingInput,
  defaultGenericSessionGroup,
  defaultSpecificIndividualDateTimingInput,
  defaultSpecificIndividualSessionGroup,
  defaultSpecificGroupDateTimingInput,
  defaultSpecificGroupSessionGroup,
  isForEdit = false,
  current_date,
  sessionErrorMsg,
  setSessionErrorMsg,
  onSubmitDraftForm
}) => {
  /*********************Use Dispatch to set state ************************/

  const dispatch = useDispatch();

  /*********************calander popup modal  ************************/
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  /************state to check specific individual form inputs are valid or not**************/
  const [isSpecificIndividualValid, setIsSpecificIndividualValid] = useState(
    false
  );
  const [specificIndividualErrors, setSpecificIndividualErrors] = useState(
    null
  );
  /************state to check specific group form inputs are valid or not**************/
  const [isSpecificGroupValid, setIsSpecificGroupValid] = useState(false);
  const [specificGroupErrors, setSpecificGroupErrors] = useState(null);
  /************state to check generic form inputs are valid or not**************/
  const [isGenericValid, setIsGenericValid] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);

  // /************all sessions errors**************/
  // const [allSessionErrors, setAllSessionErrors] = useState(null);

  // useMemo(() => {
  //   let errorsList = [];
  //   console.log(specificIndividualErrors);
  //   if (specificIndividualErrors != null) {
  //     errorsList = specificIndividualErrors;
  //     // errorsList.push(...specificIndividualErrors);
  //   }
  //   if (specificGroupErrors != null) {
  //     errorsList = [...errorsList, ...specificGroupErrors];
  //   }
  //   if (genericErrors != null) {
  //     errorsList = [...errorsList, ...genericErrors];
  //   }
  //   console.log(errorsList);
  //   setAllSessionErrors(errorsList.length > 0 ? errorsList : null);
  // }, [specificIndividualErrors, specificGroupErrors, genericErrors]);

  /************check all forms validity and enable disable next tabs**************/
  useMemo(() => {
    let canBeEnabled = true;
    if (
      (typeOfSession === SESSION_TYPE.GENERIC && !isGenericValid) ||
      ((typeOfSession === SESSION_TYPE.SPECIFIC_INDIVIDUAL ||
        typeOfSession === SESSION_TYPE.SPECIFIC_BOTH) &&
        !isSpecificIndividualValid) ||
      ((typeOfSession === SESSION_TYPE.SPECIFIC_GROUP ||
        typeOfSession === SESSION_TYPE.SPECIFIC_BOTH) &&
        !isSpecificGroupValid)
    ) {
      canBeEnabled = false;
    }
    // TODO -> @DURUL
    setCalendarConfirmationTabEnable(canBeEnabled);
  }, [isSpecificIndividualValid, isSpecificGroupValid, isGenericValid]);

  /*********************on submit event************************/
  const onSessionInformationSubmit = async (e) => {
    e.preventDefault();
    if (!calendarConfirmationTabEnable) {
      let errorsList = [];
      if (specificIndividualErrors != null) {
        await Object.keys(specificIndividualErrors).map((key) => {
          errorsList[key] = specificIndividualErrors[key];
        });
      }
      if (specificGroupErrors != null) {
        await Object.keys(specificGroupErrors).map((key) => {
          errorsList[key] = specificGroupErrors[key];
        });
      }
      if (genericErrors != null) {
        await Object.keys(genericErrors).map((key) => {
          errorsList[key] = genericErrors[key];
        });
      }
      if (Object.keys(errorsList).length > 0)
        dispatch({
          type: EDUCATOR_COURSE_CREATE_ERROR,
          payload: {
            message: "Please correct the errors",
            alertType: "danger",
            errorsList: errorsList,
          },
        });
      setSessionErrorMsg("Please fix validation errors identified below");
      window.scrollTo(0, 0);
    } else {
      let promise = await checkSessionValidation();
      let conflictStatus = false;
      await Promise.resolve(promise).then((value) => {
        conflictStatus = value;
      });
      if (conflictStatus) {
        setActiveTab(TABS.CALENDAR);
      } else {
        setSessionErrorMsg("Please fix validation errors identified below");
        window.scrollTo(0, 0);
      }
    }
  };

  /*********************back button event************************/
  const onBackButtonClick = (e) => {
    e.preventDefault();
    setActiveTab(TABS.BASIC);
  };
  const onClickDraftForm = (e) => {
    onSubmitDraftForm();
  };
  return (
    <section className="card-body">
      {!isConfirmation && sessionErrorMsg != "" && (
        <div class="alert alert-danger fade show" role="alert">
          {sessionErrorMsg}
        </div>
      )}
      {!isConfirmation && (
        <div className="row">
          <div className="col-md-12 text-right">
            <b>
              <a href="#" onClick={toggle}>
                <i className="fa fa-calendar" />
                Show My Calendar
              </a>
            </b>
          </div>
        </div>
      )}
      <form
        onSubmit={(e) => {
          onSessionInformationSubmit(e);
        }}
      >
        {typeOfSession === SESSION_TYPE.GENERIC && (
          <Generic
            sessionFormData={sessionFormData}
            setSessionFormData={setSessionFormData}
            setIsGenericValid={setIsGenericValid}
            isConfirmation={isConfirmation}
            setActiveTab={setActiveTab}
            defaultGenericDateTimingInput={defaultGenericDateTimingInput}
            defaultGenericSessionGroup={defaultGenericSessionGroup}
            isForEdit={isForEdit}
            current_date={current_date}
            setGenericErrors={setGenericErrors}
            setSessionErrorMsg={setSessionErrorMsg}
          />
        )}
        {(typeOfSession === SESSION_TYPE.SPECIFIC_INDIVIDUAL ||
          typeOfSession === SESSION_TYPE.SPECIFIC_BOTH) && (
          <SpecificIndividual
            sessionFormData={sessionFormData}
            setSessionFormData={setSessionFormData}
            setIsSpecificIndividualValid={setIsSpecificIndividualValid}
            isConfirmation={isConfirmation}
            setActiveTab={setActiveTab}
            defaultSpecificIndividualDateTimingInput={
              defaultSpecificIndividualDateTimingInput
            }
            defaultSpecificIndividualSessionGroup={
              defaultSpecificIndividualSessionGroup
            }
            isForEdit={isForEdit}
            current_date={current_date}
            setSpecificIndividualErrors={setSpecificIndividualErrors}
            setSessionErrorMsg={setSessionErrorMsg}
          />
        )}
        {(typeOfSession === SESSION_TYPE.SPECIFIC_GROUP ||
          typeOfSession === SESSION_TYPE.SPECIFIC_BOTH) && (
          <SpecificGroup
            sessionFormData={sessionFormData}
            setSessionFormData={setSessionFormData}
            setIsSpecificGroupValid={setIsSpecificGroupValid}
            isConfirmation={isConfirmation}
            setActiveTab={setActiveTab}
            defaultSpecificGroupDateTimingInput={
              defaultSpecificGroupDateTimingInput
            }
            defaultSpecificGroupSessionGroup={defaultSpecificGroupSessionGroup}
            isForEdit={isForEdit}
            current_date={current_date}
            setSpecificGroupErrors={setSpecificGroupErrors}
            setSessionErrorMsg={setSessionErrorMsg}
          />
        )}

        {!isConfirmation && (
          <div className="row">
            {/* <div className="col-md-12">
              <hr />
            </div> */}
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <button
                    className="btn btn-primary"
                    onClick={(e) => onBackButtonClick(e)}
                  >
                    Back
                  </button>
                </div>
                <div className="col-md-4" />
                <div className="col-md-4">
                  <div className="form-group text-right mb-0">
                    <button
                      className="btn btn-secondary mb-0 mr-3"
                      onClick={(e) => onClickDraftForm(e)}
                    >
                    Save Draft
                    </button>
                    <input
                      type="submit"
                      className="btn btn-secondary mb-0"
                      value="Next"
                      // disabled={!calendarConfirmationTabEnable}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>My Calendar</ModalHeader>
        <ModalBody>
          <MentorCalendar />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="m-1" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </section>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(withRouter(SessionInformation));
