import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { UncontrolledCollapse } from "reactstrap";
import { getConfigurations } from "actions/front/myaccount";

const Filter = ({
  onClickcloseNav,
  getConfigurations,
  filters,
  setFilters,
}) => {
  const [configurations, setConfigurations] = useState({
    area_of_intrest: [],
    year_of_experience: null,
    educations: null,
    industries: null,
  });
  /********************get logged in user detail and configrations data*****************/
  useEffect(() => {
    getConfigurations().then((data) => {
      var config_obj = [];
      data.response.map((configuration, i) => {
        if (configuration.config_key === "area_of_intrest") {
          let newObject = [];
          configuration.config_value.map((value) => {
            newObject.push({
              value: value,
              label: value,
            });
          });
          configuration.config_value = newObject;
        }
        config_obj[configuration.config_key] = configuration.config_value;
      });
      setConfigurations(config_obj);
    });
  }, []);
  const [filterExpand, setFilterExpand] = useState({
    education: false,
    industry: false,
    sessionCompleted: false,
    yearsOfExperince: false,
    rating: false,
  });

  const onClickCollapse = (e, filterName) => {
    setFilterExpand({
      ...filterExpand,
      [filterName]: !filterExpand[filterName],
    });
  };

  const initialFilter = {
    education: "",
    industry: [],
    session_completed: [],
    year_of_experience: [],
    rating: [],
  };

  const [custonFilter, setCustomFilter] = useState(initialFilter);

  const {
    education,
    industry,
    session_completed,
    year_of_experience,
    rating,
  } = custonFilter;

  const handleCustomFilter = (e) => {
    if (e.target.type === "checkbox") {
      let newData = [];
      if (e.target.name === "industry") {
        newData = [...industry];
      } else if (e.target.name === "session_completed") {
        newData = [...session_completed];
      } else if (e.target.name === "year_of_experience") {
        newData = [...year_of_experience];
      } else if (e.target.name === "rating") {
        newData = [...rating];
      }
      if (e.target.checked) {
        newData.push(e.target.value);
      } else {
        newData.splice(newData.indexOf(e.target.value), 1);
      }
      setCustomFilter({ ...custonFilter, [e.target.name]: newData });
    } else {
      setCustomFilter({ ...custonFilter, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    let final_query = "";
    Object.keys(custonFilter).map(function (key) {
      final_query = `${final_query}&${key}=${custonFilter[key]}`;
    });
    setFilters({ ...filters, page: 1, custom_filters: final_query });
  }, [custonFilter]);
  return (
    <div id="mySidebar" className="my-sidebar sidebar-mentor">
      <button onClick={(e) => onClickcloseNav()} className="closebtn">
        Close
      </button>
      <div className="col-md-12 mt-3">
        <article className="filter-group">
          <header className="card-header">
            <button
              className="collapsed"
              id="education"
              name="education"
              onClick={(e) => onClickCollapse(e, "education")}
            >
              <i
                className={`icon-control fa ${
                  filterExpand.education
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              />
              <h6 className="title">Education </h6>
            </button>
          </header>
          <UncontrolledCollapse className="filter-content" toggler="#education">
            <div className="card-body pl-0 pr-0">
              <div className="selectGroup">
                <select
                  name="education"
                  className="form-control custom-select"
                  value={education}
                  onChange={(e) => handleCustomFilter(e)}
                >
                  <option value="">--Select--</option>
                  {configurations.educations &&
                    configurations.educations.map((c, i) => (
                      <option key={i} value={c}>
                        {c}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </UncontrolledCollapse>
        </article>
        <article className="filter-group">
          <header className="card-header">
            <button
              className="collapsed"
              id="industry"
              name="industry"
              onClick={(e) => onClickCollapse(e, "industry")}
            >
              <i
                className={`icon-control fa ${
                  filterExpand.industry ? "fa-chevron-down" : "fa-chevron-right"
                }`}
              />
              <h6 className="title">Industry </h6>
            </button>
          </header>
          <UncontrolledCollapse className="filter-content " toggler="#industry">
            <div className="card-body pl-0 pr-0">
              {configurations.industries &&
                configurations.industries.map((c, i) => (
                  <label className="custom-control" key={i}>
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      value={c}
                      name="industry"
                      checked={industry.includes(c) ? true : false}
                      onChange={(e) => handleCustomFilter(e)}
                    />
                    <div className="custom-control-label">{c} </div>
                  </label>
                ))}
            </div>
          </UncontrolledCollapse>
        </article>
        {/* <article className="filter-group">
          <header className="card-header">
            <button
              className="collapsed"
              id="session-completed"
              name="sessionCompleted"
              onClick={(e) => onClickCollapse(e, "sessionCompleted")}
            >
              <i
                className={`icon-control fa ${
                  filterExpand.sessionCompleted
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              />
              <h6 className="title">Session Completed </h6>
            </button>
          </header>
          <UncontrolledCollapse
            className="filter-content"
            toggler="#session-completed"
          >
            <div className="card-body pl-0 pr-0">
              <label className="custom-control">
                <input type="checkbox" className="custom-control-input" />
                <div className="custom-control-label">0 to 100</div>
              </label>
              <label className="custom-control">
                <input type="checkbox" className="custom-control-input" />
                <div className="custom-control-label">101 to 250</div>
              </label>
              <label className="custom-control">
                <input type="checkbox" className="custom-control-input" />
                <div className="custom-control-label">251 to 500</div>
              </label>
              <label className="custom-control">
                <input type="checkbox" className="custom-control-input" />
                <div className="custom-control-label">500 to 1000</div>
              </label>
              <label className="custom-control">
                <input type="checkbox" className="custom-control-input" />
                <div className="custom-control-label">1001 &amp; Above</div>
              </label>
            </div>
          </UncontrolledCollapse>
        </article> */}
        <article className="filter-group">
          <header className="card-header">
            <button
              className="collapsed"
              id="years-of-experience"
              name="yearsOfExperince"
              onClick={(e) => onClickCollapse(e, "yearsOfExperince")}
            >
              <i
                className={`icon-control fa ${
                  filterExpand.yearsOfExperince
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              />
              <h6 className="title">Years of Experience </h6>
            </button>
          </header>
          <UncontrolledCollapse
            className="filter-content"
            toggler="#years-of-experience"
          >
            <div className="card-body pl-0 pr-0">
              {configurations.year_of_experience &&
                configurations.year_of_experience.map((c, i) => (
                  <label className="custom-control" key={i}>
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      value={c}
                      name="year_of_experience"
                      checked={year_of_experience.includes(c) ? true : false}
                      onChange={(e) => handleCustomFilter(e)}
                    />
                    <div className="custom-control-label">{c}</div>
                  </label>
                ))}
            </div>
          </UncontrolledCollapse>
        </article>
        <article className="filter-group">
          <header className="card-header">
            <button
              className="collapsed"
              id="rating"
              name="rating"
              onClick={(e) => onClickCollapse(e, "rating")}
            >
              <i
                className={`icon-control fa ${
                  filterExpand.rating ? "fa-chevron-down" : "fa-chevron-right"
                }`}
              />
              <h6 className="title">Rating </h6>
            </button>
          </header>
          <UncontrolledCollapse className="filter-content" toggler="#rating">
            <div className="card-body pl-0 pr-0">
              <label className="custom-control">
                <input
                  type="checkbox"
                  className="custom-control-input"
                />
                <div className="custom-control-label">All </div>
              </label>
              <label className="custom-control">
                <input
                  type="checkbox"
                  name="rating"
                  value="5"
                  onChange={(e) => handleCustomFilter(e)}
                  className="custom-control-input"
                />
                <div className="custom-control-label">5 Star </div>
              </label>
              <label className="custom-control">
                <input
                  type="checkbox"
                  name="rating"
                  value="4"
                  onChange={(e) => handleCustomFilter(e)}
                  className="custom-control-input"
                />
                <div className="custom-control-label">4 Star</div>
              </label>
              <label className="custom-control">
                <input
                  type="checkbox"
                  name="rating"
                  value="3"
                  onChange={(e) => handleCustomFilter(e)}
                  className="custom-control-input"
                />
                <div className="custom-control-label">3 Star</div>
              </label>
              <label className="custom-control">
                <input
                  type="checkbox"
                  name="rating"
                  value="2"
                  onChange={(e) => handleCustomFilter(e)}
                  className="custom-control-input"
                />
                <div className="custom-control-label">2 Star</div>
              </label>
            </div>
          </UncontrolledCollapse>
        </article>
      </div>
    </div>
  );
};

export default connect(null, {
  getConfigurations,
})(withRouter(Filter));
