import axios from "utils/axios";
export const menberProductList = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/menber/product-list`, config);
    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      return {
        status: false,
        msg:res.data.message
      };
    }
  } catch (err) {
    return {
      status: false,
    };
  }
};

export const initializeMemberPayment = (menber_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/menber/initialize-payment?menber_id=${menber_id}`,
      config
    );

    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      return {
        status: false,
      };
    }
  } catch (err) {
    return {
      status: false,
    };
  }
};

export const UpdateMenberProductList = (id,price) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(`/api/menber/update_product`,{id,price},config);
    if (res.data.status === true) {
      return { status: true, data: res.data.response };
    } else {
      return {
        status: false,
      };
    }
  } catch (err) {
    return {
      status: false,
    };
  }
};
