import { sendContactUs } from "actions/front/contactus";
import { ReactComponent as InstagramIcon } from "assets/images/icons/instagram.svg";
import { ReactComponent as LinkedinIcon } from "assets/images/icons/linkedin.svg";
import { ReactComponent as Mail } from "assets/images/icons/mail.svg";
import { ReactComponent as Phone } from "assets/images/icons/phone.svg";
import { ReactComponent as TwitterIcon } from "assets/images/icons/twitter.svg";
import { ReactComponent as YoutubeIcon } from "assets/images/icons/youtube.svg";
import Button from "components/common/Button";
import CenterContainer from "components/common/Container/Center";
import Textarea from "components/common/form/Textarea";
import TextInput from "components/common/form/TextInput";
import { SOCIAL_LINKS } from "constants";
import SelectInput from "components/common/form/SelectInput";
import { useState } from "react";
import FooterLinksLayout from "../Layout/FooterLinksLayout";
import { connect } from "react-redux";

export default function Contact() {
  return (
    <FooterLinksLayout
      title="Contact us"
      subtitle="We’d love to hear from you. Please fill out this form or shoot us an email."
    >
      <CenterContainer className="flex lg:flex-col gap-16 py-24 lg:py-12 my-28 lg:my-0">
        <div className="space-y-12 flex-1">
          {leftItems.map(({ icon, label, description, link }, i) => (
            <div key={i}>
              <div className="mb-4 text-primary-600">{icon}</div>
              <div className="mb-2 text-xl font-medium lg:text-lg">{label}</div>
              <div className="mb-4 text-dgray-500">{description}</div>
              <div className="text-primary-700 font-medium">{link}</div>
            </div>
          ))}
          <div className="flex gap-8 text-dgray-500">
            {socialItems.map(({ icon, href }, i) => (
              <a href={href} target="_blank" rel="noreferrer" key={i}>
                {icon}
              </a>
            ))}
          </div>
        </div>
        <ContactForm />
      </CenterContainer>
    </FooterLinksLayout>
  );
}

const ContactForm = connect(() => ({}), {
  sendContactUs,
})(Form);

function Form({ sendContactUs }) {
  const initialformdata = {
    f_name: "",
    l_name: "",
    c_email: "",
    topic: "",
    txt_message: "",
  };

  const [formData, setFormData] = useState(initialformdata);
  const [isSuccess, setSuccess] = useState(null);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onContactSubmit = (e) => {
    e.preventDefault();
    sendContactUs(formData).then((res) => {
      if (res.status) {
        setSuccess(res.message);
        setFormData(initialformdata);
      }
    });
  };

  return (
    <div className="flex-1 bg-dgray-50 lg:bg-white rounded-2xl py-10 lg:p-0 px-8">
      <form onSubmit={onContactSubmit}>
        <div className="space-y-6">
          <div className="flex gap-8">
            <TextInput
              noFormik
              name="f_name"
              className="flex-1 w-full"
              label="First name"
              placeholder="First name"
              onChange={onChange}
              value={formData.f_name}
              required
            />
            <TextInput
              noFormik
              name="l_name"
              className="flex-1 w-full"
              label="Last name"
              placeholder="Last name"
              onChange={onChange}
              value={formData.l_name}
              required
            />
          </div>
          <TextInput
            noFormik
            type="email"
            name="c_email"
            label="Email"
            placeholder="you@company.com"
            className="w-full"
            onChange={onChange}
            value={formData.c_email}
          />
          <SelectInput
            noFormik
            required
            small
            name="topic"
            onChange={(value, e) => setFormData({ ...formData, topic: value })}
            placeholder="Please select"
            value={formData.topic}
            options={[
              { label: "General Inquiry", value: "General Inquiry" },
              { label: "Complaint", value: "Complaint" },
              { label: "Suggestions", value: "Suggestions" },
              { label: "Others", value: "Others" },
            ]}
          />

          <Textarea
            noFormik
            name="txt_message"
            label="Message"
            onChange={onChange}
            placeholder="Leave us a message..."
            value={formData.txt_message}
            required
          />
        </div>
        {isSuccess && (
          <p className="text-primary-700 text-center mt-2">
            Submission successful
          </p>
        )}
        <Button small className="w-full py-3 mt-7">
          Send message
        </Button>
      </form>
    </div>
  );
}

const leftItems = [
  {
    icon: <Mail />,
    label: "Email",
    description: "Our friendly team is here to help.",
    link: <a href="mailto:info@dingdingnow.com">info@dingdingnow.com</a>,
  },
  // {
  //   icon: <Phone />,
  //   label: "Phone",
  //   description: "Mon-Fri from 8am to 5pm.",
  //   link: <a href="tel:+1(555)000-0000">+1 (555) 000-0000</a>,
  // },
];

const socialItems = [
  {
    icon: <LinkedinIcon />,
    href: SOCIAL_LINKS.linkedin,
  },
  {
    icon: <InstagramIcon />,
    href: SOCIAL_LINKS.instagram,
  },
  {
    icon: <TwitterIcon />,
    href: SOCIAL_LINKS.twitter,
  },
  {
    icon: <YoutubeIcon />,
    href: SOCIAL_LINKS.youtube,
  },
];
